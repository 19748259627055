import { useAtomValue } from 'jotai';
import React, { FC, useCallback, useEffect, useState } from 'react';

import { useCompanyFeatureFlag } from '@attentive/acore-utils';
import { omit } from '@attentive/nodash';
import { Box, FormField, MultiSelect, styled, Text, TextInput } from '@attentive/picnic';

import {
  ActionChannel,
  ActionFilter,
  ActionSource,
  AvailableDataType,
  CAMPAIGN_ATTR,
  CustomAttributeOptionValue,
  CustomEventValue,
  FilterType,
  FilterValue,
  HasVerbType,
  IsVerbType,
  LocationType,
  NestedPropertyStringCondition,
  ProductDataAttributeValue,
  RechargeFilterAttrs,
  SalesChannelValue,
  SegmentableAttributes,
  SegmentComponent,
  SegmentOptions,
  SegmentParameters,
  SelectableCampaignType,
  StringValueDisplayableOption,
  Vendor,
  VendorDisplayMap,
  WellKnownPropertyLocaleTypes,
  WellKnownPropertyTypes,
} from '../../../../../../constants';
import { useCompany } from '../../../../../../hooks';
import { getLocationValues } from '../../../../../../utils';
import {
  getAttributeFilterType,
  getFilter,
  isActionTypeFilter,
  isBirthdayMonthFilterType,
  isBooleanFilterType,
  isCarrierFilterType,
  isCountryFilterType,
  isDeviceTypeFilterType,
  isDistanceFilterType,
  isEmailEventType,
  isIntegerFilterType,
  isKlaviyoTextAttributeType,
  isKlaviyoOptInDateFilter,
  isLocationFilterType,
  isNewLocationFilterType,
  isOperatingSystemFilterType,
  isPunchhSingleValueAttributeType,
  isShopifyDynamicValueAttributeType,
  isShopifyOptInDateFilter,
  isSiteFilterType,
  isSourceFilterType,
  isSubscriberPreferenceFilterType,
  usesCustomVendorAttr,
  usesFrequency,
  usesHasVerb,
  usesIsVerb,
  usesTimeSelector,
  usesCustomAttributeText,
  isMailExchangeType,
  isWellKnownCountryType,
  hasNestedAttribute,
  isRechargeTimestampType,
  isRechargeStatusAttribute,
  isLanguageType,
  getNestedAttribute,
  isTextKeywordFilterType,
  isBazaarvoiceEventType,
  usesPartialDate,
} from '../../../../../../utils/typeAssertions';
import KlaviyoProfileAttrSelector from '../../../../../KlaviyoProfile/KlaviyoProfileAttrSelector';
import SegmentConditionItemContainer from '../../../../../SegmentConditionItemContainer';
import VendorAttrSelector from '../../../../../VendorAttrSelector/VendorAttrSelector';
import {
  PRODUCT_OPTION_ATTR,
  productAttrsExcludedFromViewAction,
  shopifySelectorsOptionsMap,
  birthdayMonthOptions,
} from '../../../../constants';
import {
  subscriberAttributeDataTypeMapAtom,
  vendorAttributeDataTypeMapAtom,
} from '../../../../utils/attributeDataTypeMap';
import { isPriceAttribute } from '../../../../utils/filterType';
import { getQuantityType } from '../../../../utils/getQuantityType';
import {
  getWellKnownNestedProperty,
  getWellKnownProperty,
} from '../../../../utils/getWellKnownProperty';
import { optionMatchesAttribute } from '../../../../utils/segmentConditionUtils';
import { getSentenceCase, splitNameSpacedAttribute } from '../../../../utils/stringUtils';
import { BazaarvoiceSelector } from '../../../BazaarvoiceSelector';
import { BooleanSelector } from '../../../BooleanSelector';
import { MultiCountrySelector as Country } from '../../../Country';
import CustomEventCondition from '../../../CustomEvent/CustomEventCondition';
import CustomPropertySelector from '../../../CustomPropertySelector';
import { Distance } from '../../../Distance';
import EmailSourceSelector from '../../../EmailSourceSelector';
import { FilterByActivitySelectorWrapper } from '../../../FilterByActivitySelector';
import FilterByAttributeSelector from '../../../FilterByAttributeSelector/FilterByAttributeSelector';
import FilterBySegmentSelector from '../../../FilterBySegmentSelector/FilterBySegmentSelector';
import FrequencySelector from '../../../FrequencySelector';
import { KeywordSelector } from '../../../KeywordSelector';
import { LanguageSelector } from '../../../Locale/LanguageSelector';
import { MppSegmentCondition, MessageSegmentCondition } from '../../../MessageEngagement';
import { MESSAGE_FILTER_PARAMETERS } from '../../../MessageEngagement/components/NewMessageSegmentCondition';
import OptionSelector from '../../../OptionSelector';
import PreferenceSelector from '../../../PreferenceSelector';
import ProductDataSegmentCondition from '../../../ProductData/ProductDataSegmentCondition';
import SalesChannelCondition from '../../../ProductData/SalesChannelCondition';
import QuantitySelector from '../../../QuantitySelector';
import {
  RechargeTimestampSelector,
  RechargeChurnReasonSelector,
  RechargeSubscriptionStatusSelector,
} from '../../../Recharge';
import { useShowExpressionValidationAtomValue } from '../../../SegmentExpressionBuilder/hooks/useShowExpressionValidationAtom';
import SegmentFilterButton from '../../../SegmentFilterButton';
import { ShopifyDynamicValueSelector } from '../../../ShopifyDynamicValueSelector';
import SiteSelector, { SiteConditionValue } from '../../../SiteSelector';
import SourceSelector, { SourceValue } from '../../../SourceSelector';
import StateSelector from '../../../StateSelector';
import { TimeSelector, TIME_PARAMETERS } from '../../../TimeSelector';
import VerbSelector, { VerbSelectorType } from '../../../VerbSelector';
import { WellKnownPropertySelector } from '../../../WellKnownPropertySelector';
import { Zipcode as ZipcodeSelector } from '../../../Zipcode';
import { SegmentConditionDebug } from '../../components/SegmentConditionDebug';
import { setFilterParameters } from '../../setFilterParameters';

const ConditionInputWrapper = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'start',
  '& > *': {
    mr: '$space4',
    mb: '$space4',
  },
});

export type ChangeFilterTypeFn = (newFilter: FilterType) => void;

export interface SegmentConditionSelectorsProps {
  availableData: Map<AvailableDataType, boolean>;
  setDialogIsOpen: (isOpen: boolean) => void;
  component: SegmentComponent;
  segmentOptions: SegmentOptions;
  segmentableAttributesByVendor: SegmentableAttributes;
  onChange: (parameters: SegmentParameters) => void;
  onClickFilterButton: (filterType: string) => void;
  segmentFilterType: FilterType | undefined;
  children?: React.ReactNode;
  changeFilterType?: ChangeFilterTypeFn;
}

export const SegmentConditionSelectors: FC<SegmentConditionSelectorsProps> = ({
  availableData,
  setDialogIsOpen,
  component,
  segmentOptions,
  onChange,
  segmentableAttributesByVendor,
  onClickFilterButton,
  segmentFilterType,
  children,
  changeFilterType,
}) => {
  const { parameters } = component;
  const klaviyoEmailEventsEnabled = useCompanyFeatureFlag('ENABLE_SEGMENT_BY_KLAVIYO_EMAIL_EVENT');
  const attentiveEmailEventsEnabled = useCompanyFeatureFlag('ENABLE_TRIGGERED_EMAIL');
  const useExperimentalTimeComparatorDefault = useCompanyFeatureFlag(
    'USE_WITHIN_THE_LAST_DEFAULT_IN_SEGMENT_BUILDER'
  );

  const subscriberAttributeDataTypeMap = useAtomValue(subscriberAttributeDataTypeMapAtom);
  const vendorAttributeDataTypeMap = useAtomValue(vendorAttributeDataTypeMapAtom);

  const defaultEmailActionSource = attentiveEmailEventsEnabled
    ? ActionSource.ATTENTIVE
    : ActionSource.KLAVIYO;

  const {
    productDataAttributeValues,
    salesChannelValue,
    customAttributeValueGroup,
    subscriberAttributeValueGroup,
    profileAttribute,
    subscriberAction,
    frequency,
    frequencyComparator,
    hasVerb,
    isVerb,
    propertyAttribute,
    propertyAttributeCondition,
    pattern,
    fieldValues,
    countryId,
    stateId,
    tagKey,
    quantity,
    quantityEnd,
    quantityComparator,
    subscriberPreferenceKey,
    subscriberPreferenceValue,
    textValue,
    textValues,
    subscriberListMetadataId,
    deviceTypes,
    deviceOSList,
    carrier,
    attribute,
    customEventOption,
    userPropertyValues,
    deleted: customPropertyDeleted,
    displayName: customPropertyDisplayName,
    subscriberActionChannel,
    subscriberActionSource,
    vendor,
    locations,
    origin,
    radius,
    distanceUnit,
    excludePrivacyOpens,
    wellKnownUserPropertyValues,
    journeyAttributeValues,
  } = parameters;
  const locationValues = getLocationValues(parameters) as string[] | undefined;
  const { subscriberActivity, subscriberAttributeOptions } = segmentOptions;
  const { customEventOptions, ecommerceOptions } = subscriberActivity;
  const { productDataOptions } = ecommerceOptions;
  const {
    customAttributeOptions,
    demographicOptions,
    deviceOptions,
    emailOptions,
    shopifyOptions,
    smsOptions,
  } = subscriberAttributeOptions;
  const { creativeNameToIds: emailCreatives } = emailOptions;
  const { companyCodes, creativeNameToIds: textCreatives, subscriberPreferenceGroups } = smsOptions;
  const { carriers, deviceTypes: deviceTypeOptions, operatingSystems } = deviceOptions;
  const creatives =
    subscriberActionChannel === ActionChannel.EMAIL ? emailCreatives : textCreatives;

  const [preferenceOptions, setPreferenceOptions] = useState<StringValueDisplayableOption[]>([]);

  const { country, timezone } = useCompany();

  useEffect(() => {
    setPreferenceOptions(
      subscriberPreferenceGroups.reduce(
        (result: StringValueDisplayableOption[], group) =>
          group.optionValue === subscriberPreferenceKey ? group.preferences : result,
        []
      )
    );
  }, [subscriberPreferenceGroups, subscriberPreferenceKey]);

  const foundCustomEvent =
    customEventOption &&
    customEventOptions.find(
      (option) => option.customEventType === customEventOption.customEventType
    );
  const customEventValueOptions = foundCustomEvent?.customEventValues || [];
  const selectedCustomEventValues = customEventOption?.customEventValues;

  let { attributeOptions: productDataSelectorOptions } = productDataOptions;
  if (subscriberAction === ActionFilter.VIEWED) {
    productDataSelectorOptions = productDataSelectorOptions.filter((opt) => {
      return !productAttrsExcludedFromViewAction.includes(opt.optionValue);
    });
  }

  const msgCondition =
    customAttributeValueGroup?.attribute === CAMPAIGN_ATTR ? customAttributeValueGroup : undefined;

  const thirdPartyMsgCondition =
    subscriberAttributeValueGroup?.attribute === CAMPAIGN_ATTR
      ? subscriberAttributeValueGroup
      : undefined;

  const journeyMsgCondition = journeyAttributeValues !== undefined;

  const showRechargeChurnReasonCondition = hasNestedAttribute(
    parameters,
    RechargeFilterAttrs.CANCELLATION_REASON
  );

  const isSourceFilter = isSourceFilterType(parameters);
  const isBooleanFilter = isBooleanFilterType(
    parameters,
    subscriberAttributeDataTypeMap,
    vendorAttributeDataTypeMap
  );
  const isIntegerFilter = isIntegerFilterType(
    parameters,
    subscriberAttributeDataTypeMap,
    vendorAttributeDataTypeMap
  );
  const isEmailEvent = isEmailEventType(parameters);

  const emailEventSourceOptions = [
    ...(attentiveEmailEventsEnabled ? [ActionSource.ATTENTIVE] : []),
    ...(klaviyoEmailEventsEnabled ? [ActionSource.KLAVIYO] : []),
  ];

  const { isActionFilterType, isAttributeFilterType, isSegmentMembershipFilterType } =
    getAttributeFilterType(segmentFilterType);

  const filter = getFilter(parameters);
  const isKlaviyoOptInDate = isKlaviyoOptInDateFilter(filter);
  const isShopifyOptInDate = isShopifyOptInDateFilter(filter);
  const showPlainTextSource = isKlaviyoOptInDate || isShopifyOptInDate;
  const isDistanceFilter = isDistanceFilterType(filter);
  const isCountryFilter = isCountryFilterType(filter);
  const isActionFilter = isActionTypeFilter(parameters, filter);
  const isTextKeywordFilter = isTextKeywordFilterType(parameters);
  const isSiteFilter = isSiteFilterType(filter);
  const isLocationFilter = isLocationFilterType(filter);
  const isMailExchangeFilter = isMailExchangeType(parameters);
  const isWellKnownCountryFilter = isWellKnownCountryType(parameters);
  const isNewLocationFilter = isNewLocationFilterType(filter);
  const isSubscriberPreference = isSubscriberPreferenceFilterType(filter);
  const isDeviceType = isDeviceTypeFilterType(filter);
  const isOperatingSystem = isOperatingSystemFilterType(filter);
  const isCarrier = isCarrierFilterType(filter);
  const isBirthdayMonth = isBirthdayMonthFilterType(filter);
  const isRechargeTimestamp = isRechargeTimestampType(parameters, vendorAttributeDataTypeMap);
  const isRechargeSubscriptionStatus = isRechargeStatusAttribute(parameters);
  const isLanguage = isLanguageType(parameters);
  const isBazaarvoiceEvent = isBazaarvoiceEventType(parameters);

  const languageFilter = getNestedAttribute<NestedPropertyStringCondition>(
    parameters,
    WellKnownPropertyLocaleTypes.LANGUAGE
  );

  const isKlaviyoCustomAttribute = isKlaviyoTextAttributeType(
    parameters,
    vendorAttributeDataTypeMap
  );
  const isPunchhSingleValueAttribute = isPunchhSingleValueAttributeType(parameters);
  const isShopifyDynamicValueAttribute = isShopifyDynamicValueAttributeType(parameters);

  const useCustomVendorAttr = usesCustomVendorAttr(parameters, vendorAttributeDataTypeMap);
  const useCustomAttributeText = usesCustomAttributeText(
    parameters,
    subscriberAttributeDataTypeMap
  );
  const useFrequency = usesFrequency(parameters, filter);
  const useHasVerb = usesHasVerb(parameters, filter);
  const useIsVerb = usesIsVerb(
    parameters,
    subscriberAttributeDataTypeMap,
    vendorAttributeDataTypeMap,
    filter,
    segmentFilterType
  );
  const useQuantitySelector = (isIntegerFilter && !isBirthdayMonth) || isRechargeSubscriptionStatus;
  const useTimeSelector = usesTimeSelector(
    parameters,
    subscriberAttributeDataTypeMap,
    vendorAttributeDataTypeMap,
    filter
  );

  const selectedCustomAttribute =
    useCustomAttributeText && customAttributeOptions.find((opt) => opt.optionValue === attribute);
  const customAttributeOptionValues = selectedCustomAttribute
    ? selectedCustomAttribute?.values
    : [];
  const customAttributeType = selectedCustomAttribute && selectedCustomAttribute?.type;

  const { values: wellKnownMailExchangeValues = [] } =
    getWellKnownProperty(segmentOptions, WellKnownPropertyTypes.MAIL_EXCHANGE) || {};

  const wellKnownLocale = getWellKnownProperty(segmentOptions, WellKnownPropertyTypes.LOCALE);
  const { values: languages = [] } =
    getWellKnownNestedProperty(wellKnownLocale, WellKnownPropertyLocaleTypes.LANGUAGE) || {};
  const { values: wellKnownCountryValues = [] } =
    getWellKnownProperty(segmentOptions, WellKnownPropertyTypes.COUNTRY) || {};

  const showValidation = useShowExpressionValidationAtomValue();
  const deviceTypeError = showValidation && isDeviceType && !deviceTypes?.length;
  const operatingSystemError = showValidation && isOperatingSystem && !deviceOSList?.length;
  const profileAttrError = showValidation && profileAttribute && !textValues?.length;

  const handleFilterChange = useCallback(
    (
      changedFilter: FilterValue,
      optionValue?: string,
      displayName?: string,
      nestedOptionValue?: string
    ) => {
      onChange(
        setFilterParameters({
          changedFilter,
          companyTimezone: timezone,
          defaultEmailActionSource,
          displayName,
          optionValue,
          nestedOptionValue,
          parameters,
          subscriberAttributeDataTypeMap,
          vendorAttributeDataTypeMap,
          useExperimentalDefault: useExperimentalTimeComparatorDefault,
        })
      );
    },
    [
      onChange,
      defaultEmailActionSource,
      parameters,
      subscriberAttributeDataTypeMap,
      timezone,
      vendorAttributeDataTypeMap,
      useExperimentalTimeComparatorDefault,
    ]
  );

  const updateCustomEventValueRow = (valueIdx: number, value: CustomEventValue) => {
    if (!customEventOption) return;
    const newValues = [...customEventOption.customEventValues];
    newValues[valueIdx] = value;
    onChange({
      ...parameters,
      customEventOption: {
        ...customEventOption,
        customEventValues: newValues,
      },
    });
  };

  const deleteCustomEventValueRow = (valueIdx: number) => {
    if (!customEventOption) return;
    const newValues = [...customEventOption.customEventValues];
    newValues.splice(valueIdx, 1);
    onChange({
      ...parameters,
      customEventOption: {
        ...customEventOption,
        customEventValues: newValues,
      },
    });
  };

  const formatProductOptionAttribute = (attr: ProductDataAttributeValue) => {
    // When initially selecting a product option attribute, the attribute will be formatted as 'attribute:name'.
    // Subsequent updates will have attr.attribute and attr.name set correctly and not need any special handling
    if (!attr.attribute.startsWith(`${PRODUCT_OPTION_ATTR}:`)) return attr;
    const [attributeType, name] = splitNameSpacedAttribute(attr.attribute);
    return {
      ...attr,
      attribute: attributeType,
      name,
    };
  };

  const updateProductDataRow = (attrIdx: number, attr: ProductDataAttributeValue) => {
    if (!productDataAttributeValues) return;
    const newProductData = [...productDataAttributeValues];
    newProductData[attrIdx] = formatProductOptionAttribute(attr);
    onChange({
      ...parameters,
      productDataAttributeValues: newProductData,
    });
  };

  const deleteProductDataRow = (attrIdx: number) => {
    if (!productDataAttributeValues) return;
    const newProductData = [...productDataAttributeValues];
    newProductData.splice(attrIdx, 1);
    onChange({
      ...parameters,
      productDataAttributeValues: newProductData,
    });
  };

  const updateSalesChannelRow = (val: SalesChannelValue) => {
    if (!salesChannelValue) return;
    onChange({
      ...parameters,
      salesChannelValue: val,
    });
  };

  const deleteSalesChannelRow = () => {
    const params = { ...parameters };
    delete params.salesChannelValue;
    onChange(params);
  };

  const getSelectedCampaignType = (): SelectableCampaignType => {
    if (thirdPartyMsgCondition) {
      return 'KLAVIYO_EMAIL';
    }

    return isEmailEvent ? 'ATTENTIVE_EMAIL' : 'ATTENTIVE_TEXT';
  };

  const updateMppRow = (newValue: boolean) => {
    const params = { ...parameters, excludePrivacyOpens: newValue };
    onChange(params);
  };

  const deleteMppRow = () => {
    const params = { ...parameters };
    delete params.excludePrivacyOpens;
    onChange(params);
  };

  const getAvailableCustomEventValueOptions = (selectedValue: CustomEventValue) =>
    customEventValueOptions
      .filter(
        (opt) =>
          selectedValue.customEventKey === opt.customEventKey ||
          selectedCustomEventValues?.every((val) => val.customEventKey !== opt.customEventKey)
      )
      .map((opt) => opt.customEventKey);

  const getAvailableProductDataOptions = (attr: ProductDataAttributeValue) => {
    return productDataSelectorOptions.filter((opt) => {
      // Option is the currently selected attribute
      if (optionMatchesAttribute(opt, attr)) return true;

      // Bazaarvoice events do not currently support price
      if (isBazaarvoiceEvent && isPriceAttribute(opt.optionValue)) return false;

      // Check that option was not selected in other filter
      return productDataAttributeValues?.every((r) => !optionMatchesAttribute(opt, r));
    });
  };

  return (
    <>
      <Box css={{ flex: 1 }}>
        <ConditionInputWrapper>
          {children}
          {useHasVerb && (
            <VerbSelector
              verbType={
                filter === 'VISITED_SITE' ? VerbSelectorType.HAS_ONLY : VerbSelectorType.HAS
              }
              value={hasVerb || HasVerbType.HAS}
              onChange={(verb) =>
                onChange({
                  ...parameters,
                  hasVerb: verb as HasVerbType,
                  excludePrivacyOpens: undefined,
                })
              }
            />
          )}
          {isActionFilterType && (
            <FilterByActivitySelectorWrapper
              value={filter}
              customEventType={customEventOption?.customEventType}
              onChange={handleFilterChange}
              segmentOptions={segmentOptions}
              customPropertyDeleted={customPropertyDeleted}
              customPropertyDisplayName={customPropertyDisplayName}
              availableData={availableData}
              country={country}
              changeFilterType={changeFilterType}
            />
          )}
          {isAttributeFilterType && (
            <FilterByAttributeSelector
              value={filter}
              segmentableAttributesByVendor={segmentableAttributesByVendor}
              country={country}
              preferenceKey={subscriberPreferenceKey || ''}
              attribute={attribute}
              onChange={handleFilterChange}
              segmentOptions={segmentOptions}
              customPropertyDeleted={customPropertyDeleted}
              customPropertyDisplayName={customPropertyDisplayName}
              changeFilterType={changeFilterType}
            />
          )}
          {showPlainTextSource && vendor && (
            <SegmentConditionItemContainer>
              <Text>{`from ${getSentenceCase(VendorDisplayMap[vendor])}`}</Text>
            </SegmentConditionItemContainer>
          )}
          {isEmailEvent && subscriberActionSource && emailEventSourceOptions.length > 0 && (
            <EmailSourceSelector
              value={subscriberActionSource}
              options={emailEventSourceOptions}
              onChange={(source) =>
                onChange({
                  ...omit(parameters, [...MESSAGE_FILTER_PARAMETERS, 'excludePrivacyOpens']),
                  subscriberActionSource: source,
                })
              }
            />
          )}
          {isSegmentMembershipFilterType && (
            <SegmentConditionItemContainer>
              <Text>Subscriber</Text>
            </SegmentConditionItemContainer>
          )}
          {useIsVerb && (
            <VerbSelector
              verbType={VerbSelectorType.IS}
              value={isVerb || IsVerbType.IS}
              onChange={(verb) => onChange({ ...parameters, isVerb: verb as IsVerbType })}
              disabled={customPropertyDeleted}
            />
          )}
          {isRechargeSubscriptionStatus && (
            <RechargeSubscriptionStatusSelector parameters={parameters} onChange={onChange} />
          )}
          {isSegmentMembershipFilterType && (
            <SegmentConditionItemContainer>
              <Text>in</Text>
            </SegmentConditionItemContainer>
          )}
          {isSegmentMembershipFilterType && (
            <FilterBySegmentSelector
              value={tagKey || subscriberListMetadataId || undefined}
              onChange={(filterParams: {}) =>
                onChange({
                  ...parameters,
                  ...filterParams,
                  isVerb: isVerb || IsVerbType.IS,
                })
              }
              segmentOptions={segmentOptions}
            />
          )}
          {isSiteFilter && (
            <SiteSelector
              values={{ propertyAttributeCondition, pattern } as unknown as SiteConditionValue}
              onChange={(value) => {
                onChange({
                  ...omit(parameters, 'pattern'),
                  propertyAttributeCondition: value.propertyAttributeCondition,
                  pattern: value.pattern,
                });
              }}
            />
          )}
          {isTextKeywordFilter && <KeywordSelector onChange={onChange} parameters={parameters} />}
          {isRechargeTimestamp && (
            <RechargeTimestampSelector onChange={onChange} parameters={parameters} />
          )}
          {isBooleanFilter && (
            <BooleanSelector
              textValue={textValue}
              onChange={(newTextValue: string) =>
                onChange({ ...parameters, textValue: newTextValue })
              }
            />
          )}
          {useFrequency && (
            <FrequencySelector
              values={{ frequencyComparator, frequency }}
              onChange={(value) =>
                onChange({
                  ...omit(parameters, 'frequency'),
                  frequencyComparator: value.frequencyComparator,
                  frequency: value.frequency,
                })
              }
            />
          )}
          {useQuantitySelector && (
            <QuantitySelector
              values={{ quantityComparator, quantity, quantityEnd }}
              type={getQuantityType(`${vendor}:${profileAttribute}`)}
              comparatorLabelOverrides={isRechargeSubscriptionStatus ? { EQUAL_TO: 'Exactly' } : {}}
              onChange={(value) => {
                onChange({
                  ...omit(parameters, ['quantity', 'quantityEnd']),
                  quantityComparator: value.quantityComparator,
                  quantity: value.quantity,
                  quantityEnd: value.quantityEnd,
                });
              }}
            />
          )}
          {isRechargeSubscriptionStatus && (
            <SegmentConditionItemContainer>
              <Text>subscription(s)</Text>
            </SegmentConditionItemContainer>
          )}
          {useTimeSelector && (
            <TimeSelector
              parameters={parameters}
              allowPartialDateComparison={usesPartialDate(parameters, segmentFilterType)}
              onChange={(timeValues) =>
                onChange({
                  ...omit(parameters, TIME_PARAMETERS),
                  ...timeValues,
                })
              }
            />
          )}
          {!isActionFilter && isSourceFilter && (
            <SourceSelector
              values={
                {
                  propertyAttribute,
                  propertyAttributeCondition,
                  fieldValues,
                } as unknown as SourceValue
              }
              creatives={creatives}
              keywords={companyCodes}
              onChange={(sourceValues) =>
                onChange({
                  ...omit(parameters, ['fieldValues', 'propertyAttributeCondition']),
                  ...sourceValues,
                })
              }
              subscriberActionChannel={subscriberActionChannel}
            />
          )}

          {isLocationFilter && (
            <StateSelector
              countries={demographicOptions.countries}
              country={country}
              values={{ locationValues, stateId: `${stateId}`, countryId }}
              states={demographicOptions.states}
              locationType={`${filter}` as LocationType}
              onChange={(value) => {
                const newValues = {
                  ...parameters,
                  countryId: value.countryId,
                  stateId: value.stateId,
                  locationValues: value.locationValues,
                };

                // Check to see if an old locationValue is set on parameters and set to null
                // Submitting null will delete locationValue from this condition on the backend
                if (parameters.locationValue) {
                  newValues.locationValue = null;
                }

                onChange(newValues);
              }}
            />
          )}
          {isNewLocationFilter && (
            <ZipcodeSelector
              onChange={(value) => {
                const newParameters = { ...parameters };

                if (value.zipCodes !== undefined) {
                  newParameters.locations = value.zipCodes;
                }
                if (value.countryId) {
                  newParameters.countryId = value.countryId;
                }
                onChange(newParameters);
              }}
              countryId={countryId && `${countryId}`}
              value={locations}
              country={country}
              countries={demographicOptions.countries}
            />
          )}
          {isDistanceFilter && (
            <Distance
              onChange={(newDistanceValues) => onChange({ ...parameters, ...newDistanceValues })}
              origin={origin}
              radius={radius}
              distanceUnit={distanceUnit}
              country={country}
            />
          )}
          {isCountryFilter && (
            <Country
              countries={demographicOptions.countries}
              locationValues={locationValues}
              onChange={(countries) => onChange({ ...parameters, locationValues: countries })}
            />
          )}
          {isSubscriberPreference && (
            <PreferenceSelector
              value={subscriberPreferenceValue || ''}
              preferences={preferenceOptions}
              onChange={(changedValue) =>
                onChange({ ...parameters, subscriberPreferenceValue: changedValue })
              }
            />
          )}
          {isBazaarvoiceEvent && (
            <BazaarvoiceSelector onChange={onChange} parameters={parameters} />
          )}
          {useCustomAttributeText && attribute && customAttributeType && userPropertyValues && (
            <CustomPropertySelector
              type={customAttributeType}
              selectedValues={userPropertyValues}
              optionValues={customAttributeOptionValues}
              showValidation={showValidation}
              onChange={(newValues: CustomAttributeOptionValue[]) =>
                onChange({ ...parameters, userPropertyValues: newValues })
              }
              disabled={customPropertyDeleted}
              attribute={attribute}
            />
          )}
          {profileAttribute && isShopifyDynamicValueAttribute && (
            <ShopifyDynamicValueSelector
              options={
                shopifyOptions[shopifySelectorsOptionsMap[`${Vendor.SHOPIFY}:${profileAttribute}`]]
              }
              hasError={Boolean(profileAttrError)}
              onChange={onChange}
              parameters={parameters}
            />
          )}
          {/* TODO: sc - this is a workaround until list values are properly formatted. When they are, free text inputs will be changed to multi-value selectors */}
          {profileAttribute && isPunchhSingleValueAttribute && (
            <Box>
              <TextInput
                size="small"
                value={textValues && textValues[0]}
                placeholder="Enter a value"
                onChange={(e) =>
                  onChange({
                    ...parameters,
                    textValues: e.target.value ? [e.target.value] : [],
                  })
                }
              />
            </Box>
          )}
          {profileAttribute && vendor && useCustomVendorAttr && (
            <VendorAttrSelector
              textValues={textValues}
              onChange={(newValues) => onChange({ ...parameters, textValues: newValues })}
              attr={profileAttribute}
              vendor={vendor}
            />
          )}
          {isKlaviyoCustomAttribute && (
            <KlaviyoProfileAttrSelector parameters={parameters} onChange={onChange} />
          )}
          {isCarrier && (
            <OptionSelector
              value={carrier || ''}
              options={carriers}
              conditionName="carrier"
              onChange={(value: string) => onChange({ ...parameters, carrier: value })}
            />
          )}
          {isBirthdayMonth && (
            <OptionSelector
              value={quantity ? String(quantity) : ''}
              options={birthdayMonthOptions}
              conditionName="birthday month"
              onChange={(value: string) => onChange({ ...parameters, quantity: Number(value) })}
            />
          )}
          {isMailExchangeFilter && (
            <WellKnownPropertySelector
              selectedValues={wellKnownUserPropertyValues || []}
              options={wellKnownMailExchangeValues}
              onChange={(results) =>
                onChange({ ...parameters, wellKnownUserPropertyValues: results })
              }
              propertyDisplayName="mailbox provider"
            />
          )}
          {isWellKnownCountryFilter && (
            <WellKnownPropertySelector
              selectedValues={wellKnownUserPropertyValues || []}
              options={wellKnownCountryValues}
              onChange={(results) =>
                onChange({ ...parameters, wellKnownUserPropertyValues: results })
              }
              propertyDisplayName="countries"
              isCountryProperty
            />
          )}
          {isLanguage && languageFilter && (
            <LanguageSelector
              languageFilter={languageFilter}
              languageOptions={languages}
              onChange={onChange}
              parameters={parameters}
            />
          )}
          {isDeviceType && (
            <FormField>
              <MultiSelect
                value={deviceTypes || []}
                onChange={(vals: string[]) => onChange({ ...parameters, deviceTypes: vals })}
                size="small"
                maxTokens={10}
                placeholder="Choose values"
                state={deviceTypeError ? 'error' : 'normal'}
              >
                {deviceTypeOptions.map(({ displayName, optionValue }) => (
                  <MultiSelect.Item key={optionValue} value={optionValue}>
                    {displayName}
                  </MultiSelect.Item>
                ))}
              </MultiSelect>
              {deviceTypeError && (
                <FormField.ErrorText>Please choose device types</FormField.ErrorText>
              )}
            </FormField>
          )}
          {isOperatingSystem && (
            <FormField>
              <MultiSelect
                value={deviceOSList || []}
                onChange={(vals: string[]) => onChange({ ...parameters, deviceOSList: vals })}
                size="small"
                maxTokens={10}
                placeholder="Choose values"
                state={operatingSystemError ? 'error' : 'normal'}
              >
                {operatingSystems.map(({ displayName, optionValue }) => (
                  <MultiSelect.Item key={optionValue} value={optionValue}>
                    {displayName}
                  </MultiSelect.Item>
                ))}
              </MultiSelect>
              {operatingSystemError && (
                <FormField.ErrorText>Please choose operating systems</FormField.ErrorText>
              )}
            </FormField>
          )}
        </ConditionInputWrapper>
        {selectedCustomEventValues &&
          selectedCustomEventValues.map((selectedValue, selectedValueIdx) => {
            const availableOptions = getAvailableCustomEventValueOptions(selectedValue);
            const key = `custom-event-${selectedValue.customEventKey}-${selectedValueIdx}`;
            const isFirst = selectedValueIdx === 0;
            return (
              <CustomEventCondition
                key={key}
                isFirst={isFirst}
                customEventValue={selectedValue}
                customEventKeyOptions={availableOptions || []}
                onChange={(val) => updateCustomEventValueRow(selectedValueIdx, val)}
                onDelete={() => deleteCustomEventValueRow(selectedValueIdx)}
                css={{ mb: '$space4' }}
              />
            );
          })}
        {productDataAttributeValues &&
          productDataAttributeValues.map((attr, attrIdx) => {
            const availableOptions = getAvailableProductDataOptions(attr);
            const key = `product-${attr.attribute}-${attrIdx}`;
            const isFirst = attrIdx === 0;
            return (
              <ProductDataSegmentCondition
                key={key}
                setDialogIsOpen={setDialogIsOpen}
                isFirst={isFirst}
                productDataAttributeValue={attr}
                attributeOptions={availableOptions}
                onChange={(params) => updateProductDataRow(attrIdx, params)}
                onDelete={() => deleteProductDataRow(attrIdx)}
                css={{ mb: '$space4' }}
              />
            );
          })}
        {salesChannelValue && (
          <SalesChannelCondition
            salesChannelValue={salesChannelValue}
            onChange={updateSalesChannelRow}
            onRemove={deleteSalesChannelRow}
            isFirst={!productDataAttributeValues?.length}
            css={{ mb: '$space4' }}
          />
        )}
        {(msgCondition || thirdPartyMsgCondition || journeyMsgCondition) && (
          <MessageSegmentCondition
            parameters={parameters}
            onChange={onChange}
            setDialogIsOpen={setDialogIsOpen}
            selectableCampaignType={getSelectedCampaignType()}
            css={{ mb: '$space4' }}
          />
        )}
        {excludePrivacyOpens !== undefined && (
          <MppSegmentCondition
            excludePrivacyOpens={excludePrivacyOpens}
            onChangeExcludePrivacyOpens={updateMppRow}
            onRemove={deleteMppRow}
            css={{ mb: '$space4' }}
          />
        )}
        {showRechargeChurnReasonCondition && (
          <RechargeChurnReasonSelector
            parameters={parameters}
            onChange={onChange}
            css={{ mb: '$space4' }}
            rechargeAttributes={segmentableAttributesByVendor.VENDOR_RECHARGE || []}
          />
        )}
        <SegmentConditionDebug component={component} />
      </Box>
      <Box>
        <SegmentFilterButton
          parameters={parameters}
          onChange={(params, filterType) => {
            if (filterType) {
              onClickFilterButton(filterType);
            }
            onChange(params);
          }}
          disableProductFilters={
            (productDataAttributeValues || []).length >= productDataSelectorOptions.length
          }
          disableCustomEventFilters={
            (selectedCustomEventValues || []).length >= customEventValueOptions.length
          }
        />
      </Box>
    </>
  );
};
