import { useCompanyFeatureFlag } from '@attentive/acore-utils';

export enum SubscriberChannel {
  ALL = 'ALL',
  TEXT = 'TEXT',
  EMAIL = 'EMAIL',
}

export const SubscriberChannels = Object.keys(SubscriberChannel);

export const useDefaultChannel = () => {
  const enableEmail = useCompanyFeatureFlag('ENABLE_TRIGGERED_EMAIL');
  const isEmailOnly = useCompanyFeatureFlag('ENABLE_EMAIL_ONLY_COMPANY');

  if (!enableEmail) {
    return SubscriberChannel.TEXT;
  }
  if (isEmailOnly) {
    return SubscriberChannel.EMAIL;
  }
  return SubscriberChannel.ALL;
};
