/**
 * @generated SignedSource<<60295298c0416ed3b08542b7d5cd3873>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SegmentExportDialog_TargetingCommon_Query$variables = {
  companyId: string;
};
export type SegmentExportDialog_TargetingCommon_Query$data = {
  readonly company: {
    readonly internalId?: number;
    readonly " $fragmentSpreads": FragmentRefs<"RecipientsMultiSelectAdapter_ReportingPlatform_company">;
  };
};
export type SegmentExportDialog_TargetingCommon_Query$rawResponse = {
  readonly company: {
    readonly __typename: "Company";
    readonly id: string;
    readonly internalId: number;
    readonly possibleReportRecipients: ReadonlyArray<{
      readonly account: {
        readonly email: string;
        readonly id: string;
      } | null;
    }> | null;
  } | {
    readonly __typename: string;
    readonly id: string;
  } | null;
};
export type SegmentExportDialog_TargetingCommon_Query = {
  rawResponse: SegmentExportDialog_TargetingCommon_Query$rawResponse;
  response: SegmentExportDialog_TargetingCommon_Query$data;
  variables: SegmentExportDialog_TargetingCommon_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "internalId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SegmentExportDialog_TargetingCommon_Query",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": "company",
          "args": (v1/*: any*/),
          "concreteType": null,
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                (v2/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "RecipientsMultiSelectAdapter_ReportingPlatform_company"
                }
              ],
              "type": "Company",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "company"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SegmentExportDialog_TargetingCommon_Query",
    "selections": [
      {
        "alias": "company",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ReportRecipient",
                "kind": "LinkedField",
                "name": "possibleReportRecipients",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "account",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Company",
            "abstractKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "01a3bf711ac3cbd7b485547e4ea15852",
    "id": null,
    "metadata": {},
    "name": "SegmentExportDialog_TargetingCommon_Query",
    "operationKind": "query",
    "text": "query SegmentExportDialog_TargetingCommon_Query(\n  $companyId: ID!\n) {\n  company: node(id: $companyId) {\n    __typename\n    ... on Company {\n      internalId\n      ...RecipientsMultiSelectAdapter_ReportingPlatform_company\n    }\n    id\n  }\n}\n\nfragment RecipientsMultiSelectAdapter_ReportingPlatform_company on Company {\n  ...RecipientsMultiSelect_ReportingPlatform_company\n  possibleReportRecipients {\n    account {\n      id\n      email\n    }\n  }\n}\n\nfragment RecipientsMultiSelect_ReportingPlatform_company on Company {\n  possibleReportRecipients {\n    account {\n      id\n      email\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2c1c414ec560b6861d60d34c0c1d8f34";

export default node;
