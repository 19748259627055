import React, { useContext } from 'react';

import { CurrencyCode, Locale } from '@attentive/locale-utils';
import {
  Box,
  Card,
  compositeComponent,
  ContainedLabel,
  Heading,
  PicnicCss,
  Text,
  Stack,
  StackProps,
  styled,
  Tooltip,
} from '@attentive/picnic';

import {
  calculatePercentChange,
  displayPercentChange,
  formatDisplayValue,
  getPillVariant,
} from '../../utils';

const HEADER_TOOLTIP_CSS = {
  width: 'fit-content',
  textDecoration: 'underline dashed',
  textDecorationThickness: '2px',
  textUnderlineOffset: '2px',
};

const PillContent = styled(ContainedLabel, {
  height: '$size4',
  alignSelf: 'center',
  variants: {
    size: {
      medium: {
        marginLeft: '$space3',
      },
      small: {
        marginLeft: '$space2',
      },
    },
  },
  defaultVariants: {
    size: 'medium',
  },
});

type MetricCardContextType = {
  size: 'medium' | 'small';
  value?: number;
  comparisonValue?: number;
  dataType?: 'number' | 'percent' | 'currency';
  locale?: Locale;
  currencyCode?: CurrencyCode;
};

const MetricCardContext = React.createContext<MetricCardContextType | undefined>(undefined);

const useMetricCardContext = () => {
  const context = useContext(MetricCardContext);

  if (!context) {
    throw new Error('useMetricCardContext must be used within a MetricCardContextProvider');
  }

  return context;
};

const Header = ({
  description,
  children,
}: {
  description?: string;
  children?: React.ReactNode;
}) => {
  const { size } = useMetricCardContext();
  const isMedium = size === 'medium';
  const css = description ? HEADER_TOOLTIP_CSS : {};

  if (description) {
    return (
      <Tooltip>
        <Tooltip.Trigger>
          {isMedium ? (
            <Heading variant="sm" css={css}>
              {children}
            </Heading>
          ) : (
            <Text variant="micro" css={{ fontWeight: '$bold', ...css }}>
              {children}
            </Text>
          )}
        </Tooltip.Trigger>
        <Tooltip.Content>{description}</Tooltip.Content>
      </Tooltip>
    );
  }

  return isMedium ? (
    <Heading variant="sm" css={css}>
      {children}
    </Heading>
  ) : (
    <Text variant="micro" css={{ fontWeight: '$bold', ...css }}>
      {children}
    </Text>
  );
};

const Value = ({ children, css }: { children?: React.ReactNode; css?: PicnicCss }) => {
  const { size, value: rawValue, dataType, locale, currencyCode } = useMetricCardContext();
  const displayValue = formatDisplayValue({ value: rawValue, dataType, locale, currencyCode });
  const variant = size === 'medium' ? 'lg' : 'sm';

  return (
    <Heading variant={variant} css={css}>
      {children || displayValue}
    </Heading>
  );
};

const Pill = ({
  variant,
  children,
  css,
}: {
  variant?: 'success' | 'critical' | 'neutral';
  children?: React.ReactNode;
  css?: PicnicCss;
}) => {
  const { size } = useMetricCardContext();

  return (
    <PillContent
      variant={variant}
      size={size}
      css={{ height: '$size4', alignSelf: 'center', ...css }}
    >
      {children}
    </PillContent>
  );
};

const Section = ({
  spacing,
  children,
  css,
}: {
  spacing?: StackProps['spacing'];
  children?: React.ReactNode;
  css?: PicnicCss;
}) => {
  const { size } = useMetricCardContext();
  const sectionSpacing = spacing || (size === 'medium' ? '$space3' : '$space2');

  return (
    <Stack direction="horizontal" spacing={sectionSpacing} css={{ alignItems: 'center', ...css }}>
      {children}
    </Stack>
  );
};

const PercentChange = ({
  connotation,
  error,
}: {
  connotation: 'positive' | 'negative' | 'neutral';
  error?: string;
}) => {
  const { value, comparisonValue, locale } = useMetricCardContext();
  const percentChange = calculatePercentChange({ value, comparisonValue });

  if (!percentChange) {
    return (
      <Tooltip>
        <Tooltip.Trigger>
          <Pill variant="neutral">-%</Pill>
        </Tooltip.Trigger>
        <Tooltip.Content>
          <Text>{error}</Text>
        </Tooltip.Content>
      </Tooltip>
    );
  }

  const displayValue = displayPercentChange({ percentChange, locale });
  const variant = getPillVariant({ percentChange, connotation });

  return <Pill variant={variant}>{displayValue}</Pill>;
};

const Subtext = ({ children, css }: { children?: React.ReactNode; css?: PicnicCss }) => {
  const { size } = useMetricCardContext();
  const variant = size === 'medium' ? 'caption' : 'micro';

  return (
    <Text variant={variant} color="subdued" css={css}>
      {children}
    </Text>
  );
};

const ComparisonValue = () => {
  const { comparisonValue, dataType, locale, currencyCode } = useMetricCardContext();
  const displayValue = formatDisplayValue({
    value: comparisonValue,
    dataType,
    locale,
    currencyCode,
  });

  return <Subtext>{displayValue}</Subtext>;
};

const MetricCard = ({
  variant = 'card',
  size = 'medium',
  children,
  css,
  ...props
}: {
  variant?: 'card' | 'inline';
  size?: 'medium' | 'small';
  value?: number;
  comparisonValue?: number;
  dataType?: 'number' | 'percent' | 'currency';
  locale?: Locale;
  currencyCode?: CurrencyCode;
  children?: React.ReactNode;
  css?: PicnicCss;
}) => {
  const Content = variant === 'card' ? Card : Box;

  return (
    <MetricCardContext.Provider value={{ size, ...props }}>
      <Content css={{ padding: '$space4', ...css }}>{children}</Content>
    </MetricCardContext.Provider>
  );
};

const Namespace = compositeComponent(MetricCard, {
  Header,
  Value,
  Pill,
  Section,
  Subtext,
  PercentChange,
  ComparisonValue,
});

export { Namespace as MetricCard };
