/**
 * @generated SignedSource<<fa5867019bc7ad54a7bf8a56ec15d826>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SegmentDetailsPageEntrypoint_SegmentationUI_Query$variables = {
  companyId: string;
};
export type SegmentDetailsPageEntrypoint_SegmentationUI_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SegmentDetails_SegmentationUI_Query">;
};
export type SegmentDetailsPageEntrypoint_SegmentationUI_Query$rawResponse = {
  readonly company: {
    readonly __typename: "Company";
    readonly defaultRegion: {
      readonly id: string;
      readonly timezone: string;
    } | null;
    readonly id: string;
  } | {
    readonly __typename: string;
    readonly id: string;
  } | null;
};
export type SegmentDetailsPageEntrypoint_SegmentationUI_Query = {
  rawResponse: SegmentDetailsPageEntrypoint_SegmentationUI_Query$rawResponse;
  response: SegmentDetailsPageEntrypoint_SegmentationUI_Query$data;
  variables: SegmentDetailsPageEntrypoint_SegmentationUI_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SegmentDetailsPageEntrypoint_SegmentationUI_Query",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "SegmentDetails_SegmentationUI_Query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SegmentDetailsPageEntrypoint_SegmentationUI_Query",
    "selections": [
      {
        "alias": "company",
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "companyId"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Region",
                "kind": "LinkedField",
                "name": "defaultRegion",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "timezone",
                    "storageKey": null
                  },
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Company",
            "abstractKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "510fb0e2cd03f57367f4a2606ac68571",
    "id": null,
    "metadata": {},
    "name": "SegmentDetailsPageEntrypoint_SegmentationUI_Query",
    "operationKind": "query",
    "text": "query SegmentDetailsPageEntrypoint_SegmentationUI_Query(\n  $companyId: ID!\n) {\n  ...SegmentDetails_SegmentationUI_Query\n}\n\nfragment SegmentDetails_SegmentationUI_Query on Query {\n  company: node(id: $companyId) {\n    __typename\n    ... on Company {\n      defaultRegion {\n        timezone\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "19630498f0a4afbbba6893a6777869ac";

export default node;
