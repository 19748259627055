import { Locale } from '@attentive/locale-utils';

import { formatDisplayPercent } from '../formatDisplay';

export const calculatePercentChange = ({
  value,
  comparisonValue,
}: {
  value?: number;
  comparisonValue?: number;
}) => {
  if (value === undefined) return null;
  if (comparisonValue === undefined || comparisonValue === 0) return null;

  return (value - comparisonValue) / comparisonValue;
};

export const displayPercentChange = ({
  percentChange,
  locale,
}: {
  percentChange?: number;
  locale?: Locale;
}) => {
  if (percentChange === undefined) return '';

  return formatDisplayPercent({
    value: percentChange,
    locale,
    signDisplay: 'always',
  });
};

export const getPillVariant = ({
  percentChange,
  connotation,
}: {
  percentChange?: number;
  connotation?: 'positive' | 'negative' | 'neutral';
}) => {
  if (
    percentChange === undefined ||
    percentChange === 0 ||
    connotation === undefined ||
    connotation === 'neutral'
  )
    return 'neutral';

  const modifier = connotation === 'negative' ? -1 : 1;
  return modifier * percentChange > 0 ? 'success' : 'critical';
};
