/**
 * @generated SignedSource<<d38e67362d9b33444231ff96a732e92c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AutoCampaignFilter = "AUTO_CAMPAIGN_FILTER_ALL" | "AUTO_CAMPAIGN_FILTER_AUTO" | "AUTO_CAMPAIGN_FILTER_MANUAL" | "AUTO_CAMPAIGN_FILTER_UNKNOWN" | "%future added value";
export type CampaignMessageGroupStatus = "CAMPAIGN_MESSAGE_GROUP_STATUS_ATTENTIVE_REVIEW" | "CAMPAIGN_MESSAGE_GROUP_STATUS_CANCELLED" | "CAMPAIGN_MESSAGE_GROUP_STATUS_DRAFT" | "CAMPAIGN_MESSAGE_GROUP_STATUS_NEEDS_COUPONS" | "CAMPAIGN_MESSAGE_GROUP_STATUS_PAUSED" | "CAMPAIGN_MESSAGE_GROUP_STATUS_RESCHEDULED" | "CAMPAIGN_MESSAGE_GROUP_STATUS_SCHEDULED" | "CAMPAIGN_MESSAGE_GROUP_STATUS_SCHEDULED_DRAFT" | "CAMPAIGN_MESSAGE_GROUP_STATUS_SENDING" | "CAMPAIGN_MESSAGE_GROUP_STATUS_SEND_ERROR" | "CAMPAIGN_MESSAGE_GROUP_STATUS_SENT" | "CAMPAIGN_MESSAGE_GROUP_STATUS_UNKNOWN" | "CAMPAIGN_MESSAGE_GROUP_STATUS_UNSAVED_DRAFT" | "%future added value";
export type CampaignMessageGroupType = "CAMPAIGN_MESSAGE_GROUP_TYPE_EXPERIMENT" | "CAMPAIGN_MESSAGE_GROUP_TYPE_ONE_TIME" | "CAMPAIGN_MESSAGE_GROUP_TYPE_SEND_TIME_EXPERIMENT" | "CAMPAIGN_MESSAGE_GROUP_TYPE_UNKNOWN" | "%future added value";
export type ComposeMessageChannel = "COMPOSE_MESSAGE_CHANNEL_EMAIL" | "COMPOSE_MESSAGE_CHANNEL_RBM" | "COMPOSE_MESSAGE_CHANNEL_TEXT" | "COMPOSE_MESSAGE_CHANNEL_UNKNOWN" | "%future added value";
export type ListComposeCampaignUiFilter = "LIST_COMPOSE_CAMPAIGN_UI_FILTER_DELIVERED" | "LIST_COMPOSE_CAMPAIGN_UI_FILTER_DRAFT" | "LIST_COMPOSE_CAMPAIGN_UI_FILTER_NONE" | "LIST_COMPOSE_CAMPAIGN_UI_FILTER_PAUSED" | "LIST_COMPOSE_CAMPAIGN_UI_FILTER_SCHEDULED" | "LIST_COMPOSE_CAMPAIGN_UI_FILTER_UNKNOWN" | "%future added value";
export type MessagingServiceProvider = "MESSAGING_SERVICE_PROVIDER_OPEN_MARKET" | "MESSAGING_SERVICE_PROVIDER_SINCH" | "MESSAGING_SERVICE_PROVIDER_TWILIO" | "MESSAGING_SERVICE_PROVIDER_UNKNOWN" | "MESSAGING_SERVICE_PROVIDER_ZIPWHIP" | "%future added value";
export type ComposeCampaignsFilter = {
  autoCampaignFilter?: AutoCampaignFilter | null;
  campaignId?: ReadonlyArray<string> | null;
  campaignMessageGroupIds?: ReadonlyArray<string> | null;
  campaignMessageGroupStatuses?: ReadonlyArray<CampaignMessageGroupStatus> | null;
  composeMessageIds?: ReadonlyArray<string> | null;
  dateRange?: DateTimeRange | null;
  isHidden?: boolean | null;
  listComposeUiFilter?: ReadonlyArray<ListComposeCampaignUiFilter> | null;
  messageChannels?: ReadonlyArray<ComposeMessageChannel> | null;
  name?: string | null;
  tagUuids?: ReadonlyArray<string> | null;
  textMessagingService?: TextMessagingService | null;
  types?: ReadonlyArray<CampaignMessageGroupType> | null;
};
export type DateTimeRange = {
  end: SerializedDateTime;
  start: SerializedDateTime;
};
export type TextMessagingService = {
  messagingServiceProvider?: MessagingServiceProvider | null;
  sourcePhone?: string | null;
};
export type usePaginatedCampaigns_TargetingCommon_Query_refetchable$variables = {
  after?: string | null;
  companyId?: string | null;
  filter?: ComposeCampaignsFilter | null;
  first: number;
};
export type usePaginatedCampaigns_TargetingCommon_Query_refetchable$data = {
  readonly " $fragmentSpreads": FragmentRefs<"usePaginatedCampaigns_TargetingCommon_Query">;
};
export type usePaginatedCampaigns_TargetingCommon_Query_refetchable = {
  response: usePaginatedCampaigns_TargetingCommon_Query_refetchable$data;
  variables: usePaginatedCampaigns_TargetingCommon_Query_refetchable$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  }
],
v1 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v2 = {
  "kind": "Variable",
  "name": "filter",
  "variableName": "filter"
},
v3 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v4 = [
  (v1/*: any*/),
  {
    "kind": "Variable",
    "name": "companyId",
    "variableName": "companyId"
  },
  (v2/*: any*/),
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "usePaginatedCampaigns_TargetingCommon_Query_refetchable",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "usePaginatedCampaigns_TargetingCommon_Query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "usePaginatedCampaigns_TargetingCommon_Query_refetchable",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "ComposeCampaignsConnection",
        "kind": "LinkedField",
        "name": "composeCampaigns",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ComposeCampaignEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ComposeCampaign",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "campaignStatus",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "updated",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Company",
                    "kind": "LinkedField",
                    "name": "company",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "timezone",
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      (v2/*: any*/)
                    ],
                    "concreteType": "CampaignMessageGroupsConnection",
                    "kind": "LinkedField",
                    "name": "campaignMessageGroups",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CampaignMessageGroupEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CampaignMessageGroup",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v6/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "CampaignMessageMember",
                                "kind": "LinkedField",
                                "name": "messageMembers",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ComposeMessage",
                                    "kind": "LinkedField",
                                    "name": "composeMessage",
                                    "plural": false,
                                    "selections": [
                                      (v6/*: any*/),
                                      {
                                        "alias": "messageId",
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "internalId",
                                        "storageKey": null
                                      },
                                      (v5/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v5/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "status",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "startTime",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "scheduleType",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "filters": [
          "companyId",
          "filter"
        ],
        "handle": "connection",
        "key": "usePaginatedCampaigns_TargetingCommon_Query_composeCampaigns",
        "kind": "LinkedHandle",
        "name": "composeCampaigns"
      }
    ]
  },
  "params": {
    "cacheID": "d56fb53026054ed53abcd4b15dab0bdf",
    "id": null,
    "metadata": {},
    "name": "usePaginatedCampaigns_TargetingCommon_Query_refetchable",
    "operationKind": "query",
    "text": "query usePaginatedCampaigns_TargetingCommon_Query_refetchable(\n  $after: String = null\n  $companyId: ID\n  $filter: ComposeCampaignsFilter\n  $first: Int!\n) {\n  ...usePaginatedCampaigns_TargetingCommon_Query_G9cLv\n}\n\nfragment usePaginatedCampaigns_TargetingCommon_Query_G9cLv on Query {\n  composeCampaigns(companyId: $companyId, first: $first, after: $after, filter: $filter) {\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      cursor\n      node {\n        id\n        name\n        campaignStatus\n        updated\n        company {\n          timezone\n          id\n        }\n        campaignMessageGroups(filter: $filter) {\n          edges {\n            node {\n              id\n              name\n              messageMembers {\n                composeMessage {\n                  name\n                  messageId: internalId\n                  id\n                }\n                id\n              }\n              status\n              startTime\n              scheduleType\n            }\n          }\n        }\n        __typename\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4cbd15f023daf09e284117634105ca5e";

export default node;
