import { CurrencyCode, Locale } from '@attentive/locale-utils';

const METRIC_MISSING_DATA_SYMBOL = '-';
const MINIMUM_ROUNDED_VALUE = 0.01;
const MINIMUM_PERCENT_VALUE = 0.1;
const PERCENT_MODIFIER = 100;

export const isNegative = (value: number): boolean => value < 0;

export const isBelowDisplayThreshold = ({
  value,
  roundedValue,
}: {
  value: number;
  roundedValue: number;
}): boolean => roundedValue === 0 && value !== 0;

export const roundToDecimals = ({
  value,
  maxDecimalPlaces,
}: {
  value: number;
  maxDecimalPlaces: number;
}): number => {
  const multiplier = 10 ** maxDecimalPlaces;
  return Math.round(value * multiplier) / multiplier;
};

export const buildFormatter = ({
  locale,
  style,
  currencyCode,
  minDecimalPlaces,
  maxDecimalPlaces,
  signDisplay,
}: {
  locale?: Locale;
  style?: Intl.NumberFormatOptions['style'];
  currencyCode?: CurrencyCode;
  minDecimalPlaces?: number;
  maxDecimalPlaces?: number;
  signDisplay?: Intl.NumberFormatOptions['signDisplay'];
}) => {
  return new Intl.NumberFormat(locale, {
    style,
    currency: currencyCode,
    minimumFractionDigits: minDecimalPlaces,
    maximumFractionDigits: maxDecimalPlaces,
    signDisplay,
  });
};

export const applyDisplayFormat = ({
  value,
  roundedValue,
  minimumValue = MINIMUM_ROUNDED_VALUE,
  formatValue,
}: {
  value: number;
  roundedValue: number;
  minimumValue?: number;
  formatValue: (value: number) => string;
}): string => {
  // Display very small numbers as "< 0.01" or "< -0.01"
  if (isBelowDisplayThreshold({ value, roundedValue })) {
    const signedMinimumValue = isNegative(value) ? -minimumValue : minimumValue;
    const minimumDisplayValue = formatValue(signedMinimumValue);
    return `< ${minimumDisplayValue}`;
  }

  return formatValue(value);
};

// Format whole numbers as integers (no decimals)
// For non-whole numbers, display up to 2 decimal places
export const formatDisplayNumber = ({
  value,
  locale = Locale.enUS,
}: {
  value: number;
  locale?: Locale;
}): string => {
  const roundedValue = roundToDecimals({ value, maxDecimalPlaces: 2 });
  const formatValue = buildFormatter({
    locale,
    minDecimalPlaces: 0,
    maxDecimalPlaces: Number.isInteger(value) ? 0 : 2,
  }).format;

  return applyDisplayFormat({ value, roundedValue, formatValue });
};

// Round percentages to whole numbers for simplicity
// If the value is between 0% and 1%, show 1 decimal place
export const formatDisplayPercent = ({
  value,
  locale = Locale.enUS,
  signDisplay = 'auto',
}: {
  value: number; // Non-adjusted value (ex. 0.01 instead of 1 for 1%)
  locale?: Locale;
  signDisplay?: Intl.NumberFormatOptions['signDisplay'];
}): string => {
  const adjustedValue = value * PERCENT_MODIFIER;
  const roundedValue = roundToDecimals({ value: adjustedValue, maxDecimalPlaces: 1 });
  const isBelowThreshold = isBelowDisplayThreshold({ value: adjustedValue, roundedValue });
  const isBetweenZeroAndOne = roundedValue > 0 && roundedValue < 1;

  let maxDecimalPlaces = 0;
  if (isBelowThreshold) maxDecimalPlaces = 2; // To allow for "< 0.01"
  else if (isBetweenZeroAndOne) maxDecimalPlaces = 1;

  const formatValue = buildFormatter({
    locale,
    style: 'percent',
    minDecimalPlaces: 0,
    maxDecimalPlaces,
    signDisplay,
  }).format;

  return applyDisplayFormat({
    value,
    roundedValue,
    formatValue,
    minimumValue: MINIMUM_PERCENT_VALUE / PERCENT_MODIFIER,
  });
};

// Format whole numbers as integers (no decimals)
// Display non-whole numbers with exactly 2 decimal places
export const formatDisplayCurrency = ({
  value,
  locale = Locale.enUS,
  currencyCode = CurrencyCode.US,
}: {
  value: number;
  locale?: Locale;
  currencyCode?: CurrencyCode;
}): string => {
  const roundedValue = roundToDecimals({ value, maxDecimalPlaces: 2 });
  const decimalPlaces = Number.isInteger(value) ? 0 : 2;
  const formatValue = buildFormatter({
    locale,
    style: 'currency',
    currencyCode,
    minDecimalPlaces: decimalPlaces,
    maxDecimalPlaces: decimalPlaces,
  }).format;

  return applyDisplayFormat({ value, roundedValue, formatValue });
};

export const formatDisplayValue = ({
  value,
  dataType,
  locale = Locale.enUS,
  currencyCode = CurrencyCode.US,
  signDisplay = 'auto',
}: {
  value?: number;
  dataType?: 'number' | 'percent' | 'currency';
  locale?: Locale;
  currencyCode?: CurrencyCode;
  signDisplay?: Intl.NumberFormatOptions['signDisplay'];
}) => {
  if (value === undefined) return METRIC_MISSING_DATA_SYMBOL;
  if (dataType === 'number') return formatDisplayNumber({ value, locale });
  if (dataType === 'percent') return formatDisplayPercent({ value, locale, signDisplay });
  if (dataType === 'currency') return formatDisplayCurrency({ value, locale, currencyCode });

  return value.toString();
};
