/**
 * @generated SignedSource<<20ac31ef5605e9b26cdceeeb22fb88f6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type Feature = "BRIDGE_IDENTIFIERS" | "CUSTOMER_SERVICE_OPT_IN" | "DYNAMIC_COUPON_GENERATION" | "FEATURE_ADDITIONAL_SETUP_LINK" | "FEATURE_ATTENTIVE_LOYALTY_SIGNUP" | "FEATURE_AUTO_INSTALL_TAG" | "FEATURE_CREATE_MARKETING_EMAIL_SUBSCRIPTIONS" | "FEATURE_CREATE_MARKETING_SMS_SUBSCRIPTIONS" | "FEATURE_CREATE_TRANSACTIONAL_SUBSCRIPTIONS" | "FEATURE_EMAIL_OPT_OUT_SYNC" | "FEATURE_EXPORT_SEGMENTS" | "FEATURE_GENERATE_HOOK" | "FEATURE_INGEST_AD_DATA" | "FEATURE_INGEST_LEADS" | "FEATURE_INGEST_TAG_EVENTS" | "FEATURE_INGEST_USER_DATA" | "FEATURE_MARKETING_EMAIL_MESSAGE_SEND" | "FEATURE_POSTBACK_USER_ATTRIBUTES" | "FEATURE_POSTBACK_USER_DATA" | "FEATURE_POST_SUBSCRIBER_OPT_IN" | "FEATURE_POST_SUBSCRIBER_OPT_OUT" | "FEATURE_PURCHASE_PRODUCT" | "FEATURE_REFUND_SYNC" | "FEATURE_SETUP_REDIRECT_SOURCE" | "FEATURE_SUBSCRIPTION_MESSAGING" | "FEATURE_SURVEY_MESSAGE" | "FEATURE_SYNC_ABANDONED_CHECKOUTS" | "FEATURE_SYNC_ACCOUNT_PROPERTIES" | "FEATURE_SYNC_ATTENTIVE_TO_ESP_EMAIL_OPT_OUTS_VIA_SFTP" | "FEATURE_SYNC_EMAIL_CONSENT" | "FEATURE_SYNC_EMAIL_EVENTS" | "FEATURE_SYNC_EMAIL_SUBSCRIBERS" | "FEATURE_SYNC_ESP_TO_ATTENTIVE_EMAIL_OPT_OUTS_VIA_SFTP" | "FEATURE_SYNC_EVENTS_OUTBOUND" | "FEATURE_SYNC_LIST_COUNT" | "FEATURE_SYNC_LIST_IDENTIFIERS" | "FEATURE_SYNC_LOYALTY_EVENTS" | "FEATURE_SYNC_ORDER_EVENTS" | "FEATURE_SYNC_PRODUCT_COLLECTIONS" | "FEATURE_SYNC_PURCHASES" | "FEATURE_SYNC_RECURRING_PURCHASES" | "FEATURE_SYNC_REVIEWS" | "FEATURE_SYNC_SMS_CONSENT" | "FEATURE_SYNC_THIRD_PARTY_CAMPAIGNS" | "FEATURE_SYNC_THIRD_PARTY_JOURNEYS" | "FEATURE_SYNC_USER_PROFILES" | "FEATURE_TEMPLATIZED_POSTBACK" | "FEATURE_TRIGGER_VENDOR_EMAIL_SEND" | "FEATURE_UNKNOWN" | "FORWARD_MESSAGE_CUSTOMER_SERVICE" | "INGEST_TRANSACTIONAL_EVENTS" | "POSTBACK_EMAILS" | "POST_WITH_PHONE" | "SCRUB_MESSAGE_SIGNATURE" | "SYNC_HISTORICAL_PURCHASES" | "SYNC_PRODUCTS" | "SYNC_THIRD_PARTY_LISTS" | "SYNC_THIRD_PARTY_SEGMENTS" | "THIRD_PARTY_PROCESSOR_FORWARDER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useKlaviyoEmailFeatureValidDate_TargetingCommon_Company$data = {
  readonly companyVendorIntegrations: ReadonlyArray<{
    readonly companyVendorIntegrationFeatures: ReadonlyArray<{
      readonly created: SerializedDateTime;
      readonly enabled: boolean;
      readonly externalVendorFeature: {
        readonly type: Feature;
      } | null;
      readonly " $fragmentSpreads": FragmentRefs<"getIntegrationFeature_TargetingCommon_CompanyVendorIntegrationFeature">;
    }> | null;
    readonly externalVendorApi: {
      readonly id: string;
    } | null;
    readonly id: string;
  }> | null;
  readonly " $fragmentType": "useKlaviyoEmailFeatureValidDate_TargetingCommon_Company";
};
export type useKlaviyoEmailFeatureValidDate_TargetingCommon_Company$key = {
  readonly " $data"?: useKlaviyoEmailFeatureValidDate_TargetingCommon_Company$data;
  readonly " $fragmentSpreads": FragmentRefs<"useKlaviyoEmailFeatureValidDate_TargetingCommon_Company">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "ExternalVendorFeature",
  "kind": "LinkedField",
  "name": "externalVendorFeature",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "enabled",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useKlaviyoEmailFeatureValidDate_TargetingCommon_Company",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "vendor",
          "value": "KLAVIYO"
        }
      ],
      "concreteType": "CompanyVendorIntegration",
      "kind": "LinkedField",
      "name": "companyVendorIntegrations",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ExternalVendorApi",
          "kind": "LinkedField",
          "name": "externalVendorApi",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CompanyVendorIntegrationFeature",
          "kind": "LinkedField",
          "name": "companyVendorIntegrationFeatures",
          "plural": true,
          "selections": [
            {
              "kind": "InlineDataFragmentSpread",
              "name": "getIntegrationFeature_TargetingCommon_CompanyVendorIntegrationFeature",
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/)
              ],
              "args": null,
              "argumentDefinitions": []
            },
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "created",
              "storageKey": null
            },
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": "companyVendorIntegrations(vendor:\"KLAVIYO\")"
    }
  ],
  "type": "Company",
  "abstractKey": null
};
})();

(node as any).hash = "059b33cd57fd1cf7f78c72a63505decc";

export default node;
