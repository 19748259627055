/**
 * @generated SignedSource<<0bc67389f64c5435cfcabedcf74e78ce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Feature = "BRIDGE_IDENTIFIERS" | "CUSTOMER_SERVICE_OPT_IN" | "DYNAMIC_COUPON_GENERATION" | "FEATURE_ADDITIONAL_SETUP_LINK" | "FEATURE_ATTENTIVE_LOYALTY_SIGNUP" | "FEATURE_AUTO_INSTALL_TAG" | "FEATURE_CREATE_MARKETING_EMAIL_SUBSCRIPTIONS" | "FEATURE_CREATE_MARKETING_SMS_SUBSCRIPTIONS" | "FEATURE_CREATE_TRANSACTIONAL_SUBSCRIPTIONS" | "FEATURE_EMAIL_OPT_OUT_SYNC" | "FEATURE_EXPORT_SEGMENTS" | "FEATURE_GENERATE_HOOK" | "FEATURE_INGEST_AD_DATA" | "FEATURE_INGEST_LEADS" | "FEATURE_INGEST_TAG_EVENTS" | "FEATURE_INGEST_USER_DATA" | "FEATURE_MARKETING_EMAIL_MESSAGE_SEND" | "FEATURE_POSTBACK_USER_ATTRIBUTES" | "FEATURE_POSTBACK_USER_DATA" | "FEATURE_POST_SUBSCRIBER_OPT_IN" | "FEATURE_POST_SUBSCRIBER_OPT_OUT" | "FEATURE_PURCHASE_PRODUCT" | "FEATURE_REFUND_SYNC" | "FEATURE_SETUP_REDIRECT_SOURCE" | "FEATURE_SUBSCRIPTION_MESSAGING" | "FEATURE_SURVEY_MESSAGE" | "FEATURE_SYNC_ABANDONED_CHECKOUTS" | "FEATURE_SYNC_ACCOUNT_PROPERTIES" | "FEATURE_SYNC_ATTENTIVE_TO_ESP_EMAIL_OPT_OUTS_VIA_SFTP" | "FEATURE_SYNC_EMAIL_CONSENT" | "FEATURE_SYNC_EMAIL_EVENTS" | "FEATURE_SYNC_EMAIL_SUBSCRIBERS" | "FEATURE_SYNC_ESP_TO_ATTENTIVE_EMAIL_OPT_OUTS_VIA_SFTP" | "FEATURE_SYNC_EVENTS_OUTBOUND" | "FEATURE_SYNC_LIST_COUNT" | "FEATURE_SYNC_LIST_IDENTIFIERS" | "FEATURE_SYNC_LOYALTY_EVENTS" | "FEATURE_SYNC_ORDER_EVENTS" | "FEATURE_SYNC_PRODUCT_COLLECTIONS" | "FEATURE_SYNC_PURCHASES" | "FEATURE_SYNC_RECURRING_PURCHASES" | "FEATURE_SYNC_REVIEWS" | "FEATURE_SYNC_SMS_CONSENT" | "FEATURE_SYNC_THIRD_PARTY_CAMPAIGNS" | "FEATURE_SYNC_THIRD_PARTY_JOURNEYS" | "FEATURE_SYNC_USER_PROFILES" | "FEATURE_TEMPLATIZED_POSTBACK" | "FEATURE_TRIGGER_VENDOR_EMAIL_SEND" | "FEATURE_UNKNOWN" | "FORWARD_MESSAGE_CUSTOMER_SERVICE" | "INGEST_TRANSACTIONAL_EVENTS" | "POSTBACK_EMAILS" | "POST_WITH_PHONE" | "SCRUB_MESSAGE_SIGNATURE" | "SYNC_HISTORICAL_PURCHASES" | "SYNC_PRODUCTS" | "SYNC_THIRD_PARTY_LISTS" | "SYNC_THIRD_PARTY_SEGMENTS" | "THIRD_PARTY_PROCESSOR_FORWARDER" | "%future added value";
export type useKlaviyoEmailFeatureValidDate_TargetingCommon_Query$variables = {
  companyId: string;
  isKlaviyo: boolean;
};
export type useKlaviyoEmailFeatureValidDate_TargetingCommon_Query$data = {
  readonly company?: {
    readonly " $fragmentSpreads": FragmentRefs<"useKlaviyoEmailFeatureValidDate_TargetingCommon_Company">;
  } | null;
};
export type useKlaviyoEmailFeatureValidDate_TargetingCommon_Query$rawResponse = {
  readonly company: {
    readonly __typename: "Company";
    readonly companyVendorIntegrations: ReadonlyArray<{
      readonly companyVendorIntegrationFeatures: ReadonlyArray<{
        readonly created: SerializedDateTime;
        readonly enabled: boolean;
        readonly externalVendorFeature: {
          readonly id: string;
          readonly type: Feature;
        } | null;
        readonly id: string;
      }> | null;
      readonly externalVendorApi: {
        readonly id: string;
      } | null;
      readonly id: string;
    }> | null;
    readonly id: string;
  } | {
    readonly __typename: string;
    readonly id: string;
  } | null;
};
export type useKlaviyoEmailFeatureValidDate_TargetingCommon_Query = {
  rawResponse: useKlaviyoEmailFeatureValidDate_TargetingCommon_Query$rawResponse;
  response: useKlaviyoEmailFeatureValidDate_TargetingCommon_Query$data;
  variables: useKlaviyoEmailFeatureValidDate_TargetingCommon_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isKlaviyo"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useKlaviyoEmailFeatureValidDate_TargetingCommon_Query",
    "selections": [
      {
        "condition": "isKlaviyo",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "company",
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "useKlaviyoEmailFeatureValidDate_TargetingCommon_Company"
                  }
                ],
                "type": "Company",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useKlaviyoEmailFeatureValidDate_TargetingCommon_Query",
    "selections": [
      {
        "condition": "isKlaviyo",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "company",
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "vendor",
                        "value": "KLAVIYO"
                      }
                    ],
                    "concreteType": "CompanyVendorIntegration",
                    "kind": "LinkedField",
                    "name": "companyVendorIntegrations",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ExternalVendorApi",
                        "kind": "LinkedField",
                        "name": "externalVendorApi",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CompanyVendorIntegrationFeature",
                        "kind": "LinkedField",
                        "name": "companyVendorIntegrationFeatures",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ExternalVendorFeature",
                            "kind": "LinkedField",
                            "name": "externalVendorFeature",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "type",
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "enabled",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "created",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "companyVendorIntegrations(vendor:\"KLAVIYO\")"
                  }
                ],
                "type": "Company",
                "abstractKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "83d5912a305d05c1d27cacb4c81b5748",
    "id": null,
    "metadata": {},
    "name": "useKlaviyoEmailFeatureValidDate_TargetingCommon_Query",
    "operationKind": "query",
    "text": "query useKlaviyoEmailFeatureValidDate_TargetingCommon_Query(\n  $companyId: ID!\n  $isKlaviyo: Boolean!\n) {\n  company: node(id: $companyId) @include(if: $isKlaviyo) {\n    __typename\n    ... on Company {\n      ...useKlaviyoEmailFeatureValidDate_TargetingCommon_Company\n    }\n    id\n  }\n}\n\nfragment getIntegrationFeature_TargetingCommon_CompanyVendorIntegrationFeature on CompanyVendorIntegrationFeature {\n  externalVendorFeature {\n    type\n    id\n  }\n  enabled\n}\n\nfragment useKlaviyoEmailFeatureValidDate_TargetingCommon_Company on Company {\n  companyVendorIntegrations(vendor: \"KLAVIYO\") {\n    id\n    externalVendorApi {\n      id\n    }\n    companyVendorIntegrationFeatures {\n      ...getIntegrationFeature_TargetingCommon_CompanyVendorIntegrationFeature\n      externalVendorFeature {\n        type\n        id\n      }\n      created\n      enabled\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4552a436a1d0408dff9a67b15446f989";

export default node;
