export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A slightly refined version of RFC-3339 compliant DateTime Scalar */
  DateTime: { input: SerializedDateTime; output: SerializedDateTime; }
  /** An empty object with no fields, mapping to com.google.protobuf.Empty */
  Empty: { input: any; output: any; }
  /** A JSON scalar */
  JSON: { input: any; output: any; }
  /** A 64-bit signed integer */
  Long: { input: number; output: number; }
};

export enum AIBrandVoiceAllCapsUsage {
  AllCapsUsageAdaptive = 'ALL_CAPS_USAGE_ADAPTIVE',
  AllCapsUsageFrequently = 'ALL_CAPS_USAGE_FREQUENTLY',
  AllCapsUsageNever = 'ALL_CAPS_USAGE_NEVER',
  AllCapsUsageSparingly = 'ALL_CAPS_USAGE_SPARINGLY'
}

export enum AIBrandVoiceEmojiFrequency {
  EmojiFrequencyAdaptive = 'EMOJI_FREQUENCY_ADAPTIVE',
  EmojiFrequencyFrequently = 'EMOJI_FREQUENCY_FREQUENTLY',
  EmojiFrequencyNever = 'EMOJI_FREQUENCY_NEVER',
  EmojiFrequencySparingly = 'EMOJI_FREQUENCY_SPARINGLY'
}

export enum AIBrandVoiceProductTitleFormatting {
  ProductTitleFormattingLowerCase = 'PRODUCT_TITLE_FORMATTING_LOWER_CASE',
  ProductTitleFormattingTitleCase = 'PRODUCT_TITLE_FORMATTING_TITLE_CASE',
  ProductTitleFormattingUpperCase = 'PRODUCT_TITLE_FORMATTING_UPPER_CASE'
}

/** Generated from com/attentivemobile/contracts/messaging/automation/brandvoice/AIBrandVoice.proto */
export type AIBrandVoiceSettings = {
  __typename?: 'AIBrandVoiceSettings';
  allCapsCount: Maybe<Scalars['Int']['output']>;
  allCapsUsage: Maybe<AIBrandVoiceAllCapsUsage>;
  characterCount: Maybe<Scalars['Int']['output']>;
  company: Maybe<Company>;
  emojiCount: Maybe<Scalars['Int']['output']>;
  emojiFrequency: AIBrandVoiceEmojiFrequency;
  exclusionWords: Array<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  productTitleFormatting: Maybe<AIBrandVoiceProductTitleFormatting>;
  toneKeywords: Array<Scalars['String']['output']>;
  urgency: Maybe<AIBrandVoiceUrgency>;
  wordCount: Maybe<Scalars['Int']['output']>;
};

export type AIBrandVoiceSettingsInput = {
  allCapsUsage: AIBrandVoiceAllCapsUsage;
  emojiFrequency: AIBrandVoiceEmojiFrequency;
  exclusionWords: InputMaybe<Array<Scalars['String']['input']>>;
  productTitleFormatting: AIBrandVoiceProductTitleFormatting;
  toneKeywords: InputMaybe<Array<Scalars['String']['input']>>;
  urgency: AIBrandVoiceUrgency;
};

export type AIBrandVoiceSettingsInputType = {
  allCapsCount: InputMaybe<Scalars['Int']['input']>;
  allCapsUsage: InputMaybe<AIBrandVoiceAllCapsUsage>;
  characterCount: InputMaybe<Scalars['Int']['input']>;
  emojiCount: InputMaybe<Scalars['Int']['input']>;
  emojiFrequency: InputMaybe<AIBrandVoiceEmojiFrequency>;
  exclusionWords: InputMaybe<Array<Scalars['String']['input']>>;
  productTitleFormatting: InputMaybe<AIBrandVoiceProductTitleFormatting>;
  toneKeywords: InputMaybe<Array<Scalars['String']['input']>>;
  urgency: InputMaybe<AIBrandVoiceUrgency>;
  wordCount: InputMaybe<Scalars['Int']['input']>;
};

export type AIBrandVoiceSettingsUpdateInput = {
  allCapsUsage: AIBrandVoiceAllCapsUsage;
  emojiFrequency: AIBrandVoiceEmojiFrequency;
  exclusionWords: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['String']['input'];
  productTitleFormatting: AIBrandVoiceProductTitleFormatting;
  toneKeywords: InputMaybe<Array<Scalars['String']['input']>>;
  urgency: AIBrandVoiceUrgency;
};

export type AIBrandVoiceSettingsUpdateV2Input = {
  allCapsUsage: AIBrandVoiceAllCapsUsage;
  emojiFrequency: AIBrandVoiceEmojiFrequency;
  exclusionWords: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['String']['input'];
  productTitleFormatting: AIBrandVoiceProductTitleFormatting;
  toneKeywords: InputMaybe<Array<Scalars['String']['input']>>;
  urgency: AIBrandVoiceUrgency;
};

/** Generated from com/attentivemobile/contracts/generativeaiservice/brandvoice/AIBrandVoice.proto */
export type AIBrandVoiceSettingsV2 = {
  __typename?: 'AIBrandVoiceSettingsV2';
  allCapsCount: Maybe<Scalars['Int']['output']>;
  allCapsUsage: Maybe<AIBrandVoiceAllCapsUsage>;
  characterCount: Maybe<Scalars['Int']['output']>;
  company: Maybe<Company>;
  emojiCount: Maybe<Scalars['Int']['output']>;
  emojiFrequency: AIBrandVoiceEmojiFrequency;
  exclusionWords: Array<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  productTitleFormatting: Maybe<AIBrandVoiceProductTitleFormatting>;
  toneKeywords: Array<Scalars['String']['output']>;
  urgency: Maybe<AIBrandVoiceUrgency>;
  wordCount: Maybe<Scalars['Int']['output']>;
};

export type AIBrandVoiceSettingsV2Input = {
  allCapsUsage: AIBrandVoiceAllCapsUsage;
  emojiFrequency: AIBrandVoiceEmojiFrequency;
  exclusionWords: InputMaybe<Array<Scalars['String']['input']>>;
  productTitleFormatting: AIBrandVoiceProductTitleFormatting;
  toneKeywords: InputMaybe<Array<Scalars['String']['input']>>;
  urgency: AIBrandVoiceUrgency;
};

export enum AIBrandVoiceUrgency {
  UrgencyAdaptive = 'URGENCY_ADAPTIVE',
  UrgencyFrequently = 'URGENCY_FREQUENTLY',
  UrgencyNever = 'URGENCY_NEVER',
  UrgencySparingly = 'URGENCY_SPARINGLY'
}

/** Generated from com/attentivemobile/contracts/generativeaiservice/text/TextGenerationModel.proto */
export type AIGeneratedText = Node & {
  __typename?: 'AIGeneratedText';
  aiGeneratedTextMetadata: Array<AiGeneratedTextMetadataEntry>;
  company: Maybe<Company>;
  groupId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  source: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/generativeaiservice/text/TextGenerationContract.proto */
export type AIGeneratedTextConnection = {
  __typename?: 'AIGeneratedTextConnection';
  edges: Array<AIGeneratedTextEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Long']['output'];
};

/** Generated from com/attentivemobile/contracts/generativeaiservice/text/TextGenerationModel.proto */
export type AIGeneratedTextEdge = {
  __typename?: 'AIGeneratedTextEdge';
  cursor: Scalars['String']['output'];
  node: AIGeneratedText;
};

export enum AIGeneratedTextOrigin {
  GeneratedTextOriginAutomatedJourneys = 'GENERATED_TEXT_ORIGIN_AUTOMATED_JOURNEYS',
  GeneratedTextOriginAutomatedJourneysChain = 'GENERATED_TEXT_ORIGIN_AUTOMATED_JOURNEYS_CHAIN',
  GeneratedTextOriginAutomatedWelcomeJourney = 'GENERATED_TEXT_ORIGIN_AUTOMATED_WELCOME_JOURNEY',
  GeneratedTextOriginBrandVoicePreview = 'GENERATED_TEXT_ORIGIN_BRAND_VOICE_PREVIEW',
  GeneratedTextOriginBrandVoiceSummary = 'GENERATED_TEXT_ORIGIN_BRAND_VOICE_SUMMARY',
  GeneratedTextOriginCampaignSuggestionsHomepage = 'GENERATED_TEXT_ORIGIN_CAMPAIGN_SUGGESTIONS_HOMEPAGE',
  GeneratedTextOriginCopyAssistantEmailSubjectLine = 'GENERATED_TEXT_ORIGIN_COPY_ASSISTANT_EMAIL_SUBJECT_LINE',
  GeneratedTextOriginCopyAssistantSms = 'GENERATED_TEXT_ORIGIN_COPY_ASSISTANT_SMS',
  GeneratedTextOriginUnknown = 'GENERATED_TEXT_ORIGIN_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/generativeaiservice/image/ImageGenerationContract.proto */
export type AIImageGenerationOptionsPayload = {
  __typename?: 'AIImageGenerationOptionsPayload';
  sceneConstructionOptions: Array<SceneConstructionOption>;
};

export enum AIImageGenerationType {
  AiImageGenerationTypeSceneConstruction = 'AI_IMAGE_GENERATION_TYPE_SCENE_CONSTRUCTION',
  AiImageGenerationTypeUnknown = 'AI_IMAGE_GENERATION_TYPE_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/personalization/service/aijourneys/defaultmessage/AIJourneyDefaultMessage.proto */
export type AIJourneyDefaultMessage = Node & {
  __typename?: 'AIJourneyDefaultMessage';
  company: Maybe<Company>;
  id: Scalars['ID']['output'];
  messageContent: Scalars['String']['output'];
  messageType: MessageType;
};

/** Generated from com/attentivemobile/contracts/messaging/automation/aijourneys/onboarding/AIJourneyLinkConfiguration.proto */
export type AIJourneyLinkConfiguration = {
  __typename?: 'AIJourneyLinkConfiguration';
  linkConfig: Maybe<AIJourneyLinkConfigurationLinkConfig>;
  messageType: MessageType;
};

export type AIJourneyLinkConfigurationInput = {
  linkConfig: InputMaybe<AIJourneyLinkConfigurationInputLinkConfig>;
  messageType: MessageType;
};

export type AIJourneyLinkConfigurationInputLinkConfig =
  { abandonmentLinkConfigInput: AbandonmentLinkConfigurationInput; welcomeLinkConfigInput?: never; }
  |  { abandonmentLinkConfigInput?: never; welcomeLinkConfigInput: WelcomeLinkConfigurationInput; };

export type AIJourneyLinkConfigurationLinkConfig = AbandonmentLinkConfiguration | WelcomeLinkConfiguration;

/** Generated from com/attentivemobile/contracts/messaging/automation/aijourneys/onboarding/AIJourneyLinkConfigServiceContract.proto */
export type AIJourneyTrackingParams = {
  __typename?: 'AIJourneyTrackingParams';
  aiJourneyCustomTrackingParams: Array<AiJourneyCustomTrackingParamsEntry>;
  messageType: MessageType;
  utmCampaign: Scalars['String']['output'];
};

export type AIJourneyTrackingParamsInput = {
  aiJourneyCustomTrackingParamsInput: InputMaybe<Array<AiJourneyCustomTrackingParamsInputEntry>>;
  messageType: MessageType;
  utmCampaign: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/messaging/automation/aijourneys/onboarding/AIJourneyLinkConfiguration.proto */
export type AbandonmentLinkConfiguration = {
  __typename?: 'AbandonmentLinkConfiguration';
  /** @deprecated deprecated */
  abandonmentLinkConfig: CustomLinkConfig;
  customLink: CustomLinkConfig;
};

export type AbandonmentLinkConfigurationInput = {
  customLinkInput: CustomLinkConfigInput;
};

/** Generated from com/attentivemobile/contracts/account-service/Contract.proto */
export type Account = Node & {
  __typename?: 'Account';
  assignedCompanies: Maybe<Array<AccountAssignedCompany>>;
  authenticators: Maybe<GetAuthenticatorsPayload>;
  conciergeAgent: Maybe<ConciergeAgent>;
  created: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  internalId: Scalars['Long']['output'];
  lastName: Scalars['String']['output'];
  mfaPhoneNumberHint: Maybe<Scalars['String']['output']>;
  phone: Scalars['String']['output'];
  recentCompanies: Maybe<CompanyConnection>;
  shouldUseSso: Scalars['Boolean']['output'];
};


/** Generated from com/attentivemobile/contracts/account-service/Contract.proto */
export type AccountrecentCompaniesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
};

/** Generated from com/attentivemobile/contracts/account-service/Contract.proto */
export type AccountAssignedCompany = {
  __typename?: 'AccountAssignedCompany';
  company: Maybe<Company>;
};

/** Generated from com/attentivemobile/contracts/account-service/Contract.proto */
export type AccountConnection = {
  __typename?: 'AccountConnection';
  edges: Array<AccountEdge>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/account-service/Contract.proto */
export type AccountEdge = {
  __typename?: 'AccountEdge';
  cursor: Scalars['String']['output'];
  node: Account;
};

export type AccountFilter = {
  role: InputMaybe<Role>;
  searchTerm: InputMaybe<Scalars['String']['input']>;
};

/** Generated from com/attentivemobile/contracts/concierge/service/Feedback.proto */
export type AccountItem = {
  __typename?: 'AccountItem';
  viewedBy: Maybe<Account>;
};

/** Generated from com/attentivemobile/contracts/account-service/MFAContract.proto */
export type AccountMfaPreferencesPayload = {
  __typename?: 'AccountMfaPreferencesPayload';
  mfaPasscodeDeliveryContactHint: Maybe<Scalars['String']['output']>;
  preferredOtpDeliveryMethod: Maybe<MfaOneTimePasscodeDeliveryMethod>;
};

export type AddBrandImageInput = {
  companyId: Scalars['ID']['input'];
  fileUuid: Scalars['String']['input'];
  name: Scalars['String']['input'];
  size: Scalars['Int']['input'];
  style: ImageStyle;
  type: ImageType;
};

/** Generated from com/attentivemobile/contracts/brand-kit/BrandImage.proto */
export type AddBrandImagePayload = {
  __typename?: 'AddBrandImagePayload';
  brandImage: BrandImage;
};

export type AddCampaignMessageGroupInput = {
  campaignId: Scalars['ID']['input'];
  campaignMessageGroupName: Scalars['String']['input'];
  companyId: Scalars['ID']['input'];
  experimentMetadata: InputMaybe<CreateCampaignMessageGroupExperimentInput>;
  messageChannel: ComposeMessageChannel;
  userId: Scalars['ID']['input'];
};

export type AddConciergeCompanyPromotionInput = {
  companyId: Scalars['ID']['input'];
  end: InputMaybe<Scalars['DateTime']['input']>;
  limitedTime: Scalars['Boolean']['input'];
  note: Scalars['String']['input'];
  start: InputMaybe<Scalars['DateTime']['input']>;
  startPromoting: InputMaybe<Scalars['DateTime']['input']>;
  title: Scalars['String']['input'];
  type: ConciergeCompanyPromotionType;
};

export type AddEmailIPAssignmentsInput = {
  ipAssignmentsToAdd: InputMaybe<Array<EmailIPAssignmentInput>>;
  /** @deprecated deprecated */
  newIpAssignments: InputMaybe<Array<EmailIPAssignmentInput>>;
};

export type AddEmailIPAssignmentsPayload = AddEmailIPAssignmentsSuccess | DefaultErrorFailure;

/** Generated from com/attentivemobile/contracts/emails/management/Contract.proto */
export type AddEmailIPAssignmentsSuccess = {
  __typename?: 'AddEmailIPAssignmentsSuccess';
  /** @deprecated deprecated */
  assignmentsAdded: Scalars['Long']['output'];
  emailIpAssignments: Array<EmailIPAssignment>;
};

export type AddNewEtlConfigInput = {
  companyId: Scalars['ID']['input'];
  enabled: InputMaybe<EtlEnabled>;
  etlConfigId: InputMaybe<Scalars['Long']['input']>;
  etlConfigStaticValue: InputMaybe<Array<EtlConfigStaticValueInput>>;
  etlReportMetadata: InputMaybe<EtlReportMetadataInput>;
  eventTypes: InputMaybe<Array<EventType>>;
  externalSftpConfig: InputMaybe<ExternalSftpConfigInput>;
  features: InputMaybe<Array<EtlConfigFeature>>;
};

/** Generated from com/attentivemobile/contracts/solutions/sftp/EtlConfig.proto */
export type AddNewEtlConfigPayload = {
  __typename?: 'AddNewEtlConfigPayload';
  message: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type AddToCartEvent = {
  __typename?: 'AddToCartEvent';
  companyProduct: Maybe<Product>;
  companyProductId: Scalars['ID']['output'];
  /** @deprecated deprecated */
  created: Scalars['DateTime']['output'];
  /** @deprecated deprecated */
  id: Scalars['String']['output'];
  /** @deprecated deprecated */
  productName: Scalars['String']['output'];
  /** @deprecated deprecated */
  productUrl: Scalars['String']['output'];
};

export type AddVariantToCampaignMessageGroupInput = {
  campaignMessageGroupId: Scalars['ID']['input'];
  companyId: Scalars['ID']['input'];
  contentInput: InputMaybe<AddVariantToCampaignMessageGroupInputContentInput>;
  experimentType: InputMaybe<ExperimentType>;
  /** @deprecated deprecated */
  textContent: InputMaybe<TextContentInput>;
};

export type AddVariantToCampaignMessageGroupInputContentInput =
  { emailSubjectAndPreviewInput: EmailSubjectAndPreviewContentInput; textContentInput?: never; }
  |  { emailSubjectAndPreviewInput?: never; textContentInput: TextContentInput; };

/** Generated from com/attentivemobile/contracts/journeys/api/RevisionStep.proto */
export type AdderStep = {
  __typename?: 'AdderStep';
  childrenSteps: Array<NonAdderStep>;
  stepConfig: AdderStepConfig;
};

/** Generated from com/attentivemobile/contracts/journeys/api/RevisionStepConfig.proto */
export type AdderStepConfig = {
  __typename?: 'AdderStepConfig';
  allowedStepTypes: Array<RevisionStepValue>;
};

/** Generated from com/attentivemobile/contracts/company-service/onboarding/Company.proto */
export type Address = {
  __typename?: 'Address';
  addressLine1: Scalars['String']['output'];
  addressLine2: Scalars['String']['output'];
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  state: Scalars['String']['output'];
  zipcode: Scalars['String']['output'];
};

export type AddressInput = {
  addressLine1: InputMaybe<Scalars['String']['input']>;
  addressLine2: InputMaybe<Scalars['String']['input']>;
  city: InputMaybe<Scalars['String']['input']>;
  country: InputMaybe<Scalars['String']['input']>;
  state: InputMaybe<Scalars['String']['input']>;
  zipcode: InputMaybe<Scalars['String']['input']>;
};

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type AdminNotificationConfiguration = Node & {
  __typename?: 'AdminNotificationConfiguration';
  deleted: Scalars['Boolean']['output'];
  dismissible: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  notificationTemplateId: Scalars['Long']['output'];
  slug: Scalars['String']['output'];
  type: NotificationType;
};

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type AdminNotificationConfigurationEdge = {
  __typename?: 'AdminNotificationConfigurationEdge';
  cursor: Scalars['String']['output'];
  node: AdminNotificationConfiguration;
};

export type AdminNotificationConfigurationInput = {
  dismissible: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  slug: Scalars['String']['input'];
  type: NotificationType;
};

export type AdminSetSftpUserPasswordInput = {
  companyId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/solutions/sftp/SftpUser.proto */
export type AdminSetSftpUserPasswordPayload = {
  __typename?: 'AdminSetSftpUserPasswordPayload';
  responseMsg: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/externaltokens/AffiliateUsers.proto */
export type AffiliateUser = {
  __typename?: 'AffiliateUser';
  company: Maybe<Company>;
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/externaltokens/AffiliateUsers.proto */
export type AffiliateUserConnection = {
  __typename?: 'AffiliateUserConnection';
  edges: Array<AffiliateUserEdge>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/externaltokens/AffiliateUsers.proto */
export type AffiliateUserEdge = {
  __typename?: 'AffiliateUserEdge';
  cursor: Scalars['String']['output'];
  node: AffiliateUser;
};

export type AgentMessageFeedbackFilterInput = {
  recipient: InputMaybe<Scalars['ID']['input']>;
  searchTerm: InputMaybe<Scalars['String']['input']>;
  status: InputMaybe<MessageFeedbackStatusFilter>;
  type: InputMaybe<MessageFeedbackTypeFilter>;
};

/** Generated from com/attentivemobile/contracts/concierge/service/Template.proto */
export type AgentTemplate = {
  __typename?: 'AgentTemplate';
  company: Maybe<Company>;
  createdBy: Maybe<Account>;
  disabledReason: Maybe<AgentTemplateDisabledReason>;
  id: Scalars['String']['output'];
  isVisible: Scalars['Boolean']['output'];
  shortcut: Maybe<Scalars['String']['output']>;
  source: Maybe<AgentTemplateSource>;
  templateSource: Maybe<TemplateSource>;
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updated: Scalars['DateTime']['output'];
};

export type AgentTemplateDisabledReason = UnsupportedMacros;

/** Generated from com/attentivemobile/contracts/concierge/service/TemplateRequest.proto */
export type AgentTemplateRequest = {
  __typename?: 'AgentTemplateRequest';
  company: Maybe<Company>;
  created: Scalars['DateTime']['output'];
  dateReviewed: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  notes: Maybe<Scalars['String']['output']>;
  requestedBy: Maybe<Account>;
  reviewedBy: Maybe<Account>;
  shortcut: Maybe<Scalars['String']['output']>;
  source: Maybe<AgentTemplateRequestSource>;
  status: TemplateRequestStatus;
  templateSource: Maybe<TemplateSource>;
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type AgentTemplateRequestSource = CompanySettingsSource | ConversationSource | FeedbackSource;

export type AgentTemplateSource = CompanySettingsSource | ConversationSource | FeedbackSource;

export enum Aggregator {
  AggregatorOpenMarket = 'AGGREGATOR_OPEN_MARKET',
  AggregatorSap = 'AGGREGATOR_SAP',
  AggregatorSyniverse = 'AGGREGATOR_SYNIVERSE',
  AggregatorUnknown = 'AGGREGATOR_UNKNOWN',
  AggregatorUnset = 'AGGREGATOR_UNSET'
}

/** Generated from com/attentivemobile/contracts/phonenumbers/external/Contract.proto */
export type AggregatorInfo = {
  __typename?: 'AggregatorInfo';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  rateLimit: Scalars['Long']['output'];
};

/** Generated from com/attentivemobile/protobuf/common/AudienceAiProduct.proto */
export type AiCategory = {
  __typename?: 'AiCategory';
  category: Maybe<ProductCategory>;
};

/** Generated from com/attentivemobile/contracts/generativeaiservice/text/TextGenerationModel.proto */
export type AiGeneratedTextMetadataEntry = {
  __typename?: 'AiGeneratedTextMetadataEntry';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/messaging/automation/aijourneys/onboarding/AIJourneyLinkConfigServiceContract.proto */
export type AiJourneyCustomTrackingParamsEntry = {
  __typename?: 'AiJourneyCustomTrackingParamsEntry';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type AiJourneyCustomTrackingParamsInputEntry = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export enum AiProEligibilityStatus {
  AiProEligibilityStatusEligible = 'AI_PRO_ELIGIBILITY_STATUS_ELIGIBLE',
  AiProEligibilityStatusEnrolled = 'AI_PRO_ELIGIBILITY_STATUS_ENROLLED',
  AiProEligibilityStatusIneligible = 'AI_PRO_ELIGIBILITY_STATUS_INELIGIBLE',
  AiProEligibilityStatusPending = 'AI_PRO_ELIGIBILITY_STATUS_PENDING',
  AiProEligibilityStatusUnknown = 'AI_PRO_ELIGIBILITY_STATUS_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/segment/recommendation/service/aipro/Contract.proto */
export type AiProIneligibleReason = {
  __typename?: 'AiProIneligibleReason';
  reason: Maybe<AiProIneligibleReasonReason>;
};

/** Generated from com/attentivemobile/contracts/segment/recommendation/service/aipro/Contract.proto */
export type AiProIneligibleReasonCampaignsWithEnoughSendsBelowThreshold = {
  __typename?: 'AiProIneligibleReasonCampaignsWithEnoughSendsBelowThreshold';
  campaignCount: Scalars['Int']['output'];
  thresholdCount: Scalars['Int']['output'];
  thresholdPercentage: Scalars['Int']['output'];
};

/** Generated from com/attentivemobile/contracts/segment/recommendation/service/aipro/Contract.proto */
export type AiProIneligibleReasonEligibleCampaignsWithoutConversions = {
  __typename?: 'AiProIneligibleReasonEligibleCampaignsWithoutConversions';
  campaignCount: Scalars['Int']['output'];
  campaignWithoutConversionsCount: Scalars['Int']['output'];
};

/** Generated from com/attentivemobile/contracts/segment/recommendation/service/aipro/Contract.proto */
export type AiProIneligibleReasonMonthToMonth = {
  __typename?: 'AiProIneligibleReasonMonthToMonth';
  reason: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/segment/recommendation/service/aipro/Contract.proto */
export type AiProIneligibleReasonQualifiedCampaignsSentBelowThreshold = {
  __typename?: 'AiProIneligibleReasonQualifiedCampaignsSentBelowThreshold';
  campaignCount: Scalars['Int']['output'];
  thresholdCount: Scalars['Int']['output'];
};

export type AiProIneligibleReasonReason = AiProIneligibleReasonCampaignsWithEnoughSendsBelowThreshold | AiProIneligibleReasonEligibleCampaignsWithoutConversions | AiProIneligibleReasonMonthToMonth | AiProIneligibleReasonQualifiedCampaignsSentBelowThreshold | AiProIneligibleReasonSingleAccountManagement | AiProIneligibleReasonUnknown;

/** Generated from com/attentivemobile/contracts/segment/recommendation/service/aipro/Contract.proto */
export type AiProIneligibleReasonSingleAccountManagement = {
  __typename?: 'AiProIneligibleReasonSingleAccountManagement';
  reason: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/segment/recommendation/service/aipro/Contract.proto */
export type AiProIneligibleReasonUnknown = {
  __typename?: 'AiProIneligibleReasonUnknown';
  reason: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/segment/recommendation/service/aipro/Contract.proto */
export type AiProSubscriptionStatus = {
  __typename?: 'AiProSubscriptionStatus';
  status: Maybe<AiProSubscriptionStatusStatus>;
};

/** Generated from com/attentivemobile/contracts/segment/recommendation/service/aipro/Contract.proto */
export type AiProSubscriptionStatusChurned = {
  __typename?: 'AiProSubscriptionStatusChurned';
  comment: Maybe<Scalars['String']['output']>;
  reason: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/segment/recommendation/service/aipro/Contract.proto */
export type AiProSubscriptionStatusDoesNotExist = {
  __typename?: 'AiProSubscriptionStatusDoesNotExist';
  comment: Maybe<Scalars['String']['output']>;
};

/** Generated from com/attentivemobile/contracts/segment/recommendation/service/aipro/Contract.proto */
export type AiProSubscriptionStatusExtended = {
  __typename?: 'AiProSubscriptionStatusExtended';
  extendedDate: Date;
  startDate: Date;
};

/** Generated from com/attentivemobile/contracts/segment/recommendation/service/aipro/Contract.proto */
export type AiProSubscriptionStatusPaid = {
  __typename?: 'AiProSubscriptionStatusPaid';
  convertedAt: Scalars['DateTime']['output'];
};

/** Generated from com/attentivemobile/contracts/segment/recommendation/service/aipro/Contract.proto */
export type AiProSubscriptionStatusPaused = {
  __typename?: 'AiProSubscriptionStatusPaused';
  comment: Maybe<Scalars['String']['output']>;
  reason: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/segment/recommendation/service/aipro/Contract.proto */
export type AiProSubscriptionStatusPending = {
  __typename?: 'AiProSubscriptionStatusPending';
  startDate: Date;
};

export type AiProSubscriptionStatusStatus = AiProSubscriptionStatusChurned | AiProSubscriptionStatusDoesNotExist | AiProSubscriptionStatusExtended | AiProSubscriptionStatusPaid | AiProSubscriptionStatusPaused | AiProSubscriptionStatusPending | AiProSubscriptionStatusTrial;

/** Generated from com/attentivemobile/contracts/segment/recommendation/service/aipro/Contract.proto */
export type AiProSubscriptionStatusTrial = {
  __typename?: 'AiProSubscriptionStatusTrial';
  startDate: Date;
};

/** Generated from com/attentivemobile/contracts/segment/recommendation/service/aipro/Contract.proto */
export type AiProTrialActionFailure = StandardError & {
  __typename?: 'AiProTrialActionFailure';
  humanReadableMessage: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  status: StandardErrorStatus;
  title: Scalars['String']['output'];
  traceId: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/protobuf/common/AudienceAiProduct.proto */
export type AiProduct = {
  __typename?: 'AiProduct';
  product: Maybe<Product>;
};

/** Generated from com/attentivemobile/protobuf/common/targeting/segmentation/output/SearchDefinitionOutput.proto */
export type Alias = {
  __typename?: 'Alias';
  alias: Scalars['String']['output'];
};

export type AllocateCouponsInput = {
  assignmentColumn: AssignmentLocation;
  companyId: Scalars['ID']['input'];
  couponType: CouponType;
  filename: Scalars['String']['input'];
  messageId: InputMaybe<Scalars['String']['input']>;
  subIdentifier: SubscriberIdentifier;
  totalRows: Scalars['Long']['input'];
};

/** Generated from com/attentivemobile/contracts/solutions/couponallocator/CouponAllocator.proto */
export type AllocateCouponsPayload = {
  __typename?: 'AllocateCouponsPayload';
  message: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/solutions/couponallocator/CouponAllocator.proto */
export type AllocationDateRange = {
  __typename?: 'AllocationDateRange';
  endTime: Maybe<Scalars['DateTime']['output']>;
  startTime: Maybe<Scalars['DateTime']['output']>;
};

export type AllocationDateRangeInput = {
  endTime: InputMaybe<Scalars['DateTime']['input']>;
  startTime: InputMaybe<Scalars['DateTime']['input']>;
};

/** Generated from com/attentivemobile/contracts/solutions/couponallocator/CouponAllocator.proto */
export type AllocationJobsPayload = {
  __typename?: 'AllocationJobsPayload';
  selectedJobs: Array<CouponAllocationJob>;
};

/** Generated from com/attentivemobile/contracts/creative-service/creative/CreativeGraphService.proto */
export type ApiSignupCreative = {
  __typename?: 'ApiSignupCreative';
  emailWelcomeJourney: Maybe<Journey>;
  id: Scalars['ID']['output'];
  smsWelcomeJourney: Maybe<Journey>;
};

export type AppStatus = {
  __typename?: 'AppStatus';
  errorCode: Maybe<Scalars['String']['output']>;
  errorMessage: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
};

export type AppTeardownTransaction = {
  __typename?: 'AppTeardownTransaction';
  reasonMessage: Maybe<Scalars['String']['output']>;
};

export enum ApplicableCustomers {
  AllCustomers = 'ALL_CUSTOMERS',
  SelectCustomers = 'SELECT_CUSTOMERS',
  SelectSegments = 'SELECT_SEGMENTS',
  UnknownCustomerType = 'UNKNOWN_CUSTOMER_TYPE'
}

export enum ApplicableProductType {
  AllItems = 'ALL_ITEMS',
  SelectCategories = 'SELECT_CATEGORIES',
  SelectProducts = 'SELECT_PRODUCTS',
  UnknownProductType = 'UNKNOWN_PRODUCT_TYPE'
}

/**
 * Generated from com/attentivemobile/contracts/externaltokens/Contract.proto
 * Application details.
 */
export type Application = Node & {
  __typename?: 'Application';
  applicationWebhook: Maybe<ApplicationWebhook>;
  customEventTypes: Array<CustomEventDefinition>;
  distributionStatus: DistributionStatus;
  /** ID of the application. */
  id: Scalars['ID']['output'];
  internalId: Scalars['Long']['output'];
  listing: ApplicationListing;
  /** Name of the application. */
  name: Scalars['String']['output'];
  providedJourneyTriggers: Maybe<JourneyTriggers>;
  publishedAt: Maybe<Scalars['DateTime']['output']>;
  responseCodeStats: Maybe<ApplicationResponseCodeStats>;
  scopes: Array<Scope>;
  settings: Maybe<ApplicationSettings>;
};


/**
 * Generated from com/attentivemobile/contracts/externaltokens/Contract.proto
 * Application details.
 */
export type ApplicationcustomEventTypesArgs = {
  companyId: InputMaybe<Scalars['ID']['input']>;
};


/**
 * Generated from com/attentivemobile/contracts/externaltokens/Contract.proto
 * Application details.
 */
export type ApplicationresponseCodeStatsArgs = {
  companyApplicationId: InputMaybe<Scalars['Long']['input']>;
  endTime: Scalars['DateTime']['input'];
  startTime: Scalars['DateTime']['input'];
};

/** Generated from com/attentivemobile/contracts/externaltokens/Contract.proto */
export type ApplicationCategory = {
  __typename?: 'ApplicationCategory';
  application: Maybe<Application>;
  name: ApplicationCategoryName;
  primary: Scalars['Boolean']['output'];
};

export enum ApplicationCategoryName {
  Analytics = 'ANALYTICS',
  ApplicationCategoryUnknown = 'APPLICATION_CATEGORY_UNKNOWN',
  Automation = 'AUTOMATION',
  CustomerDataPlatform = 'CUSTOMER_DATA_PLATFORM',
  CustomerExperience = 'CUSTOMER_EXPERIENCE',
  Ecommerce = 'ECOMMERCE',
  EmailServiceProvider = 'EMAIL_SERVICE_PROVIDER',
  Loyalty = 'LOYALTY',
  Other = 'OTHER',
  ReviewsUgc = 'REVIEWS_UGC',
  ShippingReturns = 'SHIPPING_RETURNS'
}

/** Generated from com/attentivemobile/contracts/externaltokens/Contract.proto */
export type ApplicationConnection = {
  __typename?: 'ApplicationConnection';
  edges: Array<ApplicationEdge>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/externaltokens/Contract.proto */
export type ApplicationEdge = {
  __typename?: 'ApplicationEdge';
  cursor: Scalars['String']['output'];
  node: Application;
};

/** Generated from com/attentivemobile/contracts/externaltokens/Contract.proto */
export type ApplicationListing = {
  __typename?: 'ApplicationListing';
  attentiveFeatures: Array<AttentiveFeature>;
  categories: Array<ApplicationCategory>;
  companyUrl: Maybe<Url>;
  creatorCompanyName: Scalars['String']['output'];
  description: Maybe<Scalars['String']['output']>;
  documentationLinks: Array<DocumentationLink>;
  imageUrl: Maybe<Url>;
  installUrl: Maybe<Url>;
  jointValueProp: Maybe<Scalars['String']['output']>;
  prerequisites: Array<Scalars['String']['output']>;
  salesEmail: Maybe<Scalars['String']['output']>;
  supportEmail: Maybe<Scalars['String']['output']>;
  tier: PartnershipTier;
};

/** Generated from com/attentivemobile/protobuf/common/EventOrigin.proto */
export type ApplicationOrigin = {
  __typename?: 'ApplicationOrigin';
  application: Maybe<Application>;
};

/** Generated from com/attentivemobile/contracts/externaltokens/Contract.proto */
export type ApplicationResponseCodeStat = {
  __typename?: 'ApplicationResponseCodeStat';
  endpoint: Scalars['String']['output'];
  publicEndpoint: PublicEndpoint;
  responseCodeCount: Scalars['Long']['output'];
  responseCodeValue: Scalars['Long']['output'];
};

/** Generated from com/attentivemobile/contracts/externaltokens/Contract.proto */
export type ApplicationResponseCodeStats = {
  __typename?: 'ApplicationResponseCodeStats';
  responseCodeStats: Array<ApplicationResponseCodeStat>;
};

/** Generated from com/attentivemobile/contracts/externaltokens/Contract.proto */
export type ApplicationSettings = {
  __typename?: 'ApplicationSettings';
  clientId: Scalars['String']['output'];
  clientSecret: Scalars['String']['output'];
  contactEmail: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  creatorCompany: Maybe<Company>;
  /** @deprecated deprecated */
  creatorCompanyId: Scalars['String']['output'];
  documentationFormUrl: Maybe<Url>;
  hint: Scalars['String']['output'];
  redirectUrls: Array<Url>;
  submittedForApproval: Scalars['Boolean']['output'];
  supportsWildcardRedirect: Scalars['Boolean']['output'];
};

/** Generated from com/attentivemobile/contracts/journeys/api/JourneyTrigger.proto */
export type ApplicationTriggerSource = {
  __typename?: 'ApplicationTriggerSource';
  application: Maybe<Application>;
  customEventType: Scalars['String']['output'];
};

export type ApplicationTriggerSourceInput = {
  applicationId: Scalars['ID']['input'];
  customEventType: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/externaltokens/Contract.proto */
export type ApplicationWebhook = Node & {
  __typename?: 'ApplicationWebhook';
  application: Maybe<Application>;
  clientSecret: Scalars['String']['output'];
  destination: Maybe<Scalars['String']['output']>;
  examplePayloads: Maybe<GetApplicationWebhookExamplePayloadsResponse>;
  id: Scalars['ID']['output'];
  selectedEvents: Maybe<Array<WebhookEventType>>;
  status: WebhookStatus;
  type: ApplicationWebhookType;
};


/** Generated from com/attentivemobile/contracts/externaltokens/Contract.proto */
export type ApplicationWebhookexamplePayloadsArgs = {
  eventType: ApplicationWebhookEventTypeInput;
};

export type ApplicationWebhookEventTypeInput = {
  displayName: Scalars['String']['input'];
  name: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export enum ApplicationWebhookType {
  ApplicationWebhookTypeUnknown = 'APPLICATION_WEBHOOK_TYPE_UNKNOWN',
  Subscription = 'SUBSCRIPTION',
  Universal = 'UNIVERSAL'
}

export type ApplyBrandElementStylesInput = {
  companyId: Scalars['ID']['input'];
  styles: InputMaybe<Array<BrandElementStyleInput>>;
};

/** Generated from com/attentivemobile/contracts/brand-kit/BrandElementStyles.proto */
export type ApplyBrandElementStylesPayload = {
  __typename?: 'ApplyBrandElementStylesPayload';
  company: Maybe<Company>;
  styles: Array<BrandElementStyle>;
};

export type ApplyBrandStyleInput = {
  companyId: Scalars['ID']['input'];
  styleJson: JsonStringInput;
  templateJson: JsonStringInput;
};

export type ApplyBrandStylePayload = ApplyBrandStyleSuccess | DefaultErrorFailure;

/** Generated from com/attentivemobile/contracts/composition/Contract.proto */
export type ApplyBrandStyleSuccess = {
  __typename?: 'ApplyBrandStyleSuccess';
  beeEditorJson: JsonString;
};

export enum ApplyIdentityAIParameterJourneyRevisionStatus {
  ApplyIdentityAiParameterJourneyRevisionStatusActive = 'APPLY_IDENTITY_AI_PARAMETER_JOURNEY_REVISION_STATUS_ACTIVE',
  ApplyIdentityAiParameterJourneyRevisionStatusDraft = 'APPLY_IDENTITY_AI_PARAMETER_JOURNEY_REVISION_STATUS_DRAFT',
  ApplyIdentityAiParameterJourneyRevisionStatusUnknown = 'APPLY_IDENTITY_AI_PARAMETER_JOURNEY_REVISION_STATUS_UNKNOWN'
}

export type ApplyIdentityAIParameterJourneyRevisionStatusFilter = {
  journeyRevisionStatuses: InputMaybe<Array<ApplyIdentityAIParameterJourneyRevisionStatus>>;
};

export enum ApplyIdentityAIParameterMode {
  ApplyIdentityAiParameterModeAppend = 'APPLY_IDENTITY_AI_PARAMETER_MODE_APPEND',
  ApplyIdentityAiParameterModeStrip = 'APPLY_IDENTITY_AI_PARAMETER_MODE_STRIP',
  ApplyIdentityAiParameterModeUnknown = 'APPLY_IDENTITY_AI_PARAMETER_MODE_UNKNOWN'
}

export type ApplyIdentityAIParameterPayload = {
  applyMode: ApplyIdentityAIParameterMode;
  journeyRevisionStatusFilter: InputMaybe<ApplyIdentityAIParameterJourneyRevisionStatusFilter>;
  targetQueryParameter: Scalars['String']['input'];
};

export enum ApprovalStatus {
  ApprovalStatusApprovalRequested = 'APPROVAL_STATUS_APPROVAL_REQUESTED',
  ApprovalStatusApproved = 'APPROVAL_STATUS_APPROVED',
  ApprovalStatusNeedsApproval = 'APPROVAL_STATUS_NEEDS_APPROVAL',
  ApprovalStatusRejected = 'APPROVAL_STATUS_REJECTED',
  ApprovalStatusUnknown = 'APPROVAL_STATUS_UNKNOWN'
}

export type ArchiveCreativeInput = {
  companyId: Scalars['ID']['input'];
  creativeId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/creative-service/creative/CreativeGraphService.proto */
export type ArchiveCreativePayload = {
  __typename?: 'ArchiveCreativePayload';
  creative: Creative;
};

/** Generated from com/attentivemobile/contracts/keywords/KeywordRuleService.proto */
export type AreaCodeRule = {
  __typename?: 'AreaCodeRule';
  areaCodes: Array<Scalars['String']['output']>;
  created: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  updated: Scalars['DateTime']['output'];
};

export type AskGraphQuestionInput = {
  accountId: Scalars['ID']['input'];
  content: Scalars['String']['input'];
};

export type AskGraphQuestionPayload = AskGraphQuestionSuccess | DefaultErrorFailure;

/** Generated from com/attentivemobile/contracts/graph/guru/service/GraphGuruService.proto */
export type AskGraphQuestionSuccess = {
  __typename?: 'AskGraphQuestionSuccess';
  runId: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type Asset = Folder | Image;

export type AssetFilters = {
  isGlobal: InputMaybe<Scalars['Boolean']['input']>;
  likeName: InputMaybe<Scalars['String']['input']>;
};

export type AssetId =
  { folderId: Scalars['ID']['input']; imageId?: never; }
  |  { folderId?: never; imageId: Scalars['ID']['input']; };

export enum AssetsDirection {
  AssetsDirectionAscending = 'ASSETS_DIRECTION_ASCENDING',
  AssetsDirectionDescending = 'ASSETS_DIRECTION_DESCENDING'
}

export enum AssetsOrderBy {
  AssetsOrderByDateAdded = 'ASSETS_ORDER_BY_DATE_ADDED',
  AssetsOrderByDateModified = 'ASSETS_ORDER_BY_DATE_MODIFIED',
  AssetsOrderByDimensions = 'ASSETS_ORDER_BY_DIMENSIONS',
  AssetsOrderByDisplayName = 'ASSETS_ORDER_BY_DISPLAY_NAME',
  AssetsOrderBySize = 'ASSETS_ORDER_BY_SIZE',
  AssetsOrderByType = 'ASSETS_ORDER_BY_TYPE',
  AssetsOrderByUnknown = 'ASSETS_ORDER_BY_UNKNOWN'
}

export type AssetsOrderByAndDirection = {
  direction: AssetsDirection;
  orderBy: AssetsOrderBy;
};

export type AssignCreativesToJourneyInput = {
  companyId: Scalars['ID']['input'];
  creativeId: InputMaybe<Array<Scalars['ID']['input']>>;
  journeyId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/journeys/api/Contract.proto */
export type AssignCreativesToJourneyPayload = {
  __typename?: 'AssignCreativesToJourneyPayload';
  journey: Journey;
};

export enum AssignmentLocation {
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME'
}

/** Generated from com/attentivemobile/contracts/solutions/sftp/EtlConfig.proto */
export type AssociatedCompanies = {
  __typename?: 'AssociatedCompanies';
  company: Maybe<Company>;
  isEnabled: Scalars['Boolean']['output'];
};

/** Generated from com/attentivemobile/contracts/composition/MessageAttachment.proto */
export type Attachment = {
  __typename?: 'Attachment';
  attachmentBody: AttachmentBody;
  attachmentType: AttachmentType;
  id: Maybe<Scalars['String']['output']>;
};

export type AttachmentBody = EventLookupAttachment | OfferAttachment | RecommendationAttachment | UserPropertyAttachment;

export type AttachmentBodyProperties = {
  eventLookupProperties: InputMaybe<EventLookupAttachmentProperties>;
  offerProperties: InputMaybe<OfferAttachmentProperties>;
  recommendationProperties: InputMaybe<RecommendationAttachmentProperties>;
  userPropertyProperties: InputMaybe<UserPropertyAttachmentProperties>;
};

export enum AttachmentType {
  AttachmentTypeEventLookup = 'ATTACHMENT_TYPE_EVENT_LOOKUP',
  AttachmentTypeOffer = 'ATTACHMENT_TYPE_OFFER',
  AttachmentTypeRecommendation = 'ATTACHMENT_TYPE_RECOMMENDATION',
  AttachmentTypeUnknown = 'ATTACHMENT_TYPE_UNKNOWN',
  AttachmentTypeUserProperty = 'ATTACHMENT_TYPE_USER_PROPERTY'
}

export enum AttentiveAwsRegion {
  AttentiveAwsRegionUnknown = 'ATTENTIVE_AWS_REGION_UNKNOWN',
  AttentiveAwsRegionUsEast_1 = 'ATTENTIVE_AWS_REGION_US_EAST_1',
  AttentiveAwsRegionUsEast_2 = 'ATTENTIVE_AWS_REGION_US_EAST_2'
}

/** Generated from com/attentivemobile/contracts/externaltokens/Contract.proto */
export type AttentiveFeature = {
  __typename?: 'AttentiveFeature';
  description: Scalars['String']['output'];
  type: AttentiveFeatureType;
};

export enum AttentiveFeatureType {
  AttentiveFeatureTypeUnknown = 'ATTENTIVE_FEATURE_TYPE_UNKNOWN',
  Journeys = 'JOURNEYS',
  Privacy = 'PRIVACY',
  Segments = 'SEGMENTS',
  Subscribers = 'SUBSCRIBERS'
}

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type AttentiveS3Settings = {
  __typename?: 'AttentiveS3Settings';
  bucket: Scalars['String']['output'];
  keyName: Scalars['String']['output'];
  timezone: Scalars['String']['output'];
};

export type AttentiveS3SettingsInput = {
  bucket: Scalars['String']['input'];
  keyName: Scalars['String']['input'];
  timezone: Scalars['String']['input'];
};

export enum AttributeDataType {
  DataTypeBoolean = 'DATA_TYPE_BOOLEAN',
  DataTypeDatetime = 'DATA_TYPE_DATETIME',
  DataTypeNumber = 'DATA_TYPE_NUMBER',
  DataTypeObject = 'DATA_TYPE_OBJECT',
  DataTypeString = 'DATA_TYPE_STRING',
  DataTypeUnknown = 'DATA_TYPE_UNKNOWN'
}

export enum AttributeScope {
  AttributeScopeCompany = 'ATTRIBUTE_SCOPE_COMPANY',
  AttributeScopeGlobal = 'ATTRIBUTE_SCOPE_GLOBAL',
  AttributeScopeUnknown = 'ATTRIBUTE_SCOPE_UNKNOWN'
}

/** Generated from com/attentivemobile/protobuf/common/targeting/segmentation/SearchAttribute.proto */
export type AttributeSource = {
  __typename?: 'AttributeSource';
  applicationId: Maybe<Scalars['String']['output']>;
  companyId: Maybe<Scalars['Long']['output']>;
  vendor: Maybe<Scalars['String']['output']>;
};

export enum AttributeStatus {
  StatusDisabled = 'STATUS_DISABLED',
  StatusEnabled = 'STATUS_ENABLED',
  StatusUnknown = 'STATUS_UNKNOWN'
}

export enum AttributeType {
  AttributeTypeEvent = 'ATTRIBUTE_TYPE_EVENT',
  AttributeTypeProperty = 'ATTRIBUTE_TYPE_PROPERTY',
  AttributeTypeUnknown = 'ATTRIBUTE_TYPE_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/lift/service/Contract.proto */
export type AttributionSettings = {
  __typename?: 'AttributionSettings';
  emailClickWindow: AttributionWindow;
  emailViewWindow: AttributionWindow;
  filterMachineOpened: Scalars['Boolean']['output'];
  textClickWindow: AttributionWindow;
  textViewWindow: AttributionWindow;
};

/** Generated from com/attentivemobile/contracts/lift/service/Contract.proto */
export type AttributionWindow = {
  __typename?: 'AttributionWindow';
  attributionWindowState: AttributionWindowState;
  value: Scalars['Int']['output'];
};

export type AttributionWindowInput = {
  attributionWindowState: AttributionWindowState;
  value: Scalars['Int']['input'];
};

export enum AttributionWindowState {
  AttributionWindowStateNull = 'ATTRIBUTION_WINDOW_STATE_NULL',
  AttributionWindowStateSet = 'ATTRIBUTION_WINDOW_STATE_SET',
  AttributionWindowStateUnknown = 'ATTRIBUTION_WINDOW_STATE_UNKNOWN'
}

export type AudienceAiProductCategoryInput = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/protobuf/common/AudienceAiProduct.proto */
export type AudienceAiProductFilter = {
  __typename?: 'AudienceAiProductFilter';
  categories: Array<AiCategory>;
  products: Array<AiProduct>;
};

export type AudienceAiProductFilterInput = {
  categories: InputMaybe<Array<AudienceAiProductCategoryInput>>;
  products: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Generated from com/attentivemobile/contracts/survey/poll/Contract.proto */
export type AudienceParticipant = {
  __typename?: 'AudienceParticipant';
  keyword: Scalars['String']['output'];
  keywordDescription: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  response: Scalars['String']['output'];
  subscriberId: SubscriberID;
  timestamp: Scalars['DateTime']['output'];
};

/** Generated from com/attentivemobile/contracts/survey/poll/Contract.proto */
export type AudienceResponse = {
  __typename?: 'AudienceResponse';
  count: Maybe<GetAudienceCountResponse>;
  participants: Maybe<GetAudienceParticipantsResponse>;
  poll: Maybe<Poll>;
};

/** Generated from com/attentivemobile/protobuf/common/targeting/segmentation/output/SearchDefinitionOutput.proto */
export type AudienceSearchCondition = {
  __typename?: 'AudienceSearchCondition';
  attribute: SearchAttributeIdentifier;
  isNot: Maybe<Scalars['Boolean']['output']>;
  subConditions: Array<SearchSubCondition>;
};

export type AudienceSearchConditionInput = {
  attribute: SearchAttributeInput;
  isNot: InputMaybe<Scalars['Boolean']['input']>;
  subConditions: InputMaybe<Array<SearchSubConditionInput>>;
};

/** Generated from com/attentivemobile/protobuf/common/targeting/segmentation/output/SearchDefinitionOutput.proto */
export type AudienceSearchConditions = {
  __typename?: 'AudienceSearchConditions';
  conditions: Array<AudienceSearchCondition>;
};

export type AudienceSearchConditionsInput = {
  conditions: InputMaybe<Array<AudienceSearchConditionInput>>;
};

/** Generated from com/attentivemobile/protobuf/common/targeting/segmentation/output/SearchDefinitionOutput.proto */
export type AudienceSearchDefinition = {
  __typename?: 'AudienceSearchDefinition';
  operator: SearchOperator;
  searchExpressions: Array<AudienceSearchExpressions>;
};

export type AudienceSearchDefinitionInput = {
  operator: SearchOperator;
  searchExpressions: InputMaybe<Array<AudienceSearchExpressionsInput>>;
};

/** Generated from com/attentivemobile/protobuf/common/targeting/segmentation/output/SearchDefinitionOutput.proto */
export type AudienceSearchExpressions = {
  __typename?: 'AudienceSearchExpressions';
  children: Maybe<AudienceSearchExpressionsChildren>;
  operator: SearchOperator;
};

export type AudienceSearchExpressionsChildren = AudienceSearchConditions;

export type AudienceSearchExpressionsInput = {
  children: InputMaybe<AudienceSearchExpressionsInputChildren>;
  operator: SearchOperator;
};

export type AudienceSearchExpressionsInputChildren =
  { searchConditions: AudienceSearchConditionsInput; };

export type AuditFloridaSubscribersV2Input = {
  activeSubscribersOnly: InputMaybe<Scalars['Boolean']['input']>;
  companyId: Scalars['ID']['input'];
  includeSignUpSources: InputMaybe<Scalars['Boolean']['input']>;
};

/** Generated from com/attentivemobile/contracts/litigator/service/Contract.proto */
export type AuditFloridaSubscribersV2Payload = {
  __typename?: 'AuditFloridaSubscribersV2Payload';
  status: FloridaSubscribersAuditStatusV2;
};

export enum AuditSubscriberStatusV2 {
  AuditSubscriberStatusAuditReportRunning = 'AUDIT_SUBSCRIBER_STATUS_AUDIT_REPORT_RUNNING',
  AuditSubscriberStatusFailure = 'AUDIT_SUBSCRIBER_STATUS_FAILURE',
  AuditSubscriberStatusPhoneInvalid = 'AUDIT_SUBSCRIBER_STATUS_PHONE_INVALID',
  AuditSubscriberStatusSuccess = 'AUDIT_SUBSCRIBER_STATUS_SUCCESS',
  AuditSubscriberStatusUnknown = 'AUDIT_SUBSCRIBER_STATUS_UNKNOWN'
}

export type AuditSubscriberV2Input = {
  companyId: Scalars['ID']['input'];
  markAsLitigator: Scalars['Boolean']['input'];
  phoneNumber: Scalars['String']['input'];
  savePermanently: InputMaybe<Scalars['Boolean']['input']>;
  shouldRunAudit: Scalars['Boolean']['input'];
};

/** Generated from com/attentivemobile/contracts/litigator/service/Contract.proto */
export type AuditSubscriberV2Payload = {
  __typename?: 'AuditSubscriberV2Payload';
  status: AuditSubscriberStatusV2;
  subscriberIdsOptedOut: Array<Scalars['Long']['output']>;
};

export type AuthStatus = {
  __typename?: 'AuthStatus';
  userAuthStatus: UserAuthStatus;
};

/** Generated from com/attentivemobile/contracts/account-service/AuthenticatorContract.proto */
export type Authenticator = {
  __typename?: 'Authenticator';
  account: Maybe<Account>;
  credentialName: Scalars['String']['output'];
};

/** Relates to the Auto Campaigns functionality (campaigns composed and scheduled automatically using AI). For example, filtering for 'AUTO_CAMPAIGN_FILTER_MANUAL' campaigns will only include campaigns _not_ automatically created. */
export enum AutoCampaignFilter {
  AutoCampaignFilterAll = 'AUTO_CAMPAIGN_FILTER_ALL',
  AutoCampaignFilterAuto = 'AUTO_CAMPAIGN_FILTER_AUTO',
  AutoCampaignFilterManual = 'AUTO_CAMPAIGN_FILTER_MANUAL',
  AutoCampaignFilterUnknown = 'AUTO_CAMPAIGN_FILTER_UNKNOWN'
}

export type AutoInstallTagInput = {
  companyId: Scalars['ID']['input'];
  tagUrl: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/integrations/tag/Contract.proto */
export type AutoInstallTagStatus = {
  __typename?: 'AutoInstallTagStatus';
  isAutoinstalled: Scalars['Boolean']['output'];
  isEligibleForAutoinstall: Scalars['Boolean']['output'];
  tagsInstalled: Array<AutoInstalledTag>;
};

/** Generated from com/attentivemobile/contracts/integrations/tag/Contract.proto */
export type AutoInstallTagStatusPayload = {
  __typename?: 'AutoInstallTagStatusPayload';
  tagStatus: AutoInstallTagStatus;
};

/** Generated from com/attentivemobile/contracts/integrations/tag/Contract.proto */
export type AutoInstalledTag = {
  __typename?: 'AutoInstalledTag';
  tagUrl: Scalars['String']['output'];
  vendor: Vendor;
};

/** Generated from com/attentivemobile/contracts/messaging/automation/autojourneys/AutoJourneysModel.proto */
export type AutoJourney = Node & {
  __typename?: 'AutoJourney';
  aiJourneyDefaultMessages: Maybe<Array<AIJourneyDefaultMessage>>;
  aiJourneyLinkTrackingParams: Maybe<Array<AIJourneyTrackingParams>>;
  aiJourneyLinkTypeConfigurations: Maybe<Array<AIJourneyLinkConfiguration>>;
  autoJourneyType: Maybe<AutoJourneyType>;
  clientSettings: AutoJourneyClientSettings;
  company: Maybe<Company>;
  config: AutoJourneyConfig;
  created: Maybe<Scalars['DateTime']['output']>;
  getShopifyAutoApplyFormats: Maybe<GetShopifyAutoApplyFormatsPayload>;
  id: Scalars['ID']['output'];
  messages: Array<AutoJourneyMessage>;
  name: Scalars['String']['output'];
  status: AutoJourneyStatus;
  /** @deprecated deprecated */
  type: JourneyTriggerType;
  updated: Maybe<Scalars['DateTime']['output']>;
  verifyAIJourneyMessageSent: Maybe<VerifyAIJourneyMessageSentPayload>;
};


/** Generated from com/attentivemobile/contracts/messaging/automation/autojourneys/AutoJourneysModel.proto */
export type AutoJourneyverifyAIJourneyMessageSentArgs = {
  lookbackDurationMinutes: Scalars['Int']['input'];
  messageType: E2ETestMessageType;
  userCompanyId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/messaging/automation/autojourneys/AutoJourneysModel.proto */
export type AutoJourneyClientSettings = {
  __typename?: 'AutoJourneyClientSettings';
  utmInputSettings: UtmSettings;
};

export type AutoJourneyClientSettingsInput = {
  utmSettingsInput: UtmSettingsInput;
};

/** Generated from com/attentivemobile/contracts/messaging/automation/autojourneys/AutoJourneysModel.proto */
export type AutoJourneyConfig = {
  __typename?: 'AutoJourneyConfig';
  journey: Maybe<Journey>;
  journeyId: Maybe<Scalars['Long']['output']>;
  trafficPercentage: Maybe<Scalars['Float']['output']>;
};

export type AutoJourneyConfigInput = {
  journeyId: InputMaybe<Scalars['Long']['input']>;
  trafficPercentage: InputMaybe<Scalars['Float']['input']>;
};

/** Generated from com/attentivemobile/contracts/messaging/automation/autojourneys/AutoJourneysModel.proto */
export type AutoJourneyMessage = {
  __typename?: 'AutoJourneyMessage';
  composeMessage: Maybe<ComposeMessage>;
};

export enum AutoJourneyStatus {
  AutoJourneyStatusActive = 'AUTO_JOURNEY_STATUS_ACTIVE',
  AutoJourneyStatusDeleted = 'AUTO_JOURNEY_STATUS_DELETED',
  AutoJourneyStatusPaused = 'AUTO_JOURNEY_STATUS_PAUSED',
  AutoJourneyStatusUnknown = 'AUTO_JOURNEY_STATUS_UNKNOWN'
}

export enum AutoJourneyTriggerType {
  AutoJourneyTriggerTypeCartAbandonment = 'AUTO_JOURNEY_TRIGGER_TYPE_CART_ABANDONMENT',
  AutoJourneyTriggerTypeUnknown = 'AUTO_JOURNEY_TRIGGER_TYPE_UNKNOWN'
}

export enum AutoJourneyType {
  CartAbandonment = 'CART_ABANDONMENT',
  Unified = 'UNIFIED',
  Unknown = 'UNKNOWN',
  Welcome = 'WELCOME'
}

/** Generated from com/attentivemobile/contracts/company-service/region/Model.proto */
export type AutomatedMessage = {
  __typename?: 'AutomatedMessage';
  message: Scalars['String']['output'];
  type: AutomatedMessageType;
};

export type AutomatedMessageInput = {
  message: Scalars['String']['input'];
  type: AutomatedMessageType;
};

export enum AutomatedMessageType {
  AutomatedMessageTypeAlreadySubscribed = 'AUTOMATED_MESSAGE_TYPE_ALREADY_SUBSCRIBED',
  AutomatedMessageTypeAuto = 'AUTOMATED_MESSAGE_TYPE_AUTO',
  AutomatedMessageTypeDoubleOptIn = 'AUTOMATED_MESSAGE_TYPE_DOUBLE_OPT_IN',
  AutomatedMessageTypeFuzzyOptOut = 'AUTOMATED_MESSAGE_TYPE_FUZZY_OPT_OUT',
  AutomatedMessageTypeHelp = 'AUTOMATED_MESSAGE_TYPE_HELP',
  AutomatedMessageTypeLegal = 'AUTOMATED_MESSAGE_TYPE_LEGAL',
  AutomatedMessageTypeStart = 'AUTOMATED_MESSAGE_TYPE_START',
  AutomatedMessageTypeStop = 'AUTOMATED_MESSAGE_TYPE_STOP',
  AutomatedMessageTypeUnknown = 'AUTOMATED_MESSAGE_TYPE_UNKNOWN',
  AutomatedMessageTypeUnsubscribe = 'AUTOMATED_MESSAGE_TYPE_UNSUBSCRIBE'
}

/** Generated from com/attentivemobile/contracts/messaging/automation/Contract.proto */
export type AutomationProgram = Node & {
  __typename?: 'AutomationProgram';
  company: Maybe<Company>;
  /** The Coupon Set that will be attached to campaigns created by Auto Campaigns */
  couponSet: Maybe<CouponSet>;
  description: Scalars['String']['output'];
  emailNotificationSettings: Maybe<EmailNotificationSettings>;
  id: Scalars['ID']['output'];
  messageLinkTargetUrl: Maybe<Scalars['String']['output']>;
  prebuiltContent: Array<PrebuiltContent>;
  prebuiltProgramId: Scalars['String']['output'];
  programCadence: Maybe<ProgramCadence>;
  programOrchestration: Array<ProgramOrchestration>;
  scheduleType: ScheduleType;
  status: ProgramStatus;
  thumbnail: Scalars['String']['output'];
  title: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/messaging/automation/Contract.proto */
export type AutomationProgramConnection = {
  __typename?: 'AutomationProgramConnection';
  edges: Array<AutomationProgramEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Long']['output'];
};

/** Generated from com/attentivemobile/contracts/messaging/automation/Contract.proto */
export type AutomationProgramEdge = {
  __typename?: 'AutomationProgramEdge';
  cursor: Scalars['String']['output'];
  node: AutomationProgram;
};

/** Generated from com/attentivemobile/contracts/brand-kit/BrandFont.proto */
export type AvailableBrandFont = {
  __typename?: 'AvailableBrandFont';
  fileUuid: Maybe<Scalars['String']['output']>;
  fontFamilyName: Scalars['String']['output'];
  format: Scalars['String']['output'];
  url: Url;
  variants: Array<BrandFontVariant>;
};

/** Generated from com/attentivemobile/contracts/brand-kit/BrandFont.proto */
export type AvailableBrandFontSource = {
  __typename?: 'AvailableBrandFontSource';
  format: Scalars['String']['output'];
  url: Url;
};

export type AvailableBrandFontsInput = {
  fileUuid: InputMaybe<Scalars['String']['input']>;
  fontFamilyName: Scalars['String']['input'];
  format: Scalars['String']['input'];
  url: InputMaybe<Scalars['String']['input']>;
  variants: InputMaybe<Array<BrandFontVariantInput>>;
};

/** Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto */
export type BEEColumn = {
  __typename?: 'BEEColumn';
  modules: Array<BEEModuleContentsWrapper>;
};

export type BEEModuleContents = ButtonContents | HeadingContents | ImageContents | ParagraphContents;

/** Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto */
export type BEEModuleContentsWrapper = {
  __typename?: 'BEEModuleContentsWrapper';
  contents: BEEModuleContents;
};

export enum BackfillType {
  ScheduledReport = 'SCHEDULED_REPORT',
  Sftp = 'SFTP'
}

export type BaseAudience = {
  excludedSegments: InputMaybe<Array<Scalars['ID']['input']>>;
  includedSegments: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type BaseAudienceDefinition = {
  channels: InputMaybe<Array<Channel>>;
  isExcludingFatiguedUsers: Scalars['Boolean']['input'];
  isIncludingOptOutUsers: Scalars['Boolean']['input'];
};

export type BatchMigrateJobsInput = {
  allowedDatastreamNames: InputMaybe<Array<Scalars['String']['input']>>;
  batchSize: Scalars['Int']['input'];
  completedCheckpointCount: Scalars['Int']['input'];
  flinkDeploymentMode: InputMaybe<FlinkDeploymentMode>;
  newCluster: InputMaybe<Cluster>;
  oldCluster: InputMaybe<Cluster>;
  pulsarClusterName: InputMaybe<Scalars['String']['input']>;
  startMode: OperationMode;
  stopMode: OperationMode;
};

/** Generated from com/attentivemobile/contracts/event/platform/batchjobs/service/Contract.proto */
export type BatchMigrateJobsPayload = {
  __typename?: 'BatchMigrateJobsPayload';
  workflowId: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/compose/api/Contract.proto */
export type BeeFreeAuthToken = {
  __typename?: 'BeeFreeAuthToken';
  accessToken: Scalars['String']['output'];
  error: Maybe<Scalars['String']['output']>;
  expiresIn: Scalars['Int']['output'];
  refreshToken: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto */
export type BeeModuleStyleOverrides = {
  __typename?: 'BeeModuleStyleOverrides';
  textAlign: Maybe<Scalars['String']['output']>;
  width: Maybe<Scalars['String']['output']>;
};

/**
 * Generated from com/attentivemobile/contracts/event/ecommerce/Order.proto
 * A representation of the amounts billed to a customer.
 */
export type BillingTotals = {
  __typename?: 'BillingTotals';
  /** The price of the order after discounts are applied, but before shipping costs, duties, taxes, and tips are applied */
  subtotalPrice: Money;
  /** The sum of all discounts applicable to this order */
  totalDiscount: Money;
  /** The sum of all duty costs */
  totalDutyCost: Money;
  /** The final price of the order (sum of the subtotal prices and all shipping costs, duties, taxes, and tips applicable to the order) */
  totalPrice: Money;
  /** The sum of all shipping costs */
  totalShippingCost: Money;
  /** The sum of all taxes applicable to this order */
  totalTax: Money;
};

/** Generated from com/attentivemobile/contracts/personalization/service/aijourneys/onboarding/companyinfo/BlockCompanyInfo.proto */
export type BlockCompanyInfo = {
  __typename?: 'BlockCompanyInfo';
  company: Maybe<Company>;
  companyInfoType: BlockCompanyInfoType;
  companyInfoValue: Scalars['String']['output'];
};

export type BlockCompanyInfoInput = {
  companyInfoType: BlockCompanyInfoType;
  companyInfoValue: Scalars['String']['input'];
};

export enum BlockCompanyInfoType {
  BlockCompanyInfoTypeAdditional = 'BLOCK_COMPANY_INFO_TYPE_ADDITIONAL',
  BlockCompanyInfoTypeReturns = 'BLOCK_COMPANY_INFO_TYPE_RETURNS',
  BlockCompanyInfoTypeShipping = 'BLOCK_COMPANY_INFO_TYPE_SHIPPING'
}

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type BlogPost = Node & {
  __typename?: 'BlogPost';
  categoryName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  imageUrl: Scalars['String']['output'];
  published: Scalars['DateTime']['output'];
  slug: Scalars['String']['output'];
  subtitle: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type BlogPostConnection = {
  __typename?: 'BlogPostConnection';
  edges: Array<BlogPostEdge>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type BlogPostEdge = {
  __typename?: 'BlogPostEdge';
  cursor: Scalars['String']['output'];
  node: BlogPost;
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type BoolPropertyValue = {
  __typename?: 'BoolPropertyValue';
  value: Scalars['Boolean']['output'];
};

/** Generated from com/attentivemobile/protobuf/common/targeting/segmentation/output/ComparisonTypes.proto */
export type BooleanComparison = {
  __typename?: 'BooleanComparison';
  comparator: SearchBooleanComparator;
  value: Scalars['Boolean']['output'];
};

export type BooleanComparisonInput = {
  comparator: SearchBooleanComparator;
  value: Scalars['Boolean']['input'];
};

export type BooleanDatasetFilter = {
  value: Scalars['Boolean']['input'];
};

/** Generated from com/attentivemobile/contracts/metricsapi/DatasetsContract.proto */
export type BooleanDatasetValue = {
  __typename?: 'BooleanDatasetValue';
  value: Scalars['Boolean']['output'];
};

/** Generated from com/attentivemobile/contracts/user/profile/property/UserPropertyV2.proto */
export type BooleanPropertyValueV2 = {
  __typename?: 'BooleanPropertyValueV2';
  booleanValue: Scalars['Boolean']['output'];
};

/** Generated from com/attentivemobile/contracts/brand-kit/BrandColorSchemeService.proto */
export type BrandColor = {
  __typename?: 'BrandColor';
  element: Element;
  value: Scalars['String']['output'];
};

export type BrandColorInput = {
  element: Element;
  value: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/brand-kit/BrandElementStyles.proto */
export type BrandElementStyle = {
  __typename?: 'BrandElementStyle';
  element: StylableElement;
  property: ElementProperty;
  value: Scalars['String']['output'];
};

export type BrandElementStyleInput = {
  element: StylableElementInput;
  property: ElementPropertyInput;
  value: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/brand-kit/BrandFont.proto */
export type BrandFontProperties = {
  __typename?: 'BrandFontProperties';
  property: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type BrandFontPropertiesInput = {
  property: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/brand-kit/BrandFont.proto */
export type BrandFontVariant = {
  __typename?: 'BrandFontVariant';
  style: Scalars['String']['output'];
  weight: Scalars['String']['output'];
};

export type BrandFontVariantInput = {
  fileUuid: InputMaybe<Scalars['String']['input']>;
  style: Scalars['String']['input'];
  weight: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/brand-kit/BrandImage.proto */
export type BrandImage = {
  __typename?: 'BrandImage';
  companyId: Scalars['String']['output'];
  fileUuid: Scalars['String']['output'];
  name: Scalars['String']['output'];
  size: Scalars['Int']['output'];
  style: ImageStyle;
  type: ImageType;
  url: Url;
};

export type BulkMigrateTagPresetsInput = {
  companyIds: InputMaybe<Array<Scalars['ID']['input']>>;
  preset: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/tagbuildservice/taghealth/Contract.proto */
export type BulkMigrateTagPresetsPayload = {
  __typename?: 'BulkMigrateTagPresetsPayload';
  releaseIds: Array<Scalars['Long']['output']>;
};

export type BulkModifyTagExperimentsInput = {
  companyIds: InputMaybe<Array<Scalars['ID']['input']>>;
  experimentId: Scalars['String']['input'];
  isExperimentOn: Scalars['Boolean']['input'];
};

export type BulkModifyTagExperimentsPayload = BulkModifyTagExperimentsSuccess | DefaultErrorFailure;

/** Generated from com/attentivemobile/contracts/tagbuildservice/taghealth/Contract.proto */
export type BulkModifyTagExperimentsSuccess = {
  __typename?: 'BulkModifyTagExperimentsSuccess';
  releaseIds: Array<Scalars['Long']['output']>;
};

export type BulkUpdateCompositionMessagesInput = {
  companyId: Scalars['ID']['input'];
  messagesToUpdate: InputMaybe<Array<UpdateCompositionMessageInput>>;
};

/** Generated from com/attentivemobile/contracts/composition/Contract.proto */
export type BulkUpdateCompositionMessagesPayload = {
  __typename?: 'BulkUpdateCompositionMessagesPayload';
  updatedMessages: Array<ComposeMessage>;
};

/** Generated from com/attentivemobile/contracts/segmentation/service/search/contract.proto */
export type BulkUserSearchCountsResponse = {
  __typename?: 'BulkUserSearchCountsResponse';
  audienceResponses: Array<UserSearchCountsResponse>;
};

/** Generated from com/attentivemobile/contracts/company-service/Contract.proto */
export type BusinessHours = {
  __typename?: 'BusinessHours';
  close: Scalars['String']['output'];
  open: Scalars['String']['output'];
  timezone: Scalars['String']['output'];
};

export enum ButtonAction {
  ButtonActionClose = 'BUTTON_ACTION_CLOSE',
  ButtonActionLink = 'BUTTON_ACTION_LINK',
  ButtonActionUnknown = 'BUTTON_ACTION_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto */
export type ButtonContents = {
  __typename?: 'ButtonContents';
  href: Scalars['String']['output'];
  styleOverrides: Maybe<BeeModuleStyleOverrides>;
  text: Scalars['String']['output'];
};

export enum CadenceComparator {
  CadenceComparatorLatest = 'CADENCE_COMPARATOR_LATEST',
  CadenceComparatorUnknown = 'CADENCE_COMPARATOR_UNKNOWN'
}

export type CadenceCondition = {
  comparator: CadenceComparator;
};

/** Generated from com/attentivemobile/contracts/message/api/query/Contract.proto */
export type Campaign = Node & {
  __typename?: 'Campaign';
  company: Maybe<Company>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export enum CampaignAction {
  CampaignActionCampaignRateLimitUpdated = 'CAMPAIGN_ACTION_CAMPAIGN_RATE_LIMIT_UPDATED',
  CampaignActionCancelled = 'CAMPAIGN_ACTION_CANCELLED',
  CampaignActionCreated = 'CAMPAIGN_ACTION_CREATED',
  CampaignActionDeleted = 'CAMPAIGN_ACTION_DELETED',
  CampaignActionDowngraded = 'CAMPAIGN_ACTION_DOWNGRADED',
  CampaignActionDrafted = 'CAMPAIGN_ACTION_DRAFTED',
  CampaignActionPaused = 'CAMPAIGN_ACTION_PAUSED',
  CampaignActionRescheduled = 'CAMPAIGN_ACTION_RESCHEDULED',
  CampaignActionResumed = 'CAMPAIGN_ACTION_RESUMED',
  CampaignActionSendFinished = 'CAMPAIGN_ACTION_SEND_FINISHED',
  CampaignActionSendStarted = 'CAMPAIGN_ACTION_SEND_STARTED',
  CampaignActionTimezoneSendFinished = 'CAMPAIGN_ACTION_TIMEZONE_SEND_FINISHED',
  CampaignActionTimezoneSendStarted = 'CAMPAIGN_ACTION_TIMEZONE_SEND_STARTED',
  CampaignActionUnknown = 'CAMPAIGN_ACTION_UNKNOWN',
  CampaignActionUpdated = 'CAMPAIGN_ACTION_UPDATED'
}

/** Generated from com/attentivemobile/protobuf/common/campaigns/Campaign.proto */
export type CampaignAudienceFilter = {
  __typename?: 'CampaignAudienceFilter';
  oneofFilterProperties: Maybe<CampaignAudienceFilterOneofFilterProperties>;
};

export type CampaignAudienceFilterOneofFilterProperties = PartnerCampaignAudienceFilter | SampleAudienceFilter | SegmentAudienceFilter | SliceAudienceFilter | SubscriberOptimizedSendTimeAudienceFilter | TimezoneAudienceFilter;

/** Generated from com/attentivemobile/protobuf/common/campaigns/Campaign.proto */
export type CampaignCompositionValidationError = {
  __typename?: 'CampaignCompositionValidationError';
  code: CampaignCompositionValidationErrorCode;
  message: Scalars['String']['output'];
};

export enum CampaignCompositionValidationErrorCode {
  CampaignCompositionValidationErrorCodeCouponRefillRequired = 'CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_COUPON_REFILL_REQUIRED',
  CampaignCompositionValidationErrorCodeEmailMessageFromAddressEmpty = 'CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_EMAIL_MESSAGE_FROM_ADDRESS_EMPTY',
  CampaignCompositionValidationErrorCodeEmailMessageReplyToAddressEmpty = 'CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_EMAIL_MESSAGE_REPLY_TO_ADDRESS_EMPTY',
  CampaignCompositionValidationErrorCodeEmailMessageSubjectEmpty = 'CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_EMAIL_MESSAGE_SUBJECT_EMPTY',
  CampaignCompositionValidationErrorCodeEmptyCampaign = 'CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_EMPTY_CAMPAIGN',
  CampaignCompositionValidationErrorCodeEmptyExperimentMetadata = 'CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_EMPTY_EXPERIMENT_METADATA',
  CampaignCompositionValidationErrorCodeGroupLimitExceeded = 'CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_GROUP_LIMIT_EXCEEDED',
  CampaignCompositionValidationErrorCodeGroupOrderError = 'CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_GROUP_ORDER_ERROR',
  CampaignCompositionValidationErrorCodeInvalidSegment = 'CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_INVALID_SEGMENT',
  CampaignCompositionValidationErrorCodeMessageBodyEmpty = 'CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_MESSAGE_BODY_EMPTY',
  CampaignCompositionValidationErrorCodeNameEmpty = 'CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_NAME_EMPTY',
  CampaignCompositionValidationErrorCodeNameLengthExceeded = 'CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_NAME_LENGTH_EXCEEDED',
  CampaignCompositionValidationErrorCodeSegmentIncludedAndExcluded = 'CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_SEGMENT_INCLUDED_AND_EXCLUDED',
  CampaignCompositionValidationErrorCodeStartTimeEmpty = 'CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_START_TIME_EMPTY',
  CampaignCompositionValidationErrorCodeStartTimeInvalid = 'CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_START_TIME_INVALID',
  CampaignCompositionValidationErrorCodeSteMessageContentOutOfSync = 'CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_STE_MESSAGE_CONTENT_OUT_OF_SYNC',
  CampaignCompositionValidationErrorCodeStoDateConflict = 'CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_STO_DATE_CONFLICT',
  CampaignCompositionValidationErrorCodeSubscriberLocalTzSendNotAllowed = 'CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_SUBSCRIBER_LOCAL_TZ_SEND_NOT_ALLOWED',
  CampaignCompositionValidationErrorCodeUnknown = 'CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_UNKNOWN'
}

/** Generated from com/attentivemobile/protobuf/common/campaigns/Campaign.proto */
export type CampaignEvent = {
  __typename?: 'CampaignEvent';
  action: CampaignAction;
  comment: Scalars['String']['output'];
  source: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
  traceId: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/protobuf/common/campaigns/Campaign.proto */
export type CampaignExperimentMetadata = {
  __typename?: 'CampaignExperimentMetadata';
  campaignExperimentSubtype: Maybe<CampaignExperimentSubtype>;
  delayPeriodSeconds: Scalars['Int']['output'];
  isPickAWinnerEnabled: Scalars['Boolean']['output'];
  samplePercentage: Scalars['Float']['output'];
  testSendTime: SendTime;
  winnerPickedTimestamp: Maybe<Scalars['DateTime']['output']>;
  winningMessageMember: Maybe<CampaignMessageMember>;
  winningVariantCriteria: CampaignWinningVariantCriteria;
};

export type CampaignExperimentMetadataAttachmentInput = {
  delayPeriodSeconds: InputMaybe<Scalars['Int']['input']>;
  /** @deprecated deprecated */
  experimentSendTimes: InputMaybe<Array<Scalars['DateTime']['input']>>;
  isPickAWinnerEnabled: InputMaybe<Scalars['Boolean']['input']>;
  /** @deprecated deprecated */
  metadataId: InputMaybe<Scalars['ID']['input']>;
  samplePercentage: InputMaybe<Scalars['Float']['input']>;
  winnerPickedTimestamp: InputMaybe<Scalars['DateTime']['input']>;
  winningCampaignMessageMemberId: InputMaybe<Scalars['ID']['input']>;
  winningVariantCriteria: InputMaybe<CampaignWinningVariantCriteria>;
};

export enum CampaignExperimentSubtype {
  CampaignExperimentSubtypeContentBodyExperiment = 'CAMPAIGN_EXPERIMENT_SUBTYPE_CONTENT_BODY_EXPERIMENT',
  CampaignExperimentSubtypeSubjectLineExperiment = 'CAMPAIGN_EXPERIMENT_SUBTYPE_SUBJECT_LINE_EXPERIMENT',
  CampaignExperimentSubtypeUnknown = 'CAMPAIGN_EXPERIMENT_SUBTYPE_UNKNOWN'
}

/** Generated from com/attentivemobile/protobuf/common/campaigns/Campaign.proto */
export type CampaignHistory = {
  __typename?: 'CampaignHistory';
  events: Array<CampaignEvent>;
};

export type CampaignMessage = {
  __typename?: 'CampaignMessage';
  attachments: Array<CampaignMessageAttachment>;
  links: Array<CampaignMessageLink>;
  messageId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  numSegments: Scalars['Int']['output'];
  text: Scalars['String']['output'];
};

export type CampaignMessageAttachment = {
  __typename?: 'CampaignMessageAttachment';
  linkId: Scalars['Int']['output'];
  shortLink: Scalars['String']['output'];
  shortUrlMacroPlaceholder: Scalars['String']['output'];
  target: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/protobuf/common/campaigns/Campaign.proto */
export type CampaignMessageGroup = Node & {
  __typename?: 'CampaignMessageGroup';
  assignedTags: Maybe<TagsConnection>;
  company: Maybe<Company>;
  created: Scalars['DateTime']['output'];
  downgradeApproved: Scalars['Boolean']['output'];
  experimentMetadata: Maybe<CampaignExperimentMetadata>;
  history: CampaignHistory;
  id: Scalars['ID']['output'];
  messageMembers: Array<CampaignMessageMember>;
  name: Scalars['String']['output'];
  passedTimezonesStrategy: Maybe<PassedTimezonesStrategy>;
  rateLimits: RateLimits;
  rawUuid: Scalars['String']['output'];
  scheduleType: CampaignScheduleType;
  segments: Array<SegmentAudienceFilter>;
  sendTimeExperimentMetadata: Maybe<SendTimeExperimentMetadata>;
  sendTimes: Array<SendTime>;
  smartSendingEnabled: Scalars['Boolean']['output'];
  startTime: Maybe<Scalars['DateTime']['output']>;
  status: CampaignMessageGroupStatus;
  type: CampaignMessageGroupType;
  updated: Scalars['DateTime']['output'];
  validationErrors: Array<CampaignCompositionValidationError>;
};


/** Generated from com/attentivemobile/protobuf/common/campaigns/Campaign.proto */
export type CampaignMessageGroupassignedTagsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  taggableEntityType: TaggableEntityType;
};

/** Generated from com/attentivemobile/contracts/campaigns/service/Contract.proto */
export type CampaignMessageGroupEdge = {
  __typename?: 'CampaignMessageGroupEdge';
  cursor: Scalars['String']['output'];
  node: CampaignMessageGroup;
};

export enum CampaignMessageGroupExperimentSubtype {
  CampaignMessageGroupExperimentSubtypeContentBodyExperiment = 'CAMPAIGN_MESSAGE_GROUP_EXPERIMENT_SUBTYPE_CONTENT_BODY_EXPERIMENT',
  CampaignMessageGroupExperimentSubtypeSubjectLineExperiment = 'CAMPAIGN_MESSAGE_GROUP_EXPERIMENT_SUBTYPE_SUBJECT_LINE_EXPERIMENT',
  CampaignMessageGroupExperimentSubtypeUnknown = 'CAMPAIGN_MESSAGE_GROUP_EXPERIMENT_SUBTYPE_UNKNOWN'
}

export enum CampaignMessageGroupExperimentType {
  CampaignMessageGroupExperimentTypeExperiment = 'CAMPAIGN_MESSAGE_GROUP_EXPERIMENT_TYPE_EXPERIMENT',
  CampaignMessageGroupExperimentTypeSendTimeExperiment = 'CAMPAIGN_MESSAGE_GROUP_EXPERIMENT_TYPE_SEND_TIME_EXPERIMENT',
  CampaignMessageGroupExperimentTypeUnknown = 'CAMPAIGN_MESSAGE_GROUP_EXPERIMENT_TYPE_UNKNOWN'
}

/** Only one of the compose_message_id and compose_message_id_to_clone will be accepted. compose_message_id - if passed in, we will update this message. compose_message_id_to_clone - if passed in, we will copy this message into a new message */
export type CampaignMessageGroupMessageMemberInput = {
  channel: ComposeMessageChannel;
  composeMessageId: InputMaybe<Scalars['ID']['input']>;
  composeMessageIdToClone: InputMaybe<Scalars['ID']['input']>;
  schedulingMetadata: InputMaybe<SchedulingMetadataInput>;
};

export enum CampaignMessageGroupStatus {
  CampaignMessageGroupStatusAttentiveReview = 'CAMPAIGN_MESSAGE_GROUP_STATUS_ATTENTIVE_REVIEW',
  CampaignMessageGroupStatusCancelled = 'CAMPAIGN_MESSAGE_GROUP_STATUS_CANCELLED',
  CampaignMessageGroupStatusDraft = 'CAMPAIGN_MESSAGE_GROUP_STATUS_DRAFT',
  CampaignMessageGroupStatusNeedsCoupons = 'CAMPAIGN_MESSAGE_GROUP_STATUS_NEEDS_COUPONS',
  CampaignMessageGroupStatusPaused = 'CAMPAIGN_MESSAGE_GROUP_STATUS_PAUSED',
  CampaignMessageGroupStatusRescheduled = 'CAMPAIGN_MESSAGE_GROUP_STATUS_RESCHEDULED',
  CampaignMessageGroupStatusScheduled = 'CAMPAIGN_MESSAGE_GROUP_STATUS_SCHEDULED',
  CampaignMessageGroupStatusScheduledDraft = 'CAMPAIGN_MESSAGE_GROUP_STATUS_SCHEDULED_DRAFT',
  CampaignMessageGroupStatusSending = 'CAMPAIGN_MESSAGE_GROUP_STATUS_SENDING',
  CampaignMessageGroupStatusSendError = 'CAMPAIGN_MESSAGE_GROUP_STATUS_SEND_ERROR',
  CampaignMessageGroupStatusSent = 'CAMPAIGN_MESSAGE_GROUP_STATUS_SENT',
  CampaignMessageGroupStatusUnknown = 'CAMPAIGN_MESSAGE_GROUP_STATUS_UNKNOWN',
  CampaignMessageGroupStatusUnsavedDraft = 'CAMPAIGN_MESSAGE_GROUP_STATUS_UNSAVED_DRAFT'
}

export enum CampaignMessageGroupType {
  CampaignMessageGroupTypeExperiment = 'CAMPAIGN_MESSAGE_GROUP_TYPE_EXPERIMENT',
  CampaignMessageGroupTypeOneTime = 'CAMPAIGN_MESSAGE_GROUP_TYPE_ONE_TIME',
  CampaignMessageGroupTypeSendTimeExperiment = 'CAMPAIGN_MESSAGE_GROUP_TYPE_SEND_TIME_EXPERIMENT',
  CampaignMessageGroupTypeUnknown = 'CAMPAIGN_MESSAGE_GROUP_TYPE_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/campaigns/service/Contract.proto */
export type CampaignMessageGroupsConnection = {
  __typename?: 'CampaignMessageGroupsConnection';
  edges: Array<CampaignMessageGroupEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Long']['output'];
};

export type CampaignMessageLink = {
  __typename?: 'CampaignMessageLink';
  id: Scalars['Int']['output'];
  shortLink: Scalars['String']['output'];
  target: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/protobuf/common/campaigns/Campaign.proto */
export type CampaignMessageMember = Node & {
  __typename?: 'CampaignMessageMember';
  company: Maybe<Company>;
  composeMessage: Maybe<ComposeMessage>;
  estimatedRecipients: Maybe<EstimatedRecipientCount>;
  experimentStatSnapshotValue: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  remainingRecipients: Maybe<RemainingRecipientsCount>;
  schedulingMetadata: Maybe<SchedulingMetadata>;
};


/** Generated from com/attentivemobile/protobuf/common/campaigns/Campaign.proto */
export type CampaignMessageMemberestimatedRecipientsArgs = {
  channel: InputMaybe<ComposeMessageChannel>;
  isSmartSendingEnabled: InputMaybe<Scalars['Boolean']['input']>;
  segmentIdsExcluded: InputMaybe<Array<Scalars['ID']['input']>>;
  segmentIdsIncluded: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Generated from com/attentivemobile/contracts/messaging/automation/Contract.proto */
export type CampaignOrchestration = {
  __typename?: 'CampaignOrchestration';
  campaign: Maybe<ComposeCampaign>;
};

export enum CampaignScheduleType {
  CampaignScheduleTypeInstant = 'CAMPAIGN_SCHEDULE_TYPE_INSTANT',
  CampaignScheduleTypeSubscriberLocalTime = 'CAMPAIGN_SCHEDULE_TYPE_SUBSCRIBER_LOCAL_TIME',
  CampaignScheduleTypeSubscriberOptimizedSendTime = 'CAMPAIGN_SCHEDULE_TYPE_SUBSCRIBER_OPTIMIZED_SEND_TIME',
  CampaignScheduleTypeUnknown = 'CAMPAIGN_SCHEDULE_TYPE_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/concierge/service/Message.proto */
export type CampaignSource = {
  __typename?: 'CampaignSource';
  campaign: Maybe<Campaign>;
};

/** Generated from com/attentivemobile/contracts/campaigns/service/Stats.proto */
export type CampaignStats = {
  __typename?: 'CampaignStats';
  campaignId: Scalars['String']['output'];
  campaignUuid: Scalars['String']['output'];
  messagesStats: Array<MessageStats>;
  sendCounts: Scalars['Int']['output'];
  sendRequests: Scalars['Int']['output'];
};

export enum CampaignStatus {
  CampaignStatusAttentiveReview = 'CAMPAIGN_STATUS_ATTENTIVE_REVIEW',
  CampaignStatusCancelled = 'CAMPAIGN_STATUS_CANCELLED',
  CampaignStatusDraft = 'CAMPAIGN_STATUS_DRAFT',
  CampaignStatusNeedsCoupons = 'CAMPAIGN_STATUS_NEEDS_COUPONS',
  CampaignStatusPaused = 'CAMPAIGN_STATUS_PAUSED',
  CampaignStatusRescheduled = 'CAMPAIGN_STATUS_RESCHEDULED',
  CampaignStatusScheduled = 'CAMPAIGN_STATUS_SCHEDULED',
  CampaignStatusScheduledDraft = 'CAMPAIGN_STATUS_SCHEDULED_DRAFT',
  CampaignStatusSending = 'CAMPAIGN_STATUS_SENDING',
  CampaignStatusSendError = 'CAMPAIGN_STATUS_SEND_ERROR',
  CampaignStatusSent = 'CAMPAIGN_STATUS_SENT',
  CampaignStatusUnknown = 'CAMPAIGN_STATUS_UNKNOWN',
  CampaignStatusUnsavedDraft = 'CAMPAIGN_STATUS_UNSAVED_DRAFT'
}

export type CampaignTagInput = {
  name: Scalars['String']['input'];
  uuid: Scalars['String']['input'];
};

export type CampaignTagsInput = {
  tag: InputMaybe<Array<CampaignTagInput>>;
};

export enum CampaignWinningVariantCriteria {
  CampaignWinningVariantCriteriaClickThroughRate = 'CAMPAIGN_WINNING_VARIANT_CRITERIA_CLICK_THROUGH_RATE',
  CampaignWinningVariantCriteriaConversionRate = 'CAMPAIGN_WINNING_VARIANT_CRITERIA_CONVERSION_RATE',
  CampaignWinningVariantCriteriaOpenRate = 'CAMPAIGN_WINNING_VARIANT_CRITERIA_OPEN_RATE',
  CampaignWinningVariantCriteriaOptOutRate = 'CAMPAIGN_WINNING_VARIANT_CRITERIA_OPT_OUT_RATE',
  CampaignWinningVariantCriteriaTotalRevenue = 'CAMPAIGN_WINNING_VARIANT_CRITERIA_TOTAL_REVENUE',
  CampaignWinningVariantCriteriaUnknown = 'CAMPAIGN_WINNING_VARIANT_CRITERIA_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/campaigns/service/Stats.proto */
export type CampaignsStats = {
  __typename?: 'CampaignsStats';
  campaignsStats: Array<CampaignStats>;
};

export type CancelUserFileDownloadInput = {
  companyId: Scalars['ID']['input'];
  fileId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/user/profile/download/Contract.proto */
export type CancelUserFileDownloadPayload = {
  __typename?: 'CancelUserFileDownloadPayload';
  file: Maybe<UserFileDownload>;
};

export enum Carrier {
  CarrierAppalachian = 'CARRIER_APPALACHIAN',
  CarrierAtt = 'CARRIER_ATT',
  CarrierBell = 'CARRIER_BELL',
  CarrierClearsky = 'CARRIER_CLEARSKY',
  CarrierGoogleVoice = 'CARRIER_GOOGLE_VOICE',
  CarrierInterop = 'CARRIER_INTEROP',
  CarrierMisc = 'CARRIER_MISC',
  CarrierRogers = 'CARRIER_ROGERS',
  CarrierTelus = 'CARRIER_TELUS',
  CarrierTmobile = 'CARRIER_TMOBILE',
  CarrierUnknown = 'CARRIER_UNKNOWN',
  CarrierUsCellular = 'CARRIER_US_CELLULAR',
  CarrierVerizon = 'CARRIER_VERIZON'
}

export type CarrierDateMapping = {
  carrier: Carrier;
  endDate: InputMaybe<Scalars['DateTime']['input']>;
  startDate: InputMaybe<Scalars['DateTime']['input']>;
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type CartUpdatedEvent = {
  __typename?: 'CartUpdatedEvent';
  company: Maybe<Company>;
  /** TEMP: A list of products related to this event */
  products: Array<ProductWrapper>;
};

/** Generated from com/attentivemobile/contracts/account-service/AuthenticatorContract.proto */
export type ChallengeResponse = {
  __typename?: 'ChallengeResponse';
  company: Maybe<Company>;
  nonce: Scalars['String']['output'];
};

export type ChangeBrandImageNameInput = {
  companyId: Scalars['ID']['input'];
  fileUuid: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/brand-kit/BrandImage.proto */
export type ChangeBrandImageNamePayload = {
  __typename?: 'ChangeBrandImageNamePayload';
  image: BrandImage;
};

export type ChangeCompanyAttnDomainInput = {
  companyId: Scalars['ID']['input'];
  newCompanyDomain: Scalars['String']['input'];
  oldCompanyDomain: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/solutions/companyconfiguration/Configuration.proto */
export type ChangeCompanyAttnDomainPayload = {
  __typename?: 'ChangeCompanyAttnDomainPayload';
  company: Maybe<Company>;
  newCompanyDomain: Maybe<Scalars['String']['output']>;
};

export type ChangeEventReplayInput = {
  eventReplayId: Scalars['ID']['input'];
  ignoreGracePeriod: InputMaybe<Scalars['Boolean']['input']>;
};

/** Generated from com/attentivemobile/contracts/tactical/eventreplay/EventReplayService.proto */
export type ChangeEventReplayPayload = {
  __typename?: 'ChangeEventReplayPayload';
  eventReplay: EventReplay;
};

export type ChangeEventReplayV2Input = {
  eventReplayId: InputMaybe<Array<Scalars['ID']['input']>>;
  ignoreGracePeriod: InputMaybe<Scalars['Boolean']['input']>;
};

/** Generated from com/attentivemobile/contracts/tactical/eventreplay/EventReplayService.proto */
export type ChangeEventReplayV2Payload = {
  __typename?: 'ChangeEventReplayV2Payload';
  eventReplay: Array<EventReplay>;
  eventReplayFailures: Array<EventReplayFailures>;
};

export enum Channel {
  ChannelEmail = 'CHANNEL_EMAIL',
  ChannelRbm = 'CHANNEL_RBM',
  ChannelText = 'CHANNEL_TEXT',
  ChannelUnknown = 'CHANNEL_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/solutions/sftp/EtlConfig.proto */
export type ChannelDataEntry = {
  __typename?: 'ChannelDataEntry';
  key: Scalars['String']['output'];
  value: ChannelOptions;
};

export type ChannelDataInputEntry = {
  key: Scalars['String']['input'];
  value: ChannelOptions;
};

export enum ChannelOptions {
  ChannelEmail = 'CHANNEL_EMAIL',
  ChannelSms = 'CHANNEL_SMS'
}

export type ChannelProperties = EmailProperties | RbmChannelProperties | TextMessageProperties;

export type ChatMessageAdminToolInput = {
  content: Scalars['String']['input'];
  role: TemplateRole;
};

export type ChatMessageInput = {
  content: Scalars['String']['input'];
  role: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type CheckoutAbandonedEvent = {
  __typename?: 'CheckoutAbandonedEvent';
  checkoutUrl: Scalars['String']['output'];
  /** @deprecated deprecated */
  created: Scalars['DateTime']['output'];
  /** @deprecated deprecated */
  id: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type CheckoutStartedEvent = {
  __typename?: 'CheckoutStartedEvent';
  checkoutUrl: Scalars['String']['output'];
  /** @deprecated deprecated */
  companyProductIds: Array<Scalars['ID']['output']>;
  /** @deprecated deprecated */
  created: Scalars['DateTime']['output'];
  /** @deprecated deprecated */
  id: Scalars['String']['output'];
  /** @deprecated deprecated */
  productBundle: Array<ProductInfo>;
  /** TEMP: A list of products related to this event */
  products: Array<ProductWrapper>;
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type CheckoutUpdatedEvent = {
  __typename?: 'CheckoutUpdatedEvent';
  checkoutUrl: Scalars['String']['output'];
  /** @deprecated deprecated */
  companyProductIds: Array<Scalars['ID']['output']>;
  /** @deprecated deprecated */
  created: Scalars['DateTime']['output'];
  /** @deprecated deprecated */
  id: Scalars['String']['output'];
  /** @deprecated deprecated */
  productBundle: Array<ProductInfo>;
  /** TEMP: A list of products related to this event */
  products: Array<ProductWrapper>;
};

/** Generated from com/attentivemobile/contracts/event/platform/registration/service/Flink.proto */
export type Checkpoint = {
  __typename?: 'Checkpoint';
  externalPath: Scalars['String']['output'];
  status: CheckpointStatus;
  type: CheckpointType;
};

export enum CheckpointStatus {
  StatusCompleted = 'STATUS_COMPLETED',
  StatusFailed = 'STATUS_FAILED',
  StatusInProgress = 'STATUS_IN_PROGRESS',
  StatusUnknown = 'STATUS_UNKNOWN'
}

export enum CheckpointType {
  TypeCheckpoint = 'TYPE_CHECKPOINT',
  TypeSavepoint = 'TYPE_SAVEPOINT',
  TypeUnknown = 'TYPE_UNKNOWN'
}

export type ChurnAiProSubscriptionInput = {
  churnReason: Scalars['String']['input'];
  comment: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['ID']['input'];
};

export type ChurnAiProSubscriptionPayload = AiProTrialActionFailure | ChurnAiProSubscriptionSuccess;

/** Generated from com/attentivemobile/contracts/segment/recommendation/service/aipro/Contract.proto */
export type ChurnAiProSubscriptionSuccess = {
  __typename?: 'ChurnAiProSubscriptionSuccess';
  success: Scalars['Boolean']['output'];
};

/** Generated from com/attentivemobile/contracts/gateway/service/RateLimiting.proto */
export type CircuitBreakerRateLimitsResponse = {
  __typename?: 'CircuitBreakerRateLimitsResponse';
  circuitBreakerRateLimitResponses: Array<UpdateCircuitBreakerRateLimitSuccess>;
};

/** Generated from com/attentivemobile/contracts/conversations/v1/Conversation.proto */
export type ClientConversation = {
  __typename?: 'ClientConversation';
  isInbound: Scalars['Boolean']['output'];
  isUnread: Scalars['Boolean']['output'];
  mediaUrl: Scalars['String']['output'];
  previewText: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
  user: Maybe<User>;
};

/** Generated from com/attentivemobile/contracts/conversations/v1/Conversation.proto */
export type ClientConversationConnection = {
  __typename?: 'ClientConversationConnection';
  edges: Array<ClientConversationEdge>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/conversations/v1/Conversation.proto */
export type ClientConversationEdge = {
  __typename?: 'ClientConversationEdge';
  cursor: Scalars['String']['output'];
  node: ClientConversation;
};

export enum ClientConversationFilter {
  ClientConversationFilterAll = 'CLIENT_CONVERSATION_FILTER_ALL',
  ClientConversationFilterConcierge = 'CLIENT_CONVERSATION_FILTER_CONCIERGE',
  ClientConversationFilterConciergeConverted = 'CLIENT_CONVERSATION_FILTER_CONCIERGE_CONVERTED',
  ClientConversationFilterConciergeEscalated = 'CLIENT_CONVERSATION_FILTER_CONCIERGE_ESCALATED',
  ClientConversationFilterMultipleConciergeConversation = 'CLIENT_CONVERSATION_FILTER_MULTIPLE_CONCIERGE_CONVERSATION',
  ClientConversationFilterUnread = 'CLIENT_CONVERSATION_FILTER_UNREAD'
}

export enum ClientConversationSort {
  ClientConversationSortLatest = 'CLIENT_CONVERSATION_SORT_LATEST',
  ClientConversationSortOldest = 'CLIENT_CONVERSATION_SORT_OLDEST'
}

export type ClientMessageFeedbackFilterInput = {
  searchTerm: InputMaybe<Scalars['String']['input']>;
  status: InputMaybe<MessageFeedbackStatusFilter>;
  type: InputMaybe<MessageFeedbackTypeFilter>;
};

/** Generated from com/attentivemobile/contracts/solutions/clientsegmentimports/ClientSegmentImports.proto */
export type ClientSegmentImportsRow = {
  __typename?: 'ClientSegmentImportsRow';
  csiId: Maybe<Scalars['Long']['output']>;
  customFilePrefix: Maybe<Scalars['String']['output']>;
  hasHeaders: Maybe<Scalars['Boolean']['output']>;
  inboundS3Bucket: Maybe<Scalars['String']['output']>;
  isEnabled: Maybe<Scalars['Boolean']['output']>;
  isEncrypted: Maybe<Scalars['Boolean']['output']>;
  isSegmentNameInFileName: Maybe<Scalars['Boolean']['output']>;
  jobName: Maybe<Scalars['String']['output']>;
  piiType: Maybe<Scalars['String']['output']>;
  scheduledRunTime: Maybe<Scalars['String']['output']>;
  sftpHostname: Maybe<Scalars['String']['output']>;
  sftpUploadsDirectory: Maybe<Scalars['String']['output']>;
  sftpUsername: Maybe<Scalars['String']['output']>;
  stripQuotesFromSegmentNames: Maybe<Scalars['Boolean']['output']>;
};

export type ClientSegmentImportsRowInput = {
  csiId: InputMaybe<Scalars['Long']['input']>;
  customFilePrefix: InputMaybe<Scalars['String']['input']>;
  hasHeaders: InputMaybe<Scalars['Boolean']['input']>;
  inboundS3Bucket: InputMaybe<Scalars['String']['input']>;
  isEnabled: InputMaybe<Scalars['Boolean']['input']>;
  isEncrypted: InputMaybe<Scalars['Boolean']['input']>;
  isSegmentNameInFileName: InputMaybe<Scalars['Boolean']['input']>;
  jobName: InputMaybe<Scalars['String']['input']>;
  piiType: InputMaybe<Scalars['String']['input']>;
  scheduledRunTime: InputMaybe<Scalars['String']['input']>;
  sftpHostname: InputMaybe<Scalars['String']['input']>;
  sftpUploadsDirectory: InputMaybe<Scalars['String']['input']>;
  sftpUsername: InputMaybe<Scalars['String']['input']>;
  stripQuotesFromSegmentNames: InputMaybe<Scalars['Boolean']['input']>;
};

export type CloneCampaignMessageGroupInput = {
  campaignMessageGroupId: Scalars['ID']['input'];
  companyId: Scalars['ID']['input'];
  createNewCampaign: Scalars['Boolean']['input'];
  userId: Scalars['ID']['input'];
};

export type CloneComposeCampaignInput = {
  campaignId: Scalars['ID']['input'];
  companyId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type CloneCreativeInput = {
  companyId: Scalars['ID']['input'];
  creativeConfig: InputMaybe<JsonStringInput>;
  creativeId: Scalars['ID']['input'];
  language: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  /**
   * Used to change the the country block lists, should be set by the backend.
   * @deprecated deprecated
   */
  rules: InputMaybe<JsonStringInput>;
  /** Changed when the smsBody is set to new language default. */
  smsBody: InputMaybe<Scalars['String']['input']>;
};

/** Generated from com/attentivemobile/contracts/creative-service/creative/CreativeGraphService.proto */
export type CloneCreativePayload = {
  __typename?: 'CloneCreativePayload';
  creativeOrError: Maybe<CloneCreativePayloadCreativeOrError>;
};

export type CloneCreativePayloadCreativeOrError = Creative | CreativeMutationError;

export type CloneEdsJobInput = {
  companyId: Scalars['ID']['input'];
  description: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  taskId: Scalars['String']['input'];
};

export type CloneEdsJobPayload = CloneEdsJobSuccess | DefaultErrorFailure;

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type CloneEdsJobSuccess = {
  __typename?: 'CloneEdsJobSuccess';
  taskUuid: Maybe<Scalars['String']['output']>;
};

export type CloneJourneyInput = {
  companyId: Scalars['ID']['input'];
  journeyId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/journeys/api/Contract.proto */
export type CloneJourneyPayload = {
  __typename?: 'CloneJourneyPayload';
  journey: Journey;
};

export type ClonePollInput = {
  companyId: Scalars['ID']['input'];
  description: InputMaybe<Scalars['String']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  pollId: Scalars['ID']['input'];
  shouldCloneKeywords: InputMaybe<Scalars['Boolean']['input']>;
};

export type ClonePollPayload = ClonePollSuccess | DefaultErrorFailure;

/** Generated from com/attentivemobile/contracts/survey/poll/Contract.proto */
export type ClonePollSuccess = {
  __typename?: 'ClonePollSuccess';
  newPoll: Maybe<Poll>;
};

export type CloseConciergeChatInput = {
  chatId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/concierge/service/Contract.proto */
export type CloseConciergeChatPayload = {
  __typename?: 'CloseConciergeChatPayload';
  account: Maybe<Account>;
};

export enum Cluster {
  ClusterBfcm = 'CLUSTER_BFCM',
  ClusterCore = 'CLUSTER_CORE',
  ClusterFeeder = 'CLUSTER_FEEDER',
  ClusterLoadtest = 'CLUSTER_LOADTEST',
  ClusterNorma = 'CLUSTER_NORMA',
  ClusterPandora = 'CLUSTER_PANDORA',
  ClusterSandbox = 'CLUSTER_SANDBOX',
  ClusterSource = 'CLUSTER_SOURCE',
  ClusterUnknown = 'CLUSTER_UNKNOWN',
  ClusterVirgo = 'CLUSTER_VIRGO'
}

/** Generated from com/attentivemobile/contracts/user/profile/property/UserPropertyV2.proto */
export type CollectionPropertyValueV2 = {
  __typename?: 'CollectionPropertyValueV2';
  jsonValues: Array<Scalars['JSON']['output']>;
};

/** Generated from com/attentivemobile/contracts/solutions/sftp/EtlConfig.proto */
export type Columns = {
  __typename?: 'Columns';
  conversions: Array<ConversionsFeedCols>;
  general: Array<GeneralDataFeedCols>;
};

export type ColumnsInput = {
  conversions: InputMaybe<Array<ConversionsFeedCols>>;
  general: InputMaybe<Array<GeneralDataFeedCols>>;
};

export type CombineImagesInput = {
  metadata: InputMaybe<GifRequestMetadata>;
  urls: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CombineImagesPayload = CombineImagesSuccess | DefaultErrorFailure;

/** Generated from com/attentivemobile/contracts/composition/media/gif/Contract.proto */
export type CombineImagesSuccess = {
  __typename?: 'CombineImagesSuccess';
  isWithinSizeLimit: Scalars['Boolean']['output'];
  url: Scalars['String']['output'];
};

export enum CommonCreativeErrors {
  CommonCreativeErrorsNameCollision = 'COMMON_CREATIVE_ERRORS_NAME_COLLISION',
  CommonCreativeErrorsUnknown = 'COMMON_CREATIVE_ERRORS_UNKNOWN'
}

export enum CommonCreativeScheduleMutationErrors {
  CommonCreativeScheduleErrorsOverlappingSchedules = 'COMMON_CREATIVE_SCHEDULE_ERRORS_OVERLAPPING_SCHEDULES',
  CommonCreativeScheduleErrorsUnknown = 'COMMON_CREATIVE_SCHEDULE_ERRORS_UNKNOWN'
}

/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type Company = Node & {
  __typename?: 'Company';
  aIGeneratedTexts: Maybe<AIGeneratedTextConnection>;
  accounts: Maybe<AccountConnection>;
  /**
   * address_country should be read from the default region unless a different region is specified. The property on a region is named country_code
   * @deprecated deprecated
   */
  addressCountry: Scalars['String']['output'];
  /**
   * address_line_1 should be read from the default region unless a different region is specified. The property on a region is named address_1
   * @deprecated deprecated
   */
  addressLine1: Scalars['String']['output'];
  /**
   * address_line_2 should be read from the default region unless a different region is specified. The property on a region is named address_2
   * @deprecated deprecated
   */
  addressLine2: Scalars['String']['output'];
  affiliateUsers: Maybe<AffiliateUserConnection>;
  aiBrandVoiceSettings: Maybe<AIBrandVoiceSettings>;
  aiBrandVoiceSettingsV2: Maybe<AIBrandVoiceSettingsV2>;
  aiJourneyDefaultMessages: Maybe<Array<AIJourneyDefaultMessage>>;
  aiJourneysConfig: Maybe<GetConfigResponse>;
  aiProSubscriptionStatus: Maybe<GetAiProSubscriptionStatusPayload>;
  applicationWebhookEventTypes: Maybe<Array<WebhookEventType>>;
  attentiveDomain: Scalars['String']['output'];
  attributionSettings: Maybe<AttributionSettings>;
  autoJourneys: Maybe<ListAutoJourneysResponse>;
  availableBrandFonts: Maybe<GetAvailableBrandFontsResponse>;
  availableJourneyTemplates: Maybe<Array<JourneyTemplate>>;
  availableJourneyTriggers: Maybe<JourneyTriggers>;
  bestsellerProducts: Maybe<ProductRecommendationsRedactedResponse>;
  bestsellersRecommendations: Maybe<ProductRecommendationsResponse>;
  blockCompanyInfo: Maybe<Array<BlockCompanyInfo>>;
  blogPosts: Maybe<BlogPostConnection>;
  /** Filtered assets that a company has uploaded to the asset library service */
  brandAssets: Maybe<FolderChildrenConnection>;
  brandColorScheme: Maybe<Array<BrandColor>>;
  brandElementStyles: Maybe<Array<BrandElementStyle>>;
  brandImages: Maybe<Array<BrandImage>>;
  brandVoice: Maybe<CompanyBrandVoice>;
  builtApplications: Maybe<ApplicationConnection>;
  /**
   * business_hours should be read from the default region unless a different region is specified. The property on a region is named quiet_hours
   * @deprecated deprecated
   */
  businessHours: BusinessHours;
  cartLink: Scalars['String']['output'];
  /**
   * city should be read from the default region unless a different region is specified. The property on a region is named locality
   * @deprecated deprecated
   */
  city: Scalars['String']['output'];
  clientConversationUnreadCount: Maybe<UnreadCountResponse>;
  clientConversations: Maybe<ClientConversationConnection>;
  clientSegmentImports: Maybe<Array<ClientSegmentImportsRow>>;
  companyCodes: Maybe<CompanyCodeConnection>;
  companyGlobalRateLimits: Maybe<CompanyGlobalRateLimitsPayload>;
  companyLinkParameters: Maybe<CompanyLinkParametersResponse>;
  companyLinks: Maybe<Array<CompanyLink>>;
  companyVendorIntegrations: Maybe<Array<CompanyVendorIntegration>>;
  compatibleContent: Maybe<CompatibleContent>;
  conciergeCompanyDetails: Maybe<ConciergeCompanyDetails>;
  conciergeSelfServeSettings: Maybe<ConciergeSelfServeSettings>;
  conciergeSettings: Maybe<ConciergeSettings>;
  contactCard: Maybe<CompanyContactCard>;
  contentSettings: Maybe<FindUserContentSettingsConnection>;
  /** @deprecated deprecated */
  conversations: Maybe<ConversationConnection>;
  conversationsSettings: Maybe<ConversationsSettings>;
  country: Scalars['String']['output'];
  countrySubdivisions: Maybe<Array<CountrySubdivision>>;
  couponSets: Maybe<CouponSetConnection>;
  creativeAssets: Maybe<CreativeAssets>;
  creativeExperiments: Maybe<CreativeExperimentConnection>;
  creativePresets: Maybe<CreativePresetConnection>;
  /** Provides a collection of Rules configured within the Creatives domain for the target Company. */
  creativeRules: Maybe<CreativeRuleConnection>;
  creativeScheduleWorkflows: Maybe<CreativeWorkflowScheduleConnection>;
  /** @deprecated deprecated */
  creativeSchedules: Maybe<CreativeScheduleConnection>;
  creativeTemplates: Maybe<CreativeTemplateConnection>;
  /** @deprecated deprecated */
  creatives: Maybe<CreativeConnection>;
  creativesListWithABTests: Maybe<CreativesAndExperimentsConnection>;
  creativesV2: Maybe<CreativeConnection>;
  csTier: Maybe<Scalars['String']['output']>;
  currencies: Maybe<Array<Scalars['String']['output']>>;
  customEventDefinitions: Maybe<CustomEventDefinitionConnection>;
  customEventKeys: Maybe<CustomEventKeyConnection>;
  /** Custom Events sent by this Company */
  customEvents: Maybe<CustomEventsConnection>;
  customKeywords: Maybe<Array<CustomKeyword>>;
  /**
   * Only use if absolutely necessary. Should be removed when all services using custom properties are migrated to GQL.
   * @deprecated deprecated
   */
  customPropertyDefinitionByInternalId: Maybe<CustomPropertyDefinition>;
  /** @deprecated deprecated */
  customReport: Maybe<Report>;
  customReportById: Maybe<Report>;
  /** Query a specific EDS sync associated with a specified company */
  dataSync: Maybe<Sync>;
  /** The EDS syncs associated with a specified company */
  dataSyncs: Maybe<Syncs>;
  dataset: Maybe<DatasetConnection>;
  /** Provides the default CDS, which has a raw status of active but might ultimately be inactive due to schedules. */
  defaultCouponDisplayScreenCreative: Maybe<GetActiveCouponDisplayScreenCreativePayload>;
  defaultRegion: Maybe<Region>;
  defaultWelcomeJourney: Maybe<Journey>;
  deleteRequests: Maybe<DeleteRequestConnection>;
  dimensionValues: Maybe<DimensionValueConnection>;
  displayName: Scalars['String']['output'];
  domain: Maybe<Scalars['String']['output']>;
  draftProductSegment: Maybe<DraftProductSegment>;
  duplicatePollKeywords: Maybe<GetDuplicateKeywordsResponse>;
  email: Scalars['String']['output'];
  emailAccount: Maybe<EmailAccount>;
  emailCampaignSchedulabilityStatus: Maybe<EmailCampaignSchedulabilityStatus>;
  /**
   * email_fatigue_rule should be read from the default region unless a different region is specified.
   * @deprecated deprecated
   */
  emailFatigueRule: CompanyEmailFatigueRule;
  emailSettings: Maybe<EmailSettings>;
  enabledAttachmentTypes: Maybe<GetEnabledAttachmentTypesResponse>;
  etlConfigs: Maybe<EtlConfigConnection>;
  eventReplays: Maybe<EventReplaysResponse>;
  exportDataset: Maybe<ExportDatasetResponse>;
  externalId: Scalars['Long']['output'];
  /**
   * fatigue_rule should be read from the default region unless a different region is specified.
   * @deprecated deprecated
   */
  fatigueRule: CompanySmsFatigueRule;
  generativeAiOptInStatus: Maybe<GetGenerativeAIOptInStatusResponse>;
  /** ID of the company. */
  id: Scalars['ID']['output'];
  installedApplications: Maybe<InstalledApplicationConnection>;
  integrationErrors: Maybe<Array<IntegrationError>>;
  internalId: Scalars['Long']['output'];
  isAttentiveDomainAvailable: Maybe<Scalars['Boolean']['output']>;
  isFeatureEnabled: Maybe<Scalars['Boolean']['output']>;
  journeySources: Maybe<JourneysWithNodeTypeResponse>;
  journeys: Maybe<JourneyConnection>;
  legacyReports: Maybe<LegacyReportConnection>;
  legacyScheduledReports: Maybe<LegacyScheduledReportConnection>;
  legacyTagConfig: Maybe<Array<LegacyTagConfig>>;
  messageFeedback: Maybe<MessageFeedbackConnection>;
  messageTemplates: Maybe<MessageTemplateConnection>;
  messagingServices: Maybe<Array<CompanyMessagingService>>;
  metricDefinition: Maybe<MetricDefinition>;
  metrics: Maybe<Array<Metric>>;
  metricsCatalog: Maybe<MetricsCatalog>;
  metricsTable: Maybe<MetricsTableConnection>;
  mfaEnabled: Maybe<Scalars['Boolean']['output']>;
  milestones: Maybe<ListMilestonesResponse>;
  /** Name of the company. */
  name: Scalars['String']['output'];
  nextBestActions: Maybe<NextBestActionConnection>;
  notifications: Maybe<NotificationConnection>;
  onboardingSurveyOptions: Maybe<ListOnboardingSurveySelectionsResponse>;
  paginatedProducts: Maybe<CompanyProductsConnection>;
  paymentsOffer: Maybe<PaymentsOffer>;
  polls: Maybe<PollsConnection>;
  pollsV2: Maybe<PollsConnection>;
  possibleReportRecipients: Maybe<Array<ReportRecipient>>;
  /**
   * privacy_link should be read from the default region unless a different region is specified. The property on a region is named privacy_url.
   * @deprecated deprecated
   */
  privacyLink: Scalars['String']['output'];
  productCategories: Maybe<CompanyProductCategoriesConnection>;
  productCollections: Maybe<CompanyProductCollectionsConnection>;
  productPropertyOptions: Maybe<ProductPropertyOptionConnection>;
  productSegments: Maybe<ProductSegmentsConnection>;
  productTags: Maybe<CompanyProductTagsConnection>;
  products: Maybe<GraphSearchProductsResponse>;
  region: Maybe<Region>;
  regions: Maybe<Array<Region>>;
  report: Maybe<Report>;
  reports: Maybe<ReportConnection>;
  scheduleEligibleReports: Maybe<ReportConnection>;
  scheduledReports: Maybe<ReportConnection>;
  searchAttributes: Maybe<SearchAttributeConnection>;
  segmentableProductProperties: Maybe<GetSegmentableProductPropertiesResponse>;
  segments: Maybe<SegmentConnection>;
  selectedBrandFontFamilies: Maybe<Array<SelectedBrandFontFamily>>;
  selectedBrandFonts: Maybe<GetSelectedBrandFontsResponse>;
  sendingDomains: Maybe<Array<SendingDomain>>;
  settings: CompanySettings;
  settingsUrl: Scalars['String']['output'];
  sftpAccountStatus: Maybe<SftpCognitoUserStatusResponse>;
  sharedMessageTemplates: Maybe<MessageTemplateConnection>;
  /**
   * state should be read from the default region unless a different region is specified. The property on a region is named region
   * @deprecated deprecated
   */
  state: Scalars['String']['output'];
  status: Scalars['String']['output'];
  /**
   * stop_to_stop_phrase should be read from automated messages on the default region's primary localized settings unless a different region is specified. The message type is AUTOMATED_MESSAGE_TYPE_UNSUBSCRIBE
   * @deprecated deprecated
   */
  stopToStopPhrase: Maybe<Scalars['String']['output']>;
  subscriberUploads: Maybe<SubscriberUploadJobConnection>;
  tagConfigReleases: Maybe<Array<TagConfigRelease>>;
  tagHealthAutoInstallStatus: Maybe<AutoInstallTagStatus>;
  tagHealthEventStats: Maybe<TagEventStats>;
  tagHealthPageInspection: Maybe<Inspection>;
  tags: Maybe<TagsConnection>;
  templatizedPostback: Maybe<Array<TemplatizedPostback>>;
  /**
   * terms_link should be read from the default region unless a different region is specified. The property on a region is named terms_url
   * @deprecated deprecated
   */
  termsLink: Scalars['String']['output'];
  textToBuyConfig: Maybe<TextToBuyConfig>;
  /**
   * timezone should be read from the default region unless a different region is specified.
   * @deprecated deprecated
   */
  timezone: Scalars['String']['output'];
  type: CompanyType;
  unifiedAutoJourney: Maybe<AutoJourney>;
  /** User associated with this company. */
  user: Maybe<User>;
  /** @deprecated deprecated */
  userPropertyDefinitions: Maybe<UserPropertyDefinitionConnection>;
  userPropertyDefinitionsV2: Maybe<UserPropertyDefinitionConnectionV2>;
  userSearchCounts: Maybe<BulkUserSearchCountsResponse>;
  vendorCompanyCards: Maybe<VendorCompanyCardConnection>;
  warmupSeriesSizeAndDuration: Maybe<WarmupSeriesSizeAndDuration>;
  /**
   * zipcode should be read from the default region unless a different region is specified. The property on a region is named postal_code
   * @deprecated deprecated
   */
  zipcode: Scalars['String']['output'];
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanyaIGeneratedTextsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanyaccountsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  filter: AccountFilter;
  first: InputMaybe<Scalars['Int']['input']>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanyaffiliateUsersArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanyautoJourneysArgs = {
  statuses: InputMaybe<Array<AutoJourneyStatus>>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanyavailableJourneyTemplatesArgs = {
  languages: InputMaybe<Array<Scalars['String']['input']>>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanybestsellersRecommendationsArgs = {
  productSegmentId: InputMaybe<Scalars['ID']['input']>;
  userId: Scalars['String']['input'];
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanyblockCompanyInfoArgs = {
  companyInfoType: InputMaybe<Array<BlockCompanyInfoType>>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanyblogPostsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  categoryName: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanybrandAssetsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filters: InputMaybe<AssetFilters>;
  first: InputMaybe<Scalars['Int']['input']>;
  folderId: InputMaybe<Scalars['ID']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orderByAndDirection: InputMaybe<AssetsOrderByAndDirection>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanybrandImagesArgs = {
  fileUuid: InputMaybe<Scalars['String']['input']>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanybuiltApplicationsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanyclientConversationsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  filter: ClientConversationFilter;
  first: InputMaybe<Scalars['Int']['input']>;
  sort: ClientConversationSort;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanycompanyCodesArgs = {
  handler: InputMaybe<Scalars['String']['input']>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanycompanyVendorIntegrationsArgs = {
  externalVendorApiId: InputMaybe<Scalars['ID']['input']>;
  segmentId: InputMaybe<Scalars['ID']['input']>;
  status: InputMaybe<CompanyVendorIntegrationStatus>;
  vendor: InputMaybe<Scalars['String']['input']>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanycompatibleContentArgs = {
  channel: ComposeMessageChannel;
  contentCategory: InputMaybe<ContentCategory>;
  contentMessageType: InputMaybe<ContentMessageType>;
  proposedAttachments: InputMaybe<Array<NewAttachmentRequest>>;
  proposedMediaUrls: InputMaybe<Array<UrlInput>>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanycontentSettingsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  userId: InputMaybe<Scalars['ID']['input']>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanyconversationsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  sort: ConversationSort;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanycouponSetsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanycreativeExperimentsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<ListCreativeExperimentsFilterProperties>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  sort: InputMaybe<Array<ListCreativeExperimentsSort>>;
  status: InputMaybe<CreativeExperimentStatus>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanycreativePresetsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  creativeFormatId: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  readyToLaunch: InputMaybe<Scalars['Boolean']['input']>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanycreativeRulesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanycreativeScheduleWorkflowsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<GetCreativeSchedulesRequestFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  sort: InputMaybe<Array<GetCreativeSchedulesRequestSort>>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanycreativeSchedulesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<GetCreativeSchedulesRequestFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  sort: InputMaybe<Array<GetCreativeSchedulesRequestSort>>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanycreativesArgs = {
  couponSetId: InputMaybe<Scalars['String']['input']>;
  customAttributeName: InputMaybe<Scalars['String']['input']>;
  status: InputMaybe<Scalars['String']['input']>;
  subtype: InputMaybe<Scalars['String']['input']>;
  type: InputMaybe<Scalars['String']['input']>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanycreativesListWithABTestsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<ListCreativesAndExperimentsFilterProperties>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  sort: InputMaybe<Array<ListCreativesAndExperimentsSort>>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanycreativesV2Args = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<ListCreativesFilterProperties>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  sort: InputMaybe<Array<ListCreativesSort>>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanycustomEventDefinitionsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanycustomEventKeysArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<ListCustomEventKeysRequestFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanycustomEventsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<ListCustomEventsRequestFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanycustomPropertyDefinitionByInternalIdArgs = {
  internalId: Scalars['String']['input'];
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanycustomReportArgs = {
  reportId: Scalars['String']['input'];
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanycustomReportByIdArgs = {
  id: Scalars['ID']['input'];
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanydataSyncArgs = {
  syncId: Scalars['ID']['input'];
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanydataSyncsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filter: ListSyncsFilter;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanydatasetArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  columns: InputMaybe<Array<Scalars['String']['input']>>;
  datasetId: Scalars['String']['input'];
  filters: InputMaybe<Array<DatasetFilter>>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<DatasetOrdering>>;
  requestTimestamp: InputMaybe<Scalars['DateTime']['input']>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanydeleteRequestsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<PrivacyRequestSearchFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  sorting: InputMaybe<PrivacyRequestSorting>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanydimensionValuesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  dimensionId: Scalars['String']['input'];
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  search: InputMaybe<ListDimensionValuesSearchInput>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanydraftProductSegmentArgs = {
  filters: InputMaybe<Array<ProductSegmentFilterInput>>;
  includeProductOriginIds: InputMaybe<Scalars['Boolean']['input']>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanyemailAccountArgs = {
  fieldMask: InputMaybe<FieldMask>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanyetlConfigsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanyexportDatasetArgs = {
  columns: InputMaybe<Array<Scalars['String']['input']>>;
  datasetId: Scalars['String']['input'];
  filters: InputMaybe<Array<DatasetFilter>>;
  orderBy: InputMaybe<Array<DatasetOrdering>>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanyinstalledApplicationsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanyintegrationErrorsArgs = {
  companyVendorIntegrationFeatureId: InputMaybe<Scalars['ID']['input']>;
  companyVendorIntegrationId: InputMaybe<Scalars['ID']['input']>;
  externalVendorApiId: InputMaybe<Scalars['ID']['input']>;
  vendor: InputMaybe<Scalars['String']['input']>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanyisAttentiveDomainAvailableArgs = {
  attentiveDomain: Scalars['String']['input'];
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanyisFeatureEnabledArgs = {
  name: Scalars['String']['input'];
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanyjourneySourcesArgs = {
  nodeType: Scalars['String']['input'];
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanyjourneysArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<JourneysFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanylegacyReportsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  existingOnly: InputMaybe<Scalars['Boolean']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  searchTerm: InputMaybe<Scalars['String']['input']>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanylegacyScheduledReportsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  existingOnly: InputMaybe<Scalars['Boolean']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  searchTerm: InputMaybe<Scalars['String']['input']>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanylegacyTagConfigArgs = {
  includeReadOnly: InputMaybe<Scalars['Boolean']['input']>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanymessageFeedbackArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<ClientMessageFeedbackFilterInput>;
  first: InputMaybe<Scalars['Int']['input']>;
  sort: InputMaybe<MessageFeedbackSortInput>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanymessageTemplatesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  channel: Channel;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  templateTypes: InputMaybe<Array<MessagingTemplateType>>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanymessagingServicesArgs = {
  filter: InputMaybe<MessagingServiceFilter>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanymetricDefinitionArgs = {
  metricId: Scalars['String']['input'];
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanymetricsArgs = {
  filters: InputMaybe<Array<DimensionFilter>>;
  groupings: InputMaybe<Array<DimensionGrouping>>;
  metricIds: Array<Scalars['String']['input']>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanymetricsTableArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filters: InputMaybe<Array<DimensionFilter>>;
  first: InputMaybe<Scalars['Int']['input']>;
  groupings: InputMaybe<Array<DimensionGrouping>>;
  last: InputMaybe<Scalars['Int']['input']>;
  metricIds: Array<Scalars['String']['input']>;
  orderBy: InputMaybe<Array<MetricsOrdering>>;
  requestTimestamp: InputMaybe<Scalars['DateTime']['input']>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanynextBestActionsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  category: InputMaybe<NextBestActionCategory>;
  first: InputMaybe<Scalars['Int']['input']>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanynotificationsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  notificationType: InputMaybe<NotificationType>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanypaginatedProductsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<PaginatedProductsFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanypaymentsOfferArgs = {
  messageId: Scalars['ID']['input'];
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanypollsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  paging: InputMaybe<PollPaging>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanypollsV2Args = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<PollsFilterV2>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  sorting: InputMaybe<PollSorting>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanyproductCategoriesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<ProductCategoriesFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanyproductCollectionsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<ProductCollectionsFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanyproductPropertyOptionsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<ProductPropertyOptionsFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  input: ProductPropertyOptionsInput;
  last: InputMaybe<Scalars['Int']['input']>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanyproductSegmentsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<ProductSegmentsFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanyproductTagsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<ProductTagsFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanyproductsArgs = {
  query: InputMaybe<Scalars['String']['input']>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanyregionArgs = {
  regionId: Scalars['ID']['input'];
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanyreportArgs = {
  reportId: Scalars['String']['input'];
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanyreportsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanyscheduleEligibleReportsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  searchTerm: InputMaybe<Scalars['String']['input']>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanyscheduledReportsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  searchTerm: InputMaybe<Scalars['String']['input']>;
  sortOptions: InputMaybe<ReportSortOptions>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanysearchAttributesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<ListSearchAttributesFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanysegmentsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<ListSegmentsFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<ListSegmentsOrderBy>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanyselectedBrandFontsArgs = {
  placement: InputMaybe<Placement>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanysharedMessageTemplatesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  channel: Channel;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  templateTypes: InputMaybe<Array<MessagingTemplateType>>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanysubscriberUploadsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  jobTypeFilter: InputMaybe<Array<SubscriberUploadJobType>>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanytagHealthEventStatsArgs = {
  entryDate: InputMaybe<Scalars['String']['input']>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanytagsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filter: TagFilter;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanytemplatizedPostbackArgs = {
  companyVendorIntegrationId: Scalars['ID']['input'];
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanyuserArgs = {
  email: InputMaybe<Scalars['String']['input']>;
  phone: InputMaybe<Scalars['String']['input']>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanyuserPropertyDefinitionsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  includeDeleted: Scalars['Boolean']['input'];
  includeInternalProperties: Scalars['Boolean']['input'];
  last: InputMaybe<Scalars['Int']['input']>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanyuserPropertyDefinitionsV2Args = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<ListUserPropertyDefinitionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanyuserSearchCountsArgs = {
  userSearchCountRequests: InputMaybe<Array<UserSearchCountsRequest>>;
};


/**
 * Generated from com/attentivemobile/contracts/company-service/Contract.proto
 * Company details.
 */
export type CompanyvendorCompanyCardsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<MobileWalletSearchFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  sorting: InputMaybe<MobileWalletSorting>;
};

/** Generated from com/attentivemobile/contracts/gateway/service/RateLimiting.proto */
export type CompanyApplicationRouteRateLimitsPayload = {
  __typename?: 'CompanyApplicationRouteRateLimitsPayload';
  companyApplicationRouteRateLimits: Array<UpdateCompanyApplicationRouteRateLimitSuccess>;
};

/** Generated from com/attentivemobile/contracts/concierge/service/ConversationManager.proto */
export type CompanyBrandEmojiSettings = {
  __typename?: 'CompanyBrandEmojiSettings';
  areEmojisEnabled: Scalars['Boolean']['output'];
  restrictedEmojiIds: Array<Scalars['String']['output']>;
};

export type CompanyBrandEmojiSettingsInput = {
  areEmojisEnabled: Scalars['Boolean']['input'];
  restrictedEmojiIds: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Generated from com/attentivemobile/contracts/concierge/service/ConversationManager.proto */
export type CompanyBrandVoice = {
  __typename?: 'CompanyBrandVoice';
  agentName: Maybe<Scalars['String']['output']>;
  blocklistedWords: Array<Scalars['String']['output']>;
  emojiSettings: CompanyBrandEmojiSettings;
  escalationTopics: Array<Scalars['String']['output']>;
  messageTone: MessageTone;
};

export type CompanyBrandVoiceInput = {
  agentName: InputMaybe<Scalars['String']['input']>;
  blocklistedWords: InputMaybe<Array<Scalars['String']['input']>>;
  emojiSettings: CompanyBrandEmojiSettingsInput;
  escalationTopics: InputMaybe<Array<Scalars['String']['input']>>;
  messageTone: MessageTone;
};

/** Generated from com/attentivemobile/contracts/creative-service/companycodes/CompanyCode.proto */
export type CompanyCode = {
  __typename?: 'CompanyCode';
  code: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/creative-service/companycodes/CompanyCodesGraphService.proto */
export type CompanyCodeConnection = {
  __typename?: 'CompanyCodeConnection';
  codes: Array<CompanyCodeEdge>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/creative-service/companycodes/CompanyCodesGraphService.proto */
export type CompanyCodeEdge = {
  __typename?: 'CompanyCodeEdge';
  cursor: Scalars['String']['output'];
  node: CompanyCode;
};

/** Generated from com/attentivemobile/contracts/company-service/Contract.proto */
export type CompanyConnection = {
  __typename?: 'CompanyConnection';
  edges: Array<CompanyEdge>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/company-service/onboarding/Company.proto */
export type CompanyContactCard = Node & {
  __typename?: 'CompanyContactCard';
  active: Scalars['Boolean']['output'];
  address: Address;
  company: Maybe<Company>;
  companyLegalName: Scalars['String']['output'];
  companyUrl: Url;
  displayName: Scalars['String']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  imageUrl: Url;
  inheritAddressFromCompany: Scalars['Boolean']['output'];
  mediaUrl: Url;
  phones: Array<Scalars['String']['output']>;
  secondaryUrl: Url;
  title: Scalars['String']['output'];
};

export type CompanyContactCardInput = {
  active: Scalars['Boolean']['input'];
  address: AddressInput;
  companyId: Scalars['ID']['input'];
  companyLegalName: Scalars['String']['input'];
  companyUrl: UrlInput;
  displayName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  imageUrl: UrlInput;
  inheritAddressFromCompany: Scalars['Boolean']['input'];
  mediaUrl: UrlInput;
  phones: InputMaybe<Array<Scalars['String']['input']>>;
  secondaryUrl: UrlInput;
  title: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto */
export type CompanyCurrenciesPayload = {
  __typename?: 'CompanyCurrenciesPayload';
  currencies: Array<Scalars['String']['output']>;
};

/** Generated from com/attentivemobile/contracts/company-service/Contract.proto */
export type CompanyEdge = {
  __typename?: 'CompanyEdge';
  cursor: Scalars['String']['output'];
  node: Company;
};

/** Generated from com/attentivemobile/contracts/company-service/Contract.proto */
export type CompanyEmailFatigueRule = {
  __typename?: 'CompanyEmailFatigueRule';
  duration: Scalars['Int']['output'];
  frequency: Scalars['Int']['output'];
  id: Maybe<Scalars['String']['output']>;
};

/** Generated from com/attentivemobile/contracts/gateway/service/RateLimiting.proto */
export type CompanyGlobalRateLimitsPayload = {
  __typename?: 'CompanyGlobalRateLimitsPayload';
  companyCompanyGlobalRateLimitResponses: Array<UpdateCompanyGlobalRateLimitSuccess>;
};

/** Generated from com/attentivemobile/contracts/company-service/companylink/Model.proto */
export type CompanyLink = {
  __typename?: 'CompanyLink';
  company: Maybe<Company>;
  externalId: Scalars['Long']['output'];
  id: Scalars['String']['output'];
  shortLink: Scalars['String']['output'];
  target: Scalars['String']['output'];
};

export type CompanyLinkInput = {
  companyId: Scalars['ID']['input'];
  target: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/composition/Contract.proto */
export type CompanyLinkParameter = {
  __typename?: 'CompanyLinkParameter';
  company: Maybe<Company>;
  isActive: Scalars['Boolean']['output'];
  isCustom: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  messageChannel: CompanyLinkParameterMessageChannel;
  messageType: CompanyLinkParameterMessageType;
  value: Scalars['String']['output'];
};

export type CompanyLinkParameterInput = {
  isActive: Scalars['Boolean']['input'];
  isCustom: Scalars['Boolean']['input'];
  key: Scalars['String']['input'];
  messageChannel: CompanyLinkParameterMessageChannel;
  messageType: CompanyLinkParameterMessageType;
  value: Scalars['String']['input'];
};

export enum CompanyLinkParameterMessageChannel {
  CompanyLinkParametersMessageChannelEmail = 'COMPANY_LINK_PARAMETERS_MESSAGE_CHANNEL_EMAIL',
  CompanyLinkParametersMessageChannelGlobal = 'COMPANY_LINK_PARAMETERS_MESSAGE_CHANNEL_GLOBAL',
  CompanyLinkParametersMessageChannelText = 'COMPANY_LINK_PARAMETERS_MESSAGE_CHANNEL_TEXT',
  CompanyLinkParametersMessageChannelUnknown = 'COMPANY_LINK_PARAMETERS_MESSAGE_CHANNEL_UNKNOWN'
}

export enum CompanyLinkParameterMessageType {
  CompanyLinkParametersMessageTypeCampaign = 'COMPANY_LINK_PARAMETERS_MESSAGE_TYPE_CAMPAIGN',
  CompanyLinkParametersMessageTypeJourney = 'COMPANY_LINK_PARAMETERS_MESSAGE_TYPE_JOURNEY',
  CompanyLinkParametersMessageTypeUnknown = 'COMPANY_LINK_PARAMETERS_MESSAGE_TYPE_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/composition/Contract.proto */
export type CompanyLinkParametersResponse = {
  __typename?: 'CompanyLinkParametersResponse';
  company: Maybe<Company>;
  companyLinkParameter: Array<CompanyLinkParameter>;
};

/** Generated from com/attentivemobile/contracts/phonenumbers/external/Contract.proto */
export type CompanyMessagingService = {
  __typename?: 'CompanyMessagingService';
  active: Scalars['Boolean']['output'];
  company: Maybe<Company>;
  id: Scalars['String']['output'];
  messagingServiceProviderId: Scalars['String']['output'];
  outboundRateLimitWeight: Scalars['Long']['output'];
  phoneNumber: Maybe<PhoneNumber>;
  primary: Scalars['Boolean']['output'];
  status: MessagingServiceStatus;
  subscriberAssignmentWeight: Scalars['Long']['output'];
};

/** Generated from com/attentivemobile/contracts/concierge/service/Notes.proto */
export type CompanyNotes = {
  __typename?: 'CompanyNotes';
  notes: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto */
export type CompanyProductCategoriesConnection = {
  __typename?: 'CompanyProductCategoriesConnection';
  edges: Array<ProductCategoryEdge>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto */
export type CompanyProductCollectionsConnection = {
  __typename?: 'CompanyProductCollectionsConnection';
  edges: Array<ProductCollectionEdge>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/product/attribute/service/gql/Contract.proto */
export type CompanyProductSummaryEdge = {
  __typename?: 'CompanyProductSummaryEdge';
  cursor: Scalars['String']['output'];
  node: ProductSummary;
};

/** Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto */
export type CompanyProductTagsConnection = {
  __typename?: 'CompanyProductTagsConnection';
  edges: Array<ProductTagEdge>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto */
export type CompanyProductsConnection = {
  __typename?: 'CompanyProductsConnection';
  edges: Array<CompanyProductSummaryEdge>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/company-service/region/Model.proto */
export type CompanyRegionFatigueRule = {
  __typename?: 'CompanyRegionFatigueRule';
  companyRegion: Maybe<Region>;
  /** Duration in seconds. */
  duration: Scalars['Int']['output'];
  fatigueRuleType: CompanyRegionFatigueRuleType;
  frequency: Scalars['Int']['output'];
};

export type CompanyRegionFatigueRuleInput = {
  companyRegionId: Scalars['ID']['input'];
  /** Duration in seconds. */
  duration: Scalars['Int']['input'];
  fatigueRuleType: CompanyRegionFatigueRuleType;
  frequency: Scalars['Int']['input'];
};

export enum CompanyRegionFatigueRuleType {
  CompanyRegionFatigueRuleTypeEmail = 'COMPANY_REGION_FATIGUE_RULE_TYPE_EMAIL',
  CompanyRegionFatigueRuleTypeMessage = 'COMPANY_REGION_FATIGUE_RULE_TYPE_MESSAGE',
  CompanyRegionFatigueRuleTypeUnknown = 'COMPANY_REGION_FATIGUE_RULE_TYPE_UNKNOWN'
}

export type CompanySearchFilter = {
  includeReadonly: InputMaybe<Scalars['Boolean']['input']>;
  searchTerm: InputMaybe<Scalars['String']['input']>;
};

/** Generated from com/attentivemobile/contracts/company-service/companysetting/Model.proto */
export type CompanySettings = {
  __typename?: 'CompanySettings';
  /** @deprecated deprecated */
  googleAnalytics: GoogleAnalytics;
  linkParameters: Array<LinkParameter>;
};

export type CompanySettingsInput = {
  /** google analytics */
  googleAnalytics: GoogleAnalyticsInput;
  /** link parameters */
  linkParameters: InputMaybe<Array<LinkParameterInput>>;
};

/** Generated from com/attentivemobile/contracts/concierge/service/Template.proto */
export type CompanySettingsSource = {
  __typename?: 'CompanySettingsSource';
  company: Maybe<Company>;
};

/** Generated from com/attentivemobile/contracts/company-service/Contract.proto */
export type CompanySmsFatigueRule = {
  __typename?: 'CompanySmsFatigueRule';
  duration: Scalars['Int']['output'];
  frequency: Scalars['Int']['output'];
  id: Maybe<Scalars['String']['output']>;
};

/** Generated from com/attentivemobile/contracts/concierge/service/Template.proto */
export type CompanyTemplateConnection = {
  __typename?: 'CompanyTemplateConnection';
  edges: Array<TemplateEdge>;
  pageInfo: PageInfo;
};

export type CompanyTransition = {
  __typename?: 'CompanyTransition';
  companyId: Maybe<Scalars['String']['output']>;
};

export enum CompanyType {
  CompanyTypeAttentive = 'COMPANY_TYPE_ATTENTIVE',
  CompanyTypeClient = 'COMPANY_TYPE_CLIENT',
  CompanyTypeNonStandard = 'COMPANY_TYPE_NON_STANDARD',
  CompanyTypeTest = 'COMPANY_TYPE_TEST',
  CompanyTypeUnknown = 'COMPANY_TYPE_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/integrations/v2/Contract.proto */
export type CompanyVendorIntegration = Node & {
  __typename?: 'CompanyVendorIntegration';
  company: Maybe<Company>;
  companyVendorIntegrationFeatures: Maybe<Array<CompanyVendorIntegrationFeature>>;
  discount: Maybe<Discount>;
  externalVendorApi: Maybe<ExternalVendorApi>;
  externalVendorData: Maybe<ExternalVendorDataWrapper>;
  id: Scalars['ID']['output'];
  integrationErrors: Maybe<Array<IntegrationError>>;
  metadata: Maybe<CompanyVendorIntegrationMetadata>;
  status: CompanyVendorIntegrationStatus;
};


/** Generated from com/attentivemobile/contracts/integrations/v2/Contract.proto */
export type CompanyVendorIntegrationdiscountArgs = {
  couponCode: Scalars['String']['input'];
};


/** Generated from com/attentivemobile/contracts/integrations/v2/Contract.proto */
export type CompanyVendorIntegrationexternalVendorDataArgs = {
  facebookFilter: InputMaybe<FacebookAdsExternalVendorFilter>;
  fields: InputMaybe<Array<ExternalVendorDataField>>;
};


/** Generated from com/attentivemobile/contracts/integrations/v2/Contract.proto */
export type CompanyVendorIntegrationintegrationErrorsArgs = {
  companyVendorIntegrationFeatureId: InputMaybe<Scalars['ID']['input']>;
  externalVendorApiId: InputMaybe<Scalars['ID']['input']>;
  vendor: InputMaybe<Scalars['String']['input']>;
};

/** Generated from com/attentivemobile/contracts/integrations/v2/Contract.proto */
export type CompanyVendorIntegrationFeature = Node & {
  __typename?: 'CompanyVendorIntegrationFeature';
  company: Maybe<Company>;
  companyVendorIntegration: Maybe<CompanyVendorIntegration>;
  created: Scalars['DateTime']['output'];
  enabled: Scalars['Boolean']['output'];
  errorState: Scalars['String']['output'];
  externalVendorFeature: Maybe<ExternalVendorFeature>;
  id: Scalars['ID']['output'];
  integrationErrors: Maybe<Array<IntegrationError>>;
  metadata: Maybe<CompanyVendorIntegrationFeatureMetadata>;
  triggers: Array<CompanyVendorIntegrationFeatureTrigger>;
};


/** Generated from com/attentivemobile/contracts/integrations/v2/Contract.proto */
export type CompanyVendorIntegrationFeatureintegrationErrorsArgs = {
  companyVendorIntegrationId: InputMaybe<Scalars['ID']['input']>;
  externalVendorApiId: InputMaybe<Scalars['ID']['input']>;
  vendor: InputMaybe<Scalars['String']['input']>;
};

export type CompanyVendorIntegrationFeatureMetadata = CvifGenericMetadata;

/** Generated from com/attentivemobile/contracts/integrations/v2/Contract.proto */
export type CompanyVendorIntegrationFeatureTrigger = {
  __typename?: 'CompanyVendorIntegrationFeatureTrigger';
  companyVendorIntegrationFeatureId: Scalars['String']['output'];
  conditions: Array<CompanyVendorIntegrationFeatureTriggerCondition>;
  enabled: Scalars['Boolean']['output'];
  eventType: EventType;
  id: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/integrations/v2/Contract.proto */
export type CompanyVendorIntegrationFeatureTriggerCondition = {
  __typename?: 'CompanyVendorIntegrationFeatureTriggerCondition';
  companyVendorIntegrationFeatureTriggerId: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  eventFilterField: Maybe<CompanyVendorIntegrationFeatureTriggerConditionEventFilterField>;
  id: Scalars['String']['output'];
};

export type CompanyVendorIntegrationFeatureTriggerConditionEventFilterField = ConditionMessageChannel | ConditionSubscriptionChannel | ConditionSubscriptionType;

export type CompanyVendorIntegrationMetadata = CviGenericMetadata;

export enum CompanyVendorIntegrationStatus {
  CompanyVendorIntegrationStatusUnknown = 'COMPANY_VENDOR_INTEGRATION_STATUS_UNKNOWN',
  Connected = 'CONNECTED',
  Disabled = 'DISABLED',
  Enabled = 'ENABLED'
}

/** Generated from com/attentivemobile/protobuf/common/targeting/segmentation/output/SearchDefinitionOutput.proto */
export type Comparison = {
  __typename?: 'Comparison';
  field: SearchFieldIdentifier;
  typeComparison: TypeComparison;
};

export enum ComparisonDateFilterPeriodType {
  ComparisonDateFilterCustomPeriod = 'COMPARISON_DATE_FILTER_CUSTOM_PERIOD',
  ComparisonDateFilterPreviousPeriod = 'COMPARISON_DATE_FILTER_PREVIOUS_PERIOD',
  ComparisonDateFilterPreviousYear = 'COMPARISON_DATE_FILTER_PREVIOUS_YEAR',
  ComparisonDateFilterUnknown = 'COMPARISON_DATE_FILTER_UNKNOWN'
}

export type ComparisonInput = {
  field: SearchFieldInput;
  typeComparison: TypeComparisonInput;
};

/** Generated from com/attentivemobile/contracts/reports/api/Contract.proto */
export type ComparisonPeriod = {
  __typename?: 'ComparisonPeriod';
  comparisonPeriodType: Maybe<ComparisonDateFilterPeriodType>;
  endDate: Maybe<Scalars['DateTime']['output']>;
  startDate: Maybe<Scalars['DateTime']['output']>;
};

export type ComparisonPeriodInput = {
  comparisonPeriodType: InputMaybe<ComparisonDateFilterPeriodType>;
  endDate: InputMaybe<Scalars['DateTime']['input']>;
  startDate: InputMaybe<Scalars['DateTime']['input']>;
};

/** Generated from com/attentivemobile/contracts/composition/MessageAttachment.proto */
export type CompatibleContent = {
  __typename?: 'CompatibleContent';
  composeContent: Array<ComposeContent>;
  contentCategory: Maybe<ContentCategory>;
  contentMessageType: Maybe<ContentMessageType>;
};

/** Generated from com/attentivemobile/contracts/company-service/onboarding/Company.proto */
export type CompleteCompanyCreationPayload = {
  __typename?: 'CompleteCompanyCreationPayload';
  company: Maybe<Company>;
};

export type CompleteCompanyCreationRequest = {
  addressCountry: Scalars['String']['input'];
  addressLine1: Scalars['String']['input'];
  addressLine2: Scalars['String']['input'];
  /** the company's unique attentive domain e.g. attentive.attn.tv */
  attentiveDomain: Scalars['String']['input'];
  city: Scalars['String']['input'];
  companyId: Scalars['ID']['input'];
  /** the company's name as it will appear in the UI */
  displayName: Scalars['String']['input'];
  /** the company's domain i.e. attentive.com */
  domain: InputMaybe<Scalars['String']['input']>;
  /** a url pointing to the company's privacy policy */
  privacyLink: Scalars['String']['input'];
  state: Scalars['String']['input'];
  /** a url pointing to the company's terms and conditions */
  termsLink: Scalars['String']['input'];
  timezone: Scalars['String']['input'];
  zipcode: Scalars['String']['input'];
};

export type CompleteMilestonesRequest = {
  companyId: Scalars['ID']['input'];
  milestoneKeys: InputMaybe<Array<MilestoneKey>>;
};

/** Generated from com/attentivemobile/contracts/company-service/onboarding/Milestone.proto */
export type CompleteMilestonesResponse = {
  __typename?: 'CompleteMilestonesResponse';
  company: Maybe<Company>;
};

/** Generated from com/attentivemobile/protobuf/common/campaigns/Campaign.proto */
export type ComposeCampaign = Node & {
  __typename?: 'ComposeCampaign';
  campaignId: Scalars['String']['output'];
  campaignMessageGroupIdOrder: Array<Scalars['ID']['output']>;
  campaignMessageGroups: Maybe<CampaignMessageGroupsConnection>;
  campaignStatus: ComposeCampaignStatus;
  campaignType: ComposeCampaignType;
  company: Maybe<Company>;
  created: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isHidden: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  rawUuid: Scalars['String']['output'];
  updated: Scalars['DateTime']['output'];
  validationErrors: Array<CampaignCompositionValidationError>;
};


/** Generated from com/attentivemobile/protobuf/common/campaigns/Campaign.proto */
export type ComposeCampaigncampaignMessageGroupsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<ComposeCampaignsFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** Generated from com/attentivemobile/contracts/campaigns/service/Contract.proto */
export type ComposeCampaignEdge = {
  __typename?: 'ComposeCampaignEdge';
  cursor: Scalars['String']['output'];
  node: ComposeCampaign;
};

/** Generated from com/attentivemobile/contracts/campaigns/service/Contract.proto */
export type ComposeCampaignMessageGroupPayload = {
  __typename?: 'ComposeCampaignMessageGroupPayload';
  campaign: ComposeCampaign;
  campaignMessageGroup: CampaignMessageGroup;
};

/** Generated from com/attentivemobile/contracts/campaigns/service/Contract.proto */
export type ComposeCampaignPayload = {
  __typename?: 'ComposeCampaignPayload';
  campaign: ComposeCampaign;
};

/** Generated from com/attentivemobile/protobuf/common/compose/Message.proto */
export type ComposeCampaignReference = {
  __typename?: 'ComposeCampaignReference';
  campaign: Maybe<ComposeCampaign>;
  campaignMessageGroup: Maybe<CampaignMessageGroup>;
};

export enum ComposeCampaignStatus {
  ComposeCampaignStatusDraft = 'COMPOSE_CAMPAIGN_STATUS_DRAFT',
  ComposeCampaignStatusScheduled = 'COMPOSE_CAMPAIGN_STATUS_SCHEDULED',
  ComposeCampaignStatusSendingStarted = 'COMPOSE_CAMPAIGN_STATUS_SENDING_STARTED',
  ComposeCampaignStatusSent = 'COMPOSE_CAMPAIGN_STATUS_SENT',
  ComposeCampaignStatusUnknown = 'COMPOSE_CAMPAIGN_STATUS_UNKNOWN'
}

export enum ComposeCampaignType {
  ComposeCampaignTypeMultiMessage = 'COMPOSE_CAMPAIGN_TYPE_MULTI_MESSAGE',
  ComposeCampaignTypeSingleMessage = 'COMPOSE_CAMPAIGN_TYPE_SINGLE_MESSAGE',
  ComposeCampaignTypeUnknown = 'COMPOSE_CAMPAIGN_TYPE_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/campaigns/service/Contract.proto */
export type ComposeCampaignsConnection = {
  __typename?: 'ComposeCampaignsConnection';
  edges: Array<ComposeCampaignEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Long']['output'];
};

/** Filters to apply to ComposeCampaign queries. Note that currently some of these filters are also applied directly to CampaignMessageGroups. */
export type ComposeCampaignsFilter = {
  autoCampaignFilter: InputMaybe<AutoCampaignFilter>;
  campaignId: InputMaybe<Array<Scalars['ID']['input']>>;
  campaignMessageGroupIds: InputMaybe<Array<Scalars['ID']['input']>>;
  /** @deprecated deprecated */
  campaignMessageGroupStatuses: InputMaybe<Array<CampaignMessageGroupStatus>>;
  composeMessageIds: InputMaybe<Array<Scalars['ID']['input']>>;
  dateRange: InputMaybe<DateTimeRange>;
  isHidden: InputMaybe<Scalars['Boolean']['input']>;
  listComposeUiFilter: InputMaybe<Array<ListComposeCampaignUiFilter>>;
  messageChannels: InputMaybe<Array<ComposeMessageChannel>>;
  /** The name of the CC/CMG. Applies case insensitive searching and matches on partials. */
  name: InputMaybe<Scalars['String']['input']>;
  tagUuids: InputMaybe<Array<Scalars['String']['input']>>;
  textMessagingService: InputMaybe<TextMessagingService>;
  types: InputMaybe<Array<CampaignMessageGroupType>>;
};

/** Generated from com/attentivemobile/contracts/composition/MessageAttachment.proto */
export type ComposeContent = {
  __typename?: 'ComposeContent';
  attachmentType: AttachmentType;
  macroConfigurations: MacroConfiguration;
  macroRoot: MacroAttribute;
};

/** Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto */
export type ComposeMessage = Node & {
  __typename?: 'ComposeMessage';
  attachments: Array<Attachment>;
  channel: ComposeMessageChannel;
  channelProperties: ChannelProperties;
  company: Maybe<Company>;
  compatibleContent: CompatibleContent;
  created: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  ignoreFatigue: Scalars['Boolean']['output'];
  internalId: Scalars['Long']['output'];
  mediaType: Scalars['String']['output'];
  mediaUrls: Array<Url>;
  messageTags: Array<ComposeMessageTag>;
  name: Scalars['String']['output'];
  publishMessageValidationErrors: Array<PublishMessageValidationError>;
  status: ComposeMessageStatus;
  subtype: Scalars['String']['output'];
  type: ComposeMessageType;
  updated: Scalars['DateTime']['output'];
  utmParameters: Array<UtmParametersEntry>;
  /** @deprecated deprecated */
  validationErrors: Array<CompositionValidationError>;
};

export enum ComposeMessageChannel {
  ComposeMessageChannelEmail = 'COMPOSE_MESSAGE_CHANNEL_EMAIL',
  ComposeMessageChannelRbm = 'COMPOSE_MESSAGE_CHANNEL_RBM',
  ComposeMessageChannelText = 'COMPOSE_MESSAGE_CHANNEL_TEXT',
  ComposeMessageChannelUnknown = 'COMPOSE_MESSAGE_CHANNEL_UNKNOWN'
}

/** Generated from com/attentivemobile/protobuf/common/compose/Message.proto */
export type ComposeMessageReference = {
  __typename?: 'ComposeMessageReference';
  composeMessage: Maybe<ComposeMessage>;
};

export enum ComposeMessageStatus {
  ComposeMessageStatusActive = 'COMPOSE_MESSAGE_STATUS_ACTIVE',
  ComposeMessageStatusDraft = 'COMPOSE_MESSAGE_STATUS_DRAFT',
  ComposeMessageStatusUnknown = 'COMPOSE_MESSAGE_STATUS_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto */
export type ComposeMessageTag = {
  __typename?: 'ComposeMessageTag';
  tag: Scalars['String']['output'];
  type: ComposeMessageTagType;
};

export type ComposeMessageTagInput = {
  tag: Scalars['String']['input'];
  type: ComposeMessageTagType;
};

export enum ComposeMessageTagType {
  ComposeMessageTagInternal = 'COMPOSE_MESSAGE_TAG_INTERNAL',
  ComposeMessageTagPublic = 'COMPOSE_MESSAGE_TAG_PUBLIC'
}

/** Generated from com/attentivemobile/protobuf/common/compose/Message.proto */
export type ComposeMessageTemplate = Node & {
  __typename?: 'ComposeMessageTemplate';
  channel: Channel;
  company: Maybe<Company>;
  created: Scalars['DateTime']['output'];
  deleted: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  metadata: Maybe<ComposeMessageTemplateMetadata>;
  syncedReferences: MessageTemplateReferences;
  templateName: Scalars['String']['output'];
  updated: Scalars['DateTime']['output'];
};

/** Generated from com/attentivemobile/contracts/compose/api/Contract.proto */
export type ComposeMessageTemplateEdge = {
  __typename?: 'ComposeMessageTemplateEdge';
  cursor: Scalars['String']['output'];
  node: ComposeMessageTemplate;
};

export type ComposeMessageTemplateMetaDataInput = {
  emailTemplate: InputMaybe<EmailTemplateInput>;
  textTemplate: InputMaybe<TextTemplateInput>;
};

export type ComposeMessageTemplateMetadata = EmailTemplate | TextTemplate;

/** Generated from com/attentivemobile/protobuf/common/compose/Message.proto */
export type ComposeMessageTemplateReference = {
  __typename?: 'ComposeMessageTemplateReference';
  template: Maybe<ComposeMessageTemplate>;
};

export enum ComposeMessageType {
  ComposeMessageTypeAiJourney = 'COMPOSE_MESSAGE_TYPE_AI_JOURNEY',
  ComposeMessageTypeCampaign = 'COMPOSE_MESSAGE_TYPE_CAMPAIGN',
  ComposeMessageTypeConcierge = 'COMPOSE_MESSAGE_TYPE_CONCIERGE',
  ComposeMessageTypeConversation = 'COMPOSE_MESSAGE_TYPE_CONVERSATION',
  ComposeMessageTypeJourney = 'COMPOSE_MESSAGE_TYPE_JOURNEY',
  ComposeMessageTypePayment = 'COMPOSE_MESSAGE_TYPE_PAYMENT',
  ComposeMessageTypeTransactional = 'COMPOSE_MESSAGE_TYPE_TRANSACTIONAL',
  ComposeMessageTypeUnknown = 'COMPOSE_MESSAGE_TYPE_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto */
export type CompositionValidationError = {
  __typename?: 'CompositionValidationError';
  message: Scalars['String']['output'];
  validationErrorInformation: ValidationErrorInformation;
};

export type CompressMMSImageForUIPayload = CompressMMSImageForUISuccess | DefaultErrorFailure;

/** Generated from com/attentivemobile/contracts/composition/media/gif/Contract.proto */
export type CompressMMSImageForUISuccess = {
  __typename?: 'CompressMMSImageForUISuccess';
  url: Scalars['String']['output'];
};

export type CompressMMSImageInput = {
  companyId: Scalars['ID']['input'];
  url: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/concierge/service/Contract.proto */
export type Concierge = {
  __typename?: 'Concierge';
  averageResponseTime: Maybe<GetAverageResponseTimeResponse>;
  companyMacros: Maybe<GetCompanyMacrosResponse>;
  specializations: Maybe<ConciergeSpecializationsConnection>;
  unhandledUsers: UnhandledUsersConnection;
};


/** Generated from com/attentivemobile/contracts/concierge/service/Contract.proto */
export type ConciergecompanyMacrosArgs = {
  companyId: InputMaybe<Scalars['ID']['input']>;
};


/** Generated from com/attentivemobile/contracts/concierge/service/Contract.proto */
export type ConciergespecializationsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  searchTerm: InputMaybe<Scalars['String']['input']>;
  sort: InputMaybe<ConciergeSpecializationsSort>;
};

/** Generated from com/attentivemobile/contracts/concierge/service/Contract.proto */
export type ConciergeAgent = {
  __typename?: 'ConciergeAgent';
  account: Maybe<Account>;
  capacity: Scalars['Long']['output'];
  chats: Array<ConciergeChat>;
  isAvailable: Scalars['Boolean']['output'];
  isEnrolledIntoChats: Scalars['Boolean']['output'];
};

/** Generated from com/attentivemobile/contracts/concierge/service/ShoppingCart.proto */
export type ConciergeCartCoupon = {
  __typename?: 'ConciergeCartCoupon';
  couponCode: Scalars['String']['output'];
  isCouponCodeEnteredManually: Scalars['Boolean']['output'];
};

export type ConciergeCartCouponInput = {
  couponCode: Scalars['String']['input'];
  isCouponCodeEnteredManually: Scalars['Boolean']['input'];
};

export type ConciergeCartItemInput = {
  lastUpdated: Scalars['DateTime']['input'];
  productVariantId: Scalars['ID']['input'];
  quantity: Scalars['Int']['input'];
};

/** Generated from com/attentivemobile/contracts/concierge/service/Contract.proto */
export type ConciergeChat = {
  __typename?: 'ConciergeChat';
  id: Scalars['ID']['output'];
  latestInbound: Maybe<Scalars['DateTime']['output']>;
  latestOutbound: Maybe<Scalars['DateTime']['output']>;
  user: Maybe<User>;
};

/** Generated from com/attentivemobile/contracts/concierge/service/Company.proto */
export type ConciergeCompanyDetails = {
  __typename?: 'ConciergeCompanyDetails';
  escalationEmails: Maybe<GetEscalationEmailsResponse>;
  notes: Maybe<CompanyNotes>;
  promotions: Array<ConciergeCompanyPromotion>;
  specialization: Maybe<ConciergeSpecialization>;
};

/** Generated from com/attentivemobile/contracts/concierge/service/CompanyPromotions.proto */
export type ConciergeCompanyPromotion = {
  __typename?: 'ConciergeCompanyPromotion';
  end: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  limitedTime: Scalars['Boolean']['output'];
  note: Scalars['String']['output'];
  start: Maybe<Scalars['DateTime']['output']>;
  startPromoting: Maybe<Scalars['DateTime']['output']>;
  title: Scalars['String']['output'];
  type: ConciergeCompanyPromotionType;
};

export enum ConciergeCompanyPromotionType {
  Coupon = 'COUPON',
  Event = 'EVENT'
}

/** Generated from com/attentivemobile/contracts/concierge/service/CompanyPromotions.proto */
export type ConciergeCompanyPromotionsResponse = {
  __typename?: 'ConciergeCompanyPromotionsResponse';
  promotions: Array<ConciergeCompanyPromotion>;
};

/** Generated from com/attentivemobile/contracts/concierge/service/ConversationManager.proto */
export type ConciergeIncentiveConfiguration = {
  __typename?: 'ConciergeIncentiveConfiguration';
  isOfferEnabled: Scalars['Boolean']['output'];
  offer: Maybe<CouponSet>;
};

export type ConciergeIncentiveConfigurationInput = {
  isOfferEnabled: Scalars['Boolean']['input'];
  offerDomainId: InputMaybe<Scalars['String']['input']>;
};

/** Generated from com/attentivemobile/contracts/concierge/service/ConversationManager.proto */
export type ConciergeJourneyControls = {
  __typename?: 'ConciergeJourneyControls';
  incentiveConfiguration: ConciergeIncentiveConfiguration;
  isConciergeEnabled: Scalars['Boolean']['output'];
};

export enum ConciergeLinkType {
  ConciergeLinkTypeAgentClickToBuyCheckout = 'CONCIERGE_LINK_TYPE_AGENT_CLICK_TO_BUY_CHECKOUT',
  ConciergeLinkTypeMlProductRecommendation = 'CONCIERGE_LINK_TYPE_ML_PRODUCT_RECOMMENDATION'
}

/** Generated from com/attentivemobile/contracts/concierge/service/SelfServe.proto */
export type ConciergeSelfServeSettings = {
  __typename?: 'ConciergeSelfServeSettings';
  additionalUrls: Array<Scalars['String']['output']>;
  agentName: Maybe<Scalars['String']['output']>;
  companyId: Scalars['String']['output'];
  conciergeRegistrationName: Maybe<Scalars['String']['output']>;
  conciergeSelfServeStatus: ConciergeSelfServeStatus;
  couponSet: Maybe<CouponSet>;
  customUrlParameters: Array<CustomUrlParametersEntry>;
  customerServiceEmail: Maybe<Scalars['String']['output']>;
  domesticShippingTimeframe: Maybe<Scalars['String']['output']>;
  escalationEmails: Array<Scalars['String']['output']>;
  faqUrl: Maybe<Scalars['String']['output']>;
  freeShippingPricePoint: Maybe<Scalars['String']['output']>;
  internationalShippingTimeframe: Maybe<Scalars['String']['output']>;
  isUtmTrackingEnabled: Scalars['Boolean']['output'];
  journeyTemplateSiteVisitorId: Scalars['Long']['output'];
  messageTone: Maybe<MessageTone>;
  processingTimeBeforeShipping: Maybe<Scalars['String']['output']>;
  returnPolicyUrl: Maybe<Scalars['String']['output']>;
  shippingProviderOther: Maybe<Scalars['String']['output']>;
  shippingProviders: Array<ShippingProvider>;
  siteVisitorJourneyId: Maybe<Scalars['Long']['output']>;
  utmCampaign: Maybe<Scalars['String']['output']>;
};

export enum ConciergeSelfServeStatus {
  ConciergeSelfServeStatusAgentPreferences = 'CONCIERGE_SELF_SERVE_STATUS_AGENT_PREFERENCES',
  ConciergeSelfServeStatusCompleted = 'CONCIERGE_SELF_SERVE_STATUS_COMPLETED',
  ConciergeSelfServeStatusCustomerSupport = 'CONCIERGE_SELF_SERVE_STATUS_CUSTOMER_SUPPORT',
  ConciergeSelfServeStatusShipping = 'CONCIERGE_SELF_SERVE_STATUS_SHIPPING',
  ConciergeSelfServeStatusSignUp = 'CONCIERGE_SELF_SERVE_STATUS_SIGN_UP',
  ConciergeSelfServeStatusUnknown = 'CONCIERGE_SELF_SERVE_STATUS_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/concierge/service/Message.proto */
export type ConciergeSession = {
  __typename?: 'ConciergeSession';
  cart: Maybe<SavedConciergeCart>;
  end: Maybe<Scalars['DateTime']['output']>;
  sessionId: Scalars['String']['output'];
  source: Maybe<ConciergeSessionSource>;
  start: Maybe<Scalars['DateTime']['output']>;
};

export type ConciergeSessionSource = CampaignSource | JourneySource | LegalMessageSource | SubscriberInitiated;

/** Generated from com/attentivemobile/contracts/concierge/service/ConversationManager.proto */
export type ConciergeSettings = {
  __typename?: 'ConciergeSettings';
  isConciergeEnabled: Scalars['Boolean']['output'];
  isRouteAllInboundsEnabled: Scalars['Boolean']['output'];
};

/** Generated from com/attentivemobile/contracts/concierge/service/Specialization.proto */
export type ConciergeSpecialization = Node & {
  __typename?: 'ConciergeSpecialization';
  agentCount: Scalars['Long']['output'];
  agents: Array<ConciergeSpecializedAgent>;
  companies: Array<ConciergeSpecializedCompany>;
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  strictRouting: Scalars['Boolean']['output'];
};

/** Generated from com/attentivemobile/contracts/concierge/service/Specialization.proto */
export type ConciergeSpecializationEdge = {
  __typename?: 'ConciergeSpecializationEdge';
  cursor: Scalars['String']['output'];
  node: ConciergeSpecialization;
};

/** Generated from com/attentivemobile/contracts/concierge/service/Specialization.proto */
export type ConciergeSpecializationsConnection = {
  __typename?: 'ConciergeSpecializationsConnection';
  edges: Array<ConciergeSpecializationEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Long']['output'];
};

export type ConciergeSpecializationsSort = {
  column: ConciergeSpecializationsSortColumn;
  direction: ConciergeSpecializationsSortDirection;
};

export enum ConciergeSpecializationsSortColumn {
  ConciergeSpecializationsSortColumnName = 'CONCIERGE_SPECIALIZATIONS_SORT_COLUMN_NAME'
}

export enum ConciergeSpecializationsSortDirection {
  ConciergeSpecializationsSortDirectionAsc = 'CONCIERGE_SPECIALIZATIONS_SORT_DIRECTION_ASC',
  ConciergeSpecializationsSortDirectionDesc = 'CONCIERGE_SPECIALIZATIONS_SORT_DIRECTION_DESC'
}

/** Generated from com/attentivemobile/contracts/concierge/service/Specialization.proto */
export type ConciergeSpecializedAgent = {
  __typename?: 'ConciergeSpecializedAgent';
  account: Maybe<Account>;
};

/** Generated from com/attentivemobile/contracts/concierge/service/Specialization.proto */
export type ConciergeSpecializedCompany = {
  __typename?: 'ConciergeSpecializedCompany';
  company: Maybe<Company>;
};

/** Generated from com/attentivemobile/contracts/integrations/v2/Contract.proto */
export type ConditionMessageChannel = {
  __typename?: 'ConditionMessageChannel';
  messageChannel: MessagingChannel;
};

/** Generated from com/attentivemobile/contracts/integrations/v2/Contract.proto */
export type ConditionSubscriptionChannel = {
  __typename?: 'ConditionSubscriptionChannel';
  subscriptionChannel: SubscriptionChannel;
};

/** Generated from com/attentivemobile/contracts/integrations/v2/Contract.proto */
export type ConditionSubscriptionType = {
  __typename?: 'ConditionSubscriptionType';
  subscriptionType: SubscriptionType;
};

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type ConfigurationNotificationConnection = {
  __typename?: 'ConfigurationNotificationConnection';
  edges: Array<AdminNotificationConfigurationEdge>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/integrations/v2/Contract.proto */
export type ConfiguredIntegrationPayload = {
  __typename?: 'ConfiguredIntegrationPayload';
  companyVendorIntegration: Maybe<CompanyVendorIntegration>;
  configuredParameters: Array<ConfiguredParametersEntry>;
  editable: Scalars['Boolean']['output'];
  externalVendorApi: Maybe<ExternalVendorApi>;
  features: Array<Feature>;
  status: CompanyVendorIntegrationStatus;
};

/** Generated from com/attentivemobile/contracts/integrations/v2/Contract.proto */
export type ConfiguredParametersEntry = {
  __typename?: 'ConfiguredParametersEntry';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ConfirmResetSftpUserPasswordInput = {
  companyId: Scalars['ID']['input'];
  confirmationCode: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/solutions/sftp/SftpUser.proto */
export type ConfirmResetSftpUserPasswordPayload = {
  __typename?: 'ConfirmResetSftpUserPasswordPayload';
  responseMsg: Scalars['String']['output'];
};

export type ConfirmSftpSettingsInput = {
  sftpSettings: SftpSettingsInput;
};

export type ConfirmSftpSettingsPayload = ConfirmSftpSettingsSuccess | DefaultErrorFailure;

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type ConfirmSftpSettingsSuccess = {
  __typename?: 'ConfirmSftpSettingsSuccess';
  responseCode: Scalars['Int']['output'];
  sftpConnectionStatus: SftpConnectionStatus;
  success: Scalars['Boolean']['output'];
};

export type ConfirmSnowflakeSettingsInput = {
  companyId: InputMaybe<Scalars['ID']['input']>;
  snowflakeSettings: SnowflakeSecureDataSharingInput;
};

export type ConfirmSnowflakeSettingsPayload = ConfirmSnowflakeSettingsSuccess | DefaultErrorFailure;

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type ConfirmSnowflakeSettingsSuccess = {
  __typename?: 'ConfirmSnowflakeSettingsSuccess';
  result: Maybe<SnowflakeSettingsConfirmationResult>;
  snowflakeSettings: SnowflakeSecureDataSharingSettings;
};

/** Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto */
export type ConflictingAttachments = {
  __typename?: 'ConflictingAttachments';
  attachments: Array<Attachment>;
};

export enum ContentCategory {
  ContentCategoryMarketing = 'CONTENT_CATEGORY_MARKETING',
  ContentCategoryTransactional = 'CONTENT_CATEGORY_TRANSACTIONAL',
  ContentCategoryUnspecified = 'CONTENT_CATEGORY_UNSPECIFIED',
  ContentCategoryWelcome = 'CONTENT_CATEGORY_WELCOME'
}

export enum ContentClass {
  ContentClassFeatureAnnouncementModal = 'CONTENT_CLASS_FEATURE_ANNOUNCEMENT_MODAL',
  ContentClassModal = 'CONTENT_CLASS_MODAL',
  ContentClassNextBestActions = 'CONTENT_CLASS_NEXT_BEST_ACTIONS',
  ContentClassUnknown = 'CONTENT_CLASS_UNKNOWN'
}

export enum ContentMessageType {
  ContentMessageTypeCampaign = 'CONTENT_MESSAGE_TYPE_CAMPAIGN',
  ContentMessageTypeJourney = 'CONTENT_MESSAGE_TYPE_JOURNEY',
  ContentMessageTypeUnspecified = 'CONTENT_MESSAGE_TYPE_UNSPECIFIED'
}

export type ContextEvaluationItem = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/conversations/v1/Conversation.proto */
export type Conversation = {
  __typename?: 'Conversation';
  isUnread: Scalars['Boolean']['output'];
  phoneNumber: Scalars['String']['output'];
  previewText: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
  user: Maybe<User>;
};

/** Generated from com/attentivemobile/contracts/conversations/v1/Conversation.proto */
export type ConversationConnection = {
  __typename?: 'ConversationConnection';
  edges: Array<ConversationEdge>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/conversations/v1/Conversation.proto */
export type ConversationEdge = {
  __typename?: 'ConversationEdge';
  cursor: Scalars['String']['output'];
  node: Conversation;
};

export enum ConversationSort {
  Latest = 'LATEST',
  Unread = 'UNREAD'
}

/** Generated from com/attentivemobile/contracts/concierge/service/Template.proto */
export type ConversationSource = {
  __typename?: 'ConversationSource';
  user: Maybe<User>;
};

/** Generated from com/attentivemobile/protobuf/common/targeting/UserProperty.proto */
export type ConversationalState = {
  __typename?: 'ConversationalState';
  conversationId: Scalars['String']['output'];
  isEngagedWithAgent: Scalars['Boolean']['output'];
};

/** Generated from com/attentivemobile/contracts/concierge/service/ConversationManager.proto */
export type ConversationsSettings = {
  __typename?: 'ConversationsSettings';
  conciergeJourneyControls: Array<ConciergeJourneyControls>;
  escalationEmails: Array<Scalars['String']['output']>;
  incentiveConfiguration: ConciergeIncentiveConfiguration;
  isConciergeForCampaignsEnabled: Scalars['Boolean']['output'];
  isRouteAllInboundsEnabled: Scalars['Boolean']['output'];
  utmConfiguration: UtmConfiguration;
};

export enum ConversionsFeedCols {
  Amount = 'AMOUNT',
  ClientIdConversions = 'CLIENT_ID_CONVERSIONS',
  ClientUserIdConversions = 'CLIENT_USER_ID_CONVERSIONS',
  CompanyIdConversions = 'COMPANY_ID_CONVERSIONS',
  Coupon = 'COUPON',
  EmailConversions = 'EMAIL_CONVERSIONS',
  EventUuid = 'EVENT_UUID',
  ExternalIdConversions = 'EXTERNAL_ID_CONVERSIONS',
  MessageIdConversions = 'MESSAGE_ID_CONVERSIONS',
  MessageNameConversions = 'MESSAGE_NAME_CONVERSIONS',
  MessageSubtypeConversions = 'MESSAGE_SUBTYPE_CONVERSIONS',
  MessageTextConversions = 'MESSAGE_TEXT_CONVERSIONS',
  MessageTypeConversions = 'MESSAGE_TYPE_CONVERSIONS',
  OrderIdConversions = 'ORDER_ID_CONVERSIONS',
  PhoneConversions = 'PHONE_CONVERSIONS',
  ProductSkus = 'PRODUCT_SKUS',
  QuantityConversions = 'QUANTITY_CONVERSIONS',
  TimestampConversions = 'TIMESTAMP_CONVERSIONS',
  UuidConversions = 'UUID_CONVERSIONS'
}

export type CopyMessageInput = {
  companyId: Scalars['ID']['input'];
  excludeContent: InputMaybe<Scalars['Boolean']['input']>;
  sourceMessageId: Scalars['ID']['input'];
  targetMessagesToOverwrite: InputMaybe<Array<TargetMessageInput>>;
};

/** Generated from com/attentivemobile/contracts/composition/Contract.proto */
export type CopyMessagePayload = {
  __typename?: 'CopyMessagePayload';
  copiedMessages: Array<ComposeMessage>;
};

export enum CopyRecipientType {
  CopyRecipientTypeBcc = 'COPY_RECIPIENT_TYPE_BCC',
  CopyRecipientTypeCc = 'COPY_RECIPIENT_TYPE_CC'
}

/** Generated from com/attentivemobile/contracts/message/explorer/Contract.proto */
export type CountResponse = {
  __typename?: 'CountResponse';
  totalCount: Maybe<Scalars['Long']['output']>;
};

/** Generated from com/attentivemobile/contracts/geography/Location.proto */
export type Country = Node & {
  __typename?: 'Country';
  countrySubdivisions: Maybe<Array<CountrySubdivision>>;
  /** ISO 3166 country code */
  id: Scalars['ID']['output'];
  isoCode: Scalars['String']['output'];
  name: Scalars['String']['output'];
  postalCodes: Maybe<PostalCodeConnection>;
};


/** Generated from com/attentivemobile/contracts/geography/Location.proto */
export type CountrypostalCodesArgs = {
  adjacent: InputMaybe<ListAdjacentPostalCodesInput>;
  after: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
};

/** Generated from com/attentivemobile/contracts/geography/Location.proto */
export type CountrySubdivision = Node & {
  __typename?: 'CountrySubdivision';
  country: Maybe<Country>;
  /** ISO 3166 country code */
  id: Scalars['ID']['output'];
  isoCode: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/solutions/couponallocator/CouponAllocator.proto */
export type CouponAllocationJob = {
  __typename?: 'CouponAllocationJob';
  company: Maybe<Company>;
  /** @deprecated deprecated */
  companyName: Scalars['String']['output'];
  dateRange: AllocationDateRange;
  /** @deprecated deprecated */
  endTime: Scalars['String']['output'];
  jobId: Scalars['String']['output'];
  progress: Scalars['String']['output'];
  /** @deprecated deprecated */
  startTime: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type CouponAssignmentInput = {
  couponSetId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/coupon/Contract.proto */
export type CouponAssignmentPayload = {
  __typename?: 'CouponAssignmentPayload';
  couponCode: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/messaging/automation/aijourneys/onboarding/AIJourneyLinkConfiguration.proto */
export type CouponDisplayScreenConfig = {
  __typename?: 'CouponDisplayScreenConfig';
  /** This field is ignored. Included to maintain compatibility with the graph. */
  emptyUrl: Maybe<Scalars['String']['output']>;
};

export type CouponDisplayScreenConfigInput = {
  /** This field is ignored. Included to maintain compatibility with the graph. */
  emptyUrl: InputMaybe<Scalars['String']['input']>;
};

/** Generated from com/attentivemobile/contracts/coupon/CouponContract.proto */
export type CouponSet = Node & {
  __typename?: 'CouponSet';
  autoRefresh: Scalars['Boolean']['output'];
  codeTemplate: Maybe<Scalars['String']['output']>;
  company: Maybe<Company>;
  countAssignable: Maybe<Scalars['Int']['output']>;
  couponUploads: Maybe<CouponUploadConnection>;
  couponUrlTemplate: Maybe<Scalars['String']['output']>;
  couponValue: Maybe<CouponValue>;
  created: Scalars['DateTime']['output'];
  description: Maybe<Scalars['String']['output']>;
  distributionTtlInSeconds: Maybe<Scalars['Long']['output']>;
  distributionTtlUnit: Maybe<DistributionUnit>;
  externalId: Maybe<Scalars['Long']['output']>;
  id: Scalars['ID']['output'];
  internalId: Scalars['Long']['output'];
  name: Scalars['String']['output'];
  staticCode: Maybe<Scalars['String']['output']>;
  status: CouponSetStatus;
  totalCount: Maybe<Scalars['Int']['output']>;
  type: CouponSetType;
  updated: Scalars['DateTime']['output'];
  uploadStatus: CouponUploadStatus;
};


/** Generated from com/attentivemobile/contracts/coupon/CouponContract.proto */
export type CouponSetcouponUploadsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
};

/** Generated from com/attentivemobile/contracts/coupon/CouponContract.proto */
export type CouponSetConnection = {
  __typename?: 'CouponSetConnection';
  edges: Array<CouponSetEdge>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/coupon/CouponContract.proto */
export type CouponSetEdge = {
  __typename?: 'CouponSetEdge';
  cursor: Scalars['String']['output'];
  node: CouponSet;
};

export enum CouponSetStatus {
  Active = 'ACTIVE',
  CouponSetStatusUnknown = 'COUPON_SET_STATUS_UNKNOWN',
  Inactive = 'INACTIVE'
}

export enum CouponSetType {
  CouponSetTypeUnknown = 'COUPON_SET_TYPE_UNKNOWN',
  Dynamic = 'DYNAMIC',
  Static = 'STATIC'
}

export enum CouponType {
  Code = 'CODE',
  Url = 'URL'
}

/** Generated from com/attentivemobile/contracts/coupon/Contract.proto */
export type CouponUpload = Node & {
  __typename?: 'CouponUpload';
  countCreated: Scalars['Long']['output'];
  countDuplicate: Scalars['Long']['output'];
  countUploaded: Scalars['Long']['output'];
  couponSet: Maybe<CouponSet>;
  created: Scalars['DateTime']['output'];
  distributionStart: Maybe<Scalars['DateTime']['output']>;
  distributionStop: Maybe<Scalars['DateTime']['output']>;
  filename: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  results: Scalars['String']['output'];
  updated: Scalars['DateTime']['output'];
  uploadStatus: CouponUploadStatus;
};

/** Generated from com/attentivemobile/contracts/coupon/Contract.proto */
export type CouponUploadConnection = {
  __typename?: 'CouponUploadConnection';
  edges: Array<CouponUploadEdge>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/coupon/Contract.proto */
export type CouponUploadEdge = {
  __typename?: 'CouponUploadEdge';
  cursor: Scalars['String']['output'];
  node: CouponUpload;
};

export enum CouponUploadStatus {
  CouponUploadStatusUnknown = 'COUPON_UPLOAD_STATUS_UNKNOWN',
  CouponUploadStatusUploadActive = 'COUPON_UPLOAD_STATUS_UPLOAD_ACTIVE',
  CouponUploadStatusUploadError = 'COUPON_UPLOAD_STATUS_UPLOAD_ERROR',
  CouponUploadStatusUploadPending = 'COUPON_UPLOAD_STATUS_UPLOAD_PENDING',
  CouponUploadStatusUploadSyncing = 'COUPON_UPLOAD_STATUS_UPLOAD_SYNCING'
}

/** Generated from com/attentivemobile/contracts/coupon/Contract.proto */
export type CouponValue = {
  __typename?: 'CouponValue';
  currencyCode: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
  valueType: CouponValueType;
};

export type CouponValueInput = {
  currencyCode: InputMaybe<Scalars['String']['input']>;
  value: Scalars['String']['input'];
  valueType: CouponValueType;
};

export enum CouponValueType {
  CouponValueTypeFlatFixedCurrency = 'COUPON_VALUE_TYPE_FLAT_FIXED_CURRENCY',
  CouponValueTypePercent = 'COUPON_VALUE_TYPE_PERCENT',
  CouponValueTypeUnknown = 'COUPON_VALUE_TYPE_UNKNOWN'
}

export type CreateAIBrandVoiceSettingsInput = {
  aiBrandVoiceSettings: AIBrandVoiceSettingsInput;
  companyId: Scalars['ID']['input'];
};

export type CreateAIBrandVoiceSettingsPayload = CreateAIBrandVoiceSettingsSuccess | DefaultErrorFailure;

/** Generated from com/attentivemobile/contracts/messaging/automation/brandvoice/AIBrandVoice.proto */
export type CreateAIBrandVoiceSettingsSuccess = {
  __typename?: 'CreateAIBrandVoiceSettingsSuccess';
  aiBrandVoiceSettings: AIBrandVoiceSettings;
  statusText: Scalars['String']['output'];
};

export type CreateAdminNotificationConfigurationInput = {
  adminNotificationConfiguration: AdminNotificationConfigurationInput;
};

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type CreateAdminNotificationConfigurationPayload = {
  __typename?: 'CreateAdminNotificationConfigurationPayload';
  adminNotificationConfiguration: AdminNotificationConfiguration;
};

export type CreateAgentTemplateInput = {
  companyId: InputMaybe<Scalars['Long']['input']>;
  shortcut: InputMaybe<Scalars['String']['input']>;
  sourceLinkId: InputMaybe<Scalars['ID']['input']>;
  sourceType: InputMaybe<SourceType>;
  text: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type CreateAgentTemplateRequestInput = {
  companyId: InputMaybe<Scalars['Long']['input']>;
  notes: InputMaybe<Scalars['String']['input']>;
  requestedBy: InputMaybe<Scalars['ID']['input']>;
  shortcut: InputMaybe<Scalars['String']['input']>;
  sourceLinkId: Scalars['ID']['input'];
  sourceType: SourceType;
  text: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type CreateAiProTrialSubscriptionInput = {
  companyId: Scalars['ID']['input'];
  formSubmittedDate: InputMaybe<DateInput>;
  startDate: InputMaybe<DateInput>;
};

export type CreateAiProTrialSubscriptionPayload = CreateAiProTrialSubscriptionSuccess | DefaultErrorFailure;

/** Generated from com/attentivemobile/contracts/segment/recommendation/service/aipro/Contract.proto */
export type CreateAiProTrialSubscriptionSuccess = {
  __typename?: 'CreateAiProTrialSubscriptionSuccess';
  eligibilityStatus: AiProEligibilityStatus;
  reason: Maybe<AiProIneligibleReason>;
  success: Scalars['Boolean']['output'];
};

export type CreateAndReleaseTagConfigInput = {
  companyId: Scalars['ID']['input'];
  description: InputMaybe<Scalars['String']['input']>;
  dtagConfig: JsonStringInput;
  enrichers: InputMaybe<Array<Scalars['String']['input']>>;
  preset: Scalars['String']['input'];
  tagVersion: Scalars['String']['input'];
  transformers: InputMaybe<Array<Scalars['String']['input']>>;
  triggers: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CreateAndReleaseTagConfigPayload = CreateAndReleaseTagConfigSuccess | DefaultErrorFailure;

/** Generated from com/attentivemobile/contracts/tagbuildservice/Contract.proto */
export type CreateAndReleaseTagConfigSuccess = {
  __typename?: 'CreateAndReleaseTagConfigSuccess';
  releasedSuccessfully: Scalars['Boolean']['output'];
  tagConfig: TagConfig;
};

export type CreateApiSignUpCreativeInput = {
  companyId: Scalars['ID']['input'];
  externalName: InputMaybe<Scalars['String']['input']>;
  language: Scalars['String']['input'];
  name: Scalars['String']['input'];
  smsBody: Scalars['String']['input'];
  subscriptionType: InputMaybe<CreativeSubscriptionType>;
};

export type CreateApiSignUpCreativePayload = CreateApiSignUpCreativeSuccess | DefaultErrorFailure;

/** Generated from com/attentivemobile/contracts/creative-service/creative/CreativeGraphService.proto */
export type CreateApiSignUpCreativeSuccess = {
  __typename?: 'CreateApiSignUpCreativeSuccess';
  apiSignupCreative: ApiSignupCreative;
};

export type CreateAutoJourneyInput = {
  autoJourneyType: InputMaybe<AutoJourneyType>;
  clientSettings: AutoJourneyClientSettingsInput;
  companyId: Scalars['ID']['input'];
  config: AutoJourneyConfigInput;
  /** @deprecated deprecated */
  messageSequencesCount: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  type: JourneyTriggerType;
};

export type CreateAutoJourneyMessageIdInput = {
  autoJourneyId: Scalars['ID']['input'];
  messageConfigs: InputMaybe<Array<MessageConfig>>;
};

export type CreateAutoJourneyMessageIdPayload = CreateAutoJourneyMessageIdSuccess | DefaultErrorFailure;

/** Generated from com/attentivemobile/contracts/messaging/automation/autojourneys/AutoJourneysContract.proto */
export type CreateAutoJourneyMessageIdSuccess = {
  __typename?: 'CreateAutoJourneyMessageIdSuccess';
  success: Scalars['Boolean']['output'];
};

/** Generated from com/attentivemobile/contracts/messaging/automation/autojourneys/AutoJourneysContract.proto */
export type CreateAutoJourneyPayload = {
  __typename?: 'CreateAutoJourneyPayload';
  journey: AutoJourney;
};

export type CreateAutomationProgramsInput = {
  companyId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/messaging/automation/Contract.proto */
export type CreateAutomationProgramsPayload = {
  __typename?: 'CreateAutomationProgramsPayload';
  edges: Array<AutomationProgramEdge>;
  pageInfo: PageInfo;
};

export type CreateBlockCompanyInfoInput = {
  blockCompanyInfo: InputMaybe<Array<BlockCompanyInfoInput>>;
  companyId: Scalars['ID']['input'];
};

export type CreateBlockCompanyInfoPayload = CreateBlockCompanyInfoSuccess | DefaultErrorFailure;

/** Generated from com/attentivemobile/contracts/personalization/service/aijourneys/onboarding/companyinfo/BlockCompanyInfo.proto */
export type CreateBlockCompanyInfoSuccess = {
  __typename?: 'CreateBlockCompanyInfoSuccess';
  blockCompanyInfo: Array<BlockCompanyInfo>;
  success: Scalars['Boolean']['output'];
};

export type CreateCampaignMessageGroupExperimentInput = {
  campaignMessageGroupExperimentSubtype: InputMaybe<CampaignMessageGroupExperimentSubtype>;
  campaignMessageGroupExperimentType: CampaignMessageGroupExperimentType;
  /** @deprecated deprecated */
  numberOfVariants: InputMaybe<Scalars['Int']['input']>;
};

export type CreateCompanyConfigInput = {
  attributeName: Scalars['String']['input'];
  companyId: Scalars['ID']['input'];
  config: Scalars['String']['input'];
};

export type CreateCompanyConfigPayload = CreateCompanyConfigSuccess | DefaultErrorFailure;

/** Generated from com/attentivemobile/contracts/messaging/automation/aijourneys/configs/Contract.proto */
export type CreateCompanyConfigSuccess = {
  __typename?: 'CreateCompanyConfigSuccess';
  id: Scalars['String']['output'];
};

export type CreateCompanyInput = {
  /** the company's unique attentive domain e.g. attentive.attn.tv */
  attentiveDomain: Scalars['String']['input'];
  companyEmail: InputMaybe<Scalars['String']['input']>;
  companyType: CompanyType;
  country: Scalars['String']['input'];
  name: Scalars['String']['input'];
  salesforceAccountId: Scalars['String']['input'];
  userId: InputMaybe<Scalars['String']['input']>;
};

export type CreateCompanyLinkInput = {
  companyLink: CompanyLinkInput;
};

/** Generated from com/attentivemobile/contracts/company-service/companylink/CompanyLink.proto */
export type CreateCompanyLinkPayload = {
  __typename?: 'CreateCompanyLinkPayload';
  companyLink: CompanyLink;
};

/** Generated from com/attentivemobile/contracts/company-service/Contract.proto */
export type CreateCompanyPayload = {
  __typename?: 'CreateCompanyPayload';
  company: Maybe<Company>;
};

export type CreateCompanySettingsInput = {
  companyId: Scalars['ID']['input'];
  companySettings: CompanySettingsInput;
};

/** Generated from com/attentivemobile/contracts/company-service/companysetting/CompanySetting.proto */
export type CreateCompanySettingsPayload = {
  __typename?: 'CreateCompanySettingsPayload';
  successful: Scalars['Boolean']['output'];
};

export type CreateComposeCampaignInput = {
  campaignMessageGroupName: Scalars['String']['input'];
  companyId: Scalars['ID']['input'];
  experimentMetadata: InputMaybe<CreateCampaignMessageGroupExperimentInput>;
  messageChannel: ComposeMessageChannel;
  name: Scalars['String']['input'];
  type: ComposeCampaignType;
  userId: Scalars['ID']['input'];
};

export type CreateCouponAllocatorUploadInput = {
  companyId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/solutions/couponallocator/CouponAllocator.proto */
export type CreateCouponAllocatorUploadPayload = {
  __typename?: 'CreateCouponAllocatorUploadPayload';
  filename: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
};

export type CreateCouponSetInput = {
  autoRefresh: Scalars['Boolean']['input'];
  codeTemplate: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['ID']['input'];
  couponUrlTemplate: InputMaybe<Scalars['String']['input']>;
  couponValueInput: InputMaybe<CouponValueInput>;
  description: InputMaybe<Scalars['String']['input']>;
  distributionTtlInSeconds: InputMaybe<Scalars['Long']['input']>;
  distributionTtlUnit: InputMaybe<DistributionUnit>;
  externalId: InputMaybe<Scalars['Long']['input']>;
  name: Scalars['String']['input'];
  staticCode: InputMaybe<Scalars['String']['input']>;
  status: CouponSetStatus;
  type: CouponSetType;
};

/** Generated from com/attentivemobile/contracts/coupon/CouponContract.proto */
export type CreateCouponSetPayload = {
  __typename?: 'CreateCouponSetPayload';
  created: CouponSet;
};

export type CreateCreativeExperimentGroupInput = {
  creativeIds: InputMaybe<Array<Scalars['ID']['input']>>;
  percent: Scalars['String']['input'];
};

export type CreateCreativeExperimentInput = {
  companyId: Scalars['ID']['input'];
  description: InputMaybe<Scalars['String']['input']>;
  experimentGroups: InputMaybe<Array<CreateCreativeExperimentGroupInput>>;
  name: Scalars['String']['input'];
  status: CreativeExperimentStatus;
};

/** Generated from com/attentivemobile/contracts/creative-service/experiment/CreativeExperimentService.proto */
export type CreateCreativeExperimentPayload = {
  __typename?: 'CreateCreativeExperimentPayload';
  experiment: CreativeExperiment;
};

export type CreateCreativeScheduleAndMembersInput = {
  members: InputMaybe<Array<CreateOrUpdateCreativeScheduleMemberInput>>;
  schedule: CreateCreativeScheduleInput;
};

export type CreateCreativeScheduleInput = {
  companyId: Scalars['ID']['input'];
  completed: InputMaybe<Scalars['DateTime']['input']>;
  description: InputMaybe<Scalars['String']['input']>;
  endTime: InputMaybe<Scalars['DateTime']['input']>;
  name: Scalars['String']['input'];
  scheduledEvent: Scalars['Boolean']['input'];
  startTime: Scalars['DateTime']['input'];
  timezone: InputMaybe<Scalars['String']['input']>;
  userId: InputMaybe<Scalars['String']['input']>;
};

export type CreateCreativeScheduleWorkflowInput = {
  companyId: Scalars['ID']['input'];
  endTime: InputMaybe<Scalars['DateTime']['input']>;
  /** @deprecated deprecated */
  externalScheduleId: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  scheduleMembers: InputMaybe<Array<CreativeScheduleMemberDetails>>;
  startTime: Scalars['DateTime']['input'];
  timezone: InputMaybe<Scalars['String']['input']>;
};

export type CreateCreativeScheduleWorkflowPayload = CreateCreativeScheduleWorkflowSuccess | DefaultErrorFailure;

/** Generated from com/attentivemobile/contracts/creative/workflow/service/workflows/CreativeSchedulesWorkflowService.proto */
export type CreateCreativeScheduleWorkflowSuccess = {
  __typename?: 'CreateCreativeScheduleWorkflowSuccess';
  schedule: CreativeWorkflowSchedule;
};

export type CreateCustomCompanyKeywordInput = {
  attributeName: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['ID']['input'];
  enabled: Scalars['Boolean']['input'];
  forceSend: Scalars['Boolean']['input'];
  keyword: Scalars['String']['input'];
  keywordOrdinal: InputMaybe<Scalars['Int']['input']>;
  mediaUrl: InputMaybe<Scalars['String']['input']>;
  messageText: Scalars['String']['input'];
  shortenLink: Scalars['Boolean']['input'];
  subscriptionType: Scalars['String']['input'];
};

export type CreateCustomCompanyKeywordPayload = CreateCustomCompanyKeywordSuccess | DefaultErrorFailure;

/** Generated from com/attentivemobile/contracts/keywords/KeywordService.proto */
export type CreateCustomCompanyKeywordSuccess = {
  __typename?: 'CreateCustomCompanyKeywordSuccess';
  keyword: CustomCompanyKeyword;
};

/** Request message for creating a custom event. */
export type CreateCustomEventInput = {
  companyId: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier representing this specific event. A UUID is recommended. */
  externalEventId: InputMaybe<Scalars['String']['input']>;
  /** Timestamp of when the action occurred in [ISO 8601 format](https://xml2rfc.tools.ietf.org/public/rfc/html/rfc3339.html#date.and.time.format.examples). If the timestamp is older than 12 hours, it will not trigger any relevant Journeys. If no timestamp is provided, it is assumed the event occurred when the endpoint is called. */
  occurredAt: InputMaybe<Scalars['DateTime']['input']>;
  /** Any metadata associated with the event. Object keys are expected to be strings, and cannot contain any of the following special characters: single quote ', double quote ", curly braces { }, square brackets [ ], backslash \, comma ,. Object values can be any type. Note that both object keys and object values are case sensitive. For example, "deliverydate" and "DeliveryDate" would be considered different event properties. For URLs that are provided as a value, Attentive will automatically shorten the link with your brand's link shortener. For example, https://mysite.com/. Note that Attentive can only shorten URLs that are 1,024 characters or fewer. */
  properties: Scalars['JSON']['input'];
  /** The type of event. This name is case sensitive. "Order shipped" and "Order Shipped" would be considered different event types. */
  type: Scalars['String']['input'];
  /** User associated with the action. Note that this is a visitor to the site and does not need to be actively subscribed to Attentive. */
  user: EventUserInput;
};

/**
 * Generated from com/attentivemobile/contracts/user/profile/Contract.proto
 * Response message to a request for creating a custom event.
 */
export type CreateCustomEventPayload = {
  __typename?: 'CreateCustomEventPayload';
  /** A unique identifier representing this specific event. */
  externalEventId: Scalars['String']['output'];
  /** Timestamp of when the action occurred. */
  occurredAt: Scalars['DateTime']['output'];
  /** Any metadata associated with the event. */
  properties: Scalars['JSON']['output'];
  /** The type of event. */
  type: Scalars['String']['output'];
  /** User associated with the action. */
  user: EventUser;
};

export type CreateCustomJourneyInput = {
  customJourneyTriggerRequest: CustomJourneyTriggerInput;
  eligibleSubscriptionTypes: InputMaybe<Array<SubscriptionType>>;
};

/** Generated from com/attentivemobile/contracts/journeys/api/Contract.proto */
export type CreateCustomJourneyPayload = {
  __typename?: 'CreateCustomJourneyPayload';
  journey: Journey;
};

export type CreateCustomKeywordInput = {
  companyId: Scalars['ID']['input'];
  isTransactional: InputMaybe<Scalars['Boolean']['input']>;
  keyword: Scalars['String']['input'];
  response: Scalars['String']['input'];
  subscriberTag: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/tactical/companycustomkeywords/CompanyCustomKeywordService.proto */
export type CreateCustomKeywordPayload = {
  __typename?: 'CreateCustomKeywordPayload';
  customKeyword: CustomKeyword;
};

export type CreateCustomPropertyDefinitionInput = {
  companyId: Scalars['ID']['input'];
  newFields: PostCustomPropertyDefinitionInput;
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type CreateCustomPropertyDefinitionPayload = {
  __typename?: 'CreateCustomPropertyDefinitionPayload';
  customPropertyDefinition: CustomPropertyDefinition;
};

export type CreateCustomReportInput = {
  companyId: Scalars['ID']['input'];
  deliveryFrequencies: InputMaybe<Array<ReportDeliveryFrequency>>;
  filters: InputMaybe<Array<ReportFilterInput>>;
  groupings: InputMaybe<Array<ReportGroupingInput>>;
  metricIds: InputMaybe<Array<Scalars['String']['input']>>;
  name: Scalars['String']['input'];
  recipients: InputMaybe<Array<Scalars['ID']['input']>>;
  reportClassId: InputMaybe<Scalars['ID']['input']>;
  selectedChart: InputMaybe<ReportChart>;
  selectedMetricId: InputMaybe<Scalars['String']['input']>;
  sftpEnabled: InputMaybe<Scalars['Boolean']['input']>;
  /** @deprecated deprecated */
  summaryMetricIds: InputMaybe<Array<Scalars['String']['input']>>;
  summaryMetrics: InputMaybe<SummaryMetricsInput>;
  tableSortOrders: InputMaybe<Array<ReportMetricsOrderingInput>>;
};

/** Generated from com/attentivemobile/contracts/reports/api/Contract.proto */
export type CreateCustomReportPayload = {
  __typename?: 'CreateCustomReportPayload';
  report: Report;
};

export type CreateDefaultWelcomeJourneyInput = {
  companyId: Scalars['ID']['input'];
  templateId: InputMaybe<Scalars['ID']['input']>;
};

/** Generated from com/attentivemobile/contracts/journeys/api/Contract.proto */
export type CreateDefaultWelcomeJourneyPayload = {
  __typename?: 'CreateDefaultWelcomeJourneyPayload';
  journey: Journey;
};

export type CreateDismissibleModalInput = {
  description: InputMaybe<Scalars['String']['input']>;
  endTime: Scalars['DateTime']['input'];
  featureFlags: InputMaybe<Array<Scalars['String']['input']>>;
  imageUrl: InputMaybe<Scalars['String']['input']>;
  modalId: Scalars['String']['input'];
  primaryButtonAction: ButtonAction;
  primaryButtonText: Scalars['String']['input'];
  primaryButtonUrl: InputMaybe<Scalars['String']['input']>;
  secondaryButtonAction: InputMaybe<ButtonAction>;
  secondaryButtonText: InputMaybe<Scalars['String']['input']>;
  secondaryButtonUrl: InputMaybe<Scalars['String']['input']>;
  startTime: Scalars['DateTime']['input'];
  title: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type CreateDismissibleModalPayload = {
  __typename?: 'CreateDismissibleModalPayload';
  modal: DismissibleModal;
};

export type CreateDomainSuppressionInput = {
  companyId: InputMaybe<Scalars['Long']['input']>;
  description: InputMaybe<Scalars['String']['input']>;
  domain: Scalars['String']['input'];
  reason: Scalars['String']['input'];
};

export type CreateDomainSuppressionPayload = CreateDomainSuppressionSuccess | DefaultErrorFailure;

/** Generated from com/attentivemobile/contracts/emails/management/Contract.proto */
export type CreateDomainSuppressionSuccess = {
  __typename?: 'CreateDomainSuppressionSuccess';
  suppression: DomainSuppression;
};

export type CreateEdsJobInput = {
  companyId: Scalars['ID']['input'];
  description: InputMaybe<Scalars['String']['input']>;
  ingestionFormat: InputMaybe<EdsJobIngestionFormat>;
  name: Scalars['String']['input'];
  sync: InputMaybe<EdsJobSync>;
  syncMethod: InputMaybe<EdsJobSyncMethod>;
};

export type CreateEdsJobMappingInput = {
  authDetails: Scalars['String']['input'];
  companyId: Scalars['ID']['input'];
  responseDetails: Scalars['String']['input'];
  sourceDetails: Scalars['String']['input'];
  sourceUuid: Scalars['String']['input'];
  targetDetails: Scalars['String']['input'];
  targetUuid: Scalars['String']['input'];
  taskId: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type CreateEdsJobMappingPayload = {
  __typename?: 'CreateEdsJobMappingPayload';
  errorMsg: Maybe<Scalars['String']['output']>;
  taskUuid: Maybe<Scalars['String']['output']>;
};

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type CreateEdsJobPayload = {
  __typename?: 'CreateEdsJobPayload';
  taskUuid: Maybe<Scalars['String']['output']>;
};

export type CreateEmailIPAddressInput = {
  companyId: Scalars['Long']['input'];
};

export type CreateEmailIPAddressPayload = CreateEmailIPAddressSuccess | DefaultErrorFailure;

/** Generated from com/attentivemobile/contracts/emails/management/Contract.proto */
export type CreateEmailIPAddressSuccess = {
  __typename?: 'CreateEmailIPAddressSuccess';
  ipAddress: EmailIPAddress;
};

export type CreateEmailIPPoolInput = {
  companyId: InputMaybe<Scalars['Long']['input']>;
  name: Scalars['String']['input'];
  shared: InputMaybe<Scalars['Boolean']['input']>;
  type: IPPoolType;
};

export type CreateEmailIPPoolPayload = CreateEmailIPPoolSuccess | DefaultErrorFailure;

/** Generated from com/attentivemobile/contracts/emails/management/Contract.proto */
export type CreateEmailIPPoolSuccess = {
  __typename?: 'CreateEmailIPPoolSuccess';
  ipPool: EmailIPPool;
};

export type CreateEntityTagAssignmentInput = {
  campaignMessageGroupId: InputMaybe<Scalars['ID']['input']>;
  tagId: Scalars['ID']['input'];
  taggableEntityType: TaggableEntityType;
};

export type CreateEventReplayInput = {
  companyId: Scalars['ID']['input'];
  description: Scalars['String']['input'];
  eventRangeEnd: Scalars['DateTime']['input'];
  eventRangeStart: Scalars['DateTime']['input'];
  eventTypes: InputMaybe<Array<EventReplayEventType>>;
  title: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/tactical/eventreplay/EventReplayService.proto */
export type CreateEventReplayPayload = {
  __typename?: 'CreateEventReplayPayload';
  eventReplay: EventReplay;
};

export type CreateEventReplayV2Input = {
  companyId: InputMaybe<Array<Scalars['ID']['input']>>;
  description: Scalars['String']['input'];
  eventRangeEnd: Scalars['DateTime']['input'];
  eventRangeStart: Scalars['DateTime']['input'];
  eventTypes: InputMaybe<Array<EventReplayEventType>>;
  title: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/tactical/eventreplay/EventReplayService.proto */
export type CreateEventReplayV2Payload = {
  __typename?: 'CreateEventReplayV2Payload';
  eventReplay: Array<EventReplay>;
  eventReplayFailures: Array<EventReplayFailures>;
};

export type CreateFeatureFlagInput = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/featureflag/service/Contract.proto */
export type CreateFeatureFlagPayload = {
  __typename?: 'CreateFeatureFlagPayload';
  featureFlag: FeatureFlag;
};

export type CreateFolderInput = {
  companyId: Scalars['ID']['input'];
  displayName: Scalars['String']['input'];
  parentId: InputMaybe<Scalars['ID']['input']>;
  proceedOnDuplicate: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateFolderPayload = CreateFolderSuccess | DefaultErrorFailure;

/** Generated from com/attentivemobile/contracts/asset/library/service/Contract.proto */
export type CreateFolderSuccess = {
  __typename?: 'CreateFolderSuccess';
  company: Maybe<Company>;
  folder: Folder;
};

export type CreateGapStoresCouponInput = {
  brandCode: Scalars['String']['input'];
  companyId: Scalars['ID']['input'];
  dollarValue: Scalars['Int']['input'];
  earn12DigitCode: Scalars['String']['input'];
  earn16DigitCode: Scalars['String']['input'];
  earnEnd: Scalars['String']['input'];
  earnStart: Scalars['String']['input'];
  redeem12DigitCode: InputMaybe<Scalars['String']['input']>;
  redeem16DigitCode: InputMaybe<Scalars['String']['input']>;
  redeemEnd: InputMaybe<Scalars['String']['input']>;
  redeemStart: InputMaybe<Scalars['String']['input']>;
};

/** Generated from com/attentivemobile/contracts/mobilewalletservice/CouponsService.proto */
export type CreateGapStoresCouponPayload = {
  __typename?: 'CreateGapStoresCouponPayload';
  isSuccess: Scalars['Boolean']['output'];
};

export type CreateImageInput = {
  companyId: Scalars['ID']['input'];
  displayName: Scalars['String']['input'];
  fileType: Scalars['String']['input'];
  heightPixels: Scalars['Int']['input'];
  parentId: InputMaybe<Scalars['ID']['input']>;
  proceedOnDuplicate: InputMaybe<Scalars['Boolean']['input']>;
  sizeBytes: Scalars['Long']['input'];
  storageUrl: Scalars['String']['input'];
  widthPixels: Scalars['Int']['input'];
};

export type CreateImagePayload = CreateImageSuccess | DefaultErrorFailure;

/** Generated from com/attentivemobile/contracts/asset/library/service/Contract.proto */
export type CreateImageSuccess = {
  __typename?: 'CreateImageSuccess';
  company: Maybe<Company>;
  image: Image;
};

export type CreateJourneyFromTemplateInput = {
  companyId: Scalars['ID']['input'];
  couponPoolId: InputMaybe<Scalars['ID']['input']>;
  isCouponPoolSelected: InputMaybe<Scalars['Boolean']['input']>;
  isVisible: InputMaybe<Scalars['Boolean']['input']>;
  templateId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/journeys/api/Contract.proto */
export type CreateJourneyFromTemplatePayload = {
  __typename?: 'CreateJourneyFromTemplatePayload';
  journey: Journey;
};

export type CreateJourneyInput = {
  companyId: Scalars['ID']['input'];
  description: InputMaybe<Scalars['String']['input']>;
  isDefault: InputMaybe<Scalars['Boolean']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  triggerId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/journeys/api/Contract.proto */
export type CreateJourneyPayload = {
  __typename?: 'CreateJourneyPayload';
  journey: Journey;
};

export type CreateJourneyRevisionInput = {
  companyId: Scalars['ID']['input'];
  journeyId: Scalars['ID']['input'];
  revisionIdToClone: InputMaybe<Scalars['ID']['input']>;
};

/** Generated from com/attentivemobile/contracts/journeys/api/Contract.proto */
export type CreateJourneyRevisionPayload = {
  __typename?: 'CreateJourneyRevisionPayload';
  journey: Journey;
};

export type CreateJourneyScheduleInput = {
  companyId: Scalars['ID']['input'];
  evictPreviousRevisionSubscribersActivateStart: InputMaybe<Scalars['Boolean']['input']>;
  journeyId: Scalars['ID']['input'];
  journeyRevisionIdActivateStart: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  startTime: InputMaybe<Scalars['DateTime']['input']>;
};

/** Generated from com/attentivemobile/contracts/journeys/api/Contract.proto */
export type CreateJourneySchedulePayload = {
  __typename?: 'CreateJourneySchedulePayload';
  journeySchedule: JourneySchedule;
};

export type CreateJourneyTriggerInput = {
  description: Scalars['String']['input'];
  eligibleSubscriptionTypes: InputMaybe<Array<SubscriptionType>>;
  eventOrigin: JourneyEventOrigin;
  maxJourneyLimit: InputMaybe<Scalars['Long']['input']>;
  name: Scalars['String']['input'];
  source: TriggerSourceInput;
  type: JourneyTriggerType;
};

/** Generated from com/attentivemobile/contracts/journeys/api/JourneyTrigger.proto */
export type CreateJourneyTriggerPayload = {
  __typename?: 'CreateJourneyTriggerPayload';
  journeyTrigger: JourneyTrigger;
};

export type CreateMessageInput = {
  attachmentsToAdd: InputMaybe<Array<NewAttachmentRequest>>;
  channel: ComposeMessageChannel;
  companyId: Scalars['ID']['input'];
  emailProperties: InputMaybe<EmailPropertiesRequest>;
  ignoreFatigue: InputMaybe<Scalars['Boolean']['input']>;
  initialUtmParameters: InputMaybe<UtmParametersInput>;
  mediaUrls: InputMaybe<Array<UrlInput>>;
  messageTags: InputMaybe<Array<ComposeMessageTagInput>>;
  name: Scalars['String']['input'];
  prebuiltMessageId: InputMaybe<Scalars['String']['input']>;
  rbmProperties: InputMaybe<RbmPropertiesInput>;
  status: ComposeMessageStatus;
  subtype: Scalars['String']['input'];
  textMessageProperties: InputMaybe<TextMessagePropertiesRequest>;
  type: ComposeMessageType;
};

export type CreateMessageTemplateInput = {
  channel: Channel;
  companyId: Scalars['Long']['input'];
  templateMetadata: ComposeMessageTemplateMetaDataInput;
  templateName: Scalars['String']['input'];
  templateType: MessagingTemplateType;
};

/** Generated from com/attentivemobile/contracts/compose/api/Contract.proto */
export type CreateMessageTemplatePayload = {
  __typename?: 'CreateMessageTemplatePayload';
  messageTemplate: ComposeMessageTemplate;
};

export type CreateMetadataEntry = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type CreateMobileWalletCardAndSendInput = {
  companyId: Scalars['ID']['input'];
  createMetadata: InputMaybe<Array<CreateMetadataEntry>>;
  mediaUrl: InputMaybe<Scalars['String']['input']>;
  messageText: Scalars['String']['input'];
  mobileWalletCard: MobileWalletCardInput;
  phone: Scalars['String']['input'];
};

export type CreateMobileWalletCardInput = {
  companyId: Scalars['ID']['input'];
  mobileWalletCard: MobileWalletCardInput;
  subscriberExternalId: Scalars['String']['input'];
};

export type CreateNBAConfigInput = {
  nextBestActionConfiguration: NextBestActionConfigurationInput;
  notificationId: InputMaybe<Scalars['String']['input']>;
  notificationName: InputMaybe<Scalars['String']['input']>;
  notificationSlug: InputMaybe<Scalars['String']['input']>;
};

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type CreateNBAConfigPayload = {
  __typename?: 'CreateNBAConfigPayload';
  nextBestActionConfiguration: NextBestActionConfiguration;
};

export type CreateNotificationBannerInput = {
  callToAction: Scalars['String']['input'];
  description: Scalars['String']['input'];
  eventDate: Scalars['String']['input'];
  startTime: Scalars['DateTime']['input'];
  stopTime: Scalars['DateTime']['input'];
  title: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type CreateNotificationBannerPayload = {
  __typename?: 'CreateNotificationBannerPayload';
  banner: NotificationBanner;
};

export type CreateNotificationEmailTemplateInput = {
  emailTemplate: NotificationEmailTemplateInput;
  notificationId: InputMaybe<Scalars['String']['input']>;
  notificationName: InputMaybe<Scalars['String']['input']>;
  notificationSlug: InputMaybe<Scalars['String']['input']>;
  templatingSyntaxType: InputMaybe<TemplatingSyntaxType>;
};

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type CreateNotificationEmailTemplatePayload = {
  __typename?: 'CreateNotificationEmailTemplatePayload';
  emailTemplate: NotificationEmailTemplate;
};

export type CreateOrUpdateCreativeScheduleMemberInput = {
  creativeId: Scalars['ID']['input'];
  /** @deprecated deprecated */
  status: InputMaybe<Scalars['String']['input']>;
  statusV2: CreativeScheduleMemberStatus;
};

/** Generated from com/attentivemobile/contracts/creative-service/schedule/CreativeScheduleService.proto */
export type CreateOrUpdateCreativeSchedulePayload = {
  __typename?: 'CreateOrUpdateCreativeSchedulePayload';
  scheduleOrError: Maybe<CreateOrUpdateCreativeSchedulePayloadScheduleOrError>;
};

export type CreateOrUpdateCreativeSchedulePayloadScheduleOrError = CreativeSchedule | CreativeScheduleMutationError;

export type CreateOrUpdateUserContentSettingInput = {
  companyId: InputMaybe<Scalars['ID']['input']>;
  objectClass: ContentClass;
  objectId: InputMaybe<Scalars['String']['input']>;
  preferenceType: PreferenceType;
  preferenceValue: Scalars['String']['input'];
  userId: InputMaybe<Scalars['ID']['input']>;
};

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type CreateOrUpdateUserContentSettingPayload = {
  __typename?: 'CreateOrUpdateUserContentSettingPayload';
  preferenceId: Scalars['String']['output'];
};

export type CreateOutboundSftpUserInput = {
  companyId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/solutions/sftp/SftpUser.proto */
export type CreateOutboundSftpUserPayload = {
  __typename?: 'CreateOutboundSftpUserPayload';
  publicSshKey: Scalars['String']['output'];
};

export type CreatePaymentMessagesInput = {
  companyId: Scalars['ID']['input'];
  messageId: Scalars['ID']['input'];
  paymentMessageType: TextToBuyMessageType;
  paymentsOfferId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/payments/v2/Contract.proto */
export type CreatePaymentMessagesPayload = {
  __typename?: 'CreatePaymentMessagesPayload';
  status: CreatePaymentsMessageStatus;
};

export enum CreatePaymentsMessageStatus {
  CreatePaymentMessageStatusFail = 'CREATE_PAYMENT_MESSAGE_STATUS_FAIL',
  CreatePaymentMessageStatusSuccess = 'CREATE_PAYMENT_MESSAGE_STATUS_SUCCESS',
  CreatePaymentMessageStatusUnknown = 'CREATE_PAYMENT_MESSAGE_STATUS_UNKNOWN'
}

export type CreatePaymentsOfferInput = {
  companyId: Scalars['ID']['input'];
  discount: PaymentsOfferDiscountInput;
  expiresAt: InputMaybe<Scalars['DateTime']['input']>;
  keyword: Scalars['String']['input'];
  /** @deprecated deprecated */
  messageId: InputMaybe<Scalars['ID']['input']>;
  paymentOfferStatus: InputMaybe<PaymentOfferStatus>;
  paymentOfferType: PaymentOfferType;
  productName: InputMaybe<Scalars['String']['input']>;
  shopifyProductId: InputMaybe<Scalars['String']['input']>;
  shopifyProductPrice: InputMaybe<MoneyInput>;
  shopifyProductVariantId: InputMaybe<Scalars['String']['input']>;
};

/** Generated from com/attentivemobile/contracts/payments/v2/Contract.proto */
export type CreatePaymentsOfferPayload = {
  __typename?: 'CreatePaymentsOfferPayload';
  offer: Maybe<PaymentsOffer>;
  status: CreatePaymentsOfferStatus;
};

export enum CreatePaymentsOfferStatus {
  CreateOfferStatusFailCompanyNotConfigured = 'CREATE_OFFER_STATUS_FAIL_COMPANY_NOT_CONFIGURED',
  CreateOfferStatusFailKeywordInUse = 'CREATE_OFFER_STATUS_FAIL_KEYWORD_IN_USE',
  CreateOfferStatusFailKeywordNotAcceptable = 'CREATE_OFFER_STATUS_FAIL_KEYWORD_NOT_ACCEPTABLE',
  CreateOfferStatusFailReservedKeyword = 'CREATE_OFFER_STATUS_FAIL_RESERVED_KEYWORD',
  CreateOfferStatusSuccess = 'CREATE_OFFER_STATUS_SUCCESS',
  CreateOfferStatusUnknown = 'CREATE_OFFER_STATUS_UNKNOWN'
}

export type CreatePollInput = {
  companyId: Scalars['ID']['input'];
  description: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/survey/poll/Contract.proto */
export type CreatePollPayload = {
  __typename?: 'CreatePollPayload';
  error: Maybe<Scalars['String']['output']>;
  poll: Maybe<Poll>;
};

export type CreatePrebuiltContentInput = {
  name: Scalars['String']['input'];
  orchestrationContent: InputMaybe<Array<OrchestrationContentInput>>;
  orchestrationType: OrchestrationType;
  prebuiltProgramId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/messaging/automation/prebuilt/Contract.proto */
export type CreatePrebuiltContentPayload = {
  __typename?: 'CreatePrebuiltContentPayload';
  prebuiltContent: PrebuiltContent;
};

export type CreatePrebuiltProgramInput = {
  description: Scalars['String']['input'];
  scheduleType: ScheduleType;
  thumbnailUrl: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/messaging/automation/prebuilt/Contract.proto */
export type CreatePrebuiltProgramPayload = {
  __typename?: 'CreatePrebuiltProgramPayload';
  prebuiltProgram: PrebuiltProgram;
};

export type CreateProductSegmentInput = {
  companyId: Scalars['ID']['input'];
  filters: InputMaybe<Array<ProductSegmentFilterInput>>;
  name: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto */
export type CreateProductSegmentPayload = {
  __typename?: 'CreateProductSegmentPayload';
  productSegment: Maybe<ProductSegment>;
};

export type CreateProviderMigrationInput = {
  carrier: InputMaybe<Carrier>;
  endDate: InputMaybe<Scalars['DateTime']['input']>;
  newProvider: Provider;
  oldProvider: Provider;
  phoneNumber: Scalars['String']['input'];
  startDate: InputMaybe<Scalars['DateTime']['input']>;
  status: InputMaybe<ProviderMigrationStatus>;
};

/** Generated from com/attentivemobile/contracts/providermigration/Contract.proto */
export type CreateProviderMigrationPayload = {
  __typename?: 'CreateProviderMigrationPayload';
  migrations: Array<ProviderMigration>;
};

export type CreateProviderMigrationsInput = {
  carrierDateMapping: InputMaybe<Array<CarrierDateMapping>>;
  newProvider: InputMaybe<Provider>;
  oldProvider: InputMaybe<Provider>;
  phoneNumbers: InputMaybe<Array<Scalars['String']['input']>>;
  status: InputMaybe<ProviderMigrationStatus>;
};

export type CreateProviderMigrationsPayload = CreateProviderMigrationsSuccess | DefaultErrorFailure;

/** Generated from com/attentivemobile/contracts/providermigration/Contract.proto */
export type CreateProviderMigrationsSuccess = {
  __typename?: 'CreateProviderMigrationsSuccess';
  migrations: Array<ProviderMigration>;
};

export type CreateQueryParameterJobInput = {
  companyId: Scalars['String']['input'];
  payload: InputMaybe<CreateQueryParameterJobInputPayload>;
};

export type CreateQueryParameterJobInputPayload =
  { applyIdentityAiParameterPayload: ApplyIdentityAIParameterPayload; };

export type CreateQueryParameterJobPayload = CreateQueryParameterJobSuccess | DefaultErrorFailure;

/** Generated from com/attentivemobile/contracts/composition/Contract.proto */
export type CreateQueryParameterJobSuccess = {
  __typename?: 'CreateQueryParameterJobSuccess';
  jobId: Scalars['String']['output'];
};

export type CreateRegionInput = {
  accountConsolidation: InputMaybe<Scalars['Boolean']['input']>;
  region: RegionInput;
};

/** Generated from com/attentivemobile/contracts/company-service/region/Region.proto */
export type CreateRegionPayload = {
  __typename?: 'CreateRegionPayload';
  error: Maybe<Scalars['String']['output']>;
  region: Region;
};

export type CreateSegmentEnhancementsWorkflowInput = {
  audienceId: InputMaybe<Scalars['ID']['input']>;
  baseAudience: InputMaybe<BaseAudience>;
  callbackId: InputMaybe<CreateSegmentEnhancementsWorkflowInputCallbackId>;
  companyId: Scalars['ID']['input'];
  filter: InputMaybe<SegmentEnhancementsFilter>;
  messageChannel: Channel;
  productFilter: InputMaybe<AudienceAiProductFilterInput>;
};

export type CreateSegmentEnhancementsWorkflowInputCallbackId =
  { campaignMessageGroupId: Scalars['ID']['input']; };

export type CreateSegmentEnhancementsWorkflowPayload = CreateSegmentEnhancementsWorkflowSuccess | DefaultErrorFailure;

/** Generated from com/attentivemobile/contracts/segment/recommendation/service/Contract.proto */
export type CreateSegmentEnhancementsWorkflowSuccess = {
  __typename?: 'CreateSegmentEnhancementsWorkflowSuccess';
  isSuccess: Scalars['Boolean']['output'];
  workflowId: Scalars['String']['output'];
};

export type CreateSegmentFromSearchDefinitionInput = {
  companyId: Scalars['ID']['input'];
  description: InputMaybe<Scalars['String']['input']>;
  legacySegmentId: InputMaybe<Scalars['ID']['input']>;
  metadata: InputMaybe<Array<CreateSegmentMetadataInput>>;
  name: Scalars['String']['input'];
  searchDefinition: AudienceSearchDefinitionInput;
};

export type CreateSegmentFromSearchDefinitionPayload = CreateSegmentFromSearchDefinitionSuccess | DefaultErrorFailure;

/** Generated from com/attentivemobile/contracts/segmentation/service/segment/contract.proto */
export type CreateSegmentFromSearchDefinitionSuccess = {
  __typename?: 'CreateSegmentFromSearchDefinitionSuccess';
  segment: Maybe<Segment>;
};

export type CreateSegmentFromSearchTemplateInput = {
  companyId: Scalars['ID']['input'];
  metadata: InputMaybe<Array<CreateSegmentMetadataInput>>;
  segmentDescription: InputMaybe<Scalars['String']['input']>;
  segmentName: Scalars['String']['input'];
  template: SegmentTemplateInput;
};

export type CreateSegmentFromSearchTemplatePayload = CreateSegmentFromSearchTemplateSuccess | DefaultErrorFailure;

/** Generated from com/attentivemobile/contracts/segmentation/service/segment/contract.proto */
export type CreateSegmentFromSearchTemplateSuccess = {
  __typename?: 'CreateSegmentFromSearchTemplateSuccess';
  segment: Maybe<Segment>;
};

export type CreateSegmentFromTemplateInput = {
  companyId: Scalars['ID']['input'];
  metadata: InputMaybe<Array<SegmentMetadataInput>>;
  segmentDescription: InputMaybe<Scalars['String']['input']>;
  segmentName: Scalars['String']['input'];
  template: SegmentTemplateInput;
};

export type CreateSegmentFromTemplatePayload = CreateSegmentFromTemplateSuccess | DefaultErrorFailure;

/** Generated from com/attentivemobile/contracts/segmentation/service/Contract.proto */
export type CreateSegmentFromTemplateSuccess = {
  __typename?: 'CreateSegmentFromTemplateSuccess';
  segment: Maybe<Segment>;
};

export type CreateSegmentMetadataInput = {
  tag: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type CreateSftpUserInput = {
  companyId: Scalars['ID']['input'];
  email: InputMaybe<Scalars['String']['input']>;
};

/** Generated from com/attentivemobile/contracts/solutions/sftp/SftpUser.proto */
export type CreateSftpUserPayload = {
  __typename?: 'CreateSftpUserPayload';
  email: Maybe<Scalars['String']['output']>;
  password: Maybe<Scalars['String']['output']>;
};

export type CreateShopifyAutoApplyFormatsInput = {
  companyId: Scalars['ID']['input'];
  discountLinkFormat: DiscountLinkFormat;
};

export type CreateShopifyAutoApplyFormatsPayload = CreateShopifyAutoApplyFormatsSuccess | DefaultErrorFailure;

/** Generated from com/attentivemobile/contracts/personalization/service/aijourneys/onboarding/autoapply/ShopifyAutoApplyFormatsContract.proto */
export type CreateShopifyAutoApplyFormatsSuccess = {
  __typename?: 'CreateShopifyAutoApplyFormatsSuccess';
  discountLinkFormat: DiscountLinkFormat;
  success: Scalars['Boolean']['output'];
};

export type CreateShortLinkInput = {
  companyId: Scalars['ID']['input'];
  isOpenInBrowser: InputMaybe<Scalars['Boolean']['input']>;
  messageId: Scalars['ID']['input'];
  redirectTargetUrl: Scalars['String']['input'];
  videoLinkProperties: InputMaybe<VideoLinkPropertiesInput>;
};

export type CreateShortLinkPayload = CreateShortLinkSuccess | DefaultErrorFailure;

/** Generated from com/attentivemobile/contracts/composition/Contract.proto */
export type CreateShortLinkSuccess = {
  __typename?: 'CreateShortLinkSuccess';
  shortLink: ShortLink;
};

/** Generated from com/attentivemobile/contracts/journeys/api/RevisionStep.proto */
export type CreateSupportTicketStep = {
  __typename?: 'CreateSupportTicketStep';
  childrenSteps: Array<AdderStep>;
  stepParameters: CreateSupportTicketStepParameters;
};

/** Generated from com/attentivemobile/contracts/journeys/api/RevisionStepParameters.proto */
export type CreateSupportTicketStepParameters = {
  __typename?: 'CreateSupportTicketStepParameters';
  customerServiceGroupId: Scalars['String']['output'];
  vendor: CreateSupportTicketVendor;
};

/** Generated from com/attentivemobile/contracts/journeys/api/RevisionStepCommon.proto */
export type CreateSupportTicketVendor = {
  __typename?: 'CreateSupportTicketVendor';
  vendorId: Scalars['String']['output'];
  vendorName: Scalars['String']['output'];
};

export type CreateSyncInput = {
  companyId: Scalars['ID']['input'];
  connectionSettings: InputMaybe<CreateSyncInputConnectionSettings>;
  description: Scalars['String']['input'];
  fetchOptions: FetchOptionsInput;
  fileSettings: FileSettingsInput;
  fileType: EdsJobIngestionFormat;
  name: Scalars['String']['input'];
  schedule: SyncScheduleInput;
  syncMethod: EdsJobSyncMethod;
  syncType: EdsJobSync;
  targetConfig: Scalars['JSON']['input'];
};

export type CreateSyncInputConnectionSettings =
  { attentiveS3Settings: AttentiveS3SettingsInput; sftpSettings?: never; snowflakeSecureDataShareSettings?: never; }
  |  { attentiveS3Settings?: never; sftpSettings: SftpSettingsInput; snowflakeSecureDataShareSettings?: never; }
  |  { attentiveS3Settings?: never; sftpSettings?: never; snowflakeSecureDataShareSettings: SnowflakeSecureDataSharingInput; };

export type CreateSyncPayload = CreateSyncSuccess | DefaultErrorFailure;

export type CreateSyncPayloadResult = Sync | SyncError;

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type CreateSyncSuccess = {
  __typename?: 'CreateSyncSuccess';
  result: Maybe<CreateSyncPayloadResult>;
  success: Scalars['Boolean']['output'];
};

export type CreateTagInput = {
  companyId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type CreateUpdatePollOptionInput = {
  keyword: Scalars['String']['input'];
  pollId: Scalars['ID']['input'];
  response: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/survey/poll/Contract.proto */
export type CreateUpdatePollOptionPayload = {
  __typename?: 'CreateUpdatePollOptionPayload';
  error: Maybe<Scalars['String']['output']>;
  pollOption: Maybe<PollOption>;
};

export type CreateVendorCompanyCardInput = {
  companyId: Scalars['ID']['input'];
  couponDisplayRegex: Scalars['String']['input'];
  failRedirectUrl: Scalars['String']['input'];
  requireExpiryOnIssue: Scalars['Boolean']['input'];
  vendorCampaignId: Scalars['String']['input'];
  vendorOfferId: Scalars['String']['input'];
};

export type CreateVendorCompanyCardPayload = CreateVendorCompanyCardSuccess | DefaultErrorFailure;

/** Generated from com/attentivemobile/contracts/mobilewalletservice/Contract.proto */
export type CreateVendorCompanyCardSuccess = {
  __typename?: 'CreateVendorCompanyCardSuccess';
  vendorCompanyCard: VendorCompanyCard;
};

export type CreateWebTemplateInput = {
  notificationId: InputMaybe<Scalars['String']['input']>;
  notificationName: InputMaybe<Scalars['String']['input']>;
  notificationSlug: InputMaybe<Scalars['String']['input']>;
  webTemplate: WebTemplateInput;
};

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type CreateWebTemplatePayload = {
  __typename?: 'CreateWebTemplatePayload';
  webTemplate: WebTemplate;
};

/** Create webhook request input. */
export type CreateWebhookInput = {
  companyId: InputMaybe<Scalars['String']['input']>;
  /** The list of events this webhook is subscribed to. */
  events: InputMaybe<Array<Scalars['String']['input']>>;
  /** The destination url of the webhook. */
  url: Scalars['String']['input'];
};

/**
 * Generated from com/attentivemobile/contracts/externaltokens/Webhooks.proto
 * Response to createWebhook mutation.
 */
export type CreateWebhookPayload = {
  __typename?: 'CreateWebhookPayload';
  /** Webhook details. */
  webhook: Webhook;
};

export type CreateWorkloadConfigInput = {
  workloadConfig: WorkloadConfigInput;
};

export type CreateWorkloadConfigPayload = CreateWorkloadConfigSuccess | DefaultErrorFailure;

/** Generated from com/attentivemobile/contracts/workload/management/service/configuration/Contract.proto */
export type CreateWorkloadConfigSuccess = {
  __typename?: 'CreateWorkloadConfigSuccess';
  workloadName: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/creative-service/creative/Creative.proto */
export type Creative = Node & {
  __typename?: 'Creative';
  archived: Maybe<Scalars['DateTime']['output']>;
  company: Maybe<Company>;
  /** @deprecated deprecated */
  companyIdOld: Scalars['String']['output'];
  composeMessage: Maybe<ComposeMessage>;
  countryCode: Maybe<Scalars['String']['output']>;
  couponSet: Maybe<CouponSet>;
  created: Scalars['DateTime']['output'];
  creativeCompositeStats: Maybe<CreativeCompositeStatistics>;
  creativeContent: Maybe<CreativeContent>;
  creativeExperimentGroups: Maybe<CreativeExperimentGroupConnection>;
  /** This relationship is deprecated. */
  creativeFormat: Maybe<CreativeFormat>;
  /** This relationship is deprecated. */
  creativePreset: Maybe<CreativePreset>;
  /** Provides a collection of RuleAssociations to this Creative. */
  creativeRuleAssociations: Maybe<CreativeRuleAssociationConnection>;
  creativeScheduleWorkflows: Maybe<CreativeWorkflowScheduleConnection>;
  /**
   * Use raw_status instead.
   * @deprecated deprecated
   */
  creativeStatus: Scalars['String']['output'];
  creativeTemplate: Maybe<CreativeTemplate>;
  /** The creative name shown in client-ui. */
  displayName: Scalars['String']['output'];
  emailWelcomeJourney: Maybe<Journey>;
  externalName: Scalars['String']['output'];
  giveawayWinners: Maybe<GetGiveawayWinnersPayload>;
  id: Scalars['ID']['output'];
  /** @deprecated deprecated */
  idOld: Maybe<Scalars['String']['output']>;
  internalId: Scalars['String']['output'];
  landingPageUrl: Maybe<Scalars['String']['output']>;
  language: Scalars['String']['output'];
  lastEditedBy: Maybe<Account>;
  /** The status of the Creative regardless of ongoing schedules.Usually statusLabel is what you want. */
  rawStatus: CreativeStatus;
  rules: Maybe<Scalars['JSON']['output']>;
  smsBody: Maybe<Scalars['String']['output']>;
  smsWelcomeJourney: Maybe<Journey>;
  /**
   * Use raw_status instead.
   * @deprecated deprecated
   */
  status: Scalars['String']['output'];
  /**
   * Use raw_status instead.
   * @deprecated deprecated
   */
  statusEnum: CreativeStatus;
  /**
   * The status of the Creative as shown in the UI.
   * This status takes into consideration schedules and metrics.
   * Shows a Creative as 'DRAFT' if the Creative (factoring in any ongoing schedules) resolves to INACTIVE and has no recorded metrics.
   */
  statusLabel: CreativeStatusLabel;
  subscriptionType: Maybe<CreativeSubscriptionType>;
  /** @deprecated deprecated */
  subtype: Scalars['String']['output'];
  subtypeEnum: CreativeSubtype;
  /** The creative config. */
  templateConfig: Maybe<Scalars['JSON']['output']>;
  ttjCode: Maybe<Scalars['String']['output']>;
  /** @deprecated deprecated */
  type: Scalars['String']['output'];
  typeEnum: CreativeType;
  updated: Scalars['DateTime']['output'];
  visibility: CreativeVisibility;
};


/** Generated from com/attentivemobile/contracts/creative-service/creative/Creative.proto */
export type CreativecreativeExperimentGroupsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


/** Generated from com/attentivemobile/contracts/creative-service/creative/Creative.proto */
export type CreativecreativeRuleAssociationsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  creativeId: InputMaybe<Scalars['ID']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  ruleId: InputMaybe<Scalars['ID']['input']>;
};


/** Generated from com/attentivemobile/contracts/creative-service/creative/Creative.proto */
export type CreativecreativeScheduleWorkflowsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<GetCreativeSchedulesRequestFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  sort: InputMaybe<Array<GetCreativeSchedulesRequestSort>>;
};


/** Generated from com/attentivemobile/contracts/creative-service/creative/Creative.proto */
export type CreativegiveawayWinnersArgs = {
  creativeIds: InputMaybe<Array<Scalars['ID']['input']>>;
  endDate: Scalars['String']['input'];
  limit: Scalars['Int']['input'];
  startDate: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/creative-service/assets/CreativeAssets.proto */
export type CreativeAssetColor = {
  __typename?: 'CreativeAssetColor';
  id: Scalars['String']['output'];
  type: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/creative-service/assets/CreativeAssets.proto */
export type CreativeAssetFont = {
  __typename?: 'CreativeAssetFont';
  deleted: Scalars['DateTime']['output'];
  family: Scalars['String']['output'];
  id: Scalars['String']['output'];
  source: Scalars['String']['output'];
  style: Scalars['String']['output'];
  type: Scalars['String']['output'];
  url: Scalars['String']['output'];
  weight: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/creative-service/assets/CreativeAssets.proto */
export type CreativeAssetImage = {
  __typename?: 'CreativeAssetImage';
  deleted: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  type: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/creative-service/assets/CreativeAssets.proto */
export type CreativeAssets = {
  __typename?: 'CreativeAssets';
  colors: Array<CreativeAssetColor>;
  fonts: Array<CreativeAssetFont>;
  images: Array<CreativeAssetImage>;
};

/** Generated from com/attentivemobile/contracts/creative-service/creative/Creative.proto */
export type CreativeCompositeStatistics = {
  __typename?: 'CreativeCompositeStatistics';
  aggregateStats: CreativeStatistics;
  creativePageStats: Array<CreativePageStatistic>;
};

/** Generated from com/attentivemobile/contracts/creative-service/creative/CreativeGraphService.proto */
export type CreativeConnection = {
  __typename?: 'CreativeConnection';
  edges: Array<CreativeEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Long']['output'];
};

/** Generated from com/attentivemobile/contracts/creative-service/creative/CreativeContentService.proto */
export type CreativeContent = Node & {
  __typename?: 'CreativeContent';
  company: Maybe<Company>;
  content: Scalars['JSON']['output'];
  created: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  updated: Scalars['DateTime']['output'];
};

export type CreativeCustomPropertyInput = {
  name: Scalars['String']['input'];
  options: InputMaybe<Array<Scalars['String']['input']>>;
  type: CreativeCustomPropertyType;
};

export enum CreativeCustomPropertyType {
  CreativeCustomPropertyTypeBool = 'CREATIVE_CUSTOM_PROPERTY_TYPE_BOOL',
  CreativeCustomPropertyTypeCheckbox = 'CREATIVE_CUSTOM_PROPERTY_TYPE_CHECKBOX',
  CreativeCustomPropertyTypeDate = 'CREATIVE_CUSTOM_PROPERTY_TYPE_DATE',
  CreativeCustomPropertyTypeInput = 'CREATIVE_CUSTOM_PROPERTY_TYPE_INPUT',
  CreativeCustomPropertyTypeRadio = 'CREATIVE_CUSTOM_PROPERTY_TYPE_RADIO',
  CreativeCustomPropertyTypeSelect = 'CREATIVE_CUSTOM_PROPERTY_TYPE_SELECT',
  CreativeCustomPropertyTypeUnknown = 'CREATIVE_CUSTOM_PROPERTY_TYPE_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/creative-service/creative/CreativeGraphService.proto */
export type CreativeEdge = {
  __typename?: 'CreativeEdge';
  cursor: Scalars['String']['output'];
  node: Creative;
};

/** Generated from com/attentivemobile/contracts/creative-service/experiment/CreativeExperiment.proto */
export type CreativeExperiment = Node & {
  __typename?: 'CreativeExperiment';
  company: Maybe<Company>;
  created: Scalars['DateTime']['output'];
  creativeExperimentGroups: Maybe<CreativeExperimentGroupConnection>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  internalId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  status: CreativeExperimentStatus;
  updated: Scalars['DateTime']['output'];
  visibility: CreativeExperimentVisibility;
};


/** Generated from com/attentivemobile/contracts/creative-service/experiment/CreativeExperiment.proto */
export type CreativeExperimentcreativeExperimentGroupsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** Generated from com/attentivemobile/contracts/creative-service/experiment/CreativeExperimentService.proto */
export type CreativeExperimentConnection = {
  __typename?: 'CreativeExperimentConnection';
  edges: Array<CreativeExperimentEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Long']['output'];
};

/** Generated from com/attentivemobile/contracts/creative-service/experiment/CreativeExperimentService.proto */
export type CreativeExperimentEdge = {
  __typename?: 'CreativeExperimentEdge';
  cursor: Scalars['String']['output'];
  node: CreativeExperiment;
};

/** Generated from com/attentivemobile/contracts/creative-service/experiment/CreativeExperiment.proto */
export type CreativeExperimentGroup = {
  __typename?: 'CreativeExperimentGroup';
  creativeExperiment: Maybe<CreativeExperiment>;
  creatives: Maybe<CreativeConnection>;
  id: Scalars['String']['output'];
  percent: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/creative-service/experiment/CreativeExperimentService.proto */
export type CreativeExperimentGroupConnection = {
  __typename?: 'CreativeExperimentGroupConnection';
  edges: Array<CreativeExperimentGroupEdge>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/creative-service/experiment/CreativeExperimentService.proto */
export type CreativeExperimentGroupEdge = {
  __typename?: 'CreativeExperimentGroupEdge';
  cursor: Scalars['String']['output'];
  node: CreativeExperimentGroup;
};

export enum CreativeExperimentStatus {
  CreativeExperimentStatusActive = 'CREATIVE_EXPERIMENT_STATUS_ACTIVE',
  CreativeExperimentStatusInactive = 'CREATIVE_EXPERIMENT_STATUS_INACTIVE',
  CreativeExperimentStatusUnknown = 'CREATIVE_EXPERIMENT_STATUS_UNKNOWN'
}

export enum CreativeExperimentVisibility {
  CreativeExperimentVisibilityEveryone = 'CREATIVE_EXPERIMENT_VISIBILITY_EVERYONE',
  CreativeExperimentVisibilitySuperuser = 'CREATIVE_EXPERIMENT_VISIBILITY_SUPERUSER',
  CreativeExperimentVisibilityUnknown = 'CREATIVE_EXPERIMENT_VISIBILITY_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/creative-service/format/CreativeFormat.proto */
export type CreativeFormat = Node & {
  __typename?: 'CreativeFormat';
  creativePresets: Maybe<CreativePresetConnection>;
  deleted: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  internalId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  rendererAppUrl: Scalars['String']['output'];
  subtype: Scalars['String']['output'];
  type: Scalars['String']['output'];
};


/** Generated from com/attentivemobile/contracts/creative-service/format/CreativeFormat.proto */
export type CreativeFormatcreativePresetsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['ID']['input'];
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  readyToLaunch: InputMaybe<Scalars['Boolean']['input']>;
};

/** Generated from com/attentivemobile/contracts/creative-service/format/CreativeFormatService.proto */
export type CreativeFormatConnection = {
  __typename?: 'CreativeFormatConnection';
  edges: Array<CreativeFormatEdge>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/creative-service/format/CreativeFormatService.proto */
export type CreativeFormatEdge = {
  __typename?: 'CreativeFormatEdge';
  cursor: Scalars['String']['output'];
  node: CreativeFormat;
};

/** Generated from com/attentivemobile/contracts/creative-service/creative/CreativeGraphService.proto */
export type CreativeMutationError = {
  __typename?: 'CreativeMutationError';
  message: Maybe<Scalars['String']['output']>;
  type: CommonCreativeErrors;
};

/** Generated from com/attentivemobile/contracts/creative-service/creative/Creative.proto */
export type CreativePageStatistic = {
  __typename?: 'CreativePageStatistic';
  pageType: Scalars['String']['output'];
  stats: CreativeStatistics;
};

/** Generated from com/attentivemobile/contracts/creative-service/preset/CreativePreset.proto */
export type CreativePreset = Node & {
  __typename?: 'CreativePreset';
  creativeEditorConfig: JsonString;
  creativeFormat: Maybe<CreativeFormat>;
  defaultCreativeConfig: JsonString;
  defaultCreativeRules: JsonString;
  deleted: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  internalId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  readyToLaunch: Scalars['Boolean']['output'];
  subtype: Scalars['String']['output'];
  type: Scalars['String']['output'];
  visibility: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/creative-service/preset/CreativePresetService.proto */
export type CreativePresetConnection = {
  __typename?: 'CreativePresetConnection';
  edges: Array<CreativePresetEdge>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/creative-service/preset/CreativePresetService.proto */
export type CreativePresetEdge = {
  __typename?: 'CreativePresetEdge';
  cursor: Scalars['String']['output'];
  node: CreativePreset;
};

/** Generated from com/attentivemobile/contracts/creative-service/rules/CreativeRule.proto */
export type CreativeRule = Node & {
  __typename?: 'CreativeRule';
  company: Maybe<Company>;
  created: Scalars['DateTime']['output'];
  /** Provides a collection of RuleAssociations to this Creative. */
  creativeRuleAssociations: Maybe<CreativeRuleAssociationConnection>;
  definitionSource: CreativeRuleDefinitionSource;
  deleted: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  rule: Scalars['JSON']['output'];
  updated: Scalars['DateTime']['output'];
  userId: Scalars['String']['output'];
};


/** Generated from com/attentivemobile/contracts/creative-service/rules/CreativeRule.proto */
export type CreativeRulecreativeRuleAssociationsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  creativeId: InputMaybe<Scalars['ID']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  ruleId: InputMaybe<Scalars['ID']['input']>;
};

/** Generated from com/attentivemobile/contracts/creative-service/rules/CreativeRuleAssociation.proto */
export type CreativeRuleAssociation = Node & {
  __typename?: 'CreativeRuleAssociation';
  created: Scalars['DateTime']['output'];
  creative: Maybe<Creative>;
  deleted: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  rule: Maybe<CreativeRule>;
  updated: Scalars['DateTime']['output'];
  userId: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/creative-service/rules/CreativeRuleAssociationService.proto */
export type CreativeRuleAssociationConnection = {
  __typename?: 'CreativeRuleAssociationConnection';
  edges: Array<CreativeRuleAssociationEdge>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/creative-service/rules/CreativeRuleAssociationService.proto */
export type CreativeRuleAssociationEdge = {
  __typename?: 'CreativeRuleAssociationEdge';
  cursor: Scalars['String']['output'];
  node: CreativeRuleAssociation;
};

/** Generated from com/attentivemobile/contracts/creative-service/rules/CreativeRuleService.proto */
export type CreativeRuleConnection = {
  __typename?: 'CreativeRuleConnection';
  edges: Array<CreativeRuleEdge>;
  pageInfo: PageInfo;
};

export enum CreativeRuleDefinitionSource {
  CreativeRuleDefinitionSourceAttentive = 'CREATIVE_RULE_DEFINITION_SOURCE_ATTENTIVE',
  CreativeRuleDefinitionSourceCreativeLocal = 'CREATIVE_RULE_DEFINITION_SOURCE_CREATIVE_LOCAL',
  CreativeRuleDefinitionSourceCreativeShared = 'CREATIVE_RULE_DEFINITION_SOURCE_CREATIVE_SHARED',
  CreativeRuleDefinitionSourceUnknown = 'CREATIVE_RULE_DEFINITION_SOURCE_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/creative-service/rules/CreativeRuleService.proto */
export type CreativeRuleEdge = {
  __typename?: 'CreativeRuleEdge';
  cursor: Scalars['String']['output'];
  node: CreativeRule;
};

/** Generated from com/attentivemobile/contracts/creative-service/schedule/CreativeSchedule.proto */
export type CreativeSchedule = Node & {
  __typename?: 'CreativeSchedule';
  company: Maybe<Company>;
  completed: Scalars['DateTime']['output'];
  created: Scalars['DateTime']['output'];
  deleted: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  endTime: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** @deprecated deprecated */
  internalId: Scalars['String']['output'];
  lastEditedBy: Maybe<Account>;
  name: Scalars['String']['output'];
  scheduledEvent: Scalars['Boolean']['output'];
  startTime: Scalars['DateTime']['output'];
  /** @deprecated deprecated */
  status: Scalars['String']['output'];
  statusV2: CreativeScheduleStatus;
  timezone: Scalars['String']['output'];
  type: CreativeScheduleType;
  updated: Scalars['DateTime']['output'];
};

/** Generated from com/attentivemobile/contracts/creative-service/schedule/CreativeScheduleService.proto */
export type CreativeScheduleConnection = {
  __typename?: 'CreativeScheduleConnection';
  edges: Array<CreativeScheduleEdge>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/creative-service/schedule/CreativeScheduleService.proto */
export type CreativeScheduleEdge = {
  __typename?: 'CreativeScheduleEdge';
  cursor: Scalars['String']['output'];
  node: CreativeSchedule;
};

/** Generated from com/attentivemobile/contracts/creative-service/schedule/CreativeScheduleService.proto */
export type CreativeScheduleMember = Node & {
  __typename?: 'CreativeScheduleMember';
  creative: Maybe<Creative>;
  /** @deprecated deprecated */
  creativeSchedule: Maybe<CreativeSchedule>;
  id: Scalars['ID']['output'];
  /** @deprecated deprecated */
  internalId: Scalars['String']['output'];
  /** @deprecated deprecated */
  status: Scalars['String']['output'];
  statusV2: CreativeScheduleMemberStatus;
};

export type CreativeScheduleMemberDetails = {
  creativeSubtype: CreativeSubtype;
  id: Scalars['ID']['input'];
  targetStatus: TargetCreativeStatus;
};

export enum CreativeScheduleMemberStatus {
  CreativeScheduleMemberStatusActive = 'CREATIVE_SCHEDULE_MEMBER_STATUS_ACTIVE',
  CreativeScheduleMemberStatusInactive = 'CREATIVE_SCHEDULE_MEMBER_STATUS_INACTIVE'
}

/** Generated from com/attentivemobile/contracts/creative-service/schedule/CreativeScheduleService.proto */
export type CreativeScheduleMutationError = {
  __typename?: 'CreativeScheduleMutationError';
  message: Maybe<Scalars['String']['output']>;
  type: CommonCreativeScheduleMutationErrors;
};

export enum CreativeScheduleStatus {
  CreativeScheduleStatusCompleted = 'CREATIVE_SCHEDULE_STATUS_COMPLETED',
  CreativeScheduleStatusNextUp = 'CREATIVE_SCHEDULE_STATUS_NEXT_UP',
  CreativeScheduleStatusRunning = 'CREATIVE_SCHEDULE_STATUS_RUNNING'
}

export enum CreativeScheduleType {
  CreativeScheduleTypeActivate = 'CREATIVE_SCHEDULE_TYPE_ACTIVATE',
  CreativeScheduleTypeDeactivate = 'CREATIVE_SCHEDULE_TYPE_DEACTIVATE',
  CreativeScheduleTypeSwap = 'CREATIVE_SCHEDULE_TYPE_SWAP',
  CreativeScheduleTypeUnknown = 'CREATIVE_SCHEDULE_TYPE_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/creative/workflow/service/workflows/CreativeSchedulesWorkflowService.proto */
export type CreativeScheduleWorkflowMember = Node & {
  __typename?: 'CreativeScheduleWorkflowMember';
  company: Maybe<Company>;
  creative: Maybe<Creative>;
  /** @deprecated deprecated */
  externalSchedule: Maybe<CreativeSchedule>;
  id: Scalars['ID']['output'];
  schedule: Maybe<CreativeWorkflowSchedule>;
  targetStatus: TargetCreativeStatus;
};

/** Generated from com/attentivemobile/contracts/creative/workflow/service/workflows/CreativeSchedulesWorkflowService.proto */
export type CreativeSchedulesMemberEdge = {
  __typename?: 'CreativeSchedulesMemberEdge';
  cursor: Scalars['String']['output'];
  node: CreativeScheduleWorkflowMember;
};

/** Generated from com/attentivemobile/contracts/creative-service/creative/Creative.proto */
export type CreativeStatistics = {
  __typename?: 'CreativeStatistics';
  clickThroughRate: Maybe<Scalars['Float']['output']>;
  clicks: Maybe<Scalars['Long']['output']>;
  emailConversionRate: Maybe<Scalars['Float']['output']>;
  emailSubscriberCount: Maybe<Scalars['Long']['output']>;
  impressions: Maybe<Scalars['Long']['output']>;
  smsConversionRate: Maybe<Scalars['Float']['output']>;
  smsSubscriberCount: Maybe<Scalars['Long']['output']>;
};

export enum CreativeStatus {
  CreativeStatusActive = 'CREATIVE_STATUS_ACTIVE',
  CreativeStatusDraft = 'CREATIVE_STATUS_DRAFT',
  CreativeStatusInactive = 'CREATIVE_STATUS_INACTIVE',
  CreativeStatusUnknown = 'CREATIVE_STATUS_UNKNOWN'
}

export enum CreativeStatusLabel {
  CreativeStatusLabelActive = 'CREATIVE_STATUS_LABEL_ACTIVE',
  CreativeStatusLabelDraft = 'CREATIVE_STATUS_LABEL_DRAFT',
  CreativeStatusLabelInactive = 'CREATIVE_STATUS_LABEL_INACTIVE',
  CreativeStatusLabelUnknown = 'CREATIVE_STATUS_LABEL_UNKNOWN'
}

export enum CreativeSubscriptionType {
  CreativeSubscriptionTypeCheckoutAbandoned = 'CREATIVE_SUBSCRIPTION_TYPE_CHECKOUT_ABANDONED',
  CreativeSubscriptionTypeMarketing = 'CREATIVE_SUBSCRIPTION_TYPE_MARKETING',
  CreativeSubscriptionTypeTransactional = 'CREATIVE_SUBSCRIPTION_TYPE_TRANSACTIONAL',
  CreativeSubscriptionTypeUnknown = 'CREATIVE_SUBSCRIPTION_TYPE_UNKNOWN'
}

export enum CreativeSubtype {
  CreativeSubtypeAffiliateEndpoint = 'CREATIVE_SUBTYPE_AFFILIATE_ENDPOINT',
  CreativeSubtypeBubble = 'CREATIVE_SUBTYPE_BUBBLE',
  CreativeSubtypeBubbleFullScreen = 'CREATIVE_SUBTYPE_BUBBLE_FULL_SCREEN',
  CreativeSubtypeBubblePartial = 'CREATIVE_SUBTYPE_BUBBLE_PARTIAL',
  CreativeSubtypeCode = 'CREATIVE_SUBTYPE_CODE',
  CreativeSubtypeConversational = 'CREATIVE_SUBTYPE_CONVERSATIONAL',
  CreativeSubtypeCouponDisplayScreen = 'CREATIVE_SUBTYPE_COUPON_DISPLAY_SCREEN',
  CreativeSubtypeDynamic = 'CREATIVE_SUBTYPE_DYNAMIC',
  CreativeSubtypeEmailBubbleFullScreen = 'CREATIVE_SUBTYPE_EMAIL_BUBBLE_FULL_SCREEN',
  CreativeSubtypeEmailBubblePartial = 'CREATIVE_SUBTYPE_EMAIL_BUBBLE_PARTIAL',
  CreativeSubtypeEmailFullScreen = 'CREATIVE_SUBTYPE_EMAIL_FULL_SCREEN',
  CreativeSubtypeEmailLightbox = 'CREATIVE_SUBTYPE_EMAIL_LIGHTBOX',
  CreativeSubtypeEmailPartial = 'CREATIVE_SUBTYPE_EMAIL_PARTIAL',
  CreativeSubtypeEmailVendorUserProfileSync = 'CREATIVE_SUBTYPE_EMAIL_VENDOR_USER_PROFILE_SYNC',
  CreativeSubtypeEmailWizard = 'CREATIVE_SUBTYPE_EMAIL_WIZARD',
  CreativeSubtypeFullScreen = 'CREATIVE_SUBTYPE_FULL_SCREEN',
  CreativeSubtypeLandingPage = 'CREATIVE_SUBTYPE_LANDING_PAGE',
  CreativeSubtypeLightbox = 'CREATIVE_SUBTYPE_LIGHTBOX',
  CreativeSubtypeLightboxBubble = 'CREATIVE_SUBTYPE_LIGHTBOX_BUBBLE',
  CreativeSubtypeLoyalty = 'CREATIVE_SUBTYPE_LOYALTY',
  CreativeSubtypeManualEntry = 'CREATIVE_SUBTYPE_MANUAL_ENTRY',
  CreativeSubtypeNoShow = 'CREATIVE_SUBTYPE_NO_SHOW',
  CreativeSubtypeOfferDisplay = 'CREATIVE_SUBTYPE_OFFER_DISPLAY',
  CreativeSubtypePartial = 'CREATIVE_SUBTYPE_PARTIAL',
  CreativeSubtypePrivacy = 'CREATIVE_SUBTYPE_PRIVACY',
  CreativeSubtypeProductDisplay = 'CREATIVE_SUBTYPE_PRODUCT_DISPLAY',
  CreativeSubtypeRechargeCheckout = 'CREATIVE_SUBTYPE_RECHARGE_CHECKOUT',
  CreativeSubtypeRechargeCheckoutTransactional = 'CREATIVE_SUBTYPE_RECHARGE_CHECKOUT_TRANSACTIONAL',
  CreativeSubtypeShopifyCheckout = 'CREATIVE_SUBTYPE_SHOPIFY_CHECKOUT',
  CreativeSubtypeShopifyCheckoutEmail = 'CREATIVE_SUBTYPE_SHOPIFY_CHECKOUT_EMAIL',
  CreativeSubtypeShopifyCheckoutTransactional = 'CREATIVE_SUBTYPE_SHOPIFY_CHECKOUT_TRANSACTIONAL',
  CreativeSubtypeTagAddToCart = 'CREATIVE_SUBTYPE_TAG_ADD_TO_CART',
  CreativeSubtypeTagPageView = 'CREATIVE_SUBTYPE_TAG_PAGE_VIEW',
  CreativeSubtypeTagProductView = 'CREATIVE_SUBTYPE_TAG_PRODUCT_VIEW',
  CreativeSubtypeTerms = 'CREATIVE_SUBTYPE_TERMS',
  CreativeSubtypeUnknown = 'CREATIVE_SUBTYPE_UNKNOWN',
  CreativeSubtypeUnsubscribe = 'CREATIVE_SUBTYPE_UNSUBSCRIBE',
  CreativeSubtypeUser = 'CREATIVE_SUBTYPE_USER'
}

/** Generated from com/attentivemobile/contracts/creative-service/template/CreativeTemplate.proto */
export type CreativeTemplate = Node & {
  __typename?: 'CreativeTemplate';
  archived: Maybe<Scalars['DateTime']['output']>;
  creativePresetId: Scalars['String']['output'];
  /** Creates the default display rules for the given template, country, and company. */
  defaultDisplayRules: Maybe<GetTemplateCreativeRuleDefaultsResponse>;
  defaults: Scalars['JSON']['output'];
  formConfig: Scalars['JSON']['output'];
  id: Scalars['ID']['output'];
  internalId: Scalars['String']['output'];
  location: Scalars['String']['output'];
  name: Scalars['String']['output'];
  ssrEnabled: Scalars['String']['output'];
  /** @deprecated deprecated */
  subtype: Scalars['String']['output'];
  subtypeEnum: CreativeSubtype;
  /** @deprecated deprecated */
  type: Scalars['String']['output'];
  typeEnum: CreativeType;
  visibility: Scalars['String']['output'];
};


/** Generated from com/attentivemobile/contracts/creative-service/template/CreativeTemplate.proto */
export type CreativeTemplatedefaultDisplayRulesArgs = {
  companyId: Scalars['ID']['input'];
  country: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/creative-service/template/TemplateService.proto */
export type CreativeTemplateConnection = {
  __typename?: 'CreativeTemplateConnection';
  edges: Array<CreativeTemplateEdge>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/creative-service/template/TemplateService.proto */
export type CreativeTemplateEdge = {
  __typename?: 'CreativeTemplateEdge';
  cursor: Scalars['String']['output'];
  node: CreativeTemplate;
};

export enum CreativeType {
  CreativeTypeAbandonmentAutoOptIn = 'CREATIVE_TYPE_ABANDONMENT_AUTO_OPT_IN',
  CreativeTypeDesktop = 'CREATIVE_TYPE_DESKTOP',
  CreativeTypeIntegration = 'CREATIVE_TYPE_INTEGRATION',
  CreativeTypeLandingPage = 'CREATIVE_TYPE_LANDING_PAGE',
  CreativeTypeLegal = 'CREATIVE_TYPE_LEGAL',
  CreativeTypeManualUpload = 'CREATIVE_TYPE_MANUAL_UPLOAD',
  CreativeTypeMobilePayment = 'CREATIVE_TYPE_MOBILE_PAYMENT',
  CreativeTypeOnSite = 'CREATIVE_TYPE_ON_SITE',
  CreativeTypeTextToJoin = 'CREATIVE_TYPE_TEXT_TO_JOIN',
  CreativeTypeUnknown = 'CREATIVE_TYPE_UNKNOWN'
}

export enum CreativeVisibility {
  CreativeVisibilityEveryone = 'CREATIVE_VISIBILITY_EVERYONE',
  CreativeVisibilitySuperuser = 'CREATIVE_VISIBILITY_SUPERUSER',
  CreativeVisibilityUnknown = 'CREATIVE_VISIBILITY_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/creative/workflow/service/workflows/CreativeSchedulesWorkflowService.proto */
export type CreativeWorkflowSchedule = Node & {
  __typename?: 'CreativeWorkflowSchedule';
  company: Maybe<Company>;
  created: Scalars['DateTime']['output'];
  creativeWorkflowScheduleMembers: Maybe<CreativeWorkflowScheduleMemberConnection>;
  deleted: Maybe<Scalars['DateTime']['output']>;
  endDate: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated deprecated */
  externalSchedule: Maybe<CreativeSchedule>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  startDate: Scalars['DateTime']['output'];
  status: CreativeScheduleStatus;
  timezone: Scalars['String']['output'];
  type: CreativeScheduleType;
  updated: Scalars['DateTime']['output'];
};


/** Generated from com/attentivemobile/contracts/creative/workflow/service/workflows/CreativeSchedulesWorkflowService.proto */
export type CreativeWorkflowSchedulecreativeWorkflowScheduleMembersArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** Generated from com/attentivemobile/contracts/creative/workflow/service/workflows/CreativeSchedulesWorkflowService.proto */
export type CreativeWorkflowScheduleConnection = {
  __typename?: 'CreativeWorkflowScheduleConnection';
  edges: Array<CreativeWorkflowScheduleEdge>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/creative/workflow/service/workflows/CreativeSchedulesWorkflowService.proto */
export type CreativeWorkflowScheduleEdge = {
  __typename?: 'CreativeWorkflowScheduleEdge';
  cursor: Scalars['String']['output'];
  node: CreativeWorkflowSchedule;
};

/** Generated from com/attentivemobile/contracts/creative/workflow/service/workflows/CreativeSchedulesWorkflowService.proto */
export type CreativeWorkflowScheduleMemberConnection = {
  __typename?: 'CreativeWorkflowScheduleMemberConnection';
  edges: Array<CreativeSchedulesMemberEdge>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/creative-service/experiment/CreativeExperimentService.proto */
export type CreativesAndExperimentsConnection = {
  __typename?: 'CreativesAndExperimentsConnection';
  edges: Array<CreativesAndExperimentsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Long']['output'];
};

/** Generated from com/attentivemobile/contracts/creative-service/experiment/CreativeExperimentService.proto */
export type CreativesAndExperimentsEdge = {
  __typename?: 'CreativesAndExperimentsEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<CreativesAndExperimentsEdgeNode>;
};

export type CreativesAndExperimentsEdgeNode = Creative | CreativeExperiment;

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type Cron = {
  __typename?: 'Cron';
  cronString: Scalars['String']['output'];
  timezone: Scalars['String']['output'];
};

export type CronInput = {
  cronString: Scalars['String']['input'];
  timezone: Scalars['String']['input'];
};

export type CronJobExecutionInput = {
  taskId: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/eventdestination/cron/service/CronJobExecution.proto */
export type CronJobExecutionPayload = {
  __typename?: 'CronJobExecutionPayload';
  isSuccess: Scalars['Boolean']['output'];
};

/** Generated from com/attentivemobile/contracts/solutions/sftp/EtlConfig.proto */
export type CsvFormatting = {
  __typename?: 'CsvFormatting';
  mapColName: Array<MapColNameEntry>;
  mapEventName: Array<MapEventNameEntry>;
  pipeDelimited: Maybe<Scalars['Boolean']['output']>;
  timestampFormat: Maybe<Scalars['String']['output']>;
  writeFooter: Maybe<Scalars['Boolean']['output']>;
  writeHeader: Maybe<Scalars['Boolean']['output']>;
};

export type CsvFormattingInput = {
  mapColNameInput: InputMaybe<Array<MapColNameInputEntry>>;
  mapEventNameInput: InputMaybe<Array<MapEventNameInputEntry>>;
  pipeDelimited: InputMaybe<Scalars['Boolean']['input']>;
  timestampFormat: InputMaybe<Scalars['String']['input']>;
  writeFooter: InputMaybe<Scalars['Boolean']['input']>;
  writeHeader: InputMaybe<Scalars['Boolean']['input']>;
};

export enum CsvSortByField {
  CsvSortByFieldUnknown = 'CSV_SORT_BY_FIELD_UNKNOWN'
}

/** The currency in ISO 4217 format. */
export enum CurrencyCode {
  Aed = 'AED',
  Afn = 'AFN',
  All = 'ALL',
  Amd = 'AMD',
  Ang = 'ANG',
  Aoa = 'AOA',
  Ars = 'ARS',
  Aud = 'AUD',
  Awg = 'AWG',
  Azn = 'AZN',
  Bam = 'BAM',
  Bbd = 'BBD',
  Bdt = 'BDT',
  Bgn = 'BGN',
  Bhd = 'BHD',
  Bif = 'BIF',
  Bmd = 'BMD',
  Bnd = 'BND',
  Bob = 'BOB',
  Bov = 'BOV',
  Brl = 'BRL',
  Bsd = 'BSD',
  Btn = 'BTN',
  Bwp = 'BWP',
  Byn = 'BYN',
  Bzd = 'BZD',
  Cad = 'CAD',
  Cdf = 'CDF',
  Che = 'CHE',
  Chf = 'CHF',
  Chw = 'CHW',
  Clf = 'CLF',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Cou = 'COU',
  Crc = 'CRC',
  Cuc = 'CUC',
  Cup = 'CUP',
  CurrencyUnknown = 'CURRENCY_UNKNOWN',
  Cve = 'CVE',
  Czk = 'CZK',
  Djf = 'DJF',
  Dkk = 'DKK',
  Dop = 'DOP',
  Dzd = 'DZD',
  Egp = 'EGP',
  Ern = 'ERN',
  Etb = 'ETB',
  Eur = 'EUR',
  Fjd = 'FJD',
  Fkp = 'FKP',
  Gbp = 'GBP',
  Gel = 'GEL',
  Ghs = 'GHS',
  Gip = 'GIP',
  Gmd = 'GMD',
  Gnf = 'GNF',
  Gtq = 'GTQ',
  Gyd = 'GYD',
  Hkd = 'HKD',
  Hnl = 'HNL',
  Hrk = 'HRK',
  Htg = 'HTG',
  Huf = 'HUF',
  Idr = 'IDR',
  Ils = 'ILS',
  Inr = 'INR',
  Iqd = 'IQD',
  Irr = 'IRR',
  Isk = 'ISK',
  Jmd = 'JMD',
  Jod = 'JOD',
  Jpy = 'JPY',
  Kes = 'KES',
  Kgs = 'KGS',
  Khr = 'KHR',
  Kmf = 'KMF',
  Kpw = 'KPW',
  Krw = 'KRW',
  Kwd = 'KWD',
  Kyd = 'KYD',
  Kzt = 'KZT',
  Lak = 'LAK',
  Lbp = 'LBP',
  Lkr = 'LKR',
  Lrd = 'LRD',
  Lsl = 'LSL',
  Lyd = 'LYD',
  Mad = 'MAD',
  Mdl = 'MDL',
  Mga = 'MGA',
  Mkd = 'MKD',
  Mmk = 'MMK',
  Mnt = 'MNT',
  Mop = 'MOP',
  Mru = 'MRU',
  Mur = 'MUR',
  Mvr = 'MVR',
  Mwk = 'MWK',
  Mxn = 'MXN',
  Mxv = 'MXV',
  Myr = 'MYR',
  Mzn = 'MZN',
  Nad = 'NAD',
  Ngn = 'NGN',
  Nio = 'NIO',
  Nok = 'NOK',
  Npr = 'NPR',
  Nzd = 'NZD',
  Omr = 'OMR',
  Pab = 'PAB',
  Pen = 'PEN',
  Pgk = 'PGK',
  Php = 'PHP',
  Pkr = 'PKR',
  Pln = 'PLN',
  Pyg = 'PYG',
  Qar = 'QAR',
  Ron = 'RON',
  Rsd = 'RSD',
  Rub = 'RUB',
  Rwf = 'RWF',
  Sar = 'SAR',
  Sbd = 'SBD',
  Scr = 'SCR',
  Sdg = 'SDG',
  Sek = 'SEK',
  Sgd = 'SGD',
  Shp = 'SHP',
  Sle = 'SLE',
  Sll = 'SLL',
  Sos = 'SOS',
  Srd = 'SRD',
  Ssp = 'SSP',
  Stn = 'STN',
  Svc = 'SVC',
  Syp = 'SYP',
  Szl = 'SZL',
  Thb = 'THB',
  Tjs = 'TJS',
  Tmt = 'TMT',
  Tnd = 'TND',
  Top = 'TOP',
  Try = 'TRY',
  Ttd = 'TTD',
  Twd = 'TWD',
  Tzs = 'TZS',
  Uah = 'UAH',
  Ugx = 'UGX',
  Usd = 'USD',
  Usn = 'USN',
  Uyi = 'UYI',
  Uyu = 'UYU',
  Uyw = 'UYW',
  Uzs = 'UZS',
  Ved = 'VED',
  Ves = 'VES',
  Vnd = 'VND',
  Vuv = 'VUV',
  Wst = 'WST',
  Xaf = 'XAF',
  Xag = 'XAG',
  Xau = 'XAU',
  Xba = 'XBA',
  Xbb = 'XBB',
  Xbc = 'XBC',
  Xbd = 'XBD',
  Xcd = 'XCD',
  Xdr = 'XDR',
  Xof = 'XOF',
  Xpd = 'XPD',
  Xpf = 'XPF',
  Xpt = 'XPT',
  Xsu = 'XSU',
  Xts = 'XTS',
  Xua = 'XUA',
  Xxx = 'XXX',
  Yer = 'YER',
  Zar = 'ZAR',
  Zmw = 'ZMW',
  Zwl = 'ZWL'
}

/** Generated from com/attentivemobile/contracts/keywords/KeywordRuleService.proto */
export type CustomAttributeRule = {
  __typename?: 'CustomAttributeRule';
  created: Scalars['DateTime']['output'];
  expression: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  updated: Scalars['DateTime']['output'];
};

/** Generated from com/attentivemobile/contracts/keywords/KeywordService.proto */
export type CustomCompanyKeyword = Node & {
  __typename?: 'CustomCompanyKeyword';
  created: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  keywordInfo: KeywordInfo;
  messageInfo: MessageInfo;
  subscriberInfo: SubscriberInfo;
  updated: Scalars['DateTime']['output'];
};

/** Generated from com/attentivemobile/contracts/keywords/KeywordService.proto */
export type CustomCompanyKeywordEdge = {
  __typename?: 'CustomCompanyKeywordEdge';
  cursor: Scalars['String']['output'];
  node: CustomCompanyKeyword;
};

/** Generated from com/attentivemobile/contracts/keywords/KeywordService.proto */
export type CustomCompanyKeywordsConnection = {
  __typename?: 'CustomCompanyKeywordsConnection';
  edges: Array<CustomCompanyKeywordEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type CustomEvent = {
  __typename?: 'CustomEvent';
  /** @deprecated deprecated */
  created: Scalars['DateTime']['output'];
  customEventType: Scalars['String']['output'];
  /** @deprecated deprecated */
  id: Scalars['String']['output'];
  properties: JsonString;
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type CustomEventDefinition = {
  __typename?: 'CustomEventDefinition';
  eventOrigin: EventOrigin;
  keys: Maybe<CustomEventKeyConnection>;
  type: Scalars['String']['output'];
};


/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type CustomEventDefinitionkeysArgs = {
  includeInactive: InputMaybe<Scalars['Boolean']['input']>;
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type CustomEventDefinitionConnection = {
  __typename?: 'CustomEventDefinitionConnection';
  edges: Array<CustomEventDefinitionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Long']['output'];
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type CustomEventDefinitionEdge = {
  __typename?: 'CustomEventDefinitionEdge';
  cursor: Scalars['String']['output'];
  node: CustomEventDefinition;
};

/**
 * Generated from com/attentivemobile/contracts/user/profile/Contract.proto
 * A property key of a Custom Event
 */
export type CustomEventKey = Node & {
  __typename?: 'CustomEventKey';
  active: Scalars['Boolean']['output'];
  companyId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  customEventType: Scalars['String']['output'];
  eventOrigin: Maybe<EventOrigin>;
  id: Scalars['ID']['output'];
  keyType: CustomEventKeyType;
  propertyKey: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type CustomEventKeyConnection = {
  __typename?: 'CustomEventKeyConnection';
  edges: Array<CustomEventKeyEdge>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type CustomEventKeyEdge = {
  __typename?: 'CustomEventKeyEdge';
  cursor: Scalars['String']['output'];
  node: CustomEventKey;
};

export enum CustomEventKeyType {
  KeyTypeArray = 'KEY_TYPE_ARRAY',
  KeyTypeBool = 'KEY_TYPE_BOOL',
  KeyTypeHyperlink = 'KEY_TYPE_HYPERLINK',
  KeyTypeImage = 'KEY_TYPE_IMAGE',
  KeyTypeLocalDate = 'KEY_TYPE_LOCAL_DATE',
  KeyTypeNumber = 'KEY_TYPE_NUMBER',
  KeyTypeObject = 'KEY_TYPE_OBJECT',
  KeyTypeString = 'KEY_TYPE_STRING',
  KeyTypeUnknown = 'KEY_TYPE_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type CustomEventsConnection = {
  __typename?: 'CustomEventsConnection';
  edges: Array<UserEventsEdge>;
  pageInfo: PageInfo;
};

export type CustomJourneyTriggerInput = {
  applicationId: InputMaybe<Scalars['ID']['input']>;
  companyId: Scalars['ID']['input'];
  customEventType: Scalars['String']['input'];
  displayName: Scalars['String']['input'];
  eventType: Scalars['String']['input'];
  modalDescription: Scalars['String']['input'];
  triggerId: InputMaybe<Scalars['ID']['input']>;
  type: JourneyTriggerType;
};

/** Generated from com/attentivemobile/contracts/tactical/companycustomkeywords/CompanyCustomKeywordService.proto */
export type CustomKeyword = Node & {
  __typename?: 'CustomKeyword';
  company: Maybe<Company>;
  id: Scalars['ID']['output'];
  isActive: Maybe<Scalars['Boolean']['output']>;
  isTransactional: Maybe<Scalars['Boolean']['output']>;
  keyword: Scalars['String']['output'];
  response: Scalars['String']['output'];
  subscriberTag: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/messaging/automation/aijourneys/onboarding/AIJourneyLinkConfiguration.proto */
export type CustomLinkConfig = {
  __typename?: 'CustomLinkConfig';
  url: Scalars['String']['output'];
};

export type CustomLinkConfigInput = {
  url: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/messaging/automation/autojourneys/AutoJourneysModel.proto */
export type CustomParamsEntry = {
  __typename?: 'CustomParamsEntry';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/product/attribute/service/gql/Contract.proto */
export type CustomProductProperty = {
  __typename?: 'CustomProductProperty';
  customProductPropertyType: CustomProductPropertyType;
  productPropertyName: Scalars['String']['output'];
};

export type CustomProductPropertyInput = {
  customProductPropertyType: CustomProductPropertyType;
  productPropertyName: Scalars['String']['input'];
};

export enum CustomProductPropertyType {
  CustomProductPropertyTypeProductVariant = 'CUSTOM_PRODUCT_PROPERTY_TYPE_PRODUCT_VARIANT',
  CustomProductPropertyTypeUnknown = 'CUSTOM_PRODUCT_PROPERTY_TYPE_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type CustomPropertyDefinition = Node & {
  __typename?: 'CustomPropertyDefinition';
  created: Scalars['DateTime']['output'];
  /** @deprecated deprecated */
  customPropertyId: CustomPropertyID;
  id: Scalars['ID']['output'];
  isDeleted: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  propertyType: CustomPropertyType;
  typeDefinition: Maybe<CustomPropertyDefinitionTypeDefinition>;
  updated: Scalars['DateTime']['output'];
  /** Segments that use this custom property. */
  usages: Array<Segment>;
};

export type CustomPropertyDefinitionInput = {
  name: Scalars['String']['input'];
  propertyType: CustomPropertyType;
};

export type CustomPropertyDefinitionTypeDefinition = EnumeratedPropertyDefinition;

/** Generated from com/attentivemobile/protobuf/common/targeting/UserProperty.proto */
export type CustomPropertyID = {
  __typename?: 'CustomPropertyID';
  id: Scalars['String']['output'];
};

export enum CustomPropertyType {
  TypeArrayObject = 'TYPE_ARRAY_OBJECT',
  TypeBool = 'TYPE_BOOL',
  TypeDate = 'TYPE_DATE',
  TypeDatetime = 'TYPE_DATETIME',
  TypeEnum = 'TYPE_ENUM',
  TypeLong = 'TYPE_LONG',
  TypeNumber = 'TYPE_NUMBER',
  TypeObject = 'TYPE_OBJECT',
  TypeString = 'TYPE_STRING',
  TypeTimestamp = 'TYPE_TIMESTAMP',
  TypeUnknown = 'TYPE_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/journeys/api/JourneyTrigger.proto */
export type CustomTriggerSource = {
  __typename?: 'CustomTriggerSource';
  company: Maybe<Company>;
  customEventType: Scalars['String']['output'];
};

export type CustomTriggerSourceInput = {
  companyId: Scalars['ID']['input'];
  customEventType: Scalars['String']['input'];
};

export type CustomUrlInputParamsEntry = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/concierge/service/SelfServe.proto */
export type CustomUrlParametersEntry = {
  __typename?: 'CustomUrlParametersEntry';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type CustomUrlParametersInputEntry = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/concierge/service/ConversationManager.proto */
export type CustomUrlParamsEntry = {
  __typename?: 'CustomUrlParamsEntry';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type CustomUrlParamsInputEntry = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/integrations/discount/Contract.proto */
export type CustomerReceives = {
  __typename?: 'CustomerReceives';
  applicableProduct: Maybe<Scalars['String']['output']>;
  applicableProductCategory: Maybe<Scalars['String']['output']>;
  applicableProductType: ApplicableProductType;
  applicableVariant: Maybe<Scalars['String']['output']>;
  appliesOnEachItem: Maybe<Scalars['Boolean']['output']>;
  discountAmount: Maybe<Money>;
  discountPercentage: Maybe<Scalars['Float']['output']>;
  discountedQuantity: Maybe<Scalars['Long']['output']>;
  freeShipping: Maybe<Scalars['Boolean']['output']>;
};

/** Generated from com/attentivemobile/contracts/integrations/discount/Contract.proto */
export type CustomerRequirements = {
  __typename?: 'CustomerRequirements';
  applicableProduct: Maybe<Scalars['String']['output']>;
  applicableProductCategory: Maybe<Scalars['String']['output']>;
  applicableProductType: ApplicableProductType;
  applicableVariant: Maybe<Scalars['String']['output']>;
  maximumShippingPrice: Maybe<Money>;
  purchaseAmount: Maybe<Money>;
  purchaseQuantity: Maybe<Scalars['Long']['output']>;
};

/** Generated from com/attentivemobile/contracts/integrations/v2/Contract.proto */
export type CviGenericMetadata = {
  __typename?: 'CviGenericMetadata';
  integrationMetadataMap: Array<IntegrationMetadataMapEntry>;
};

/** Generated from com/attentivemobile/contracts/integrations/v2/Contract.proto */
export type CvifGenericMetadata = {
  __typename?: 'CvifGenericMetadata';
  metadata: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/event/platform/registration/service/Transformation.proto */
export type DataSource = {
  __typename?: 'DataSource';
  endpoint: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/metricsapi/DatasetsContract.proto */
export type DatasetConnection = {
  __typename?: 'DatasetConnection';
  edges: Array<GroupedDatasetEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Long']['output'];
};

export type DatasetFilter = {
  column: Scalars['String']['input'];
  filter: InputMaybe<DatasetFilterFilter>;
  type: DatasetFilterType;
};

export type DatasetFilterFilter =
  { boolean: BooleanDatasetFilter; numeric?: never; string?: never; time?: never; }
  |  { boolean?: never; numeric: NumericDatasetFilter; string?: never; time?: never; }
  |  { boolean?: never; numeric?: never; string: StringDatasetFilter; time?: never; }
  |  { boolean?: never; numeric?: never; string?: never; time: TimeDatasetFilter; };

export enum DatasetFilterType {
  DatasetFilterTypeBoolean = 'DATASET_FILTER_TYPE_BOOLEAN',
  DatasetFilterTypeNumeric = 'DATASET_FILTER_TYPE_NUMERIC',
  DatasetFilterTypeString = 'DATASET_FILTER_TYPE_STRING',
  DatasetFilterTypeTime = 'DATASET_FILTER_TYPE_TIME',
  DatasetFilterTypeUnknown = 'DATASET_FILTER_TYPE_UNKNOWN'
}

export enum DatasetOrderByDirection {
  DatasetOrderByDirectionAsc = 'DATASET_ORDER_BY_DIRECTION_ASC',
  DatasetOrderByDirectionDesc = 'DATASET_ORDER_BY_DIRECTION_DESC',
  DatasetOrderByDirectionUnknown = 'DATASET_ORDER_BY_DIRECTION_UNKNOWN'
}

export type DatasetOrdering = {
  column: Scalars['String']['input'];
  direction: DatasetOrderByDirection;
};

/** Generated from com/attentivemobile/contracts/metricsapi/DatasetsContract.proto */
export type DatasetValue = {
  __typename?: 'DatasetValue';
  columnDisplayName: Scalars['String']['output'];
  value: Maybe<DatasetValueValue>;
};

export type DatasetValueValue = BooleanDatasetValue | NumericDatasetValue | StringDatasetValue | TimeDatasetValue;

/** Generated from com/attentivemobile/contracts/event/platform/registration/service/Datastream.proto */
export type Datastream = {
  __typename?: 'Datastream';
  contactName: Scalars['String']['output'];
  datastreamName: Scalars['String']['output'];
  eventFilter: DatastreamEventFilter;
  /** @deprecated deprecated */
  eventTypeFilters: Array<EventType>;
  impactMetadata: DatastreamImpactMetadata;
  sinks: Array<DatastreamSink>;
  sources: Array<DatastreamSource>;
  state: DatastreamStatus;
  teamName: Scalars['String']['output'];
  transformations: Array<DatastreamTransformation>;
};

/** Generated from com/attentivemobile/contracts/event/platform/registration/service/Datastream.proto */
export type DatastreamEventFilter = {
  __typename?: 'DatastreamEventFilter';
  eventTypeFilters: Array<EventType>;
  parallelism: Maybe<Scalars['Int']['output']>;
};

/** Generated from com/attentivemobile/contracts/event/platform/registration/service/Datastream.proto */
export type DatastreamImpactMetadata = {
  __typename?: 'DatastreamImpactMetadata';
  impact: Maybe<Scalars['String']['output']>;
  priority: Maybe<DatastreamPriority>;
  sloAlertS: Maybe<Scalars['Int']['output']>;
  sloWarnS: Maybe<Scalars['Int']['output']>;
};

export type DatastreamImpactMetadataInput = {
  impact: InputMaybe<Scalars['String']['input']>;
  priority: InputMaybe<DatastreamPriority>;
  sloAlertS: InputMaybe<Scalars['Int']['input']>;
  sloWarnS: InputMaybe<Scalars['Int']['input']>;
};

export enum DatastreamPriority {
  DatastreamPriorityP0 = 'DATASTREAM_PRIORITY_P0',
  DatastreamPriorityP1 = 'DATASTREAM_PRIORITY_P1',
  DatastreamPriorityP2 = 'DATASTREAM_PRIORITY_P2',
  DatastreamPriorityP3 = 'DATASTREAM_PRIORITY_P3',
  DatastreamPriorityUnknown = 'DATASTREAM_PRIORITY_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/event/platform/registration/service/Datastream.proto */
export type DatastreamSink = {
  __typename?: 'DatastreamSink';
  maxBufferTimeMillis: Maybe<Scalars['Int']['output']>;
  parallelism: Maybe<Scalars['Int']['output']>;
  rateLimitPercentage: Maybe<Scalars['Int']['output']>;
  type: Maybe<DatastreamSinkType>;
};

export type DatastreamSinkType = EventBackup | GenericBackup | HttpEndpoint | KinesisStream | PulsarTopic | S3EventSink | S3GenericSink;

/** Generated from com/attentivemobile/contracts/event/platform/registration/service/Datastream.proto */
export type DatastreamSource = {
  __typename?: 'DatastreamSource';
  parallelism: Maybe<Scalars['Int']['output']>;
  topicToMessageOffsets: Array<TopicToMessageOffsetsEntry>;
  type: Maybe<DatastreamSourceType>;
};

export type DatastreamSourceType = KinesisStream | PulsarTopic | S3Location;

export enum DatastreamStatus {
  DatastreamStatusOff = 'DATASTREAM_STATUS_OFF',
  DatastreamStatusOn = 'DATASTREAM_STATUS_ON',
  DatastreamStatusUnknown = 'DATASTREAM_STATUS_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/event/platform/registration/service/Contract.proto */
export type DatastreamStatusInfo = {
  __typename?: 'DatastreamStatusInfo';
  datastreamDetails: Maybe<GetDatastreamDetailsResponse>;
  datastreamName: Scalars['String']['output'];
  health: JobHealth;
  jobUrl: Scalars['String']['output'];
  status: JobStatus;
};

/** Generated from com/attentivemobile/contracts/event/platform/registration/service/Datastream.proto */
export type DatastreamTeam = {
  __typename?: 'DatastreamTeam';
  datastreams: Maybe<ListDatastreams>;
  name: Scalars['String']['output'];
};


/** Generated from com/attentivemobile/contracts/event/platform/registration/service/Datastream.proto */
export type DatastreamTeamdatastreamsArgs = {
  cluster: InputMaybe<Cluster>;
};

/** Generated from com/attentivemobile/contracts/event/platform/registration/service/Datastream.proto */
export type DatastreamTransformation = {
  __typename?: 'DatastreamTransformation';
  operator: Maybe<DatastreamTransformationOperator>;
  parallelism: Maybe<Scalars['Int']['output']>;
};

export type DatastreamTransformationOperator = Enricher | EventTypeSplitter | GenericEventRateLimiter | PiiScrubber | ProtoEventJoiner | ProtoEventRateLimiter;

/** Generated from com/attentivemobile/contracts/event/platform/registration/service/Contract.proto */
export type DatastreamWithRuntime = {
  __typename?: 'DatastreamWithRuntime';
  datastream: Datastream;
  runtime: Maybe<DatastreamWithRuntimeRuntime>;
};

export type DatastreamWithRuntimeRuntime = FlinkJob;

/** Generated from com/attentivemobile/protobuf/common/targeting/UserPropertyValue.proto */
export type Date = {
  __typename?: 'Date';
  day: Maybe<Scalars['Int']['output']>;
  month: Maybe<Scalars['Int']['output']>;
  year: Maybe<Scalars['Int']['output']>;
};

/** Generated from com/attentivemobile/protobuf/common/targeting/segmentation/output/ComparisonTypes.proto */
export type DateComponentComparison = {
  __typename?: 'DateComponentComparison';
  comparator: SearchDateComponentComparator;
  values: Array<Scalars['Long']['output']>;
};

export type DateComponentComparisonInput = {
  comparator: SearchDateComponentComparator;
  values: InputMaybe<Array<Scalars['Long']['input']>>;
};

export enum DateFilterPeriod {
  DateFilterPeriodLast_7Days = 'DATE_FILTER_PERIOD_LAST_7_DAYS',
  DateFilterPeriodLast_30Days = 'DATE_FILTER_PERIOD_LAST_30_DAYS',
  DateFilterPeriodLast_90Days = 'DATE_FILTER_PERIOD_LAST_90_DAYS',
  DateFilterPeriodLastMonth = 'DATE_FILTER_PERIOD_LAST_MONTH',
  DateFilterPeriodLastQuarter = 'DATE_FILTER_PERIOD_LAST_QUARTER',
  DateFilterPeriodLastYear = 'DATE_FILTER_PERIOD_LAST_YEAR',
  DateFilterPeriodMonthToDate = 'DATE_FILTER_PERIOD_MONTH_TO_DATE',
  DateFilterPeriodQuarterToDate = 'DATE_FILTER_PERIOD_QUARTER_TO_DATE',
  DateFilterPeriodToday = 'DATE_FILTER_PERIOD_TODAY',
  DateFilterPeriodUnknown = 'DATE_FILTER_PERIOD_UNKNOWN',
  DateFilterPeriodWeekToDate = 'DATE_FILTER_PERIOD_WEEK_TO_DATE',
  DateFilterPeriodYearToDate = 'DATE_FILTER_PERIOD_YEAR_TO_DATE',
  DateFilterPeriodYesterday = 'DATE_FILTER_PERIOD_YESTERDAY'
}

export type DateInput = {
  day: Scalars['Int']['input'];
  month: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type DatePropertyValue = {
  __typename?: 'DatePropertyValue';
  value: Scalars['DateTime']['output'];
};

export type DateRange = {
  end: Scalars['DateTime']['input'];
  start: Scalars['DateTime']['input'];
};

export type DateRangeFilter = {
  end: Scalars['DateTime']['input'];
  start: Scalars['DateTime']['input'];
};

export type DateTimeRange = {
  end: Scalars['DateTime']['input'];
  start: Scalars['DateTime']['input'];
};

/** Generated from com/attentivemobile/contracts/user/profile/property/UserPropertyV2.proto */
export type DateTimeValueV2 = {
  __typename?: 'DateTimeValueV2';
  dateTimeValue: Scalars['DateTime']['output'];
};

/** Generated from com/attentivemobile/contracts/user/profile/property/UserPropertyV2.proto */
export type DateValueV2 = {
  __typename?: 'DateValueV2';
  day: Maybe<Scalars['Int']['output']>;
  month: Maybe<Scalars['Int']['output']>;
  year: Maybe<Scalars['Int']['output']>;
};

export type DeactivateSyncInput = {
  syncId: Scalars['String']['input'];
};

export type DeactivateSyncPayload = DeactivateSyncSuccess | DefaultErrorFailure;

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type DeactivateSyncSuccess = {
  __typename?: 'DeactivateSyncSuccess';
  success: Scalars['Boolean']['output'];
};

/** Generated from com/attentivemobile/contracts/common/Error.proto */
export type DefaultErrorFailure = StandardError & {
  __typename?: 'DefaultErrorFailure';
  message: Scalars['String']['output'];
  status: StandardErrorStatus;
  title: Scalars['String']['output'];
  traceId: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/protobuf/common/graphql/error.proto */
export type DefaultStandardError = {
  __typename?: 'DefaultStandardError';
  message: Scalars['String']['output'];
  status: StandardErrorStatus;
  title: Scalars['String']['output'];
  traceId: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/journeys/api/RevisionStep.proto */
export type DelayStep = {
  __typename?: 'DelayStep';
  childrenSteps: Array<AdderStep>;
  stepParameters: DelayStepParameters;
};

/** Generated from com/attentivemobile/contracts/journeys/api/RevisionStepParameters.proto */
export type DelayStepParameters = {
  __typename?: 'DelayStepParameters';
  durationSeconds: Scalars['Long']['output'];
  durationUnit: JourneyDurationUnit;
};

export type DeleteAccountInput = {
  accountId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/account-service/Contract.proto */
export type DeleteAccountPayload = {
  __typename?: 'DeleteAccountPayload';
  accountId: Scalars['ID']['output'];
};

export type DeleteAdminNotificationConfigurationInput = {
  notificationId: InputMaybe<Scalars['String']['input']>;
  notificationName: InputMaybe<Scalars['String']['input']>;
  notificationSlug: InputMaybe<Scalars['String']['input']>;
};

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type DeleteAdminNotificationConfigurationPayload = {
  __typename?: 'DeleteAdminNotificationConfigurationPayload';
  notificationId: Scalars['String']['output'];
};

export type DeleteAffiliateUserInput = {
  affiliateUserId: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/externaltokens/AffiliateUsers.proto */
export type DeleteAffiliateUserPayload = {
  __typename?: 'DeleteAffiliateUserPayload';
  affiliateUserId: Scalars['String']['output'];
};

export type DeleteAssetsInput = {
  assetIds: InputMaybe<Array<AssetId>>;
};

export type DeleteAssetsPayload = DefaultErrorFailure | DeleteAssetsSuccess;

/** Generated from com/attentivemobile/contracts/asset/library/service/Contract.proto */
export type DeleteAssetsSuccess = {
  __typename?: 'DeleteAssetsSuccess';
  deletedFolderIds: Array<Scalars['ID']['output']>;
  deletedImageIds: Array<Scalars['ID']['output']>;
};

/** This request will be for security users only */
export type DeleteAuthenticatorEnrollmentByEmailInput = {
  email: Scalars['String']['input'];
};

export type DeleteAuthenticatorEnrollmentByEmailPayload = DefaultErrorFailure | DeleteAuthenticatorEnrollmentByEmailSuccess;

/**
 * Generated from com/attentivemobile/contracts/account-service/AuthenticatorContract.proto
 * If success is true, the authenticator has been deleted
 */
export type DeleteAuthenticatorEnrollmentByEmailSuccess = {
  __typename?: 'DeleteAuthenticatorEnrollmentByEmailSuccess';
  success: Scalars['Boolean']['output'];
};

export type DeleteBlockCompanyInfoInput = {
  companyId: Scalars['ID']['input'];
  companyInfoType: InputMaybe<Array<BlockCompanyInfoType>>;
};

export type DeleteBlockCompanyInfoPayload = DefaultErrorFailure | DeleteBlockCompanyInfoSuccess;

/** Generated from com/attentivemobile/contracts/personalization/service/aijourneys/onboarding/companyinfo/BlockCompanyInfo.proto */
export type DeleteBlockCompanyInfoSuccess = {
  __typename?: 'DeleteBlockCompanyInfoSuccess';
  success: Scalars['Boolean']['output'];
};

export type DeleteBrandImagesInput = {
  companyId: Scalars['ID']['input'];
  fileUuid: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Generated from com/attentivemobile/contracts/brand-kit/BrandImage.proto */
export type DeleteBrandImagesPayload = {
  __typename?: 'DeleteBrandImagesPayload';
  company: Maybe<Company>;
};

export type DeleteCampaignMessageGroupInput = {
  campaignMessageGroupId: Scalars['ID']['input'];
  companyId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type DeleteCircuitBreakerRateLimitInput = {
  id: Scalars['Long']['input'];
};

export type DeleteCircuitBreakerRateLimitsPayload = DefaultErrorFailure | DeleteCircuitBreakerRateLimitsSuccess;

/** Generated from com/attentivemobile/contracts/gateway/service/RateLimiting.proto */
export type DeleteCircuitBreakerRateLimitsSuccess = {
  __typename?: 'DeleteCircuitBreakerRateLimitsSuccess';
  successful: Scalars['Boolean']['output'];
};

export type DeleteCompanyApplicationRouteRateLimitInput = {
  id: Scalars['Long']['input'];
};

export type DeleteCompanyApplicationRouteRateLimitPayload = DefaultErrorFailure | DeleteCompanyApplicationRouteRateLimitSuccess;

/** Generated from com/attentivemobile/contracts/gateway/service/RateLimiting.proto */
export type DeleteCompanyApplicationRouteRateLimitSuccess = {
  __typename?: 'DeleteCompanyApplicationRouteRateLimitSuccess';
  successful: Scalars['Boolean']['output'];
};

export type DeleteCompanyCodesInput = {
  code: InputMaybe<Array<Scalars['String']['input']>>;
  companyId: Scalars['ID']['input'];
  handler: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/creative-service/creative/LegacyCreativeService.proto */
export type DeleteCompanyCodesPayload = {
  __typename?: 'DeleteCompanyCodesPayload';
  code: Array<CompanyCode>;
};

export type DeleteCompanyGlobalRateLimitInput = {
  id: Scalars['Long']['input'];
};

export type DeleteCompanyGlobalRateLimitPayload = DefaultErrorFailure | DeleteCompanyGlobalRateLimitSuccess;

/** Generated from com/attentivemobile/contracts/gateway/service/RateLimiting.proto */
export type DeleteCompanyGlobalRateLimitSuccess = {
  __typename?: 'DeleteCompanyGlobalRateLimitSuccess';
  successful: Scalars['Boolean']['output'];
};

export type DeleteComposeCampaignInput = {
  campaignId: Scalars['ID']['input'];
  companyId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/campaigns/service/Contract.proto */
export type DeleteComposeCampaignPayload = {
  __typename?: 'DeleteComposeCampaignPayload';
  campaignId: Scalars['ID']['output'];
};

export type DeleteConciergeSpecializationInput = {
  id: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/concierge/service/Specialization.proto */
export type DeleteConciergeSpecializationPayload = {
  __typename?: 'DeleteConciergeSpecializationPayload';
  id: Scalars['ID']['output'];
};

export type DeleteCouponSetInput = {
  couponSetId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/coupon/CouponContract.proto */
export type DeleteCouponSetPayload = {
  __typename?: 'DeleteCouponSetPayload';
  deleted: CouponSet;
};

export type DeleteCouponUploadInput = {
  couponSetId: Scalars['ID']['input'];
  couponUploadId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/coupon/Contract.proto */
export type DeleteCouponUploadPayload = {
  __typename?: 'DeleteCouponUploadPayload';
  deleted: CouponUpload;
};

export type DeleteCreativeGraphInput = {
  companyId: Scalars['ID']['input'];
  creativeId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/creative-service/creative/CreativeGraphService.proto */
export type DeleteCreativeGraphPayload = {
  __typename?: 'DeleteCreativeGraphPayload';
  deleted: Scalars['Boolean']['output'];
};

/** This message type is for explicitly deleting properties that are nullable on a creative */
export type DeleteCreativePropertiesInput = {
  alreadySubscribedMessageId: InputMaybe<Scalars['Boolean']['input']>;
  couponSetId: InputMaybe<Scalars['Boolean']['input']>;
};

export type DeleteCreativeScheduleInput = {
  applyPermanently: Scalars['Boolean']['input'];
  companyId: Scalars['ID']['input'];
  scheduleId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/creative-service/schedule/CreativeScheduleService.proto */
export type DeleteCreativeSchedulePayload = {
  __typename?: 'DeleteCreativeSchedulePayload';
  schedule: CreativeSchedule;
  /** @deprecated deprecated */
  scheduleId: Scalars['String']['output'];
};

export type DeleteCreativeScheduleWorkflowInput = {
  companyId: Scalars['ID']['input'];
  /** @deprecated deprecated */
  scheduleId: InputMaybe<Scalars['ID']['input']>;
  workflowId: InputMaybe<Scalars['ID']['input']>;
};

export type DeleteCreativeScheduleWorkflowPayload = DefaultErrorFailure | DeleteCreativeScheduleWorkflowSuccess;

/** Generated from com/attentivemobile/contracts/creative/workflow/service/workflows/CreativeSchedulesWorkflowService.proto */
export type DeleteCreativeScheduleWorkflowSuccess = {
  __typename?: 'DeleteCreativeScheduleWorkflowSuccess';
  /** @deprecated deprecated */
  schedule: Maybe<CreativeSchedule>;
  workflow: Maybe<CreativeWorkflowSchedule>;
};

export type DeleteCustomCompanyKeywordInput = {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type DeleteCustomCompanyKeywordPayload = DefaultErrorFailure | DeleteCustomCompanyKeywordSuccess;

/** Generated from com/attentivemobile/contracts/keywords/KeywordService.proto */
export type DeleteCustomCompanyKeywordSuccess = {
  __typename?: 'DeleteCustomCompanyKeywordSuccess';
  deleted: Scalars['Boolean']['output'];
};

export type DeleteCustomKeywordInput = {
  companyId: Scalars['ID']['input'];
  customKeywordId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/tactical/companycustomkeywords/CompanyCustomKeywordService.proto */
export type DeleteCustomKeywordPayload = {
  __typename?: 'DeleteCustomKeywordPayload';
  deleted: Scalars['Boolean']['output'];
};

export type DeleteCustomPropertyDefinitionInput = {
  companyId: Scalars['ID']['input'];
  customPropertyDefinitionId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type DeleteCustomPropertyDefinitionPayload = {
  __typename?: 'DeleteCustomPropertyDefinitionPayload';
  customPropertyDefinition: CustomPropertyDefinition;
};

export type DeleteCustomReportInput = {
  companyId: Scalars['ID']['input'];
  reportId: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/reports/api/Contract.proto */
export type DeleteCustomReportPayload = {
  __typename?: 'DeleteCustomReportPayload';
  reportId: Scalars['String']['output'];
};

export type DeleteDismissibleModalInput = {
  id: Scalars['ID']['input'];
  modalId: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type DeleteDismissibleModalPayload = {
  __typename?: 'DeleteDismissibleModalPayload';
  id: Scalars['ID']['output'];
};

export type DeleteEdsCronScheduleInput = {
  jobType: Scalars['String']['input'];
  taskId: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/eventdestination/cron/service/CronScheduler.proto */
export type DeleteEdsCronSchedulePayload = {
  __typename?: 'DeleteEdsCronSchedulePayload';
  msg: Scalars['String']['output'];
};

export type DeleteEdsJobInput = {
  taskId: Scalars['String']['input'];
};

export type DeleteEdsJobPayload = DefaultErrorFailure | DeleteEdsJobSuccess;

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type DeleteEdsJobSuccess = {
  __typename?: 'DeleteEdsJobSuccess';
  taskUuid: Maybe<Scalars['String']['output']>;
};

export type DeleteJourneyInput = {
  journeyId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/journeys/api/Contract.proto */
export type DeleteJourneyPayload = {
  __typename?: 'DeleteJourneyPayload';
  deletedJourney: Journey;
};

export type DeleteJourneyScheduleInput = {
  companyId: Scalars['ID']['input'];
  journeyScheduleId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/journeys/api/JourneySchedule.proto */
export type DeleteJourneySchedulePayload = {
  __typename?: 'DeleteJourneySchedulePayload';
  deletedJourneySchedule: JourneySchedule;
};

export type DeleteKeywordRulePayload = DefaultErrorFailure | DeleteKeywordRuleSuccess;

export type DeleteKeywordRuleRequestInput = {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/keywords/KeywordRuleService.proto */
export type DeleteKeywordRuleSuccess = {
  __typename?: 'DeleteKeywordRuleSuccess';
  deleted: Scalars['Boolean']['output'];
};

export type DeleteMessageTemplateInput = {
  channel: Channel;
  id: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/compose/api/Contract.proto */
export type DeleteMessageTemplatePayload = {
  __typename?: 'DeleteMessageTemplatePayload';
  messageTemplate: ComposeMessageTemplate;
};

export type DeletePollOptionInput = {
  companyId: Scalars['ID']['input'];
  pollOptionId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/survey/poll/Contract.proto */
export type DeletePollOptionPayload = {
  __typename?: 'DeletePollOptionPayload';
  deleted: Scalars['Boolean']['output'];
  error: Maybe<Scalars['String']['output']>;
};

/** Generated from com/attentivemobile/contracts/privacy-service/Contract.proto */
export type DeleteRequest = Node & {
  __typename?: 'DeleteRequest';
  affiliateUserId: Maybe<Scalars['Long']['output']>;
  clientUserId: Maybe<Scalars['String']['output']>;
  company: Maybe<Company>;
  id: Scalars['ID']['output'];
  metadata: Maybe<Scalars['String']['output']>;
  processed: Scalars['Boolean']['output'];
  requestDateTime: Scalars['DateTime']['output'];
  status: Maybe<Scalars['String']['output']>;
  subjectEmail: Maybe<Scalars['String']['output']>;
  subjectPhone: Maybe<Scalars['String']['output']>;
  updated: Maybe<Scalars['DateTime']['output']>;
};

/** Generated from com/attentivemobile/contracts/privacy-service/Contract.proto */
export type DeleteRequestConnection = {
  __typename?: 'DeleteRequestConnection';
  edges: Array<DeleteRequestEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Generated from com/attentivemobile/contracts/privacy-service/Contract.proto */
export type DeleteRequestEdge = {
  __typename?: 'DeleteRequestEdge';
  cursor: Scalars['String']['output'];
  node: DeleteRequest;
};

export type DeleteRouteRateLimitInput = {
  id: Scalars['Long']['input'];
};

export type DeleteRouteRateLimitPayload = DefaultErrorFailure | DeleteRouteRateLimitSuccess;

/** Generated from com/attentivemobile/contracts/gateway/service/RateLimiting.proto */
export type DeleteRouteRateLimitSuccess = {
  __typename?: 'DeleteRouteRateLimitSuccess';
  successful: Scalars['Boolean']['output'];
};

export type DeleteSegmentsInput = {
  companyId: Scalars['ID']['input'];
  segmentIds: InputMaybe<Array<SegmentId>>;
};

export type DeleteSegmentsPayload = DefaultErrorFailure | DeleteSegmentsSuccess;

/** Generated from com/attentivemobile/contracts/segmentation/service/segment/contract.proto */
export type DeleteSegmentsSuccess = {
  __typename?: 'DeleteSegmentsSuccess';
  legacyIds: Array<Scalars['ID']['output']>;
  segmentIds: Array<Scalars['ID']['output']>;
};

export type DeleteShopifyAutoApplyFormatsInput = {
  companyId: Scalars['ID']['input'];
};

export type DeleteShopifyAutoApplyFormatsPayload = DefaultErrorFailure | DeleteShopifyAutoApplyFormatsSuccess;

/** Generated from com/attentivemobile/contracts/personalization/service/aijourneys/onboarding/autoapply/ShopifyAutoApplyFormatsContract.proto */
export type DeleteShopifyAutoApplyFormatsSuccess = {
  __typename?: 'DeleteShopifyAutoApplyFormatsSuccess';
  success: Scalars['Boolean']['output'];
};

export type DeleteSignupInput = {
  companyId: Scalars['ID']['input'];
  signupId: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/creative-service/signup/SignupService.proto */
export type DeleteSignupPayload = {
  __typename?: 'DeleteSignupPayload';
  signupId: Scalars['String']['output'];
};

export type DeleteSyncInput = {
  syncId: Scalars['String']['input'];
};

export type DeleteSyncPayload = DefaultErrorFailure | DeleteSyncSuccess;

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type DeleteSyncSuccess = {
  __typename?: 'DeleteSyncSuccess';
  success: Scalars['Boolean']['output'];
};

export type DeleteTagInput = {
  companyId: Scalars['ID']['input'];
  tagId: Scalars['ID']['input'];
};

export type DeleteUserContentSettingInput = {
  companyId: InputMaybe<Scalars['ID']['input']>;
  objectClass: ContentClass;
  objectId: InputMaybe<Scalars['String']['input']>;
  preferenceType: PreferenceType;
  userId: InputMaybe<Scalars['ID']['input']>;
};

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type DeleteUserContentSettingPayload = {
  __typename?: 'DeleteUserContentSettingPayload';
  preferenceId: Scalars['String']['output'];
};

/** Delete webhook request input. */
export type DeleteWebhookInput = {
  companyId: InputMaybe<Scalars['String']['input']>;
  /** Id of the webhook to delete. */
  id: Scalars['ID']['input'];
};

/**
 * Generated from com/attentivemobile/contracts/externaltokens/Webhooks.proto
 * Response to deleteWebhook mutation.
 */
export type DeleteWebhookPayload = {
  __typename?: 'DeleteWebhookPayload';
  /** True if webhook was deleted. */
  deleted: Scalars['Boolean']['output'];
};

export type DeleteWorkloadConfigInput = {
  workloadName: Scalars['String']['input'];
};

export type DeleteWorkloadConfigPayload = DefaultErrorFailure | DeleteWorkloadConfigSuccess;

/** Generated from com/attentivemobile/contracts/workload/management/service/configuration/Contract.proto */
export type DeleteWorkloadConfigSuccess = {
  __typename?: 'DeleteWorkloadConfigSuccess';
  status: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/event/platform/registration/service/Transformation.proto */
export type DependentField = {
  __typename?: 'DependentField';
  fieldName: Scalars['String']['output'];
  sourceFieldPath: Scalars['String']['output'];
};

export type DeployServerSideTagInput = {
  companyId: Scalars['ID']['input'];
};

export type DeployServerSideTagPayload = DefaultErrorFailure | DeployServerSideTagSuccess;

/** Generated from com/attentivemobile/contracts/integrations/serversidetag/Contract.proto */
export type DeployServerSideTagSuccess = {
  __typename?: 'DeployServerSideTagSuccess';
  success: Scalars['Boolean']['output'];
};

export type DescheduleWorkloadInput = {
  workloadName: Scalars['String']['input'];
};

export type DescheduleWorkloadPayload = DefaultErrorFailure | DescheduleWorkloadSuccess;

/** Generated from com/attentivemobile/contracts/workload/management/service/runner/Contract.proto */
export type DescheduleWorkloadSuccess = {
  __typename?: 'DescheduleWorkloadSuccess';
  status: Scalars['String']['output'];
};

export type DestinationAddress = DestinationEmail | DestinationPhone;

/**
 * Generated from com/attentivemobile/contracts/subscriptions/api/v2/Model.proto
 * Destination email.
 */
export type DestinationEmail = {
  __typename?: 'DestinationEmail';
  /** Value of destination email. */
  value: Scalars['String']['output'];
};

/**
 * Generated from com/attentivemobile/contracts/subscriptions/api/v2/Model.proto
 * Destination phone.
 */
export type DestinationPhone = {
  __typename?: 'DestinationPhone';
  /** Value of destination phone. */
  value: Scalars['String']['output'];
};

export type DeveloperBanner = {
  __typename?: 'DeveloperBanner';
  showDeveloperBanner: Scalars['Boolean']['output'];
};

export type Dimension = StringDimension | TimeDimension;

export type DimensionFilter = {
  dimensionId: Scalars['String']['input'];
  endDate: InputMaybe<Scalars['DateTime']['input']>;
  list: InputMaybe<DimensionFilterValuesList>;
  nodeId: InputMaybe<Scalars['ID']['input']>;
  nodeIds: InputMaybe<Array<Scalars['ID']['input']>>;
  operator: InputMaybe<FilterOperator>;
  startDate: InputMaybe<Scalars['DateTime']['input']>;
  value: InputMaybe<Scalars['String']['input']>;
};

export type DimensionFilterValuesList = {
  values: Array<Scalars['String']['input']>;
};

export type DimensionGrouping = {
  dimensionId: Scalars['String']['input'];
  granularity: InputMaybe<TimeDimensionGranularity>;
};

/** Generated from com/attentivemobile/contracts/metricsapi/Contract.proto */
export type DimensionValue = {
  __typename?: 'DimensionValue';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/metricsapi/Contract.proto */
export type DimensionValueConnection = {
  __typename?: 'DimensionValueConnection';
  edges: Array<DimensionValueEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Long']['output'];
};

/** Generated from com/attentivemobile/contracts/metricsapi/Contract.proto */
export type DimensionValueEdge = {
  __typename?: 'DimensionValueEdge';
  cursor: Scalars['String']['output'];
  node: DimensionValue;
};

/** Generated from com/attentivemobile/contracts/metricsapi/Contract.proto */
export type DimensionWrapper = {
  __typename?: 'DimensionWrapper';
  dimension: Dimension;
};

export type DisableAllLegacyScheduledReportsInput = {
  companyId: Scalars['ID']['input'];
};

export type DisableAllLegacyScheduledReportsPayload = DefaultErrorFailure | DisableAllLegacyScheduledReportsSuccess;

/** Generated from com/attentivemobile/contracts/reports/api/Contract.proto */
export type DisableAllLegacyScheduledReportsSuccess = {
  __typename?: 'DisableAllLegacyScheduledReportsSuccess';
  message: Scalars['String']['output'];
};

export type DisableAutomationProgramInput = {
  automationProgramId: Scalars['ID']['input'];
  companyId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/messaging/automation/Contract.proto */
export type DisableAutomationProgramPayload = {
  __typename?: 'DisableAutomationProgramPayload';
  automationProgram: AutomationProgram;
};

/** Generated from com/attentivemobile/contracts/integrations/discount/Contract.proto */
export type Discount = {
  __typename?: 'Discount';
  allCountriesValid: Maybe<Scalars['Boolean']['output']>;
  applicableCustomers: ApplicableCustomers;
  appliesOnOneTimePurchase: Maybe<Scalars['Boolean']['output']>;
  appliesOnSubscription: Maybe<Scalars['Boolean']['output']>;
  appliesOncePerCustomer: Maybe<Scalars['Boolean']['output']>;
  company: Maybe<Company>;
  couponCode: Scalars['String']['output'];
  customerReceives: CustomerReceives;
  customerRequirements: CustomerRequirements;
  discountStatus: DiscountStatus;
  discountType: DiscountType;
  ends: Maybe<Scalars['DateTime']['output']>;
  externalVendorApi: Maybe<ExternalVendorApi>;
  starts: Maybe<Scalars['DateTime']['output']>;
  summary: Scalars['String']['output'];
  validCountries: Array<Scalars['String']['output']>;
  vendor: Vendor;
  vendorDiscountId: Scalars['String']['output'];
};

export enum DiscountLinkFormat {
  DiscountLinkFormatCodeInPath = 'DISCOUNT_LINK_FORMAT_CODE_IN_PATH',
  DiscountLinkFormatCodeInQuery = 'DISCOUNT_LINK_FORMAT_CODE_IN_QUERY',
  DiscountLinkFormatNone = 'DISCOUNT_LINK_FORMAT_NONE'
}

export enum DiscountStatus {
  Active = 'ACTIVE',
  Expired = 'EXPIRED',
  Scheduled = 'SCHEDULED',
  UnknownStatus = 'UNKNOWN_STATUS'
}

export enum DiscountType {
  BuyXGetY = 'BUY_X_GET_Y',
  FreeShipping = 'FREE_SHIPPING',
  PercentageOffQuantity = 'PERCENTAGE_OFF_QUANTITY',
  PercentageOffTotal = 'PERCENTAGE_OFF_TOTAL',
  PerItemDiscount = 'PER_ITEM_DISCOUNT',
  PerTotalDiscount = 'PER_TOTAL_DISCOUNT',
  UnknownDiscountType = 'UNKNOWN_DISCOUNT_TYPE'
}

export type DismissBannerInput = {
  bannerId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type DismissBannerPayload = {
  __typename?: 'DismissBannerPayload';
  banner: NotificationBanner;
};

export type DismissNotificationInput = {
  notificationId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type DismissNotificationPayload = {
  __typename?: 'DismissNotificationPayload';
  notification: Notification;
};

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type DismissibleModal = Node & {
  __typename?: 'DismissibleModal';
  description: Maybe<Scalars['String']['output']>;
  endTime: Scalars['DateTime']['output'];
  featureFlags: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageUrl: Maybe<Scalars['String']['output']>;
  modalId: Scalars['String']['output'];
  primaryButtonAction: ButtonAction;
  primaryButtonText: Scalars['String']['output'];
  primaryButtonUrl: Maybe<Scalars['String']['output']>;
  secondaryButtonAction: Maybe<ButtonAction>;
  secondaryButtonText: Maybe<Scalars['String']['output']>;
  secondaryButtonUrl: Maybe<Scalars['String']['output']>;
  startTime: Scalars['DateTime']['output'];
  title: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type DismissibleModalConnection = {
  __typename?: 'DismissibleModalConnection';
  edges: Array<DismissibleModalEdge>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type DismissibleModalEdge = {
  __typename?: 'DismissibleModalEdge';
  cursor: Scalars['String']['output'];
  node: DismissibleModal;
};

export type DismissibleModalFilter = {
  activeOnly: InputMaybe<Scalars['Boolean']['input']>;
  companyId: InputMaybe<Scalars['ID']['input']>;
};

export type Distance = {
  distanceUnit: DistanceUnit;
  value: Scalars['Float']['input'];
};

/** Generated from com/attentivemobile/protobuf/common/targeting/segmentation/output/ComparisonTypes.proto */
export type DistanceComparison = {
  __typename?: 'DistanceComparison';
  comparator: SearchDistanceComparator;
  distanceAmount: Scalars['Long']['output'];
  distanceUnit: SearchDistanceUnit;
  origin: Scalars['String']['output'];
};

export type DistanceComparisonInput = {
  comparator: SearchDistanceComparator;
  distanceAmount: Scalars['Long']['input'];
  distanceUnit: SearchDistanceUnit;
  origin: Scalars['String']['input'];
};

export enum DistanceUnit {
  DistanceUnitKilometer = 'DISTANCE_UNIT_KILOMETER',
  DistanceUnitMeter = 'DISTANCE_UNIT_METER',
  DistanceUnitMile = 'DISTANCE_UNIT_MILE',
  DistanceUnitUnknown = 'DISTANCE_UNIT_UNKNOWN'
}

export enum DistributionStatus {
  Beta = 'BETA',
  DistributionStatusUnknown = 'DISTRIBUTION_STATUS_UNKNOWN',
  Private = 'PRIVATE',
  Public = 'PUBLIC',
  Unlisted = 'UNLISTED'
}

export enum DistributionUnit {
  DistributionUnitDay = 'DISTRIBUTION_UNIT_DAY',
  DistributionUnitHour = 'DISTRIBUTION_UNIT_HOUR',
  DistributionUnitMonth = 'DISTRIBUTION_UNIT_MONTH',
  DistributionUnitUnknown = 'DISTRIBUTION_UNIT_UNKNOWN',
  DistributionUnitWeek = 'DISTRIBUTION_UNIT_WEEK'
}

/** Generated from com/attentivemobile/contracts/emails/management/Contract.proto */
export type DnsRecord = {
  __typename?: 'DnsRecord';
  aliasDomainName: Scalars['String']['output'];
  sendgridDomainName: Scalars['String']['output'];
  type: DnsRecordType;
  valid: Scalars['Boolean']['output'];
};

export enum DnsRecordType {
  DnsRecordTypeCname = 'DNS_RECORD_TYPE_CNAME',
  DnsRecordTypeMx = 'DNS_RECORD_TYPE_MX',
  DnsRecordTypeTxt = 'DNS_RECORD_TYPE_TXT',
  DnsRecordTypeUnknown = 'DNS_RECORD_TYPE_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/emails/management/Contract.proto */
export type DnsRecordValidationResult = {
  __typename?: 'DnsRecordValidationResult';
  invalidReason: Maybe<Scalars['String']['output']>;
  isValid: Scalars['Boolean']['output'];
};

/** Generated from com/attentivemobile/contracts/emails/management/Contract.proto */
export type DnsRecords = {
  __typename?: 'DnsRecords';
  dkim: DnsRecord;
  dkim1: DnsRecord;
  dkim2: DnsRecord;
  mailCname: DnsRecord;
  mailServer: DnsRecord;
  subdomainSpf: DnsRecord;
};

/** Generated from com/attentivemobile/contracts/emails/management/Contract.proto */
export type DnsRecordsValidationResult = {
  __typename?: 'DnsRecordsValidationResult';
  dkim1: DnsRecordValidationResult;
  dkim2: DnsRecordValidationResult;
  mailCname: DnsRecordValidationResult;
};

/** Generated from com/attentivemobile/contracts/externaltokens/Contract.proto */
export type DocumentationLink = {
  __typename?: 'DocumentationLink';
  text: Scalars['String']['output'];
  url: Url;
};

/** Generated from com/attentivemobile/contracts/emails/management/Contract.proto */
export type DomainSuppression = {
  __typename?: 'DomainSuppression';
  companyId: Maybe<Scalars['Long']['output']>;
  created: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  domain: Scalars['String']['output'];
  reason: Scalars['String']['output'];
  userEmail: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/emails/management/Contract.proto */
export type DomainSuppressionConnection = {
  __typename?: 'DomainSuppressionConnection';
  edges: Array<DomainSuppressionEdge>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/emails/management/Contract.proto */
export type DomainSuppressionEdge = {
  __typename?: 'DomainSuppressionEdge';
  cursor: Scalars['String']['output'];
  node: DomainSuppression;
};

/** Generated from com/attentivemobile/contracts/emails/management/Contract.proto */
export type DomainValidationResult = {
  __typename?: 'DomainValidationResult';
  dnsRecordsValidation: DnsRecordsValidationResult;
  domainId: Scalars['Long']['output'];
  isValid: Scalars['Boolean']['output'];
};

/** Generated from com/attentivemobile/contracts/user/profile/property/UserPropertyV2.proto */
export type DoubleValueV2 = {
  __typename?: 'DoubleValueV2';
  doubleValue: Scalars['Float']['output'];
};

export type DownloadUsersFilterInput = {
  status: InputMaybe<UserStatus>;
};

export type DownloadUsersInput = {
  companyId: Scalars['ID']['input'];
  filter: InputMaybe<DownloadUsersFilterInput>;
  userFields: InputMaybe<Array<UserDownloadField>>;
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type DownloadUsersPayload = {
  __typename?: 'DownloadUsersPayload';
  company: Maybe<Company>;
  downloadUrl: Scalars['String']['output'];
  failureCount: Maybe<Scalars['Long']['output']>;
  listSize: Maybe<Scalars['Long']['output']>;
};

/** Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto */
export type DraftProductSegment = {
  __typename?: 'DraftProductSegment';
  productCount: Scalars['Long']['output'];
  productOriginIds: Array<Scalars['String']['output']>;
};

/** Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto */
export type DragAndDropEditorProperties = {
  __typename?: 'DragAndDropEditorProperties';
  htmlContent: Scalars['String']['output'];
  jsonContent: Scalars['JSON']['output'];
};

export type DragAndDropEditorPropertiesInput = {
  jsonContent: Scalars['JSON']['input'];
};

/** Generated from google/protobuf/duration.proto */
export type Duration = {
  __typename?: 'Duration';
  nanos: Scalars['Int']['output'];
  seconds: Scalars['Long']['output'];
};

/** Generated from com/attentivemobile/contracts/segmentation/data/service/Contract.proto */
export type DynamicOption = {
  __typename?: 'DynamicOption';
  name: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/segmentation/data/service/Contract.proto */
export type DynamicOptionConnection = {
  __typename?: 'DynamicOptionConnection';
  edges: Array<DynamicOptionEdge>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/segmentation/data/service/Contract.proto */
export type DynamicOptionEdge = {
  __typename?: 'DynamicOptionEdge';
  cursor: Scalars['String']['output'];
  node: DynamicOption;
};

export enum E2ETestMessageType {
  E2ETestMessageTypeBrowseAbandonment = 'E2E_TEST_MESSAGE_TYPE_BROWSE_ABANDONMENT',
  E2ETestMessageTypeCartAbandonment = 'E2E_TEST_MESSAGE_TYPE_CART_ABANDONMENT',
  E2ETestMessageTypeLegal = 'E2E_TEST_MESSAGE_TYPE_LEGAL',
  E2ETestMessageTypeSessionAbandonment = 'E2E_TEST_MESSAGE_TYPE_SESSION_ABANDONMENT',
  E2ETestMessageTypeUnknown = 'E2E_TEST_MESSAGE_TYPE_UNKNOWN',
  E2ETestMessageTypeWelcome = 'E2E_TEST_MESSAGE_TYPE_WELCOME',
  E2ETestMessageTypeWelcomeFollowup_1 = 'E2E_TEST_MESSAGE_TYPE_WELCOME_FOLLOWUP_1',
  E2ETestMessageTypeWelcomeFollowup_2 = 'E2E_TEST_MESSAGE_TYPE_WELCOME_FOLLOWUP_2',
  E2ETestMessageTypeWelcomeFollowup_3 = 'E2E_TEST_MESSAGE_TYPE_WELCOME_FOLLOWUP_3',
  E2ETestMessageTypeWelcomeFollowup_4 = 'E2E_TEST_MESSAGE_TYPE_WELCOME_FOLLOWUP_4'
}

/** Generated from com/attentivemobile/contracts/eventdestination/report/service/Contract.proto */
export type EDSJobHistoryConnection = {
  __typename?: 'EDSJobHistoryConnection';
  edges: Array<EDSJobHistoryEdge>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/eventdestination/report/service/Contract.proto */
export type EDSJobHistoryEdge = {
  __typename?: 'EDSJobHistoryEdge';
  cursor: Scalars['String']['output'];
  node: EDSJobReport;
};

/** Generated from com/attentivemobile/contracts/eventdestination/report/service/Contract.proto */
export type EDSJobReport = {
  __typename?: 'EDSJobReport';
  duration: Scalars['String']['output'];
  endTime: Maybe<Scalars['DateTime']['output']>;
  failure: Scalars['Long']['output'];
  rate: Scalars['String']['output'];
  requests: Scalars['Long']['output'];
  startTime: Maybe<Scalars['DateTime']['output']>;
  status: Maybe<Scalars['String']['output']>;
  success: Scalars['Long']['output'];
  successRate: Scalars['String']['output'];
  taskUuid: Scalars['String']['output'];
};

/**
 * Generated from com/attentivemobile/contracts/analytics/event/handler/Contract.proto
 * Product details.
 */
export type EcommerceProduct = {
  __typename?: 'EcommerceProduct';
  /** A unique identifier for the product (i.e. "T-Shirt"). If you are providing a [Google Shopping Feed](https://support.google.com/merchants/answer/7052112?hl=en), this should be [item_group_id](https://support.google.com/merchants/answer/6324507). If you don't have an item_group_id in your feed, use [id](https://support.google.com/merchants/answer/6324405). If you are using [Shopify](https://help.shopify.com/en/manual/products), this should be Shopify product ID. */
  productId: Scalars['String']['output'];
  /** A link to the image of the product. The image should not be larger than 500kb. This image will be used when sending MMS text messages. */
  productImage: Maybe<Scalars['String']['output']>;
  /** The name of the product. This should be in a format that could be used directly in a message. */
  productName: Maybe<Scalars['String']['output']>;
  /** The prices and currencies of the product. */
  productPrices: Array<Money>;
  /** A unique identifier for the product variant (i.e. "Medium Blue T-Shirt"). If you are providing a [Google Shopping Feed](https://support.google.com/merchants/answer/7052112?hl=en), this should be [id](https://support.google.com/merchants/answer/6324405). If you are using Shopify, this should be Shopify product Variant ID. */
  productVariantId: Scalars['String']['output'];
  /** The number of products. */
  quantity: Maybe<Scalars['Long']['output']>;
};

/** Product details. */
export type EcommerceProductInput = {
  /** ID for the product. */
  productId: Scalars['String']['input'];
  /** Image for the product. */
  productImage: InputMaybe<Scalars['String']['input']>;
  /** Name for the product. */
  productName: InputMaybe<Scalars['String']['input']>;
  /** Prices for the product. */
  productPrices: InputMaybe<Array<MoneyInput>>;
  /** ID for the product variant. */
  productVariantId: Scalars['String']['input'];
  /** Quantity of the product. */
  quantity: InputMaybe<Scalars['Long']['input']>;
};

export type EditEdsJobInput = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  taskId: Scalars['String']['input'];
};

export type EditEdsJobPayload = DefaultErrorFailure | EditEdsJobSuccess;

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type EditEdsJobSuccess = {
  __typename?: 'EditEdsJobSuccess';
  description: Scalars['String']['output'];
  name: Scalars['String']['output'];
  taskUuid: Scalars['String']['output'];
};

export type EditFeatureFlagInput = {
  description: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  rolloutVariants: InputMaybe<Array<RolloutInput>>;
};

/** Generated from com/attentivemobile/contracts/featureflag/service/Contract.proto */
export type EditFeatureFlagPayload = {
  __typename?: 'EditFeatureFlagPayload';
  featureFlag: FeatureFlag;
};

export enum EditorSource {
  EditorSourceDragAndDrop = 'EDITOR_SOURCE_DRAG_AND_DROP',
  EditorSourceHtml = 'EDITOR_SOURCE_HTML',
  EditorSourceUnknown = 'EDITOR_SOURCE_UNKNOWN'
}

export enum EdsJobIngestionFormat {
  EdsJobIngestionFormatCsv = 'EDS_JOB_INGESTION_FORMAT_CSV',
  EdsJobIngestionFormatNdjson = 'EDS_JOB_INGESTION_FORMAT_NDJSON',
  EdsJobIngestionFormatPsv = 'EDS_JOB_INGESTION_FORMAT_PSV',
  EdsJobIngestionFormatTsv = 'EDS_JOB_INGESTION_FORMAT_TSV',
  EdsJobIngestionFormatUnknown = 'EDS_JOB_INGESTION_FORMAT_UNKNOWN',
  EdsJobIngestionFormatXml = 'EDS_JOB_INGESTION_FORMAT_XML'
}

export enum EdsJobSync {
  EdsJobSyncCustomAttributes = 'EDS_JOB_SYNC_CUSTOM_ATTRIBUTES',
  EdsJobSyncCustomEvents = 'EDS_JOB_SYNC_CUSTOM_EVENTS',
  EdsJobSyncEcommerceAddToCarts = 'EDS_JOB_SYNC_ECOMMERCE_ADD_TO_CARTS',
  EdsJobSyncEcommerceProductViews = 'EDS_JOB_SYNC_ECOMMERCE_PRODUCT_VIEWS',
  EdsJobSyncEcommercePurchases = 'EDS_JOB_SYNC_ECOMMERCE_PURCHASES',
  EdsJobSyncMobileWallet = 'EDS_JOB_SYNC_MOBILE_WALLET',
  EdsJobSyncProductCatalog = 'EDS_JOB_SYNC_PRODUCT_CATALOG',
  EdsJobSyncSubscriberOptIns = 'EDS_JOB_SYNC_SUBSCRIBER_OPT_INS',
  EdsJobSyncSubscriberOptOuts = 'EDS_JOB_SYNC_SUBSCRIBER_OPT_OUTS',
  EdsJobSyncTextSend = 'EDS_JOB_SYNC_TEXT_SEND',
  EdsJobSyncTextSendBulk = 'EDS_JOB_SYNC_TEXT_SEND_BULK',
  EdsJobSyncUnknown = 'EDS_JOB_SYNC_UNKNOWN',
  EdsJobSyncUserIdentifiers = 'EDS_JOB_SYNC_USER_IDENTIFIERS'
}

export enum EdsJobSyncMethod {
  EdsJobSyncMethodAmazonS3 = 'EDS_JOB_SYNC_METHOD_AMAZON_S3',
  EdsJobSyncMethodAttentiveSftp = 'EDS_JOB_SYNC_METHOD_ATTENTIVE_SFTP',
  EdsJobSyncMethodClientHostedSftp = 'EDS_JOB_SYNC_METHOD_CLIENT_HOSTED_SFTP',
  EdsJobSyncMethodManualUpload = 'EDS_JOB_SYNC_METHOD_MANUAL_UPLOAD',
  EdsJobSyncMethodS3 = 'EDS_JOB_SYNC_METHOD_S3',
  EdsJobSyncMethodSftp = 'EDS_JOB_SYNC_METHOD_SFTP',
  EdsJobSyncMethodSnowflakeDataShare = 'EDS_JOB_SYNC_METHOD_SNOWFLAKE_DATA_SHARE',
  EdsJobSyncMethodUnknown = 'EDS_JOB_SYNC_METHOD_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type EdsTaskProcessingConfig = {
  __typename?: 'EdsTaskProcessingConfig';
  endpoint: Scalars['String']['output'];
  id: Maybe<Scalars['String']['output']>;
  intervalBetweenRetries: Scalars['Int']['output'];
  limitPerSec: Scalars['Int']['output'];
  metadata: Scalars['String']['output'];
  retryMultiplier: Scalars['Int']['output'];
  retryTimes: Scalars['Int']['output'];
  taskId: Maybe<Scalars['String']['output']>;
  threads: Scalars['Int']['output'];
  timeoutInSec: Scalars['Int']['output'];
};

export enum Element {
  BrandkitElementBackground = 'BRANDKIT_ELEMENT_BACKGROUND',
  BrandkitElementButton = 'BRANDKIT_ELEMENT_BUTTON',
  BrandkitElementButtonText = 'BRANDKIT_ELEMENT_BUTTON_TEXT',
  BrandkitElementText = 'BRANDKIT_ELEMENT_TEXT'
}

/** Generated from com/attentivemobile/contracts/brand-kit/BrandElementStyles.proto */
export type ElementProperty = {
  __typename?: 'ElementProperty';
  name: Scalars['String']['output'];
};

export type ElementPropertyInput = {
  name: Scalars['String']['input'];
};

/**
 * Generated from com/attentivemobile/contracts/subscriptions/api/v2/Model.proto
 * Eligibility information.
 */
export type Eligibility = {
  __typename?: 'Eligibility';
  /** Reason for ineligibility. */
  ineligibilityReason: Maybe<IneligibilityReason>;
  /** True if eligible, false if not eligible. */
  isEligible: Scalars['Boolean']['output'];
};

/** Generated from com/attentivemobile/contracts/emails/management/Contract.proto */
export type EmailAccount = Node & {
  __typename?: 'EmailAccount';
  company: Maybe<Company>;
  emailProvider: EmailProvider;
  fromAddress: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isReadyToSend: Maybe<Scalars['Boolean']['output']>;
  messageTypePools: EmailMessagingTypeIPPools;
  name: Scalars['String']['output'];
  sendingDomain: Maybe<Scalars['String']['output']>;
  warmupEndDate: Maybe<Scalars['DateTime']['output']>;
};

/** Generated from com/attentivemobile/contracts/emails/management/Contract.proto */
export type EmailAccountConnection = {
  __typename?: 'EmailAccountConnection';
  edges: Array<EmailAccountEdge>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/emails/management/Contract.proto */
export type EmailAccountEdge = {
  __typename?: 'EmailAccountEdge';
  cursor: Scalars['String']['output'];
  node: EmailAccount;
};

export type EmailAccountFilterInput = {
  companyId: InputMaybe<Scalars['ID']['input']>;
  ipPoolId: InputMaybe<Scalars['ID']['input']>;
  unscheduledWarmup: InputMaybe<Scalars['Boolean']['input']>;
};

export enum EmailAccountSort {
  EmailAccountSortLatest = 'EMAIL_ACCOUNT_SORT_LATEST',
  EmailAccountSortOldest = 'EMAIL_ACCOUNT_SORT_OLDEST',
  EmailAccountSortUnknown = 'EMAIL_ACCOUNT_SORT_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/solutions/bulkoptout/SolutionsOptout.proto */
export type EmailBulkOptOutJob = {
  __typename?: 'EmailBulkOptOutJob';
  companyName: Scalars['String']['output'];
  dateRange: Maybe<SolutionsDateRange>;
  jobId: Scalars['String']['output'];
  results: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/emailwarmups/Contract.proto */
export type EmailCampaignSchedulabilityStatus = {
  __typename?: 'EmailCampaignSchedulabilityStatus';
  canSchedule: Scalars['Boolean']['output'];
  warningMessage: ScheduleWarnings;
};

/** Generated from com/attentivemobile/contracts/emails/management/Contract.proto */
export type EmailCopyRecipient = {
  __typename?: 'EmailCopyRecipient';
  emailAddress: Scalars['String']['output'];
  recipientType: CopyRecipientType;
};

/** Generated from com/attentivemobile/contracts/emails/management/Contract.proto */
export type EmailIPAddress = Node & {
  __typename?: 'EmailIPAddress';
  assignedEmailIpPools: Maybe<EmailIPPoolsConnection>;
  /** @deprecated deprecated */
  assignedIpPool: EmailIPPool;
  /** @deprecated deprecated */
  assignedIpPools: Array<EmailIPPool>;
  domain: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  ipAddress: Scalars['String']['output'];
  verified: Scalars['Boolean']['output'];
  warmupDate: Maybe<Scalars['DateTime']['output']>;
  warmupStatus: EmailIPAddressWarmupStatus;
};


/** Generated from com/attentivemobile/contracts/emails/management/Contract.proto */
export type EmailIPAddressassignedEmailIpPoolsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  companyId: InputMaybe<Scalars['ID']['input']>;
  filter: InputMaybe<EmailIPPoolFilterInput>;
  first: InputMaybe<Scalars['Int']['input']>;
  ipPoolId: InputMaybe<Scalars['ID']['input']>;
  ipPoolName: InputMaybe<Scalars['String']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  poolType: InputMaybe<IPPoolType>;
  shared: InputMaybe<Scalars['Boolean']['input']>;
};

/** Generated from com/attentivemobile/contracts/emails/management/Contract.proto */
export type EmailIPAddressEdge = {
  __typename?: 'EmailIPAddressEdge';
  cursor: Scalars['String']['output'];
  node: EmailIPAddress;
};

export type EmailIPAddressFilterInput = {
  available: InputMaybe<Scalars['Boolean']['input']>;
  companyId: InputMaybe<Scalars['Long']['input']>;
  domains: InputMaybe<Array<Scalars['String']['input']>>;
  emailAccountId: InputMaybe<Scalars['Long']['input']>;
  ipAddress: InputMaybe<Scalars['String']['input']>;
  ipPoolId: InputMaybe<Scalars['Long']['input']>;
  ipPoolNames: InputMaybe<Array<Scalars['String']['input']>>;
  verified: InputMaybe<Scalars['Boolean']['input']>;
  warmupStatus: InputMaybe<EmailIPAddressWarmupStatus>;
};

export enum EmailIPAddressWarmupStatus {
  EmailIpAddressWarmupStatusDone = 'EMAIL_IP_ADDRESS_WARMUP_STATUS_DONE',
  EmailIpAddressWarmupStatusInProgress = 'EMAIL_IP_ADDRESS_WARMUP_STATUS_IN_PROGRESS',
  EmailIpAddressWarmupStatusNotStarted = 'EMAIL_IP_ADDRESS_WARMUP_STATUS_NOT_STARTED',
  EmailIpAddressWarmupStatusUnknown = 'EMAIL_IP_ADDRESS_WARMUP_STATUS_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/emails/management/Contract.proto */
export type EmailIPAddressesConnection = {
  __typename?: 'EmailIPAddressesConnection';
  edges: Array<EmailIPAddressEdge>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/emails/management/Contract.proto */
export type EmailIPAssignment = {
  __typename?: 'EmailIPAssignment';
  ip: Maybe<EmailIPAddress>;
  ipAddress: Scalars['String']['output'];
  ipPool: Maybe<EmailIPPool>;
};

export type EmailIPAssignmentInput = {
  ipAddress: Scalars['String']['input'];
  ipPoolId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/emails/management/Contract.proto */
export type EmailIPPool = Node & {
  __typename?: 'EmailIPPool';
  assignedIpAddresses: Maybe<EmailIPAddressesConnection>;
  companies: Maybe<CompanyConnection>;
  id: Scalars['ID']['output'];
  /** @deprecated deprecated */
  ipAddresses: Array<EmailIPAddress>;
  name: Scalars['String']['output'];
  shared: Scalars['Boolean']['output'];
  type: IPPoolType;
};


/** Generated from com/attentivemobile/contracts/emails/management/Contract.proto */
export type EmailIPPoolassignedIpAddressesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  available: InputMaybe<Scalars['Boolean']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  companyId: InputMaybe<Scalars['ID']['input']>;
  domains: InputMaybe<Array<Scalars['String']['input']>>;
  emailAccountId: InputMaybe<Scalars['ID']['input']>;
  filter: InputMaybe<EmailIPAddressFilterInput>;
  first: InputMaybe<Scalars['Int']['input']>;
  ipAddress: InputMaybe<Scalars['String']['input']>;
  ipPoolNames: InputMaybe<Array<Scalars['String']['input']>>;
  last: InputMaybe<Scalars['Int']['input']>;
  verified: InputMaybe<Scalars['Boolean']['input']>;
  warmupStatus: InputMaybe<EmailIPAddressWarmupStatus>;
};


/** Generated from com/attentivemobile/contracts/emails/management/Contract.proto */
export type EmailIPPoolcompaniesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** Generated from com/attentivemobile/contracts/emails/management/Contract.proto */
export type EmailIPPoolEdge = {
  __typename?: 'EmailIPPoolEdge';
  cursor: Scalars['String']['output'];
  node: EmailIPPool;
};

export type EmailIPPoolFilterInput = {
  companyId: InputMaybe<Scalars['ID']['input']>;
  ipAddress: InputMaybe<Scalars['String']['input']>;
  ipPoolId: InputMaybe<Scalars['ID']['input']>;
  ipPoolName: InputMaybe<Scalars['String']['input']>;
  poolType: InputMaybe<IPPoolType>;
  shared: InputMaybe<Scalars['Boolean']['input']>;
};

/** Generated from com/attentivemobile/contracts/emails/management/Contract.proto */
export type EmailIPPoolsConnection = {
  __typename?: 'EmailIPPoolsConnection';
  edges: Array<EmailIPPoolEdge>;
  pageInfo: PageInfo;
};

export enum EmailIntegrationLevel {
  Off = 'OFF',
  TriggeredAndCampaignEmail = 'TRIGGERED_AND_CAMPAIGN_EMAIL',
  TriggeredEmailOnly = 'TRIGGERED_EMAIL_ONLY'
}

/** Generated from com/attentivemobile/contracts/emails/management/Contract.proto */
export type EmailManagementCsvResponse = {
  __typename?: 'EmailManagementCsvResponse';
  csvUrl: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/emails/management/Contract.proto */
export type EmailMessagingTypeIPPools = {
  __typename?: 'EmailMessagingTypeIPPools';
  campaignsPool: EmailIPPool;
  transactionalPool: EmailIPPool;
  triggeredPool: EmailIPPool;
};

/** Generated from com/attentivemobile/contracts/messaging/automation/Contract.proto */
export type EmailNotificationSettings = {
  __typename?: 'EmailNotificationSettings';
  emailRecipients: Array<EmailRecipient>;
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type EmailOpened = {
  __typename?: 'EmailOpened';
  campaignName: Scalars['String']['output'];
  /** @deprecated deprecated */
  created: Scalars['DateTime']['output'];
  emailId: Maybe<EmailOpenedEmailId>;
  /** @deprecated deprecated */
  id: Scalars['String']['output'];
  vendor: Scalars['String']['output'];
};

export type EmailOpenedEmailId = MessageID | ThirdPartyMessageId;

export type EmailPoolAssignedCompanies = {
  companyInternalIds: InputMaybe<Array<Scalars['Long']['input']>>;
};

export type EmailPoolAssignedIPAddresses = {
  ips: InputMaybe<Array<Scalars['String']['input']>>;
};

export type EmailPreviewInput = {
  body: Scalars['String']['input'];
  preheaderText: InputMaybe<Scalars['String']['input']>;
  subject: Scalars['String']['input'];
};

export type EmailPreviewPropertiesInput = {
  fromEmail: Scalars['String']['input'];
  fromName: Scalars['String']['input'];
  htmlContent: Scalars['String']['input'];
  preheader: InputMaybe<Scalars['String']['input']>;
  replyToEmail: Scalars['String']['input'];
  subject: Scalars['String']['input'];
};

export type EmailPreviewRecipientsInput = {
  emailAddresses: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto */
export type EmailProperties = {
  __typename?: 'EmailProperties';
  /** @deprecated deprecated */
  beeEditorJson: Maybe<JsonString>;
  editorSource: EditorSource;
  emailTemplateInstanceId: Scalars['String']['output'];
  fromEmail: Scalars['String']['output'];
  fromName: Scalars['String']['output'];
  gmailOfferAnnotation: Maybe<GmailOfferAnnotation>;
  /** @deprecated deprecated */
  htmlContent: Maybe<Scalars['String']['output']>;
  htmlContentUrl: Url;
  messageEditorProperties: MessageEditorProperties;
  preheaderText: Maybe<Scalars['String']['output']>;
  replyToEmail: Scalars['String']['output'];
  subject: Scalars['String']['output'];
};

export type EmailPropertiesRequest = {
  /** @deprecated deprecated */
  beeEditorJson: InputMaybe<JsonStringInput>;
  dragAndDropEditorPropertiesRequest: InputMaybe<DragAndDropEditorPropertiesInput>;
  editorSource: EditorSource;
  fromEmail: InputMaybe<Scalars['String']['input']>;
  fromName: InputMaybe<Scalars['String']['input']>;
  gmailOfferAnnotation: InputMaybe<GmailOfferAnnotationRequest>;
  htmlEditorPropertiesRequest: InputMaybe<HtmlEditorPropertiesInput>;
  preheaderText: InputMaybe<Scalars['String']['input']>;
  replyToEmail: InputMaybe<Scalars['String']['input']>;
  subject: InputMaybe<Scalars['String']['input']>;
};

export enum EmailProvider {
  EmailProviderSendgrid = 'EMAIL_PROVIDER_SENDGRID',
  EmailProviderSfmc = 'EMAIL_PROVIDER_SFMC',
  EmailProviderUnknown = 'EMAIL_PROVIDER_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/messaging/automation/Contract.proto */
export type EmailRecipient = {
  __typename?: 'EmailRecipient';
  email: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type EmailSent = {
  __typename?: 'EmailSent';
  campaignName: Scalars['String']['output'];
  /** @deprecated deprecated */
  created: Scalars['DateTime']['output'];
  emailId: Maybe<EmailSentEmailId>;
  /** @deprecated deprecated */
  id: Scalars['String']['output'];
  vendor: Scalars['String']['output'];
};

export type EmailSentEmailId = MessageID | ThirdPartyMessageId;

/** Generated from com/attentivemobile/contracts/emails/management/Contract.proto */
export type EmailSettings = {
  __typename?: 'EmailSettings';
  emailCopyRecipients: Array<EmailCopyRecipient>;
  enableCopyRecipientsForCampaigns: Maybe<Scalars['Boolean']['output']>;
  enableCopyRecipientsForJourneys: Maybe<Scalars['Boolean']['output']>;
  linkSubdomain: Scalars['String']['output'];
};

export type EmailSubjectAndPreviewContentInput = {
  previewText: InputMaybe<Scalars['String']['input']>;
  subjectLine: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/protobuf/common/compose/Message.proto */
export type EmailTemplate = {
  __typename?: 'EmailTemplate';
  htmlContentUrl: Scalars['String']['output'];
  htmlPreviewUrl: Scalars['String']['output'];
  jsonContent: Scalars['String']['output'];
  /** @deprecated deprecated */
  jsonContentUrl: Scalars['String']['output'];
  subject: Scalars['String']['output'];
};

export type EmailTemplateInput = {
  htmlContent: Scalars['String']['input'];
  jsonContent: JsonStringInput;
  templateType: MessagingTemplateType;
};

export type EmptyCartInput = {
  sessionId: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/concierge/service/ShoppingCart.proto */
export type EmptyConciergeCartPayload = {
  __typename?: 'EmptyConciergeCartPayload';
  cart: SavedConciergeCart;
};

/** Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto */
export type EmptyEmailSubject = {
  __typename?: 'EmptyEmailSubject';
  message: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto */
export type EmptyMessageBody = {
  __typename?: 'EmptyMessageBody';
  body: Scalars['String']['output'];
};

export type EnableAutomationProgramInput = {
  automationProgramId: Scalars['ID']['input'];
  companyId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/messaging/automation/Contract.proto */
export type EnableAutomationProgramPayload = {
  __typename?: 'EnableAutomationProgramPayload';
  automationProgram: AutomationProgram;
};

export type EnableCustomCompanyKeywordInput = {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  shouldEnable: Scalars['Boolean']['input'];
};

export type EnableCustomCompanyKeywordPayload = DefaultErrorFailure | EnableCustomCompanyKeywordSuccess;

/** Generated from com/attentivemobile/contracts/keywords/KeywordService.proto */
export type EnableCustomCompanyKeywordSuccess = {
  __typename?: 'EnableCustomCompanyKeywordSuccess';
  keyword: CustomCompanyKeyword;
};

export type EnableSendScheduledReportOverSftpInput = {
  active: InputMaybe<Scalars['Boolean']['input']>;
  companyId: Scalars['ID']['input'];
  convertCsv: InputMaybe<Scalars['Boolean']['input']>;
  filename: InputMaybe<Scalars['String']['input']>;
  reportSystem: InputMaybe<Scalars['String']['input']>;
  /** @deprecated deprecated */
  s3Path: InputMaybe<Scalars['String']['input']>;
  scheduledReportId: Scalars['Long']['input'];
  scheduledReportIds: InputMaybe<Array<Scalars['Long']['input']>>;
  sftpHost: InputMaybe<Scalars['String']['input']>;
  sftpPassword: InputMaybe<Scalars['String']['input']>;
  sftpPath: InputMaybe<Scalars['String']['input']>;
  sftpUser: InputMaybe<Scalars['String']['input']>;
  /** @deprecated deprecated */
  sharedS3Sftp: InputMaybe<Scalars['String']['input']>;
};

/** Generated from com/attentivemobile/contracts/solutions/sftp/Sftp.proto */
export type EnableSendScheduledReportOverSftpPayload = {
  __typename?: 'EnableSendScheduledReportOverSftpPayload';
  company: Maybe<Company>;
  unscheduledReportIds: Array<Scalars['Long']['output']>;
};

export type EnableStandardSftpReportsInput = {
  companyId: Scalars['ID']['input'];
  features: InputMaybe<Array<SftpReportFeature>>;
  fileName: InputMaybe<Scalars['String']['input']>;
  fileS3Path: InputMaybe<Scalars['String']['input']>;
  isTripleWhale: InputMaybe<Scalars['Boolean']['input']>;
  sftpHost: InputMaybe<Scalars['String']['input']>;
  sftpPassword: InputMaybe<Scalars['String']['input']>;
  sftpPath: InputMaybe<Scalars['String']['input']>;
  sftpUser: InputMaybe<Scalars['String']['input']>;
  typeFilter: InputMaybe<Array<EventType>>;
  useSftpKey: InputMaybe<Scalars['Boolean']['input']>;
};

/** Generated from com/attentivemobile/contracts/solutions/sftp/Sftp.proto */
export type EnableStandardSftpReportsPayload = {
  __typename?: 'EnableStandardSftpReportsPayload';
  company: Maybe<Company>;
};

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type Encryption = {
  __typename?: 'Encryption';
  passphrase: Maybe<Scalars['String']['output']>;
  privateKey: PrivateKey;
  type: EncryptionType;
};

export type EncryptionInput = {
  passphrase: InputMaybe<Scalars['String']['input']>;
  privateKey: PrivateKeyInput;
  type: EncryptionType;
};

export enum EncryptionType {
  EncryptionTypePgp = 'ENCRYPTION_TYPE_PGP',
  EncryptionTypeUnknown = 'ENCRYPTION_TYPE_UNKNOWN'
}

export type EndCreativeExperimentInput = {
  companyId: Scalars['ID']['input'];
  experimentId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/creative-service/experiment/CreativeExperimentService.proto */
export type EndCreativeExperimentPayload = {
  __typename?: 'EndCreativeExperimentPayload';
  experiment: CreativeExperiment;
};

/** Generated from com/attentivemobile/protobuf/common/Effect.proto */
export type EnhancementEffect = {
  __typename?: 'EnhancementEffect';
  change: EnhancementMetricChange;
  metric: EnhancementMetric;
};

export enum EnhancementMetric {
  EnhancementMetricRev = 'ENHANCEMENT_METRIC_REV',
  EnhancementMetricRoi = 'ENHANCEMENT_METRIC_ROI',
  EnhancementMetricUnknown = 'ENHANCEMENT_METRIC_UNKNOWN'
}

export enum EnhancementMetricChange {
  EnhancementMetricChangeHigher = 'ENHANCEMENT_METRIC_CHANGE_HIGHER',
  EnhancementMetricChangeLower = 'ENHANCEMENT_METRIC_CHANGE_LOWER',
  EnhancementMetricChangeMaximized = 'ENHANCEMENT_METRIC_CHANGE_MAXIMIZED',
  EnhancementMetricChangeSimilar = 'ENHANCEMENT_METRIC_CHANGE_SIMILAR',
  EnhancementMetricChangeUnknown = 'ENHANCEMENT_METRIC_CHANGE_UNKNOWN'
}

export enum EnhancementType {
  EnhancementTypeBlended = 'ENHANCEMENT_TYPE_BLENDED',
  EnhancementTypeExclusion = 'ENHANCEMENT_TYPE_EXCLUSION',
  EnhancementTypeRevenueBoosting = 'ENHANCEMENT_TYPE_REVENUE_BOOSTING',
  EnhancementTypeRevenueMaximizing = 'ENHANCEMENT_TYPE_REVENUE_MAXIMIZING',
  EnhancementTypeRoiBoosting = 'ENHANCEMENT_TYPE_ROI_BOOSTING',
  EnhancementTypeRoiMaximizing = 'ENHANCEMENT_TYPE_ROI_MAXIMIZING',
  EnhancementTypeUnknown = 'ENHANCEMENT_TYPE_UNKNOWN'
}

export type EnqueueSuppressionJobInput = {
  fileId: Scalars['ID']['input'];
  removeSuppression: Scalars['Boolean']['input'];
};

/** Generated from com/attentivemobile/contracts/subscriptions/batch/Contract.proto */
export type EnqueueSuppressionJobPayload = {
  __typename?: 'EnqueueSuppressionJobPayload';
  fileId: Scalars['String']['output'];
};

export type EnqueueUserFileDownloadInput = {
  companyId: Scalars['ID']['input'];
  filter: UserFileDownloadFilterInput;
  userFields: InputMaybe<Array<UserFileDownloadField>>;
};

/** Generated from com/attentivemobile/contracts/user/profile/download/Contract.proto */
export type EnqueueUserFileDownloadPayload = {
  __typename?: 'EnqueueUserFileDownloadPayload';
  file: Maybe<UserFileDownload>;
};

/** Generated from com/attentivemobile/contracts/event/platform/registration/service/Transformation.proto */
export type EnrichedField = {
  __typename?: 'EnrichedField';
  fieldName: Scalars['String']['output'];
  targetFieldPath: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/event/platform/registration/service/Transformation.proto */
export type Enricher = {
  __typename?: 'Enricher';
  enrichment: Enrichment;
  eventTypes: Array<EventType>;
  needEnrichment: Maybe<NeedEnrichment>;
};

/** Generated from com/attentivemobile/contracts/event/platform/registration/service/Transformation.proto */
export type Enrichment = {
  __typename?: 'Enrichment';
  dataSource: DataSource;
  dependentFields: Array<DependentField>;
  enrichedFields: Array<EnrichedField>;
};

/** Generated from com/attentivemobile/contracts/solutions/sftp/EtlConfig.proto */
export type EnrichmentTriggers = {
  __typename?: 'EnrichmentTriggers';
  channelData: Array<ChannelDataEntry>;
  /** @deprecated deprecated */
  doubleoptSignupurl: Maybe<Scalars['Boolean']['output']>;
  exportAnonId: Maybe<Scalars['Boolean']['output']>;
  filterKlaviyo: Maybe<Scalars['Boolean']['output']>;
  localeUpdatedEvents: Maybe<Scalars['Boolean']['output']>;
  timezone: Maybe<Scalars['String']['output']>;
  useAttributableConversions: Maybe<Scalars['Boolean']['output']>;
  useFilteredEvents: Maybe<Scalars['Boolean']['output']>;
};

export type EnrichmentTriggersInput = {
  channelDataInput: InputMaybe<Array<ChannelDataInputEntry>>;
  /** @deprecated deprecated */
  doubleoptSignupurl: InputMaybe<Scalars['Boolean']['input']>;
  exportAnonId: InputMaybe<Scalars['Boolean']['input']>;
  filterKlaviyo: InputMaybe<Scalars['Boolean']['input']>;
  localeUpdatedEvents: InputMaybe<Scalars['Boolean']['input']>;
  timezone: InputMaybe<Scalars['String']['input']>;
  useAttributableConversions: InputMaybe<Scalars['Boolean']['input']>;
  useFilteredEvents: InputMaybe<Scalars['Boolean']['input']>;
};

export type EnterConciergeChatInput = {
  chatId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/concierge/service/Chat.proto */
export type EnterConciergeChatPayload = {
  __typename?: 'EnterConciergeChatPayload';
  chatId: Scalars['ID']['output'];
};

/** Generated from com/attentivemobile/contracts/tag/service/Contract.proto */
export type EntityTagAssigmentPayload = {
  __typename?: 'EntityTagAssigmentPayload';
  taggableEntity: TaggableEntity;
};

export enum EntityType {
  EntityTypeCompanyApplication = 'ENTITY_TYPE_COMPANY_APPLICATION',
  EntityTypeCompanyUpload = 'ENTITY_TYPE_COMPANY_UPLOAD',
  EntityTypeCompanyVendorIntegration = 'ENTITY_TYPE_COMPANY_VENDOR_INTEGRATION',
  EntityTypeConversational = 'ENTITY_TYPE_CONVERSATIONAL',
  EntityTypeCreative = 'ENTITY_TYPE_CREATIVE',
  EntityTypeJourneys = 'ENTITY_TYPE_JOURNEYS',
  EntityTypePublicApiApplication = 'ENTITY_TYPE_PUBLIC_API_APPLICATION',
  EntityTypeSendService = 'ENTITY_TYPE_SEND_SERVICE',
  EntityTypeShopifyServerPixel = 'ENTITY_TYPE_SHOPIFY_SERVER_PIXEL',
  EntityTypeTag = 'ENTITY_TYPE_TAG',
  EntityTypeUnknown = 'ENTITY_TYPE_UNKNOWN'
}

export type EntryClassArgsEntry = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/composition/MessageAttachment.proto */
export type EnumMacroBehaviorConfig = {
  __typename?: 'EnumMacroBehaviorConfig';
  enumeratedValues: Array<Scalars['String']['output']>;
};

/** Generated from com/attentivemobile/protobuf/common/targeting/UserProperty.proto */
export type EnumeratedPropertyDefinition = {
  __typename?: 'EnumeratedPropertyDefinition';
  enumeratedValues: Array<EnumeratedValue>;
};

/** Generated from com/attentivemobile/protobuf/common/targeting/UserProperty.proto */
export type EnumeratedPropertyValueID = {
  __typename?: 'EnumeratedPropertyValueID';
  id: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/protobuf/common/targeting/UserProperty.proto */
export type EnumeratedValue = {
  __typename?: 'EnumeratedValue';
  isDeleted: Scalars['Boolean']['output'];
  value: Scalars['String']['output'];
  valueId: EnumeratedPropertyValueID;
};

/** Generated from com/attentivemobile/contracts/user/profile/property/UserPropertyV2.proto */
export type EnumeratedValueV2 = {
  __typename?: 'EnumeratedValueV2';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type ErrorReport = {
  __typename?: 'ErrorReport';
  count: Scalars['Long']['output'];
  error: Scalars['String']['output'];
  statusCode: Scalars['Long']['output'];
  timestamp: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/event/platform/batchjobs/service/Contract.proto */
export type ErroredJob = {
  __typename?: 'ErroredJob';
  cluster: Cluster;
  datastreamName: Scalars['String']['output'];
  errorMessage: Scalars['String']['output'];
  jobId: Scalars['String']['output'];
  latestCheckpoint: Checkpoint;
};

/** Generated from com/attentivemobile/contracts/concierge/service/Escalation.proto */
export type EscalationConnection = {
  __typename?: 'EscalationConnection';
  edges: Array<EscalationEdge>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/concierge/service/Escalation.proto */
export type EscalationEdge = {
  __typename?: 'EscalationEdge';
  cursor: Scalars['String']['output'];
  node: UserConversationEscalation;
};

export type EscalationsFilter = {
  end: InputMaybe<Scalars['DateTime']['input']>;
  start: InputMaybe<Scalars['DateTime']['input']>;
};

export type EscalationsSort = {
  sortColumn: EscalationsSortColumn;
  sortDirection: EscalationsSortDirection;
};

export enum EscalationsSortColumn {
  EscalationsSortColumnDateTimestamp = 'ESCALATIONS_SORT_COLUMN_DATE_TIMESTAMP',
  EscalationsSortColumnUnknownColumn = 'ESCALATIONS_SORT_COLUMN_UNKNOWN_COLUMN'
}

export enum EscalationsSortDirection {
  EscalationsSortDirectionAsc = 'ESCALATIONS_SORT_DIRECTION_ASC',
  EscalationsSortDirectionDesc = 'ESCALATIONS_SORT_DIRECTION_DESC',
  EscalationsSortUnknownDirection = 'ESCALATIONS_SORT_UNKNOWN_DIRECTION'
}

/** Generated from com/attentivemobile/contracts/campaigns/service/Stats.proto */
export type EstimatedRecipientCount = {
  __typename?: 'EstimatedRecipientCount';
  count: Scalars['Int']['output'];
  countNotFatigued: Scalars['Int']['output'];
};

/** Generated from com/attentivemobile/contracts/solutions/sftp/EtlConfig.proto */
export type EtlConfig = {
  __typename?: 'EtlConfig';
  associatedCompanies: Array<AssociatedCompanies>;
  company: Maybe<Company>;
  enabled: Maybe<EtlEnabled>;
  etlConfigId: Scalars['String']['output'];
  etlConfigStaticValue: Array<EtlConfigStaticValue>;
  etlReportMetadata: Maybe<EtlReportMetadata>;
  eventTypes: Array<EventType>;
  externalSftpConfig: Maybe<ExternalSftpConfig>;
  features: Array<EtlConfigFeature>;
};

/** Generated from com/attentivemobile/contracts/solutions/sftp/EtlConfig.proto */
export type EtlConfigConnection = {
  __typename?: 'EtlConfigConnection';
  edges: Array<EtlConfigEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Generated from com/attentivemobile/contracts/solutions/sftp/EtlConfig.proto */
export type EtlConfigEdge = {
  __typename?: 'EtlConfigEdge';
  cursor: Scalars['String']['output'];
  node: EtlConfig;
};

export enum EtlConfigFeature {
  EtlConfigFeatureConversions = 'ETL_CONFIG_FEATURE_CONVERSIONS',
  EtlConfigFeatureGeneralDataFeed = 'ETL_CONFIG_FEATURE_GENERAL_DATA_FEED'
}

/** Generated from com/attentivemobile/contracts/solutions/sftp/EtlConfig.proto */
export type EtlConfigStaticValue = {
  __typename?: 'EtlConfigStaticValue';
  columnName: Scalars['String']['output'];
  isEnabled: Scalars['Boolean']['output'];
  staticValueId: Maybe<Scalars['String']['output']>;
  valueToPopulate: Scalars['String']['output'];
};

export type EtlConfigStaticValueInput = {
  columnName: Scalars['String']['input'];
  isEnabled: Scalars['Boolean']['input'];
  staticValueId: InputMaybe<Scalars['String']['input']>;
  valueToPopulate: Scalars['String']['input'];
};

export enum EtlEnabled {
  CustomDag = 'CUSTOM_DAG',
  Disabled = 'DISABLED',
  Enabled = 'ENABLED'
}

/** Generated from com/attentivemobile/contracts/solutions/sftp/EtlConfig.proto */
export type EtlReportMetadata = {
  __typename?: 'EtlReportMetadata';
  autoIngested: Maybe<Scalars['Boolean']['output']>;
  columns: Maybe<Columns>;
  csvFormatting: Maybe<CsvFormatting>;
  enrichmentTriggers: Maybe<EnrichmentTriggers>;
  isEncrypted: Maybe<Scalars['Boolean']['output']>;
  paths: Maybe<Paths>;
  /** @deprecated deprecated */
  publicKeyFingerprint: Maybe<Scalars['String']['output']>;
  rollup: Maybe<Scalars['Boolean']['output']>;
  rollupAndIndividualFiles: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated deprecated */
  runEtl: Maybe<Scalars['String']['output']>;
};

export type EtlReportMetadataInput = {
  autoIngested: InputMaybe<Scalars['Boolean']['input']>;
  columns: InputMaybe<ColumnsInput>;
  csvFormatting: InputMaybe<CsvFormattingInput>;
  enrichmentTriggers: InputMaybe<EnrichmentTriggersInput>;
  isEncrypted: InputMaybe<Scalars['Boolean']['input']>;
  paths: InputMaybe<PathsInput>;
  /** @deprecated deprecated */
  pgpKeyString: InputMaybe<Scalars['String']['input']>;
  /** @deprecated deprecated */
  publicKeyFingerprint: InputMaybe<Scalars['String']['input']>;
  rollup: InputMaybe<Scalars['Boolean']['input']>;
  rollupAndIndividualFiles: InputMaybe<Scalars['Boolean']['input']>;
  /** @deprecated deprecated */
  runEtl: InputMaybe<Scalars['String']['input']>;
};

/** Generated from com/attentivemobile/contracts/composition/Contract.proto */
export type EvaluateMessagePreviewPayload = {
  __typename?: 'EvaluateMessagePreviewPayload';
  evaluatedChannelResponse: EvaluatedChannelResponse;
};

export type EvaluatedChannelResponse = EvaluatedEmailResponse | EvaluatedTextResponse;

/** Generated from com/attentivemobile/contracts/composition/Contract.proto */
export type EvaluatedEmailResponse = {
  __typename?: 'EvaluatedEmailResponse';
  evaluatedBody: Scalars['String']['output'];
  evaluatedPreheaderText: Maybe<Scalars['String']['output']>;
  evaluatedSubject: Scalars['String']['output'];
  macrosWithoutContent: Array<Scalars['String']['output']>;
};

/** Generated from com/attentivemobile/contracts/composition/Contract.proto */
export type EvaluatedTextResponse = {
  __typename?: 'EvaluatedTextResponse';
  evaluatedBody: Scalars['String']['output'];
  evaluatedMediaUrl: Maybe<Scalars['String']['output']>;
};

/** Generated from com/attentivemobile/contracts/event/platform/registration/service/Datastream.proto */
export type EventBackup = {
  __typename?: 'EventBackup';
  s3Location: S3Location;
};

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type EventDestinationNode = {
  __typename?: 'EventDestinationNode';
  authorId: Scalars['Long']['output'];
  collection: Maybe<NodeCollection>;
  collectionId: Scalars['Long']['output'];
  created: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  jobCount: Maybe<Scalars['Long']['output']>;
  metadata: Scalars['JSON']['output'];
  name: Scalars['String']['output'];
  nodeType: Maybe<Scalars['String']['output']>;
  nodeTypeId: Scalars['Long']['output'];
  tags: Array<Scalars['String']['output']>;
  updated: Scalars['DateTime']['output'];
  uuid: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type EventDestinationNodeConnection = {
  __typename?: 'EventDestinationNodeConnection';
  edges: Array<EventDestinationNodeEdge>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type EventDestinationNodeEdge = {
  __typename?: 'EventDestinationNodeEdge';
  cursor: Scalars['String']['output'];
  node: EventDestinationNode;
};

/** Generated from com/attentivemobile/contracts/eventdestination/task/service/Contract.proto */
export type EventDestinationTask = {
  __typename?: 'EventDestinationTask';
  company: Maybe<Company>;
  created: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  jobType: Maybe<Scalars['String']['output']>;
  lastExecutedRun: Maybe<EventDestinationTaskHistory>;
  name: Maybe<Scalars['String']['output']>;
  nextRun: Maybe<Scalars['DateTime']['output']>;
  previousRun: Maybe<Scalars['DateTime']['output']>;
  sourceInfo: Maybe<Scalars['String']['output']>;
  sourceType: Maybe<Scalars['String']['output']>;
  status: Maybe<Scalars['String']['output']>;
  targetInfo: Maybe<Scalars['String']['output']>;
  targetType: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/eventdestination/task/service/Contract.proto */
export type EventDestinationTaskEdge = {
  __typename?: 'EventDestinationTaskEdge';
  cursor: Scalars['String']['output'];
  node: EventDestinationTask;
};

/** Generated from com/attentivemobile/contracts/eventdestination/task/service/Contract.proto */
export type EventDestinationTaskHistory = {
  __typename?: 'EventDestinationTaskHistory';
  context: Maybe<Scalars['JSON']['output']>;
  parentTaskId: Maybe<Scalars['String']['output']>;
  runDate: Maybe<Scalars['DateTime']['output']>;
  scheduledDate: Scalars['DateTime']['output'];
  status: Scalars['String']['output'];
  taskId: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

export type EventDestinationTasksFilter = {
  companyId: InputMaybe<Scalars['ID']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
};

/** Generated from com/attentivemobile/contracts/eventdestination/task/service/Contract.proto */
export type EventDestinationTasksPayload = {
  __typename?: 'EventDestinationTasksPayload';
  edges: Array<EventDestinationTaskEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Long']['output'];
};

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type EventDestinations = {
  __typename?: 'EventDestinations';
  destinations: Maybe<NodeCollectionConnection>;
  errorReportsByRun: Maybe<JobErrorReportResponse>;
  job: Maybe<JobRecord>;
  jobHistory: Maybe<JobHistoryConnection>;
  jobHistoryV2: Maybe<JobHistoryConnection>;
  jobHistoryV3: Maybe<EDSJobHistoryConnection>;
  jobReport: Maybe<GetJobReportResponse>;
  jobReports: Maybe<JobReportsConnection>;
  jobs: Maybe<JobRecordConnection>;
  node: Maybe<EventDestinationNode>;
  nodes: Maybe<EventDestinationNodeConnection>;
  nodesByTag: Maybe<EventDestinationNodeConnection>;
  runReportsV2: Maybe<GetRunReportsResponse>;
};


/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type EventDestinationsdestinationsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type EventDestinationserrorReportsByRunArgs = {
  end: InputMaybe<Scalars['String']['input']>;
  jobUuid: Scalars['String']['input'];
  start: InputMaybe<Scalars['String']['input']>;
};


/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type EventDestinationsjobArgs = {
  jobRecordUuid: Scalars['String']['input'];
};


/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type EventDestinationsjobHistoryArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  taskId: Scalars['String']['input'];
};


/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type EventDestinationsjobHistoryV2Args = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  taskId: Scalars['String']['input'];
};


/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type EventDestinationsjobHistoryV3Args = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  end: Scalars['DateTime']['input'];
  excludeNoop: InputMaybe<Scalars['Boolean']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  start: Scalars['DateTime']['input'];
  taskId: Scalars['String']['input'];
};


/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type EventDestinationsjobReportArgs = {
  end: InputMaybe<Scalars['DateTime']['input']>;
  runId: InputMaybe<Scalars['String']['input']>;
  start: InputMaybe<Scalars['DateTime']['input']>;
  taskId: Scalars['String']['input'];
};


/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type EventDestinationsjobReportsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  end: Scalars['DateTime']['input'];
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  start: Scalars['DateTime']['input'];
  taskIds: InputMaybe<Array<Scalars['String']['input']>>;
};


/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type EventDestinationsjobsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<GetJobRecordsFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  nodeUuid: InputMaybe<Scalars['String']['input']>;
};


/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type EventDestinationsnodeArgs = {
  nodeUuid: Scalars['String']['input'];
};


/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type EventDestinationsnodesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  collectionId: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type EventDestinationsnodesByTagArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  tag: Scalars['String']['input'];
};


/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type EventDestinationsrunReportsV2Args = {
  companyId: Scalars['ID']['input'];
  lastXOffset: InputMaybe<Scalars['Int']['input']>;
  lastXRuns: Scalars['Int']['input'];
  taskUuid: Scalars['String']['input'];
  topicType: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/composition/MessageAttachment.proto */
export type EventFilter = {
  __typename?: 'EventFilter';
  timeCondition: TimeConditionOutput;
};

/** Generated from com/attentivemobile/contracts/composition/MessageAttachment.proto */
export type EventLookupAttachment = {
  __typename?: 'EventLookupAttachment';
  application: Maybe<Application>;
  entityToken: Scalars['String']['output'];
  eventFilter: Maybe<EventFilter>;
  eventLookupType: EventLookupType;
  eventType: Scalars['String']['output'];
  isCustom: Maybe<Scalars['Boolean']['output']>;
};

export type EventLookupAttachmentProperties = {
  applicationId: InputMaybe<Scalars['ID']['input']>;
  entityToken: Scalars['String']['input'];
  eventLookupType: EventLookupType;
  eventType: Scalars['String']['input'];
  isCustom: InputMaybe<Scalars['Boolean']['input']>;
  timeCondition: InputMaybe<TimeCondition>;
};

export enum EventLookupType {
  EventLookupTypeByUuid = 'EVENT_LOOKUP_TYPE_BY_UUID',
  EventLookupTypeDynamicFilters = 'EVENT_LOOKUP_TYPE_DYNAMIC_FILTERS',
  EventLookupTypeUnknown = 'EVENT_LOOKUP_TYPE_UNKNOWN'
}

/** Generated from com/attentivemobile/protobuf/common/EventOrigin.proto */
export type EventOrigin = {
  __typename?: 'EventOrigin';
  entity: Maybe<EventOriginEntity>;
};

export type EventOriginEntity = ApplicationOrigin | VendorOrigin;

export type EventPropertiesFilter = {
  propertyName: Scalars['String']['input'];
  stringCondition: InputMaybe<StringCondition>;
};

/** Generated from com/attentivemobile/contracts/tactical/eventreplay/EventReplayService.proto */
export type EventReplay = Node & {
  __typename?: 'EventReplay';
  company: Maybe<Company>;
  created: Scalars['DateTime']['output'];
  description: Maybe<Scalars['String']['output']>;
  estimatedEventCounts: Scalars['String']['output'];
  eventRangeEnd: Scalars['DateTime']['output'];
  eventRangeStart: Scalars['DateTime']['output'];
  eventReplayRunDetails: Maybe<EventReplayRunDetails>;
  eventTypes: Array<EventReplayEventType>;
  id: Scalars['ID']['output'];
  status: EventReplayStatus;
  title: Maybe<Scalars['String']['output']>;
  updated: Scalars['DateTime']['output'];
  user: Maybe<Account>;
};

export enum EventReplayEventType {
  EmailSave = 'EMAIL_SAVE',
  Join = 'JOIN',
  JourneyPostback = 'JOURNEY_POSTBACK',
  MessageLinkClick = 'MESSAGE_LINK_CLICK',
  MessageReceipt = 'MESSAGE_RECEIPT',
  SubscriptionAlreadySubscribed = 'SUBSCRIPTION_ALREADY_SUBSCRIBED',
  SubscriptionOptedOut = 'SUBSCRIPTION_OPTED_OUT',
  SubscriptionOptInCompleted = 'SUBSCRIPTION_OPT_IN_COMPLETED',
  SubscriptionOptInStarted = 'SUBSCRIPTION_OPT_IN_STARTED',
  UserPropertySet = 'USER_PROPERTY_SET'
}

/** Generated from com/attentivemobile/contracts/tactical/eventreplay/EventReplayService.proto */
export type EventReplayFailures = {
  __typename?: 'EventReplayFailures';
  company: Maybe<Company>;
  errorMessage: Maybe<Scalars['String']['output']>;
  eventReplay: Maybe<EventReplay>;
};

/** Generated from com/attentivemobile/contracts/tactical/eventreplay/EventReplayService.proto */
export type EventReplayRunDetails = {
  __typename?: 'EventReplayRunDetails';
  eventCounts: Scalars['String']['output'];
  id: Scalars['String']['output'];
  runDateEnd: Scalars['DateTime']['output'];
  runDateStart: Scalars['DateTime']['output'];
};

export enum EventReplayStatus {
  Analyzing = 'ANALYZING',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING',
  Queued = 'QUEUED',
  Ready = 'READY'
}

/** Generated from com/attentivemobile/contracts/tactical/eventreplay/EventReplayService.proto */
export type EventReplaysResponse = {
  __typename?: 'EventReplaysResponse';
  eventReplay: Array<EventReplay>;
};

/** Generated from com/attentivemobile/contracts/tagbuildservice/taghealth/Contract.proto */
export type EventStats = {
  __typename?: 'EventStats';
  categoryCount: Scalars['Long']['output'];
  emailCount: Scalars['Long']['output'];
  entryDate: Scalars['String']['output'];
  eventCount: Scalars['Long']['output'];
  matchesProductDataCount: Scalars['Long']['output'];
  nameCount: Scalars['Long']['output'];
  phoneCount: Scalars['Long']['output'];
  productMatchRate: Scalars['Float']['output'];
  skuCount: Scalars['Long']['output'];
  subscriberCount: Scalars['Long']['output'];
  updated: Scalars['DateTime']['output'];
};

export enum EventType {
  AddToCart = 'ADD_TO_CART',
  Alert = 'ALERT',
  AttentiveCdc = 'ATTENTIVE_CDC',
  CampaignCancelled = 'CAMPAIGN_CANCELLED',
  CampaignCreated = 'CAMPAIGN_CREATED',
  CampaignDeleted = 'CAMPAIGN_DELETED',
  CampaignDowngraded = 'CAMPAIGN_DOWNGRADED',
  CampaignDrafted = 'CAMPAIGN_DRAFTED',
  CampaignMmsRateLimitUpdated = 'CAMPAIGN_MMS_RATE_LIMIT_UPDATED',
  CampaignNeedsCoupons = 'CAMPAIGN_NEEDS_COUPONS',
  CampaignPaused = 'CAMPAIGN_PAUSED',
  CampaignPawAudienceSizeZero = 'CAMPAIGN_PAW_AUDIENCE_SIZE_ZERO',
  CampaignPickAWinnerFailed = 'CAMPAIGN_PICK_A_WINNER_FAILED',
  CampaignRateLimitUpdated = 'CAMPAIGN_RATE_LIMIT_UPDATED',
  CampaignReplayConfirmed = 'CAMPAIGN_REPLAY_CONFIRMED',
  CampaignReplayRequested = 'CAMPAIGN_REPLAY_REQUESTED',
  CampaignRescheduled = 'CAMPAIGN_RESCHEDULED',
  CampaignResumed = 'CAMPAIGN_RESUMED',
  CampaignSendFinished = 'CAMPAIGN_SEND_FINISHED',
  CampaignSendStarted = 'CAMPAIGN_SEND_STARTED',
  CampaignSmsRateLimitUpdated = 'CAMPAIGN_SMS_RATE_LIMIT_UPDATED',
  CampaignSubscriberQualificationEvaluated = 'CAMPAIGN_SUBSCRIBER_QUALIFICATION_EVALUATED',
  CampaignTimezoneSendFinished = 'CAMPAIGN_TIMEZONE_SEND_FINISHED',
  CampaignTimezoneSendStarted = 'CAMPAIGN_TIMEZONE_SEND_STARTED',
  CampaignUpdated = 'CAMPAIGN_UPDATED',
  CarrierDataReceived = 'CARRIER_DATA_RECEIVED',
  CartUpdated = 'CART_UPDATED',
  CartViewed = 'CART_VIEWED',
  Checkout = 'CHECKOUT',
  CheckoutAbandoned = 'CHECKOUT_ABANDONED',
  CheckoutStarted = 'CHECKOUT_STARTED',
  CheckoutUpdated = 'CHECKOUT_UPDATED',
  Click = 'CLICK',
  CollectionViewed = 'COLLECTION_VIEWED',
  CompanyLinkClick = 'COMPANY_LINK_CLICK',
  Conversion = 'CONVERSION',
  ConversionForMessage = 'CONVERSION_FOR_MESSAGE',
  CouponAssigned = 'COUPON_ASSIGNED',
  CouponScreenLoad = 'COUPON_SCREEN_LOAD',
  CreativeInteraction = 'CREATIVE_INTERACTION',
  CustomerServiceTicketClosed = 'CUSTOMER_SERVICE_TICKET_CLOSED',
  CustomerServiceTicketOpened = 'CUSTOMER_SERVICE_TICKET_OPENED',
  CustomEvent = 'CUSTOM_EVENT',
  DlrMessageReceipt = 'DLR_MESSAGE_RECEIPT',
  DruidMessageExplorerData = 'DRUID_MESSAGE_EXPLORER_DATA',
  EdsTelemetryTracer = 'EDS_TELEMETRY_TRACER',
  EmailActivity = 'EMAIL_ACTIVITY',
  EmailMessageOpened = 'EMAIL_MESSAGE_OPENED',
  EmailMessageSent = 'EMAIL_MESSAGE_SENT',
  EmailSave = 'EMAIL_SAVE',
  EmailSpamReported = 'EMAIL_SPAM_REPORTED',
  EmailStatusUpdated = 'EMAIL_STATUS_UPDATED',
  EnrichedDlr = 'ENRICHED_DLR',
  EventConsumed = 'EVENT_CONSUMED',
  FulfillmentAvailableForPickup = 'FULFILLMENT_AVAILABLE_FOR_PICKUP',
  FulfillmentCancelled = 'FULFILLMENT_CANCELLED',
  FulfillmentCreated = 'FULFILLMENT_CREATED',
  FulfillmentDelivered = 'FULFILLMENT_DELIVERED',
  FulfillmentDeliveryAttemptFailure = 'FULFILLMENT_DELIVERY_ATTEMPT_FAILURE',
  FulfillmentDeliveryException = 'FULFILLMENT_DELIVERY_EXCEPTION',
  FulfillmentInTransit = 'FULFILLMENT_IN_TRANSIT',
  FulfillmentOutForDelivery = 'FULFILLMENT_OUT_FOR_DELIVERY',
  FulfillmentPending = 'FULFILLMENT_PENDING',
  Impression = 'IMPRESSION',
  InventoryListing = 'INVENTORY_LISTING',
  Join = 'JOIN',
  JourneyPostback = 'JOURNEY_POSTBACK',
  JourneyRevisionPublished = 'JOURNEY_REVISION_PUBLISHED',
  JourneyTaskExecuted = 'JOURNEY_TASK_EXECUTED',
  JourneyTriggered = 'JOURNEY_TRIGGERED',
  LastClickConversion = 'LAST_CLICK_CONVERSION',
  LoyaltyOptedIn = 'LOYALTY_OPTED_IN',
  LoyaltyOptedOut = 'LOYALTY_OPTED_OUT',
  LoyaltyPointsChanged = 'LOYALTY_POINTS_CHANGED',
  LoyaltyPointBalanceReminderIssued = 'LOYALTY_POINT_BALANCE_REMINDER_ISSUED',
  LoyaltyReferralCompleted = 'LOYALTY_REFERRAL_COMPLETED',
  LoyaltyRewardRedeemed = 'LOYALTY_REWARD_REDEEMED',
  LoyaltyRewardRedemptionReminderIssued = 'LOYALTY_REWARD_REDEMPTION_REMINDER_ISSUED',
  LoyaltyTierChanged = 'LOYALTY_TIER_CHANGED',
  LoyaltyTierProgressUpdated = 'LOYALTY_TIER_PROGRESS_UPDATED',
  LoyaltyUserSpecialDayReached = 'LOYALTY_USER_SPECIAL_DAY_REACHED',
  MessageExplorerContentCreated = 'MESSAGE_EXPLORER_CONTENT_CREATED',
  MessageExplorerCreated = 'MESSAGE_EXPLORER_CREATED',
  MessageExplorerUpdated = 'MESSAGE_EXPLORER_UPDATED',
  MessageFatigue = 'MESSAGE_FATIGUE',
  MessageLinkClick = 'MESSAGE_LINK_CLICK',
  MessageLinkCreated = 'MESSAGE_LINK_CREATED',
  MessageLinkPrefetch = 'MESSAGE_LINK_PREFETCH',
  MessageMetadataContent = 'MESSAGE_METADATA_CONTENT',
  MessageMetadataFinalized = 'MESSAGE_METADATA_FINALIZED',
  MessageReceipt = 'MESSAGE_RECEIPT',
  MessageStatusDetails = 'MESSAGE_STATUS_DETAILS',
  MobileWalletCouponInstalled = 'MOBILE_WALLET_COUPON_INSTALLED',
  MobileWalletCouponUninstalled = 'MOBILE_WALLET_COUPON_UNINSTALLED',
  MultiPropertySet = 'MULTI_PROPERTY_SET',
  OptOut = 'OPT_OUT',
  OptOutSubscription = 'OPT_OUT_SUBSCRIPTION',
  Order = 'ORDER',
  OrderCancelled = 'ORDER_CANCELLED',
  OrderConfirmed = 'ORDER_CONFIRMED',
  OrderSubscriptionChargeFailed = 'ORDER_SUBSCRIPTION_CHARGE_FAILED',
  OrderSubscriptionChargePaid = 'ORDER_SUBSCRIPTION_CHARGE_PAID',
  OrderSubscriptionChargeScheduled = 'ORDER_SUBSCRIPTION_CHARGE_SCHEDULED',
  OrderSubscriptionCreated = 'ORDER_SUBSCRIPTION_CREATED',
  OrderSubscriptionOneTimeProductAddFailed = 'ORDER_SUBSCRIPTION_ONE_TIME_PRODUCT_ADD_FAILED',
  OrderSubscriptionOneTimeProductAddSucceeded = 'ORDER_SUBSCRIPTION_ONE_TIME_PRODUCT_ADD_SUCCEEDED',
  OrderSubscriptionProcessed = 'ORDER_SUBSCRIPTION_PROCESSED',
  OrderSubscriptionSkipped = 'ORDER_SUBSCRIPTION_SKIPPED',
  PageLeave = 'PAGE_LEAVE',
  PageView = 'PAGE_VIEW',
  PaymentsBuyResponseReceived = 'PAYMENTS_BUY_RESPONSE_RECEIVED',
  PaymentsCallToActionSent = 'PAYMENTS_CALL_TO_ACTION_SENT',
  PaymentsCardVaulted = 'PAYMENTS_CARD_VAULTED',
  PaymentsOrderCancelled = 'PAYMENTS_ORDER_CANCELLED',
  PaymentsOrderCancelRequested = 'PAYMENTS_ORDER_CANCEL_REQUESTED',
  PaymentsPurchaseCompleted = 'PAYMENTS_PURCHASE_COMPLETED',
  PaymentsPurchaseRefunded = 'PAYMENTS_PURCHASE_REFUNDED',
  PaymentsSessionFailed = 'PAYMENTS_SESSION_FAILED',
  PaymentsSessionStarted = 'PAYMENTS_SESSION_STARTED',
  PrincipalActionOccurred = 'PRINCIPAL_ACTION_OCCURRED',
  Probe = 'PROBE',
  ProductInventoryChange = 'PRODUCT_INVENTORY_CHANGE',
  ProductRecommendation = 'PRODUCT_RECOMMENDATION',
  ProductView = 'PRODUCT_VIEW',
  PublicApiRequest = 'PUBLIC_API_REQUEST',
  Purchase = 'PURCHASE',
  RcsCapabilityUpdated = 'RCS_CAPABILITY_UPDATED',
  RefundFailed = 'REFUND_FAILED',
  RefundSucceeded = 'REFUND_SUCCEEDED',
  RemovedFromCart = 'REMOVED_FROM_CART',
  RemoveFromCart = 'REMOVE_FROM_CART',
  Replenishment = 'REPLENISHMENT',
  Resubscribe = 'RESUBSCRIBE',
  ReviewSubmitted = 'REVIEW_SUBMITTED',
  SearchAttributeSet = 'SEARCH_ATTRIBUTE_SET',
  SearchSubmitted = 'SEARCH_SUBMITTED',
  SegmentEnhancementSubscriberMetadataProcessed = 'SEGMENT_ENHANCEMENT_SUBSCRIBER_METADATA_PROCESSED',
  SegmentJoined = 'SEGMENT_JOINED',
  SendMetadataCreated = 'SEND_METADATA_CREATED',
  SendMetadataUpdated = 'SEND_METADATA_UPDATED',
  SendStatusUpdated = 'SEND_STATUS_UPDATED',
  SharedIpEventSnapshot = 'SHARED_IP_EVENT_SNAPSHOT',
  SubscriberDoubleOptInStarted = 'SUBSCRIBER_DOUBLE_OPT_IN_STARTED',
  SubscriberFatigued = 'SUBSCRIBER_FATIGUED',
  SubscriberList = 'SUBSCRIBER_LIST',
  SubscriberPurchaseKeywordReceived = 'SUBSCRIBER_PURCHASE_KEYWORD_RECEIVED',
  SubscriberTag = 'SUBSCRIBER_TAG',
  SubscriptionAlreadySubscribed = 'SUBSCRIPTION_ALREADY_SUBSCRIBED',
  SubscriptionContactInfoReassigned = 'SUBSCRIPTION_CONTACT_INFO_REASSIGNED',
  SubscriptionDeactivated = 'SUBSCRIPTION_DEACTIVATED',
  SubscriptionDeactivatedSkipped = 'SUBSCRIPTION_DEACTIVATED_SKIPPED',
  SubscriptionOptedOut = 'SUBSCRIPTION_OPTED_OUT',
  SubscriptionOptInCompleted = 'SUBSCRIPTION_OPT_IN_COMPLETED',
  SubscriptionOptInDeclined = 'SUBSCRIPTION_OPT_IN_DECLINED',
  SubscriptionOptInStarted = 'SUBSCRIPTION_OPT_IN_STARTED',
  SubscriptionPointInTimeMetric = 'SUBSCRIPTION_POINT_IN_TIME_METRIC',
  SubscriptionSuppressed = 'SUBSCRIPTION_SUPPRESSED',
  SubscriptionUnsuppressed = 'SUBSCRIPTION_UNSUPPRESSED',
  SurveyKeywordReceived = 'SURVEY_KEYWORD_RECEIVED',
  TextMessageReceived = 'TEXT_MESSAGE_RECEIVED',
  TraceEvent = 'TRACE_EVENT',
  TrinoQuery = 'TRINO_QUERY',
  UserIdentifierCollected = 'USER_IDENTIFIER_COLLECTED',
  UserPropertyBatch = 'USER_PROPERTY_BATCH',
  UserPropertyDefinitionChange = 'USER_PROPERTY_DEFINITION_CHANGE',
  UserPropertyMutate = 'USER_PROPERTY_MUTATE',
  UserPropertySet = 'USER_PROPERTY_SET',
  UserPropertyValueChange = 'USER_PROPERTY_VALUE_CHANGE',
  UserRelevantVariantPriceDrop = 'USER_RELEVANT_VARIANT_PRICE_DROP',
  VariantInventoryChange = 'VARIANT_INVENTORY_CHANGE',
  VariantPriceChange = 'VARIANT_PRICE_CHANGE',
  VisitorExperimentAssignment = 'VISITOR_EXPERIMENT_ASSIGNMENT'
}

/** Generated from com/attentivemobile/contracts/event/platform/registration/service/Transformation.proto */
export type EventTypeSplitter = {
  __typename?: 'EventTypeSplitter';
  dlqTopic: Maybe<PulsarTopic>;
  rules: Array<EventTypeSplitterRule>;
};

/** Generated from com/attentivemobile/contracts/event/platform/registration/service/Transformation.proto */
export type EventTypeSplitterRule = {
  __typename?: 'EventTypeSplitterRule';
  disabled: Maybe<Scalars['Boolean']['output']>;
  eventTypes: Array<EventType>;
  parallelism: Scalars['Int']['output'];
  pulsarTopic: PulsarTopic;
};

/** Generated from com/attentivemobile/contracts/user/profile/EventUser.proto */
export type EventUser = {
  __typename?: 'EventUser';
  email: Maybe<Scalars['String']['output']>;
  externalIdentifiers: Maybe<EventUserExternalIdentifiers>;
  phone: Maybe<Scalars['String']['output']>;
};

/** Generated from com/attentivemobile/contracts/user/profile/EventUser.proto */
export type EventUserCustomIdentifier = {
  __typename?: 'EventUserCustomIdentifier';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type EventUserCustomIdentifierInput = {
  name: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/user/profile/EventUser.proto */
export type EventUserExternalIdentifiers = {
  __typename?: 'EventUserExternalIdentifiers';
  clientUserId: Maybe<Scalars['String']['output']>;
  customIdentifiers: Array<EventUserCustomIdentifier>;
};

export type EventUserExternalIdentifiersInput = {
  clientUserId: InputMaybe<Scalars['String']['input']>;
  customIdentifiers: InputMaybe<Array<EventUserCustomIdentifierInput>>;
};

export type EventUserInput = {
  email: InputMaybe<Scalars['String']['input']>;
  externalIdentifiers: InputMaybe<EventUserExternalIdentifiersInput>;
  phone: InputMaybe<Scalars['String']['input']>;
};

/** Generated from com/attentivemobile/contracts/common/EventVisitor.proto */
export type EventVisitor = {
  __typename?: 'EventVisitor';
  /** Email of the user associated with the action. This field is required if phone is not provided. */
  email: Maybe<Scalars['String']['output']>;
  /** Phone number of the user associated with the action. E.164 format is required. This field is required if email is not provided. */
  phone: Maybe<Scalars['String']['output']>;
};

export type EventVisitorInput = {
  /** Email of the user associated with the action. This field is required if phone is not provided. */
  email: InputMaybe<Scalars['String']['input']>;
  /** Phone number of the user associated with the action. E.164 format is required. This field is required if email is not provided. */
  phone: InputMaybe<Scalars['String']['input']>;
};

/** Generated from com/attentivemobile/protobuf/common/Chunk.proto */
export type ExclusionHeuristicChunk = {
  __typename?: 'ExclusionHeuristicChunk';
  dummyField: Scalars['Empty']['output'];
};

/** Generated from com/attentivemobile/protobuf/common/Chunk.proto */
export type ExclusionInvalidSubscribersChunk = {
  __typename?: 'ExclusionInvalidSubscribersChunk';
  dummyField: Scalars['Empty']['output'];
};

/** Generated from com/attentivemobile/protobuf/common/Chunk.proto */
export type ExclusionProductAffinityAlphaChunk = {
  __typename?: 'ExclusionProductAffinityAlphaChunk';
  /** @deprecated deprecated */
  productFilter: AudienceAiProductFilter;
  productOriginIds: Array<ProductOriginID>;
  purchasePropensityModelVersion: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/protobuf/common/targeting/segmentation/output/ComparisonTypes.proto */
export type ExistenceComparison = {
  __typename?: 'ExistenceComparison';
  isNot: Maybe<Scalars['Boolean']['output']>;
};

export type ExistenceComparisonInput = {
  isNot: InputMaybe<Scalars['Boolean']['input']>;
};

/** Generated from com/attentivemobile/contracts/journeys/api/RevisionStep.proto */
export type ExperimentStep = {
  __typename?: 'ExperimentStep';
  childrenSteps: Array<VariantStep>;
  stepConfig: ExperimentStepConfig;
  stepParameters: ExperimentStepParameters;
};

/** Generated from com/attentivemobile/contracts/journeys/api/RevisionStepConfig.proto */
export type ExperimentStepConfig = {
  __typename?: 'ExperimentStepConfig';
  branchMax: Scalars['Int']['output'];
  branchMin: Scalars['Int']['output'];
};

/** Generated from com/attentivemobile/contracts/journeys/api/RevisionStepParameters.proto */
export type ExperimentStepParameters = {
  __typename?: 'ExperimentStepParameters';
  trafficSplitType: TrafficSplitType;
};

export enum ExperimentType {
  ExperimentEmailContent = 'EXPERIMENT_EMAIL_CONTENT',
  ExperimentEmailSubjectLineAndPreviewText = 'EXPERIMENT_EMAIL_SUBJECT_LINE_AND_PREVIEW_TEXT',
  ExperimentSendTime = 'EXPERIMENT_SEND_TIME',
  ExperimentSubscriberOptimizedSendTime = 'EXPERIMENT_SUBSCRIBER_OPTIMIZED_SEND_TIME',
  ExperimentTextContent = 'EXPERIMENT_TEXT_CONTENT'
}

/** Generated from com/attentivemobile/contracts/metricsapi/DatasetsContract.proto */
export type ExportDatasetResponse = {
  __typename?: 'ExportDatasetResponse';
  s3Urls: Array<Scalars['String']['output']>;
};

/**
 * Generated from com/attentivemobile/contracts/subscriptions/api/v2/Contract.proto
 * External Identifiers for a user.
 */
export type ExternalCustomIdentifiers = {
  __typename?: 'ExternalCustomIdentifiers';
  /** External identifier name. */
  name: Scalars['String']['output'];
  /** External identifier value. */
  value: Scalars['String']['output'];
};

/** External Identifiers for a user. */
export type ExternalCustomIdentifiersInput = {
  /** External identifier name. */
  name: Scalars['String']['input'];
  /** External identifier value. */
  value: Scalars['String']['input'];
};

/**
 * Generated from com/attentivemobile/contracts/subscriptions/api/v2/Contract.proto
 * External Identifiers for a user.
 */
export type ExternalIdentifiersForSubscribe = {
  __typename?: 'ExternalIdentifiersForSubscribe';
  /** Client user ID. */
  clientUserId: Maybe<Scalars['String']['output']>;
  /** Custom identifiers. */
  customIdentifiers: Array<ExternalCustomIdentifiers>;
  /** Klaviyo ID. */
  klaviyoId: Maybe<Scalars['String']['output']>;
  /** Shopify ID. */
  shopifyId: Maybe<Scalars['String']['output']>;
};

/** External Identifiers for a user. */
export type ExternalIdentifiersForSubscribeInput = {
  /** Client user ID. */
  clientUserId: InputMaybe<Scalars['String']['input']>;
  /** Custom identifiers. */
  customIdentifiers: InputMaybe<Array<ExternalCustomIdentifiersInput>>;
  /** Klaviyo ID. */
  klaviyoId: InputMaybe<Scalars['String']['input']>;
  /** Shopify ID. */
  shopifyId: InputMaybe<Scalars['String']['input']>;
};

/** Generated from com/attentivemobile/contracts/solutions/sftp/EtlConfig.proto */
export type ExternalSftpConfig = {
  __typename?: 'ExternalSftpConfig';
  description: Scalars['String']['output'];
  externalSftConfigId: Scalars['String']['output'];
  sftpHost: Scalars['String']['output'];
  /** @deprecated deprecated */
  sftpPassword: Maybe<Scalars['String']['output']>;
  sftpPath: Maybe<Scalars['String']['output']>;
  sftpUser: Scalars['String']['output'];
  useKey: Maybe<Scalars['Boolean']['output']>;
};

export type ExternalSftpConfigInput = {
  description: Scalars['String']['input'];
  externalSftConfigId: Scalars['String']['input'];
  sftpHost: Scalars['String']['input'];
  sftpPassword: InputMaybe<Scalars['String']['input']>;
  sftpPath: InputMaybe<Scalars['String']['input']>;
  sftpUser: Scalars['String']['input'];
  useKey: InputMaybe<Scalars['Boolean']['input']>;
};

/** Generated from com/attentivemobile/contracts/integrations/v2/Contract.proto */
export type ExternalVendorApi = Node & {
  __typename?: 'ExternalVendorApi';
  description: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  externalVendorFeatures: Maybe<Array<ExternalVendorFeature>>;
  id: Scalars['ID']['output'];
  integrationGuideUrl: Scalars['String']['output'];
  validationUrl: Scalars['String']['output'];
  vendor: Vendor;
  visibility: VisibilityType;
};

export type ExternalVendorData = FacebookAdsExternalVendorData;

export enum ExternalVendorDataField {
  ExternalDataFieldFacebookAdsAdAccounts = 'EXTERNAL_DATA_FIELD_FACEBOOK_ADS_AD_ACCOUNTS',
  ExternalDataFieldFacebookAdsCustomAudiences = 'EXTERNAL_DATA_FIELD_FACEBOOK_ADS_CUSTOM_AUDIENCES',
  ExternalDataFieldUnknown = 'EXTERNAL_DATA_FIELD_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/integrations/v2/Contract.proto */
export type ExternalVendorDataWrapper = {
  __typename?: 'ExternalVendorDataWrapper';
  data: ExternalVendorData;
};

/** Generated from com/attentivemobile/contracts/integrations/v2/Contract.proto */
export type ExternalVendorFeature = Node & {
  __typename?: 'ExternalVendorFeature';
  deciderName: Scalars['String']['output'];
  description: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  enabledByDefault: Scalars['Boolean']['output'];
  externalVendorApi: Maybe<ExternalVendorApi>;
  id: Scalars['ID']['output'];
  metadata: Maybe<ExternalVendorFeatureMetadata>;
  type: Feature;
};

/** Generated from com/attentivemobile/contracts/integrations/v2/Contract.proto */
export type ExternalVendorFeatureGenericMetadata = {
  __typename?: 'ExternalVendorFeatureGenericMetadata';
  metadata: Scalars['String']['output'];
};

export type ExternalVendorFeatureMetadata = ExternalVendorFeatureGenericMetadata;

/** Generated from com/attentivemobile/contracts/integrations/v2/externalvendordata/Contract.proto */
export type FacebookAdAccount = {
  __typename?: 'FacebookAdAccount';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  tosAccepted: Scalars['Boolean']['output'];
};

/** Generated from com/attentivemobile/contracts/integrations/v2/externalvendordata/Contract.proto */
export type FacebookAdsExternalVendorData = {
  __typename?: 'FacebookAdsExternalVendorData';
  adAccounts: Array<FacebookAdAccount>;
  customAudiences: Array<FacebookCustomAudience>;
};

export type FacebookAdsExternalVendorFilter = {
  adAccountId: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/integrations/v2/externalvendordata/Contract.proto */
export type FacebookCustomAudience = {
  __typename?: 'FacebookCustomAudience';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/segment/recommendation/service/Contract.proto */
export type FailureSegmentEnhancementWorkflowStatus = {
  __typename?: 'FailureSegmentEnhancementWorkflowStatus';
  reason: Scalars['String']['output'];
};

export enum Feature {
  BridgeIdentifiers = 'BRIDGE_IDENTIFIERS',
  CustomerServiceOptIn = 'CUSTOMER_SERVICE_OPT_IN',
  DynamicCouponGeneration = 'DYNAMIC_COUPON_GENERATION',
  FeatureAdditionalSetupLink = 'FEATURE_ADDITIONAL_SETUP_LINK',
  FeatureAttentiveLoyaltySignup = 'FEATURE_ATTENTIVE_LOYALTY_SIGNUP',
  FeatureAutoInstallTag = 'FEATURE_AUTO_INSTALL_TAG',
  FeatureCreateMarketingEmailSubscriptions = 'FEATURE_CREATE_MARKETING_EMAIL_SUBSCRIPTIONS',
  FeatureCreateMarketingSmsSubscriptions = 'FEATURE_CREATE_MARKETING_SMS_SUBSCRIPTIONS',
  FeatureCreateTransactionalSubscriptions = 'FEATURE_CREATE_TRANSACTIONAL_SUBSCRIPTIONS',
  FeatureEmailOptOutSync = 'FEATURE_EMAIL_OPT_OUT_SYNC',
  FeatureExportSegments = 'FEATURE_EXPORT_SEGMENTS',
  FeatureGenerateHook = 'FEATURE_GENERATE_HOOK',
  FeatureIngestAdData = 'FEATURE_INGEST_AD_DATA',
  FeatureIngestLeads = 'FEATURE_INGEST_LEADS',
  FeatureIngestTagEvents = 'FEATURE_INGEST_TAG_EVENTS',
  FeatureIngestUserData = 'FEATURE_INGEST_USER_DATA',
  FeatureMarketingEmailMessageSend = 'FEATURE_MARKETING_EMAIL_MESSAGE_SEND',
  FeaturePostbackUserAttributes = 'FEATURE_POSTBACK_USER_ATTRIBUTES',
  FeaturePostbackUserData = 'FEATURE_POSTBACK_USER_DATA',
  FeaturePostSubscriberOptIn = 'FEATURE_POST_SUBSCRIBER_OPT_IN',
  FeaturePostSubscriberOptOut = 'FEATURE_POST_SUBSCRIBER_OPT_OUT',
  FeaturePurchaseProduct = 'FEATURE_PURCHASE_PRODUCT',
  FeatureRefundSync = 'FEATURE_REFUND_SYNC',
  FeatureSetupRedirectSource = 'FEATURE_SETUP_REDIRECT_SOURCE',
  FeatureSubscriptionMessaging = 'FEATURE_SUBSCRIPTION_MESSAGING',
  FeatureSurveyMessage = 'FEATURE_SURVEY_MESSAGE',
  FeatureSyncAbandonedCheckouts = 'FEATURE_SYNC_ABANDONED_CHECKOUTS',
  FeatureSyncAccountProperties = 'FEATURE_SYNC_ACCOUNT_PROPERTIES',
  FeatureSyncAttentiveToEspEmailOptOutsViaSftp = 'FEATURE_SYNC_ATTENTIVE_TO_ESP_EMAIL_OPT_OUTS_VIA_SFTP',
  FeatureSyncEmailConsent = 'FEATURE_SYNC_EMAIL_CONSENT',
  FeatureSyncEmailEvents = 'FEATURE_SYNC_EMAIL_EVENTS',
  FeatureSyncEmailSubscribers = 'FEATURE_SYNC_EMAIL_SUBSCRIBERS',
  FeatureSyncEspToAttentiveEmailOptOutsViaSftp = 'FEATURE_SYNC_ESP_TO_ATTENTIVE_EMAIL_OPT_OUTS_VIA_SFTP',
  FeatureSyncEventsOutbound = 'FEATURE_SYNC_EVENTS_OUTBOUND',
  FeatureSyncListCount = 'FEATURE_SYNC_LIST_COUNT',
  FeatureSyncListIdentifiers = 'FEATURE_SYNC_LIST_IDENTIFIERS',
  FeatureSyncLoyaltyEvents = 'FEATURE_SYNC_LOYALTY_EVENTS',
  FeatureSyncOrderEvents = 'FEATURE_SYNC_ORDER_EVENTS',
  FeatureSyncProductCollections = 'FEATURE_SYNC_PRODUCT_COLLECTIONS',
  FeatureSyncPurchases = 'FEATURE_SYNC_PURCHASES',
  FeatureSyncRecurringPurchases = 'FEATURE_SYNC_RECURRING_PURCHASES',
  FeatureSyncReviews = 'FEATURE_SYNC_REVIEWS',
  FeatureSyncSmsConsent = 'FEATURE_SYNC_SMS_CONSENT',
  FeatureSyncThirdPartyCampaigns = 'FEATURE_SYNC_THIRD_PARTY_CAMPAIGNS',
  FeatureSyncThirdPartyJourneys = 'FEATURE_SYNC_THIRD_PARTY_JOURNEYS',
  FeatureSyncUserProfiles = 'FEATURE_SYNC_USER_PROFILES',
  FeatureTemplatizedPostback = 'FEATURE_TEMPLATIZED_POSTBACK',
  FeatureTriggerVendorEmailSend = 'FEATURE_TRIGGER_VENDOR_EMAIL_SEND',
  FeatureUnknown = 'FEATURE_UNKNOWN',
  ForwardMessageCustomerService = 'FORWARD_MESSAGE_CUSTOMER_SERVICE',
  IngestTransactionalEvents = 'INGEST_TRANSACTIONAL_EVENTS',
  PostbackEmails = 'POSTBACK_EMAILS',
  PostWithPhone = 'POST_WITH_PHONE',
  ScrubMessageSignature = 'SCRUB_MESSAGE_SIGNATURE',
  SyncHistoricalPurchases = 'SYNC_HISTORICAL_PURCHASES',
  SyncProducts = 'SYNC_PRODUCTS',
  SyncThirdPartyLists = 'SYNC_THIRD_PARTY_LISTS',
  SyncThirdPartySegments = 'SYNC_THIRD_PARTY_SEGMENTS',
  ThirdPartyProcessorForwarder = 'THIRD_PARTY_PROCESSOR_FORWARDER'
}

export type FeatureFieldsEntry = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/featureflag/service/Contract.proto */
export type FeatureFlag = Node & {
  __typename?: 'FeatureFlag';
  created: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** @deprecated deprecated */
  legacyId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  rolloutVariants: Array<Rollout>;
  targetingData: Maybe<TargetingConnection>;
  updated: Scalars['DateTime']['output'];
};


/** Generated from com/attentivemobile/contracts/featureflag/service/Contract.proto */
export type FeatureFlagtargetingDataArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  companyId: InputMaybe<Scalars['ID']['input']>;
  contextKey: InputMaybe<Scalars['String']['input']>;
  contextValue: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
};

/** Generated from com/attentivemobile/contracts/featureflag/service/Contract.proto */
export type FeatureFlagConnection = {
  __typename?: 'FeatureFlagConnection';
  edges: Array<FeatureFlagEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Long']['output'];
};

/** Generated from com/attentivemobile/contracts/featureflag/service/Contract.proto */
export type FeatureFlagEdge = {
  __typename?: 'FeatureFlagEdge';
  cursor: Scalars['String']['output'];
  node: FeatureFlag;
};

export type FeatureMapInput = {
  featureSettingPair: InputMaybe<Array<FeatureSettingConfigurationInput>>;
};

export type FeatureSettingConfigurationInput = {
  key: Feature;
  value: FeatureSettingInput;
};

export type FeatureSettingInput = {
  enabled: Scalars['Boolean']['input'];
  featureFields: InputMaybe<Array<FeatureFieldsEntry>>;
};

/** Generated from com/attentivemobile/contracts/concierge/service/Template.proto */
export type FeedbackSource = {
  __typename?: 'FeedbackSource';
  feedback: Maybe<MessageFeedback>;
  /** @deprecated deprecated */
  feedbackId: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type FetchOptions = {
  __typename?: 'FetchOptions';
  matchPrefix: Scalars['Boolean']['output'];
  maxFiles: Scalars['Int']['output'];
};

export type FetchOptionsInput = {
  matchPrefix: Scalars['Boolean']['input'];
  maxFiles: Scalars['Int']['input'];
};

export type FieldMask = {
  paths: InputMaybe<Array<Scalars['String']['input']>>;
};

export type FieldsEntry = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type FileSettings = {
  __typename?: 'FileSettings';
  delimiter: Maybe<Scalars['String']['output']>;
  encryption: Maybe<Encryption>;
  headerSettings: HeaderSettings;
  legacyFileType: Maybe<Scalars['String']['output']>;
  preprocess: Scalars['Boolean']['output'];
};

export type FileSettingsInput = {
  delimiter: InputMaybe<Scalars['String']['input']>;
  encryption: InputMaybe<EncryptionInput>;
  headerSettings: HeaderSettingsInput;
  legacyFileType: InputMaybe<Scalars['String']['input']>;
  preprocess: Scalars['Boolean']['input'];
};

export enum FilterOperation {
  FilterOperationAnd = 'FILTER_OPERATION_AND',
  FilterOperationOr = 'FILTER_OPERATION_OR'
}

export enum FilterOperator {
  /** Deprecated, use FILTER_OPERATOR_IN instead */
  FilterOperatorEquals = 'FILTER_OPERATOR_EQUALS',
  FilterOperatorIn = 'FILTER_OPERATOR_IN',
  /** Deprecated, use FILTER_OPERATOR_NOT_IN instead */
  FilterOperatorNotEquals = 'FILTER_OPERATOR_NOT_EQUALS',
  FilterOperatorNotIn = 'FILTER_OPERATOR_NOT_IN',
  FilterOperatorUnknown = 'FILTER_OPERATOR_UNKNOWN'
}

export type FindMessagesFilter = {
  carriers: InputMaybe<Array<Carrier>>;
  companyId: InputMaybe<Scalars['ID']['input']>;
  countryCodes: InputMaybe<Array<Scalars['String']['input']>>;
  dateRange: DateRange;
  errorCode: InputMaybe<Scalars['String']['input']>;
  /** @deprecated deprecated */
  fromPhoneNumber: InputMaybe<Scalars['String']['input']>;
  fromPhoneNumbers: InputMaybe<Array<Scalars['String']['input']>>;
  messageId: InputMaybe<Scalars['Long']['input']>;
  providers: InputMaybe<Array<Provider>>;
  status: InputMaybe<MessageStatus>;
  /** @deprecated deprecated */
  toPhoneNumber: InputMaybe<Scalars['String']['input']>;
  toPhoneNumbers: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type FindSpecificUserContentSettingConnection = {
  __typename?: 'FindSpecificUserContentSettingConnection';
  contentSetting: UserContentSetting;
};

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type FindUserContentSettingsConnection = {
  __typename?: 'FindUserContentSettingsConnection';
  edges: Array<UserContentSettingsEdge>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type FindUserContentVisibilityResponse = {
  __typename?: 'FindUserContentVisibilityResponse';
  isVisible: Scalars['Boolean']['output'];
  preferenceValue: Scalars['String']['output'];
};

export type FinishAuthenticatorEnrollmentInput = {
  credentialName: Scalars['String']['input'];
  publicKeyCredentialJson: Scalars['String']['input'];
};

export type FinishAuthenticatorEnrollmentPayload = DefaultErrorFailure | FinishAuthenticatorEnrollmentSuccess;

/**
 * Generated from com/attentivemobile/contracts/account-service/AuthenticatorContract.proto
 * If success is true, the user has passed authorization and enrollment is complete
 */
export type FinishAuthenticatorEnrollmentSuccess = {
  __typename?: 'FinishAuthenticatorEnrollmentSuccess';
  success: Scalars['Boolean']['output'];
};

/** Generated from com/attentivemobile/contracts/featureflag/service/Contract.proto */
export type FlagEnabled = {
  __typename?: 'FlagEnabled';
  enabled: Scalars['Boolean']['output'];
  featureFlagName: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/featureflag/service/Contract.proto */
export type FlagEnabledConnection = {
  __typename?: 'FlagEnabledConnection';
  edges: Array<FlagEnabledEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Long']['output'];
};

/** Generated from com/attentivemobile/contracts/featureflag/service/Contract.proto */
export type FlagEnabledEdge = {
  __typename?: 'FlagEnabledEdge';
  cursor: Scalars['String']['output'];
  node: FlagEnabled;
};

export enum FlinkDeploymentMode {
  FlinkDeploymentModeApplicationMode = 'FLINK_DEPLOYMENT_MODE_APPLICATION_MODE',
  FlinkDeploymentModeSessionMode = 'FLINK_DEPLOYMENT_MODE_SESSION_MODE',
  FlinkDeploymentModeUnknown = 'FLINK_DEPLOYMENT_MODE_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/event/platform/registration/service/Flink.proto */
export type FlinkJob = {
  __typename?: 'FlinkJob';
  applicationId: Scalars['String']['output'];
  cluster: Cluster;
  dedupStateLocation: Scalars['String']['output'];
  flinkDeploymentMode: FlinkDeploymentMode;
  jobId: Scalars['String']['output'];
  jobManagerUrl: Scalars['String']['output'];
  processedEventsLocation: Scalars['String']['output'];
  region: AttentiveAwsRegion;
  status: Scalars['String']['output'];
};

export type FlinkJobWrite = {
  cluster: Cluster;
  entryClass: Scalars['String']['input'];
  flinkDeploymentMode: FlinkDeploymentMode;
  jarName: Scalars['String']['input'];
  jobId: InputMaybe<Scalars['String']['input']>;
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type FloatPropertyValue = {
  __typename?: 'FloatPropertyValue';
  value: Scalars['Float']['output'];
};

export enum FloridaSubscribersAuditStatusV2 {
  FloridaSubscribersAuditStatusAuditReportRunning = 'FLORIDA_SUBSCRIBERS_AUDIT_STATUS_AUDIT_REPORT_RUNNING',
  FloridaSubscribersAuditStatusFailure = 'FLORIDA_SUBSCRIBERS_AUDIT_STATUS_FAILURE',
  FloridaSubscribersAuditStatusUnknown = 'FLORIDA_SUBSCRIBERS_AUDIT_STATUS_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/asset/library/service/Assets.proto */
export type Folder = Node & {
  __typename?: 'Folder';
  /** The child assets of the folder */
  childAssets: Maybe<FolderChildrenConnection>;
  company: Maybe<Company>;
  createdAt: Scalars['DateTime']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  parent: Maybe<Folder>;
  updatedAt: Scalars['DateTime']['output'];
};


/** Generated from com/attentivemobile/contracts/asset/library/service/Assets.proto */
export type FolderchildAssetsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orderByAndDirection: InputMaybe<AssetsOrderByAndDirection>;
};

/** Generated from com/attentivemobile/contracts/asset/library/service/Contract.proto */
export type FolderChildEdge = {
  __typename?: 'FolderChildEdge';
  cursor: Scalars['String']['output'];
  node: Asset;
};

/** Generated from com/attentivemobile/contracts/asset/library/service/Contract.proto */
export type FolderChildrenConnection = {
  __typename?: 'FolderChildrenConnection';
  edges: Array<FolderChildEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Long']['output'];
};

export enum FontType {
  BrandkitFontTypeCustom = 'BRANDKIT_FONT_TYPE_CUSTOM',
  BrandkitFontTypeGoogle = 'BRANDKIT_FONT_TYPE_GOOGLE',
  BrandkitFontTypeTypekit = 'BRANDKIT_FONT_TYPE_TYPEKIT',
  BrandkitFontTypeWebsafe = 'BRANDKIT_FONT_TYPE_WEBSAFE'
}

/** Generated from com/attentivemobile/contracts/journeys/api/RevisionStep.proto */
export type ForwardToConciergeStep = {
  __typename?: 'ForwardToConciergeStep';
  childrenSteps: Array<AdderStep>;
  stepParameters: ForwardToConciergeStepParameters;
};

/** Generated from com/attentivemobile/contracts/journeys/api/RevisionStepParameters.proto */
export type ForwardToConciergeStepParameters = {
  __typename?: 'ForwardToConciergeStepParameters';
  couponPoolId: Maybe<Scalars['Long']['output']>;
  couponPoolName: Maybe<Scalars['String']['output']>;
  messageId: Scalars['Long']['output'];
  messageLinkId: Scalars['Long']['output'];
  shouldIncludeUtmParams: Scalars['Boolean']['output'];
  utmCampaign: Maybe<Scalars['String']['output']>;
};

/** Generated from com/attentivemobile/protobuf/common/targeting/segmentation/output/ComparisonTypes.proto */
export type FrequencyComparison = {
  __typename?: 'FrequencyComparison';
  comparator: SearchFrequencyComparator;
  endValue: Maybe<Scalars['Int']['output']>;
  value: Scalars['Int']['output'];
};

export type FrequencyComparisonInput = {
  comparator: SearchFrequencyComparator;
  endValue: InputMaybe<Scalars['Int']['input']>;
  value: Scalars['Int']['input'];
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type FulfillmentAvailableForPickupEvent = {
  __typename?: 'FulfillmentAvailableForPickupEvent';
  company: Maybe<Company>;
  fulfillment: FulfillmentInfo;
  order: Maybe<OrderInfo>;
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type FulfillmentCancelledEvent = {
  __typename?: 'FulfillmentCancelledEvent';
  company: Maybe<Company>;
  fulfillment: FulfillmentInfo;
  order: Maybe<OrderInfo>;
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type FulfillmentCreatedEvent = {
  __typename?: 'FulfillmentCreatedEvent';
  company: Maybe<Company>;
  fulfillment: FulfillmentInfo;
  order: Maybe<OrderInfo>;
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type FulfillmentDeliveredEvent = {
  __typename?: 'FulfillmentDeliveredEvent';
  company: Maybe<Company>;
  fulfillment: FulfillmentInfo;
  order: Maybe<OrderInfo>;
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type FulfillmentDeliveryAttemptFailureEvent = {
  __typename?: 'FulfillmentDeliveryAttemptFailureEvent';
  company: Maybe<Company>;
  fulfillment: FulfillmentInfo;
  order: Maybe<OrderInfo>;
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type FulfillmentDeliveryExceptionEvent = {
  __typename?: 'FulfillmentDeliveryExceptionEvent';
  company: Maybe<Company>;
  fulfillment: FulfillmentInfo;
  order: Maybe<OrderInfo>;
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type FulfillmentInTransitEvent = {
  __typename?: 'FulfillmentInTransitEvent';
  company: Maybe<Company>;
  fulfillment: FulfillmentInfo;
  order: Maybe<OrderInfo>;
};

/** Generated from com/attentivemobile/contracts/event/ecommerce/Fulfillment.proto */
export type FulfillmentInfo = {
  __typename?: 'FulfillmentInfo';
  deliveryDate: Scalars['DateTime']['output'];
  externalOrderId: Scalars['String']['output'];
  fulfillmentId: Scalars['String']['output'];
  orderProducts: Array<OrderProduct>;
  orderRecipient: OrderRecipient;
  orderStatusUrl: Scalars['String']['output'];
  tracking: Array<Tracking>;
  userOrderNumber: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type FulfillmentOutForDeliveryEvent = {
  __typename?: 'FulfillmentOutForDeliveryEvent';
  company: Maybe<Company>;
  fulfillment: FulfillmentInfo;
  order: Maybe<OrderInfo>;
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type FulfillmentPendingEvent = {
  __typename?: 'FulfillmentPendingEvent';
  company: Maybe<Company>;
  fulfillment: FulfillmentInfo;
  order: Maybe<OrderInfo>;
};

export type FullListSearch = {
  dummy: Scalars['Int']['input'];
};

export enum GeneralDataFeedCols {
  CampaignName = 'CAMPAIGN_NAME',
  CampaignType = 'CAMPAIGN_TYPE',
  ClientIdGeneralDataFeed = 'CLIENT_ID_GENERAL_DATA_FEED',
  ClientUserIdGeneralDataFeed = 'CLIENT_USER_ID_GENERAL_DATA_FEED',
  CompanyIdGeneralDataFeed = 'COMPANY_ID_GENERAL_DATA_FEED',
  Coupons = 'COUPONS',
  CreativeId = 'CREATIVE_ID',
  CreativeName = 'CREATIVE_NAME',
  CreativeSubtype = 'CREATIVE_SUBTYPE',
  CreativeType = 'CREATIVE_TYPE',
  Date = 'DATE',
  Email = 'EMAIL',
  ExternalIdGeneralDataFeed = 'EXTERNAL_ID_GENERAL_DATA_FEED',
  HasMedia = 'HAS_MEDIA',
  HoldoutGroup = 'HOLDOUT_GROUP',
  JoinSource = 'JOIN_SOURCE',
  MessageGid = 'MESSAGE_GID',
  MessageIdGeneralDataFeed = 'MESSAGE_ID_GENERAL_DATA_FEED',
  MessageLinkTargetGeneralDataFeed = 'MESSAGE_LINK_TARGET_GENERAL_DATA_FEED',
  MessageNameGeneralDataFeed = 'MESSAGE_NAME_GENERAL_DATA_FEED',
  MessageSid = 'MESSAGE_SID',
  MessageStart = 'MESSAGE_START',
  MessageSubtypeGeneralDataFeed = 'MESSAGE_SUBTYPE_GENERAL_DATA_FEED',
  MessageTextGeneralDataFeed = 'MESSAGE_TEXT_GENERAL_DATA_FEED',
  MessageTypeGeneralDataFeed = 'MESSAGE_TYPE_GENERAL_DATA_FEED',
  OptInFlag = 'OPT_IN_FLAG',
  OptInSms = 'OPT_IN_SMS',
  OrderIdGeneralDataFeed = 'ORDER_ID_GENERAL_DATA_FEED',
  ParentMessageName = 'PARENT_MESSAGE_NAME',
  PhoneCountryGeneralDataFeed = 'PHONE_COUNTRY_GENERAL_DATA_FEED',
  PhoneGeneralDataFeed = 'PHONE_GENERAL_DATA_FEED',
  ProductIdGeneralDataFeed = 'PRODUCT_ID_GENERAL_DATA_FEED',
  ProductName = 'PRODUCT_NAME',
  ProductPrice = 'PRODUCT_PRICE',
  ProductSku = 'PRODUCT_SKU',
  PropertyId = 'PROPERTY_ID',
  PropertyName = 'PROPERTY_NAME',
  PropertyValue = 'PROPERTY_VALUE',
  PropertyValueId = 'PROPERTY_VALUE_ID',
  QuantityGeneralDataFeed = 'QUANTITY_GENERAL_DATA_FEED',
  RawExternalIdGeneralDataFeed = 'RAW_EXTERNAL_ID_GENERAL_DATA_FEED',
  RegionGeneralDataFeed = 'REGION_GENERAL_DATA_FEED',
  SignupUrlGeneralDataFeed = 'SIGNUP_URL_GENERAL_DATA_FEED',
  SmsAcqSource = 'SMS_ACQ_SOURCE',
  Source = 'SOURCE',
  SubscriberId = 'SUBSCRIBER_ID',
  SubscriberKey = 'SUBSCRIBER_KEY',
  SubscriberVal = 'SUBSCRIBER_VAL',
  SubscriptionChannel = 'SUBSCRIPTION_CHANNEL',
  SubscriptionType = 'SUBSCRIPTION_TYPE',
  TimestampGeneralDataFeed = 'TIMESTAMP_GENERAL_DATA_FEED',
  Total = 'TOTAL',
  TtjSource = 'TTJ_SOURCE',
  Type = 'TYPE',
  UserId = 'USER_ID',
  UuidGeneralDataFeed = 'UUID_GENERAL_DATA_FEED',
  VisitorId = 'VISITOR_ID',
  VisitorPhone = 'VISITOR_PHONE',
  Zipcode = 'ZIPCODE'
}

export type GenerateAIImageInput = {
  sceneConstructionInput: InputMaybe<SceneConstructionInput>;
  transformationType: AIImageGenerationType;
};

/** Generated from com/attentivemobile/contracts/generativeaiservice/image/ImageGenerationContract.proto */
export type GenerateAIImagePayload = {
  __typename?: 'GenerateAIImagePayload';
  transformedImageUrls: Array<Url>;
};

export type GenerateAIJourneyTextInput = {
  brandVoiceSettings: InputMaybe<AIBrandVoiceSettingsInputType>;
  companyId: Scalars['ID']['input'];
  inputs: InputMaybe<Array<ChatMessageAdminToolInput>>;
  metadata: GenerateAIJourneyTextMetadata;
  useMessageEvaluation: InputMaybe<Scalars['Boolean']['input']>;
};

export type GenerateAIJourneyTextMetadata = {
  n: InputMaybe<Scalars['Int']['input']>;
  origin: InputMaybe<Scalars['String']['input']>;
  templateInput: GenerativeAITextTemplateInput;
  vendorOptions: InputMaybe<TextGenVendorOptions>;
};

export type GenerateAIJourneyTextPayload = DefaultErrorFailure | GenerateAIJourneyTextSuccess;

/** Generated from com/attentivemobile/contracts/messaging/automation/autojourneys/preview/GenerateAIJourneyTextContract.proto */
export type GenerateAIJourneyTextSuccess = {
  __typename?: 'GenerateAIJourneyTextSuccess';
  generatedTextResponses: Array<AIGeneratedText>;
};

export type GenerateAITextInput = {
  companyId: Scalars['ID']['input'];
  /** Number of responses. */
  n: InputMaybe<Scalars['Int']['input']>;
  origin: AIGeneratedTextOrigin;
  prompt: InputMaybe<Array<Scalars['String']['input']>>;
  promptRefinementOptions: InputMaybe<Array<PromptRefinementOption>>;
  template: InputMaybe<GenerativeAITextTemplateInput>;
  useMessageEvaluation: InputMaybe<Scalars['Boolean']['input']>;
  vendorOptions: InputMaybe<TextGenVendorOptions>;
};

/** Generated from com/attentivemobile/contracts/generativeaiservice/text/TextGenerationContract.proto */
export type GenerateAITextPayload = {
  __typename?: 'GenerateAITextPayload';
  generatedTextResponses: Array<AIGeneratedText>;
};

export type GenerateAITextV2Input = {
  brandVoiceSettings: InputMaybe<AIBrandVoiceSettingsInputType>;
  companyId: Scalars['ID']['input'];
  messages: InputMaybe<Array<ChatMessageInput>>;
  metadata: GeneratedTextInputMetadata;
  useMessageEvaluation: InputMaybe<Scalars['Boolean']['input']>;
};

export type GenerateAITextV2Payload = DefaultErrorFailure | GenerateAITextV2Success;

/** Generated from com/attentivemobile/contracts/generativeaiservice/text/TextGenerationContract.proto */
export type GenerateAITextV2Success = {
  __typename?: 'GenerateAITextV2Success';
  generatedTextResponses: Array<AIGeneratedText>;
};

export type GenerateChallengeForAdminAccessInput = {
  email: Scalars['String']['input'];
};

export type GenerateChallengeForAdminAccessPayload = DefaultErrorFailure | GenerateChallengeForAdminAccessSuccess;

/**
 * Generated from com/attentivemobile/contracts/account-service/AuthenticatorContract.proto
 * This will be the challenge for the user to pass to get a new JWT token for admin access
 */
export type GenerateChallengeForAdminAccessSuccess = {
  __typename?: 'GenerateChallengeForAdminAccessSuccess';
  nonce: Scalars['String']['output'];
};

export type GenerateImageInput = {
  sceneCreationInput: InputMaybe<SceneCreationInput>;
  transformationType: ImageGenerationType;
};

/** Generated from com/attentivemobile/contracts/composition/generation/Contract.proto */
export type GenerateImagePayload = {
  __typename?: 'GenerateImagePayload';
  transformedImageUrls: Array<Url>;
};

export type GenerateLayoutInput = {
  companyId: Scalars['ID']['input'];
  promptText: Scalars['String']['input'];
};

export type GenerateLayoutPayload = DefaultErrorFailure | GenerateLayoutSuccess;

/** Generated from com/attentivemobile/contracts/composition/Contract.proto */
export type GenerateLayoutSuccess = {
  __typename?: 'GenerateLayoutSuccess';
  columns: Array<BEEColumn>;
  promptText: Scalars['String']['output'];
};

export type GenerateMobileWalletAnalyticsReportInput = {
  campaignId: Scalars['String']['input'];
  companyId: Scalars['ID']['input'];
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
};

/** Generated from com/attentivemobile/contracts/mobilewalletservice/Contract.proto */
export type GenerateMobileWalletAnalyticsReportPayload = {
  __typename?: 'GenerateMobileWalletAnalyticsReportPayload';
  status: Scalars['String']['output'];
};

export type GeneratePreSignedUrlForBulkOptOutJobInput = {
  companyId: Scalars['ID']['input'];
  jobType: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/solutions/bulkoptout/SolutionsOptout.proto */
export type GeneratePreSignedUrlForBulkOptOutJobPayload = {
  __typename?: 'GeneratePreSignedUrlForBulkOptOutJobPayload';
  filename: Scalars['String']['output'];
  presignedUrl: Scalars['String']['output'];
};

export type GeneratePresignedS3UrlInput = {
  companyId: Scalars['ID']['input'];
  contentMd5: Scalars['String']['input'];
  contentType: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/brand-kit/FileUpload.proto */
export type GeneratePresignedS3UrlPayload = {
  __typename?: 'GeneratePresignedS3UrlPayload';
  fileUuid: Scalars['String']['output'];
  url: Url;
};

export type GeneratePresignedUrlInput = {
  companyId: Scalars['ID']['input'];
  externalId: Scalars['String']['input'];
};

export type GeneratePresignedUrlPayload = DefaultErrorFailure | GeneratePresignedUrlSuccess;

/** Generated from com/attentivemobile/contracts/segmentation/service/ManualSegmentUpload.proto */
export type GeneratePresignedUrlSuccess = {
  __typename?: 'GeneratePresignedUrlSuccess';
  presignedUrl: Scalars['String']['output'];
};

export type GenerateZendeskJwtInput = {
  type: ZendeskJwtType;
};

/** Generated from com/attentivemobile/contracts/account-service/ZendeskContract.proto */
export type GenerateZendeskJwtPayload = {
  __typename?: 'GenerateZendeskJwtPayload';
  jwt: Scalars['String']['output'];
};

export type GeneratedTextInputMetadata = {
  maxTokens: InputMaybe<Scalars['Int']['input']>;
  n: InputMaybe<Scalars['Int']['input']>;
  origin: InputMaybe<Scalars['String']['input']>;
  temperature: InputMaybe<Scalars['Float']['input']>;
  template: InputMaybe<GenerativeAITextTemplateInput>;
  topP: InputMaybe<Scalars['Float']['input']>;
  vendorOptions: InputMaybe<TextGenVendorOptions>;
};

/** Generated from com/attentivemobile/contracts/generativeaiservice/template/GenerativeAITemplateContract.proto */
export type GenerativeAICopyAssistantTemplate = {
  __typename?: 'GenerativeAICopyAssistantTemplate';
  allVersions: Maybe<Array<Scalars['String']['output']>>;
  created: Scalars['DateTime']['output'];
  liveVersion: Maybe<Scalars['String']['output']>;
  roleBasedContents: Maybe<GetTemplateContentOutput>;
  templateName: Scalars['String']['output'];
  updated: Scalars['DateTime']['output'];
};


/** Generated from com/attentivemobile/contracts/generativeaiservice/template/GenerativeAITemplateContract.proto */
export type GenerativeAICopyAssistantTemplateroleBasedContentsArgs = {
  role: InputMaybe<Scalars['String']['input']>;
  version: InputMaybe<Scalars['String']['input']>;
};

export enum GenerativeAITextFeedbackCategory {
  GenerativeAiTextFeedbackCategoryBadGrammar = 'GENERATIVE_AI_TEXT_FEEDBACK_CATEGORY_BAD_GRAMMAR',
  GenerativeAiTextFeedbackCategoryBrandVoiceMismatch = 'GENERATIVE_AI_TEXT_FEEDBACK_CATEGORY_BRAND_VOICE_MISMATCH',
  GenerativeAiTextFeedbackCategoryContentPromptMismatch = 'GENERATIVE_AI_TEXT_FEEDBACK_CATEGORY_CONTENT_PROMPT_MISMATCH',
  GenerativeAiTextFeedbackCategoryIncludesPii = 'GENERATIVE_AI_TEXT_FEEDBACK_CATEGORY_INCLUDES_PII',
  GenerativeAiTextFeedbackCategoryOffensiveLanguage = 'GENERATIVE_AI_TEXT_FEEDBACK_CATEGORY_OFFENSIVE_LANGUAGE',
  GenerativeAiTextFeedbackCategoryOther = 'GENERATIVE_AI_TEXT_FEEDBACK_CATEGORY_OTHER',
  GenerativeAiTextFeedbackCategoryUnknown = 'GENERATIVE_AI_TEXT_FEEDBACK_CATEGORY_UNKNOWN'
}

export type GenerativeAITextFeedbackInput = {
  feedbackCategory: InputMaybe<GenerativeAITextFeedbackCategory>;
  feedbackType: GenerativeAITextFeedbackType;
  generatedTextId: Scalars['ID']['input'];
  groupId: Scalars['String']['input'];
  messageId: InputMaybe<Scalars['ID']['input']>;
  userId: Scalars['ID']['input'];
};

export enum GenerativeAITextFeedbackType {
  GenerativeAiTextFeedbackTypeABWinner = 'GENERATIVE_AI_TEXT_FEEDBACK_TYPE_A_B_WINNER',
  GenerativeAiTextFeedbackTypeFlagged = 'GENERATIVE_AI_TEXT_FEEDBACK_TYPE_FLAGGED',
  GenerativeAiTextFeedbackTypeGenerateMoreSimilar = 'GENERATIVE_AI_TEXT_FEEDBACK_TYPE_GENERATE_MORE_SIMILAR',
  GenerativeAiTextFeedbackTypeRankedWinner = 'GENERATIVE_AI_TEXT_FEEDBACK_TYPE_RANKED_WINNER',
  GenerativeAiTextFeedbackTypeUnknown = 'GENERATIVE_AI_TEXT_FEEDBACK_TYPE_UNKNOWN'
}

export type GenerativeAITextTemplateInput = {
  templateName: Scalars['String']['input'];
  version: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/event/platform/registration/service/Datastream.proto */
export type GenericBackup = {
  __typename?: 'GenericBackup';
  s3Location: S3Location;
};

/** Generated from com/attentivemobile/contracts/event/platform/registration/service/Transformation.proto */
export type GenericEventRateLimiter = {
  __typename?: 'GenericEventRateLimiter';
  desiredRps: Scalars['Int']['output'];
};

/** Generated from com/attentivemobile/contracts/messaging/automation/aijourneys/onboarding/AIJourneyLinkConfigurationServiceContract.proto */
export type GetAIJourneyLinkConfigurationResponse = {
  __typename?: 'GetAIJourneyLinkConfigurationResponse';
  linkConfiguration: Array<AIJourneyLinkConfiguration>;
};

/** Generated from com/attentivemobile/contracts/messaging/automation/aijourneys/onboarding/AIJourneyLinkConfigServiceContract.proto */
export type GetAIJourneyLinkTrackingParamsResponse = {
  __typename?: 'GetAIJourneyLinkTrackingParamsResponse';
  aiJourneyTrackingParams: Array<AIJourneyTrackingParams>;
};

/** Generated from com/attentivemobile/contracts/account-service/Contract.proto */
export type GetAccountAssignedCompaniesResponse = {
  __typename?: 'GetAccountAssignedCompaniesResponse';
  companies: Array<AccountAssignedCompany>;
};

/** Generated from com/attentivemobile/contracts/creative-service/creative/CreativeGraphService.proto */
export type GetActiveCouponDisplayScreenCreativePayload = {
  __typename?: 'GetActiveCouponDisplayScreenCreativePayload';
  /** Active coupon display screen creative for a company, is not required. Only one can be active at a time. */
  couponDisplayScreenCreative: Maybe<Creative>;
};

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type GetActiveNotificationBannerResponse = {
  __typename?: 'GetActiveNotificationBannerResponse';
  banner: Maybe<NotificationBanner>;
};

/** Generated from com/attentivemobile/contracts/segment/recommendation/service/aipro/Contract.proto */
export type GetAiProSubscriptionStatusPayload = {
  __typename?: 'GetAiProSubscriptionStatusPayload';
  status: AiProSubscriptionStatus;
};

/** Generated from com/attentivemobile/contracts/segment/recommendation/service/aipro/Contract.proto */
export type GetAiProTrialEligibilityStatusPayload = Node & {
  __typename?: 'GetAiProTrialEligibilityStatusPayload';
  company: Maybe<Company>;
  eligibilityStatus: AiProEligibilityStatus;
  id: Scalars['ID']['output'];
  reason: Maybe<AiProIneligibleReason>;
};

/** Generated from com/attentivemobile/contracts/generativeaiservice/template/GenerativeAITemplateContract.proto */
export type GetAllVersionsForTemplatePayload = {
  __typename?: 'GetAllVersionsForTemplatePayload';
  versions: Array<Scalars['String']['output']>;
};

/** Generated from com/attentivemobile/contracts/reports/api/CustomReport.proto */
export type GetAllowedCompanyReportRecipientsResponse = {
  __typename?: 'GetAllowedCompanyReportRecipientsResponse';
  recipients: Array<ReportRecipient>;
};

/** Generated from com/attentivemobile/contracts/phonenumbers/external/Contract.proto */
export type GetApplicationStatusHistoryByPhoneIdResponse = {
  __typename?: 'GetApplicationStatusHistoryByPhoneIdResponse';
  phoneNumberApplicationStatus: PhoneNumberApplicationStatusHistory;
};

/** Generated from com/attentivemobile/contracts/externaltokens/Contract.proto */
export type GetApplicationWebhookEventTypesResponse = {
  __typename?: 'GetApplicationWebhookEventTypesResponse';
  eventTypes: Array<WebhookEventType>;
};

/** Generated from com/attentivemobile/contracts/externaltokens/Contract.proto */
export type GetApplicationWebhookExamplePayloadsResponse = {
  __typename?: 'GetApplicationWebhookExamplePayloadsResponse';
  examplePayloads: Array<Scalars['JSON']['output']>;
};

/** Generated from com/attentivemobile/contracts/phonenumbers/external/Contract.proto */
export type GetAssignedCompaniesResponse = {
  __typename?: 'GetAssignedCompaniesResponse';
  assignedCompanies: Array<Scalars['ID']['output']>;
};

/** Generated from com/attentivemobile/contracts/survey/poll/Contract.proto */
export type GetAudienceCountResponse = {
  __typename?: 'GetAudienceCountResponse';
  count: Scalars['Int']['output'];
};

/** Generated from com/attentivemobile/contracts/survey/poll/Contract.proto */
export type GetAudienceParticipantsResponse = {
  __typename?: 'GetAudienceParticipantsResponse';
  participants: Array<AudienceParticipant>;
};

/** Generated from com/attentivemobile/contracts/account-service/AuthenticatorContract.proto */
export type GetAuthenticatorsPayload = {
  __typename?: 'GetAuthenticatorsPayload';
  authenticators: Array<Authenticator>;
};

/** Generated from com/attentivemobile/contracts/brand-kit/BrandFont.proto */
export type GetAvailableBrandFontsResponse = {
  __typename?: 'GetAvailableBrandFontsResponse';
  availableBrandFonts: Array<AvailableBrandFont>;
};

/** Generated from com/attentivemobile/contracts/concierge/service/Analytics.proto */
export type GetAverageResponseTimeResponse = {
  __typename?: 'GetAverageResponseTimeResponse';
  averageResponseTimeSeconds: Scalars['Long']['output'];
};

/** Generated from com/attentivemobile/contracts/event/platform/batchjobs/service/Contract.proto */
export type GetBatchMigrationStatusResponse = {
  __typename?: 'GetBatchMigrationStatusResponse';
  jobsCompleted: Array<ValidatedMigratedJob>;
  jobsInError: Array<ErroredJob>;
  jobsToMigrate: Array<Scalars['String']['output']>;
};

/** Generated from com/attentivemobile/contracts/personalization/service/aijourneys/onboarding/companyinfo/BlockCompanyInfo.proto */
export type GetBlockCompanyInfoPayload = {
  __typename?: 'GetBlockCompanyInfoPayload';
  blockCompanyInfo: Array<BlockCompanyInfo>;
};

/** Generated from com/attentivemobile/contracts/brand-kit/BrandColorSchemeService.proto */
export type GetBrandColorSchemeResponse = {
  __typename?: 'GetBrandColorSchemeResponse';
  colors: Array<BrandColor>;
};

/** Generated from com/attentivemobile/contracts/brand-kit/BrandElementStyles.proto */
export type GetBrandElementStylesResponse = {
  __typename?: 'GetBrandElementStylesResponse';
  styles: Array<BrandElementStyle>;
};

/** Generated from com/attentivemobile/contracts/brand-kit/BrandImage.proto */
export type GetBrandImagesResponse = {
  __typename?: 'GetBrandImagesResponse';
  images: Array<BrandImage>;
};

/** Generated from com/attentivemobile/contracts/company-service/Contract.proto */
export type GetCompaniesByInternalIdResponse = {
  __typename?: 'GetCompaniesByInternalIdResponse';
  companies: Array<Maybe<Company>>;
};

/** Generated from com/attentivemobile/contracts/company-service/companylink/CompanyLink.proto */
export type GetCompanyLinksPayload = {
  __typename?: 'GetCompanyLinksPayload';
  CompanyLinks: Array<CompanyLink>;
};

/** Generated from com/attentivemobile/contracts/concierge/service/Macro.proto */
export type GetCompanyMacrosResponse = {
  __typename?: 'GetCompanyMacrosResponse';
  macros: Array<UserConversationMacro>;
};

/** Generated from com/attentivemobile/contracts/messaging/automation/aijourneys/configs/Contract.proto */
export type GetConfigResponse = {
  __typename?: 'GetConfigResponse';
  config: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/generativeaiservice/template/GenerativeAITemplateContract.proto */
export type GetCopyAssistantTemplateResponse = {
  __typename?: 'GetCopyAssistantTemplateResponse';
  template: GenerativeAICopyAssistantTemplate;
};

export type GetCreativeSchedulesRequestFilter = {
  endOfDateRange: InputMaybe<Scalars['DateTime']['input']>;
  startOfDateRange: InputMaybe<Scalars['DateTime']['input']>;
  status: InputMaybe<CreativeScheduleStatus>;
  type: InputMaybe<CreativeScheduleType>;
};

export type GetCreativeSchedulesRequestSort = {
  sortDirection: GetCreativeSchedulesSortDirection;
  sortField: GetCreativeSchedulesSortField;
};

export enum GetCreativeSchedulesSortDirection {
  CreativeScheduleSortDirectionAsc = 'CREATIVE_SCHEDULE_SORT_DIRECTION_ASC',
  CreativeScheduleSortDirectionDesc = 'CREATIVE_SCHEDULE_SORT_DIRECTION_DESC',
  CreativeScheduleSortDirectionUnknown = 'CREATIVE_SCHEDULE_SORT_DIRECTION_UNKNOWN'
}

export enum GetCreativeSchedulesSortField {
  CreativeScheduleSortFieldEndTime = 'CREATIVE_SCHEDULE_SORT_FIELD_END_TIME',
  CreativeScheduleSortFieldName = 'CREATIVE_SCHEDULE_SORT_FIELD_NAME',
  CreativeScheduleSortFieldStartTime = 'CREATIVE_SCHEDULE_SORT_FIELD_START_TIME',
  CreativeScheduleSortFieldStatus = 'CREATIVE_SCHEDULE_SORT_FIELD_STATUS',
  CreativeScheduleSortFieldUnknown = 'CREATIVE_SCHEDULE_SORT_FIELD_UNKNOWN'
}

export type GetCronExpressionInput = {
  customExpression: InputMaybe<Scalars['String']['input']>;
  daysOfWeek: InputMaybe<Array<Scalars['Int']['input']>>;
  hourEnd: InputMaybe<Scalars['Int']['input']>;
  hourStart: InputMaybe<Scalars['Int']['input']>;
  hoursOfDay: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** Generated from com/attentivemobile/contracts/eventdestination/cron/service/CronExpression.proto */
export type GetCronExpressionPayload = {
  __typename?: 'GetCronExpressionPayload';
  cronExpression: Scalars['String']['output'];
  readableDisplay: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type GetCsTierResponse = {
  __typename?: 'GetCsTierResponse';
  csTier: Scalars['String']['output'];
};

export type GetCustomCompanyKeywordsFilter = {
  enabled: InputMaybe<Scalars['Boolean']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
};

export type GetCustomCompanyKeywordsSort = {
  direction: InputMaybe<SortDirection>;
  sortBy: InputMaybe<Scalars['String']['input']>;
};

/** Generated from com/attentivemobile/contracts/tactical/companycustomkeywords/CompanyCustomKeywordService.proto */
export type GetCustomKeywordsForCompanyResponse = {
  __typename?: 'GetCustomKeywordsForCompanyResponse';
  customKeywords: Array<CustomKeyword>;
};

/** Generated from com/attentivemobile/contracts/user/profile/api/Contract.proto */
export type GetCustomPropertySegmentsResponse = {
  __typename?: 'GetCustomPropertySegmentsResponse';
  segments: Array<Segment>;
};

/** Generated from com/attentivemobile/contracts/event/platform/registration/service/Contract.proto */
export type GetDatastreamDetailsResponse = {
  __typename?: 'GetDatastreamDetailsResponse';
  datastreamRuntime: DatastreamWithRuntime;
};

/** Generated from com/attentivemobile/contracts/company-service/region/Region.proto */
export type GetDefaultRegionResponse = {
  __typename?: 'GetDefaultRegionResponse';
  defaultRegion: Region;
};

/** Generated from com/attentivemobile/contracts/survey/poll/PollKeywordService.proto */
export type GetDuplicateKeywordsResponse = {
  __typename?: 'GetDuplicateKeywordsResponse';
  keywordPollsMap: Array<KeywordPollsMap>;
};

/** Generated from com/attentivemobile/contracts/solutions/bulkoptout/SolutionsOptout.proto */
export type GetEmailBulkOptOutJobsResponse = {
  __typename?: 'GetEmailBulkOptOutJobsResponse';
  selectedJobs: Array<EmailBulkOptOutJob>;
};

/** Generated from com/attentivemobile/contracts/composition/Contract.proto */
export type GetEnabledAttachmentTypesResponse = {
  __typename?: 'GetEnabledAttachmentTypesResponse';
  attachmentTypes: Array<AttachmentType>;
};

/** Generated from com/attentivemobile/contracts/concierge/service/Escalation.proto */
export type GetEscalationEmailsResponse = {
  __typename?: 'GetEscalationEmailsResponse';
  emails: Array<Scalars['String']['output']>;
};

export type GetFilePublicUrlInput = {
  uuid: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/brand-kit/FileUpload.proto */
export type GetFilePublicUrlPayload = {
  __typename?: 'GetFilePublicUrlPayload';
  url: Url;
};

/** Generated from com/attentivemobile/contracts/generativeaiservice/legal/GenerativeAILegalContract.proto */
export type GetGenerativeAIOptInStatusResponse = {
  __typename?: 'GetGenerativeAIOptInStatusResponse';
  optedIn: Scalars['Boolean']['output'];
};

/** Generated from com/attentivemobile/contracts/solutions/subscribers/SolutionsSubscribers.proto */
export type GetGiveawayWinnersPayload = {
  __typename?: 'GetGiveawayWinnersPayload';
  giveawayWinnerConfigs: Array<GiveawayWinnerConfig>;
};

export type GetJobRecordsFilter = {
  companyId: InputMaybe<Scalars['ID']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
  status: InputMaybe<Scalars['String']['input']>;
};

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type GetJobReportResponse = {
  __typename?: 'GetJobReportResponse';
  report: Maybe<JobReport>;
};

/** Generated from com/attentivemobile/contracts/tagbuildservice/Contract.proto */
export type GetLegacyTagConfigResponse = {
  __typename?: 'GetLegacyTagConfigResponse';
  tagConfig: Array<LegacyTagConfig>;
};

/** Generated from com/attentivemobile/contracts/phonenumbers/external/Contract.proto */
export type GetMessagingServiceByPhoneIdResponse = {
  __typename?: 'GetMessagingServiceByPhoneIdResponse';
  companyMessagingServices: Array<CompanyMessagingService>;
};

/** Generated from com/attentivemobile/contracts/survey/poll/PollKeywordService.proto */
export type GetPollKeywordsResponse = {
  __typename?: 'GetPollKeywordsResponse';
  pollKeywords: Array<PollKeyword>;
};

/** Generated from com/attentivemobile/contracts/survey/poll/Contract.proto */
export type GetPollStatsResponse = {
  __typename?: 'GetPollStatsResponse';
  stats: PollStats;
};

/** Generated from com/attentivemobile/contracts/company-service/region/Region.proto */
export type GetRegionsResponse = {
  __typename?: 'GetRegionsResponse';
  regions: Array<Region>;
};

/** Generated from com/attentivemobile/contracts/account-service/AuthorizationContract.proto */
export type GetRolesResponse = {
  __typename?: 'GetRolesResponse';
  roles: Array<Role>;
};

/** Generated from com/attentivemobile/contracts/eventdestination/telemetry/service/Contract.proto */
export type GetRunReportsResponse = {
  __typename?: 'GetRunReportsResponse';
  reports: Array<RunReport>;
};

/** Generated from com/attentivemobile/contracts/subscriptions/batch/Contract.proto */
export type GetSampleDataResponse = {
  __typename?: 'GetSampleDataResponse';
  columns: Array<SubscriberUploadFileColumn>;
};

/** Generated from com/attentivemobile/contracts/eventdestination/cron/service/CronScheduler.proto */
export type GetScheduleResponse = {
  __typename?: 'GetScheduleResponse';
  cronExpression: Scalars['String']['output'];
  readableDisplay: Scalars['String']['output'];
  timeZone: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/authorization/Contract.proto */
export type GetScopesResponse = {
  __typename?: 'GetScopesResponse';
  scopes: Array<Scope>;
};

/** Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto */
export type GetSegmentableProductPropertiesResponse = {
  __typename?: 'GetSegmentableProductPropertiesResponse';
  productProperties: Array<ProductProperty>;
};

/** Generated from com/attentivemobile/contracts/brand-kit/BrandFont.proto */
export type GetSelectedBrandFontFamiliesResponse = {
  __typename?: 'GetSelectedBrandFontFamiliesResponse';
  selectedBrandFontFamilies: Array<SelectedBrandFontFamily>;
};

/** Generated from com/attentivemobile/contracts/brand-kit/BrandFont.proto */
export type GetSelectedBrandFontsResponse = {
  __typename?: 'GetSelectedBrandFontsResponse';
  selectedBrandFonts: Array<SelectedBrandFont>;
};

/** Generated from com/attentivemobile/contracts/personalization/service/aijourneys/onboarding/autoapply/ShopifyAutoApplyFormatsContract.proto */
export type GetShopifyAutoApplyFormatsPayload = {
  __typename?: 'GetShopifyAutoApplyFormatsPayload';
  discountLinkFormat: DiscountLinkFormat;
};

/** Generated from com/attentivemobile/contracts/survey/poll/Contract.proto */
export type GetSweepstakesFilterResponse = {
  __typename?: 'GetSweepstakesFilterResponse';
  id: Scalars['Int']['output'];
  numToSelect: Scalars['Int']['output'];
  poll: Maybe<Poll>;
  pollKeywords: Array<PollOptionEdge>;
  selectorType: SweepstakesSelectorType;
  subscriberType: SweepstakesSubscriberType;
};

/** Generated from com/attentivemobile/contracts/tagbuildservice/Contract.proto */
export type GetTagConfigReleasesForCompanyResponse = {
  __typename?: 'GetTagConfigReleasesForCompanyResponse';
  releases: Array<TagConfigRelease>;
};

/** Generated from com/attentivemobile/contracts/generativeaiservice/template/GenerativeAITemplateContract.proto */
export type GetTemplateContentOutput = {
  __typename?: 'GetTemplateContentOutput';
  contents: Array<RoleBasedTemplateContent>;
};

/** Generated from com/attentivemobile/contracts/creative-service/template/TemplateService.proto */
export type GetTemplateCreativeRuleDefaultsResponse = {
  __typename?: 'GetTemplateCreativeRuleDefaultsResponse';
  rules: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/integrations/templatizedpostbacks/Contract.proto */
export type GetTemplatizedPostbackResponse = {
  __typename?: 'GetTemplatizedPostbackResponse';
  templatizedPostbacks: Array<TemplatizedPostback>;
};

/** Generated from com/attentivemobile/contracts/messaging/automation/autojourneys/AutoJourneysContract.proto */
export type GetUnifiedAutoJourneyResponse = {
  __typename?: 'GetUnifiedAutoJourneyResponse';
  autoJourney: AutoJourney;
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type GetUserEventsResponse = {
  __typename?: 'GetUserEventsResponse';
  edges: Array<UserEventsEdge>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/externaltokens/Contract.proto */
export type GetWebhookEventsForWebhookResponse = {
  __typename?: 'GetWebhookEventsForWebhookResponse';
  events: Array<WebhookEventType>;
};

/** Generated from com/attentivemobile/contracts/workload/management/service/configuration/Contract.proto */
export type GetWorkloadConfigResponse = {
  __typename?: 'GetWorkloadConfigResponse';
  workloadConfig: WorkloadConfig;
};

/** Generated from com/attentivemobile/contracts/workload/management/service/runner/Contract.proto */
export type GetWorkloadHistoryResponse = {
  __typename?: 'GetWorkloadHistoryResponse';
  apps: Array<WorkloadRun>;
};

/** Generated from com/attentivemobile/contracts/workload/management/service/runner/Contract.proto */
export type GetWorkloadStatesResponse = {
  __typename?: 'GetWorkloadStatesResponse';
  jobStates: Array<WorkloadJobState>;
};

export type GifRequestMetadata = {
  /** Time delay between images measured in hundredths of a second. */
  delay: InputMaybe<Scalars['Int']['input']>;
  /** Height of the final GIF in pixels */
  height: InputMaybe<Scalars['Int']['input']>;
  /** Desired overall image shape */
  orientation: InputMaybe<ImageOrientation>;
  /** Width of the final GIF in pixels */
  width: InputMaybe<Scalars['Int']['input']>;
};

export type GiveMessageFeedbackInput = {
  companyId: Scalars['ID']['input'];
  feedback: Scalars['String']['input'];
  feedbackType: MessageFeedbackType;
  givenBy: MessageFeedbackGivenBy;
  messageId: Scalars['ID']['input'];
  subject: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/concierge/service/Feedback.proto */
export type GiveMessageFeedbackPayload = {
  __typename?: 'GiveMessageFeedbackPayload';
  message: Maybe<Message>;
};

export type GiveawayTermsUploadInput = {
  companyId: Scalars['ID']['input'];
  filename: InputMaybe<Scalars['String']['input']>;
};

/** Generated from com/attentivemobile/contracts/solutions/companyconfiguration/GiveawayTerms.proto */
export type GiveawayTermsUploadPayload = {
  __typename?: 'GiveawayTermsUploadPayload';
  companyName: Maybe<Scalars['String']['output']>;
  presignedUrl: Scalars['String']['output'];
  uploadedFile: Maybe<Scalars['String']['output']>;
};

/** Generated from com/attentivemobile/contracts/solutions/subscribers/SolutionsSubscribers.proto */
export type GiveawayWinnerConfig = {
  __typename?: 'GiveawayWinnerConfig';
  email: Maybe<Scalars['String']['output']>;
  phone: Maybe<Scalars['String']['output']>;
};

export type GlobalMessageTemplatesFilterInput = {
  templateTypes: InputMaybe<Array<MessagingTemplateType>>;
};

/** Generated from com/attentivemobile/contracts/journeys/api/JourneyTrigger.proto */
export type GlobalTriggerSource = {
  __typename?: 'GlobalTriggerSource';
  eventType: EventType;
};

/** Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto */
export type GmailOfferAnnotation = {
  __typename?: 'GmailOfferAnnotation';
  code: Maybe<Scalars['String']['output']>;
  description: Maybe<Scalars['String']['output']>;
  endDateTime: Maybe<Scalars['DateTime']['output']>;
  startDateTime: Maybe<Scalars['DateTime']['output']>;
};

export type GmailOfferAnnotationRequest = {
  code: InputMaybe<Scalars['String']['input']>;
  description: InputMaybe<Scalars['String']['input']>;
  endDateTime: InputMaybe<Scalars['DateTime']['input']>;
  startDateTime: InputMaybe<Scalars['DateTime']['input']>;
};

/** Generated from com/attentivemobile/contracts/company-service/companysetting/Model.proto */
export type GoogleAnalytics = {
  __typename?: 'GoogleAnalytics';
  isEnabled: Scalars['Boolean']['output'];
  utmMedium: Scalars['String']['output'];
  utmSource: Scalars['String']['output'];
};

export type GoogleAnalyticsInput = {
  /** whether google analytics is enabled or not. */
  isEnabled: InputMaybe<Scalars['Boolean']['input']>;
  /** utm medium. */
  utmMedium: InputMaybe<Scalars['String']['input']>;
  /** utm address */
  utmSource: InputMaybe<Scalars['String']['input']>;
};

/** Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto */
export type GraphSearchProductsResponse = {
  __typename?: 'GraphSearchProductsResponse';
  products: Array<Product>;
};

/** Generated from com/attentivemobile/protobuf/common/targeting/segmentation/output/SearchDefinitionOutput.proto */
export type GroupedComparison = {
  __typename?: 'GroupedComparison';
  comparisons: Array<Comparison>;
  operator: SearchOperator;
};

export type GroupedComparisonInput = {
  comparisons: InputMaybe<Array<ComparisonInput>>;
  operator: SearchOperator;
};

/** Generated from com/attentivemobile/contracts/metricsapi/DatasetsContract.proto */
export type GroupedDatasetEdge = {
  __typename?: 'GroupedDatasetEdge';
  cursor: Scalars['String']['output'];
  values: Array<DatasetValue>;
};

/** Generated from com/attentivemobile/contracts/metricsapi/Contract.proto */
export type GroupedMetricValue = {
  __typename?: 'GroupedMetricValue';
  errors: Array<MetricDataError>;
  /**
   * Deprecated, use the groupingDimensions field instead
   * @deprecated deprecated
   */
  groupingDimensionValues: Array<Scalars['String']['output']>;
  /** Dimension values are listed in the same order as the grouping dimensions given in the metrics query */
  groupingDimensions: Array<DimensionValue>;
  /** Not present when any errors are encountered calculating the grouped value */
  value: Maybe<Scalars['Float']['output']>;
};

/** Generated from com/attentivemobile/contracts/metricsapi/Contract.proto */
export type GroupedMetrics = {
  __typename?: 'GroupedMetrics';
  /**
   * Deprecated, use the grouping_dimensions field instead
   * @deprecated deprecated
   */
  dimensionValues: Array<Scalars['String']['output']>;
  groupingDimensions: Array<DimensionValue>;
  values: Array<MetricValue>;
};

/** Generated from com/attentivemobile/contracts/metricsapi/Contract.proto */
export type GroupedMetricsEdge = {
  __typename?: 'GroupedMetricsEdge';
  cursor: Scalars['String']['output'];
  node: GroupedMetrics;
};

export type HTMLPreviewInput = {
  beeEditorJson: JsonStringInput;
  companyId: Scalars['ID']['input'];
};

export type HTMLPreviewPayload = DefaultErrorFailure | HTMLPreviewSuccess;

/** Generated from com/attentivemobile/contracts/composition/Contract.proto */
export type HTMLPreviewSuccess = {
  __typename?: 'HTMLPreviewSuccess';
  htmlContent: Scalars['String']['output'];
};

export enum HasVerb {
  HasVerbHas = 'HAS_VERB_HAS',
  HasVerbHasNot = 'HAS_VERB_HAS_NOT',
  HasVerbUnknown = 'HAS_VERB_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type HeaderSettings = {
  __typename?: 'HeaderSettings';
  expectedColumnNum: Scalars['Int']['output'];
  hasHeader: Scalars['Boolean']['output'];
  rowStartNum: Scalars['Int']['output'];
};

export type HeaderSettingsInput = {
  expectedColumnNum: Scalars['Int']['input'];
  hasHeader: Scalars['Boolean']['input'];
  rowStartNum: Scalars['Int']['input'];
};

/** Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto */
export type HeadingContents = {
  __typename?: 'HeadingContents';
  level: Scalars['Int']['output'];
  styleOverrides: Maybe<BeeModuleStyleOverrides>;
  text: Scalars['String']['output'];
};

export type HideComposeCampaignInput = {
  campaignId: Scalars['ID']['input'];
  companyId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/campaigns/service/Contract.proto */
export type HideComposeCampaignPayload = {
  __typename?: 'HideComposeCampaignPayload';
  campaign: ComposeCampaign;
};

/** Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto */
export type HtmlEditorProperties = {
  __typename?: 'HtmlEditorProperties';
  htmlContent: Scalars['String']['output'];
};

export type HtmlEditorPropertiesInput = {
  htmlContent: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/event/platform/registration/service/Datastream.proto */
export type HttpEndpoint = {
  __typename?: 'HttpEndpoint';
  uri: Scalars['String']['output'];
};

export enum HttpRequestType {
  Delete = 'DELETE',
  Get = 'GET',
  HttpRequestTypeUnknown = 'HTTP_REQUEST_TYPE_UNKNOWN',
  Patch = 'PATCH',
  Post = 'POST',
  Put = 'PUT'
}

export enum IPPoolType {
  IpPoolTypeCampaign = 'IP_POOL_TYPE_CAMPAIGN',
  IpPoolTypeTransactional = 'IP_POOL_TYPE_TRANSACTIONAL',
  IpPoolTypeTriggered = 'IP_POOL_TYPE_TRIGGERED',
  IpPoolTypeUnknown = 'IP_POOL_TYPE_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/asset/library/service/Assets.proto */
export type Image = Node & {
  __typename?: 'Image';
  company: Maybe<Company>;
  createdAt: Scalars['DateTime']['output'];
  displayName: Scalars['String']['output'];
  fileType: Scalars['String']['output'];
  heightPixels: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  parent: Maybe<Folder>;
  sizeBytes: Scalars['Long']['output'];
  storageUrl: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  widthPixels: Scalars['Int']['output'];
};

/** Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto */
export type ImageContents = {
  __typename?: 'ImageContents';
  alt: Scalars['String']['output'];
  href: Scalars['String']['output'];
  src: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/composition/generation/Contract.proto */
export type ImageGenerationOptionsPayload = {
  __typename?: 'ImageGenerationOptionsPayload';
  sceneCreationOptions: Array<SceneCreationOption>;
};

export enum ImageGenerationType {
  ImageGenerationTypeSceneCreation = 'IMAGE_GENERATION_TYPE_SCENE_CREATION',
  ImageGenerationTypeUnknown = 'IMAGE_GENERATION_TYPE_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/product/attribute/service/gql/Contract.proto */
export type ImageLink = {
  __typename?: 'ImageLink';
  created: Scalars['DateTime']['output'];
  height: Maybe<Scalars['Int']['output']>;
  position: Scalars['Int']['output'];
  updated: Scalars['DateTime']['output'];
  url: Scalars['String']['output'];
  width: Maybe<Scalars['Int']['output']>;
};

export enum ImageOrientation {
  ImageOrientationAuto = 'IMAGE_ORIENTATION_AUTO',
  ImageOrientationLandscape = 'IMAGE_ORIENTATION_LANDSCAPE',
  ImageOrientationPortrait = 'IMAGE_ORIENTATION_PORTRAIT',
  ImageOrientationSquare = 'IMAGE_ORIENTATION_SQUARE'
}

export enum ImageStyle {
  ImageStyleDark = 'IMAGE_STYLE_DARK',
  ImageStyleLight = 'IMAGE_STYLE_LIGHT',
  ImageStyleUnknown = 'IMAGE_STYLE_UNKNOWN'
}

export enum ImageType {
  ImageTypeGeneric = 'IMAGE_TYPE_GENERIC',
  ImageTypeLogo = 'IMAGE_TYPE_LOGO',
  ImageTypeUnknown = 'IMAGE_TYPE_UNKNOWN'
}

export type ImportCompanyFromSalesforceInput = {
  opportunityId: Scalars['String']['input'];
  primaryContactEmail: Scalars['String']['input'];
  primaryContactFirstName: Scalars['String']['input'];
  primaryContactLastName: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/company-service/onboarding/Company.proto */
export type ImportCompanyFromSalesforcePayload = {
  __typename?: 'ImportCompanyFromSalesforcePayload';
  company: Maybe<Company>;
};

/** Generated from com/attentivemobile/contracts/segment/recommendation/service/Contract.proto */
export type InProgressSegmentEnhancementWorkflowStatus = {
  __typename?: 'InProgressSegmentEnhancementWorkflowStatus';
  startTime: Scalars['DateTime']['output'];
};

export type IncludeExcludeSearch = {
  excludeAudienceSearchDefinition: InputMaybe<Array<UserSearchDefinition>>;
  includeAudienceSearchDefinition: InputMaybe<Array<UserSearchDefinition>>;
};

/** Generated from com/attentivemobile/protobuf/common/Chunk.proto */
export type InclusionProductAffinityAlphaChunk = {
  __typename?: 'InclusionProductAffinityAlphaChunk';
  mlCutoffPercentile: Scalars['Int']['output'];
  /** @deprecated deprecated */
  productFilter: AudienceAiProductFilter;
  productOriginIds: Array<ProductOriginID>;
  purchasePropensityModelVersion: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/protobuf/common/Chunk.proto */
export type InclusionUserPurchasePropensityChunk = {
  __typename?: 'InclusionUserPurchasePropensityChunk';
  mlCutoffPercentile: Scalars['Int']['output'];
};

/** Generated from com/attentivemobile/contracts/journeys/api/RevisionStep.proto */
export type IncomingTextStep = {
  __typename?: 'IncomingTextStep';
  stepConfig: IncomingTextStepConfig;
};

/** Generated from com/attentivemobile/contracts/journeys/api/RevisionStepConfig.proto */
export type IncomingTextStepConfig = {
  __typename?: 'IncomingTextStepConfig';
  reservedKeywords: Array<ReservedKeyword>;
};

export enum IneligibilityReason {
  IneligibilityReasonAutoSuppressed = 'INELIGIBILITY_REASON_AUTO_SUPPRESSED',
  IneligibilityReasonKnownLitigator = 'INELIGIBILITY_REASON_KNOWN_LITIGATOR',
  IneligibilityReasonNotFullyOptedIn = 'INELIGIBILITY_REASON_NOT_FULLY_OPTED_IN',
  IneligibilityReasonOptedOut = 'INELIGIBILITY_REASON_OPTED_OUT',
  IneligibilityReasonUnknown = 'INELIGIBILITY_REASON_UNKNOWN',
  IneligibilityReasonUserSuppressed = 'INELIGIBILITY_REASON_USER_SUPPRESSED'
}

export type InitializeAuthenticatorEnrollmentInput = {
  email: Scalars['String']['input'];
};

export type InitializeAuthenticatorEnrollmentPayload = DefaultErrorFailure | InitializeAuthenticatorEnrollmentSuccess;

/** Generated from com/attentivemobile/contracts/account-service/AuthenticatorContract.proto */
export type InitializeAuthenticatorEnrollmentSuccess = {
  __typename?: 'InitializeAuthenticatorEnrollmentSuccess';
  nonce: Scalars['String']['output'];
};

export type InitiateCognitoAuthInput = {
  companyId: Scalars['ID']['input'];
  temporaryPassword: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/solutions/sftp/SftpUser.proto */
export type InitiateCognitoAuthPayload = {
  __typename?: 'InitiateCognitoAuthPayload';
  sessiontoken: Scalars['String']['output'];
};

export type InsertCompanyCodesInput = {
  code: InputMaybe<Array<Scalars['String']['input']>>;
  companyId: Scalars['ID']['input'];
  handler: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/creative-service/creative/LegacyCreativeService.proto */
export type InsertCompanyCodesPayload = {
  __typename?: 'InsertCompanyCodesPayload';
  code: Array<CompanyCode>;
};

export type InsertCreativeAuditInput = {
  auditTimestamp: Scalars['String']['input'];
  creativeId: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/creative-service/audit/CreativeAuditService.proto */
export type InsertCreativeAuditPayload = {
  __typename?: 'InsertCreativeAuditPayload';
  inserted: Scalars['Boolean']['output'];
};

/** Generated from com/attentivemobile/contracts/tagbuildservice/taghealth/Contract.proto */
export type Inspection = {
  __typename?: 'Inspection';
  company: Maybe<Company>;
  created: Scalars['DateTime']['output'];
  inspectionException: Maybe<InspectionException>;
  inspectionResult: Maybe<InspectionResult>;
  inspectionSuccessful: Scalars['Boolean']['output'];
  updated: Scalars['DateTime']['output'];
};

/** Generated from com/attentivemobile/contracts/tagbuildservice/taghealth/Contract.proto */
export type InspectionException = {
  __typename?: 'InspectionException';
  exceptionMessage: Scalars['String']['output'];
  exceptionType: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/tagbuildservice/taghealth/Contract.proto */
export type InspectionResult = {
  __typename?: 'InspectionResult';
  bigcommerce: Scalars['Boolean']['output'];
  dtagInstalled: Scalars['Boolean']['output'];
  facebookPixelInstalled: Scalars['Boolean']['output'];
  googleAnalyticsInstalled: Scalars['Boolean']['output'];
  googleTagManagerInstalled: Scalars['Boolean']['output'];
  klaviyo: Scalars['Boolean']['output'];
  legacySnippetInstalled: Scalars['Boolean']['output'];
  legacyTagInstalled: Scalars['Boolean']['output'];
  modernTagInstalled: Scalars['Boolean']['output'];
  shopify: Scalars['Boolean']['output'];
  shopifyAutoinstalled: Scalars['Boolean']['output'];
  tagVersion: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

/**
 * Generated from com/attentivemobile/contracts/externaltokens/Contract.proto
 * Installed application details.
 */
export type InstalledApplication = Node & {
  __typename?: 'InstalledApplication';
  /** The application which was installed */
  application: Maybe<Application>;
  /** The ID of the installed Application. */
  id: Scalars['ID']['output'];
  /** The company this application was installed in */
  installerCompany: Maybe<Company>;
  needsUpdate: Scalars['Boolean']['output'];
  /** Webhook details for this installed application. */
  webhooks: Maybe<Array<Webhook>>;
};

/** Generated from com/attentivemobile/contracts/externaltokens/Contract.proto */
export type InstalledApplicationConnection = {
  __typename?: 'InstalledApplicationConnection';
  edges: Array<InstalledApplicationEdge>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/externaltokens/Contract.proto */
export type InstalledApplicationEdge = {
  __typename?: 'InstalledApplicationEdge';
  cursor: Scalars['String']['output'];
  node: InstalledApplication;
};

/** Generated from com/attentivemobile/contracts/integrations/v2/Contract.proto */
export type IntegrationError = Node & {
  __typename?: 'IntegrationError';
  company: Maybe<Company>;
  companyVendorIntegration: Maybe<CompanyVendorIntegration>;
  companyVendorIntegrationFeature: Maybe<CompanyVendorIntegrationFeature>;
  created: Scalars['DateTime']['output'];
  externalVendorApi: Maybe<ExternalVendorApi>;
  id: Scalars['ID']['output'];
  resolved: Maybe<Scalars['DateTime']['output']>;
  type: IntegrationErrorType;
  updated: Scalars['DateTime']['output'];
};

export enum IntegrationErrorType {
  IntegrationErrorTypeAuthenticationBadAttentiveCredentials = 'INTEGRATION_ERROR_TYPE_AUTHENTICATION_BAD_ATTENTIVE_CREDENTIALS',
  IntegrationErrorTypeAuthenticationBadVendorCredentials = 'INTEGRATION_ERROR_TYPE_AUTHENTICATION_BAD_VENDOR_CREDENTIALS',
  IntegrationErrorTypeAuthorizationPermissionsInsufficient = 'INTEGRATION_ERROR_TYPE_AUTHORIZATION_PERMISSIONS_INSUFFICIENT',
  IntegrationErrorTypeConfigurationShopifyPlanStatusMismatch = 'INTEGRATION_ERROR_TYPE_CONFIGURATION_SHOPIFY_PLAN_STATUS_MISMATCH',
  IntegrationErrorTypeEmailListNotFound = 'INTEGRATION_ERROR_TYPE_EMAIL_LIST_NOT_FOUND',
  IntegrationErrorTypeLowProductIdMatchRate = 'INTEGRATION_ERROR_TYPE_LOW_PRODUCT_ID_MATCH_RATE',
  IntegrationErrorTypeShopifyDiscountUnavailableError = 'INTEGRATION_ERROR_TYPE_SHOPIFY_DISCOUNT_UNAVAILABLE_ERROR',
  IntegrationErrorTypeShopifyUnavailableShopName = 'INTEGRATION_ERROR_TYPE_SHOPIFY_UNAVAILABLE_SHOP_NAME',
  IntegrationErrorTypeUnknown = 'INTEGRATION_ERROR_TYPE_UNKNOWN'
}

export type IntegrationEventTypeInput = {
  integrationEvent: EventType;
  messageChannel: InputMaybe<MessagingChannel>;
  subscriptionChannel: InputMaybe<SubscriptionChannel>;
};

/** Generated from com/attentivemobile/contracts/integrations/v2/Contract.proto */
export type IntegrationMetadataMapEntry = {
  __typename?: 'IntegrationMetadataMapEntry';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export enum IntegrationType {
  CustomIntegration = 'CUSTOM_INTEGRATION',
  IntegrationNotSpecified = 'INTEGRATION_NOT_SPECIFIED',
  OverrideIntegration = 'OVERRIDE_INTEGRATION'
}

/** Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto */
export type InvalidCouponCodeUsage = {
  __typename?: 'InvalidCouponCodeUsage';
  couponCode: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/subscriptions/batch/Model.proto */
export type InvalidEnumValuesEntry = {
  __typename?: 'InvalidEnumValuesEntry';
  key: Scalars['String']['output'];
  value: Scalars['Long']['output'];
};

/** Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto */
export type InvalidLink = {
  __typename?: 'InvalidLink';
  invalidLink: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto */
export type InvalidLiquidSyntax = {
  __typename?: 'InvalidLiquidSyntax';
  liquidExpression: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto */
export type InvalidMacroUsages = {
  __typename?: 'InvalidMacroUsages';
  macro: Array<Scalars['String']['output']>;
};

/** Generated from com/attentivemobile/contracts/geography/Location.proto */
export type InvalidPostalCode = {
  __typename?: 'InvalidPostalCode';
  postalCode: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/product/attribute/service/gql/Contract.proto */
export type InventoryAttribute = {
  __typename?: 'InventoryAttribute';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export enum InventoryComparator {
  InventoryComparatorGreaterThan = 'INVENTORY_COMPARATOR_GREATER_THAN',
  InventoryComparatorLessThan = 'INVENTORY_COMPARATOR_LESS_THAN'
}

export enum IsVerb {
  IsVerbIs = 'IS_VERB_IS',
  IsVerbIsNot = 'IS_VERB_IS_NOT',
  IsVerbUnknown = 'IS_VERB_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type JobErrorReport = {
  __typename?: 'JobErrorReport';
  errors: Array<ErrorReport>;
  jobRunUuid: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type JobErrorReportResponse = {
  __typename?: 'JobErrorReportResponse';
  runs: Array<RunsEntry>;
};

export enum JobHealth {
  JobhealthHealthy = 'JOBHEALTH_HEALTHY',
  JobhealthUnhealthyJobBackpressure = 'JOBHEALTH_UNHEALTHY_JOB_BACKPRESSURE',
  JobhealthUnhealthyRecentError = 'JOBHEALTH_UNHEALTHY_RECENT_ERROR',
  JobhealthUnhealthyYarnAppFailure = 'JOBHEALTH_UNHEALTHY_YARN_APP_FAILURE',
  JobhealthUnknown = 'JOBHEALTH_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type JobHistory = {
  __typename?: 'JobHistory';
  context: Maybe<Scalars['JSON']['output']>;
  parentTaskId: Maybe<Scalars['String']['output']>;
  runDate: Maybe<Scalars['DateTime']['output']>;
  scheduledDate: Scalars['DateTime']['output'];
  status: Scalars['String']['output'];
  taskId: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type JobHistoryConnection = {
  __typename?: 'JobHistoryConnection';
  edges: Array<JobHistoryEdge>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type JobHistoryEdge = {
  __typename?: 'JobHistoryEdge';
  cursor: Scalars['String']['output'];
  node: JobHistory;
};

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type JobRecord = Node & {
  __typename?: 'JobRecord';
  company: Maybe<Company>;
  created: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  ingestionFormat: Maybe<EdsJobIngestionFormat>;
  internalId: Scalars['String']['output'];
  jobType: Scalars['String']['output'];
  lastExecutedRun: Maybe<JobHistory>;
  name: Scalars['String']['output'];
  nextRun: Maybe<Scalars['DateTime']['output']>;
  previousRun: Maybe<Scalars['DateTime']['output']>;
  sourceInfo: Maybe<Scalars['String']['output']>;
  sourceNodeUuid: Maybe<Scalars['String']['output']>;
  sourceType: Maybe<Scalars['String']['output']>;
  status: Maybe<Scalars['String']['output']>;
  sync: Maybe<EdsJobSync>;
  syncMethod: Maybe<EdsJobSyncMethod>;
  targetInfo: Maybe<Scalars['String']['output']>;
  targetNodeUuid: Maybe<Scalars['String']['output']>;
  targetType: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type JobRecordConnection = {
  __typename?: 'JobRecordConnection';
  edges: Array<JobRecordEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Long']['output'];
};

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type JobRecordEdge = {
  __typename?: 'JobRecordEdge';
  cursor: Scalars['String']['output'];
  node: JobRecord;
};

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type JobReport = {
  __typename?: 'JobReport';
  endTime: Scalars['String']['output'];
  errorMsg: Scalars['String']['output'];
  failure: Scalars['Long']['output'];
  requests: Scalars['Long']['output'];
  startTime: Scalars['String']['output'];
  success: Scalars['Long']['output'];
  taskId: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type JobReportEdge = {
  __typename?: 'JobReportEdge';
  cursor: Scalars['String']['output'];
  node: JobReport;
};

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type JobReportsConnection = {
  __typename?: 'JobReportsConnection';
  edges: Array<JobReportEdge>;
  pageInfo: PageInfo;
};

export enum JobStatus {
  JobstatusFlapping = 'JOBSTATUS_FLAPPING',
  JobstatusRunning = 'JOBSTATUS_RUNNING',
  JobstatusStarting = 'JOBSTATUS_STARTING',
  JobstatusStopped = 'JOBSTATUS_STOPPED',
  JobstatusUnknown = 'JOBSTATUS_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type JoinEvent = {
  __typename?: 'JoinEvent';
  /** @deprecated deprecated */
  created: Scalars['DateTime']['output'];
  /** @deprecated deprecated */
  id: Scalars['String']['output'];
  subscription: Subscription;
};

/** Generated from com/attentivemobile/contracts/journeys/api/Contract.proto */
export type Journey = Node & {
  __typename?: 'Journey';
  approvalHash: Scalars['String']['output'];
  approvalStatus: ApprovalStatus;
  company: Maybe<Company>;
  conciergeJourneyControls: Maybe<ConciergeJourneyControls>;
  /** @deprecated deprecated */
  currentRevisionId: Scalars['String']['output'];
  description: Scalars['String']['output'];
  draftRevision: Maybe<JourneyRevision>;
  eligibleSubscriptionTypes: Array<SubscriptionType>;
  errors: Array<JourneyRuntimeErrorType>;
  id: Scalars['ID']['output'];
  internalId: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  journeyRevisions: Maybe<Array<JourneyRevision>>;
  journeySchedule: Maybe<JourneySchedule>;
  journeyTags: Array<JourneyTag>;
  journeyTrigger: Maybe<JourneyTrigger>;
  name: Scalars['String']['output'];
  publishedRevision: Maybe<JourneyRevision>;
  /** @deprecated deprecated */
  publishedRevisionInternalId: Maybe<Scalars['String']['output']>;
  stats: Maybe<JourneyStats>;
  subscriptionCategory: Maybe<SubscriptionType>;
  template: Maybe<JourneyTemplate>;
};

export enum JourneyChannel {
  JourneyChannelEmail = 'JOURNEY_CHANNEL_EMAIL',
  JourneyChannelMulti = 'JOURNEY_CHANNEL_MULTI',
  JourneyChannelSms = 'JOURNEY_CHANNEL_SMS',
  JourneyChannelUnknown = 'JOURNEY_CHANNEL_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/journeys/api/Contract.proto */
export type JourneyConnection = {
  __typename?: 'JourneyConnection';
  edges: Array<JourneyEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Long']['output'];
};

export enum JourneyDurationUnit {
  JourneyDurationUnitDays = 'JOURNEY_DURATION_UNIT_DAYS',
  JourneyDurationUnitHours = 'JOURNEY_DURATION_UNIT_HOURS',
  JourneyDurationUnitMinutes = 'JOURNEY_DURATION_UNIT_MINUTES',
  JourneyDurationUnitUnknown = 'JOURNEY_DURATION_UNIT_UNKNOWN',
  JourneyDurationUnitWeeks = 'JOURNEY_DURATION_UNIT_WEEKS'
}

/** Generated from com/attentivemobile/contracts/journeys/api/Contract.proto */
export type JourneyEdge = {
  __typename?: 'JourneyEdge';
  cursor: Scalars['String']['output'];
  node: Journey;
};

/** Generated from com/attentivemobile/contracts/journeys/api/JourneyErrors.proto */
export type JourneyErrors = {
  __typename?: 'JourneyErrors';
  errors: Array<JourneyRuntimeErrorType>;
};

export enum JourneyEventOrigin {
  JourneyEventOriginAttentiveTag = 'JOURNEY_EVENT_ORIGIN_ATTENTIVE_TAG',
  JourneyEventOriginIntegrations = 'JOURNEY_EVENT_ORIGIN_INTEGRATIONS',
  JourneyEventOriginSms = 'JOURNEY_EVENT_ORIGIN_SMS',
  JourneyEventOriginUnknown = 'JOURNEY_EVENT_ORIGIN_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/journeys/api/JourneyRevision.proto */
export type JourneyMessage = {
  __typename?: 'JourneyMessage';
  composeMessage: Maybe<ComposeMessage>;
};

export enum JourneyNodeType {
  JourneyNodeTypeCreateSupportTicket = 'JOURNEY_NODE_TYPE_CREATE_SUPPORT_TICKET',
  JourneyNodeTypeForwardToConcierge = 'JOURNEY_NODE_TYPE_FORWARD_TO_CONCIERGE',
  JourneyNodeTypeIncomingText = 'JOURNEY_NODE_TYPE_INCOMING_TEXT',
  JourneyNodeTypeKeywordResponse = 'JOURNEY_NODE_TYPE_KEYWORD_RESPONSE',
  JourneyNodeTypeOpenResponse = 'JOURNEY_NODE_TYPE_OPEN_RESPONSE',
  JourneyNodeTypeSaveUserAttribute = 'JOURNEY_NODE_TYPE_SAVE_USER_ATTRIBUTE',
  JourneyNodeTypeSendConversationMessage = 'JOURNEY_NODE_TYPE_SEND_CONVERSATION_MESSAGE',
  JourneyNodeTypeSendEmail = 'JOURNEY_NODE_TYPE_SEND_EMAIL',
  JourneyNodeTypeSendLegalWelcome = 'JOURNEY_NODE_TYPE_SEND_LEGAL_WELCOME',
  JourneyNodeTypeSendMessage = 'JOURNEY_NODE_TYPE_SEND_MESSAGE',
  JourneyNodeTypeSendPaymentMessage = 'JOURNEY_NODE_TYPE_SEND_PAYMENT_MESSAGE',
  JourneyNodeTypeTrigger = 'JOURNEY_NODE_TYPE_TRIGGER',
  JourneyNodeTypeTriggerIntegrationPostback = 'JOURNEY_NODE_TYPE_TRIGGER_INTEGRATION_POSTBACK',
  JourneyNodeTypeTwoWayMessage = 'JOURNEY_NODE_TYPE_TWO_WAY_MESSAGE',
  JourneyNodeTypeUnknown = 'JOURNEY_NODE_TYPE_UNKNOWN',
  JourneyNodeTypeVariant = 'JOURNEY_NODE_TYPE_VARIANT'
}

/** Generated from com/attentivemobile/contracts/journeys/api/JourneyRevision.proto */
export type JourneyRevision = Node & {
  __typename?: 'JourneyRevision';
  approvalFlowRequired: Scalars['Boolean']['output'];
  createdTime: Scalars['DateTime']['output'];
  eligibilityDurationSeconds: Scalars['Long']['output'];
  eligibilityDurationUnit: JourneyDurationUnit;
  entryCooldownSeconds: Scalars['Long']['output'];
  entryCooldownUnit: JourneyDurationUnit;
  hasChanges: Scalars['Boolean']['output'];
  hasValidationErrors: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  internalId: Scalars['Long']['output'];
  journey: Maybe<Journey>;
  journeySchedule: Maybe<JourneySchedule>;
  lastUpdatedTime: Scalars['DateTime']['output'];
  messages: Array<JourneyMessage>;
  publishEndTime: Maybe<Scalars['DateTime']['output']>;
  publishStartTime: Maybe<Scalars['DateTime']['output']>;
  publishedStatus: JourneyRevisionPublishedStatus;
};

export enum JourneyRevisionPublishedStatus {
  JourneyRevisionPublishedStatusActive = 'JOURNEY_REVISION_PUBLISHED_STATUS_ACTIVE',
  JourneyRevisionPublishedStatusDraft = 'JOURNEY_REVISION_PUBLISHED_STATUS_DRAFT',
  JourneyRevisionPublishedStatusInactive = 'JOURNEY_REVISION_PUBLISHED_STATUS_INACTIVE',
  JourneyRevisionPublishedStatusScheduled = 'JOURNEY_REVISION_PUBLISHED_STATUS_SCHEDULED',
  JourneyRevisionPublishedStatusUnknown = 'JOURNEY_REVISION_PUBLISHED_STATUS_UNKNOWN'
}

/** Generated from com/attentivemobile/protobuf/common/compose/Message.proto */
export type JourneyRevisionReference = {
  __typename?: 'JourneyRevisionReference';
  journeyRevision: Maybe<JourneyRevision>;
};

/** Generated from com/attentivemobile/contracts/journeys/api/JourneyRevision.proto */
export type JourneyRevisions = {
  __typename?: 'JourneyRevisions';
  journeyRevisions: Array<JourneyRevision>;
};

export enum JourneyRuntimeErrorType {
  JourneyRuntimeErrorTypeCouponPoolExhausted = 'JOURNEY_RUNTIME_ERROR_TYPE_COUPON_POOL_EXHAUSTED',
  JourneyRuntimeErrorTypeReplyToBuyApiKeyInvalid = 'JOURNEY_RUNTIME_ERROR_TYPE_REPLY_TO_BUY_API_KEY_INVALID',
  JourneyRuntimeErrorTypeUnknown = 'JOURNEY_RUNTIME_ERROR_TYPE_UNKNOWN',
  JourneyRuntimeErrorTypeUserProfileAttributeDeleted = 'JOURNEY_RUNTIME_ERROR_TYPE_USER_PROFILE_ATTRIBUTE_DELETED'
}

/** Generated from com/attentivemobile/contracts/journeys/api/JourneySchedule.proto */
export type JourneySchedule = Node & {
  __typename?: 'JourneySchedule';
  assignedJourney: ScheduleAssignedJourney;
  company: Maybe<Company>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  startTime: Maybe<Scalars['DateTime']['output']>;
  status: JourneyScheduleStatus;
};

export enum JourneyScheduleStatus {
  JourneyScheduleStatusCancelled = 'JOURNEY_SCHEDULE_STATUS_CANCELLED',
  JourneyScheduleStatusCompleted = 'JOURNEY_SCHEDULE_STATUS_COMPLETED',
  JourneyScheduleStatusScheduled = 'JOURNEY_SCHEDULE_STATUS_SCHEDULED',
  JourneyScheduleStatusUnknown = 'JOURNEY_SCHEDULE_STATUS_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/concierge/service/Message.proto */
export type JourneySource = {
  __typename?: 'JourneySource';
  journey: Maybe<Journey>;
};

export enum JourneyState {
  JourneyStateActive = 'JOURNEY_STATE_ACTIVE',
  JourneyStateDraft = 'JOURNEY_STATE_DRAFT',
  JourneyStateInactive = 'JOURNEY_STATE_INACTIVE',
  JourneyStateUnknown = 'JOURNEY_STATE_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/journeys/api/JourneyStats.proto */
export type JourneyStats = {
  __typename?: 'JourneyStats';
  attributedRevenue: Money;
  attributedRevenueByCurrency: Array<Money>;
  clicks: Scalars['Long']['output'];
  conversions: Scalars['Long']['output'];
  entered: Scalars['Long']['output'];
  messagesSent: Scalars['Long']['output'];
};

/** Generated from com/attentivemobile/contracts/journeys/api/Contract.proto */
export type JourneyTag = {
  __typename?: 'JourneyTag';
  displayName: Scalars['String']['output'];
  type: JourneyTagDisplayType;
};

export enum JourneyTagDisplayType {
  JourneyTagDisplayTypeDefault = 'JOURNEY_TAG_DISPLAY_TYPE_DEFAULT',
  JourneyTagDisplayTypeUnspecified = 'JOURNEY_TAG_DISPLAY_TYPE_UNSPECIFIED'
}

/** Generated from com/attentivemobile/contracts/journeys/api/JourneyTemplate.proto */
export type JourneyTemplate = Node & {
  __typename?: 'JourneyTemplate';
  channel: JourneyChannel;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  internalId: Scalars['Long']['output'];
  language: Scalars['String']['output'];
  name: Scalars['String']['output'];
  trigger: Maybe<JourneyTrigger>;
};

/** Generated from com/attentivemobile/contracts/journeys/api/JourneyTemplate.proto */
export type JourneyTemplates = {
  __typename?: 'JourneyTemplates';
  journeyTemplates: Array<JourneyTemplate>;
};

/** Generated from com/attentivemobile/contracts/journeys/api/JourneyTrigger.proto */
export type JourneyTrigger = Node & {
  __typename?: 'JourneyTrigger';
  companyLimit: Maybe<Scalars['Long']['output']>;
  count: Maybe<Scalars['Long']['output']>;
  description: Scalars['String']['output'];
  eligibleSubscriptionTypes: Array<SubscriptionType>;
  id: Scalars['ID']['output'];
  internalId: Scalars['Long']['output'];
  isActive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  source: TriggerSource;
  templates: Maybe<Array<JourneyTemplate>>;
  type: JourneyTriggerType;
};


/** Generated from com/attentivemobile/contracts/journeys/api/JourneyTrigger.proto */
export type JourneyTriggercountArgs = {
  companyId: Scalars['ID']['input'];
  journeyState: InputMaybe<JourneyState>;
};

export enum JourneyTriggerType {
  JourneyTriggerTypeAddToCart = 'JOURNEY_TRIGGER_TYPE_ADD_TO_CART',
  JourneyTriggerTypeBackInStock = 'JOURNEY_TRIGGER_TYPE_BACK_IN_STOCK',
  JourneyTriggerTypeCustomEvent = 'JOURNEY_TRIGGER_TYPE_CUSTOM_EVENT',
  JourneyTriggerTypeIntegrationEvent = 'JOURNEY_TRIGGER_TYPE_INTEGRATION_EVENT',
  JourneyTriggerTypeJoinedASegment = 'JOURNEY_TRIGGER_TYPE_JOINED_A_SEGMENT',
  JourneyTriggerTypeLowInventory = 'JOURNEY_TRIGGER_TYPE_LOW_INVENTORY',
  JourneyTriggerTypeOptInBackInStock = 'JOURNEY_TRIGGER_TYPE_OPT_IN_BACK_IN_STOCK',
  JourneyTriggerTypeOrderCreated = 'JOURNEY_TRIGGER_TYPE_ORDER_CREATED',
  JourneyTriggerTypeOrderFulfilled = 'JOURNEY_TRIGGER_TYPE_ORDER_FULFILLED',
  JourneyTriggerTypeOrderSubscriptionChargeFailed = 'JOURNEY_TRIGGER_TYPE_ORDER_SUBSCRIPTION_CHARGE_FAILED',
  JourneyTriggerTypeOrderSubscriptionCreated = 'JOURNEY_TRIGGER_TYPE_ORDER_SUBSCRIPTION_CREATED',
  JourneyTriggerTypeOrderSubscriptionSkipped = 'JOURNEY_TRIGGER_TYPE_ORDER_SUBSCRIPTION_SKIPPED',
  JourneyTriggerTypeOrderSubscriptionUpcomingCharge = 'JOURNEY_TRIGGER_TYPE_ORDER_SUBSCRIPTION_UPCOMING_CHARGE',
  JourneyTriggerTypePageView = 'JOURNEY_TRIGGER_TYPE_PAGE_VIEW',
  JourneyTriggerTypePriceDrop = 'JOURNEY_TRIGGER_TYPE_PRICE_DROP',
  JourneyTriggerTypeProductView = 'JOURNEY_TRIGGER_TYPE_PRODUCT_VIEW',
  JourneyTriggerTypePurchase = 'JOURNEY_TRIGGER_TYPE_PURCHASE',
  JourneyTriggerTypeSignedUp = 'JOURNEY_TRIGGER_TYPE_SIGNED_UP',
  JourneyTriggerTypeSignedUpForEmail = 'JOURNEY_TRIGGER_TYPE_SIGNED_UP_FOR_EMAIL',
  JourneyTriggerTypeTextedAKeyword = 'JOURNEY_TRIGGER_TYPE_TEXTED_A_KEYWORD',
  JourneyTriggerTypeUnknown = 'JOURNEY_TRIGGER_TYPE_UNKNOWN',
  JourneyTriggerTypeUserWinBack = 'JOURNEY_TRIGGER_TYPE_USER_WIN_BACK'
}

/** Generated from com/attentivemobile/contracts/journeys/api/JourneyTrigger.proto */
export type JourneyTriggers = {
  __typename?: 'JourneyTriggers';
  journeyTriggers: Array<JourneyTrigger>;
};

export type JourneysFilter = {
  journeyTriggerId: InputMaybe<Scalars['ID']['input']>;
  nodeType: InputMaybe<Array<JourneyNodeType>>;
  searchTerm: InputMaybe<Scalars['String']['input']>;
  status: InputMaybe<JourneyRevisionPublishedStatus>;
  templateIds: InputMaybe<Array<Scalars['ID']['input']>>;
  /** @deprecated deprecated */
  triggerType: InputMaybe<JourneyTriggerType>;
};

/** Generated from com/attentivemobile/contracts/journeys/api/Contract.proto */
export type JourneysWithNodeTypeResponse = {
  __typename?: 'JourneysWithNodeTypeResponse';
  names: Array<Scalars['String']['output']>;
};

/** Generated from com/attentivemobile/contracts/user/profile/property/UserPropertyV2.proto */
export type JsonPropertyValueV2 = {
  __typename?: 'JsonPropertyValueV2';
  jsonValue: Scalars['JSON']['output'];
};

/** Generated from com/attentivemobile/protobuf/common/Json.proto */
export type JsonString = {
  __typename?: 'JsonString';
  json: Scalars['String']['output'];
};

export type JsonStringInput = {
  json: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/keywords/KeywordService.proto */
export type KeywordInfo = {
  __typename?: 'KeywordInfo';
  attributeName: Maybe<Scalars['String']['output']>;
  company: Maybe<Company>;
  enabled: Scalars['Boolean']['output'];
  hasSiblings: Maybe<Scalars['Boolean']['output']>;
  keyword: Scalars['String']['output'];
  keywordOrdinal: Maybe<Scalars['Int']['output']>;
  ruleCount: Maybe<Scalars['Int']['output']>;
};

/** Generated from com/attentivemobile/contracts/survey/poll/PollKeywordService.proto */
export type KeywordPollBannerInfo = {
  __typename?: 'KeywordPollBannerInfo';
  poll: Maybe<Poll>;
};

/** Generated from com/attentivemobile/contracts/survey/poll/PollKeywordService.proto */
export type KeywordPollsMap = {
  __typename?: 'KeywordPollsMap';
  keyword: Scalars['String']['output'];
  pollInfos: Array<KeywordPollBannerInfo>;
};

/** Generated from com/attentivemobile/contracts/keywords/KeywordRuleService.proto */
export type KeywordRule = Node & {
  __typename?: 'KeywordRule';
  areaCodeRule: Maybe<AreaCodeRule>;
  company: Maybe<Company>;
  created: Maybe<Scalars['DateTime']['output']>;
  customAttributeRule: Maybe<CustomAttributeRule>;
  id: Scalars['ID']['output'];
  keyword: Maybe<CustomCompanyKeyword>;
  marketingSubscriberRule: Maybe<Scalars['Boolean']['output']>;
  ruleExpression: Maybe<Scalars['String']['output']>;
  timeWindowRule: Maybe<TimeWindowRule>;
  updated: Maybe<Scalars['DateTime']['output']>;
};

/** Generated from com/attentivemobile/contracts/keywords/KeywordRuleService.proto */
export type KeywordRulesInfoPayload = {
  __typename?: 'KeywordRulesInfoPayload';
  companyKeywordId: Scalars['Int']['output'];
  ruleCount: Scalars['Int']['output'];
  rulesExpression: Scalars['String']['output'];
  rulesLegend: Array<RulesLegendEntry>;
};

/** Generated from com/attentivemobile/contracts/survey/poll/Contract.proto */
export type KeywordStats = {
  __typename?: 'KeywordStats';
  count: Scalars['Int']['output'];
  formattedPercentage: Maybe<Scalars['String']['output']>;
  keyword: Scalars['String']['output'];
  percentage: Scalars['Float']['output'];
  uuid: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/survey/poll/Contract.proto */
export type KeywordsTimeline = {
  __typename?: 'KeywordsTimeline';
  timelineEntries: Array<TimelineEntry>;
};

export type KillWorkloadInput = {
  appId: Scalars['String']['input'];
  workloadName: Scalars['String']['input'];
};

export type KillWorkloadPayload = DefaultErrorFailure | KillWorkloadSuccess;

/** Generated from com/attentivemobile/contracts/workload/management/service/runner/Contract.proto */
export type KillWorkloadSuccess = {
  __typename?: 'KillWorkloadSuccess';
  workloadName: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/event/platform/registration/service/DatastreamModel.proto */
export type KinesisStream = {
  __typename?: 'KinesisStream';
  streamName: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/product/attribute/service/gql/Contract.proto */
export type KnownProductProperty = {
  __typename?: 'KnownProductProperty';
  knownProductPropertyType: KnownProductPropertyType;
};

export type KnownProductPropertyInput = {
  knownProductPropertyType: KnownProductPropertyType;
};

export enum KnownProductPropertyType {
  KnownProductPropertyTypeBrand = 'KNOWN_PRODUCT_PROPERTY_TYPE_BRAND',
  KnownProductPropertyTypeCategory = 'KNOWN_PRODUCT_PROPERTY_TYPE_CATEGORY',
  KnownProductPropertyTypeCollection = 'KNOWN_PRODUCT_PROPERTY_TYPE_COLLECTION',
  KnownProductPropertyTypeColor = 'KNOWN_PRODUCT_PROPERTY_TYPE_COLOR',
  KnownProductPropertyTypeName = 'KNOWN_PRODUCT_PROPERTY_TYPE_NAME',
  KnownProductPropertyTypePrice = 'KNOWN_PRODUCT_PROPERTY_TYPE_PRICE',
  KnownProductPropertyTypeSize = 'KNOWN_PRODUCT_PROPERTY_TYPE_SIZE',
  KnownProductPropertyTypeTag = 'KNOWN_PRODUCT_PROPERTY_TYPE_TAG',
  KnownProductPropertyTypeUnknown = 'KNOWN_PRODUCT_PROPERTY_TYPE_UNKNOWN'
}

export type LeaveConciergeChatInput = {
  chatId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/concierge/service/Chat.proto */
export type LeaveConciergeChatPayload = {
  __typename?: 'LeaveConciergeChatPayload';
  chatId: Scalars['ID']['output'];
};

/** Generated from com/attentivemobile/contracts/reports/api/Contract.proto */
export type LegacyReport = {
  __typename?: 'LegacyReport';
  active: Scalars['Boolean']['output'];
  company: Maybe<Company>;
  created: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  name: Scalars['String']['output'];
  reportId: Scalars['String']['output'];
  updated: Scalars['DateTime']['output'];
};

/** Generated from com/attentivemobile/contracts/reports/api/Contract.proto */
export type LegacyReportConnection = {
  __typename?: 'LegacyReportConnection';
  edges: Array<LegacyReportEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Generated from com/attentivemobile/contracts/reports/api/Contract.proto */
export type LegacyReportEdge = {
  __typename?: 'LegacyReportEdge';
  cursor: Scalars['String']['output'];
  node: LegacyReport;
};

/** Generated from com/attentivemobile/contracts/reports/api/Contract.proto */
export type LegacyScheduledReport = {
  __typename?: 'LegacyScheduledReport';
  active: Scalars['Boolean']['output'];
  company: Maybe<Company>;
  created: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  recipients: Scalars['String']['output'];
  reportId: Scalars['String']['output'];
  reportInterval: ReportDeliveryFrequency;
  schedulingInterval: ReportDeliveryFrequency;
  startDate: Scalars['DateTime']['output'];
  updated: Scalars['DateTime']['output'];
  userId: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/reports/api/Contract.proto */
export type LegacyScheduledReportConnection = {
  __typename?: 'LegacyScheduledReportConnection';
  edges: Array<LegacyScheduledReportEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Generated from com/attentivemobile/contracts/reports/api/Contract.proto */
export type LegacyScheduledReportEdge = {
  __typename?: 'LegacyScheduledReportEdge';
  cursor: Scalars['String']['output'];
  node: LegacyScheduledReport;
};

/** Generated from com/attentivemobile/contracts/tagbuildservice/Contract.proto */
export type LegacyTagConfig = {
  __typename?: 'LegacyTagConfig';
  configId: Scalars['Long']['output'];
  keyId: Scalars['Int']['output'];
  keyName: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type LegacyTagConfigUpdateInput = {
  keyId: Scalars['Int']['input'];
  value: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/concierge/service/Message.proto */
export type LegalMessageSource = {
  __typename?: 'LegalMessageSource';
  isLegalMessageSource: Scalars['Boolean']['output'];
};

/** Generated from com/attentivemobile/contracts/company-service/companysetting/Model.proto */
export type LinkParameter = {
  __typename?: 'LinkParameter';
  isActive: Scalars['Boolean']['output'];
  isCustom: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  messageChannel: LinkParameterMessageChannel;
  messageType: LinkParameterMessageType;
  value: Scalars['String']['output'];
};

export type LinkParameterInput = {
  isActive: InputMaybe<Scalars['Boolean']['input']>;
  isCustom: InputMaybe<Scalars['Boolean']['input']>;
  key: InputMaybe<Scalars['String']['input']>;
  messageChannel: InputMaybe<LinkParameterMessageChannel>;
  messageType: InputMaybe<LinkParameterMessageType>;
  value: InputMaybe<Scalars['String']['input']>;
};

export enum LinkParameterMessageChannel {
  LinkParameterMessageChannelEmail = 'LINK_PARAMETER_MESSAGE_CHANNEL_EMAIL',
  LinkParameterMessageChannelGlobal = 'LINK_PARAMETER_MESSAGE_CHANNEL_GLOBAL',
  LinkParameterMessageChannelText = 'LINK_PARAMETER_MESSAGE_CHANNEL_TEXT',
  LinkParameterMessageChannelUnknown = 'LINK_PARAMETER_MESSAGE_CHANNEL_UNKNOWN'
}

export enum LinkParameterMessageType {
  LinkParameterMessageTypeCampaign = 'LINK_PARAMETER_MESSAGE_TYPE_CAMPAIGN',
  LinkParameterMessageTypeJourney = 'LINK_PARAMETER_MESSAGE_TYPE_JOURNEY',
  LinkParameterMessageTypeUnknown = 'LINK_PARAMETER_MESSAGE_TYPE_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/personalization/service/aijourneys/defaultmessage/AIJourneyDefaultMessageServiceContract.proto */
export type ListAIJourneyDefaultMessagesResponse = {
  __typename?: 'ListAIJourneyDefaultMessagesResponse';
  defaultMessages: Array<AIJourneyDefaultMessage>;
};

export type ListAdjacentPostalCodesInput = {
  distance: Distance;
  origin: PostalCodeInput;
};

/** Generated from com/attentivemobile/contracts/concierge/service/Template.proto */
export type ListAgentTemplatesResponse = {
  __typename?: 'ListAgentTemplatesResponse';
  recommendedTemplateRequestId: Maybe<Scalars['String']['output']>;
  templates: Array<AgentTemplate>;
};

/** Generated from com/attentivemobile/contracts/messaging/automation/autojourneys/AutoJourneysContract.proto */
export type ListAutoJourneysResponse = {
  __typename?: 'ListAutoJourneysResponse';
  journeys: Array<AutoJourney>;
};

/** Generated from com/attentivemobile/contracts/solutions/clientsegmentimports/ClientSegmentImports.proto */
export type ListClientSegmentImportsResponse = {
  __typename?: 'ListClientSegmentImportsResponse';
  clientSegmentImportsRows: Array<ClientSegmentImportsRow>;
};

/** Generated from com/attentivemobile/contracts/integrations/v2/Contract.proto */
export type ListCompanyVendorIntegrationFeaturesResponse = {
  __typename?: 'ListCompanyVendorIntegrationFeaturesResponse';
  companyVendorIntegrationFeatures: Array<CompanyVendorIntegrationFeature>;
};

/** Generated from com/attentivemobile/contracts/integrations/v2/Contract.proto */
export type ListCompanyVendorIntegrationsResponse = {
  __typename?: 'ListCompanyVendorIntegrationsResponse';
  companyVendorIntegrations: Array<CompanyVendorIntegration>;
};

/** Proxies filtering by status, by using the groupings exposed to our clients in the UI */
export enum ListComposeCampaignUiFilter {
  ListComposeCampaignUiFilterDelivered = 'LIST_COMPOSE_CAMPAIGN_UI_FILTER_DELIVERED',
  ListComposeCampaignUiFilterDraft = 'LIST_COMPOSE_CAMPAIGN_UI_FILTER_DRAFT',
  ListComposeCampaignUiFilterNone = 'LIST_COMPOSE_CAMPAIGN_UI_FILTER_NONE',
  ListComposeCampaignUiFilterPaused = 'LIST_COMPOSE_CAMPAIGN_UI_FILTER_PAUSED',
  ListComposeCampaignUiFilterScheduled = 'LIST_COMPOSE_CAMPAIGN_UI_FILTER_SCHEDULED',
  ListComposeCampaignUiFilterUnknown = 'LIST_COMPOSE_CAMPAIGN_UI_FILTER_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/generativeaiservice/template/GenerativeAITemplateContract.proto */
export type ListCopyAssistantTemplatesPayload = {
  __typename?: 'ListCopyAssistantTemplatesPayload';
  templates: Array<GenerativeAICopyAssistantTemplate>;
};

/** Generated from com/attentivemobile/contracts/geography/Location.proto */
export type ListCountriesResponse = {
  __typename?: 'ListCountriesResponse';
  countries: Array<Country>;
};

/** Generated from com/attentivemobile/contracts/geography/Location.proto */
export type ListCountrySubdivisionsResponse = {
  __typename?: 'ListCountrySubdivisionsResponse';
  countrySubdivisions: Array<CountrySubdivision>;
};

export type ListCreativeExperimentsFilter = {
  status: InputMaybe<Array<CreativeExperimentStatus>>;
};

export type ListCreativeExperimentsFilterProperties = {
  exclude: InputMaybe<ListCreativeExperimentsFilter>;
  include: InputMaybe<ListCreativeExperimentsFilter>;
  searchTerm: InputMaybe<Scalars['String']['input']>;
};

export type ListCreativeExperimentsSort = {
  sortDirection: ListCreativeExperimentsSortDirection;
  sortField: ListCreativeExperimentsSortField;
};

export enum ListCreativeExperimentsSortDirection {
  CreativeExperimentsSortDirectionAsc = 'CREATIVE_EXPERIMENTS_SORT_DIRECTION_ASC',
  CreativeExperimentsSortDirectionDesc = 'CREATIVE_EXPERIMENTS_SORT_DIRECTION_DESC',
  CreativeExperimentsSortDirectionUnknown = 'CREATIVE_EXPERIMENTS_SORT_DIRECTION_UNKNOWN'
}

export enum ListCreativeExperimentsSortField {
  CreativeExperimentsSortFieldClicks = 'CREATIVE_EXPERIMENTS_SORT_FIELD_CLICKS',
  CreativeExperimentsSortFieldCreated = 'CREATIVE_EXPERIMENTS_SORT_FIELD_CREATED',
  CreativeExperimentsSortFieldCtr = 'CREATIVE_EXPERIMENTS_SORT_FIELD_CTR',
  CreativeExperimentsSortFieldCvr = 'CREATIVE_EXPERIMENTS_SORT_FIELD_CVR',
  CreativeExperimentsSortFieldImpressions = 'CREATIVE_EXPERIMENTS_SORT_FIELD_IMPRESSIONS',
  CreativeExperimentsSortFieldInternalId = 'CREATIVE_EXPERIMENTS_SORT_FIELD_INTERNAL_ID',
  CreativeExperimentsSortFieldName = 'CREATIVE_EXPERIMENTS_SORT_FIELD_NAME',
  CreativeExperimentsSortFieldStatus = 'CREATIVE_EXPERIMENTS_SORT_FIELD_STATUS',
  CreativeExperimentsSortFieldStatusLabel = 'CREATIVE_EXPERIMENTS_SORT_FIELD_STATUS_LABEL',
  CreativeExperimentsSortFieldSubscribers = 'CREATIVE_EXPERIMENTS_SORT_FIELD_SUBSCRIBERS',
  CreativeExperimentsSortFieldUnknown = 'CREATIVE_EXPERIMENTS_SORT_FIELD_UNKNOWN',
  CreativeExperimentsSortFieldUpdated = 'CREATIVE_EXPERIMENTS_SORT_FIELD_UPDATED'
}

export type ListCreativesAndExperimentsFilter = {
  archived: InputMaybe<Scalars['Boolean']['input']>;
  canEditRules: InputMaybe<Scalars['Boolean']['input']>;
  countryCode: InputMaybe<Array<Scalars['String']['input']>>;
  couponSetId: InputMaybe<Array<Scalars['ID']['input']>>;
  customAttributeName: InputMaybe<Array<Scalars['String']['input']>>;
  experimentStatus: InputMaybe<Array<CreativeExperimentStatus>>;
  hasActiveExperiment: InputMaybe<Scalars['Boolean']['input']>;
  /** @deprecated deprecated */
  status: InputMaybe<Array<Scalars['String']['input']>>;
  statusLabel: InputMaybe<Array<CreativeStatusLabel>>;
  statusV2: InputMaybe<Array<CreativeStatus>>;
  /** @deprecated deprecated */
  subtype: InputMaybe<Array<Scalars['String']['input']>>;
  subtypeV2: InputMaybe<Array<CreativeSubtype>>;
  /** @deprecated deprecated */
  type: InputMaybe<Array<Scalars['String']['input']>>;
  typeV2: InputMaybe<Array<CreativeType>>;
  userView: InputMaybe<ListCreativesAndExperimentsFilterUserView>;
};

export type ListCreativesAndExperimentsFilterProperties = {
  exclude: InputMaybe<ListCreativesAndExperimentsFilter>;
  include: InputMaybe<ListCreativesAndExperimentsFilter>;
  searchTerm: InputMaybe<Scalars['String']['input']>;
};

export enum ListCreativesAndExperimentsFilterUserView {
  ListCreativesAndExperimentsFilterUserViewSuperuser = 'LIST_CREATIVES_AND_EXPERIMENTS_FILTER_USER_VIEW_SUPERUSER',
  ListCreativesAndExperimentsFilterUserViewUnknown = 'LIST_CREATIVES_AND_EXPERIMENTS_FILTER_USER_VIEW_UNKNOWN'
}

export type ListCreativesAndExperimentsSort = {
  sortDirection: ListCreativesAndExperimentsSortDirection;
  sortField: ListCreativesAndExperimentsSortField;
};

export enum ListCreativesAndExperimentsSortDirection {
  CreativesAndExperimentsSortDirectionAsc = 'CREATIVES_AND_EXPERIMENTS_SORT_DIRECTION_ASC',
  CreativesAndExperimentsSortDirectionDesc = 'CREATIVES_AND_EXPERIMENTS_SORT_DIRECTION_DESC',
  CreativesAndExperimentsSortDirectionUnknown = 'CREATIVES_AND_EXPERIMENTS_SORT_DIRECTION_UNKNOWN'
}

export enum ListCreativesAndExperimentsSortField {
  CreativesAndExperimentsSortFieldClicks = 'CREATIVES_AND_EXPERIMENTS_SORT_FIELD_CLICKS',
  CreativesAndExperimentsSortFieldCountryCode = 'CREATIVES_AND_EXPERIMENTS_SORT_FIELD_COUNTRY_CODE',
  CreativesAndExperimentsSortFieldCreated = 'CREATIVES_AND_EXPERIMENTS_SORT_FIELD_CREATED',
  CreativesAndExperimentsSortFieldCtr = 'CREATIVES_AND_EXPERIMENTS_SORT_FIELD_CTR',
  CreativesAndExperimentsSortFieldCvr = 'CREATIVES_AND_EXPERIMENTS_SORT_FIELD_CVR',
  CreativesAndExperimentsSortFieldImpressions = 'CREATIVES_AND_EXPERIMENTS_SORT_FIELD_IMPRESSIONS',
  CreativesAndExperimentsSortFieldInternalId = 'CREATIVES_AND_EXPERIMENTS_SORT_FIELD_INTERNAL_ID',
  CreativesAndExperimentsSortFieldLanguage = 'CREATIVES_AND_EXPERIMENTS_SORT_FIELD_LANGUAGE',
  CreativesAndExperimentsSortFieldName = 'CREATIVES_AND_EXPERIMENTS_SORT_FIELD_NAME',
  CreativesAndExperimentsSortFieldRules = 'CREATIVES_AND_EXPERIMENTS_SORT_FIELD_RULES',
  CreativesAndExperimentsSortFieldStatus = 'CREATIVES_AND_EXPERIMENTS_SORT_FIELD_STATUS',
  CreativesAndExperimentsSortFieldStatusLabel = 'CREATIVES_AND_EXPERIMENTS_SORT_FIELD_STATUS_LABEL',
  CreativesAndExperimentsSortFieldSubscribers = 'CREATIVES_AND_EXPERIMENTS_SORT_FIELD_SUBSCRIBERS',
  CreativesAndExperimentsSortFieldUnknown = 'CREATIVES_AND_EXPERIMENTS_SORT_FIELD_UNKNOWN',
  CreativesAndExperimentsSortFieldUpdated = 'CREATIVES_AND_EXPERIMENTS_SORT_FIELD_UPDATED'
}

export type ListCreativesFilter = {
  archived: InputMaybe<Scalars['Boolean']['input']>;
  canEditRules: InputMaybe<Scalars['Boolean']['input']>;
  countryCode: InputMaybe<Array<Scalars['String']['input']>>;
  couponSetId: InputMaybe<Array<Scalars['ID']['input']>>;
  customAttributeName: InputMaybe<Array<Scalars['String']['input']>>;
  hasActiveExperiment: InputMaybe<Scalars['Boolean']['input']>;
  /** @deprecated deprecated */
  status: InputMaybe<Array<Scalars['String']['input']>>;
  statusLabel: InputMaybe<Array<CreativeStatusLabel>>;
  statusV2: InputMaybe<Array<CreativeStatus>>;
  /** @deprecated deprecated */
  subtype: InputMaybe<Array<Scalars['String']['input']>>;
  subtypeV2: InputMaybe<Array<CreativeSubtype>>;
  /** @deprecated deprecated */
  type: InputMaybe<Array<Scalars['String']['input']>>;
  typeV2: InputMaybe<Array<CreativeType>>;
  userView: InputMaybe<ListCreativesFilterUserView>;
};

export type ListCreativesFilterProperties = {
  exclude: InputMaybe<ListCreativesFilter>;
  include: InputMaybe<ListCreativesFilter>;
  searchTerm: InputMaybe<Scalars['String']['input']>;
};

export enum ListCreativesFilterUserView {
  ListCreativesFilterUserViewSuperuser = 'LIST_CREATIVES_FILTER_USER_VIEW_SUPERUSER',
  ListCreativesFilterUserViewUnknown = 'LIST_CREATIVES_FILTER_USER_VIEW_UNKNOWN'
}

export type ListCreativesSort = {
  sortDirection: ListCreativesSortDirection;
  sortField: ListCreativesSortField;
};

export enum ListCreativesSortDirection {
  CreativesSortDirectionAsc = 'CREATIVES_SORT_DIRECTION_ASC',
  CreativesSortDirectionDesc = 'CREATIVES_SORT_DIRECTION_DESC',
  CreativesSortDirectionUnknown = 'CREATIVES_SORT_DIRECTION_UNKNOWN'
}

export enum ListCreativesSortField {
  CreativesSortFieldArchived = 'CREATIVES_SORT_FIELD_ARCHIVED',
  CreativesSortFieldClicks = 'CREATIVES_SORT_FIELD_CLICKS',
  CreativesSortFieldCountryCode = 'CREATIVES_SORT_FIELD_COUNTRY_CODE',
  CreativesSortFieldCreated = 'CREATIVES_SORT_FIELD_CREATED',
  CreativesSortFieldCtr = 'CREATIVES_SORT_FIELD_CTR',
  CreativesSortFieldCvr = 'CREATIVES_SORT_FIELD_CVR',
  CreativesSortFieldDisplayRules = 'CREATIVES_SORT_FIELD_DISPLAY_RULES',
  CreativesSortFieldHasCouponSet = 'CREATIVES_SORT_FIELD_HAS_COUPON_SET',
  CreativesSortFieldImpressions = 'CREATIVES_SORT_FIELD_IMPRESSIONS',
  CreativesSortFieldInternalId = 'CREATIVES_SORT_FIELD_INTERNAL_ID',
  CreativesSortFieldLanguage = 'CREATIVES_SORT_FIELD_LANGUAGE',
  CreativesSortFieldName = 'CREATIVES_SORT_FIELD_NAME',
  CreativesSortFieldStatus = 'CREATIVES_SORT_FIELD_STATUS',
  CreativesSortFieldStatusLabel = 'CREATIVES_SORT_FIELD_STATUS_LABEL',
  CreativesSortFieldSubscribers = 'CREATIVES_SORT_FIELD_SUBSCRIBERS',
  CreativesSortFieldSubtype = 'CREATIVES_SORT_FIELD_SUBTYPE',
  CreativesSortFieldType = 'CREATIVES_SORT_FIELD_TYPE',
  CreativesSortFieldUnknown = 'CREATIVES_SORT_FIELD_UNKNOWN',
  CreativesSortFieldUpdated = 'CREATIVES_SORT_FIELD_UPDATED'
}

export type ListCustomEventKeysRequestFilter = {
  customEventType: InputMaybe<Scalars['String']['input']>;
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type ListCustomEventTypesByApplicationResponse = {
  __typename?: 'ListCustomEventTypesByApplicationResponse';
  customEventDefinitions: Array<CustomEventDefinition>;
};

export type ListCustomEventsRequestFilter = {
  customEventType: InputMaybe<Scalars['String']['input']>;
};

/** Generated from com/attentivemobile/contracts/event/platform/registration/service/Contract.proto */
export type ListDatastreams = {
  __typename?: 'ListDatastreams';
  statusInfo: Array<DatastreamStatusInfo>;
};

export type ListDimensionValuesSearchInput = {
  keys: InputMaybe<Array<Scalars['String']['input']>>;
  values: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Generated from com/attentivemobile/contracts/integrations/v2/Contract.proto */
export type ListExternalVendorFeaturesResponse = {
  __typename?: 'ListExternalVendorFeaturesResponse';
  externalVendorFeatures: Array<ExternalVendorFeature>;
};

/** Generated from com/attentivemobile/contracts/integrations/v2/Contract.proto */
export type ListIntegrationErrorsResponse = {
  __typename?: 'ListIntegrationErrorsResponse';
  integrationErrors: Array<IntegrationError>;
};

/** Generated from com/attentivemobile/contracts/concierge/service/Feedback.proto */
export type ListMessageFeedbackResponse = {
  __typename?: 'ListMessageFeedbackResponse';
  messageFeedback: Array<MessageFeedback>;
};

/** Generated from com/attentivemobile/contracts/phonenumbers/external/Contract.proto */
export type ListMessagingServiceResponse = {
  __typename?: 'ListMessagingServiceResponse';
  messagingServices: Array<CompanyMessagingService>;
};

/** Generated from com/attentivemobile/contracts/metricsapi/Contract.proto */
export type ListMetricsResponse = {
  __typename?: 'ListMetricsResponse';
  metrics: Array<Metric>;
};

/** Generated from com/attentivemobile/contracts/company-service/onboarding/Milestone.proto */
export type ListMilestonesResponse = {
  __typename?: 'ListMilestonesResponse';
  /** milestones represent steps an attentive client goes through in their onboarding process */
  milestones: Array<Milestone>;
};

/** Generated from com/attentivemobile/contracts/company-service/onboarding/Survey.proto */
export type ListOnboardingSurveySelectionsResponse = {
  __typename?: 'ListOnboardingSurveySelectionsResponse';
  selections: Array<OnboardingSurveySelection>;
};

export type ListPhoneNumbersFilterInput = {
  applicationStatus: InputMaybe<PhoneApplicationStatus>;
  assignedCompany: InputMaybe<Scalars['String']['input']>;
  assignmentStatus: InputMaybe<PhoneNumberCompanyAssignmentStatus>;
  dateRange: InputMaybe<DateRangeFilter>;
  messagingServiceCompany: InputMaybe<Scalars['String']['input']>;
  messagingServiceStatus: InputMaybe<MessagingServiceStatus>;
  phoneNumber: InputMaybe<Scalars['String']['input']>;
  phoneNumberStatus: InputMaybe<PhoneNumberStatus>;
  provider: InputMaybe<Provider>;
};

export type ListSearchAttributeFieldIdentifier = {
  identifier: InputMaybe<ListSearchAttributeFieldIdentifierIdentifier>;
};

export type ListSearchAttributeFieldIdentifierIdentifier =
  { searchAttributeFieldAlias: Scalars['String']['input']; searchAttributeFieldId?: never; }
  |  { searchAttributeFieldAlias?: never; searchAttributeFieldId: Scalars['ID']['input']; };

export type ListSearchAttributeFieldsFilter = {
  identifiers: InputMaybe<Array<ListSearchAttributeFieldIdentifier>>;
};

export type ListSearchAttributeIdentifier = {
  identifier: InputMaybe<ListSearchAttributeIdentifierIdentifier>;
};

export type ListSearchAttributeIdentifierIdentifier =
  { searchAttributeAlias: Scalars['String']['input']; searchAttributeId?: never; }
  |  { searchAttributeAlias?: never; searchAttributeId: Scalars['ID']['input']; };

export type ListSearchAttributesFilter = {
  identifiers: InputMaybe<Array<ListSearchAttributeIdentifier>>;
};

export type ListSegmentsFilter = {
  name: InputMaybe<StringCondition>;
  statusFilter: InputMaybe<SegmentStatusFilter>;
  tagFilter: InputMaybe<SegmentTagFilter>;
};

export type ListSegmentsOrderBy = {
  column: OrderColumn;
  direction: OrderDirection;
};

/** Generated from com/attentivemobile/contracts/emails/management/Contract.proto */
export type ListSendingDomainsResponse = {
  __typename?: 'ListSendingDomainsResponse';
  sendingDomains: Array<SendingDomain>;
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type ListSupportedCustomPropertyDataTypesResponse = {
  __typename?: 'ListSupportedCustomPropertyDataTypesResponse';
  types: Array<CustomPropertyType>;
};

export type ListSyncsFilter = {
  syncMethod: InputMaybe<EdsJobSyncMethod>;
  syncStatus: InputMaybe<SyncStatus>;
  syncType: InputMaybe<EdsJobSync>;
};

/** Generated from com/attentivemobile/contracts/event/platform/registration/service/Contract.proto */
export type ListTeamsResponse = {
  __typename?: 'ListTeamsResponse';
  teams: Array<DatastreamTeam>;
};

/** Generated from com/attentivemobile/contracts/concierge/service/Macro.proto */
export type ListUserConversationMacrosResponse = {
  __typename?: 'ListUserConversationMacrosResponse';
  macros: Array<UserConversationMacro>;
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type ListUserPropertiesResponse = {
  __typename?: 'ListUserPropertiesResponse';
  propertyData: Array<UserPropertyDefinitionValue>;
};

export type ListUserPropertyDefinitionFilter = {
  customPropertyTypes: InputMaybe<Array<CustomPropertyType>>;
  includeDeleted: InputMaybe<Scalars['Boolean']['input']>;
  /** @deprecated deprecated */
  includeInternalProperties: InputMaybe<Scalars['Boolean']['input']>;
  source: InputMaybe<UserPropertySource>;
};

export type ListUsersClickEventFilter = {
  hasVerb: HasVerb;
  timeCondition: TimeCondition;
};

export type ListUsersFilter = {
  clickEventFilter: InputMaybe<ListUsersClickEventFilter>;
  location: InputMaybe<ListUsersLocationFilter>;
  purchaseEventFilter: InputMaybe<PurchaseEventFilter>;
  searchFilter: InputMaybe<UserSearchFilter>;
  segmentId: InputMaybe<Scalars['ID']['input']>;
  /** @deprecated deprecated */
  segmentInternalId: InputMaybe<Scalars['String']['input']>;
  status: InputMaybe<UserStatus>;
  subscriptionOptInCompletedFilter: InputMaybe<ListUsersSubscriptionOptInCompletedFilter>;
};

export type ListUsersLocationFilter = {
  isVerb: IsVerb;
  isoCodes: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ListUsersSubscriptionOptInCompletedFilter = {
  hasVerb: HasVerb;
  timeCondition: TimeCondition;
};

/**
 * Generated from com/attentivemobile/contracts/externaltokens/Webhooks.proto
 * Make an API call to this mutation to list existing webhooks.
 */
export type ListWebhookResponse = {
  __typename?: 'ListWebhookResponse';
  /** Webhook details. */
  webhooks: Array<Webhook>;
};

/** Generated from com/attentivemobile/contracts/workload/management/service/configuration/Contract.proto */
export type ListWorkloadConfigsResponse = {
  __typename?: 'ListWorkloadConfigsResponse';
  workloadConfigs: Array<WorkloadConfig>;
};

/** Generated from com/attentivemobile/protobuf/common/targeting/UserProperty.proto */
export type LocalDateFields = {
  __typename?: 'LocalDateFields';
  hasDay: Scalars['Boolean']['output'];
  hasMonth: Scalars['Boolean']['output'];
  hasYear: Scalars['Boolean']['output'];
};

/** Generated from com/attentivemobile/contracts/keywords/KeywordRuleService.proto */
export type LocalTimeMessage = {
  __typename?: 'LocalTimeMessage';
  hour: Scalars['Int']['output'];
  minute: Scalars['Int']['output'];
  second: Scalars['Int']['output'];
};

/** Locale of the user. */
export type LocaleForSubscribeInput = {
  /** Two-letter country code of the locale. (ISO 3166-1 alpha 2)[https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2] is required. Example=US */
  country: Scalars['String']['input'];
  /** Two-letter language code of the locale. (ISO 639-1 alpha 2)[https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes] is required. Example=en */
  language: Scalars['String']['input'];
};

export type LocaleInput = {
  languageCode: Scalars['String']['input'];
  regionCode: Scalars['String']['input'];
  variant: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/company-service/region/Model.proto */
export type LocalizedSettings = {
  __typename?: 'LocalizedSettings';
  autoResponseEnabled: Scalars['Boolean']['output'];
  autoUnsubscribeMessageEnabled: Scalars['Boolean']['output'];
  automatedMessages: Array<AutomatedMessage>;
  doubleOptInMessageEnabled: Scalars['Boolean']['output'];
  isPrimary: Scalars['Boolean']['output'];
  /** IETF BCP 47 language tag. e.g. en-US, en-CA, en-GB, etc. */
  languageTag: Scalars['String']['output'];
  legalMessageEnabled: Scalars['Boolean']['output'];
};

export type LocalizedSettingsInput = {
  autoResponseEnabled: InputMaybe<Scalars['Boolean']['input']>;
  autoUnsubscribeMessageEnabled: InputMaybe<Scalars['Boolean']['input']>;
  automatedMessages: InputMaybe<Array<AutomatedMessageInput>>;
  doubleOptInMessageEnabled: InputMaybe<Scalars['Boolean']['input']>;
  isPrimary: InputMaybe<Scalars['Boolean']['input']>;
  /** IETF BCP 47 language tag. e.g. en-US, en-CA, en-GB, etc. */
  languageTag: Scalars['String']['input'];
  legalMessageEnabled: InputMaybe<Scalars['Boolean']['input']>;
};

export type LoginWithMfaTokenInput = {
  mfaToken: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/account-service/MFAContract.proto */
export type LoginWithMfaTokenPayload = {
  __typename?: 'LoginWithMfaTokenPayload';
  jwtToken: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/account-service/AuthenticationContract.proto */
export type LoginWithPasswordFailureResponse = {
  __typename?: 'LoginWithPasswordFailureResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type LoginWithPasswordInput = {
  deviceId: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/account-service/AuthenticationContract.proto */
export type LoginWithPasswordMfaResponse = {
  __typename?: 'LoginWithPasswordMfaResponse';
  mfaEmailHint: Maybe<Scalars['String']['output']>;
  /** If true, the user already has MFA configured. If false they must set it up. */
  mfaEnabled: Scalars['Boolean']['output'];
  mfaPhoneNumberHint: Maybe<Scalars['String']['output']>;
  mfaToken: Scalars['String']['output'];
  preferredMfaMethod: PreferredMfaMethod;
};

/** Generated from com/attentivemobile/contracts/account-service/AuthenticationContract.proto */
export type LoginWithPasswordPayload = {
  __typename?: 'LoginWithPasswordPayload';
  response: Maybe<LoginWithPasswordPayloadResponse>;
};

export type LoginWithPasswordPayloadResponse = LoginWithPasswordFailureResponse | LoginWithPasswordMfaResponse | LoginWithPasswordSuccessResponse;

/** Generated from com/attentivemobile/contracts/account-service/AuthenticationContract.proto */
export type LoginWithPasswordSuccessResponse = {
  __typename?: 'LoginWithPasswordSuccessResponse';
  jwtToken: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/user/profile/property/UserPropertyV2.proto */
export type LongValueV2 = {
  __typename?: 'LongValueV2';
  longValue: Scalars['Long']['output'];
};

/** Generated from com/attentivemobile/contracts/composition/MessageAttachment.proto */
export type MacroAttribute = {
  __typename?: 'MacroAttribute';
  behavior: MacroBehavior;
  displayName: Scalars['String']['output'];
  extension: Scalars['String']['output'];
  macroAttributes: Array<MacroAttribute>;
  macroBehaviorConfig: Maybe<MacroAttributeMacroBehaviorConfig>;
};

export type MacroAttributeMacroBehaviorConfig = EnumMacroBehaviorConfig;

export enum MacroBehavior {
  MacroBehaviorBoolean = 'MACRO_BEHAVIOR_BOOLEAN',
  MacroBehaviorDate = 'MACRO_BEHAVIOR_DATE',
  MacroBehaviorEnum = 'MACRO_BEHAVIOR_ENUM',
  MacroBehaviorImage = 'MACRO_BEHAVIOR_IMAGE',
  MacroBehaviorLink = 'MACRO_BEHAVIOR_LINK',
  MacroBehaviorNumber = 'MACRO_BEHAVIOR_NUMBER',
  MacroBehaviorObject = 'MACRO_BEHAVIOR_OBJECT',
  MacroBehaviorObjectList = 'MACRO_BEHAVIOR_OBJECT_LIST',
  MacroBehaviorPrimitiveList = 'MACRO_BEHAVIOR_PRIMITIVE_LIST',
  MacroBehaviorText = 'MACRO_BEHAVIOR_TEXT',
  MacroBehaviorUnknown = 'MACRO_BEHAVIOR_UNKNOWN',
  MacroBehaviorUnknownText = 'MACRO_BEHAVIOR_UNKNOWN_TEXT'
}

export enum MacroCategory {
  Company = 'COMPANY',
  Coupon = 'COUPON',
  Event = 'EVENT',
  Personalization = 'PERSONALIZATION'
}

/** Generated from com/attentivemobile/contracts/composition/MessageAttachment.proto */
export type MacroConfiguration = {
  __typename?: 'MacroConfiguration';
  entityToken: Scalars['String']['output'];
  macroAttributes: Array<MacroAttribute>;
};

export enum MacroType {
  Link = 'LINK',
  Text = 'TEXT'
}

export type MakeCreativeInput = {
  alreadySubscribedMessageId: InputMaybe<Scalars['ID']['input']>;
  companyId: Scalars['ID']['input'];
  config: InputMaybe<JsonStringInput>;
  couponSetId: InputMaybe<Scalars['ID']['input']>;
  customProperties: InputMaybe<Array<CreativeCustomPropertyInput>>;
  externalName: InputMaybe<Scalars['String']['input']>;
  language: Scalars['String']['input'];
  name: Scalars['String']['input'];
  rules: InputMaybe<JsonStringInput>;
  smsBody: Scalars['String']['input'];
  /** @deprecated deprecated */
  src: InputMaybe<Scalars['String']['input']>;
  status: CreativeStatus;
  subscriptionType: InputMaybe<CreativeSubscriptionType>;
  subtype: CreativeSubtype;
  templateId: InputMaybe<Scalars['String']['input']>;
  type: CreativeType;
};

/** Generated from com/attentivemobile/contracts/creative-service/creative/CreativeGraphService.proto */
export type MakeCreativePayload = {
  __typename?: 'MakeCreativePayload';
  creativeOrError: Maybe<MakeCreativePayloadCreativeOrError>;
};

export type MakeCreativePayloadCreativeOrError = Creative | CreativeMutationError;

export enum ManualSegmentSource {
  ManualSegmentSourceInternal = 'MANUAL_SEGMENT_SOURCE_INTERNAL',
  ManualSegmentSourceThirdParty = 'MANUAL_SEGMENT_SOURCE_THIRD_PARTY',
  ManualSegmentSourceUnknown = 'MANUAL_SEGMENT_SOURCE_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/solutions/sftp/EtlConfig.proto */
export type MapColNameEntry = {
  __typename?: 'MapColNameEntry';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type MapColNameInputEntry = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/solutions/sftp/EtlConfig.proto */
export type MapEventNameEntry = {
  __typename?: 'MapEventNameEntry';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type MapEventNameInputEntry = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type MarkAITemplateAsLiveInput = {
  templateName: Scalars['String']['input'];
  version: Scalars['String']['input'];
};

export type MarkAITemplateAsLivePayload = DefaultErrorFailure | MarkAITemplateAsLiveSuccess;

/** Generated from com/attentivemobile/contracts/generativeaiservice/template/GenerativeAITemplateContract.proto */
export type MarkAITemplateAsLiveSuccess = {
  __typename?: 'MarkAITemplateAsLiveSuccess';
  isSuccess: Scalars['Boolean']['output'];
};

export type MarkClientConversationReadInput = {
  userId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/conversations/v1/Conversation.proto */
export type MarkClientConversationReadPayload = {
  __typename?: 'MarkClientConversationReadPayload';
  user: Maybe<User>;
};

export type MarkFeedbackViewedInput = {
  messageFeedbackId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/concierge/service/Feedback.proto */
export type MarkFeedbackViewedPayload = {
  __typename?: 'MarkFeedbackViewedPayload';
  messageFeedback: MessageFeedback;
};

export type MarkMilestonesSkippedRequest = {
  companyId: Scalars['ID']['input'];
  milestoneKeys: InputMaybe<Array<MilestoneKey>>;
};

/** Generated from com/attentivemobile/contracts/company-service/onboarding/Milestone.proto */
export type MarkMilestonesSkippedResponse = {
  __typename?: 'MarkMilestonesSkippedResponse';
  company: Maybe<Company>;
};

/** Generated from com/attentivemobile/contracts/message/history/Contract.proto */
export type Message = Node & {
  __typename?: 'Message';
  agent: Maybe<SendingAgent>;
  aggregator: Maybe<Aggregator>;
  body: Scalars['String']['output'];
  carrier: Scalars['String']['output'];
  company: Maybe<Company>;
  composeMessage: Maybe<ComposeMessage>;
  conciergeSession: Maybe<ConciergeSession>;
  countryCode: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTime']['output'];
  direction: MessageDirection;
  errorCode: Maybe<Scalars['String']['output']>;
  externalId: Maybe<Scalars['String']['output']>;
  fromPhone: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isSentByAgent: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated deprecated */
  mediaUrl: Maybe<Scalars['String']['output']>;
  mediaUrls: Array<Scalars['String']['output']>;
  messageFeedbackList: Maybe<ListMessageFeedbackResponse>;
  messageId: Maybe<Scalars['Long']['output']>;
  provider: Provider;
  segmentCount: Scalars['Int']['output'];
  sendId: Maybe<Scalars['String']['output']>;
  status: MessageStatus;
  statusDetails: Array<MessageStatusDetail>;
  toPhone: Scalars['String']['output'];
  user: Maybe<User>;
};


/** Generated from com/attentivemobile/contracts/message/history/Contract.proto */
export type MessagemessageFeedbackListArgs = {
  isClientView: Scalars['Boolean']['input'];
};

export type MessageConfig = {
  messageSequenceType: MessageSequenceType;
  sequenceNumber: InputMaybe<Scalars['Int']['input']>;
  utmSettingsInput: UtmSettingsInput;
};

/** Generated from com/attentivemobile/contracts/message/history/Contract.proto */
export type MessageConnection = {
  __typename?: 'MessageConnection';
  edges: Array<MessageEdge>;
  pageInfo: PageInfo;
  totalCount: Maybe<Scalars['Long']['output']>;
};

export enum MessageDirection {
  MessageDirectionInbound = 'MESSAGE_DIRECTION_INBOUND',
  MessageDirectionOutbound = 'MESSAGE_DIRECTION_OUTBOUND',
  MessageDirectionUnknown = 'MESSAGE_DIRECTION_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/message/history/Contract.proto */
export type MessageEdge = {
  __typename?: 'MessageEdge';
  cursor: Scalars['String']['output'];
  node: Message;
};

export type MessageEditorProperties = DragAndDropEditorProperties | HtmlEditorProperties;

/** Generated from com/attentivemobile/contracts/concierge/service/Feedback.proto */
export type MessageFeedback = Node & {
  __typename?: 'MessageFeedback';
  company: Maybe<Company>;
  created: Scalars['DateTime']['output'];
  feedback: Scalars['String']['output'];
  feedbackType: MessageFeedbackType;
  givenBy: MessageFeedbackGivenBy;
  givenByAccount: Maybe<Account>;
  handledBy: Maybe<Account>;
  handledTimestamp: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  message: Maybe<Message>;
  outcome: Maybe<MessageFeedbackOutcome>;
  subject: Scalars['String']['output'];
  updated: Scalars['DateTime']['output'];
  viewedBy: Array<AccountItem>;
};

/** Generated from com/attentivemobile/contracts/concierge/service/Feedback.proto */
export type MessageFeedbackConnection = {
  __typename?: 'MessageFeedbackConnection';
  edges: Array<MessageFeedbackEdge>;
  pageInfo: PageInfo;
  totalCount: Maybe<Scalars['Long']['output']>;
};

/** Generated from com/attentivemobile/contracts/concierge/service/Feedback.proto */
export type MessageFeedbackEdge = {
  __typename?: 'MessageFeedbackEdge';
  cursor: Scalars['String']['output'];
  node: MessageFeedback;
};

export enum MessageFeedbackGivenBy {
  Agent = 'AGENT',
  Client = 'CLIENT',
  UnknownGivenBy = 'UNKNOWN_GIVEN_BY'
}

export enum MessageFeedbackOutcome {
  Notes = 'NOTES',
  NoAction = 'NO_ACTION',
  Template = 'TEMPLATE',
  UnknownOutcome = 'UNKNOWN_OUTCOME'
}

export enum MessageFeedbackSortColumn {
  MessageFeedbackSortColumnDateGiven = 'MESSAGE_FEEDBACK_SORT_COLUMN_DATE_GIVEN',
  MessageFeedbackSortColumnDateResolved = 'MESSAGE_FEEDBACK_SORT_COLUMN_DATE_RESOLVED',
  MessageFeedbackSortColumnUnknownColumn = 'MESSAGE_FEEDBACK_SORT_COLUMN_UNKNOWN_COLUMN'
}

export enum MessageFeedbackSortDirection {
  MessageFeedbackSortDirectionAsc = 'MESSAGE_FEEDBACK_SORT_DIRECTION_ASC',
  MessageFeedbackSortDirectionDesc = 'MESSAGE_FEEDBACK_SORT_DIRECTION_DESC',
  MessageFeedbackSortUnknownDirection = 'MESSAGE_FEEDBACK_SORT_UNKNOWN_DIRECTION'
}

export type MessageFeedbackSortInput = {
  sortColumn: MessageFeedbackSortColumn;
  sortDirection: MessageFeedbackSortDirection;
};

export enum MessageFeedbackStatusFilter {
  MessageFeedbackStatusHandled = 'MESSAGE_FEEDBACK_STATUS_HANDLED',
  MessageFeedbackStatusNotHandled = 'MESSAGE_FEEDBACK_STATUS_NOT_HANDLED',
  MessageFeedbackStatusUnknownHandled = 'MESSAGE_FEEDBACK_STATUS_UNKNOWN_HANDLED'
}

export enum MessageFeedbackType {
  Negative = 'NEGATIVE',
  Neutral = 'NEUTRAL',
  Positive = 'POSITIVE',
  UnknownType = 'UNKNOWN_TYPE'
}

export type MessageFeedbackTypeFilter = {
  feedbackTypes: InputMaybe<Array<MessageFeedbackType>>;
};

/** Generated from com/attentivemobile/protobuf/common/Identifiers.proto */
export type MessageID = {
  __typename?: 'MessageID';
  id: Scalars['Long']['output'];
};

/** Generated from com/attentivemobile/contracts/keywords/KeywordService.proto */
export type MessageInfo = {
  __typename?: 'MessageInfo';
  forceSend: Scalars['Boolean']['output'];
  mediaUrl: Maybe<Scalars['String']['output']>;
  messageText: Scalars['String']['output'];
  shortenLink: Scalars['Boolean']['output'];
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type MessageLinkClick = {
  __typename?: 'MessageLinkClick';
  campaignName: Scalars['String']['output'];
  channel: Channel;
  /** @deprecated deprecated */
  created: Scalars['DateTime']['output'];
  /** @deprecated deprecated */
  id: Scalars['String']['output'];
  messageId: Maybe<MessageLinkClickMessageId>;
  vendor: Scalars['String']['output'];
};

export type MessageLinkClickMessageId = MessageID | ThirdPartyMessageId;

export enum MessageSequenceType {
  MessageSequenceTypeBrowseAbandonmentMessage = 'MESSAGE_SEQUENCE_TYPE_BROWSE_ABANDONMENT_MESSAGE',
  MessageSequenceTypeCartAbandonmentFollowupMessage = 'MESSAGE_SEQUENCE_TYPE_CART_ABANDONMENT_FOLLOWUP_MESSAGE',
  MessageSequenceTypeCartAbandonmentMessage = 'MESSAGE_SEQUENCE_TYPE_CART_ABANDONMENT_MESSAGE',
  MessageSequenceTypeContactCardMessage = 'MESSAGE_SEQUENCE_TYPE_CONTACT_CARD_MESSAGE',
  MessageSequenceTypeControlFollowupMessage = 'MESSAGE_SEQUENCE_TYPE_CONTROL_FOLLOWUP_MESSAGE',
  MessageSequenceTypeControlWelcomeMessage = 'MESSAGE_SEQUENCE_TYPE_CONTROL_WELCOME_MESSAGE',
  MessageSequenceTypeFollowupMessage = 'MESSAGE_SEQUENCE_TYPE_FOLLOWUP_MESSAGE',
  MessageSequenceTypeInitialMessage = 'MESSAGE_SEQUENCE_TYPE_INITIAL_MESSAGE',
  MessageSequenceTypeLegalMessage = 'MESSAGE_SEQUENCE_TYPE_LEGAL_MESSAGE',
  MessageSequenceTypePriceDropMessage = 'MESSAGE_SEQUENCE_TYPE_PRICE_DROP_MESSAGE',
  MessageSequenceTypeSessionAbandonmentMessage = 'MESSAGE_SEQUENCE_TYPE_SESSION_ABANDONMENT_MESSAGE',
  MessageSequenceTypeUnknown = 'MESSAGE_SEQUENCE_TYPE_UNKNOWN',
  MessageSequenceTypeWelcomeMessage = 'MESSAGE_SEQUENCE_TYPE_WELCOME_MESSAGE'
}

export enum MessageSortField {
  MessageSortFieldCreatedAt = 'MESSAGE_SORT_FIELD_CREATED_AT',
  MessageSortFieldUnknown = 'MESSAGE_SORT_FIELD_UNKNOWN'
}

export enum MessageStage {
  StageAttentivePlatform = 'STAGE_ATTENTIVE_PLATFORM',
  StageCarrierNetwork = 'STAGE_CARRIER_NETWORK',
  StageSendingPartner = 'STAGE_SENDING_PARTNER',
  StageUnknown = 'STAGE_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/campaigns/service/Stats.proto */
export type MessageStats = {
  __typename?: 'MessageStats';
  clicks: Scalars['Int']['output'];
  conversions: Scalars['Int']['output'];
  messageId: Scalars['String']['output'];
  optOuts: Scalars['Int']['output'];
  revenue: Scalars['Float']['output'];
  sends: Scalars['Int']['output'];
  totalOpens: Scalars['Int']['output'];
  uniqueClicks: Scalars['Int']['output'];
  uniqueOpens: Scalars['Int']['output'];
};

export enum MessageStatus {
  StatusCreated = 'STATUS_CREATED',
  StatusDelivered = 'STATUS_DELIVERED',
  StatusFailed = 'STATUS_FAILED',
  StatusFatigued = 'STATUS_FATIGUED',
  StatusIncomplete = 'STATUS_INCOMPLETE',
  StatusMitigated = 'STATUS_MITIGATED',
  StatusReceived = 'STATUS_RECEIVED',
  StatusSeen = 'STATUS_SEEN',
  StatusSent = 'STATUS_SENT',
  StatusUndelivered = 'STATUS_UNDELIVERED',
  StatusUnknown = 'STATUS_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/message/explorer/Contract.proto */
export type MessageStatusDetail = {
  __typename?: 'MessageStatusDetail';
  details: Scalars['String']['output'];
  errorCode: Scalars['String']['output'];
  stage: MessageStage;
  step: MessageStep;
  timestamp: Scalars['DateTime']['output'];
};

/** Generated from com/attentivemobile/contracts/message/explorer/Contract.proto */
export type MessageStatusDetailsResponse = {
  __typename?: 'MessageStatusDetailsResponse';
  details: Array<MessageStatusDetail>;
};

export enum MessageStep {
  StepAccepted = 'STEP_ACCEPTED',
  StepCancelled = 'STEP_CANCELLED',
  StepCreated = 'STEP_CREATED',
  StepDelivered = 'STEP_DELIVERED',
  StepDowngraded = 'STEP_DOWNGRADED',
  StepFailed = 'STEP_FAILED',
  StepFatigued = 'STEP_FATIGUED',
  StepPaused = 'STEP_PAUSED',
  StepProblem = 'STEP_PROBLEM',
  StepQueued = 'STEP_QUEUED',
  StepReceived = 'STEP_RECEIVED',
  StepResumed = 'STEP_RESUMED',
  StepSeen = 'STEP_SEEN',
  StepSending = 'STEP_SENDING',
  StepSent = 'STEP_SENT',
  StepUndelivered = 'STEP_UNDELIVERED',
  StepUndetermined = 'STEP_UNDETERMINED',
  StepUnknown = 'STEP_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/compose/api/Contract.proto */
export type MessageTemplateConnection = {
  __typename?: 'MessageTemplateConnection';
  edges: Array<ComposeMessageTemplateEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Generated from com/attentivemobile/protobuf/common/compose/Message.proto */
export type MessageTemplateReferences = {
  __typename?: 'MessageTemplateReferences';
  campaigns: Array<ComposeCampaignReference>;
  journeyRevisions: Array<JourneyRevisionReference>;
  messages: Array<ComposeMessageReference>;
  templates: Array<ComposeMessageTemplateReference>;
};

export enum MessageTone {
  MessageToneCasual = 'MESSAGE_TONE_CASUAL',
  MessageToneFormal = 'MESSAGE_TONE_FORMAL',
  MessageTonePolished = 'MESSAGE_TONE_POLISHED',
  MessageToneSuperCasual = 'MESSAGE_TONE_SUPER_CASUAL'
}

export enum MessageType {
  MessageTypeBrowseAbandonment = 'MESSAGE_TYPE_BROWSE_ABANDONMENT',
  MessageTypeCartAbandonment = 'MESSAGE_TYPE_CART_ABANDONMENT',
  MessageTypeCartAbandonmentFollowup_1 = 'MESSAGE_TYPE_CART_ABANDONMENT_FOLLOWUP_1',
  MessageTypeContactCard = 'MESSAGE_TYPE_CONTACT_CARD',
  MessageTypePriceDrop = 'MESSAGE_TYPE_PRICE_DROP',
  MessageTypeSessionAbandonment = 'MESSAGE_TYPE_SESSION_ABANDONMENT',
  MessageTypeUnknown = 'MESSAGE_TYPE_UNKNOWN',
  MessageTypeWelcome = 'MESSAGE_TYPE_WELCOME',
  MessageTypeWelcomeFollowup_1 = 'MESSAGE_TYPE_WELCOME_FOLLOWUP_1',
  MessageTypeWelcomeFollowup_2 = 'MESSAGE_TYPE_WELCOME_FOLLOWUP_2',
  MessageTypeWelcomeFollowup_3 = 'MESSAGE_TYPE_WELCOME_FOLLOWUP_3',
  MessageTypeWelcomeFollowup_4 = 'MESSAGE_TYPE_WELCOME_FOLLOWUP_4'
}

/** Generated from com/attentivemobile/contracts/message/explorer/Contract.proto */
export type MessagesCsvResponse = {
  __typename?: 'MessagesCsvResponse';
  csv: Scalars['String']['output'];
};

export enum MessagingChannel {
  MessagingChannelEmail = 'MESSAGING_CHANNEL_EMAIL',
  MessagingChannelText = 'MESSAGING_CHANNEL_TEXT',
  MessagingChannelUnknown = 'MESSAGING_CHANNEL_UNKNOWN'
}

export type MessagingServiceFilter = {
  countryCode: InputMaybe<Scalars['String']['input']>;
  includeInactive: InputMaybe<Scalars['Boolean']['input']>;
};

export enum MessagingServiceProvider {
  MessagingServiceProviderOpenMarket = 'MESSAGING_SERVICE_PROVIDER_OPEN_MARKET',
  MessagingServiceProviderSinch = 'MESSAGING_SERVICE_PROVIDER_SINCH',
  MessagingServiceProviderTwilio = 'MESSAGING_SERVICE_PROVIDER_TWILIO',
  MessagingServiceProviderUnknown = 'MESSAGING_SERVICE_PROVIDER_UNKNOWN',
  MessagingServiceProviderZipwhip = 'MESSAGING_SERVICE_PROVIDER_ZIPWHIP'
}

export enum MessagingServiceStatus {
  MessagingServiceStatusActive = 'MESSAGING_SERVICE_STATUS_ACTIVE',
  MessagingServiceStatusInactive = 'MESSAGING_SERVICE_STATUS_INACTIVE',
  MessagingServiceStatusTesting = 'MESSAGING_SERVICE_STATUS_TESTING',
  MessagingServiceStatusUnknown = 'MESSAGING_SERVICE_STATUS_UNKNOWN'
}

export enum MessagingTemplateType {
  MessagingTemplateTypeCampaign = 'MESSAGING_TEMPLATE_TYPE_CAMPAIGN',
  MessagingTemplateTypeCompanyEmailCommon = 'MESSAGING_TEMPLATE_TYPE_COMPANY_EMAIL_COMMON',
  MessagingTemplateTypeEmailPreview = 'MESSAGING_TEMPLATE_TYPE_EMAIL_PREVIEW',
  MessagingTemplateTypeJoinedASegment = 'MESSAGING_TEMPLATE_TYPE_JOINED_A_SEGMENT',
  MessagingTemplateTypeJourneysBackInStock = 'MESSAGING_TEMPLATE_TYPE_JOURNEYS_BACK_IN_STOCK',
  MessagingTemplateTypeJourneysBrowseAbandoner = 'MESSAGING_TEMPLATE_TYPE_JOURNEYS_BROWSE_ABANDONER',
  MessagingTemplateTypeJourneysCartAbandoner = 'MESSAGING_TEMPLATE_TYPE_JOURNEYS_CART_ABANDONER',
  MessagingTemplateTypeJourneysCheckoutAbandoner = 'MESSAGING_TEMPLATE_TYPE_JOURNEYS_CHECKOUT_ABANDONER',
  MessagingTemplateTypeJourneysPostPurchase = 'MESSAGING_TEMPLATE_TYPE_JOURNEYS_POST_PURCHASE',
  MessagingTemplateTypeJourneysWelcomeEmail = 'MESSAGING_TEMPLATE_TYPE_JOURNEYS_WELCOME_EMAIL',
  MessagingTemplateTypeJourneysWinback = 'MESSAGING_TEMPLATE_TYPE_JOURNEYS_WINBACK',
  MessagingTemplateTypeLowInventory = 'MESSAGING_TEMPLATE_TYPE_LOW_INVENTORY',
  MessagingTemplateTypePriceDrop = 'MESSAGING_TEMPLATE_TYPE_PRICE_DROP',
  MessagingTemplateTypeRow = 'MESSAGING_TEMPLATE_TYPE_ROW',
  MessagingTemplateTypeUnknown = 'MESSAGING_TEMPLATE_TYPE_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/metricsapi/Contract.proto */
export type Metric = {
  __typename?: 'Metric';
  /** Not present when any errors are encountered calculating the aggregate */
  aggregateValue: Maybe<Scalars['Float']['output']>;
  definition: MetricDefinition;
  /** List of data level errors detected when computing metric values, such as divisions by zero */
  errors: Array<MetricDataError>;
  groupedValues: Array<GroupedMetricValue>;
};

export enum MetricAggregationType {
  MetricAggregationTypeAverage = 'METRIC_AGGREGATION_TYPE_AVERAGE',
  MetricAggregationTypeCount = 'METRIC_AGGREGATION_TYPE_COUNT',
  MetricAggregationTypeCountDistinct = 'METRIC_AGGREGATION_TYPE_COUNT_DISTINCT',
  MetricAggregationTypePointInTimeCount = 'METRIC_AGGREGATION_TYPE_POINT_IN_TIME_COUNT',
  MetricAggregationTypePointInTimeSum = 'METRIC_AGGREGATION_TYPE_POINT_IN_TIME_SUM',
  MetricAggregationTypeSum = 'METRIC_AGGREGATION_TYPE_SUM',
  MetricAggregationTypeUnknown = 'METRIC_AGGREGATION_TYPE_UNKNOWN'
}

export enum MetricConnotation {
  MetricConnotationNegative = 'METRIC_CONNOTATION_NEGATIVE',
  MetricConnotationNeutral = 'METRIC_CONNOTATION_NEUTRAL',
  MetricConnotationPositive = 'METRIC_CONNOTATION_POSITIVE',
  MetricConnotationUnknown = 'METRIC_CONNOTATION_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/metricsapi/Contract.proto */
export type MetricDataError = {
  __typename?: 'MetricDataError';
  message: Scalars['String']['output'];
  type: MetricDataErrorType;
};

export enum MetricDataErrorType {
  MetricDataErrorTypeDivideByZero = 'METRIC_DATA_ERROR_TYPE_DIVIDE_BY_ZERO',
  MetricDataErrorTypeNoData = 'METRIC_DATA_ERROR_TYPE_NO_DATA',
  MetricDataErrorTypeUnknown = 'METRIC_DATA_ERROR_TYPE_UNKNOWN'
}

export enum MetricDataType {
  MetricDataTypeCurrency = 'METRIC_DATA_TYPE_CURRENCY',
  MetricDataTypeNumber = 'METRIC_DATA_TYPE_NUMBER',
  MetricDataTypePercent = 'METRIC_DATA_TYPE_PERCENT',
  MetricDataTypeUnknown = 'METRIC_DATA_TYPE_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/metricsapi/Contract.proto */
export type MetricDefinition = {
  __typename?: 'MetricDefinition';
  aggregationType: MetricAggregationType;
  connotation: MetricConnotation;
  dataType: MetricDataType;
  description: Scalars['String']['output'];
  dimensionWrappers: Array<DimensionWrapper>;
  /**
   * This field is not yet functional - use the dimensionWrappers field instead. Blocked by AP-1039
   * @deprecated deprecated
   */
  dimensions: Array<Dimension>;
  domain: Scalars['String']['output'];
  metricId: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/metricsapi/Contract.proto */
export type MetricValue = {
  __typename?: 'MetricValue';
  errors: Array<MetricDataError>;
  value: Maybe<Scalars['Float']['output']>;
};

/** Generated from com/attentivemobile/contracts/metricsapi/Contract.proto */
export type MetricsCatalog = {
  __typename?: 'MetricsCatalog';
  metrics: Array<MetricDefinition>;
};

export enum MetricsOrderByDirection {
  MetricsOrderByDirectionAsc = 'METRICS_ORDER_BY_DIRECTION_ASC',
  MetricsOrderByDirectionDesc = 'METRICS_ORDER_BY_DIRECTION_DESC',
  MetricsOrderByDirectionUnknown = 'METRICS_ORDER_BY_DIRECTION_UNKNOWN'
}

export type MetricsOrdering = {
  /** Must be one of the dimensions provided as a grouping to the metrics table query. */
  dimensionId: InputMaybe<Scalars['String']['input']>;
  direction: MetricsOrderByDirection;
  /** Must be one of the metrics requested in the metrics table query. */
  metricId: InputMaybe<Scalars['String']['input']>;
};

/** Generated from com/attentivemobile/contracts/metricsapi/Contract.proto */
export type MetricsTableConnection = {
  __typename?: 'MetricsTableConnection';
  aggregateValues: Array<MetricValue>;
  definitions: Array<MetricDefinition>;
  edges: Array<GroupedMetricsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Long']['output'];
};

export enum MfaOneTimePasscodeDeliveryMethod {
  MfaOneTimePasscodeDeliveryMethodEmail = 'MFA_ONE_TIME_PASSCODE_DELIVERY_METHOD_EMAIL',
  MfaOneTimePasscodeDeliveryMethodSms = 'MFA_ONE_TIME_PASSCODE_DELIVERY_METHOD_SMS',
  MfaOneTimePasscodeDeliveryMethodUnknown = 'MFA_ONE_TIME_PASSCODE_DELIVERY_METHOD_UNKNOWN'
}

export type MigrateCreativeContentAndCloneInput = {
  companyId: Scalars['ID']['input'];
  creativeId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type MigrateCreativeContentAndClonePayload = DefaultErrorFailure | MigrateCreativeContentAndCloneSuccess;

/** Generated from com/attentivemobile/contracts/creative-service/creative/CreativeContentService.proto */
export type MigrateCreativeContentAndCloneSuccess = {
  __typename?: 'MigrateCreativeContentAndCloneSuccess';
  creative: Maybe<Creative>;
};

export type MigrateDatastreamInput = {
  datastreamName: Scalars['String']['input'];
  pulsarClusterName: InputMaybe<Scalars['String']['input']>;
  runtime: InputMaybe<MigrateDatastreamInputRuntime>;
  startMode: OperationMode;
  stopMode: OperationMode;
};

export type MigrateDatastreamInputRuntime =
  { newFlinkJob: FlinkJobWrite; };

/** Generated from com/attentivemobile/contracts/event/platform/registration/service/Contract.proto */
export type MigrateDatastreamPayload = {
  __typename?: 'MigrateDatastreamPayload';
  newRuntime: Maybe<MigrateDatastreamPayloadNewRuntime>;
  oldRuntime: Maybe<MigrateDatastreamPayloadOldRuntime>;
  restorePath: Scalars['String']['output'];
  result: OperationResult;
};

export type MigrateDatastreamPayloadNewRuntime = MigrateFlinkJobResponse;

export type MigrateDatastreamPayloadOldRuntime = MigrateFlinkJobResponse;

/** Generated from com/attentivemobile/contracts/event/platform/registration/service/Flink.proto */
export type MigrateFlinkJobResponse = {
  __typename?: 'MigrateFlinkJobResponse';
  applicationId: Scalars['String']['output'];
  cluster: Cluster;
  entryClass: Scalars['String']['output'];
  flinkDeploymentMode: FlinkDeploymentMode;
  jarName: Scalars['String']['output'];
  jobId: Scalars['String']['output'];
  jobManagerUrl: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/company-service/onboarding/Milestone.proto */
export type Milestone = {
  __typename?: 'Milestone';
  /** the timestamp when the milestone was completed, will be null when a milestone is incomplete */
  completed: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  /** the unique key name of the milestone */
  key: MilestoneKey;
  /** the timestamp when the milestone was skipped, can be null if not skipped */
  skipped: Maybe<Scalars['DateTime']['output']>;
};

export enum MilestoneKey {
  /** the user has added user accounts */
  MilestoneKeyAddedUserAccounts = 'MILESTONE_KEY_ADDED_USER_ACCOUNTS',
  /** the user has completed the updating the company's brand styles */
  MilestoneKeyCompletedBrandStyles = 'MILESTONE_KEY_COMPLETED_BRAND_STYLES',
  /** the user has completed the company's onboarding form */
  MilestoneKeyCompletedCompanyForm = 'MILESTONE_KEY_COMPLETED_COMPANY_FORM',
  /** the user has completed the onboarding integrating survey */
  MilestoneKeyCompletedIntegrationSurvey = 'MILESTONE_KEY_COMPLETED_INTEGRATION_SURVEY',
  /** the user has completed the onboarding process */
  MilestoneKeyCompletedOnboarding = 'MILESTONE_KEY_COMPLETED_ONBOARDING',
  /** the user has completed the "Ready to Launch" creatives */
  MilestoneKeyCompletedRtlCreative = 'MILESTONE_KEY_COMPLETED_RTL_CREATIVE',
  /** the user has created a welcome journey */
  MilestoneKeyCompletedWelcomeJourney = 'MILESTONE_KEY_COMPLETED_WELCOME_JOURNEY',
  /** the user has reviewed the default offer */
  MilestoneKeyDefaultOfferReviewed = 'MILESTONE_KEY_DEFAULT_OFFER_REVIEWED',
  /** the user has completed integrating with their ecommerce platform */
  MilestoneKeyEcommercePlatformCompleted = 'MILESTONE_KEY_ECOMMERCE_PLATFORM_COMPLETED',
  /** the user has clicked the CTA on the onboarding email */
  MilestoneKeyEmailCtaFirstClicked = 'MILESTONE_KEY_EMAIL_CTA_FIRST_CLICKED',
  /** an email was sent to the user to start the company onboarding process */
  MilestoneKeyEmailFirstSent = 'MILESTONE_KEY_EMAIL_FIRST_SENT',
  /** the user has completed integrating with their email provider platform */
  MilestoneKeyEspIntegrationCompleted = 'MILESTONE_KEY_ESP_INTEGRATION_COMPLETED',
  /** the company has been imported from salesforce, represents the start of an onboarding process */
  MilestoneKeyImportedFromSalesforce = 'MILESTONE_KEY_IMPORTED_FROM_SALESFORCE',
  /** the user has installed the attentive tag */
  MilestoneKeyInstalledTag = 'MILESTONE_KEY_INSTALLED_TAG',
  /** Deprecated: this key is not used */
  MilestoneKeySalesforceConvertedToPaying = 'MILESTONE_KEY_SALESFORCE_CONVERTED_TO_PAYING',
  /** Deprecated: this key is not currently used */
  MilestoneKeySalesforceGoLive = 'MILESTONE_KEY_SALESFORCE_GO_LIVE',
  MilestoneKeyUnknown = 'MILESTONE_KEY_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto */
export type MissingEmailMetadata = {
  __typename?: 'MissingEmailMetadata';
  metadata: Scalars['String']['output'];
};

export type MobileWalletCardInput = {
  cardText: Scalars['String']['input'];
  couponId: Scalars['ID']['input'];
  expirationDate: Scalars['DateTime']['input'];
  externalCampaignId: InputMaybe<Scalars['String']['input']>;
  externalOfferId: Scalars['String']['input'];
  uuid: InputMaybe<Scalars['String']['input']>;
};

/** Generated from com/attentivemobile/contracts/mobilewalletservice/Contract.proto */
export type MobileWalletCardPayload = {
  __typename?: 'MobileWalletCardPayload';
  mobileWalletUrl: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/mobilewalletservice/Contract.proto */
export type MobileWalletChangePayload = {
  __typename?: 'MobileWalletChangePayload';
  status: Scalars['String']['output'];
};

export type MobileWalletSearchFilter = {
  search: InputMaybe<Scalars['String']['input']>;
};

export type MobileWalletSorting = {
  direction: InputMaybe<SortDirection>;
  sortBy: MobileWalletSortingField;
};

export enum MobileWalletSortingField {
  MobileWalletSortingFieldId = 'MOBILE_WALLET_SORTING_FIELD_ID',
  MobileWalletSortingFieldUpdated = 'MOBILE_WALLET_SORTING_FIELD_UPDATED'
}

/** Generated from com/attentivemobile/contracts/productrecsservice/Contract.proto */
export type ModelMetadata = {
  __typename?: 'ModelMetadata';
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

/**
 * Generated from com/attentivemobile/contracts/common/Money.proto
 * The price and currency of the product.
 */
export type Money = {
  __typename?: 'Money';
  /** The price of the product. */
  amount: Scalars['String']['output'];
  /** The currency used for the price in ISO 4217 format. */
  currency: Maybe<CurrencyCode>;
};

/** The price and currency of the product. */
export type MoneyInput = {
  /** The price of the product. */
  amount: Scalars['String']['input'];
  /** The currency used for the price in ISO 4217 format. */
  currency: InputMaybe<CurrencyCode>;
};

export type MoveAssetsInput = {
  assetIds: InputMaybe<Array<AssetId>>;
  proceedOnDuplicate: InputMaybe<Scalars['Boolean']['input']>;
  targetParentId: InputMaybe<Scalars['ID']['input']>;
};

export type MoveAssetsPayload = DefaultErrorFailure | MoveAssetsSuccess;

/** Generated from com/attentivemobile/contracts/asset/library/service/Contract.proto */
export type MoveAssetsSuccess = {
  __typename?: 'MoveAssetsSuccess';
  sourceFolder: Maybe<Folder>;
  targetFolder: Maybe<Folder>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addBrandImage: Maybe<AddBrandImagePayload>;
  addCampaignMessageGroup: Maybe<ComposeCampaignMessageGroupPayload>;
  addCompanyApplicationRouteRateLimit: Maybe<UpdateCompanyApplicationRouteRateLimitPayload>;
  addCompanyGlobalRateLimit: Maybe<UpdateCompanyGlobalRateLimitPayload>;
  addConciergeCompanyPromotion: Maybe<UpdateConciergeCompanyPromotionPayload>;
  addEmailIpAssignments: Maybe<AddEmailIPAssignmentsPayload>;
  addNewEtlConfig: Maybe<AddNewEtlConfigPayload>;
  addRouteRateLimit: Maybe<UpdateRouteRateLimitPayload>;
  /** Make a call to this mutation when a user adds a product to their shopping cart. */
  addToCartEvent: Maybe<SendAddProductToCartEventPayload>;
  addVariantToCampaignMessageGroup: Maybe<ComposeCampaignMessageGroupPayload>;
  adminSetSftpUserPassword: Maybe<AdminSetSftpUserPasswordPayload>;
  allocateCoupons: Maybe<AllocateCouponsPayload>;
  applyBrandElementStyles: Maybe<ApplyBrandElementStylesPayload>;
  applyBrandStyleRequest: Maybe<ApplyBrandStylePayload>;
  archiveCreative: Maybe<ArchiveCreativePayload>;
  askGraphQuestion: Maybe<AskGraphQuestionPayload>;
  assignCreativesToJourney: Maybe<AssignCreativesToJourneyPayload>;
  auditFloridaSubscribersV2: Maybe<AuditFloridaSubscribersV2Payload>;
  auditSubscriberV2: Maybe<AuditSubscriberV2Payload>;
  autoInstallTag: Maybe<AutoInstallTagStatusPayload>;
  batchMigrateJobs: Maybe<BatchMigrateJobsPayload>;
  bulkMigrateTagPresets: Maybe<BulkMigrateTagPresetsPayload>;
  bulkModifyTagExperiments: Maybe<BulkModifyTagExperimentsPayload>;
  bulkUpdateCompositionMessages: Maybe<BulkUpdateCompositionMessagesPayload>;
  calculateLiveSegmentCount: Maybe<SegmentCountPayload>;
  cancelUserFileDownload: Maybe<CancelUserFileDownloadPayload>;
  changeBrandImageName: Maybe<ChangeBrandImageNamePayload>;
  changeCompanyDomain: Maybe<ChangeCompanyAttnDomainPayload>;
  churnAiProSubscription: Maybe<ChurnAiProSubscriptionPayload>;
  cloneCampaignMessageGroup: Maybe<ComposeCampaignMessageGroupPayload>;
  cloneComposeCampaign: Maybe<ComposeCampaignPayload>;
  cloneCreative: Maybe<CloneCreativePayload>;
  cloneEdsJob: Maybe<CloneEdsJobPayload>;
  cloneJourney: Maybe<CloneJourneyPayload>;
  clonePoll: Maybe<ClonePollPayload>;
  closeConciergeChat: Maybe<CloseConciergeChatPayload>;
  combineImages: Maybe<CombineImagesPayload>;
  completeCompanyCreation: Maybe<CompleteCompanyCreationPayload>;
  /** marks a set of milestones as completed */
  completeOnboardingMilestones: Maybe<CompleteMilestonesResponse>;
  compressMMSImage: Maybe<CompressMMSImageForUIPayload>;
  configureIntegrationSettings: Maybe<ConfiguredIntegrationPayload>;
  confirmResetSftpUserPassword: Maybe<ConfirmResetSftpUserPasswordPayload>;
  copyMessageRequest: Maybe<CopyMessagePayload>;
  couponAssignment: Maybe<CouponAssignmentPayload>;
  /** @deprecated deprecated */
  createAIBrandVoiceSettings: Maybe<CreateAIBrandVoiceSettingsPayload>;
  createAdminNotificationConfiguration: Maybe<CreateAdminNotificationConfigurationPayload>;
  createAgentTemplate: Maybe<AgentTemplate>;
  createAgentTemplateRequest: Maybe<AgentTemplateRequest>;
  createAiJourneysCompanyConfig: Maybe<CreateCompanyConfigPayload>;
  createAiProTrialSubscription: Maybe<CreateAiProTrialSubscriptionPayload>;
  createAndReleaseTagConfig: Maybe<CreateAndReleaseTagConfigPayload>;
  createApiSignUpCreative: Maybe<CreateApiSignUpCreativePayload>;
  createAutoJourney: Maybe<CreateAutoJourneyPayload>;
  /** @deprecated deprecated */
  createAutoJourneyMessageId: Maybe<CreateAutoJourneyMessageIdPayload>;
  createAutomationPrograms: Maybe<CreateAutomationProgramsPayload>;
  createBlockCompanyInfo: Maybe<CreateBlockCompanyInfoPayload>;
  createCompany: Maybe<CreateCompanyPayload>;
  createCompanyLink: Maybe<CreateCompanyLinkPayload>;
  createCompanySettings: Maybe<CreateCompanySettingsPayload>;
  createComposeCampaign: Maybe<ComposeCampaignPayload>;
  createCouponAllocatorUpload: Maybe<CreateCouponAllocatorUploadPayload>;
  createCouponSet: Maybe<CreateCouponSetPayload>;
  createCreativeExperiment: Maybe<CreateCreativeExperimentPayload>;
  /** @deprecated deprecated */
  createCreativeSchedule: Maybe<CreateOrUpdateCreativeSchedulePayload>;
  createCreativeScheduleWorkflow: Maybe<CreateCreativeScheduleWorkflowPayload>;
  createCustomCompanyKeyword: Maybe<CreateCustomCompanyKeywordPayload>;
  /** Use the Custom Events mutation to send user actions to use in the Attentive Segment Builder and Journey Builder. This data cannot contain any sensitive or special categories of information as defined in applicable data protection and privacy laws, including the California Consumer Privacy Act (CCPA) and California Privacy Rights Act (CPRA). See a list of specific categories of data you cannot share with Attentive [here](https://docs.attentivemobile.com/pages/legal-docs/pi-disclaimer/). Make a call to this mutation for any event-based data representing user actions. */
  createCustomEvent: Maybe<CreateCustomEventPayload>;
  createCustomJourney: Maybe<CreateCustomJourneyPayload>;
  createCustomKeyword: Maybe<CreateCustomKeywordPayload>;
  createCustomPropertyDefinition: Maybe<CreateCustomPropertyDefinitionPayload>;
  createCustomReport: Maybe<CreateCustomReportPayload>;
  createDefaultWelcomeJourney: Maybe<CreateDefaultWelcomeJourneyPayload>;
  createDismissibleModal: Maybe<CreateDismissibleModalPayload>;
  createDomainSuppression: Maybe<CreateDomainSuppressionPayload>;
  createEdsJob: Maybe<CreateEdsJobPayload>;
  createEdsJobMapping: Maybe<CreateEdsJobMappingPayload>;
  createEmailIpAddress: Maybe<CreateEmailIPAddressPayload>;
  createEmailIpPool: Maybe<CreateEmailIPPoolPayload>;
  createEntityTagAssignment: Maybe<EntityTagAssigmentPayload>;
  createEventReplay: Maybe<CreateEventReplayPayload>;
  createEventReplayV2: Maybe<CreateEventReplayV2Payload>;
  createFeatureFlag: Maybe<CreateFeatureFlagPayload>;
  createFolder: Maybe<CreateFolderPayload>;
  createGapStoresCoupon: Maybe<CreateGapStoresCouponPayload>;
  createImage: Maybe<CreateImagePayload>;
  createJourney: Maybe<CreateJourneyPayload>;
  createJourneyFromTemplate: Maybe<CreateJourneyFromTemplatePayload>;
  createJourneyRevision: Maybe<CreateJourneyRevisionPayload>;
  createJourneySchedule: Maybe<CreateJourneySchedulePayload>;
  createJourneyTrigger: Maybe<CreateJourneyTriggerPayload>;
  createMessageRequest: Maybe<ComposeMessage>;
  createMessageTemplate: Maybe<CreateMessageTemplatePayload>;
  createMobileWalletCard: Maybe<MobileWalletCardPayload>;
  createMobileWalletCardAndSend: Maybe<MobileWalletChangePayload>;
  createNBAConfig: Maybe<CreateNBAConfigPayload>;
  createNotificationBanner: Maybe<CreateNotificationBannerPayload>;
  createNotificationEmailTemplate: Maybe<CreateNotificationEmailTemplatePayload>;
  createOrUpdateUserContentSetting: Maybe<CreateOrUpdateUserContentSettingPayload>;
  createOutboundSftpUser: Maybe<CreateOutboundSftpUserPayload>;
  createPaymentMessages: Maybe<CreatePaymentMessagesPayload>;
  createPaymentsOffer: Maybe<CreatePaymentsOfferPayload>;
  createPoll: Maybe<CreatePollPayload>;
  createPrebuiltContent: Maybe<CreatePrebuiltContentPayload>;
  createPrebuiltProgram: Maybe<CreatePrebuiltProgramPayload>;
  createProductSegment: Maybe<CreateProductSegmentPayload>;
  createProviderMigration: Maybe<CreateProviderMigrationPayload>;
  createProviderMigrations: Maybe<CreateProviderMigrationsPayload>;
  createQueryParameterJob: Maybe<CreateQueryParameterJobPayload>;
  createRegion: Maybe<CreateRegionPayload>;
  createSegmentEnhancementsWorkflow: Maybe<CreateSegmentEnhancementsWorkflowPayload>;
  createSegmentFromSearchDefinition: Maybe<CreateSegmentFromSearchDefinitionPayload>;
  createSegmentFromSearchTemplate: Maybe<CreateSegmentFromSearchTemplatePayload>;
  createSegmentFromTemplate: Maybe<CreateSegmentFromTemplatePayload>;
  createSftpUser: Maybe<CreateSftpUserPayload>;
  createShopifyAutoApplyFormat: Maybe<CreateShopifyAutoApplyFormatsPayload>;
  createShortLink: Maybe<CreateShortLinkPayload>;
  createSync: Maybe<CreateSyncPayload>;
  createTag: Maybe<TagPayload>;
  createUpdatePollOption: Maybe<CreateUpdatePollOptionPayload>;
  createVendorCompanyCard: Maybe<CreateVendorCompanyCardPayload>;
  createWebTemplate: Maybe<CreateWebTemplatePayload>;
  /** Make an API call to this mutation to subscribe to a webhook. Events are a collection of strings of the following types: `email.subscribed`, `sms.subscribed`, `email.unsubscribed`, `sms.sent`, `sms.message_link_click`, `custom_attribute.set`. Event types are case sensitive. All events included will be sent to the URL. */
  createWebhook: Maybe<CreateWebhookPayload>;
  createWorkloadConfig: Maybe<CreateWorkloadConfigPayload>;
  deactivateSync: Maybe<DeactivateSyncPayload>;
  deescalateUserConversation: Maybe<UserConversationDeescalationPayload>;
  deleteAccount: Maybe<DeleteAccountPayload>;
  deleteAdminNotificationConfiguration: Maybe<DeleteAdminNotificationConfigurationPayload>;
  deleteAffiliateUser: Maybe<DeleteAffiliateUserPayload>;
  deleteAsset: Maybe<DeleteAssetsPayload>;
  deleteAuthenticatorEnrollmentByEmail: Maybe<DeleteAuthenticatorEnrollmentByEmailPayload>;
  deleteBlockCompanyInfo: Maybe<DeleteBlockCompanyInfoPayload>;
  deleteBrandImages: Maybe<DeleteBrandImagesPayload>;
  deleteCampaignMessageGroup: Maybe<ComposeCampaignPayload>;
  deleteCircuitBreakerRateLimits: Maybe<DeleteCircuitBreakerRateLimitsPayload>;
  deleteCompanyCodes: Maybe<DeleteCompanyCodesPayload>;
  deleteComposeCampaign: Maybe<DeleteComposeCampaignPayload>;
  deleteConciergeSpecialization: Maybe<DeleteConciergeSpecializationPayload>;
  deleteCouponSet: Maybe<DeleteCouponSetPayload>;
  deleteCouponUpload: Maybe<DeleteCouponUploadPayload>;
  /** @deprecated deprecated */
  deleteCreative: Maybe<DeleteCreativeGraphPayload>;
  /**
   * Soft delete an upcoming schedule or complete a schedule if the schedule is running. A soft delete will return null.
   * @deprecated deprecated
   */
  deleteCreativeSchedule: Maybe<DeleteCreativeSchedulePayload>;
  deleteCreativeScheduleWorkflow: Maybe<DeleteCreativeScheduleWorkflowPayload>;
  deleteCustomCompanyKeyword: Maybe<DeleteCustomCompanyKeywordPayload>;
  deleteCustomKeywords: Maybe<DeleteCustomKeywordPayload>;
  deleteCustomPropertyDefinition: Maybe<DeleteCustomPropertyDefinitionPayload>;
  deleteCustomReport: Maybe<DeleteCustomReportPayload>;
  deleteDismissibleModal: Maybe<DeleteDismissibleModalPayload>;
  deleteEdsCronSchedule: Maybe<DeleteEdsCronSchedulePayload>;
  deleteEdsJob: Maybe<DeleteEdsJobPayload>;
  deleteJourney: Maybe<DeleteJourneyPayload>;
  deleteJourneySchedule: Maybe<DeleteJourneySchedulePayload>;
  deleteKeywordRule: Maybe<DeleteKeywordRulePayload>;
  deleteMessageTemplate: Maybe<DeleteMessageTemplatePayload>;
  deletePollOption: Maybe<DeletePollOptionPayload>;
  deleteSegments: Maybe<DeleteSegmentsPayload>;
  deleteShopifyAutoApplyFormat: Maybe<DeleteShopifyAutoApplyFormatsPayload>;
  deleteSignup: Maybe<DeleteSignupPayload>;
  deleteSync: Maybe<DeleteSyncPayload>;
  deleteTag: Maybe<TagPayload>;
  deleteUserContentSetting: Maybe<DeleteUserContentSettingPayload>;
  /** Make an API call to this mutation to remove a webhook. */
  deleteWebhook: Maybe<DeleteWebhookPayload>;
  deleteWorkloadConfig: Maybe<DeleteWorkloadConfigPayload>;
  deployServerSideTag: Maybe<DeployServerSideTagPayload>;
  descheduleWorkload: Maybe<DescheduleWorkloadPayload>;
  disableAllLegacyScheduledReports: Maybe<DisableAllLegacyScheduledReportsPayload>;
  disableAutomationProgram: Maybe<DisableAutomationProgramPayload>;
  dismissNotification: Maybe<DismissNotificationPayload>;
  dismissNotificationBanner: Maybe<DismissBannerPayload>;
  dispatchEdsCronJob: Maybe<CronJobExecutionPayload>;
  downloadUsers: Maybe<DownloadUsersPayload>;
  editEdsJob: Maybe<EditEdsJobPayload>;
  editFeatureFlag: Maybe<EditFeatureFlagPayload>;
  emptyConciergeCart: Maybe<EmptyConciergeCartPayload>;
  enableAutomationProgram: Maybe<EnableAutomationProgramPayload>;
  enableCustomCompanyKeyword: Maybe<EnableCustomCompanyKeywordPayload>;
  enableSendScheduledReportOverSftp: Maybe<EnableSendScheduledReportOverSftpPayload>;
  enableStandardSftpReports: Maybe<EnableStandardSftpReportsPayload>;
  endCreativeExperiment: Maybe<EndCreativeExperimentPayload>;
  enqueueSuppressionJob: Maybe<EnqueueSuppressionJobPayload>;
  enqueueUserFileDownload: Maybe<EnqueueUserFileDownloadPayload>;
  enterConciergeChat: Maybe<EnterConciergeChatPayload>;
  escalateUserConversation: Maybe<UserConversationEscalationPayload>;
  eventDestinationSftpSettings: Maybe<ConfirmSftpSettingsPayload>;
  eventDestinationSnowflakeSettings: Maybe<ConfirmSnowflakeSettingsPayload>;
  finishEnrollmentOfAuthenticator: Maybe<FinishAuthenticatorEnrollmentPayload>;
  generateAIImage: Maybe<GenerateAIImagePayload>;
  generateAIJourneyText: Maybe<GenerateAIJourneyTextPayload>;
  generateAIText: Maybe<GenerateAITextPayload>;
  generateAITextV2: Maybe<GenerateAITextV2Payload>;
  generateChallengeForAdminAccess: Maybe<GenerateChallengeForAdminAccessPayload>;
  /** @deprecated deprecated */
  generateCouponCode: Maybe<CouponAssignmentPayload>;
  generateHtmlPreviewRequest: Maybe<HTMLPreviewPayload>;
  generateImage: Maybe<GenerateImagePayload>;
  generateLayout: Maybe<GenerateLayoutPayload>;
  generateMobileWalletAnalyticsReport: Maybe<GenerateMobileWalletAnalyticsReportPayload>;
  generatePreSignedUrlForBulkOptOutJob: Maybe<GeneratePreSignedUrlForBulkOptOutJobPayload>;
  generatePresignedS3Url: Maybe<GeneratePresignedS3UrlPayload>;
  generatePresignedUrl: Maybe<GeneratePresignedUrlPayload>;
  generateZendeskJwt: Maybe<GenerateZendeskJwtPayload>;
  getCronExpression: Maybe<GetCronExpressionPayload>;
  getFilePublicUrl: Maybe<GetFilePublicUrlPayload>;
  getPreSignedUrlForGiveawayTermsUpload: Maybe<GiveawayTermsUploadPayload>;
  giveMessageFeedback: Maybe<GiveMessageFeedbackPayload>;
  hideComposeCampaign: Maybe<HideComposeCampaignPayload>;
  importCompanyFromSalesforce: Maybe<ImportCompanyFromSalesforcePayload>;
  initializeAuthenticatorEnrollment: Maybe<InitializeAuthenticatorEnrollmentPayload>;
  initiateCognitoAuth: Maybe<InitiateCognitoAuthPayload>;
  insertCompanyCodes: Maybe<InsertCompanyCodesPayload>;
  insertCreativeAudit: Maybe<InsertCreativeAuditPayload>;
  killWorkload: Maybe<KillWorkloadPayload>;
  leaveConciergeChat: Maybe<LeaveConciergeChatPayload>;
  lockUserConversation: Maybe<UserConversationLockPayload>;
  loginWithMfaToken: Maybe<LoginWithMfaTokenPayload>;
  loginWithPassword: Maybe<LoginWithPasswordPayload>;
  makeCreative: Maybe<MakeCreativePayload>;
  markAITemplateAsLive: Maybe<MarkAITemplateAsLivePayload>;
  markClientConversationRead: Maybe<MarkClientConversationReadPayload>;
  markFeedbackViewed: Maybe<MarkFeedbackViewedPayload>;
  /** marks a set of milestones as skipped */
  markMilestonesSkipped: Maybe<MarkMilestonesSkippedResponse>;
  migrateCreativeContentAndClone: Maybe<MigrateCreativeContentAndClonePayload>;
  migrateDatastream: Maybe<MigrateDatastreamPayload>;
  moveAsset: Maybe<MoveAssetsPayload>;
  nextAgentUser: Maybe<NextAgentUserPayload>;
  nudgeUserConversation: Maybe<UserConversationNudgePayload>;
  offboardPaymentCompany: Maybe<OffboardPaymentCompanyPayload>;
  onboardCompanySendingDomain: Maybe<OnboardCompanySendingDomainPayload>;
  onboardPaymentCompany: Maybe<OnboardPaymentCompanyPayload>;
  onboardSharedSendingDomain: Maybe<OnboardSharedSendingDomainPayload>;
  onboardSubdomain: Maybe<OnboardSubdomainPayload>;
  optOutUserByClient: Maybe<OptOutUserByClientPayload>;
  pauseAiProTrialSubscription: Maybe<PauseAiProTrialSubscriptionPayload>;
  pauseComposeCampaign: Maybe<ComposeCampaignPayload>;
  pauseEdsCronJob: Maybe<CronJobExecutionPayload>;
  pollGraphQuestion: Maybe<PollGraphQuestionPayload>;
  preScheduleComposeCampaign: Maybe<PreScheduleComposeCampaignPayload>;
  processCustomFonts: Maybe<ProcessCustomFontsPayload>;
  processTypekit: Maybe<ProcessTypekitPayload>;
  /** Make a call to this mutation when a user generates an order or purchase. */
  productPurchaseEvent: Maybe<SendProductPurchaseEventPayload>;
  /** Make a call to this mutation when a user views a product. */
  productViewEvent: Maybe<SendProductViewEventPayload>;
  publishJourneyRevision: Maybe<PublishJourneyRevisionPayload>;
  readNotification: Maybe<ReadNotificationPayload>;
  recoverDatastream: Maybe<RecoverDatastreamPayload>;
  redeemGapStoresCoupon: Maybe<RedeemGapStoresCouponPayload>;
  refreshItemListPages: Maybe<RefreshItemListPagesPayload>;
  refreshProgramSendTimes: Maybe<RefreshProgramSendTimesPayload>;
  refreshSessionOrChallenge: Maybe<RefreshSessionOrChallengePayload>;
  refreshUserFileDownloadUrl: Maybe<RefreshUserFileDownloadUrlPayload>;
  removeAccountFromCompany: Maybe<RemoveAccountFromCompanyPayload>;
  removeCompanyApplicationRouteRateLimit: Maybe<DeleteCompanyApplicationRouteRateLimitPayload>;
  removeCompanyGlobalRateLimit: Maybe<DeleteCompanyGlobalRateLimitPayload>;
  removeConciergeCompanyPromotion: Maybe<UpdateConciergeCompanyPromotionPayload>;
  removeDomainSuppression: Maybe<RemoveDomainSuppressionPayload>;
  removeEmailIpAssignments: Maybe<RemoveEmailIPAssignmentsPayload>;
  removeFeatureFlag: Maybe<RemoveFeatureFlagPayload>;
  removeRegion: Maybe<RemoveRegionPayload>;
  removeRouteRateLimit: Maybe<DeleteRouteRateLimitPayload>;
  removeTargetingData: Maybe<RemoveTargetingDataPayload>;
  removeUserFromAgentQueue: Maybe<RemoveUserFromAgentQueueResponse>;
  renameAsset: Maybe<RenameAssetPayload>;
  replaceCompanyCodes: Maybe<ReplaceCompanyCodesPayload>;
  replayByTime: Maybe<ReplayByTimePayload>;
  replayByUUIDs: Maybe<ReplayByUUIDsPayload>;
  resetAccountMfa: Maybe<ResetAccountMfaPayload>;
  resetPoll: Maybe<ResetPollPayload>;
  resetPrograms: Maybe<ResetProgramsPayload>;
  resetServerSideTagEventsApiKey: Maybe<ResetServerSideTagEventsApiKeyPayload>;
  resetSftpUserPassword: Maybe<ResetSftpUserPasswordPayload>;
  resetSubscriber: Maybe<ResetSubscriberPayload>;
  respondToCognitoAuthChallenge: Maybe<RespondToCognitoAuthChallengePayload>;
  restartAiProTrialSubscription: Maybe<RestartAiProTrialSubscriptionPayload>;
  resumeEdsCronJob: Maybe<CronJobExecutionPayload>;
  revisePaymentsOffer: Maybe<RevisePaymentsOfferPayload>;
  revokeRolesByEmail: Maybe<RevokeRolesByEmailPayload>;
  runBackfill: Maybe<RunBackfillPayload>;
  runScheduledReportBackfill: Maybe<RunScheduledReportBackfillPayload>;
  runSftpReportBackfill: Maybe<RunSftpReportBackfillPayload>;
  saveAITemplate: Maybe<SaveAITemplatePayload>;
  saveAutoJourneySettings: Maybe<SaveAutoJourneySettingsPayload>;
  saveAvailableBrandFonts: Maybe<SaveAvailableBrandFontsPayload>;
  saveConciergeCart: Maybe<SaveConciergeCartPayload>;
  saveConciergeSpecialization: Maybe<SaveConciergeSpecializationPayload>;
  saveContactCard: Maybe<SaveContactCardPayload>;
  saveGeneratedTextAndFeedback: Maybe<SaveGeneratedTextAndFeedbackPayload>;
  saveMappingAndEnqueueBatchJob: Maybe<SaveMappingAndEnqueueBatchJobPayload>;
  saveOnboardingSurveySelections: Maybe<OnboardingSurveySelectionsPayload>;
  saveSelectedBrandFonts: Maybe<SaveSelectedBrandFontsPayload>;
  scheduleCompanyWarmupCampaigns: Maybe<ScheduleWarmupCampaignPayload>;
  scheduleComposeCampaign: Maybe<ComposeCampaignPayload>;
  scheduleEdsCronJob: Maybe<ScheduleEdsCronJobPayload>;
  scheduleTagHealthPageInspection: Maybe<SchedulePageInspectionPayload>;
  scheduleWorkload: Maybe<ScheduleWorkloadPayload>;
  sendAgentHeartbeat: Maybe<SendAgentHeartbeatPayload>;
  sendAgentMessage: Maybe<SendMessagePayload>;
  sendApplicationWebhookTestEvent: Maybe<SendApplicationWebhookTestEventPayload>;
  sendClientMessage: Maybe<SendClientMessagePayload>;
  sendEmailMessagePreview: Maybe<SendEmailMessagePreviewResponsePayload>;
  sendMessageToAudience: Maybe<SendMessageToAudiencePayload>;
  sendMfaOneTimePasscode: Maybe<SendMfaOneTimePasscodePayload>;
  sendPasswordResetEmail: Maybe<SendPasswordResetEmailPayload>;
  sendPhonyInboundMessage: Maybe<SendPhonyInboundMessagePayload>;
  sendTextMessagePreview: Maybe<SendTextMessagePreviewResponsePayload>;
  setAIBrandVoiceSettings: Maybe<SetAIBrandVoiceSettingsPayload>;
  setAIBrandVoiceSettingsV2: Maybe<SetAIBrandVoiceSettingsV2Payload>;
  setAIJourneyLinkConfiguration: Maybe<SetAIJourneyLinkConfigurationPayload>;
  setAIJourneyLinkTrackingParams: Maybe<SetAIJourneyLinkTrackingParamsPayload>;
  setAccountMfaPreference: Maybe<AccountMfaPreferencesPayload>;
  setBrandColorScheme: Maybe<SetBrandColorSchemePayload>;
  setConciergeAgentChatAvailability: Maybe<SetConciergeAgentAvailabilityPayload>;
  /** Use the Custom Attributes mutation to apply customizable data or characteristics to each of your subscribers. You can then build segments based on that information to send targeted campaigns and journeys. Make a call to this endpoint for any attribute-based data. There are no limits to the amount of custom attributes that can be created. Note that you can create net-new properties with this API, however, it cannot be used to create new values for an existing UI-created property name. If a property name is created through the Attentive platform, all possible property values must also be defined in the platform. */
  setCustomAttributes: Maybe<SetCustomAttributesPayload>;
  setDefaultDomain: Maybe<SetDefaultDomainPayload>;
  setIpWarmupDate: Maybe<SetEmailIPAddressWarmupDatePayload>;
  setSubscriberBlocklistStatusByAgent: Maybe<SetSubscriberBlocklistStatusByAgentPayload>;
  setSubscriberOptInStatusByAgent: Maybe<SetSubscriberOptInStatusByAgentPayload>;
  setSweepstakesFilter: Maybe<SetSweepstakesFilterPayload>;
  setTargetingData: Maybe<SetTargetingDataPayload>;
  setupTemplatizedPostback: Maybe<SetupTemplatizedPostbackPayload>;
  setupTemplatizedPostbackSettings: Maybe<TemplatizedPostbackSettingsPayload>;
  solutionsEmailOptOut: Maybe<SolutionsEmailOptOutPayload>;
  solutionsPhoneOptOut: Maybe<SolutionsPhoneOptOutPayload>;
  /** Exposes UpdateMessageLinkTarget (owned by Content) to the graph with additional authentication/validation */
  solutionsUpdateMessageLinkTarget: Maybe<SolutionsUpdateMessageLinkTargetPayload>;
  startAiProTrialSubscription: Maybe<StartAiProTrialSubscriptionPayload>;
  startDatastream: Maybe<StartDatastreamPayload>;
  stopDatastream: Maybe<StopDatastreamPayload>;
  streamingReplay: Maybe<StreamingReplayPayload>;
  submitGeneratedTextFeedback: Maybe<SubmitGeneratedTextFeedbackPayload>;
  submitGenerativeAIOptIn: Maybe<SubmitGenerativeAIOptInPayload>;
  /** Make a call to this mutation to opt-in a user to a subscription. Note that a legal disclosure is required when a user is opted-in programmatically. Requests must contain either a sign up source id or both a locale and a subscription type. For transactional messages, you must include a transactional opt-in unit. By default, if a subscription already exists, it will try and record the attempt to create the subscription again. For TEXT subscriptions, this will result in a message being sent to the person indicating that they are already subscribed. */
  subscribe: Maybe<SubscribePayload>;
  swapCreativeExternalName: Maybe<SwapCreativeExternalNamePayload>;
  takeActionOnFeedback: Maybe<TakeActionOnFeedbackPayload>;
  toggleArchivePoll: Maybe<ToggleArchivePollPayload>;
  toggleCreativeTemplate: Maybe<ToggleCreativeTemplatePayload>;
  toggleLegacyReport: Maybe<ToggleLegacyReportPayload>;
  toggleLegacyScheduledReport: Maybe<ToggleLegacyScheduledReportPayload>;
  toggleMfaForCompany: Maybe<ToggleMfaForCompanyPayload>;
  triggerIpDomainVerification: Maybe<TriggerEmailIPAddressDomainVerificationPayload>;
  triggerJourneyPreview: Maybe<TriggerJourneyPreviewPayload>;
  triggerWorkload: Maybe<TriggerWorkloadPayload>;
  turnOffTagAiCollection: Maybe<TurnOffTagAiCollectionAndReleaseTagConfigPayload>;
  turnOnTagAiCollection: Maybe<TurnOnTagAiCollectionAndReleaseTagConfigPayload>;
  unarchiveCreative: Maybe<UnarchiveCreativePayload>;
  unassignCreativesFromJourney: Maybe<UnassignCreativesFromJourneyPayload>;
  uninstallTag: Maybe<AutoInstallTagStatusPayload>;
  unlockUserConversation: Maybe<UserConversationUnlockPayload>;
  unscheduleEdsCronJob: Maybe<UnscheduleEdsCronJobPayload>;
  /** Make a call to this mutation to unsubscribe a user from a subscription type or channel. If no subscriptions are present in the request, the user is unsubscribed from all subscriptions. If subscriptions are present in the request, the user is unsubscribed from the requested type or channel combination. By default, if a subscription exists, but the user is already unsubscribed, it records the attempt to unsubscribe the subscription again. For TEXT subscriptions, a message is sent to the person indicating that they are unsubscribed. */
  unsubscribe: Maybe<UnsubscribePayload>;
  updateAIBrandVoiceSettings: Maybe<UpdateAIBrandVoiceSettingsPayload>;
  updateAIBrandVoiceSettingsV2: Maybe<UpdateAIBrandVoiceSettingsV2Payload>;
  updateAdminNotificationConfiguration: Maybe<UpdateAdminNotificationConfigurationPayload>;
  updateAgentTemplate: Maybe<AgentTemplate>;
  updateAgentTemplateRequest: Maybe<AgentTemplateRequest>;
  updateApplicationWebhook: Maybe<UpdateApplicationWebhookPayload>;
  updateAttributionSettings: Maybe<UpdateAttributionSettingsPayload>;
  updateAutoJourney: Maybe<UpdateAutoJourneysPayload>;
  updateAutomationProgram: Maybe<UpdateAutomationProgramPayload>;
  updateBlockCompanyInfo: Maybe<UpdateBlockCompanyInfoPayload>;
  updateCampaignMessageGroup: Maybe<ComposeCampaignMessageGroupPayload>;
  updateCircuitBreakerRateLimits: Maybe<UpdateCircuitBreakerRateLimitPayload>;
  updateCompanyBrandVoice: Maybe<UpdateCompanyBrandVoicePayload>;
  updateCompanyEmailIntegration: Maybe<UpdateCompanyEmailIntegrationPayload>;
  updateCompanyLinkParameters: Maybe<UpdateCompanyLinkParametersPayload>;
  updateCompanyRegionLocalizedSettings: Maybe<UpdateCompanyRegionLocalizedSettingsPayload>;
  updateCompanySettings: Maybe<UpdateCompanySettingsPayload>;
  updateCompanyV2: Maybe<UpdateCompanyV2Payload>;
  updateCompanyVendorIntegrationStatus: Maybe<UpdateCompanyVendorIntegrationStatusPayload>;
  updateComposeCampaign: Maybe<ComposeCampaignPayload>;
  updateCompositionMessageRequest: Maybe<UpdateCompositionMessagePayload>;
  updateConciergeCompanyNotes: Maybe<UpdateConciergeCompanyNotesPayload>;
  updateConciergeCompanyPromotion: Maybe<UpdateConciergeCompanyPromotionPayload>;
  updateConciergeJourneyControls: Maybe<UpdateConciergeJourneyControlsPayload>;
  updateConciergeSelfServeSettingsAgentPreferences: Maybe<UpdateConciergeSelfServeSettingsPayload>;
  updateConciergeSelfServeSettingsCustomerSupport: Maybe<UpdateConciergeSelfServeSettingsPayload>;
  updateConciergeSelfServeSettingsJourneys: Maybe<UpdateConciergeSelfServeSettingsJourneysPayload>;
  updateConciergeSelfServeSettingsShipping: Maybe<UpdateConciergeSelfServeSettingsPayload>;
  updateConciergeSelfServeSettingsSignUp: Maybe<UpdateConciergeSelfServeSettingsPayload>;
  updateConciergeSelfServeSettingsSubmission: Maybe<UpdateConciergeSelfServeSettingsPayload>;
  updateConciergeSettings: Maybe<UpdateConciergeSettingsPayload>;
  updateConversationsSettings: Maybe<UpdateConversationsSettingsPayload>;
  updateCouponSet: Maybe<UpdateCouponSetPayload>;
  updateCouponUpload: Maybe<UpdateCouponUploadPayload>;
  updateCreative: Maybe<UpdateCreativePayload>;
  updateCreativeContent: Maybe<UpdateCreativeContentPayload>;
  updateCreativeExperimentTrafficSplit: Maybe<UpdateCreativeExperimentTrafficSplitPayload>;
  /** @deprecated deprecated */
  updateCreativeSchedule: Maybe<CreateOrUpdateCreativeSchedulePayload>;
  updateCreativeScheduleWorkflow: Maybe<UpdateCreativeScheduleWorkflowPayload>;
  updateCustomCompanyKeyword: Maybe<UpdateCustomCompanyKeywordPayload>;
  updateCustomEventKey: Maybe<UpdateCustomEventKeyPayload>;
  updateCustomKeyword: Maybe<UpdateCustomKeywordPayload>;
  updateCustomPropertyDefinition: Maybe<UpdateCustomPropertyDefinitionPayload>;
  updateCustomReport: Maybe<UpdateCustomReportPayload>;
  updateDatastreamImpactMetadata: Maybe<UpdateImpactMetadataPayload>;
  updateDatastreamParallelism: Maybe<UpdateDatastreamParallelismPayload>;
  updateDatastreamsParallelism: Maybe<UpdateDatastreamsParallelismPayload>;
  updateDismissibleModal: Maybe<UpdateDismissibleModalPayload>;
  updateEmailAccountPools: Maybe<UpdateEmailAccountPayload>;
  updateEmailIpAddress: Maybe<UpdateEmailIPAddressPayload>;
  updateEmailIpPool: Maybe<UpdateEmailIPPoolPayload>;
  updateEmailSettings: Maybe<UpdateEmailSettingsPayload>;
  updateEncryption: Maybe<UpdateEncryptionPayload>;
  updateEscalationEmails: Maybe<UpdateEscalationEmailsPayload>;
  updateEtlConfigCompanies: Maybe<UpdateEtlConfigCompaniesPayload>;
  updateEventReplayToPending: Maybe<ChangeEventReplayPayload>;
  updateEventReplayToPendingV2: Maybe<ChangeEventReplayV2Payload>;
  updateEventReplayToQueued: Maybe<ChangeEventReplayPayload>;
  updateEventReplayToQueuedV2: Maybe<ChangeEventReplayV2Payload>;
  updateEventReplayToReady: Maybe<ChangeEventReplayPayload>;
  updateEventReplayToReadyV2: Maybe<ChangeEventReplayV2Payload>;
  updateFilter: Maybe<UpdateFilterPayload>;
  updateJourney: Maybe<UpdateJourneyPayload>;
  updateJourneyDraftRevisionApprovalStatus: Maybe<UpdateJourneyRevisionApprovalStatusPayload>;
  updateJourneyRevision: Maybe<UpdateJourneyRevisionPayload>;
  updateJourneySchedule: Maybe<UpdateJourneySchedulePayload>;
  updateJourneyStatus: Maybe<UpdateJourneyStatusPayload>;
  updateJourneyTrigger: Maybe<UpdateJourneyTriggerPayload>;
  updateJourneyTriggerStatus: Maybe<UpdateJourneyTriggerStatusPayload>;
  updateLegacyScheduledReportRecipient: Maybe<UpdateLegacyScheduledReportRecipientPayload>;
  updateLegacyTagConfig: Maybe<UpdateLegacyTagConfigPayload>;
  updateMessageRequest: Maybe<ComposeMessage>;
  updateMessageTemplate: Maybe<UpdateMessageTemplatePayload>;
  updateMobileWalletCard: Maybe<MobileWalletChangePayload>;
  updateNBAConfig: Maybe<UpdateNBAConfigPayload>;
  updateNotificationBanner: Maybe<UpdateNotificationBannerPayload>;
  updateNotificationEmailTemplate: Maybe<UpdateNotificationEmailTemplatePayload>;
  updatePollStatus: Maybe<UpdatePollStatusPayload>;
  updatePollV2: Maybe<UpdatePollV2Payload>;
  updatePrebuiltContent: Maybe<UpdatePrebuiltContentPayload>;
  updatePrebuiltProgram: Maybe<UpdatePrebuiltProgramPayload>;
  updateProductSegment: Maybe<UpdateProductSegmentPayload>;
  updateProviderMigration: Maybe<UpdateProviderMigrationPayload>;
  updateProviderMigrations: Maybe<UpdateProviderMigrationsPayload>;
  updateRateLimit: Maybe<UpdateRateLimitPayload>;
  updateRegion: Maybe<UpdateRegionPayload>;
  updateRevisionStep: Maybe<UpdateRevisionStepPayload>;
  updateSegmentFromSearchDefinition: Maybe<UpdateSegmentFromSearchDefinitionPayload>;
  updateSendScheduledReportOverSftp: Maybe<UpdateSendScheduledReportOverSftpPayload>;
  updateSftpUserEmail: Maybe<UpdateSftpUserEmailPayload>;
  updateShopifyAutoApplyFormat: Maybe<UpdateShopifyAutoApplyFormatsPayload>;
  updateSync: Maybe<UpdateSyncPayload>;
  updateTag: Maybe<TagPayload>;
  updateWebTemplate: Maybe<UpdateWebTemplatePayload>;
  /** Make an API call to this endpoint to update a webhook. */
  updateWebhook: Maybe<UpdateWebhookPayload>;
  updateWorkloadConfig: Maybe<UpdateWorkloadConfigPayload>;
  uploadManualSegment: Maybe<UploadManualSegmentPayload>;
  upsertAIJourneyDefaultMessage: Maybe<UpsertAIJourneyDefaultMessagePayload>;
  upsertAccount: Maybe<UpsertAccountPayload>;
  upsertAffiliateUser: Maybe<UpsertAffiliateUserPayload>;
  upsertClientSegmentImport: Maybe<UpsertClientSegmentImportPayload>;
  upsertEdsTaskProcessingConfig: Maybe<UpsertEdsTaskProcessingConfigPayload>;
  upsertKeywordRule: Maybe<UpsertKeywordRulePayload>;
  upsertPollKeyword: Maybe<UpsertPollKeywordPayload>;
  validateFieldMappingForSubscriberUploadFile: Maybe<ValidateMappingForFilePayload>;
  verifyChallenge: Maybe<VerifyChallengePayload>;
  verifyChallengeForAdminAccess: Maybe<VerifyChallengeForAdminAccessPayload>;
  verifyMfaOneTimePasscode: Maybe<VerifyMfaOneTimePasscodePayload>;
  verifySendingDomain: Maybe<VerifySendingDomainPayload>;
  verifyServerSideTagDnsRecords: Maybe<VerifyServerSideTagDnsRecordsPayload>;
  verifySubdomain: Maybe<VerifySubdomainPayload>;
};


export type MutationaddBrandImageArgs = {
  input: AddBrandImageInput;
};


export type MutationaddCampaignMessageGroupArgs = {
  input: AddCampaignMessageGroupInput;
};


export type MutationaddCompanyApplicationRouteRateLimitArgs = {
  input: UpdateCompanyApplicationRouteRateLimitInput;
};


export type MutationaddCompanyGlobalRateLimitArgs = {
  input: UpdateCompanyGlobalRateLimitInput;
};


export type MutationaddConciergeCompanyPromotionArgs = {
  input: AddConciergeCompanyPromotionInput;
};


export type MutationaddEmailIpAssignmentsArgs = {
  input: AddEmailIPAssignmentsInput;
};


export type MutationaddNewEtlConfigArgs = {
  input: AddNewEtlConfigInput;
};


export type MutationaddRouteRateLimitArgs = {
  input: UpdateRouteRateLimitInput;
};


export type MutationaddToCartEventArgs = {
  input: SendAddProductToCartEventInput;
};


export type MutationaddVariantToCampaignMessageGroupArgs = {
  input: AddVariantToCampaignMessageGroupInput;
};


export type MutationadminSetSftpUserPasswordArgs = {
  input: AdminSetSftpUserPasswordInput;
};


export type MutationallocateCouponsArgs = {
  input: AllocateCouponsInput;
};


export type MutationapplyBrandElementStylesArgs = {
  input: ApplyBrandElementStylesInput;
};


export type MutationapplyBrandStyleRequestArgs = {
  input: ApplyBrandStyleInput;
};


export type MutationarchiveCreativeArgs = {
  input: ArchiveCreativeInput;
};


export type MutationaskGraphQuestionArgs = {
  input: AskGraphQuestionInput;
};


export type MutationassignCreativesToJourneyArgs = {
  input: AssignCreativesToJourneyInput;
};


export type MutationauditFloridaSubscribersV2Args = {
  input: AuditFloridaSubscribersV2Input;
};


export type MutationauditSubscriberV2Args = {
  input: AuditSubscriberV2Input;
};


export type MutationautoInstallTagArgs = {
  input: AutoInstallTagInput;
};


export type MutationbatchMigrateJobsArgs = {
  input: BatchMigrateJobsInput;
};


export type MutationbulkMigrateTagPresetsArgs = {
  input: BulkMigrateTagPresetsInput;
};


export type MutationbulkModifyTagExperimentsArgs = {
  input: BulkModifyTagExperimentsInput;
};


export type MutationbulkUpdateCompositionMessagesArgs = {
  input: BulkUpdateCompositionMessagesInput;
};


export type MutationcalculateLiveSegmentCountArgs = {
  input: Scalars['Empty']['input'];
};


export type MutationcancelUserFileDownloadArgs = {
  input: CancelUserFileDownloadInput;
};


export type MutationchangeBrandImageNameArgs = {
  input: ChangeBrandImageNameInput;
};


export type MutationchangeCompanyDomainArgs = {
  input: ChangeCompanyAttnDomainInput;
};


export type MutationchurnAiProSubscriptionArgs = {
  input: ChurnAiProSubscriptionInput;
};


export type MutationcloneCampaignMessageGroupArgs = {
  input: CloneCampaignMessageGroupInput;
};


export type MutationcloneComposeCampaignArgs = {
  input: CloneComposeCampaignInput;
};


export type MutationcloneCreativeArgs = {
  input: CloneCreativeInput;
};


export type MutationcloneEdsJobArgs = {
  input: CloneEdsJobInput;
};


export type MutationcloneJourneyArgs = {
  input: CloneJourneyInput;
};


export type MutationclonePollArgs = {
  input: ClonePollInput;
};


export type MutationcloseConciergeChatArgs = {
  input: CloseConciergeChatInput;
};


export type MutationcombineImagesArgs = {
  input: CombineImagesInput;
};


export type MutationcompleteCompanyCreationArgs = {
  input: CompleteCompanyCreationRequest;
};


export type MutationcompleteOnboardingMilestonesArgs = {
  input: CompleteMilestonesRequest;
};


export type MutationcompressMMSImageArgs = {
  input: CompressMMSImageInput;
};


export type MutationconfigureIntegrationSettingsArgs = {
  input: UpdateIntegrationVendorSettingsInput;
};


export type MutationconfirmResetSftpUserPasswordArgs = {
  input: ConfirmResetSftpUserPasswordInput;
};


export type MutationcopyMessageRequestArgs = {
  input: CopyMessageInput;
};


export type MutationcouponAssignmentArgs = {
  input: CouponAssignmentInput;
};


export type MutationcreateAIBrandVoiceSettingsArgs = {
  input: CreateAIBrandVoiceSettingsInput;
};


export type MutationcreateAdminNotificationConfigurationArgs = {
  input: CreateAdminNotificationConfigurationInput;
};


export type MutationcreateAgentTemplateArgs = {
  input: CreateAgentTemplateInput;
};


export type MutationcreateAgentTemplateRequestArgs = {
  input: CreateAgentTemplateRequestInput;
};


export type MutationcreateAiJourneysCompanyConfigArgs = {
  input: CreateCompanyConfigInput;
};


export type MutationcreateAiProTrialSubscriptionArgs = {
  input: CreateAiProTrialSubscriptionInput;
};


export type MutationcreateAndReleaseTagConfigArgs = {
  input: CreateAndReleaseTagConfigInput;
};


export type MutationcreateApiSignUpCreativeArgs = {
  input: CreateApiSignUpCreativeInput;
};


export type MutationcreateAutoJourneyArgs = {
  input: CreateAutoJourneyInput;
};


export type MutationcreateAutoJourneyMessageIdArgs = {
  input: CreateAutoJourneyMessageIdInput;
};


export type MutationcreateAutomationProgramsArgs = {
  input: CreateAutomationProgramsInput;
};


export type MutationcreateBlockCompanyInfoArgs = {
  input: CreateBlockCompanyInfoInput;
};


export type MutationcreateCompanyArgs = {
  input: CreateCompanyInput;
};


export type MutationcreateCompanyLinkArgs = {
  input: CreateCompanyLinkInput;
};


export type MutationcreateCompanySettingsArgs = {
  input: CreateCompanySettingsInput;
};


export type MutationcreateComposeCampaignArgs = {
  input: CreateComposeCampaignInput;
};


export type MutationcreateCouponAllocatorUploadArgs = {
  input: CreateCouponAllocatorUploadInput;
};


export type MutationcreateCouponSetArgs = {
  input: CreateCouponSetInput;
};


export type MutationcreateCreativeExperimentArgs = {
  input: CreateCreativeExperimentInput;
};


export type MutationcreateCreativeScheduleArgs = {
  input: CreateCreativeScheduleAndMembersInput;
};


export type MutationcreateCreativeScheduleWorkflowArgs = {
  input: CreateCreativeScheduleWorkflowInput;
};


export type MutationcreateCustomCompanyKeywordArgs = {
  input: CreateCustomCompanyKeywordInput;
};


export type MutationcreateCustomEventArgs = {
  input: CreateCustomEventInput;
};


export type MutationcreateCustomJourneyArgs = {
  input: CreateCustomJourneyInput;
};


export type MutationcreateCustomKeywordArgs = {
  input: CreateCustomKeywordInput;
};


export type MutationcreateCustomPropertyDefinitionArgs = {
  input: CreateCustomPropertyDefinitionInput;
};


export type MutationcreateCustomReportArgs = {
  input: CreateCustomReportInput;
};


export type MutationcreateDefaultWelcomeJourneyArgs = {
  input: CreateDefaultWelcomeJourneyInput;
};


export type MutationcreateDismissibleModalArgs = {
  input: CreateDismissibleModalInput;
};


export type MutationcreateDomainSuppressionArgs = {
  input: CreateDomainSuppressionInput;
};


export type MutationcreateEdsJobArgs = {
  input: CreateEdsJobInput;
};


export type MutationcreateEdsJobMappingArgs = {
  input: CreateEdsJobMappingInput;
};


export type MutationcreateEmailIpAddressArgs = {
  input: CreateEmailIPAddressInput;
};


export type MutationcreateEmailIpPoolArgs = {
  input: CreateEmailIPPoolInput;
};


export type MutationcreateEntityTagAssignmentArgs = {
  input: CreateEntityTagAssignmentInput;
};


export type MutationcreateEventReplayArgs = {
  input: CreateEventReplayInput;
};


export type MutationcreateEventReplayV2Args = {
  input: CreateEventReplayV2Input;
};


export type MutationcreateFeatureFlagArgs = {
  input: CreateFeatureFlagInput;
};


export type MutationcreateFolderArgs = {
  input: CreateFolderInput;
};


export type MutationcreateGapStoresCouponArgs = {
  input: CreateGapStoresCouponInput;
};


export type MutationcreateImageArgs = {
  input: CreateImageInput;
};


export type MutationcreateJourneyArgs = {
  input: CreateJourneyInput;
};


export type MutationcreateJourneyFromTemplateArgs = {
  input: CreateJourneyFromTemplateInput;
};


export type MutationcreateJourneyRevisionArgs = {
  input: CreateJourneyRevisionInput;
};


export type MutationcreateJourneyScheduleArgs = {
  input: CreateJourneyScheduleInput;
};


export type MutationcreateJourneyTriggerArgs = {
  input: CreateJourneyTriggerInput;
};


export type MutationcreateMessageRequestArgs = {
  input: CreateMessageInput;
};


export type MutationcreateMessageTemplateArgs = {
  input: CreateMessageTemplateInput;
};


export type MutationcreateMobileWalletCardArgs = {
  input: CreateMobileWalletCardInput;
};


export type MutationcreateMobileWalletCardAndSendArgs = {
  input: CreateMobileWalletCardAndSendInput;
};


export type MutationcreateNBAConfigArgs = {
  input: CreateNBAConfigInput;
};


export type MutationcreateNotificationBannerArgs = {
  input: CreateNotificationBannerInput;
};


export type MutationcreateNotificationEmailTemplateArgs = {
  input: CreateNotificationEmailTemplateInput;
};


export type MutationcreateOrUpdateUserContentSettingArgs = {
  input: CreateOrUpdateUserContentSettingInput;
};


export type MutationcreateOutboundSftpUserArgs = {
  input: CreateOutboundSftpUserInput;
};


export type MutationcreatePaymentMessagesArgs = {
  input: CreatePaymentMessagesInput;
};


export type MutationcreatePaymentsOfferArgs = {
  input: CreatePaymentsOfferInput;
};


export type MutationcreatePollArgs = {
  input: CreatePollInput;
};


export type MutationcreatePrebuiltContentArgs = {
  input: CreatePrebuiltContentInput;
};


export type MutationcreatePrebuiltProgramArgs = {
  input: CreatePrebuiltProgramInput;
};


export type MutationcreateProductSegmentArgs = {
  input: CreateProductSegmentInput;
};


export type MutationcreateProviderMigrationArgs = {
  input: CreateProviderMigrationInput;
};


export type MutationcreateProviderMigrationsArgs = {
  input: CreateProviderMigrationsInput;
};


export type MutationcreateQueryParameterJobArgs = {
  input: CreateQueryParameterJobInput;
};


export type MutationcreateRegionArgs = {
  input: CreateRegionInput;
};


export type MutationcreateSegmentEnhancementsWorkflowArgs = {
  input: CreateSegmentEnhancementsWorkflowInput;
};


export type MutationcreateSegmentFromSearchDefinitionArgs = {
  input: CreateSegmentFromSearchDefinitionInput;
};


export type MutationcreateSegmentFromSearchTemplateArgs = {
  input: CreateSegmentFromSearchTemplateInput;
};


export type MutationcreateSegmentFromTemplateArgs = {
  input: CreateSegmentFromTemplateInput;
};


export type MutationcreateSftpUserArgs = {
  input: CreateSftpUserInput;
};


export type MutationcreateShopifyAutoApplyFormatArgs = {
  input: CreateShopifyAutoApplyFormatsInput;
};


export type MutationcreateShortLinkArgs = {
  input: CreateShortLinkInput;
};


export type MutationcreateSyncArgs = {
  input: CreateSyncInput;
};


export type MutationcreateTagArgs = {
  input: CreateTagInput;
};


export type MutationcreateUpdatePollOptionArgs = {
  input: CreateUpdatePollOptionInput;
};


export type MutationcreateVendorCompanyCardArgs = {
  input: CreateVendorCompanyCardInput;
};


export type MutationcreateWebTemplateArgs = {
  input: CreateWebTemplateInput;
};


export type MutationcreateWebhookArgs = {
  input: CreateWebhookInput;
};


export type MutationcreateWorkloadConfigArgs = {
  input: CreateWorkloadConfigInput;
};


export type MutationdeactivateSyncArgs = {
  input: DeactivateSyncInput;
};


export type MutationdeescalateUserConversationArgs = {
  input: UserConversationDeescalationInput;
};


export type MutationdeleteAccountArgs = {
  input: DeleteAccountInput;
};


export type MutationdeleteAdminNotificationConfigurationArgs = {
  input: DeleteAdminNotificationConfigurationInput;
};


export type MutationdeleteAffiliateUserArgs = {
  input: DeleteAffiliateUserInput;
};


export type MutationdeleteAssetArgs = {
  input: DeleteAssetsInput;
};


export type MutationdeleteAuthenticatorEnrollmentByEmailArgs = {
  input: DeleteAuthenticatorEnrollmentByEmailInput;
};


export type MutationdeleteBlockCompanyInfoArgs = {
  input: DeleteBlockCompanyInfoInput;
};


export type MutationdeleteBrandImagesArgs = {
  input: DeleteBrandImagesInput;
};


export type MutationdeleteCampaignMessageGroupArgs = {
  input: DeleteCampaignMessageGroupInput;
};


export type MutationdeleteCircuitBreakerRateLimitsArgs = {
  input: DeleteCircuitBreakerRateLimitInput;
};


export type MutationdeleteCompanyCodesArgs = {
  input: DeleteCompanyCodesInput;
};


export type MutationdeleteComposeCampaignArgs = {
  input: DeleteComposeCampaignInput;
};


export type MutationdeleteConciergeSpecializationArgs = {
  input: DeleteConciergeSpecializationInput;
};


export type MutationdeleteCouponSetArgs = {
  input: DeleteCouponSetInput;
};


export type MutationdeleteCouponUploadArgs = {
  input: DeleteCouponUploadInput;
};


export type MutationdeleteCreativeArgs = {
  input: DeleteCreativeGraphInput;
};


export type MutationdeleteCreativeScheduleArgs = {
  input: DeleteCreativeScheduleInput;
};


export type MutationdeleteCreativeScheduleWorkflowArgs = {
  input: DeleteCreativeScheduleWorkflowInput;
};


export type MutationdeleteCustomCompanyKeywordArgs = {
  input: DeleteCustomCompanyKeywordInput;
};


export type MutationdeleteCustomKeywordsArgs = {
  input: DeleteCustomKeywordInput;
};


export type MutationdeleteCustomPropertyDefinitionArgs = {
  input: DeleteCustomPropertyDefinitionInput;
};


export type MutationdeleteCustomReportArgs = {
  input: DeleteCustomReportInput;
};


export type MutationdeleteDismissibleModalArgs = {
  input: DeleteDismissibleModalInput;
};


export type MutationdeleteEdsCronScheduleArgs = {
  input: DeleteEdsCronScheduleInput;
};


export type MutationdeleteEdsJobArgs = {
  input: DeleteEdsJobInput;
};


export type MutationdeleteJourneyArgs = {
  input: DeleteJourneyInput;
};


export type MutationdeleteJourneyScheduleArgs = {
  input: DeleteJourneyScheduleInput;
};


export type MutationdeleteKeywordRuleArgs = {
  input: DeleteKeywordRuleRequestInput;
};


export type MutationdeleteMessageTemplateArgs = {
  input: DeleteMessageTemplateInput;
};


export type MutationdeletePollOptionArgs = {
  input: DeletePollOptionInput;
};


export type MutationdeleteSegmentsArgs = {
  input: DeleteSegmentsInput;
};


export type MutationdeleteShopifyAutoApplyFormatArgs = {
  input: DeleteShopifyAutoApplyFormatsInput;
};


export type MutationdeleteSignupArgs = {
  input: DeleteSignupInput;
};


export type MutationdeleteSyncArgs = {
  input: DeleteSyncInput;
};


export type MutationdeleteTagArgs = {
  input: DeleteTagInput;
};


export type MutationdeleteUserContentSettingArgs = {
  input: DeleteUserContentSettingInput;
};


export type MutationdeleteWebhookArgs = {
  input: DeleteWebhookInput;
};


export type MutationdeleteWorkloadConfigArgs = {
  input: DeleteWorkloadConfigInput;
};


export type MutationdeployServerSideTagArgs = {
  input: DeployServerSideTagInput;
};


export type MutationdescheduleWorkloadArgs = {
  input: DescheduleWorkloadInput;
};


export type MutationdisableAllLegacyScheduledReportsArgs = {
  input: DisableAllLegacyScheduledReportsInput;
};


export type MutationdisableAutomationProgramArgs = {
  input: DisableAutomationProgramInput;
};


export type MutationdismissNotificationArgs = {
  input: DismissNotificationInput;
};


export type MutationdismissNotificationBannerArgs = {
  input: DismissBannerInput;
};


export type MutationdispatchEdsCronJobArgs = {
  input: CronJobExecutionInput;
};


export type MutationdownloadUsersArgs = {
  input: DownloadUsersInput;
};


export type MutationeditEdsJobArgs = {
  input: EditEdsJobInput;
};


export type MutationeditFeatureFlagArgs = {
  input: EditFeatureFlagInput;
};


export type MutationemptyConciergeCartArgs = {
  input: EmptyCartInput;
};


export type MutationenableAutomationProgramArgs = {
  input: EnableAutomationProgramInput;
};


export type MutationenableCustomCompanyKeywordArgs = {
  input: EnableCustomCompanyKeywordInput;
};


export type MutationenableSendScheduledReportOverSftpArgs = {
  input: EnableSendScheduledReportOverSftpInput;
};


export type MutationenableStandardSftpReportsArgs = {
  input: EnableStandardSftpReportsInput;
};


export type MutationendCreativeExperimentArgs = {
  input: EndCreativeExperimentInput;
};


export type MutationenqueueSuppressionJobArgs = {
  input: EnqueueSuppressionJobInput;
};


export type MutationenqueueUserFileDownloadArgs = {
  input: EnqueueUserFileDownloadInput;
};


export type MutationenterConciergeChatArgs = {
  input: EnterConciergeChatInput;
};


export type MutationescalateUserConversationArgs = {
  input: UserConversationEscalationInput;
};


export type MutationeventDestinationSftpSettingsArgs = {
  input: ConfirmSftpSettingsInput;
};


export type MutationeventDestinationSnowflakeSettingsArgs = {
  input: ConfirmSnowflakeSettingsInput;
};


export type MutationfinishEnrollmentOfAuthenticatorArgs = {
  input: FinishAuthenticatorEnrollmentInput;
};


export type MutationgenerateAIImageArgs = {
  input: GenerateAIImageInput;
};


export type MutationgenerateAIJourneyTextArgs = {
  input: GenerateAIJourneyTextInput;
};


export type MutationgenerateAITextArgs = {
  input: GenerateAITextInput;
};


export type MutationgenerateAITextV2Args = {
  input: GenerateAITextV2Input;
};


export type MutationgenerateChallengeForAdminAccessArgs = {
  input: GenerateChallengeForAdminAccessInput;
};


export type MutationgenerateCouponCodeArgs = {
  input: CouponAssignmentInput;
};


export type MutationgenerateHtmlPreviewRequestArgs = {
  input: HTMLPreviewInput;
};


export type MutationgenerateImageArgs = {
  input: GenerateImageInput;
};


export type MutationgenerateLayoutArgs = {
  input: GenerateLayoutInput;
};


export type MutationgenerateMobileWalletAnalyticsReportArgs = {
  input: GenerateMobileWalletAnalyticsReportInput;
};


export type MutationgeneratePreSignedUrlForBulkOptOutJobArgs = {
  input: GeneratePreSignedUrlForBulkOptOutJobInput;
};


export type MutationgeneratePresignedS3UrlArgs = {
  input: GeneratePresignedS3UrlInput;
};


export type MutationgeneratePresignedUrlArgs = {
  input: GeneratePresignedUrlInput;
};


export type MutationgenerateZendeskJwtArgs = {
  input: GenerateZendeskJwtInput;
};


export type MutationgetCronExpressionArgs = {
  input: GetCronExpressionInput;
};


export type MutationgetFilePublicUrlArgs = {
  input: GetFilePublicUrlInput;
};


export type MutationgetPreSignedUrlForGiveawayTermsUploadArgs = {
  input: GiveawayTermsUploadInput;
};


export type MutationgiveMessageFeedbackArgs = {
  input: GiveMessageFeedbackInput;
};


export type MutationhideComposeCampaignArgs = {
  input: HideComposeCampaignInput;
};


export type MutationimportCompanyFromSalesforceArgs = {
  input: ImportCompanyFromSalesforceInput;
};


export type MutationinitializeAuthenticatorEnrollmentArgs = {
  input: InitializeAuthenticatorEnrollmentInput;
};


export type MutationinitiateCognitoAuthArgs = {
  input: InitiateCognitoAuthInput;
};


export type MutationinsertCompanyCodesArgs = {
  input: InsertCompanyCodesInput;
};


export type MutationinsertCreativeAuditArgs = {
  input: InsertCreativeAuditInput;
};


export type MutationkillWorkloadArgs = {
  input: KillWorkloadInput;
};


export type MutationleaveConciergeChatArgs = {
  input: LeaveConciergeChatInput;
};


export type MutationlockUserConversationArgs = {
  input: UserConversationLockInput;
};


export type MutationloginWithMfaTokenArgs = {
  input: LoginWithMfaTokenInput;
};


export type MutationloginWithPasswordArgs = {
  input: LoginWithPasswordInput;
};


export type MutationmakeCreativeArgs = {
  input: MakeCreativeInput;
};


export type MutationmarkAITemplateAsLiveArgs = {
  input: MarkAITemplateAsLiveInput;
};


export type MutationmarkClientConversationReadArgs = {
  input: MarkClientConversationReadInput;
};


export type MutationmarkFeedbackViewedArgs = {
  input: MarkFeedbackViewedInput;
};


export type MutationmarkMilestonesSkippedArgs = {
  input: MarkMilestonesSkippedRequest;
};


export type MutationmigrateCreativeContentAndCloneArgs = {
  input: MigrateCreativeContentAndCloneInput;
};


export type MutationmigrateDatastreamArgs = {
  input: MigrateDatastreamInput;
};


export type MutationmoveAssetArgs = {
  input: MoveAssetsInput;
};


export type MutationnextAgentUserArgs = {
  input: NextAgentUserInput;
};


export type MutationnudgeUserConversationArgs = {
  input: UserConversationNudgeInput;
};


export type MutationoffboardPaymentCompanyArgs = {
  input: OffboardPaymentCompanyInput;
};


export type MutationonboardCompanySendingDomainArgs = {
  input: OnboardCompanySendingDomainInput;
};


export type MutationonboardPaymentCompanyArgs = {
  input: OnboardPaymentCompanyInput;
};


export type MutationonboardSharedSendingDomainArgs = {
  input: OnboardSharedSendingDomainInput;
};


export type MutationonboardSubdomainArgs = {
  input: OnboardSubdomainInput;
};


export type MutationoptOutUserByClientArgs = {
  input: OptOutUserByClientInput;
};


export type MutationpauseAiProTrialSubscriptionArgs = {
  input: PauseAiProTrialSubscriptionInput;
};


export type MutationpauseComposeCampaignArgs = {
  input: PauseComposeCampaignInput;
};


export type MutationpauseEdsCronJobArgs = {
  input: CronJobExecutionInput;
};


export type MutationpollGraphQuestionArgs = {
  input: PollGraphQuestionInput;
};


export type MutationpreScheduleComposeCampaignArgs = {
  input: PreScheduleComposeCampaignInput;
};


export type MutationprocessCustomFontsArgs = {
  input: ProcessCustomFontsInput;
};


export type MutationprocessTypekitArgs = {
  input: ProcessTypekitInput;
};


export type MutationproductPurchaseEventArgs = {
  input: SendProductPurchaseEventInput;
};


export type MutationproductViewEventArgs = {
  input: SendProductViewEventInput;
};


export type MutationpublishJourneyRevisionArgs = {
  input: PublishJourneyRevisionInput;
};


export type MutationreadNotificationArgs = {
  input: ReadNotificationInput;
};


export type MutationrecoverDatastreamArgs = {
  input: RecoverDatastreamInput;
};


export type MutationredeemGapStoresCouponArgs = {
  input: RedeemGapStoresCouponInput;
};


export type MutationrefreshItemListPagesArgs = {
  input: RefreshItemListPagesInput;
};


export type MutationrefreshProgramSendTimesArgs = {
  input: RefreshProgramSendTimesInput;
};


export type MutationrefreshSessionOrChallengeArgs = {
  input: RefreshSessionOrChallengeInput;
};


export type MutationrefreshUserFileDownloadUrlArgs = {
  input: RefreshUserFileDownloadUrlInput;
};


export type MutationremoveAccountFromCompanyArgs = {
  input: RemoveAccountFromCompanyInput;
};


export type MutationremoveCompanyApplicationRouteRateLimitArgs = {
  input: DeleteCompanyApplicationRouteRateLimitInput;
};


export type MutationremoveCompanyGlobalRateLimitArgs = {
  input: DeleteCompanyGlobalRateLimitInput;
};


export type MutationremoveConciergeCompanyPromotionArgs = {
  input: RemoveConciergeCompanyPromotionInput;
};


export type MutationremoveDomainSuppressionArgs = {
  input: RemoveDomainSuppressionInput;
};


export type MutationremoveEmailIpAssignmentsArgs = {
  input: RemoveEmailIPAssignmentsInput;
};


export type MutationremoveFeatureFlagArgs = {
  input: RemoveFeatureFlagInput;
};


export type MutationremoveRegionArgs = {
  input: RemoveRegionInput;
};


export type MutationremoveRouteRateLimitArgs = {
  input: DeleteRouteRateLimitInput;
};


export type MutationremoveTargetingDataArgs = {
  input: RemoveTargetingDataInput;
};


export type MutationremoveUserFromAgentQueueArgs = {
  input: RemoveUserFromAgentQueueInput;
};


export type MutationrenameAssetArgs = {
  input: RenameAssetInput;
};


export type MutationreplaceCompanyCodesArgs = {
  input: ReplaceCompanyCodesInput;
};


export type MutationreplayByTimeArgs = {
  input: ReplayByTimeInput;
};


export type MutationreplayByUUIDsArgs = {
  input: ReplayByUUIDsInput;
};


export type MutationresetAccountMfaArgs = {
  input: ResetAccountMfaInput;
};


export type MutationresetPollArgs = {
  input: ResetPollInput;
};


export type MutationresetProgramsArgs = {
  input: ResetProgramsInput;
};


export type MutationresetServerSideTagEventsApiKeyArgs = {
  input: ResetServerSideTagEventsApiKeyInput;
};


export type MutationresetSftpUserPasswordArgs = {
  input: ResetSftpUserPasswordInput;
};


export type MutationresetSubscriberArgs = {
  input: ResetSubscriberInput;
};


export type MutationrespondToCognitoAuthChallengeArgs = {
  input: RespondToCognitoAuthChallengeInput;
};


export type MutationrestartAiProTrialSubscriptionArgs = {
  input: RestartAiProTrialSubscriptionInput;
};


export type MutationresumeEdsCronJobArgs = {
  input: CronJobExecutionInput;
};


export type MutationrevisePaymentsOfferArgs = {
  input: RevisePaymentsOfferInput;
};


export type MutationrevokeRolesByEmailArgs = {
  input: RevokeRolesByEmailInput;
};


export type MutationrunBackfillArgs = {
  input: RunBackfillInput;
};


export type MutationrunScheduledReportBackfillArgs = {
  input: RunScheduledReportBackfillInput;
};


export type MutationrunSftpReportBackfillArgs = {
  input: RunSftpReportBackfillInput;
};


export type MutationsaveAITemplateArgs = {
  input: SaveAITemplateInput;
};


export type MutationsaveAutoJourneySettingsArgs = {
  input: SaveAutoJourneySettingsInput;
};


export type MutationsaveAvailableBrandFontsArgs = {
  input: SaveAvailableBrandFontsInput;
};


export type MutationsaveConciergeCartArgs = {
  input: SaveConciergeCartInput;
};


export type MutationsaveConciergeSpecializationArgs = {
  input: SaveConciergeSpecializationInput;
};


export type MutationsaveContactCardArgs = {
  input: SaveContactCardInput;
};


export type MutationsaveGeneratedTextAndFeedbackArgs = {
  input: SaveGeneratedTextAndFeedbackInput;
};


export type MutationsaveMappingAndEnqueueBatchJobArgs = {
  input: SaveMappingAndEnqueueBatchJobInput;
};


export type MutationsaveOnboardingSurveySelectionsArgs = {
  input: SaveOnboardingSurveySelectionsInput;
};


export type MutationsaveSelectedBrandFontsArgs = {
  input: SaveSelectedBrandFontsInput;
};


export type MutationscheduleCompanyWarmupCampaignsArgs = {
  input: ScheduleWarmupCampaignInput;
};


export type MutationscheduleComposeCampaignArgs = {
  input: ScheduleComposeCampaignInput;
};


export type MutationscheduleEdsCronJobArgs = {
  input: ScheduleEdsCronJobInput;
};


export type MutationscheduleTagHealthPageInspectionArgs = {
  input: SchedulePageInspectionInput;
};


export type MutationscheduleWorkloadArgs = {
  input: ScheduleWorkloadInput;
};


export type MutationsendAgentHeartbeatArgs = {
  input: Scalars['Empty']['input'];
};


export type MutationsendAgentMessageArgs = {
  input: SendAgentMessageInput;
};


export type MutationsendApplicationWebhookTestEventArgs = {
  input: SendApplicationWebhookTestEventInput;
};


export type MutationsendClientMessageArgs = {
  input: SendClientMessageInput;
};


export type MutationsendEmailMessagePreviewArgs = {
  input: SendEmailMessagePreviewRequestInput;
};


export type MutationsendMessageToAudienceArgs = {
  input: SendMessageToAudienceInput;
};


export type MutationsendMfaOneTimePasscodeArgs = {
  input: SendMfaOneTimePasscodeInput;
};


export type MutationsendPasswordResetEmailArgs = {
  input: SendPasswordResetEmailInput;
};


export type MutationsendPhonyInboundMessageArgs = {
  input: SendPhonyInboundMessageInput;
};


export type MutationsendTextMessagePreviewArgs = {
  input: SendTextMessagePreviewRequestInput;
};


export type MutationsetAIBrandVoiceSettingsArgs = {
  input: SetAIBrandVoiceSettingsInput;
};


export type MutationsetAIBrandVoiceSettingsV2Args = {
  input: SetAIBrandVoiceSettingsV2Input;
};


export type MutationsetAIJourneyLinkConfigurationArgs = {
  input: SetAIJourneyLinkConfigurationInput;
};


export type MutationsetAIJourneyLinkTrackingParamsArgs = {
  input: SetAIJourneyLinkTrackingParamsInput;
};


export type MutationsetAccountMfaPreferenceArgs = {
  input: SetAccountMfaPreferencesInput;
};


export type MutationsetBrandColorSchemeArgs = {
  input: SetBrandColorSchemeInput;
};


export type MutationsetConciergeAgentChatAvailabilityArgs = {
  input: SetConciergeAgentAvailabilityInput;
};


export type MutationsetCustomAttributesArgs = {
  input: SetCustomAttributesInput;
};


export type MutationsetDefaultDomainArgs = {
  input: SetDefaultDomainInput;
};


export type MutationsetIpWarmupDateArgs = {
  input: SetEmailIPAddressWarmupDateInput;
};


export type MutationsetSubscriberBlocklistStatusByAgentArgs = {
  input: SetSubscriberBlocklistStatusByAgentInput;
};


export type MutationsetSubscriberOptInStatusByAgentArgs = {
  input: SetSubscriberOptInStatusByAgentInput;
};


export type MutationsetSweepstakesFilterArgs = {
  input: SetSweepstakesFilterInput;
};


export type MutationsetTargetingDataArgs = {
  input: SetTargetingDataInput;
};


export type MutationsetupTemplatizedPostbackArgs = {
  input: SetupTemplatizedPostbackInput;
};


export type MutationsetupTemplatizedPostbackSettingsArgs = {
  input: TemplatizedPostbackSettingsInput;
};


export type MutationsolutionsEmailOptOutArgs = {
  input: SolutionsEmailOptOutInput;
};


export type MutationsolutionsPhoneOptOutArgs = {
  input: SolutionsPhoneOptOutInput;
};


export type MutationsolutionsUpdateMessageLinkTargetArgs = {
  input: SolutionsUpdateMessageLinkTargetInput;
};


export type MutationstartAiProTrialSubscriptionArgs = {
  input: StartAiProTrialSubscriptionInput;
};


export type MutationstartDatastreamArgs = {
  input: StartDatastreamInput;
};


export type MutationstopDatastreamArgs = {
  input: StopDatastreamInput;
};


export type MutationstreamingReplayArgs = {
  input: StreamingReplayInput;
};


export type MutationsubmitGeneratedTextFeedbackArgs = {
  input: SubmitGeneratedTextFeedbackInput;
};


export type MutationsubmitGenerativeAIOptInArgs = {
  input: SubmitGenerativeAIOptInInput;
};


export type MutationsubscribeArgs = {
  input: SubscribeInput;
};


export type MutationswapCreativeExternalNameArgs = {
  input: SwapCreativeExternalNameInput;
};


export type MutationtakeActionOnFeedbackArgs = {
  input: TakeActionOnFeedbackInput;
};


export type MutationtoggleArchivePollArgs = {
  input: ToggleArchivePollInput;
};


export type MutationtoggleCreativeTemplateArgs = {
  input: ToggleCreativeTemplateInput;
};


export type MutationtoggleLegacyReportArgs = {
  input: ToggleLegacyReportInput;
};


export type MutationtoggleLegacyScheduledReportArgs = {
  input: ToggleLegacyScheduledReportInput;
};


export type MutationtoggleMfaForCompanyArgs = {
  input: ToggleMfaForCompanyInput;
};


export type MutationtriggerIpDomainVerificationArgs = {
  input: TriggerEmailIPAddressDomainVerificationInput;
};


export type MutationtriggerJourneyPreviewArgs = {
  input: TriggerJourneyPreviewInput;
};


export type MutationtriggerWorkloadArgs = {
  input: TriggerWorkloadInput;
};


export type MutationturnOffTagAiCollectionArgs = {
  input: TurnOffTagAiCollectionAndReleaseTagConfigInput;
};


export type MutationturnOnTagAiCollectionArgs = {
  input: TurnOnTagAiCollectionAndReleaseTagConfigInput;
};


export type MutationunarchiveCreativeArgs = {
  input: UnarchiveCreativeInput;
};


export type MutationunassignCreativesFromJourneyArgs = {
  input: UnassignCreativesFromJourneyInput;
};


export type MutationuninstallTagArgs = {
  input: UninstallTagInput;
};


export type MutationunlockUserConversationArgs = {
  input: UserConversationUnlockInput;
};


export type MutationunscheduleEdsCronJobArgs = {
  input: UnscheduleEdsCronJobInput;
};


export type MutationunsubscribeArgs = {
  input: UnsubscribeInput;
};


export type MutationupdateAIBrandVoiceSettingsArgs = {
  input: UpdateAIBrandVoiceSettingsInput;
};


export type MutationupdateAIBrandVoiceSettingsV2Args = {
  input: UpdateAIBrandVoiceSettingsV2Input;
};


export type MutationupdateAdminNotificationConfigurationArgs = {
  input: UpdateAdminNotificationConfigurationInput;
};


export type MutationupdateAgentTemplateArgs = {
  input: UpdateRequest;
};


export type MutationupdateAgentTemplateRequestArgs = {
  input: UpdateAgentTemplateRequestInput;
};


export type MutationupdateApplicationWebhookArgs = {
  input: UpdateApplicationWebhookInput;
};


export type MutationupdateAttributionSettingsArgs = {
  input: UpdateAttributionSettingsInput;
};


export type MutationupdateAutoJourneyArgs = {
  input: UpdateAutoJourneyInput;
};


export type MutationupdateAutomationProgramArgs = {
  input: UpdateAutomationProgramInput;
};


export type MutationupdateBlockCompanyInfoArgs = {
  input: UpdateBlockCompanyInfoInput;
};


export type MutationupdateCampaignMessageGroupArgs = {
  input: UpdateCampaignMessageGroupInput;
};


export type MutationupdateCircuitBreakerRateLimitsArgs = {
  input: UpdateCircuitBreakerRateLimitInput;
};


export type MutationupdateCompanyBrandVoiceArgs = {
  input: UpdateCompanyBrandVoiceInput;
};


export type MutationupdateCompanyEmailIntegrationArgs = {
  input: UpdateCompanyEmailIntegrationInput;
};


export type MutationupdateCompanyLinkParametersArgs = {
  input: UpdateCompanyLinkParametersInput;
};


export type MutationupdateCompanyRegionLocalizedSettingsArgs = {
  input: UpdateCompanyRegionLocalizedSettingsInput;
};


export type MutationupdateCompanySettingsArgs = {
  input: UpdateCompanySettingsInput;
};


export type MutationupdateCompanyV2Args = {
  input: UpdateCompanyV2Input;
};


export type MutationupdateCompanyVendorIntegrationStatusArgs = {
  input: UpdateCompanyVendorIntegrationStatusInput;
};


export type MutationupdateComposeCampaignArgs = {
  input: UpdateComposeCampaignInput;
};


export type MutationupdateCompositionMessageRequestArgs = {
  input: UpdateCompositionMessageInput;
};


export type MutationupdateConciergeCompanyNotesArgs = {
  input: UpdateConciergeCompanyNotesInput;
};


export type MutationupdateConciergeCompanyPromotionArgs = {
  input: UpdateConciergeCompanyPromotionInput;
};


export type MutationupdateConciergeJourneyControlsArgs = {
  input: UpdateConciergeJourneyControlsInput;
};


export type MutationupdateConciergeSelfServeSettingsAgentPreferencesArgs = {
  input: UpdateConciergeSelfServeSettingsAgentPreferencesInput;
};


export type MutationupdateConciergeSelfServeSettingsCustomerSupportArgs = {
  input: UpdateConciergeSelfServeSettingsCustomerSupportInput;
};


export type MutationupdateConciergeSelfServeSettingsJourneysArgs = {
  input: UpdateConciergeSelfServeSettingsJourneysInput;
};


export type MutationupdateConciergeSelfServeSettingsShippingArgs = {
  input: UpdateConciergeSelfServeSettingsShippingInput;
};


export type MutationupdateConciergeSelfServeSettingsSignUpArgs = {
  input: UpdateConciergeSelfServeSettingsSignUpInput;
};


export type MutationupdateConciergeSelfServeSettingsSubmissionArgs = {
  input: UpdateConciergeSelfServeSettingsSubmissionInput;
};


export type MutationupdateConciergeSettingsArgs = {
  input: UpdateConciergeSettingsInput;
};


export type MutationupdateConversationsSettingsArgs = {
  input: UpdateConversationsSettingsInput;
};


export type MutationupdateCouponSetArgs = {
  input: UpdateCouponSetInput;
};


export type MutationupdateCouponUploadArgs = {
  input: UpdateCouponUploadInput;
};


export type MutationupdateCreativeArgs = {
  input: UpdateCreativeInput;
};


export type MutationupdateCreativeContentArgs = {
  input: UpdateCreativeContentInput;
};


export type MutationupdateCreativeExperimentTrafficSplitArgs = {
  input: UpdateCreativeExperimentTrafficSplitInput;
};


export type MutationupdateCreativeScheduleArgs = {
  input: UpdateCreativeScheduleAndMembersInput;
};


export type MutationupdateCreativeScheduleWorkflowArgs = {
  input: UpdateCreativeScheduleWorkflowInput;
};


export type MutationupdateCustomCompanyKeywordArgs = {
  input: UpdateCustomCompanyKeywordInput;
};


export type MutationupdateCustomEventKeyArgs = {
  input: UpdateCustomEventKeyInput;
};


export type MutationupdateCustomKeywordArgs = {
  input: UpdateCustomKeywordInput;
};


export type MutationupdateCustomPropertyDefinitionArgs = {
  input: UpdateCustomPropertyDefinitionInput;
};


export type MutationupdateCustomReportArgs = {
  input: UpdateCustomReportInput;
};


export type MutationupdateDatastreamImpactMetadataArgs = {
  input: UpdateImpactMetadataInput;
};


export type MutationupdateDatastreamParallelismArgs = {
  input: UpdateDatastreamParallelismInput;
};


export type MutationupdateDatastreamsParallelismArgs = {
  input: UpdateDatastreamsParallelismInput;
};


export type MutationupdateDismissibleModalArgs = {
  input: UpdateDismissibleModalInput;
};


export type MutationupdateEmailAccountPoolsArgs = {
  input: UpdateEmailAccountPoolsInput;
};


export type MutationupdateEmailIpAddressArgs = {
  input: UpdateEmailIPAddressInput;
};


export type MutationupdateEmailIpPoolArgs = {
  input: UpdateEmailIPPoolInput;
};


export type MutationupdateEmailSettingsArgs = {
  input: UpdateEmailSettingsInput;
};


export type MutationupdateEncryptionArgs = {
  input: UpdateEncryptionInput;
};


export type MutationupdateEscalationEmailsArgs = {
  input: UpdateEscalationEmailsInput;
};


export type MutationupdateEtlConfigCompaniesArgs = {
  input: UpdateEtlConfigCompaniesInput;
};


export type MutationupdateEventReplayToPendingArgs = {
  input: ChangeEventReplayInput;
};


export type MutationupdateEventReplayToPendingV2Args = {
  input: ChangeEventReplayV2Input;
};


export type MutationupdateEventReplayToQueuedArgs = {
  input: ChangeEventReplayInput;
};


export type MutationupdateEventReplayToQueuedV2Args = {
  input: ChangeEventReplayV2Input;
};


export type MutationupdateEventReplayToReadyArgs = {
  input: ChangeEventReplayInput;
};


export type MutationupdateEventReplayToReadyV2Args = {
  input: ChangeEventReplayV2Input;
};


export type MutationupdateFilterArgs = {
  input: UpdateFilterInput;
};


export type MutationupdateJourneyArgs = {
  input: UpdateJourneyInput;
};


export type MutationupdateJourneyDraftRevisionApprovalStatusArgs = {
  input: UpdateJourneyRevisionApprovalStatusInput;
};


export type MutationupdateJourneyRevisionArgs = {
  input: UpdateJourneyRevisionInput;
};


export type MutationupdateJourneyScheduleArgs = {
  input: UpdateJourneyScheduleInput;
};


export type MutationupdateJourneyStatusArgs = {
  input: UpdateJourneyStatusInput;
};


export type MutationupdateJourneyTriggerArgs = {
  input: UpdateJourneyTriggerInput;
};


export type MutationupdateJourneyTriggerStatusArgs = {
  input: UpdateJourneyTriggerStatusInput;
};


export type MutationupdateLegacyScheduledReportRecipientArgs = {
  input: UpdateLegacyScheduledReportRecipientInput;
};


export type MutationupdateLegacyTagConfigArgs = {
  input: UpdateLegacyTagConfigInput;
};


export type MutationupdateMessageRequestArgs = {
  input: UpdateMessageInput;
};


export type MutationupdateMessageTemplateArgs = {
  input: UpdateMessageTemplateInput;
};


export type MutationupdateMobileWalletCardArgs = {
  input: UpdateMobileWalletCardInput;
};


export type MutationupdateNBAConfigArgs = {
  input: UpdateNBAConfigInput;
};


export type MutationupdateNotificationBannerArgs = {
  input: UpdateNotificationBannerInput;
};


export type MutationupdateNotificationEmailTemplateArgs = {
  input: UpdateNotificationEmailTemplateInput;
};


export type MutationupdatePollStatusArgs = {
  input: UpdatePollStatusInput;
};


export type MutationupdatePollV2Args = {
  input: UpdatePollV2Input;
};


export type MutationupdatePrebuiltContentArgs = {
  input: UpdatePrebuiltContentInput;
};


export type MutationupdatePrebuiltProgramArgs = {
  input: UpdatePrebuiltProgramInput;
};


export type MutationupdateProductSegmentArgs = {
  input: UpdateProductSegmentInput;
};


export type MutationupdateProviderMigrationArgs = {
  input: UpdateProviderMigrationInput;
};


export type MutationupdateProviderMigrationsArgs = {
  input: CreateProviderMigrationsInput;
};


export type MutationupdateRateLimitArgs = {
  input: UpdateRateLimitInput;
};


export type MutationupdateRegionArgs = {
  input: UpdateRegionInput;
};


export type MutationupdateRevisionStepArgs = {
  input: UpdateRevisionStepInput;
};


export type MutationupdateSegmentFromSearchDefinitionArgs = {
  input: UpdateSegmentFromSearchDefinitionInput;
};


export type MutationupdateSendScheduledReportOverSftpArgs = {
  input: UpdateSendScheduledReportOverSftpInput;
};


export type MutationupdateSftpUserEmailArgs = {
  input: UpdateSftpUserEmailInput;
};


export type MutationupdateShopifyAutoApplyFormatArgs = {
  input: UpdateShopifyAutoApplyFormatsInput;
};


export type MutationupdateSyncArgs = {
  input: UpdateSyncInput;
};


export type MutationupdateTagArgs = {
  input: UpdateTagInput;
};


export type MutationupdateWebTemplateArgs = {
  input: UpdateWebTemplateInput;
};


export type MutationupdateWebhookArgs = {
  input: UpdateWebhookInput;
};


export type MutationupdateWorkloadConfigArgs = {
  input: UpdateWorkloadConfigInput;
};


export type MutationuploadManualSegmentArgs = {
  input: UploadManualSegmentInput;
};


export type MutationupsertAIJourneyDefaultMessageArgs = {
  input: UpsertAIJourneyDefaultMessageInput;
};


export type MutationupsertAccountArgs = {
  input: UpsertAccountInput;
};


export type MutationupsertAffiliateUserArgs = {
  input: UpsertAffiliateUserInput;
};


export type MutationupsertClientSegmentImportArgs = {
  input: UpsertClientSegmentImportInput;
};


export type MutationupsertEdsTaskProcessingConfigArgs = {
  input: UpsertEdsTaskProcessingConfigInput;
};


export type MutationupsertKeywordRuleArgs = {
  input: UpsertKeywordRuleRequestInput;
};


export type MutationupsertPollKeywordArgs = {
  input: UpsertPollKeywordInput;
};


export type MutationvalidateFieldMappingForSubscriberUploadFileArgs = {
  input: ValidateMappingForFileInput;
};


export type MutationverifyChallengeArgs = {
  input: VerifyChallengeInput;
};


export type MutationverifyChallengeForAdminAccessArgs = {
  input: VerifyChallengeForAdminAccessInput;
};


export type MutationverifyMfaOneTimePasscodeArgs = {
  input: VerifyMfaOneTimePasscodeInput;
};


export type MutationverifySendingDomainArgs = {
  input: VerifySendingDomainInput;
};


export type MutationverifyServerSideTagDnsRecordsArgs = {
  input: VerifyServerSideTagDnsRecordsInput;
};


export type MutationverifySubdomainArgs = {
  input: VerifySubdomainInput;
};

/** Generated from com/attentivemobile/contracts/event/platform/registration/service/Transformation.proto */
export type NeedEnrichment = {
  __typename?: 'NeedEnrichment';
  dependentFields: Array<DependentField>;
  expression: Scalars['String']['output'];
};

export type NewAttachmentRequest = {
  attachmentContextRequest: AttachmentBodyProperties;
  attachmentType: AttachmentType;
};

export type NextAgentUserInput = {
  userId: InputMaybe<Scalars['ID']['input']>;
};

/** Generated from com/attentivemobile/contracts/concierge/service/Contract.proto */
export type NextAgentUserPayload = {
  __typename?: 'NextAgentUserPayload';
  prevUser: Maybe<User>;
  user: Maybe<User>;
};

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type NextBestAction = Node & {
  __typename?: 'NextBestAction';
  category: NextBestActionCategory;
  ctaUrl: Scalars['String']['output'];
  description: Scalars['String']['output'];
  externalId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  imageUrl: Scalars['String']['output'];
  infoUrl: Scalars['String']['output'];
  isFeatured: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  pill: Scalars['String']['output'];
  priority: Scalars['Long']['output'];
  title: Scalars['String']['output'];
  type: NextBestActionDisplayType;
};

export enum NextBestActionCategory {
  NbaCategoryDriveRoi = 'NBA_CATEGORY_DRIVE_ROI',
  NbaCategoryGrowYourList = 'NBA_CATEGORY_GROW_YOUR_LIST',
  NbaCategoryIntegrations = 'NBA_CATEGORY_INTEGRATIONS',
  NbaCategoryMaximizeRevenue = 'NBA_CATEGORY_MAXIMIZE_REVENUE',
  NbaCategoryUnknown = 'NBA_CATEGORY_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type NextBestActionConfiguration = Node & {
  __typename?: 'NextBestActionConfiguration';
  category: NextBestActionCategory;
  ctaUrl: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  imageUrl: Scalars['String']['output'];
  infoUrl: Scalars['String']['output'];
  isFeatured: Scalars['Boolean']['output'];
  priority: Scalars['Long']['output'];
  smartRecommendationKey: Scalars['String']['output'];
  startTime: Scalars['DateTime']['output'];
  title: Scalars['String']['output'];
  type: NextBestActionDisplayType;
};

export type NextBestActionConfigurationInput = {
  category: NextBestActionCategory;
  ctaUrl: Scalars['String']['input'];
  description: Scalars['String']['input'];
  imageUrl: Scalars['String']['input'];
  infoUrl: Scalars['String']['input'];
  isFeatured: Scalars['Boolean']['input'];
  priority: Scalars['Long']['input'];
  smartRecommendationKey: InputMaybe<Scalars['String']['input']>;
  startTime: Scalars['DateTime']['input'];
  title: Scalars['String']['input'];
  type: NextBestActionDisplayType;
};

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type NextBestActionConnection = {
  __typename?: 'NextBestActionConnection';
  edges: Array<NextBestActionEdge>;
  pageInfo: PageInfo;
};

export enum NextBestActionDisplayType {
  NbaDisplayTypeSimplePrompt = 'NBA_DISPLAY_TYPE_SIMPLE_PROMPT',
  NbaDisplayTypeUnknown = 'NBA_DISPLAY_TYPE_UNKNOWN',
  NbaDisplayTypeVideoPrompt = 'NBA_DISPLAY_TYPE_VIDEO_PROMPT'
}

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type NextBestActionEdge = {
  __typename?: 'NextBestActionEdge';
  cursor: Scalars['String']['output'];
  node: NextBestAction;
};

export type Node = {
  id: Scalars['ID']['output'];
};

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type NodeCollection = Node & {
  __typename?: 'NodeCollection';
  author: Maybe<Account>;
  authorId: Scalars['Long']['output'];
  created: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  image: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updated: Scalars['DateTime']['output'];
  uuid: Scalars['String']['output'];
  visibilityTypeId: Scalars['Long']['output'];
};

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type NodeCollectionConnection = {
  __typename?: 'NodeCollectionConnection';
  edges: Array<NodeCollectionEdge>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type NodeCollectionEdge = {
  __typename?: 'NodeCollectionEdge';
  cursor: Scalars['String']['output'];
  node: NodeCollection;
};

/** Generated from com/attentivemobile/contracts/journeys/api/RevisionStep.proto */
export type NonAdderStep = {
  __typename?: 'NonAdderStep';
  type: Maybe<NonAdderStepType>;
};

export type NonAdderStepType = CreateSupportTicketStep | DelayStep | ExperimentStep | ForwardToConciergeStep | IncomingTextStep | SaveUserAttributeStep | VariantStep;

/** Generated from com/attentivemobile/contracts/segment/recommendation/service/Contract.proto */
export type NotFoundSegmentEnhancementWorkflowStatus = {
  __typename?: 'NotFoundSegmentEnhancementWorkflowStatus';
  company: Maybe<Company>;
  workflowId: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type Notification = Node & {
  __typename?: 'Notification';
  callToAction: Scalars['String']['output'];
  description: Scalars['String']['output'];
  dismissable: Scalars['Boolean']['output'];
  eventDate: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  read: Scalars['Boolean']['output'];
  severity: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type NotificationBanner = Node & {
  __typename?: 'NotificationBanner';
  callToAction: Scalars['String']['output'];
  description: Scalars['String']['output'];
  eventDate: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  startTime: Scalars['DateTime']['output'];
  stopTime: Scalars['DateTime']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type NotificationBannerConnection = {
  __typename?: 'NotificationBannerConnection';
  edges: Array<NotificationBannerEdge>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type NotificationBannerEdge = {
  __typename?: 'NotificationBannerEdge';
  cursor: Scalars['String']['output'];
  node: NotificationBanner;
};

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type NotificationConnection = {
  __typename?: 'NotificationConnection';
  edges: Array<NotificationEdge>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type NotificationEdge = {
  __typename?: 'NotificationEdge';
  cursor: Scalars['String']['output'];
  node: Notification;
};

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type NotificationEmailTemplate = Node & {
  __typename?: 'NotificationEmailTemplate';
  emailBody: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  messagingTemplateId: Scalars['String']['output'];
  subject: Scalars['String']['output'];
  templatingSyntaxType: TemplatingSyntaxType;
};

export type NotificationEmailTemplateInput = {
  emailBody: InputMaybe<Scalars['String']['input']>;
  messagingTemplateId: InputMaybe<Scalars['String']['input']>;
  subject: InputMaybe<Scalars['String']['input']>;
  templatingSyntaxType: InputMaybe<TemplatingSyntaxType>;
};

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type NotificationFilter = Node & {
  __typename?: 'NotificationFilter';
  cooldownAllInstances: Scalars['Boolean']['output'];
  dismissalCooldownSeconds: Scalars['Long']['output'];
  emailCooldownSeconds: Scalars['Long']['output'];
  emailDelaySeconds: Maybe<Scalars['Long']['output']>;
  featureFlag: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  notificationId: Scalars['String']['output'];
  smartRecommendationKey: Scalars['String']['output'];
  startTime: Scalars['DateTime']['output'];
  stopTime: Scalars['DateTime']['output'];
};

export type NotificationFilterInput = {
  cooldownAllInstances: InputMaybe<Scalars['Boolean']['input']>;
  dismissalCooldownSeconds: InputMaybe<Scalars['Long']['input']>;
  emailCooldownSeconds: InputMaybe<Scalars['Long']['input']>;
  emailDelaySeconds: InputMaybe<Scalars['Long']['input']>;
  featureFlag: InputMaybe<Scalars['String']['input']>;
  filterId: Scalars['ID']['input'];
  notificationId: InputMaybe<Scalars['String']['input']>;
  smartRecommendationKey: InputMaybe<Scalars['String']['input']>;
  startTime: InputMaybe<Scalars['DateTime']['input']>;
  stopTime: InputMaybe<Scalars['DateTime']['input']>;
};

export enum NotificationType {
  NotificationTypeAlerts = 'NOTIFICATION_TYPE_ALERTS',
  NotificationTypeAll = 'NOTIFICATION_TYPE_ALL',
  NotificationTypeFeatured = 'NOTIFICATION_TYPE_FEATURED',
  NotificationTypeMarketing = 'NOTIFICATION_TYPE_MARKETING',
  NotificationTypeNextBestAction = 'NOTIFICATION_TYPE_NEXT_BEST_ACTION',
  NotificationTypePerformance = 'NOTIFICATION_TYPE_PERFORMANCE',
  NotificationTypeProductContent = 'NOTIFICATION_TYPE_PRODUCT_CONTENT',
  NotificationTypeUnknown = 'NOTIFICATION_TYPE_UNKNOWN'
}

/** Generated from com/attentivemobile/protobuf/common/targeting/segmentation/output/ComparisonTypes.proto */
export type NumericComparison = {
  __typename?: 'NumericComparison';
  comparator: SearchNumericComparator;
  endValue: Maybe<Scalars['Float']['output']>;
  isNot: Maybe<Scalars['Boolean']['output']>;
  values: Array<Scalars['Float']['output']>;
};

export type NumericComparisonInput = {
  comparator: SearchNumericComparator;
  endValue: InputMaybe<Scalars['Float']['input']>;
  isNot: InputMaybe<Scalars['Boolean']['input']>;
  values: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type NumericDatasetFilter = {
  operator: NumericFilterOperator;
  values: InputMaybe<Array<Scalars['Float']['input']>>;
};

/** Generated from com/attentivemobile/contracts/metricsapi/DatasetsContract.proto */
export type NumericDatasetValue = {
  __typename?: 'NumericDatasetValue';
  value: Scalars['Float']['output'];
};

export enum NumericFilterOperator {
  NumericFilterOperatorIn = 'NUMERIC_FILTER_OPERATOR_IN',
  NumericFilterOperatorNotIn = 'NUMERIC_FILTER_OPERATOR_NOT_IN',
  NumericFilterOperatorUnknown = 'NUMERIC_FILTER_OPERATOR_UNKNOWN'
}

export type OffboardPaymentCompanyInput = {
  companyId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/payments/v2/Contract.proto */
export type OffboardPaymentCompanyPayload = {
  __typename?: 'OffboardPaymentCompanyPayload';
  status: PaymentCompanyStateChangeStatus;
};

/** Generated from com/attentivemobile/contracts/composition/MessageAttachment.proto */
export type OfferAttachment = {
  __typename?: 'OfferAttachment';
  entityToken: Scalars['String']['output'];
  offer: Maybe<CouponSet>;
};

export type OfferAttachmentProperties = {
  couponSetId: Scalars['ID']['input'];
  entityToken: Scalars['String']['input'];
};

export enum OfferDiscountType {
  DiscountTypeCode = 'DISCOUNT_TYPE_CODE',
  DiscountTypeDollarAmount = 'DISCOUNT_TYPE_DOLLAR_AMOUNT',
  DiscountTypePercentage = 'DISCOUNT_TYPE_PERCENTAGE',
  DiscountTypeUnknown = 'DISCOUNT_TYPE_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/messaging/automation/aijourneys/onboarding/AIJourneyLinkConfiguration.proto */
export type OfferLinkConfig = {
  __typename?: 'OfferLinkConfig';
  /** This field is ignored. Included to maintain compatibility with the graph. */
  emptyUrl: Maybe<Scalars['String']['output']>;
};

export type OfferLinkConfigInput = {
  /** This field is ignored. Included to maintain compatibility with the graph. */
  emptyUrl: InputMaybe<Scalars['String']['input']>;
};

export type OnboardCompanySendingDomainInput = {
  companyId: Scalars['ID']['input'];
  domain: Scalars['String']['input'];
  fromAddress: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/emails/management/Contract.proto */
export type OnboardCompanySendingDomainPayload = {
  __typename?: 'OnboardCompanySendingDomainPayload';
  sendingDomain: SendingDomain;
};

export type OnboardPaymentCompanyInput = {
  companyId: Scalars['ID']['input'];
  disableOrderConfirmMessages: Scalars['Boolean']['input'];
};

/** Generated from com/attentivemobile/contracts/payments/v2/Contract.proto */
export type OnboardPaymentCompanyPayload = {
  __typename?: 'OnboardPaymentCompanyPayload';
  status: PaymentCompanyStateChangeStatus;
};

export type OnboardSharedSendingDomainInput = {
  companyId: Scalars['ID']['input'];
  fromAddress: Scalars['String']['input'];
  sharedSendingDomainName: InputMaybe<Scalars['String']['input']>;
};

/** Generated from com/attentivemobile/contracts/emails/management/Contract.proto */
export type OnboardSharedSendingDomainPayload = {
  __typename?: 'OnboardSharedSendingDomainPayload';
  emailAccount: EmailAccount;
};

export type OnboardSubdomainInput = {
  companyId: Scalars['ID']['input'];
  subdomain: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/emails/management/Contract.proto */
export type OnboardSubdomainPayload = {
  __typename?: 'OnboardSubdomainPayload';
  sendingDomain: SendingDomain;
};

/** Generated from com/attentivemobile/contracts/company-service/onboarding/Survey.proto */
export type OnboardingSurveySelection = {
  __typename?: 'OnboardingSurveySelection';
  displayName: Scalars['String']['output'];
  optionKey: Maybe<Scalars['String']['output']>;
  selected: Scalars['Boolean']['output'];
  surveyOptionId: Scalars['Long']['output'];
  type: OnboardingSurveySelectionType;
};

export type OnboardingSurveySelectionInput = {
  displayName: Scalars['String']['input'];
  optionKey: InputMaybe<Scalars['String']['input']>;
  selected: Scalars['Boolean']['input'];
  surveyOptionId: Scalars['Long']['input'];
  type: OnboardingSurveySelectionType;
};

export enum OnboardingSurveySelectionType {
  OnboardingSurveySelectionTypeEcommercePlatform = 'ONBOARDING_SURVEY_SELECTION_TYPE_ECOMMERCE_PLATFORM',
  OnboardingSurveySelectionTypeEmailProvider = 'ONBOARDING_SURVEY_SELECTION_TYPE_EMAIL_PROVIDER',
  OnboardingSurveySelectionTypeOtherServices = 'ONBOARDING_SURVEY_SELECTION_TYPE_OTHER_SERVICES',
  OnboardingSurveySelectionTypeUnknown = 'ONBOARDING_SURVEY_SELECTION_TYPE_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/company-service/onboarding/Survey.proto */
export type OnboardingSurveySelectionsPayload = {
  __typename?: 'OnboardingSurveySelectionsPayload';
  company: Maybe<Company>;
};

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type OneTime = {
  __typename?: 'OneTime';
  runsImmediately: Scalars['Boolean']['output'];
};

export type OneTimeInput = {
  runsImmediately: Scalars['Boolean']['input'];
};

export enum OpenAIModel {
  OpenaiModelGpt_3_5Turbo = 'OPENAI_MODEL_GPT_3_5_TURBO',
  OpenaiModelGpt_4 = 'OPENAI_MODEL_GPT_4',
  OpenaiModelGpt_4_300Test = 'OPENAI_MODEL_GPT_4_300_TEST',
  OpenaiModelGpt_4O = 'OPENAI_MODEL_GPT_4_O',
  OpenaiModelGpt_4Turbo = 'OPENAI_MODEL_GPT_4_TURBO',
  OpenaiModelUnknown = 'OPENAI_MODEL_UNKNOWN',
  OpenAiModelGpt_4_0Mini = 'OPEN_AI_MODEL_GPT_4_0_MINI'
}

export type OpenAIOptions = {
  model: OpenAIModel;
};

export enum OperationMode {
  OperationModeCheckpoint = 'OPERATION_MODE_CHECKPOINT',
  OperationModeNone = 'OPERATION_MODE_NONE',
  OperationModeSavepoint = 'OPERATION_MODE_SAVEPOINT',
  OperationModeUnknown = 'OPERATION_MODE_UNKNOWN'
}

export enum OperationResult {
  OperationResultFailure = 'OPERATION_RESULT_FAILURE',
  OperationResultSuccess = 'OPERATION_RESULT_SUCCESS',
  OperationResultUnknown = 'OPERATION_RESULT_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type OptOutEvent = {
  __typename?: 'OptOutEvent';
  /** @deprecated deprecated */
  created: Scalars['DateTime']['output'];
  /** @deprecated deprecated */
  id: Scalars['String']['output'];
  subscription: Subscription;
};

export enum OptOutSource {
  OptOutSourceAttentiveSupportEmail = 'OPT_OUT_SOURCE_ATTENTIVE_SUPPORT_EMAIL',
  OptOutSourceCcpa = 'OPT_OUT_SOURCE_CCPA',
  OptOutSourceClient = 'OPT_OUT_SOURCE_CLIENT',
  OptOutSourceEmail = 'OPT_OUT_SOURCE_EMAIL',
  OptOutSourceKeyword = 'OPT_OUT_SOURCE_KEYWORD',
  OptOutSourceLitigator = 'OPT_OUT_SOURCE_LITIGATOR',
  OptOutSourceScript = 'OPT_OUT_SOURCE_SCRIPT',
  OptOutSourceUnknown = 'OPT_OUT_SOURCE_UNKNOWN'
}

export type OptOutUserByClientInput = {
  userId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/conversations/v1/Conversation.proto */
export type OptOutUserByClientPayload = {
  __typename?: 'OptOutUserByClientPayload';
  user: Maybe<User>;
};

/** Generated from com/attentivemobile/contracts/messaging/automation/Contract.proto */
export type OrchestrationConnection = {
  __typename?: 'OrchestrationConnection';
  edges: Array<OrchestrationEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Long']['output'];
};

export type OrchestrationContentInput =
  { prebuiltCampaignMessage: PrebuiltCampaignMessageInput; };

/** Generated from com/attentivemobile/contracts/messaging/automation/Contract.proto */
export type OrchestrationEdge = {
  __typename?: 'OrchestrationEdge';
  cursor: Scalars['String']['output'];
  node: ProgramOrchestration;
};

export enum OrchestrationType {
  OrchestrationTypeCampaign = 'ORCHESTRATION_TYPE_CAMPAIGN',
  OrchestrationTypeUnknown = 'ORCHESTRATION_TYPE_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type OrderCancelledEvent = {
  __typename?: 'OrderCancelledEvent';
  company: Maybe<Company>;
  /** @deprecated deprecated */
  created: Scalars['DateTime']['output'];
  /** @deprecated deprecated */
  id: Scalars['String']['output'];
  order: OrderInfo;
};

export enum OrderColumn {
  Id = 'ID',
  Name = 'NAME'
}

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type OrderConfirmedEvent = {
  __typename?: 'OrderConfirmedEvent';
  /** @deprecated deprecated */
  companyProductIds: Array<Scalars['ID']['output']>;
  /** @deprecated deprecated */
  created: Scalars['DateTime']['output'];
  /** @deprecated deprecated */
  id: Scalars['String']['output'];
  order: OrderInfo;
  /** @deprecated deprecated */
  orderId: Scalars['String']['output'];
  /** @deprecated deprecated */
  orderProducts: Array<ProductInfo>;
  /** @deprecated deprecated */
  orderStatusUrl: Scalars['String']['output'];
  /**
   * TEMP: A list of products related to this event
   * @deprecated deprecated
   */
  products: Array<ProductWrapper>;
};

export enum OrderDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

/**
 * Generated from com/attentivemobile/contracts/event/ecommerce/Order.proto
 * Represents an order for a product or service made by a consumer to a producer.
 */
export type OrderInfo = {
  __typename?: 'OrderInfo';
  /** The ID of the order, unique with respect to all orders managed by the eCommerce platform handling this order */
  externalOrderId: Scalars['String']['output'];
  /** The name of the order */
  name: Scalars['String']['output'];
  /** The list of line items, each detailing an item in the order */
  orderProducts: Array<OrderProduct>;
  /** Information about the customer making this order */
  orderRecipient: OrderRecipient;
  /** The URL of a webpage detailing the current status of the order */
  orderStatusUrl: Scalars['String']['output'];
  /** The totals associated with this order */
  orderTotals: BillingTotals;
  /** The time at which this order was created */
  processedAt: Scalars['DateTime']['output'];
  /** The number of the order made to the merchant */
  userOrderNumber: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/event/ecommerce/Order.proto */
export type OrderProduct = {
  __typename?: 'OrderProduct';
  companyProductVariant: Maybe<ProductVariant>;
  /** The title of the line item */
  name: Scalars['String']['output'];
  /** The price presented to a customer of a single item, before any discounts are applied */
  pricePresented: Money;
  /** The quantity of the item purchased */
  quantity: Scalars['Long']['output'];
};

/** Generated from com/attentivemobile/contracts/event/ecommerce/Order.proto */
export type OrderRecipient = {
  __typename?: 'OrderRecipient';
  /** The customer's billing address */
  billingAddress: PostalAddress;
  /** The customer's email address */
  email: Scalars['String']['output'];
  /** The customer's first name */
  firstName: Scalars['String']['output'];
  /** The customer's last name */
  lastName: Scalars['String']['output'];
  /** The customer's phone number */
  phone: Scalars['String']['output'];
  /** The customer's shipping address */
  shippingAddress: PostalAddress;
};

/** Generated from com/attentivemobile/protobuf/common/Context.proto */
export type OriginatingEntity = {
  __typename?: 'OriginatingEntity';
  entityType: EntityType;
  id: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/protobuf/common/graphql/pageinfo.proto */
export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor: Scalars['String']['output'];
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor: Scalars['String']['output'];
};

export type PaginatedProductsFilter = {
  name: InputMaybe<Scalars['String']['input']>;
};

/** Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto */
export type ParagraphContents = {
  __typename?: 'ParagraphContents';
  text: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/protobuf/common/campaigns/Campaign.proto */
export type PartnerCampaignAudienceFilter = {
  __typename?: 'PartnerCampaignAudienceFilter';
  campaignId: Scalars['String']['output'];
  isExcludingSubscribers: Scalars['Boolean']['output'];
};

export enum PartnershipTier {
  Elite = 'ELITE',
  None = 'NONE',
  PartnershipTierUnknown = 'PARTNERSHIP_TIER_UNKNOWN',
  Premier = 'PREMIER'
}

export enum PassedTimezonesStrategy {
  PassedTimezonesStrategyDoNotSend = 'PASSED_TIMEZONES_STRATEGY_DO_NOT_SEND',
  PassedTimezonesStrategySendNow = 'PASSED_TIMEZONES_STRATEGY_SEND_NOW',
  PassedTimezonesStrategySendOneDayLater = 'PASSED_TIMEZONES_STRATEGY_SEND_ONE_DAY_LATER',
  PassedTimezonesStrategyUnknown = 'PASSED_TIMEZONES_STRATEGY_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/solutions/sftp/EtlConfig.proto */
export type Paths = {
  __typename?: 'Paths';
  allowPathAndFilenameMismatch: Maybe<Scalars['Boolean']['output']>;
  conversionsFullPath: Maybe<Scalars['String']['output']>;
  generalDataFeedFullPath: Maybe<Scalars['String']['output']>;
};

export type PathsInput = {
  allowPathAndFilenameMismatch: InputMaybe<Scalars['Boolean']['input']>;
  conversionsFullPath: InputMaybe<Scalars['String']['input']>;
  generalDataFeedFullPath: InputMaybe<Scalars['String']['input']>;
};

export type PauseAiProTrialSubscriptionInput = {
  comment: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['ID']['input'];
  pauseReason: Scalars['String']['input'];
};

export type PauseAiProTrialSubscriptionPayload = AiProTrialActionFailure | PauseAiProTrialSubscriptionSuccess;

/** Generated from com/attentivemobile/contracts/segment/recommendation/service/aipro/Contract.proto */
export type PauseAiProTrialSubscriptionSuccess = {
  __typename?: 'PauseAiProTrialSubscriptionSuccess';
  success: Scalars['Boolean']['output'];
};

export type PauseComposeCampaignInput = {
  campaignMessageGroupId: InputMaybe<Scalars['ID']['input']>;
  companyId: Scalars['ID']['input'];
  composeCampaignId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export enum PaymentCompanyStateChangeStatus {
  PaymentCompanyStateChangeStatusFail = 'PAYMENT_COMPANY_STATE_CHANGE_STATUS_FAIL',
  PaymentCompanyStateChangeStatusSuccess = 'PAYMENT_COMPANY_STATE_CHANGE_STATUS_SUCCESS',
  PaymentCompanyStateChangeStatusUnknown = 'PAYMENT_COMPANY_STATE_CHANGE_STATUS_UNKNOWN'
}

export enum PaymentCompanyStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  NonExistent = 'NON_EXISTENT'
}

export enum PaymentOfferStatus {
  PaymentOfferStatusActive = 'PAYMENT_OFFER_STATUS_ACTIVE',
  PaymentOfferStatusDeleted = 'PAYMENT_OFFER_STATUS_DELETED',
  PaymentOfferStatusDraft = 'PAYMENT_OFFER_STATUS_DRAFT',
  PaymentOfferStatusExpired = 'PAYMENT_OFFER_STATUS_EXPIRED',
  PaymentOfferStatusUnknown = 'PAYMENT_OFFER_STATUS_UNKNOWN'
}

export enum PaymentOfferType {
  Dynamic = 'DYNAMIC',
  Static = 'STATIC'
}

/** Generated from com/attentivemobile/contracts/payments/v2/Contract.proto */
export type PaymentsOffer = Node & {
  __typename?: 'PaymentsOffer';
  discount: PaymentsOfferDiscountOutput;
  expiresAt: Maybe<Scalars['DateTime']['output']>;
  externalId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  internalId: Scalars['Long']['output'];
  keyword: Scalars['String']['output'];
  paymentOfferStatus: PaymentOfferStatus;
  productId: Scalars['String']['output'];
  productName: Scalars['String']['output'];
  productPrice: Money;
  productVariantId: Scalars['String']['output'];
};

export type PaymentsOfferDiscountInput = {
  type: OfferDiscountType;
  value: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/payments/v2/Contract.proto */
export type PaymentsOfferDiscountOutput = {
  __typename?: 'PaymentsOfferDiscountOutput';
  type: OfferDiscountType;
  value: Scalars['String']['output'];
};

export enum PhoneApplicationStatus {
  PhoneApplicationStatusApplied = 'PHONE_APPLICATION_STATUS_APPLIED',
  PhoneApplicationStatusApproved = 'PHONE_APPLICATION_STATUS_APPROVED',
  PhoneApplicationStatusCancelled = 'PHONE_APPLICATION_STATUS_CANCELLED',
  PhoneApplicationStatusPendingCarrierApproval = 'PHONE_APPLICATION_STATUS_PENDING_CARRIER_APPROVAL',
  PhoneApplicationStatusPendingMockup = 'PHONE_APPLICATION_STATUS_PENDING_MOCKUP',
  PhoneApplicationStatusUnassigned = 'PHONE_APPLICATION_STATUS_UNASSIGNED',
  PhoneApplicationStatusUnknown = 'PHONE_APPLICATION_STATUS_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/phonenumbers/external/Contract.proto */
export type PhoneNumber = Node & {
  __typename?: 'PhoneNumber';
  aggregator: AggregatorInfo;
  /** Companies assigned to this phone number. */
  assigned_companies: Maybe<Array<Scalars['ID']['output']>>;
  /** Messaging services associated with this phone number. */
  company_messaging_services: Maybe<Array<CompanyMessagingService>>;
  country: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  messagingServiceProviderId: Scalars['String']['output'];
  messagingServiceProviderName: Scalars['String']['output'];
  messagingServiceSid: Scalars['String']['output'];
  mmsVasId: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  phoneNumberType: Scalars['String']['output'];
  phoneNumberUseCase: PhoneNumberUseCase;
  /** The application status for the phone number. */
  phone_number_application_status: Maybe<PhoneNumberApplicationStatusHistory>;
  shared: Scalars['Boolean']['output'];
  shortCodeLeaseOwner: ShortCodeLeaseOwner;
  status: PhoneNumberStatus;
};

/** Generated from com/attentivemobile/contracts/phonenumbers/external/Contract.proto */
export type PhoneNumberApplicationStatusHistory = {
  __typename?: 'PhoneNumberApplicationStatusHistory';
  applicationId: Scalars['String']['output'];
  approvedDate: Scalars['DateTime']['output'];
  cancellationDate: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  phoneNumberId: Scalars['String']['output'];
  status: PhoneApplicationStatus;
  submittedDate: Scalars['DateTime']['output'];
};

export enum PhoneNumberCompanyAssignmentStatus {
  PhoneNumberCompanyAssignmentStatusAssigned = 'PHONE_NUMBER_COMPANY_ASSIGNMENT_STATUS_ASSIGNED',
  PhoneNumberCompanyAssignmentStatusNone = 'PHONE_NUMBER_COMPANY_ASSIGNMENT_STATUS_NONE',
  PhoneNumberCompanyAssignmentStatusUnknown = 'PHONE_NUMBER_COMPANY_ASSIGNMENT_STATUS_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/phonenumbers/external/Contract.proto */
export type PhoneNumberEdge = {
  __typename?: 'PhoneNumberEdge';
  cursor: Scalars['String']['output'];
  node: PhoneNumber;
};

export enum PhoneNumberStatus {
  PhoneNumberStatusActive = 'PHONE_NUMBER_STATUS_ACTIVE',
  PhoneNumberStatusInactive = 'PHONE_NUMBER_STATUS_INACTIVE',
  PhoneNumberStatusUnknown = 'PHONE_NUMBER_STATUS_UNKNOWN'
}

export enum PhoneNumberType {
  PhoneNumberTypeLongCode = 'PHONE_NUMBER_TYPE_LONG_CODE',
  PhoneNumberTypeShortCode = 'PHONE_NUMBER_TYPE_SHORT_CODE',
  PhoneNumberTypeTenDlc = 'PHONE_NUMBER_TYPE_TEN_DLC',
  PhoneNumberTypeTollFree = 'PHONE_NUMBER_TYPE_TOLL_FREE',
  PhoneNumberTypeUnknown = 'PHONE_NUMBER_TYPE_UNKNOWN'
}

export enum PhoneNumberUseCase {
  PhoneNumberUseCaseAttentive = 'PHONE_NUMBER_USE_CASE_ATTENTIVE',
  PhoneNumberUseCaseClient = 'PHONE_NUMBER_USE_CASE_CLIENT',
  PhoneNumberUseCaseTest = 'PHONE_NUMBER_USE_CASE_TEST',
  PhoneNumberUseCaseUnknown = 'PHONE_NUMBER_USE_CASE_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/phonenumbers/external/Contract.proto */
export type PhoneNumbersConnection = {
  __typename?: 'PhoneNumbersConnection';
  edges: Array<PhoneNumberEdge>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/event/platform/registration/service/Transformation.proto */
export type PiiScrubber = {
  __typename?: 'PiiScrubber';
  placeholder: Maybe<Scalars['String']['output']>;
};

export enum Placement {
  BrandkitPlacementAdditional = 'BRANDKIT_PLACEMENT_ADDITIONAL',
  BrandkitPlacementDefault = 'BRANDKIT_PLACEMENT_DEFAULT',
  BrandkitPlacementSecondary = 'BRANDKIT_PLACEMENT_SECONDARY'
}

/** Generated from com/attentivemobile/contracts/survey/poll/Contract.proto */
export type Poll = Node & {
  __typename?: 'Poll';
  allowAltSignupUnit: Maybe<Scalars['Boolean']['output']>;
  allowBreakTies: Maybe<Scalars['Boolean']['output']>;
  allowMarketingSubmitMessage: Maybe<Scalars['Boolean']['output']>;
  allowMultiVote: Scalars['Boolean']['output'];
  altSignupUnitId: Maybe<Scalars['Int']['output']>;
  applicationId: Maybe<Scalars['String']['output']>;
  audience: Maybe<AudienceResponse>;
  captureAll: Maybe<Scalars['Boolean']['output']>;
  closedMessage: Maybe<Scalars['String']['output']>;
  company: Maybe<Company>;
  createdAt: Scalars['DateTime']['output'];
  currentRunId: Maybe<Scalars['Int']['output']>;
  decimalPrecision: Maybe<Scalars['Int']['output']>;
  description: Maybe<Scalars['String']['output']>;
  duplicateVoteMessage: Maybe<Scalars['String']['output']>;
  end: Maybe<Scalars['DateTime']['output']>;
  hasWinnerFilter: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  isArchived: Maybe<Scalars['Boolean']['output']>;
  marketingSubmitMessage: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  onClosedMessage: Maybe<Scalars['String']['output']>;
  options: Array<PollOption>;
  participantAttributeName: Maybe<Scalars['String']['output']>;
  pollReport: Maybe<PollUsageReportResponse>;
  pollReportHistory: Maybe<PollUsageHistoryConnection>;
  poll_keywords: Maybe<GetPollKeywordsResponse>;
  poll_stats: Maybe<GetPollStatsResponse>;
  start: Maybe<Scalars['DateTime']['output']>;
  state: PollState;
  /** @deprecated deprecated */
  status: PollStatus;
  submitMessage: Maybe<Scalars['String']['output']>;
  subscribedEventType: Maybe<Scalars['String']['output']>;
  sweepstakesFilter: Maybe<GetSweepstakesFilterResponse>;
  totalResponses: Maybe<Scalars['Long']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  uuid: Scalars['String']['output'];
};


/** Generated from com/attentivemobile/contracts/survey/poll/Contract.proto */
export type PollpollReportArgs = {
  runId: Scalars['Int']['input'];
};


/** Generated from com/attentivemobile/contracts/survey/poll/Contract.proto */
export type PollpollReportHistoryArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** Generated from com/attentivemobile/contracts/survey/poll/Contract.proto */
export type PollEdge = {
  __typename?: 'PollEdge';
  cursor: Scalars['String']['output'];
  node: Poll;
};

export type PollGraphQuestionInput = {
  accountId: Scalars['ID']['input'];
  runId: Scalars['String']['input'];
};

export type PollGraphQuestionPayload = DefaultErrorFailure | PollGraphQuestionSuccess;

/** Generated from com/attentivemobile/contracts/graph/guru/service/GraphGuruService.proto */
export type PollGraphQuestionSuccess = {
  __typename?: 'PollGraphQuestionSuccess';
  message: Maybe<Scalars['String']['output']>;
  status: ThreadRunStatus;
};

/** Generated from com/attentivemobile/contracts/survey/poll/PollKeywordService.proto */
export type PollKeyword = Node & {
  __typename?: 'PollKeyword';
  altMatches: Array<Scalars['String']['output']>;
  deleted: Scalars['Boolean']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  keyword: Scalars['String']['output'];
  poll: Maybe<Poll>;
};

export type PollKeywordInput = {
  altMatches: InputMaybe<Array<Scalars['String']['input']>>;
  deleted: Scalars['Boolean']['input'];
  description: Scalars['String']['input'];
  keyword: Scalars['String']['input'];
  keywordId: InputMaybe<Scalars['ID']['input']>;
};

/** Generated from com/attentivemobile/contracts/survey/poll/Contract.proto */
export type PollOption = Node & {
  __typename?: 'PollOption';
  id: Scalars['ID']['output'];
  keyword: Scalars['String']['output'];
  optionUuid: Scalars['String']['output'];
  response: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/survey/poll/Contract.proto */
export type PollOptionEdge = {
  __typename?: 'PollOptionEdge';
  cursor: Scalars['String']['output'];
  node: PollOption;
};

export type PollPaging = {
  hideDisabled: InputMaybe<Scalars['Boolean']['input']>;
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  searchFilter: InputMaybe<Scalars['String']['input']>;
};

export type PollSorting = {
  sortBy: PollSortingField;
  sortDirection: SortDirection;
};

export enum PollSortingField {
  PollSortingFieldName = 'POLL_SORTING_FIELD_NAME',
  PollSortingFieldUpdated = 'POLL_SORTING_FIELD_UPDATED'
}

export enum PollState {
  Closed = 'CLOSED',
  Off = 'OFF',
  Open = 'OPEN'
}

/** Generated from com/attentivemobile/contracts/survey/poll/Contract.proto */
export type PollStats = {
  __typename?: 'PollStats';
  keywordStats: Array<KeywordStats>;
  keywordTimeline: KeywordsTimeline;
  poll: Maybe<Poll>;
};

export enum PollStatus {
  PollStatusCancelled = 'POLL_STATUS_CANCELLED',
  PollStatusComplete = 'POLL_STATUS_COMPLETE',
  PollStatusDeleted = 'POLL_STATUS_DELETED',
  PollStatusDraft = 'POLL_STATUS_DRAFT',
  PollStatusFailed = 'POLL_STATUS_FAILED',
  PollStatusInProgress = 'POLL_STATUS_IN_PROGRESS',
  PollStatusUnknown = 'POLL_STATUS_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/survey/poll/PollReportService.proto */
export type PollUsageHistoryConnection = {
  __typename?: 'PollUsageHistoryConnection';
  edges: Array<PollUsageReportEdge>;
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

/** Generated from com/attentivemobile/contracts/survey/poll/PollReportService.proto */
export type PollUsageReport = {
  __typename?: 'PollUsageReport';
  endDate: Scalars['DateTime']['output'];
  marketingParticipantCount: Scalars['Int']['output'];
  marketingParticipantPercent: Scalars['String']['output'];
  optInsCompleted: Scalars['Int']['output'];
  optInsCompletedPercent: Scalars['String']['output'];
  optInsStarted: Scalars['Int']['output'];
  participantCount: Scalars['Int']['output'];
  runId: Scalars['Int']['output'];
  startDate: Scalars['DateTime']['output'];
  totalVotes: Scalars['Int']['output'];
  transactionalParticipantCount: Scalars['Int']['output'];
  transactionalParticipantPercent: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/survey/poll/PollReportService.proto */
export type PollUsageReportEdge = {
  __typename?: 'PollUsageReportEdge';
  cursor: Scalars['String']['output'];
  node: PollUsageReport;
};

/** Generated from com/attentivemobile/contracts/survey/poll/PollReportService.proto */
export type PollUsageReportResponse = {
  __typename?: 'PollUsageReportResponse';
  endDate: Scalars['DateTime']['output'];
  optInsCompleted: Scalars['Int']['output'];
  optInsCompletedPercent: Scalars['String']['output'];
  optInsStarted: Scalars['Int']['output'];
  participantCount: Scalars['Int']['output'];
  runId: Scalars['Int']['output'];
  startDate: Scalars['DateTime']['output'];
  subscribedParticipantCount: Scalars['Int']['output'];
  subscribedParticipantPercent: Scalars['String']['output'];
  totalVotes: Scalars['Int']['output'];
  transactionalParticipantCount: Scalars['Int']['output'];
  transactionalParticipantPercent: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/survey/poll/Contract.proto */
export type PollsConnection = {
  __typename?: 'PollsConnection';
  edges: Array<PollEdge>;
  page: Scalars['Int']['output'];
  pageInfo: PageInfo;
  pageSize: Scalars['Int']['output'];
  totalCount: Maybe<Scalars['Int']['output']>;
};

export type PollsFilterV2 = {
  includedStates: InputMaybe<Array<PollState>>;
  searchTerm: InputMaybe<Scalars['String']['input']>;
  showDeleted: InputMaybe<Scalars['Boolean']['input']>;
};

export type PostCustomEventKeyInput = {
  active: Scalars['Boolean']['input'];
  customEventType: Scalars['String']['input'];
  keyType: CustomEventKeyType;
  propertyKey: Scalars['String']['input'];
};

export type PostCustomPropertyDefinitionInput = {
  enumValues: InputMaybe<Array<Scalars['String']['input']>>;
  /** @deprecated deprecated */
  isDeleted: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  propertyType: CustomPropertyType;
};

/** Generated from google/type/postal_address.proto */
export type PostalAddress = {
  __typename?: 'PostalAddress';
  addressLines: Array<Scalars['String']['output']>;
  administrativeArea: Scalars['String']['output'];
  languageCode: Scalars['String']['output'];
  locality: Scalars['String']['output'];
  organization: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  recipients: Array<Scalars['String']['output']>;
  regionCode: Scalars['String']['output'];
  revision: Scalars['Int']['output'];
  sortingCode: Scalars['String']['output'];
  sublocality: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/geography/Location.proto */
export type PostalCode = Node & {
  __typename?: 'PostalCode';
  countrySubdivision: Maybe<CountrySubdivision>;
  id: Scalars['ID']['output'];
  postalCode: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/geography/Location.proto */
export type PostalCodeAggregate = {
  __typename?: 'PostalCodeAggregate';
  subdivisionTotal: Scalars['Long']['output'];
  total: Scalars['Long']['output'];
};

/** Generated from com/attentivemobile/contracts/geography/Location.proto */
export type PostalCodeConnection = {
  __typename?: 'PostalCodeConnection';
  aggregate: PostalCodeAggregate;
  edges: Array<PostalCodeEdge>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/geography/Location.proto */
export type PostalCodeEdge = {
  __typename?: 'PostalCodeEdge';
  cursor: Scalars['String']['output'];
  node: PostalCode;
};

export type PostalCodeInput = {
  postalCode: Scalars['String']['input'];
};

export type PostalCodeInputList = {
  postalCodes: InputMaybe<Array<PostalCodeInput>>;
};

export type PreScheduleComposeCampaignInput = {
  companyId: Scalars['ID']['input'];
  composeCampaignId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type PreScheduleComposeCampaignPayload = DefaultErrorFailure | PreScheduleComposeCampaignSuccess;

/** Generated from com/attentivemobile/contracts/campaigns/service/Contract.proto */
export type PreScheduleComposeCampaignSuccess = {
  __typename?: 'PreScheduleComposeCampaignSuccess';
  campaign: ComposeCampaign;
};

/** Generated from com/attentivemobile/contracts/messaging/automation/prebuilt/Model.proto */
export type PrebuiltCampaignContent = {
  __typename?: 'PrebuiltCampaignContent';
  excludeOffer: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  scheduledAt: Scalars['DateTime']['output'];
  segmentQuery: Array<Scalars['String']['output']>;
  text: Scalars['String']['output'];
};

export type PrebuiltCampaignMessageInput = {
  excludeOffer: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  scheduledAt: Scalars['DateTime']['input'];
  segmentQuery: InputMaybe<Array<Scalars['String']['input']>>;
  text: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/messaging/automation/prebuilt/Model.proto */
export type PrebuiltContent = Node & {
  __typename?: 'PrebuiltContent';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /** @deprecated deprecated */
  orchestrationType: Scalars['String']['output'];
  orchestrationTypeEnum: OrchestrationType;
  orchestrations: Maybe<OrchestrationConnection>;
  prebuiltOrchestrationContent: Array<PrebuiltOrchestrationContent>;
  prebuiltProgram: Maybe<PrebuiltProgram>;
};


/** Generated from com/attentivemobile/contracts/messaging/automation/prebuilt/Model.proto */
export type PrebuiltContentorchestrationsArgs = {
  companyId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/messaging/automation/prebuilt/Contract.proto */
export type PrebuiltContentConnection = {
  __typename?: 'PrebuiltContentConnection';
  pageInfo: PageInfo;
  prebuiltContentEdges: Array<PrebuiltContentEdge>;
  totalCount: Scalars['Long']['output'];
};

/** Generated from com/attentivemobile/contracts/messaging/automation/prebuilt/Contract.proto */
export type PrebuiltContentEdge = {
  __typename?: 'PrebuiltContentEdge';
  cursor: Scalars['String']['output'];
  node: PrebuiltContent;
};

/** Generated from com/attentivemobile/contracts/messaging/automation/prebuilt/Model.proto */
export type PrebuiltOrchestrationContent = Node & {
  __typename?: 'PrebuiltOrchestrationContent';
  id: Scalars['ID']['output'];
  prebuiltOrchestrationContent: Maybe<PrebuiltOrchestrationContentPrebuiltOrchestrationContent>;
};

export type PrebuiltOrchestrationContentPrebuiltOrchestrationContent = PrebuiltCampaignContent;

/** Generated from com/attentivemobile/contracts/messaging/automation/prebuilt/Model.proto */
export type PrebuiltProgram = Node & {
  __typename?: 'PrebuiltProgram';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  prebuiltContent: Maybe<PrebuiltContentConnection>;
  scheduleType: ScheduleType;
  status: ProgramStatus;
  thumbnailUrl: Scalars['String']['output'];
  title: Scalars['String']['output'];
};


/** Generated from com/attentivemobile/contracts/messaging/automation/prebuilt/Model.proto */
export type PrebuiltProgramprebuiltContentArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** Generated from com/attentivemobile/contracts/messaging/automation/prebuilt/Contract.proto */
export type PrebuiltProgramConnection = {
  __typename?: 'PrebuiltProgramConnection';
  pageInfo: PageInfo;
  prebuiltProgramEdges: Array<PrebuiltProgramEdge>;
  totalCount: Scalars['Long']['output'];
};

/** Generated from com/attentivemobile/contracts/messaging/automation/prebuilt/Contract.proto */
export type PrebuiltProgramEdge = {
  __typename?: 'PrebuiltProgramEdge';
  cursor: Scalars['String']['output'];
  node: PrebuiltProgram;
};

export enum PreferenceType {
  PreferenceTypeOrdering = 'PREFERENCE_TYPE_ORDERING',
  PreferenceTypeUnknown = 'PREFERENCE_TYPE_UNKNOWN',
  PreferenceTypeVisibility = 'PREFERENCE_TYPE_VISIBILITY'
}

export enum PreferredMfaMethod {
  PreferredMfaMethodEmail = 'PREFERRED_MFA_METHOD_EMAIL',
  PreferredMfaMethodSms = 'PREFERRED_MFA_METHOD_SMS'
}

/** Generated from com/attentivemobile/protobuf/common/Url.proto */
export type PresignedUrl = {
  __typename?: 'PresignedUrl';
  expiresAt: Scalars['DateTime']['output'];
  value: Scalars['String']['output'];
};

export enum PriceComparator {
  PriceComparatorGreaterThan = 'PRICE_COMPARATOR_GREATER_THAN',
  PriceComparatorLessThan = 'PRICE_COMPARATOR_LESS_THAN'
}

export type PrivacyRequestSearchFilter = {
  search: InputMaybe<Scalars['String']['input']>;
};

export type PrivacyRequestSorting = {
  direction: InputMaybe<SortDirection>;
  sortBy: InputMaybe<Scalars['String']['input']>;
};

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type PrivateKey = {
  __typename?: 'PrivateKey';
  bucketName: Scalars['String']['output'];
  keyName: Scalars['String']['output'];
};

export type PrivateKeyInput = {
  bucketName: Scalars['String']['input'];
  keyName: Scalars['String']['input'];
};

export type ProcessCustomFontsInput = {
  companyId: Scalars['ID']['input'];
  fileUuids: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Generated from com/attentivemobile/contracts/brand-kit/BrandFont.proto */
export type ProcessCustomFontsPayload = {
  __typename?: 'ProcessCustomFontsPayload';
  availableBrandFonts: Array<AvailableBrandFont>;
};

export type ProcessTypekitInput = {
  typekitUrl: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/brand-kit/BrandFont.proto */
export type ProcessTypekitPayload = {
  __typename?: 'ProcessTypekitPayload';
  availableBrandFonts: Array<AvailableBrandFont>;
  processedTypekits: Array<ProcessedTypekit>;
};

/** Generated from com/attentivemobile/contracts/brand-kit/BrandFont.proto */
export type ProcessedTypekit = {
  __typename?: 'ProcessedTypekit';
  fontFamilyName: Scalars['String']['output'];
  format: Scalars['String']['output'];
  url: Url;
  variants: Array<Scalars['String']['output']>;
};

/** Generated from com/attentivemobile/contracts/product/attribute/service/gql/Contract.proto */
export type Product = Node & {
  __typename?: 'Product';
  attributes: Array<InventoryAttribute>;
  availability: ProductAvailability;
  brand: Scalars['String']['output'];
  categories: Array<Scalars['String']['output']>;
  collections: Array<Scalars['String']['output']>;
  company: Maybe<Company>;
  created: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  imageLinks: Array<ImageLink>;
  link: Scalars['String']['output'];
  maxPrice: Money;
  minPrice: Money;
  name: Scalars['String']['output'];
  originId: Scalars['String']['output'];
  originPublishedAt: Scalars['DateTime']['output'];
  originUpsertTime: Scalars['DateTime']['output'];
  productOptions: Array<ProductOptionValue>;
  tags: Array<Scalars['String']['output']>;
  updated: Scalars['DateTime']['output'];
  variants: Maybe<ProductVariantConnection>;
};


/** Generated from com/attentivemobile/contracts/product/attribute/service/gql/Contract.proto */
export type ProductvariantsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export enum ProductAvailability {
  ProductAvailabilityAvailableForOrder = 'PRODUCT_AVAILABILITY_AVAILABLE_FOR_ORDER',
  ProductAvailabilityDiscontinued = 'PRODUCT_AVAILABILITY_DISCONTINUED',
  ProductAvailabilityInStock = 'PRODUCT_AVAILABILITY_IN_STOCK',
  ProductAvailabilityOutOfStock = 'PRODUCT_AVAILABILITY_OUT_OF_STOCK',
  ProductAvailabilityPending = 'PRODUCT_AVAILABILITY_PENDING',
  ProductAvailabilityPreorder = 'PRODUCT_AVAILABILITY_PREORDER',
  ProductAvailabilityRemoved = 'PRODUCT_AVAILABILITY_REMOVED',
  ProductAvailabilityUnknown = 'PRODUCT_AVAILABILITY_UNKNOWN'
}

export type ProductCategoriesFilter = {
  name: InputMaybe<Scalars['String']['input']>;
};

/** Generated from com/attentivemobile/contracts/product/attribute/service/gql/Contract.proto */
export type ProductCategory = Node & {
  __typename?: 'ProductCategory';
  company: Maybe<Company>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/product/attribute/service/gql/Contract.proto */
export type ProductCategoryEdge = {
  __typename?: 'ProductCategoryEdge';
  cursor: Scalars['String']['output'];
  node: ProductCategory;
};

/** Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto */
export type ProductCategoryOutput = {
  __typename?: 'ProductCategoryOutput';
  productCategory: Maybe<ProductCategory>;
};

/** Generated from com/attentivemobile/contracts/product/attribute/service/gql/Contract.proto */
export type ProductCollection = Node & {
  __typename?: 'ProductCollection';
  company: Maybe<Company>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/product/attribute/service/gql/Contract.proto */
export type ProductCollectionEdge = {
  __typename?: 'ProductCollectionEdge';
  cursor: Scalars['String']['output'];
  node: ProductCollection;
};

/** Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto */
export type ProductCollectionOutput = {
  __typename?: 'ProductCollectionOutput';
  productCollection: Maybe<ProductCollection>;
};

export type ProductCollectionsFilter = {
  name: InputMaybe<Scalars['String']['input']>;
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type ProductInfo = {
  __typename?: 'ProductInfo';
  productId: Scalars['String']['output'];
  productName: Scalars['String']['output'];
  productSku: Scalars['String']['output'];
  productUrl: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto */
export type ProductInventoryFilter = {
  __typename?: 'ProductInventoryFilter';
  inventoryComparator: InventoryComparator;
  inventoryQuantity: Scalars['Long']['output'];
};

export type ProductInventoryFilterInput = {
  inventoryComparator: InventoryComparator;
  inventoryQuantity: Scalars['Long']['input'];
};

/** Generated from com/attentivemobile/contracts/product/attribute/service/gql/Contract.proto */
export type ProductOptionValue = {
  __typename?: 'ProductOptionValue';
  name: Scalars['String']['output'];
  values: Array<Scalars['String']['output']>;
};

/** Generated from com/attentivemobile/protobuf/common/Identifiers.proto */
export type ProductOriginID = {
  __typename?: 'ProductOriginID';
  company: Maybe<Company>;
  productOriginId: Scalars['String']['output'];
};

export type ProductProperty = CustomProductProperty | KnownProductProperty;

/** Generated from com/attentivemobile/contracts/product/attribute/service/gql/Contract.proto */
export type ProductPropertyOption = {
  __typename?: 'ProductPropertyOption';
  option: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto */
export type ProductPropertyOptionConnection = {
  __typename?: 'ProductPropertyOptionConnection';
  edges: Array<ProductPropertyOptionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Long']['output'];
};

/** Generated from com/attentivemobile/contracts/product/attribute/service/gql/Contract.proto */
export type ProductPropertyOptionEdge = {
  __typename?: 'ProductPropertyOptionEdge';
  cursor: Scalars['String']['output'];
  productPropertyOption: ProductPropertyOption;
};

export type ProductPropertyOptionsFilter = {
  searchTerm: InputMaybe<Scalars['String']['input']>;
};

export type ProductPropertyOptionsInput =
  { customProductProperty: CustomProductPropertyInput; knownProductProperty?: never; }
  |  { customProductProperty?: never; knownProductProperty: KnownProductPropertyInput; };

/** Generated from com/attentivemobile/contracts/productrecsservice/Contract.proto */
export type ProductRecommendation = {
  __typename?: 'ProductRecommendation';
  availability: Scalars['String']['output'];
  /** @deprecated deprecated */
  confidence: Scalars['Long']['output'];
  createdAt: Scalars['DateTime']['output'];
  currency: Scalars['String']['output'];
  description: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
  link: Scalars['String']['output'];
  /** @deprecated deprecated */
  maxPrice: Scalars['Long']['output'];
  maximumPrice: Scalars['Float']['output'];
  /** @deprecated deprecated */
  minPrice: Scalars['Long']['output'];
  minimumPrice: Scalars['Float']['output'];
  modelMetadata: ModelMetadata;
  modelScore: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  productId: Scalars['String']['output'];
  /** @deprecated deprecated */
  productRecommendationSource: ProductRecommendationSource;
  /** @deprecated deprecated */
  recommendationSourceModel: Scalars['String']['output'];
  /** @deprecated deprecated */
  recommendationSourceModelVersion: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/productrecsservice/Contract.proto */
export type ProductRecommendationMetaRedacted = {
  __typename?: 'ProductRecommendationMetaRedacted';
  createdAt: Scalars['DateTime']['output'];
  product: Maybe<Product>;
};

export enum ProductRecommendationSource {
  RecommendationSourceCompanyBestsellersJob = 'RECOMMENDATION_SOURCE_COMPANY_BESTSELLERS_JOB',
  RecommendationSourceLastPurchasedCategoryBestseller = 'RECOMMENDATION_SOURCE_LAST_PURCHASED_CATEGORY_BESTSELLER',
  RecommendationSourceUnknown = 'RECOMMENDATION_SOURCE_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/productrecsservice/Contract.proto */
export type ProductRecommendationsRedactedResponse = {
  __typename?: 'ProductRecommendationsRedactedResponse';
  productRecommendations: Array<ProductRecommendationMetaRedacted>;
};

/** Generated from com/attentivemobile/contracts/productrecsservice/Contract.proto */
export type ProductRecommendationsResponse = {
  __typename?: 'ProductRecommendationsResponse';
  productRecommendations: Array<ProductRecommendation>;
};

/** Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto */
export type ProductSegment = Node & {
  __typename?: 'ProductSegment';
  company: Maybe<Company>;
  created: Scalars['DateTime']['output'];
  filters: Array<ProductSegmentFilter>;
  id: Scalars['ID']['output'];
  imageUrl: Maybe<Url>;
  name: Scalars['String']['output'];
  productCount: Scalars['Long']['output'];
  updated: Scalars['DateTime']['output'];
};

/** Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto */
export type ProductSegmentCategoryIdFilter = {
  __typename?: 'ProductSegmentCategoryIdFilter';
  productCategories: Array<ProductCategoryOutput>;
};

export type ProductSegmentCategoryIdFilterInput = {
  ids: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto */
export type ProductSegmentCollectionIdFilter = {
  __typename?: 'ProductSegmentCollectionIdFilter';
  productCollections: Array<ProductCollectionOutput>;
};

export type ProductSegmentCollectionIdFilterInput = {
  ids: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto */
export type ProductSegmentEdge = {
  __typename?: 'ProductSegmentEdge';
  cursor: Scalars['String']['output'];
  node: ProductSegment;
};

/** Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto */
export type ProductSegmentFilter = {
  __typename?: 'ProductSegmentFilter';
  filter: Maybe<ProductSegmentFilterFilter>;
  inverse: Scalars['Boolean']['output'];
};

export type ProductSegmentFilterFilter = ProductInventoryFilter | ProductSegmentCategoryIdFilter | ProductSegmentCollectionIdFilter | ProductSegmentPriceFilter | ProductSegmentTagIdFilter;

export type ProductSegmentFilterInput = {
  filterInput: InputMaybe<ProductSegmentFilterInputFilterInput>;
  inverse: Scalars['Boolean']['input'];
};

export type ProductSegmentFilterInputFilterInput =
  { categoryIdFilter: ProductSegmentCategoryIdFilterInput; collectionIdFilter?: never; inventoryFilter?: never; priceFilter?: never; tagFilter?: never; }
  |  { categoryIdFilter?: never; collectionIdFilter: ProductSegmentCollectionIdFilterInput; inventoryFilter?: never; priceFilter?: never; tagFilter?: never; }
  |  { categoryIdFilter?: never; collectionIdFilter?: never; inventoryFilter: ProductInventoryFilterInput; priceFilter?: never; tagFilter?: never; }
  |  { categoryIdFilter?: never; collectionIdFilter?: never; inventoryFilter?: never; priceFilter: ProductSegmentPriceFilterInput; tagFilter?: never; }
  |  { categoryIdFilter?: never; collectionIdFilter?: never; inventoryFilter?: never; priceFilter?: never; tagFilter: ProductSegmentTagIdFilterInput; };

/** Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto */
export type ProductSegmentPriceFilter = {
  __typename?: 'ProductSegmentPriceFilter';
  price: Scalars['String']['output'];
  priceComparator: PriceComparator;
};

export type ProductSegmentPriceFilterInput = {
  price: Scalars['String']['input'];
  priceComparator: PriceComparator;
};

/** Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto */
export type ProductSegmentTagIdFilter = {
  __typename?: 'ProductSegmentTagIdFilter';
  productTags: Array<ProductTagOutput>;
};

export type ProductSegmentTagIdFilterInput = {
  ids: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto */
export type ProductSegmentsConnection = {
  __typename?: 'ProductSegmentsConnection';
  edges: Array<ProductSegmentEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Long']['output'];
};

export type ProductSegmentsFilter = {
  name: InputMaybe<Scalars['String']['input']>;
};

/** Generated from com/attentivemobile/contracts/product/attribute/service/gql/Contract.proto */
export type ProductSummary = Node & {
  __typename?: 'ProductSummary';
  company: Maybe<Company>;
  id: Scalars['ID']['output'];
  link: Scalars['String']['output'];
  name: Scalars['String']['output'];
  variants: Maybe<ProductVariantConnection>;
};


/** Generated from com/attentivemobile/contracts/product/attribute/service/gql/Contract.proto */
export type ProductSummaryvariantsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** Generated from com/attentivemobile/contracts/product/attribute/service/gql/Contract.proto */
export type ProductTag = Node & {
  __typename?: 'ProductTag';
  company: Maybe<Company>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/product/attribute/service/gql/Contract.proto */
export type ProductTagEdge = {
  __typename?: 'ProductTagEdge';
  cursor: Scalars['String']['output'];
  node: ProductTag;
};

/** Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto */
export type ProductTagOutput = {
  __typename?: 'ProductTagOutput';
  productTag: Maybe<ProductTag>;
};

export type ProductTagsFilter = {
  name: InputMaybe<Scalars['String']['input']>;
};

/** Generated from com/attentivemobile/contracts/product/attribute/service/gql/Contract.proto */
export type ProductVariant = Node & {
  __typename?: 'ProductVariant';
  attributes: Array<InventoryAttribute>;
  availability: ProductAvailability;
  availabilityDate: Maybe<Scalars['DateTime']['output']>;
  company: Maybe<Company>;
  created: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  imageLinks: Array<ImageLink>;
  inventoryQuantity: Scalars['Long']['output'];
  isDefault: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  originPublishedAt: Scalars['DateTime']['output'];
  originUpsertTime: Scalars['DateTime']['output'];
  prices: Array<ProductVariantPrice>;
  product: Maybe<Product>;
  productOriginId: Scalars['String']['output'];
  selectedOptions: Array<ProductVariantOptionValue>;
  updated: Scalars['DateTime']['output'];
  variantOriginId: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto */
export type ProductVariantConnection = {
  __typename?: 'ProductVariantConnection';
  edges: Array<ProductVariantEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Long']['output'];
};

/** Generated from com/attentivemobile/contracts/product/attribute/service/gql/Contract.proto */
export type ProductVariantEdge = {
  __typename?: 'ProductVariantEdge';
  cursor: Scalars['String']['output'];
  node: ProductVariant;
};

/** Generated from com/attentivemobile/contracts/product/attribute/service/gql/Contract.proto */
export type ProductVariantOptionValue = {
  __typename?: 'ProductVariantOptionValue';
  name: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  product: Maybe<Product>;
  value: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/product/attribute/service/gql/Contract.proto */
export type ProductVariantPrice = {
  __typename?: 'ProductVariantPrice';
  activeTime: Maybe<Scalars['DateTime']['output']>;
  compareAtPrice: Maybe<Money>;
  created: Scalars['DateTime']['output'];
  expirationTime: Maybe<Scalars['DateTime']['output']>;
  isActive: Scalars['Boolean']['output'];
  price: Money;
  priceType: ProductVariantPriceType;
  updated: Scalars['DateTime']['output'];
};

export enum ProductVariantPriceType {
  VariantPriceTypeRegular = 'VARIANT_PRICE_TYPE_REGULAR',
  VariantPriceTypeSale = 'VARIANT_PRICE_TYPE_SALE',
  VariantPriceTypeUnknown = 'VARIANT_PRICE_TYPE_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type ProductViewEvent = {
  __typename?: 'ProductViewEvent';
  companyProduct: Maybe<Product>;
  companyProductId: Scalars['ID']['output'];
  /** @deprecated deprecated */
  created: Scalars['DateTime']['output'];
  /** @deprecated deprecated */
  id: Scalars['String']['output'];
  /** @deprecated deprecated */
  productName: Scalars['String']['output'];
  /** @deprecated deprecated */
  productUrl: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type ProductWrapper = {
  __typename?: 'ProductWrapper';
  companyProduct: Maybe<Product>;
};

export enum ProfileVendor {
  ProfileVendorApplication = 'PROFILE_VENDOR_APPLICATION',
  ProfileVendorAttentive = 'PROFILE_VENDOR_ATTENTIVE',
  ProfileVendorBazaarVoice = 'PROFILE_VENDOR_BAZAAR_VOICE',
  ProfileVendorCompany = 'PROFILE_VENDOR_COMPANY',
  ProfileVendorInferred = 'PROFILE_VENDOR_INFERRED',
  ProfileVendorKlaviyo = 'PROFILE_VENDOR_KLAVIYO',
  ProfileVendorMparticle = 'PROFILE_VENDOR_MPARTICLE',
  ProfileVendorPunchh = 'PROFILE_VENDOR_PUNCHH',
  ProfileVendorRecharge = 'PROFILE_VENDOR_RECHARGE',
  ProfileVendorSegment = 'PROFILE_VENDOR_SEGMENT',
  ProfileVendorShopify = 'PROFILE_VENDOR_SHOPIFY',
  ProfileVendorSmileIo = 'PROFILE_VENDOR_SMILE_IO',
  ProfileVendorUnknown = 'PROFILE_VENDOR_UNKNOWN',
  ProfileVendorYotpo = 'PROFILE_VENDOR_YOTPO'
}

/** Generated from com/attentivemobile/contracts/messaging/automation/Contract.proto */
export type ProgramCadence = {
  __typename?: 'ProgramCadence';
  firstSendDate: Scalars['DateTime']['output'];
  programCadenceType: ProgramCadenceType;
  programFrequency: ProgramFrequency;
};

export enum ProgramCadenceType {
  ProgramCadenceTypeFixedPeriod = 'PROGRAM_CADENCE_TYPE_FIXED_PERIOD',
  ProgramCadenceTypeUnknown = 'PROGRAM_CADENCE_TYPE_UNKNOWN'
}

export enum ProgramCalendarPeriod {
  ProgramCalendarPeriodDay = 'PROGRAM_CALENDAR_PERIOD_DAY',
  ProgramCalendarPeriodMonth = 'PROGRAM_CALENDAR_PERIOD_MONTH',
  ProgramCalendarPeriodUnknown = 'PROGRAM_CALENDAR_PERIOD_UNKNOWN',
  ProgramCalendarPeriodWeek = 'PROGRAM_CALENDAR_PERIOD_WEEK'
}

/** Generated from com/attentivemobile/contracts/messaging/automation/Contract.proto */
export type ProgramFrequency = {
  __typename?: 'ProgramFrequency';
  period: Scalars['Int']['output'];
  periodUnit: ProgramCalendarPeriod;
};

/** Generated from com/attentivemobile/contracts/messaging/automation/Contract.proto */
export type ProgramOrchestration = Node & {
  __typename?: 'ProgramOrchestration';
  automationProgramId: Scalars['ID']['output'];
  deleted: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  orchestrationContent: Maybe<ProgramOrchestrationOrchestrationContent>;
  /** @deprecated deprecated */
  orchestrationId: Scalars['ID']['output'];
  orchestrationType: Scalars['String']['output'];
  prebuiltContentId: Maybe<Scalars['ID']['output']>;
};

export type ProgramOrchestrationOrchestrationContent = CampaignOrchestration;

export enum ProgramStatus {
  ProgramStatusActive = 'PROGRAM_STATUS_ACTIVE',
  ProgramStatusInactive = 'PROGRAM_STATUS_INACTIVE',
  ProgramStatusUnknown = 'PROGRAM_STATUS_UNKNOWN'
}

export enum PromptRefinementOption {
  PromptRefinementOptionAddEmojis = 'PROMPT_REFINEMENT_OPTION_ADD_EMOJIS',
  PromptRefinementOptionAddUrgency = 'PROMPT_REFINEMENT_OPTION_ADD_URGENCY',
  PromptRefinementOptionMakeCasual = 'PROMPT_REFINEMENT_OPTION_MAKE_CASUAL',
  PromptRefinementOptionMakeFunny = 'PROMPT_REFINEMENT_OPTION_MAKE_FUNNY',
  PromptRefinementOptionMakeShorter = 'PROMPT_REFINEMENT_OPTION_MAKE_SHORTER',
  PromptRefinementOptionUnknown = 'PROMPT_REFINEMENT_OPTION_UNKNOWN',
  PromptRefinementOptionUseBrandVoice = 'PROMPT_REFINEMENT_OPTION_USE_BRAND_VOICE'
}

/** Generated from com/attentivemobile/contracts/user/profile/property/UserPropertyV2.proto */
export type PropertyDefinitionId = {
  __typename?: 'PropertyDefinitionId';
  company: Maybe<Company>;
  definitionName: Scalars['String']['output'];
  displayName: Maybe<Scalars['String']['output']>;
  internal: Scalars['Boolean']['output'];
  reservedProperty: Maybe<ReservedPropertyType>;
  vendor: ProfileVendor;
  vendorId: Maybe<Scalars['String']['output']>;
  wellKnownProperty: Scalars['Boolean']['output'];
};

export type PropertyDefinitionInput = {
  vendor: ProfileVendor;
  vendorId: InputMaybe<Scalars['String']['input']>;
};

export type PropertyInput = {
  definitionName: Scalars['String']['input'];
  vendor: ProfileVendor;
  vendorId: InputMaybe<Scalars['String']['input']>;
};

export type PropertyLinkInput = {
  priority: Scalars['Int']['input'];
  propertyDefinitionId: PropertyDefinitionInput;
};

export type PropertyNameInput =
  { propertyInput: PropertyInput; reservedPropertyInput?: never; }
  |  { propertyInput?: never; reservedPropertyInput: ReservedPropertyInput; };

/** Generated from com/attentivemobile/protobuf/common/targeting/UserProperty.proto */
export type PropertyOrigin = {
  __typename?: 'PropertyOrigin';
  source: Maybe<Scalars['String']['output']>;
};

/** Generated from com/attentivemobile/protobuf/common/targeting/UserProperty.proto */
export type PropertyValueMetadata = {
  __typename?: 'PropertyValueMetadata';
  localDateFields: Maybe<LocalDateFields>;
  propertyOrigin: Maybe<PropertyOrigin>;
};

/** Generated from com/attentivemobile/contracts/event/platform/registration/service/Transformation.proto */
export type ProtoEventJoiner = {
  __typename?: 'ProtoEventJoiner';
  lowerBoundMilli: Scalars['Int']['output'];
  protoEventJoinerType: ProtoEventJoinerType;
  source: Maybe<ProtoEventJoinerSource>;
  sourceParallelism: Maybe<Scalars['Int']['output']>;
  upperBoundMilli: Scalars['Int']['output'];
};

export type ProtoEventJoinerSource = PulsarTopic | S3Location;

export enum ProtoEventJoinerType {
  ProtoEventJoinerTypeEnricherAnalytics = 'PROTO_EVENT_JOINER_TYPE_ENRICHER_ANALYTICS',
  ProtoEventJoinerTypeUnknown = 'PROTO_EVENT_JOINER_TYPE_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/event/platform/registration/service/Transformation.proto */
export type ProtoEventRateLimiter = {
  __typename?: 'ProtoEventRateLimiter';
  desiredRps: Scalars['Int']['output'];
};

export enum Provider {
  ProviderBandwidth = 'PROVIDER_BANDWIDTH',
  ProviderGeneric = 'PROVIDER_GENERIC',
  ProviderImiMobile = 'PROVIDER_IMI_MOBILE',
  ProviderMitto = 'PROVIDER_MITTO',
  ProviderOpenMarket = 'PROVIDER_OPEN_MARKET',
  ProviderSinch = 'PROVIDER_SINCH',
  ProviderTwilio = 'PROVIDER_TWILIO',
  ProviderUnknown = 'PROVIDER_UNKNOWN',
  ProviderVibes = 'PROVIDER_VIBES',
  ProviderZipwhip = 'PROVIDER_ZIPWHIP'
}

/** Generated from com/attentivemobile/contracts/providermigration/Contract.proto */
export type ProviderMigration = {
  __typename?: 'ProviderMigration';
  carrier: Carrier;
  endDate: Maybe<Scalars['DateTime']['output']>;
  newProvider: Provider;
  oldProvider: Provider;
  phoneNumber: Scalars['String']['output'];
  startDate: Scalars['DateTime']['output'];
  status: ProviderMigrationStatus;
};

/** Generated from com/attentivemobile/contracts/providermigration/Contract.proto */
export type ProviderMigrationConnection = {
  __typename?: 'ProviderMigrationConnection';
  edges: Array<ProviderMigration>;
  pageInfo: PageInfo;
};

export enum ProviderMigrationStatus {
  ProviderMigrationStatusCompleted = 'PROVIDER_MIGRATION_STATUS_COMPLETED',
  ProviderMigrationStatusInProgress = 'PROVIDER_MIGRATION_STATUS_IN_PROGRESS',
  ProviderMigrationStatusNotStarted = 'PROVIDER_MIGRATION_STATUS_NOT_STARTED',
  ProviderMigrationStatusUnknown = 'PROVIDER_MIGRATION_STATUS_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/providermigration/Contract.proto */
export type ProviderMigrationsResponse = {
  __typename?: 'ProviderMigrationsResponse';
  migrations: Array<ProviderMigration>;
};

export enum PublicEndpoint {
  PublicEndpointAddCustomIdentifier = 'PUBLIC_ENDPOINT_ADD_CUSTOM_IDENTIFIER',
  PublicEndpointAddPrivacyDeleteRequest = 'PUBLIC_ENDPOINT_ADD_PRIVACY_DELETE_REQUEST',
  PublicEndpointAddToCart = 'PUBLIC_ENDPOINT_ADD_TO_CART',
  PublicEndpointAutogenerateCoupons = 'PUBLIC_ENDPOINT_AUTOGENERATE_COUPONS',
  PublicEndpointBulkSendText = 'PUBLIC_ENDPOINT_BULK_SEND_TEXT',
  PublicEndpointCreateWebhook = 'PUBLIC_ENDPOINT_CREATE_WEBHOOK',
  PublicEndpointCustomAttributes = 'PUBLIC_ENDPOINT_CUSTOM_ATTRIBUTES',
  PublicEndpointCustomEvents = 'PUBLIC_ENDPOINT_CUSTOM_EVENTS',
  PublicEndpointDeleteWebhook = 'PUBLIC_ENDPOINT_DELETE_WEBHOOK',
  PublicEndpointGetSubscriberEligibility = 'PUBLIC_ENDPOINT_GET_SUBSCRIBER_ELIGIBILITY',
  PublicEndpointListWebhooks = 'PUBLIC_ENDPOINT_LIST_WEBHOOKS',
  PublicEndpointMe = 'PUBLIC_ENDPOINT_ME',
  PublicEndpointProductView = 'PUBLIC_ENDPOINT_PRODUCT_VIEW',
  PublicEndpointPurchase = 'PUBLIC_ENDPOINT_PURCHASE',
  PublicEndpointSendCampaignText = 'PUBLIC_ENDPOINT_SEND_CAMPAIGN_TEXT',
  PublicEndpointSendText = 'PUBLIC_ENDPOINT_SEND_TEXT',
  PublicEndpointSubscribeUser = 'PUBLIC_ENDPOINT_SUBSCRIBE_USER',
  PublicEndpointUnknown = 'PUBLIC_ENDPOINT_UNKNOWN',
  PublicEndpointUnsubscribeUser = 'PUBLIC_ENDPOINT_UNSUBSCRIBE_USER',
  PublicEndpointUpdateWebhook = 'PUBLIC_ENDPOINT_UPDATE_WEBHOOK',
  PublicEndpointUploadProductCatalog = 'PUBLIC_ENDPOINT_UPLOAD_PRODUCT_CATALOG',
  PublicEndpointUpsertMessageText = 'PUBLIC_ENDPOINT_UPSERT_MESSAGE_TEXT',
  PublicEndpointViewProductCatalogIngestion = 'PUBLIC_ENDPOINT_VIEW_PRODUCT_CATALOG_INGESTION',
  PublicEndpointViewRecentCatalogUploads = 'PUBLIC_ENDPOINT_VIEW_RECENT_CATALOG_UPLOADS'
}

export type PublishJourneyRevisionInput = {
  companyId: Scalars['ID']['input'];
  doEvictSubscribers: Scalars['Boolean']['input'];
  journeyRevisionId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/journeys/api/JourneyRevision.proto */
export type PublishJourneyRevisionPayload = {
  __typename?: 'PublishJourneyRevisionPayload';
  journeyRevision: JourneyRevision;
};

export type PublishMessageValidationError = EmptyEmailSubject | EmptyMessageBody | MissingEmailMetadata;

/** Generated from com/attentivemobile/contracts/event/platform/registration/service/DatastreamModel.proto */
export type PulsarTopic = {
  __typename?: 'PulsarTopic';
  uri: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type PurchaseEvent = {
  __typename?: 'PurchaseEvent';
  companyProduct: Maybe<Product>;
  companyProductId: Scalars['ID']['output'];
  /** @deprecated deprecated */
  created: Scalars['DateTime']['output'];
  /** @deprecated deprecated */
  id: Scalars['String']['output'];
  /** @deprecated deprecated */
  productName: Scalars['String']['output'];
  /** @deprecated deprecated */
  productUrl: Scalars['String']['output'];
};

export type PurchaseEventFilter = {
  hasVerb: HasVerb;
  timeCondition: TimeCondition;
};

export type Query = {
  __typename?: 'Query';
  aIGeneratedText: Maybe<AIGeneratedText>;
  accounts: Maybe<AccountConnection>;
  accountsByEmail: Maybe<AccountConnection>;
  activeDismissibleModal: Maybe<DismissibleModal>;
  activeNotificationBanner: Maybe<GetActiveNotificationBannerResponse>;
  adminNotificationConfigurations: Maybe<ConfigurationNotificationConnection>;
  agentTemplateRequests: Maybe<TemplateRequestConnection>;
  aiImageGenerationOptions: Maybe<AIImageGenerationOptionsPayload>;
  aiProTrialEligibilityStatus: Maybe<GetAiProTrialEligibilityStatusPayload>;
  allEdsSyncs: Maybe<Syncs>;
  apiRateLimits: Maybe<CompanyApplicationRouteRateLimitsPayload>;
  applicationWebhookEvents: Maybe<WebhookEventConnection>;
  authenticators: Maybe<GetAuthenticatorsPayload>;
  automationPrograms: Maybe<AutomationProgramConnection>;
  circuitBreakerRateLimits: Maybe<CircuitBreakerRateLimitsResponse>;
  companies: Maybe<CompanyConnection>;
  /** @deprecated deprecated */
  companiesByInternalId: Maybe<GetCompaniesByInternalIdResponse>;
  companyByAttentiveDomain: Maybe<Company>;
  companyGlobalRateLimits: Maybe<CompanyGlobalRateLimitsPayload>;
  composeCampaigns: Maybe<ComposeCampaignsConnection>;
  concierge: Maybe<Concierge>;
  conciergeCompanyTemplates: Maybe<CompanyTemplateConnection>;
  contentSetting: Maybe<FindSpecificUserContentSettingConnection>;
  contentSettings: Maybe<FindUserContentSettingsConnection>;
  contentVisibility: Maybe<FindUserContentVisibilityResponse>;
  copyAssistantTemplate: Maybe<GetCopyAssistantTemplateResponse>;
  copyAssistantTemplates: Maybe<ListCopyAssistantTemplatesPayload>;
  countries: Maybe<Array<Country>>;
  country: Maybe<Country>;
  countrySubdivision: Maybe<CountrySubdivision>;
  countrySubdivisions: Maybe<Array<CountrySubdivision>>;
  creativeByInternalID: Maybe<Creative>;
  creativeFormats: Maybe<CreativeFormatConnection>;
  creativeTemplateByInternalId: Maybe<CreativeTemplate>;
  customCompanyKeywords: Maybe<CustomCompanyKeywordsConnection>;
  datastreamBatchMigrationStatus: Maybe<GetBatchMigrationStatusResponse>;
  datastreamDetails: Maybe<GetDatastreamDetailsResponse>;
  datastreamTeams: Maybe<ListTeamsResponse>;
  datastreams: Maybe<ListDatastreams>;
  deleteRequests: Maybe<DeleteRequestConnection>;
  dismissibleModals: Maybe<DismissibleModalConnection>;
  domainSuppressions: Maybe<DomainSuppressionConnection>;
  edsJobSchedule: Maybe<GetScheduleResponse>;
  edsLatestRunReports: Maybe<GetRunReportsResponse>;
  edsRunReportsByTopic: Maybe<GetRunReportsResponse>;
  edsTaskProcessingConfig: Maybe<EdsTaskProcessingConfig>;
  emailAccounts: Maybe<EmailAccountConnection>;
  emailIpAddresses: Maybe<EmailIPAddressesConnection>;
  emailIpPools: Maybe<EmailIPPoolsConnection>;
  emailManagementCsvExport: Maybe<EmailManagementCsvResponse>;
  enabledFlags: Maybe<FlagEnabledConnection>;
  escalatedConciergeConversations: Maybe<EscalationConnection>;
  evaluateMessagePreviewRequest: Maybe<EvaluateMessagePreviewPayload>;
  eventDestinationJobs: Maybe<JobRecordConnection>;
  eventDestinationTasks: Maybe<EventDestinationTasksPayload>;
  eventDestinations: Maybe<EventDestinations>;
  featureFlag: Maybe<FeatureFlag>;
  featureFlags: Maybe<FeatureFlagConnection>;
  /** @deprecated deprecated */
  getCampaignStats: Maybe<CampaignsStats>;
  getCouponAllocationJobs: Maybe<AllocationJobsPayload>;
  getEmailBulkOptOutJobs: Maybe<GetEmailBulkOptOutJobsResponse>;
  getEstimatedRecipientCount: Maybe<EstimatedRecipientCount>;
  getRemainingRecipients: Maybe<RemainingRecipientsCount>;
  giveawayWinners: Maybe<GetGiveawayWinnersPayload>;
  globalMessageTemplates: Maybe<MessageTemplateConnection>;
  imageGenerationOptions: Maybe<ImageGenerationOptionsPayload>;
  keywordRuleByKeywordId: Maybe<KeywordRule>;
  keywordRulesInfoByKeywordId: Maybe<KeywordRulesInfoPayload>;
  marketplaceApplications: Maybe<ApplicationConnection>;
  messageFeedback: Maybe<MessageFeedbackConnection>;
  messages: Maybe<MessageConnection>;
  messagesCsv: Maybe<MessagesCsvResponse>;
  node: Maybe<Node>;
  nodes: Array<Maybe<Node>>;
  notificationBanners: Maybe<NotificationBannerConnection>;
  notifications: Maybe<NotificationConnection>;
  phoneNumbers: Maybe<PhoneNumbersConnection>;
  postalCodes: Maybe<PostalCodeConnection>;
  prebuiltContent: Maybe<PrebuiltContentConnection>;
  prebuiltPrograms: Maybe<PrebuiltProgramConnection>;
  programOrchestrations: Maybe<OrchestrationConnection>;
  providerMigrations: Maybe<ProviderMigrationsResponse>;
  rateLimit: Maybe<RateLimit>;
  roles: Maybe<GetRolesResponse>;
  routeRateLimits: Maybe<RouteRateLimitsPayload>;
  searchProviderMigrations: Maybe<ProviderMigrationConnection>;
  segmentEnhancementsWorkflowResult: Maybe<SegmentEnhancementsWorkflowResultPayload>;
  shopifyAutoApplyFormat: Maybe<GetShopifyAutoApplyFormatsPayload>;
  site: Site;
  supportedCustomPropertyDataTypes: Maybe<ListSupportedCustomPropertyDataTypesResponse>;
  targetingData: Maybe<TargetingConnection>;
  targetingDatum: Maybe<TargetingDatum>;
  user: Maybe<User>;
  users: Maybe<UsersConnection>;
  validatePostalCodes: Maybe<ValidatePostalCodesResponse>;
  validateTemplateField: Maybe<ValidateTemplateFieldResponse>;
  verifyAIJourneyWelcomeSent: Maybe<VerifyAIJourneyWelcomeSentPayload>;
  viewer: Maybe<Viewer>;
  workloadConfig: Maybe<WorkloadConfig>;
  workloadConfigs: Maybe<Array<WorkloadConfig>>;
  workloadHistory: Maybe<Array<WorkloadRun>>;
  workloadStates: Maybe<Array<WorkloadJobState>>;
  zendeskHelpCenterArticles: Maybe<ZendeskHelpCenterArticleConnection>;
};


export type QueryaIGeneratedTextArgs = {
  id: Scalars['ID']['input'];
};


export type QueryaccountsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  companyId: InputMaybe<Scalars['ID']['input']>;
  filter: AccountFilter;
  first: InputMaybe<Scalars['Int']['input']>;
};


export type QueryaccountsByEmailArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  emails: InputMaybe<Array<Scalars['String']['input']>>;
  filter: AccountFilter;
  first: InputMaybe<Scalars['Int']['input']>;
};


export type QueryactiveDismissibleModalArgs = {
  companyId: InputMaybe<Scalars['ID']['input']>;
  url: Scalars['String']['input'];
  userId: InputMaybe<Scalars['ID']['input']>;
};


export type QueryadminNotificationConfigurationsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
};


export type QueryagentTemplateRequestsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  companyId: InputMaybe<Scalars['ID']['input']>;
  filter: InputMaybe<TemplateRequestFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  requesterId: InputMaybe<Scalars['ID']['input']>;
  reviewerId: InputMaybe<Scalars['ID']['input']>;
  searchTerm: InputMaybe<Scalars['String']['input']>;
};


export type QueryaiProTrialEligibilityStatusArgs = {
  companyId: Scalars['ID']['input'];
};


export type QueryallEdsSyncsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['ID']['input'];
  filter: ListSyncsFilter;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


export type QueryapplicationWebhookEventsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


export type QueryauthenticatorsArgs = {
  accountId: Scalars['ID']['input'];
};


export type QueryautomationProgramsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['ID']['input'];
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


export type QuerycompaniesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<CompanySearchFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
};


export type QuerycompaniesByInternalIdArgs = {
  internalIds: InputMaybe<Array<Scalars['Long']['input']>>;
};


export type QuerycompanyByAttentiveDomainArgs = {
  attentiveDomain: Scalars['String']['input'];
};


export type QuerycomposeCampaignsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  companyId: InputMaybe<Scalars['ID']['input']>;
  filter: InputMaybe<ComposeCampaignsFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


export type QueryconciergeCompanyTemplatesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  companyId: InputMaybe<Scalars['ID']['input']>;
  filter: InputMaybe<TemplateFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  searchByShortcut: InputMaybe<Scalars['Boolean']['input']>;
  searchByText: InputMaybe<Scalars['Boolean']['input']>;
  searchByTitle: InputMaybe<Scalars['Boolean']['input']>;
  searchTerm: InputMaybe<Scalars['String']['input']>;
};


export type QuerycontentSettingArgs = {
  companyId: InputMaybe<Scalars['ID']['input']>;
  objectClass: ContentClass;
  objectId: InputMaybe<Scalars['String']['input']>;
  preferenceType: PreferenceType;
  userId: InputMaybe<Scalars['ID']['input']>;
};


export type QuerycontentSettingsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  companyId: InputMaybe<Scalars['ID']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  userId: InputMaybe<Scalars['ID']['input']>;
};


export type QuerycontentVisibilityArgs = {
  companyId: Scalars['ID']['input'];
  objectClass: ContentClass;
  objectId: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};


export type QuerycopyAssistantTemplateArgs = {
  templateName: Scalars['String']['input'];
};


export type QuerycountryArgs = {
  id: Scalars['ID']['input'];
};


export type QuerycountrySubdivisionArgs = {
  id: Scalars['ID']['input'];
};


export type QuerycountrySubdivisionsArgs = {
  countryId: InputMaybe<Scalars['ID']['input']>;
};


export type QuerycreativeByInternalIDArgs = {
  internalId: Scalars['String']['input'];
};


export type QuerycreativeFormatsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


export type QuerycreativeTemplateByInternalIdArgs = {
  internalId: Scalars['String']['input'];
};


export type QuerycustomCompanyKeywordsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['ID']['input'];
  filter: GetCustomCompanyKeywordsFilter;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  sort: GetCustomCompanyKeywordsSort;
};


export type QuerydatastreamBatchMigrationStatusArgs = {
  workflowId: Scalars['String']['input'];
};


export type QuerydatastreamDetailsArgs = {
  datastreamName: Scalars['String']['input'];
};


export type QuerydatastreamsArgs = {
  cluster: InputMaybe<Cluster>;
  team: InputMaybe<Scalars['String']['input']>;
};


export type QuerydeleteRequestsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<PrivacyRequestSearchFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  sorting: InputMaybe<PrivacyRequestSorting>;
};


export type QuerydismissibleModalsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<DismissibleModalFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
};


export type QuerydomainSuppressionsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


export type QueryedsJobScheduleArgs = {
  taskId: Scalars['String']['input'];
};


export type QueryedsLatestRunReportsArgs = {
  intervalDays: InputMaybe<Scalars['Int']['input']>;
  lastXRuns: InputMaybe<Scalars['Int']['input']>;
  taskUuid: Scalars['String']['input'];
  topicType: Scalars['String']['input'];
};


export type QueryedsRunReportsByTopicArgs = {
  end: Scalars['String']['input'];
  runUuids: InputMaybe<Array<Scalars['String']['input']>>;
  start: Scalars['String']['input'];
  taskId: Scalars['String']['input'];
  type: Scalars['String']['input'];
};


export type QueryedsTaskProcessingConfigArgs = {
  endpoint: Scalars['String']['input'];
  taskId: InputMaybe<Scalars['String']['input']>;
};


export type QueryemailAccountsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filter: EmailAccountFilterInput;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  sort: EmailAccountSort;
};


export type QueryemailIpAddressesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  available: InputMaybe<Scalars['Boolean']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  companyId: InputMaybe<Scalars['ID']['input']>;
  domains: InputMaybe<Array<Scalars['String']['input']>>;
  emailAccountId: InputMaybe<Scalars['ID']['input']>;
  filter: InputMaybe<EmailIPAddressFilterInput>;
  first: InputMaybe<Scalars['Int']['input']>;
  ipAddress: InputMaybe<Scalars['String']['input']>;
  ipPoolId: InputMaybe<Scalars['ID']['input']>;
  ipPoolNames: InputMaybe<Array<Scalars['String']['input']>>;
  last: InputMaybe<Scalars['Int']['input']>;
  verified: InputMaybe<Scalars['Boolean']['input']>;
  warmupStatus: InputMaybe<EmailIPAddressWarmupStatus>;
};


export type QueryemailIpPoolsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  companyId: InputMaybe<Scalars['ID']['input']>;
  filter: InputMaybe<EmailIPPoolFilterInput>;
  first: InputMaybe<Scalars['Int']['input']>;
  ipAddress: InputMaybe<Scalars['String']['input']>;
  ipPoolId: InputMaybe<Scalars['ID']['input']>;
  ipPoolName: InputMaybe<Scalars['String']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  poolType: InputMaybe<IPPoolType>;
  shared: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryemailManagementCsvExportArgs = {
  sortBy: InputMaybe<CsvSortByField>;
  sortDirection: InputMaybe<SortDirection>;
};


export type QueryenabledFlagsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  contexts: InputMaybe<Array<ContextEvaluationItem>>;
  featureFlagNames: InputMaybe<Array<Scalars['String']['input']>>;
  first: InputMaybe<Scalars['Int']['input']>;
};


export type QueryescalatedConciergeConversationsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<EscalationsFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  sort: InputMaybe<EscalationsSort>;
};


export type QueryevaluateMessagePreviewRequestArgs = {
  attachments: InputMaybe<Array<UpdateAttachmentRequest>>;
  emailPreviewRequest: InputMaybe<EmailPreviewInput>;
  messageId: InputMaybe<Scalars['ID']['input']>;
  messageName: InputMaybe<Scalars['String']['input']>;
  messageType: InputMaybe<ComposeMessageType>;
  productId: InputMaybe<Scalars['ID']['input']>;
  textPreviewRequest: InputMaybe<TextPreviewInput>;
  userCompositeId: InputMaybe<Scalars['ID']['input']>;
  utmParameters: InputMaybe<UtmParametersInput>;
};


export type QueryeventDestinationJobsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<GetJobRecordsFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  nodeUuid: InputMaybe<Scalars['String']['input']>;
};


export type QueryeventDestinationTasksArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<EventDestinationTasksFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  withTaskHistory: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryfeatureFlagArgs = {
  id: Scalars['ID']['input'];
};


export type QueryfeatureFlagsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  companyId: InputMaybe<Scalars['ID']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  flagNames: InputMaybe<Array<Scalars['String']['input']>>;
  search: InputMaybe<Scalars['String']['input']>;
};


export type QuerygetCampaignStatsArgs = {
  campaignIds: InputMaybe<Array<Scalars['ID']['input']>>;
  company: Scalars['ID']['input'];
};


export type QuerygetCouponAllocationJobsArgs = {
  dateRange: InputMaybe<AllocationDateRangeInput>;
  endTime: InputMaybe<Scalars['String']['input']>;
  startTime: InputMaybe<Scalars['String']['input']>;
};


export type QuerygetEmailBulkOptOutJobsArgs = {
  dateRange: InputMaybe<SolutionsDateRangeInput>;
};


export type QuerygetEstimatedRecipientCountArgs = {
  channel: InputMaybe<ComposeMessageChannel>;
  company: Scalars['ID']['input'];
  isSmartSendingEnabled: InputMaybe<Scalars['Boolean']['input']>;
  messageId: InputMaybe<Scalars['ID']['input']>;
  segmentIdsExcluded: InputMaybe<Array<Scalars['ID']['input']>>;
  segmentIdsIncluded: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QuerygetRemainingRecipientsArgs = {
  company: Scalars['ID']['input'];
  messageId: Scalars['ID']['input'];
};


export type QuerygiveawayWinnersArgs = {
  creativeId: InputMaybe<Scalars['ID']['input']>;
  creativeIds: InputMaybe<Array<Scalars['ID']['input']>>;
  endDate: Scalars['String']['input'];
  limit: Scalars['Int']['input'];
  startDate: Scalars['String']['input'];
};


export type QueryglobalMessageTemplatesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  channel: Channel;
  filter: InputMaybe<GlobalMessageTemplatesFilterInput>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


export type QuerykeywordRuleByKeywordIdArgs = {
  keywordId: Scalars['ID']['input'];
};


export type QuerykeywordRulesInfoByKeywordIdArgs = {
  companyId: Scalars['ID']['input'];
  keywordId: Scalars['ID']['input'];
};


export type QuerymarketplaceApplicationsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


export type QuerymessageFeedbackArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<AgentMessageFeedbackFilterInput>;
  first: InputMaybe<Scalars['Int']['input']>;
  handled: Scalars['Boolean']['input'];
  recipient: InputMaybe<Scalars['ID']['input']>;
  searchTerm: InputMaybe<Scalars['String']['input']>;
  sort: InputMaybe<MessageFeedbackSortInput>;
};


export type QuerymessagesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  filter: FindMessagesFilter;
  first: InputMaybe<Scalars['Int']['input']>;
  sortBy: InputMaybe<MessageSortField>;
  sortDirection: InputMaybe<SortDirection>;
};


export type QuerymessagesCsvArgs = {
  filter: FindMessagesFilter;
  sortBy: InputMaybe<MessageSortField>;
  sortDirection: InputMaybe<SortDirection>;
};


export type QuerynodeArgs = {
  id: Scalars['ID']['input'];
};


export type QuerynodesArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type QuerynotificationBannersArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
};


export type QuerynotificationsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['ID']['input'];
  first: InputMaybe<Scalars['Int']['input']>;
  notificationType: InputMaybe<NotificationType>;
};


export type QueryphoneNumbersArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filters: ListPhoneNumbersFilterInput;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


export type QuerypostalCodesArgs = {
  adjacent: InputMaybe<ListAdjacentPostalCodesInput>;
  after: InputMaybe<Scalars['String']['input']>;
  countryIsoCode: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
};


export type QueryprebuiltContentArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  prebuiltProgramId: Scalars['ID']['input'];
};


export type QueryprebuiltProgramsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


export type QueryprogramOrchestrationsArgs = {
  companyId: Scalars['ID']['input'];
  prebuiltContentId: Scalars['ID']['input'];
};


export type QueryproviderMigrationsArgs = {
  carrier: InputMaybe<Carrier>;
  phoneNumber: Scalars['String']['input'];
};


export type QueryrateLimitArgs = {
  aggregator: InputMaybe<Aggregator>;
  country: InputMaybe<RateLimitCountry>;
  phoneNumberType: InputMaybe<PhoneNumberType>;
  provider: Provider;
  textChannel: TextChannel;
};


export type QueryrolesArgs = {
  companyId: Scalars['ID']['input'];
};


export type QuerysearchProviderMigrationsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  carrier: InputMaybe<Carrier>;
  endDate: InputMaybe<Scalars['DateTime']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  newProvider: InputMaybe<Provider>;
  oldProvider: InputMaybe<Provider>;
  phoneNumber: InputMaybe<Array<Scalars['String']['input']>>;
  startDate: InputMaybe<Scalars['DateTime']['input']>;
  status: InputMaybe<ProviderMigrationStatus>;
};


export type QuerysegmentEnhancementsWorkflowResultArgs = {
  callbackId: InputMaybe<SegmentEnhancementsWorkflowResultInputCallbackId>;
  companyId: Scalars['ID']['input'];
  workflowId: Scalars['String']['input'];
};


export type QueryshopifyAutoApplyFormatArgs = {
  companyId: Scalars['ID']['input'];
};


export type QuerytargetingDataArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  companyId: InputMaybe<Scalars['ID']['input']>;
  contextKey: InputMaybe<Scalars['String']['input']>;
  contextValue: InputMaybe<Scalars['String']['input']>;
  featureFlagId: Scalars['ID']['input'];
  first: InputMaybe<Scalars['Int']['input']>;
};


export type QuerytargetingDatumArgs = {
  contextKey: Scalars['String']['input'];
  contextValue: Scalars['String']['input'];
  featureFlagId: Scalars['ID']['input'];
};


export type QueryuserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryusersArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  companyId: InputMaybe<Scalars['ID']['input']>;
  email: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<ListUsersFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  phoneNumber: InputMaybe<Scalars['String']['input']>;
};


export type QueryvalidatePostalCodesArgs = {
  countryId: InputMaybe<Scalars['String']['input']>;
  postalCodeInput: InputMaybe<PostalCodeInputList>;
};


export type QueryvalidateTemplateFieldArgs = {
  companyId: InputMaybe<Scalars['ID']['input']>;
  content: Scalars['String']['input'];
  validationType: TemplateFieldValidationType;
};


export type QueryverifyAIJourneyWelcomeSentArgs = {
  lookbackDurationMinutes: Scalars['Int']['input'];
  userCompanyId: Scalars['ID']['input'];
};


export type QueryworkloadConfigArgs = {
  workloadName: Scalars['String']['input'];
};


export type QueryworkloadConfigsArgs = {
  tags: InputMaybe<Array<Scalars['String']['input']>>;
  teamName: InputMaybe<Scalars['String']['input']>;
};


export type QueryworkloadHistoryArgs = {
  workloadName: Scalars['String']['input'];
};


export type QueryworkloadStatesArgs = {
  workloadName: Scalars['String']['input'];
};


export type QueryzendeskHelpCenterArticlesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  searchTerm: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/company-service/region/Model.proto */
export type QuietHours = {
  __typename?: 'QuietHours';
  /** A time of day (hrs, mins, secs, nanos), representing the end of regional, daily quiet hours. */
  end: TimeOfDay;
  /** A time of day (hrs, mins, secs, nanos), representing the beginning of regional, daily quiet hours. */
  start: TimeOfDay;
  /** The timezone for the region and its associated quiet hours. */
  timezone: Scalars['String']['output'];
};

export type QuietHoursInput = {
  /** A time of day (hrs, mins, secs, nanos), representing the end of regional, daily quiet hours. */
  end: InputMaybe<TimeOfDayInput>;
  /** A time of day (hrs, mins, secs, nanos), representing the beginning of regional, daily quiet hours. */
  start: InputMaybe<TimeOfDayInput>;
  /** The timezone for the region and its associated quiet hours. */
  timezone: InputMaybe<Scalars['String']['input']>;
};

/** Generated from com/attentivemobile/contracts/ratelimiting/Contract.proto */
export type RateLimit = Node & {
  __typename?: 'RateLimit';
  aggregator: Maybe<Aggregator>;
  channel: TextChannel;
  country: RateLimitCountry;
  id: Scalars['ID']['output'];
  limit: Scalars['Long']['output'];
  phoneNumberType: Maybe<PhoneNumberType>;
  provider: Provider;
};

export enum RateLimitCountry {
  RateLimitCountryAu = 'RATE_LIMIT_COUNTRY_AU',
  RateLimitCountryCa = 'RATE_LIMIT_COUNTRY_CA',
  RateLimitCountryUk = 'RATE_LIMIT_COUNTRY_UK',
  RateLimitCountryUnknown = 'RATE_LIMIT_COUNTRY_UNKNOWN',
  RateLimitCountryUs = 'RATE_LIMIT_COUNTRY_US'
}

/** Generated from com/attentivemobile/protobuf/common/campaigns/Campaign.proto */
export type RateLimits = {
  __typename?: 'RateLimits';
  emailRateLimit: Scalars['Float']['output'];
  mmsRateLimit: Scalars['Float']['output'];
  smsRateLimit: Scalars['Float']['output'];
};

/** Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto */
export type RbmCard = {
  __typename?: 'RbmCard';
  description: Maybe<Scalars['String']['output']>;
  media: Maybe<RbmCardMedia>;
  suggestions: Array<RbmSuggestion>;
  title: Maybe<Scalars['String']['output']>;
};

export enum RbmCardAlignment {
  RbmCardAlignmentLeft = 'RBM_CARD_ALIGNMENT_LEFT',
  RbmCardAlignmentRight = 'RBM_CARD_ALIGNMENT_RIGHT',
  RbmCardAlignmentUnknown = 'RBM_CARD_ALIGNMENT_UNKNOWN'
}

export type RbmCardInput = {
  description: InputMaybe<Scalars['String']['input']>;
  media: InputMaybe<RbmCardMediaInput>;
  suggestions: InputMaybe<Array<RbmSuggestionInput>>;
  title: InputMaybe<Scalars['String']['input']>;
};

/** Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto */
export type RbmCardMedia = {
  __typename?: 'RbmCardMedia';
  height: RbmCardMediaHeight;
  mediaUrl: Scalars['String']['output'];
  thumbnailUrl: Maybe<Scalars['String']['output']>;
};

export enum RbmCardMediaHeight {
  RbmCardMediaHeightMedium = 'RBM_CARD_MEDIA_HEIGHT_MEDIUM',
  RbmCardMediaHeightShort = 'RBM_CARD_MEDIA_HEIGHT_SHORT',
  RbmCardMediaHeightTall = 'RBM_CARD_MEDIA_HEIGHT_TALL',
  RbmCardMediaHeightUnknown = 'RBM_CARD_MEDIA_HEIGHT_UNKNOWN'
}

export type RbmCardMediaInput = {
  height: RbmCardMediaHeight;
  mediaUrl: Scalars['String']['input'];
  thumbnailUrl: InputMaybe<Scalars['String']['input']>;
};

export enum RbmCardOrientation {
  RbmCardOrientationHorizontal = 'RBM_CARD_ORIENTATION_HORIZONTAL',
  RbmCardOrientationUnknown = 'RBM_CARD_ORIENTATION_UNKNOWN',
  RbmCardOrientationVertical = 'RBM_CARD_ORIENTATION_VERTICAL'
}

/** Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto */
export type RbmCardProperties = {
  __typename?: 'RbmCardProperties';
  alignment: RbmCardAlignment;
  orientation: RbmCardOrientation;
  rbmCard: RbmCard;
};

export type RbmCardPropertiesInput = {
  alignment: RbmCardAlignment;
  orientation: RbmCardOrientation;
  rbmCard: RbmCardInput;
};

export enum RbmCardWidth {
  RbmCardWidthMedium = 'RBM_CARD_WIDTH_MEDIUM',
  RbmCardWidthSmall = 'RBM_CARD_WIDTH_SMALL',
  RbmCardWidthUnknown = 'RBM_CARD_WIDTH_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto */
export type RbmCarouselProperties = {
  __typename?: 'RbmCarouselProperties';
  cards: Array<RbmCard>;
  width: RbmCardWidth;
};

export type RbmCarouselPropertiesInput = {
  cards: InputMaybe<Array<RbmCardInput>>;
  width: RbmCardWidth;
};

/** Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto */
export type RbmChannelProperties = {
  __typename?: 'RbmChannelProperties';
  fallback: Maybe<RbmFallback>;
  macroStatsLinkId: Scalars['Long']['output'];
  rbmMessageParts: Array<RbmMessagePart>;
  shortLinks: Array<ShortLink>;
  suggestions: Array<RbmSuggestion>;
};

/** Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto */
export type RbmFallback = {
  __typename?: 'RbmFallback';
  mediaUrl: Maybe<Scalars['String']['output']>;
  template: Scalars['String']['output'];
};

export type RbmFallbackRequest = {
  mediaUrl: InputMaybe<Scalars['String']['input']>;
  template: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto */
export type RbmMediaProperties = {
  __typename?: 'RbmMediaProperties';
  mediaUrl: Scalars['String']['output'];
  thumbnailUrl: Maybe<Scalars['String']['output']>;
};

export type RbmMediaPropertiesInput = {
  fileUrl: Scalars['String']['input'];
  thumbnailUrl: InputMaybe<Scalars['String']['input']>;
};

export type RbmMessagePart = RbmCardProperties | RbmCarouselProperties | RbmMediaProperties | RbmTextProperties;

export type RbmMessagePartInput = {
  properties: InputMaybe<RbmMessagePartInputProperties>;
};

export type RbmMessagePartInputProperties =
  { cardProperties: RbmCardPropertiesInput; carouselProperties?: never; mediaProperties?: never; textProperties?: never; }
  |  { cardProperties?: never; carouselProperties: RbmCarouselPropertiesInput; mediaProperties?: never; textProperties?: never; }
  |  { cardProperties?: never; carouselProperties?: never; mediaProperties: RbmMediaPropertiesInput; textProperties?: never; }
  |  { cardProperties?: never; carouselProperties?: never; mediaProperties?: never; textProperties: RbmTextPropertiesInput; };

/** Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto */
export type RbmOpenLinkSuggestion = {
  __typename?: 'RbmOpenLinkSuggestion';
  template: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type RbmOpenLinkSuggestionRequest = {
  template: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type RbmPropertiesInput = {
  fallback: InputMaybe<RbmFallbackRequest>;
  rbmMessageParts: InputMaybe<Array<RbmMessagePartInput>>;
  shortLinkProperties: InputMaybe<Array<ShortLinkProperties>>;
  suggestions: InputMaybe<Array<RbmSuggestionInput>>;
};

/** Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto */
export type RbmReplySuggestion = {
  __typename?: 'RbmReplySuggestion';
  template: Scalars['String']['output'];
};

export type RbmReplySuggestionRequest = {
  template: Scalars['String']['input'];
};

export type RbmSuggestion = RbmOpenLinkSuggestion | RbmReplySuggestion;

export type RbmSuggestionInput = {
  suggestion: InputMaybe<RbmSuggestionInputSuggestion>;
};

export type RbmSuggestionInputSuggestion =
  { openLinkSuggestion: RbmOpenLinkSuggestionRequest; replySuggestion?: never; }
  |  { openLinkSuggestion?: never; replySuggestion: RbmReplySuggestionRequest; };

/** Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto */
export type RbmTextProperties = {
  __typename?: 'RbmTextProperties';
  template: Scalars['String']['output'];
};

export type RbmTextPropertiesInput = {
  template: Scalars['String']['input'];
};

export type ReadNotificationInput = {
  companyId: Scalars['ID']['input'];
  notificationInstanceId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type ReadNotificationPayload = {
  __typename?: 'ReadNotificationPayload';
  notification: Notification;
};

export type RecipientInput =
  { email: Scalars['String']['input']; phone?: never; }
  |  { email?: never; phone: Scalars['String']['input']; };

/** Generated from com/attentivemobile/contracts/composition/MessageAttachment.proto */
export type RecommendationAttachment = {
  __typename?: 'RecommendationAttachment';
  entityToken: Scalars['String']['output'];
  productSegment: Maybe<ProductSegment>;
  recommendationType: RecommendationAttachmentType;
};

export type RecommendationAttachmentProperties = {
  entityToken: Scalars['String']['input'];
  productSegmentId: InputMaybe<Scalars['ID']['input']>;
};

export enum RecommendationAttachmentType {
  RecommendationAttachmentTypeCompanyBestseller = 'RECOMMENDATION_ATTACHMENT_TYPE_COMPANY_BESTSELLER',
  RecommendationAttachmentTypePersonalized = 'RECOMMENDATION_ATTACHMENT_TYPE_PERSONALIZED',
  RecommendationAttachmentTypeUnknown = 'RECOMMENDATION_ATTACHMENT_TYPE_UNKNOWN'
}

export type RecommendedTemplateInput = {
  rank: Scalars['Long']['input'];
  templateId: Scalars['String']['input'];
};

export type RecommendedTemplateListInput = {
  recommendedTemplates: InputMaybe<Array<RecommendedTemplateInput>>;
};

export type RecoverDatastreamInput = {
  datastreamName: Scalars['String']['input'];
  recoveryPolicy: InputMaybe<RecoverDatastreamInputRecoveryPolicy>;
  runtime: InputMaybe<RecoverDatastreamInputRuntime>;
};

export type RecoverDatastreamInputRecoveryPolicy =
  { startWithState: StateRecoveryPolicy; startWithoutState?: never; }
  |  { startWithState?: never; startWithoutState: Scalars['Boolean']['input']; };

export type RecoverDatastreamInputRuntime =
  { newFlinkJob: FlinkJobWrite; };

/** Generated from com/attentivemobile/contracts/event/platform/registration/service/Contract.proto */
export type RecoverDatastreamPayload = {
  __typename?: 'RecoverDatastreamPayload';
  newRuntime: Maybe<RecoverDatastreamPayloadNewRuntime>;
  oldRuntime: Maybe<RecoverDatastreamPayloadOldRuntime>;
  restorePath: Scalars['String']['output'];
  result: OperationResult;
};

export type RecoverDatastreamPayloadNewRuntime = MigrateFlinkJobResponse;

export type RecoverDatastreamPayloadOldRuntime = MigrateFlinkJobResponse;

export enum RedeemCouponError {
  RedeemCouponErrorAlreadyRedeemed = 'REDEEM_COUPON_ERROR_ALREADY_REDEEMED',
  RedeemCouponErrorApi = 'REDEEM_COUPON_ERROR_API',
  RedeemCouponErrorExpired = 'REDEEM_COUPON_ERROR_EXPIRED',
  RedeemCouponErrorInvalid = 'REDEEM_COUPON_ERROR_INVALID',
  RedeemCouponErrorUnknown = 'REDEEM_COUPON_ERROR_UNKNOWN'
}

export type RedeemGapStoresCouponInput = {
  campaignId: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['ID']['input'];
  couponCode: Scalars['String']['input'];
  encodedSubscriberId: Scalars['String']['input'];
  offerId: InputMaybe<Scalars['String']['input']>;
  region: InputMaybe<Scalars['String']['input']>;
};

/** Generated from com/attentivemobile/contracts/mobilewalletservice/Contract.proto */
export type RedeemGapStoresCouponPayload = {
  __typename?: 'RedeemGapStoresCouponPayload';
  errorCode: Maybe<RedeemCouponError>;
  mobileWalletUrl: Scalars['String']['output'];
};

export type RefreshItemListPagesInput = {
  companyId: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type RefreshItemListPagesPayload = DefaultErrorFailure | RefreshItemListPagesSuccess;

/** Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto */
export type RefreshItemListPagesSuccess = {
  __typename?: 'RefreshItemListPagesSuccess';
  isSuccessful: Maybe<Scalars['Boolean']['output']>;
};

export type RefreshProgramSendTimesInput = {
  prebuiltContentId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/messaging/automation/admin/Contract.proto */
export type RefreshProgramSendTimesPayload = {
  __typename?: 'RefreshProgramSendTimesPayload';
  prebuiltContent: PrebuiltContent;
};

export type RefreshSessionOrChallengeInput = {
  id: InputMaybe<RefreshSessionOrChallengeInputId>;
};

export type RefreshSessionOrChallengeInputId =
  { companyId: Scalars['ID']['input']; externalCompanyId?: never; }
  |  { companyId?: never; externalCompanyId: Scalars['Long']['input']; };

export type RefreshSessionOrChallengePayload = DefaultErrorFailure | RefreshSessionOrChallengeSuccess;

export type RefreshSessionOrChallengeResponseResponse = ChallengeResponse | RefreshSessionResponse;

/** Generated from com/attentivemobile/contracts/account-service/AuthenticatorContract.proto */
export type RefreshSessionOrChallengeSuccess = {
  __typename?: 'RefreshSessionOrChallengeSuccess';
  response: Maybe<RefreshSessionOrChallengeResponseResponse>;
};

/** Generated from com/attentivemobile/contracts/account-service/AuthenticatorContract.proto */
export type RefreshSessionResponse = {
  __typename?: 'RefreshSessionResponse';
  company: Maybe<Company>;
  token: Scalars['String']['output'];
};

export type RefreshUserFileDownloadUrlInput = {
  id: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/user/profile/download/Contract.proto */
export type RefreshUserFileDownloadUrlPayload = {
  __typename?: 'RefreshUserFileDownloadUrlPayload';
  file: Maybe<UserFileDownload>;
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type RefundFailedEvent = {
  __typename?: 'RefundFailedEvent';
  company: Maybe<Company>;
  order: OrderInfo;
  refund: RefundInfo;
};

/**
 * Generated from com/attentivemobile/contracts/event/ecommerce/Refund.proto
 * Represents a refund applied to some line items to an order
 */
export type RefundInfo = {
  __typename?: 'RefundInfo';
  /** The total of all refund transactional amounts toward the customer in this refund (i.e. shipping costs, reimbursed duties, etc) */
  amount: Money;
  /** A unique identifier of the refund. */
  externalRefundId: Scalars['String']['output'];
  /** The time at which the refund was created */
  processedAt: Scalars['DateTime']['output'];
  /** Information about what quantities of what items are being refunded */
  refundLineItems: Array<RefundLineItem>;
};

/**
 * Generated from com/attentivemobile/contracts/event/ecommerce/Refund.proto
 * Represents a refund applied to a line item in an order
 */
export type RefundLineItem = {
  __typename?: 'RefundLineItem';
  /** The quantity of the item refunded */
  quantityRefunded: Scalars['Int']['output'];
  /** The pertinent line item */
  relatedLineItem: OrderProduct;
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type RefundSucceededEvent = {
  __typename?: 'RefundSucceededEvent';
  company: Maybe<Company>;
  order: OrderInfo;
  refund: RefundInfo;
};

/** Generated from com/attentivemobile/contracts/company-service/region/Model.proto */
export type Region = Node & {
  __typename?: 'Region';
  address: RegionAddress;
  /** The company's attn.tv domain for this region. For example, in France, Super Duper Pooper Scoopers might have an attn.tv domain like sdps-fr.attn.tv */
  attentiveDomain: Scalars['String']['output'];
  company: Maybe<Company>;
  countryCode: Scalars['String']['output'];
  databaseId: Scalars['Long']['output'];
  /** A designation for the region, like DEFAULT. In the case of DEFAULT, one and only one Region will be so designated. */
  designation: RegionDesignation;
  /** The company's domain in this region. For example, in France, Super Duper Pooper Scoopers might have a domain like superduperpooperscoopers.example.fr. */
  domain: Scalars['String']['output'];
  fatigueRules: Array<CompanyRegionFatigueRule>;
  id: Scalars['ID']['output'];
  languageTag: Scalars['String']['output'];
  localizedSettings: Array<LocalizedSettings>;
  privacyUrl: Url;
  /** Inverse of Business Hours. The times between which no responses should go out. The start and end fields are in UTC time! */
  quietHours: QuietHours;
  /** @deprecated deprecated */
  salesforceOpportunityId: Maybe<Scalars['String']['output']>;
  termsUrl: Url;
  timezone: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/company-service/region/Model.proto */
export type RegionAddress = {
  __typename?: 'RegionAddress';
  /** street address */
  address1: Scalars['String']['output'];
  /** additional, optional street address qualifiers like Suite, Flat, Floor, Box, etc. */
  address2: Maybe<Scalars['String']['output']>;
  countryCode: Scalars['String']['output'];
  /** Locality containing the street address, like city or town. */
  locality: Scalars['String']['output'];
  postalCode: Maybe<Scalars['String']['output']>;
  /** Region containing the locality, like state or province. Some countries' postal services don't require regions in addresses. */
  region: Maybe<Scalars['String']['output']>;
};

export type RegionAddressInput = {
  /** street address */
  address1: InputMaybe<Scalars['String']['input']>;
  /** additional, optional street address qualifiers like Suite, Flat, Floor, Box, etc. */
  address2: InputMaybe<Scalars['String']['input']>;
  countryCode: InputMaybe<Scalars['String']['input']>;
  /** Locality containing the street address, like city or town. */
  locality: InputMaybe<Scalars['String']['input']>;
  postalCode: InputMaybe<Scalars['String']['input']>;
  /** Region containing the locality, like state or province. Some countries' postal services don't require regions in addresses. */
  region: InputMaybe<Scalars['String']['input']>;
};

export enum RegionDesignation {
  /** One and only one region gets the default designation. */
  RegionDesignationDefault = 'REGION_DESIGNATION_DEFAULT',
  /** We designate non-default regions "OTHER" to indicate no special relation with other regions. */
  RegionDesignationOther = 'REGION_DESIGNATION_OTHER',
  /** No known, supported designation. */
  RegionDesignationUnknown = 'REGION_DESIGNATION_UNKNOWN'
}

export type RegionInput = {
  address: InputMaybe<RegionAddressInput>;
  /** The company's attn.tv domain for this region. For example, in France, Super Duper Pooper Scoopers might have an attn.tv domain like sdps-fr.attn.tv */
  attentiveDomain: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['ID']['input'];
  countryCode: InputMaybe<Scalars['String']['input']>;
  /** A designation for the region, like DEFAULT. In the case of DEFAULT, one and only one Region will be so designated. */
  designation: InputMaybe<RegionDesignation>;
  /** The company's domain in this region. For example, in France, Super Duper Pooper Scoopers might have a domain like superduperpooperscoopers.example.fr. */
  domain: InputMaybe<Scalars['String']['input']>;
  fatigueRules: InputMaybe<Array<CompanyRegionFatigueRuleInput>>;
  id: Scalars['ID']['input'];
  languageTag: InputMaybe<Scalars['String']['input']>;
  privacyUrl: InputMaybe<UrlInput>;
  /** Inverse of Business Hours. The times between which no responses should go out. The start and end fields are in UTC time! */
  quietHours: InputMaybe<QuietHoursInput>;
  salesforceAccountId: InputMaybe<Scalars['String']['input']>;
  /** @deprecated deprecated */
  salesforceOpportunityId: InputMaybe<Scalars['String']['input']>;
  termsUrl: InputMaybe<UrlInput>;
};

/** Generated from com/attentivemobile/protobuf/common/targeting/segmentation/output/ComparisonTypes.proto */
export type RelativeTimeComparison = {
  __typename?: 'RelativeTimeComparison';
  comparator: SearchRelativeTimeComparator;
  endTime: Maybe<Scalars['Long']['output']>;
  startTime: Scalars['Long']['output'];
  timeUnit: SearchTimeUnit;
};

export type RelativeTimeComparisonInput = {
  comparator: SearchRelativeTimeComparator;
  endTime: InputMaybe<Scalars['Long']['input']>;
  startTime: Scalars['Long']['input'];
  timeUnit: SearchTimeUnit;
};

/** Generated from com/attentivemobile/contracts/campaigns/service/Stats.proto */
export type RemainingRecipientsCount = {
  __typename?: 'RemainingRecipientsCount';
  recipientcount: Scalars['Int']['output'];
};

export type RemoveAccountFromCompanyInput = {
  accountId: Scalars['ID']['input'];
  companyId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/account-service/Contract.proto */
export type RemoveAccountFromCompanyPayload = {
  __typename?: 'RemoveAccountFromCompanyPayload';
  accountId: Scalars['ID']['output'];
};

export type RemoveConciergeCompanyPromotionInput = {
  id: Scalars['ID']['input'];
};

export type RemoveDomainSuppressionInput = {
  companyId: InputMaybe<Scalars['Long']['input']>;
  domain: Scalars['String']['input'];
};

export type RemoveDomainSuppressionPayload = DefaultErrorFailure | RemoveDomainSuppressionSuccess;

/** Generated from com/attentivemobile/contracts/emails/management/Contract.proto */
export type RemoveDomainSuppressionSuccess = {
  __typename?: 'RemoveDomainSuppressionSuccess';
  suppression: DomainSuppression;
};

export type RemoveEmailIPAssignmentsInput = {
  ipAssignmentsToRemove: InputMaybe<Array<EmailIPAssignmentInput>>;
};

export type RemoveEmailIPAssignmentsPayload = DefaultErrorFailure | RemoveEmailIPAssignmentsSuccess;

/** Generated from com/attentivemobile/contracts/emails/management/Contract.proto */
export type RemoveEmailIPAssignmentsSuccess = {
  __typename?: 'RemoveEmailIPAssignmentsSuccess';
  /** @deprecated deprecated */
  assignmentsRemoved: Scalars['Long']['output'];
  emailIpAssignments: Array<EmailIPAssignment>;
};

export type RemoveFeatureFlagInput = {
  id: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/featureflag/service/Contract.proto */
export type RemoveFeatureFlagPayload = {
  __typename?: 'RemoveFeatureFlagPayload';
  removed: Maybe<FeatureFlag>;
};

export type RemoveRegionInput = {
  id: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/company-service/region/Region.proto */
export type RemoveRegionPayload = {
  __typename?: 'RemoveRegionPayload';
  region: Region;
};

export type RemoveTargetingDataInput = {
  featureFlagId: Scalars['ID']['input'];
  inputs: InputMaybe<Array<TargetingRemove>>;
};

/** Generated from com/attentivemobile/contracts/featureflag/service/Contract.proto */
export type RemoveTargetingDataPayload = {
  __typename?: 'RemoveTargetingDataPayload';
  removed: Maybe<TargetingDatum>;
};

export type RemoveUserFromAgentQueueInput = {
  userId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/concierge/service/Contract.proto */
export type RemoveUserFromAgentQueueResponse = {
  __typename?: 'RemoveUserFromAgentQueueResponse';
  userId: Scalars['String']['output'];
};

export type RenameAssetInput = {
  assetId: AssetId;
  newDisplayName: Scalars['String']['input'];
};

export type RenameAssetPayload = DefaultErrorFailure | RenameAssetSuccess;

/** Generated from com/attentivemobile/contracts/asset/library/service/Contract.proto */
export type RenameAssetSuccess = {
  __typename?: 'RenameAssetSuccess';
  renamedFolder: Maybe<Folder>;
  renamedImage: Maybe<Image>;
};

export type ReplaceCompanyCodesInput = {
  code: InputMaybe<Array<Scalars['String']['input']>>;
  companyId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/creative-service/creative/LegacyCreativeService.proto */
export type ReplaceCompanyCodesPayload = {
  __typename?: 'ReplaceCompanyCodesPayload';
  codes: Array<Scalars['String']['output']>;
};

export type ReplayByTimeInput = {
  desiredRps: InputMaybe<Scalars['Int']['input']>;
  destinationHttpEndpoint: Scalars['String']['input'];
  destinationStreamName: Scalars['String']['input'];
  endTimeUtc: Scalars['DateTime']['input'];
  eventTypeFilters: InputMaybe<Array<EventType>>;
  forceProd: InputMaybe<Scalars['Boolean']['input']>;
  operatorParallelism: InputMaybe<Scalars['Int']['input']>;
  s3ReadParallelism: InputMaybe<Scalars['Int']['input']>;
  sourceS3Uri: Scalars['String']['input'];
  sourceType: ReplaySourceType;
  startTimeUtc: Scalars['DateTime']['input'];
};

export type ReplayByTimePayload = DefaultErrorFailure | ReplayByTimeSuccess;

/** Generated from com/attentivemobile/contracts/event/platform/registration/service/replay/Contract.proto */
export type ReplayByTimeSuccess = {
  __typename?: 'ReplayByTimeSuccess';
  datadogNotebookUrl: Scalars['String']['output'];
  datastreamName: Scalars['String']['output'];
  flinkJobId: Scalars['String']['output'];
  jobmanagerUrl: Scalars['String']['output'];
};

export type ReplayByUUIDsInput = {
  destinationBackupName: Scalars['String']['input'];
  destinationStreamName: Scalars['String']['input'];
  validationS3Dir: Scalars['String']['input'];
};

export type ReplayByUUIDsPayload = DefaultErrorFailure | ReplayByUUIDsSuccess;

/** Generated from com/attentivemobile/contracts/event/platform/registration/service/replay/Contract.proto */
export type ReplayByUUIDsSuccess = {
  __typename?: 'ReplayByUUIDsSuccess';
  csvPath: Scalars['String']['output'];
  datastreamName: Scalars['String']['output'];
  flinkJobId: Scalars['String']['output'];
  jobmanagerUrl: Scalars['String']['output'];
  message: Scalars['String']['output'];
  missingUuidCount: Scalars['Int']['output'];
  validationQuery: Scalars['String']['output'];
};

export enum ReplaySourceType {
  SourceTypeEvent = 'SOURCE_TYPE_EVENT',
  SourceTypeGeneric = 'SOURCE_TYPE_GENERIC',
  SourceTypeIstioLogs = 'SOURCE_TYPE_ISTIO_LOGS',
  SourceTypeUnknown = 'SOURCE_TYPE_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/reports/api/Contract.proto */
export type Report = {
  __typename?: 'Report';
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<Account>;
  deliveryFrequencies: Array<ReportDeliveryFrequency>;
  description: Maybe<Scalars['String']['output']>;
  filterDimensions: Array<ReportFilterWrapper>;
  groupingDimensions: Array<ReportGroupingWrapper>;
  hasWriteAccess: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  metricIds: Array<Scalars['String']['output']>;
  /**
   * This field is defunct - metrics must be pulled separately based on a report's configuration
   * @deprecated deprecated
   */
  metrics: Array<Metric>;
  name: Scalars['String']['output'];
  nextDeliveryDate: Maybe<Scalars['DateTime']['output']>;
  recipients: Array<ReportRecipient>;
  reportClass: Maybe<ReportClass>;
  reportDomain: ReportDomain;
  reportId: Scalars['String']['output'];
  reportType: ReportType;
  selectedChart: Maybe<ReportChart>;
  selectedMetricId: Maybe<Scalars['String']['output']>;
  sftpEnabled: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated deprecated */
  summaryMetricIds: Array<Scalars['String']['output']>;
  summaryMetrics: Maybe<SummaryMetrics>;
  supportsGroupByEntireRange: Scalars['Boolean']['output'];
  tableSortOrders: Array<ReportMetricsOrdering>;
  /** The timestamp is in UTC */
  updated: Scalars['DateTime']['output'];
  updatedBy: Maybe<Account>;
};

export enum ReportChart {
  ReportChartExpansionRevenue = 'REPORT_CHART_EXPANSION_REVENUE',
  ReportChartIncrementalRevenue = 'REPORT_CHART_INCREMENTAL_REVENUE',
  ReportChartLineChart = 'REPORT_CHART_LINE_CHART',
  ReportChartSubscriberGrowth = 'REPORT_CHART_SUBSCRIBER_GROWTH',
  ReportChartSubscriberLift = 'REPORT_CHART_SUBSCRIBER_LIFT',
  ReportChartSuppressedSends = 'REPORT_CHART_SUPPRESSED_SENDS'
}

/** Generated from com/attentivemobile/contracts/reports/api/Contract.proto */
export type ReportClass = Node & {
  __typename?: 'ReportClass';
  filterDimensions: Array<DimensionWrapper>;
  groupingDimensions: Array<DimensionWrapper>;
  id: Scalars['ID']['output'];
  metricDefinitions: Array<MetricDefinition>;
  /** @deprecated deprecated */
  metrics: Array<Metric>;
  name: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/reports/api/Contract.proto */
export type ReportConnection = {
  __typename?: 'ReportConnection';
  edges: Array<ReportEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Long']['output'];
};

export type ReportDeliveryFrequenciesUpdateInput = {
  deliveryFrequencies: InputMaybe<Array<ReportDeliveryFrequency>>;
};

export enum ReportDeliveryFrequency {
  ReportDeliveryFrequencyDaily = 'REPORT_DELIVERY_FREQUENCY_DAILY',
  ReportDeliveryFrequencyEmpty = 'REPORT_DELIVERY_FREQUENCY_EMPTY',
  ReportDeliveryFrequencyMonthly = 'REPORT_DELIVERY_FREQUENCY_MONTHLY',
  ReportDeliveryFrequencyQuarterly = 'REPORT_DELIVERY_FREQUENCY_QUARTERLY',
  ReportDeliveryFrequencyUnknown = 'REPORT_DELIVERY_FREQUENCY_UNKNOWN',
  ReportDeliveryFrequencyWeekly = 'REPORT_DELIVERY_FREQUENCY_WEEKLY'
}

/** Generated from com/attentivemobile/contracts/reports/api/Contract.proto */
export type ReportDomain = {
  __typename?: 'ReportDomain';
  description: Scalars['String']['output'];
  displayOrder: Scalars['Int']['output'];
  domainId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  orderedReportIds: Array<Scalars['String']['output']>;
};

/** Generated from com/attentivemobile/contracts/reports/api/Contract.proto */
export type ReportEdge = {
  __typename?: 'ReportEdge';
  cursor: Scalars['String']['output'];
  node: Report;
};

export type ReportFilter = ReportStringFilter | ReportTimeFilter;

/** Generated from com/attentivemobile/contracts/reports/api/Contract.proto */
export type ReportFilterDimensionSet = {
  __typename?: 'ReportFilterDimensionSet';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ReportFilterInput = {
  stringFilter: InputMaybe<ReportStringFilterInput>;
  timeFilter: InputMaybe<ReportTimeFilterInput>;
};

/** Generated from com/attentivemobile/contracts/reports/api/Contract.proto */
export type ReportFilterWrapper = {
  __typename?: 'ReportFilterWrapper';
  filter: ReportFilter;
};

export type ReportFiltersUpdateInput = {
  filters: InputMaybe<Array<ReportFilterInput>>;
};

export type ReportGrouping = ReportStringGrouping | ReportTimeGrouping;

export type ReportGroupingInput = {
  stringGrouping: InputMaybe<ReportStringGroupingInput>;
  timeGrouping: InputMaybe<ReportTimeGroupingInput>;
};

/** Generated from com/attentivemobile/contracts/reports/api/Contract.proto */
export type ReportGroupingWrapper = {
  __typename?: 'ReportGroupingWrapper';
  grouping: ReportGrouping;
};

export type ReportGroupingsUpdateInput = {
  groupings: InputMaybe<Array<ReportGroupingInput>>;
};

/** Generated from com/attentivemobile/contracts/reports/api/Contract.proto */
export type ReportMetricsOrdering = {
  __typename?: 'ReportMetricsOrdering';
  dimensionId: Maybe<Scalars['String']['output']>;
  metricId: Maybe<Scalars['String']['output']>;
  sortDirection: ReportsSortDirection;
  sortRank: Scalars['Int']['output'];
};

export type ReportMetricsOrderingInput = {
  dimensionId: InputMaybe<Scalars['String']['input']>;
  metricId: InputMaybe<Scalars['String']['input']>;
  sortDirection: ReportsSortDirection;
  sortRank: Scalars['Int']['input'];
};

export type ReportMetricsUpdateInput = {
  metricIds: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Generated from com/attentivemobile/contracts/reports/api/CustomReport.proto */
export type ReportRecipient = {
  __typename?: 'ReportRecipient';
  account: Maybe<Account>;
};

export type ReportSortOptions = {
  column: ReportsSortColumn;
  direction: ReportsSortDirection;
};

/** Generated from com/attentivemobile/contracts/reports/api/Contract.proto */
export type ReportStringFilter = {
  __typename?: 'ReportStringFilter';
  dimensionId: Scalars['String']['output'];
  dimensionSets: Array<ReportFilterDimensionSet>;
  filterOperator: Maybe<FilterOperator>;
  isAllFilterOperator: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated deprecated */
  value: Scalars['String']['output'];
  values: Array<Scalars['String']['output']>;
};

export type ReportStringFilterInput = {
  dimensionId: Scalars['String']['input'];
  filterOperator: FilterOperator;
  value: InputMaybe<Scalars['String']['input']>;
  values: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Generated from com/attentivemobile/contracts/reports/api/Contract.proto */
export type ReportStringGrouping = {
  __typename?: 'ReportStringGrouping';
  dimensionId: Scalars['String']['output'];
};

export type ReportStringGroupingInput = {
  dimensionId: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/reports/api/Contract.proto */
export type ReportTimeFilter = {
  __typename?: 'ReportTimeFilter';
  comparisonPeriod: Maybe<ComparisonPeriod>;
  dateFilterPeriod: Maybe<DateFilterPeriod>;
  dimensionId: Scalars['String']['output'];
  endDate: Maybe<Scalars['DateTime']['output']>;
  startDate: Maybe<Scalars['DateTime']['output']>;
};

export type ReportTimeFilterInput = {
  comparisonPeriod: InputMaybe<ComparisonPeriodInput>;
  dateFilterPeriod: InputMaybe<DateFilterPeriod>;
  dimensionId: Scalars['String']['input'];
  endDate: InputMaybe<Scalars['DateTime']['input']>;
  startDate: InputMaybe<Scalars['DateTime']['input']>;
};

/** Generated from com/attentivemobile/contracts/reports/api/Contract.proto */
export type ReportTimeGrouping = {
  __typename?: 'ReportTimeGrouping';
  dimensionId: Scalars['String']['output'];
  granularity: TimeDimensionGranularity;
};

export type ReportTimeGroupingInput = {
  dimensionId: Scalars['String']['input'];
  granularity: TimeDimensionGranularity;
};

export enum ReportType {
  ReportTypeCustom = 'REPORT_TYPE_CUSTOM',
  ReportTypeLegacy = 'REPORT_TYPE_LEGACY',
  ReportTypeStandard = 'REPORT_TYPE_STANDARD',
  ReportTypeUnknown = 'REPORT_TYPE_UNKNOWN'
}

export enum ReportsSortColumn {
  ReportsSortColumnName = 'REPORTS_SORT_COLUMN_NAME',
  ReportsSortColumnNextDeliveryDate = 'REPORTS_SORT_COLUMN_NEXT_DELIVERY_DATE',
  ReportsSortColumnType = 'REPORTS_SORT_COLUMN_TYPE',
  ReportsSortColumnUnknown = 'REPORTS_SORT_COLUMN_UNKNOWN'
}

export enum ReportsSortDirection {
  ReportsSortDirectionAsc = 'REPORTS_SORT_DIRECTION_ASC',
  ReportsSortDirectionDesc = 'REPORTS_SORT_DIRECTION_DESC',
  ReportsSortDirectionUnknown = 'REPORTS_SORT_DIRECTION_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/journeys/api/RevisionStepConfig.proto */
export type ReservedKeyword = {
  __typename?: 'ReservedKeyword';
  keyword: Scalars['String']['output'];
  type: ReservedKeywordType;
};

export enum ReservedKeywordType {
  ReservedKeywordTypeCompanyCode = 'RESERVED_KEYWORD_TYPE_COMPANY_CODE',
  ReservedKeywordTypeIncomingText = 'RESERVED_KEYWORD_TYPE_INCOMING_TEXT',
  ReservedKeywordTypeSubscriberOptOut = 'RESERVED_KEYWORD_TYPE_SUBSCRIBER_OPT_OUT',
  ReservedKeywordTypeTcpaCompliance = 'RESERVED_KEYWORD_TYPE_TCPA_COMPLIANCE',
  ReservedKeywordTypeTwoWayMessage = 'RESERVED_KEYWORD_TYPE_TWO_WAY_MESSAGE',
  ReservedKeywordTypeUnknown = 'RESERVED_KEYWORD_TYPE_UNKNOWN'
}

export type ReservedPropertyInput = {
  /** Dynamic override rules for reserved properties. */
  linkOverride: InputMaybe<Array<PropertyLinkInput>>;
  reservedType: ReservedPropertyType;
};

export enum ReservedPropertyType {
  ReservedPropertyTypeCountry = 'RESERVED_PROPERTY_TYPE_COUNTRY',
  ReservedPropertyTypeFirstName = 'RESERVED_PROPERTY_TYPE_FIRST_NAME',
  ReservedPropertyTypeFullName = 'RESERVED_PROPERTY_TYPE_FULL_NAME',
  ReservedPropertyTypeLastName = 'RESERVED_PROPERTY_TYPE_LAST_NAME',
  ReservedPropertyTypeLocale = 'RESERVED_PROPERTY_TYPE_LOCALE',
  ReservedPropertyTypeUnknown = 'RESERVED_PROPERTY_TYPE_UNKNOWN'
}

export type ResetAccountMfaInput = {
  accountId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/account-service/MFAContract.proto */
export type ResetAccountMfaPayload = {
  __typename?: 'ResetAccountMfaPayload';
  successful: Scalars['Boolean']['output'];
};

export type ResetPollInput = {
  companyId: Scalars['ID']['input'];
  pollId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/survey/poll/Contract.proto */
export type ResetPollPayload = {
  __typename?: 'ResetPollPayload';
  success: Scalars['Boolean']['output'];
};

export type ResetProgramsInput = {
  companyId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/messaging/automation/admin/Contract.proto */
export type ResetProgramsPayload = {
  __typename?: 'ResetProgramsPayload';
  company: Maybe<Company>;
};

export type ResetServerSideTagEventsApiKeyInput = {
  companyId: Scalars['ID']['input'];
};

export type ResetServerSideTagEventsApiKeyPayload = DefaultErrorFailure | ResetServerSideTagEventsApiKeySuccess;

/** Generated from com/attentivemobile/contracts/integrations/serversidetag/Contract.proto */
export type ResetServerSideTagEventsApiKeySuccess = {
  __typename?: 'ResetServerSideTagEventsApiKeySuccess';
  success: Scalars['Boolean']['output'];
};

export type ResetSftpUserPasswordInput = {
  companyId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/solutions/sftp/SftpUser.proto */
export type ResetSftpUserPasswordPayload = {
  __typename?: 'ResetSftpUserPasswordPayload';
  details: Scalars['String']['output'];
};

export type ResetSubscriberInput = {
  companyId: Scalars['Long']['input'];
  phone: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/subscriptions/api/Contract.proto */
export type ResetSubscriberPayload = {
  __typename?: 'ResetSubscriberPayload';
  success: Scalars['Boolean']['output'];
};

export type RespondToCognitoAuthChallengeInput = {
  companyId: Scalars['ID']['input'];
  newPassword: Scalars['String']['input'];
  sessiontoken: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/solutions/sftp/SftpUser.proto */
export type RespondToCognitoAuthChallengePayload = {
  __typename?: 'RespondToCognitoAuthChallengePayload';
  msg: Scalars['String']['output'];
};

export type RestartAiProTrialSubscriptionInput = {
  companyId: Scalars['ID']['input'];
};

export type RestartAiProTrialSubscriptionPayload = AiProTrialActionFailure | RestartAiProTrialSubscriptionSuccess;

/** Generated from com/attentivemobile/contracts/segment/recommendation/service/aipro/Contract.proto */
export type RestartAiProTrialSubscriptionSuccess = {
  __typename?: 'RestartAiProTrialSubscriptionSuccess';
  success: Scalars['Boolean']['output'];
};

export type RevisePaymentsOfferDiscountInput = {
  type: InputMaybe<OfferDiscountType>;
  value: InputMaybe<Scalars['String']['input']>;
};

export type RevisePaymentsOfferInput = {
  companyId: Scalars['ID']['input'];
  discount: InputMaybe<RevisePaymentsOfferDiscountInput>;
  expiresAt: InputMaybe<Scalars['DateTime']['input']>;
  externalId: Scalars['String']['input'];
  keyword: InputMaybe<Scalars['String']['input']>;
  messageId: InputMaybe<Scalars['ID']['input']>;
  productName: InputMaybe<Scalars['String']['input']>;
  shopifyProductId: InputMaybe<Scalars['String']['input']>;
  shopifyProductPrice: InputMaybe<MoneyInput>;
  shopifyProductVariantId: InputMaybe<Scalars['String']['input']>;
};

/** Generated from com/attentivemobile/contracts/payments/v2/Contract.proto */
export type RevisePaymentsOfferPayload = {
  __typename?: 'RevisePaymentsOfferPayload';
  offer: Maybe<PaymentsOffer>;
  status: RevisePaymentsOfferStatus;
};

export enum RevisePaymentsOfferStatus {
  ReviseOfferStatusFailKeywordNotAcceptable = 'REVISE_OFFER_STATUS_FAIL_KEYWORD_NOT_ACCEPTABLE',
  ReviseOfferStatusFailReservedKeyword = 'REVISE_OFFER_STATUS_FAIL_RESERVED_KEYWORD',
  ReviseOfferStatusOfferInUse = 'REVISE_OFFER_STATUS_OFFER_IN_USE',
  ReviseOfferStatusSuccess = 'REVISE_OFFER_STATUS_SUCCESS',
  ReviseOfferStatusUnknown = 'REVISE_OFFER_STATUS_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/journeys/api/RevisionStep.proto */
export type RevisionStep = Node & {
  __typename?: 'RevisionStep';
  /** @deprecated deprecated */
  childrenSteps: Array<RevisionStep>;
  id: Scalars['ID']['output'];
  internalId: Scalars['String']['output'];
  type: Maybe<RevisionStepType>;
};

export type RevisionStepType = AdderStep | CreateSupportTicketStep | DelayStep | ExperimentStep | ForwardToConciergeStep | IncomingTextStep | SaveUserAttributeStep | VariantStep;

export enum RevisionStepValue {
  RevisionStepValueCreateSupportTicket = 'REVISION_STEP_VALUE_CREATE_SUPPORT_TICKET',
  RevisionStepValueForwardToConcierge = 'REVISION_STEP_VALUE_FORWARD_TO_CONCIERGE',
  RevisionStepValueIncomingText = 'REVISION_STEP_VALUE_INCOMING_TEXT',
  RevisionStepValueKeywordResponse = 'REVISION_STEP_VALUE_KEYWORD_RESPONSE',
  RevisionStepValueOpenResponse = 'REVISION_STEP_VALUE_OPEN_RESPONSE',
  RevisionStepValueSaveUserAttribute = 'REVISION_STEP_VALUE_SAVE_USER_ATTRIBUTE',
  RevisionStepValueSendConversationMessage = 'REVISION_STEP_VALUE_SEND_CONVERSATION_MESSAGE',
  RevisionStepValueSendEmail = 'REVISION_STEP_VALUE_SEND_EMAIL',
  RevisionStepValueSendLegalWelcome = 'REVISION_STEP_VALUE_SEND_LEGAL_WELCOME',
  RevisionStepValueSendMessage = 'REVISION_STEP_VALUE_SEND_MESSAGE',
  RevisionStepValueTrigger = 'REVISION_STEP_VALUE_TRIGGER',
  RevisionStepValueTwoWayMessage = 'REVISION_STEP_VALUE_TWO_WAY_MESSAGE',
  RevisionStepValueUnknown = 'REVISION_STEP_VALUE_UNKNOWN',
  RevisionStepValueVariant = 'REVISION_STEP_VALUE_VARIANT'
}

/** This request will be for security users only in order to revoke roles from other users */
export type RevokeRolesByEmailInput = {
  email: Scalars['String']['input'];
  roles: InputMaybe<Array<Role>>;
};

export type RevokeRolesByEmailPayload = DefaultErrorFailure | RevokeRolesByEmailSuccess;

/**
 * Generated from com/attentivemobile/contracts/account-service/AuthorizationContract.proto
 * If success is true, the admin role was revoked successfully
 */
export type RevokeRolesByEmailSuccess = {
  __typename?: 'RevokeRolesByEmailSuccess';
  success: Scalars['Boolean']['output'];
};

export enum Role {
  RoleAdminAccess = 'ROLE_ADMIN_ACCESS',
  RoleAttentiveAccount = 'ROLE_ATTENTIVE_ACCOUNT',
  RoleClientAccount = 'ROLE_CLIENT_ACCOUNT',
  RoleClientAdminAccount = 'ROLE_CLIENT_ADMIN_ACCOUNT',
  RoleClientLimitedAccount = 'ROLE_CLIENT_LIMITED_ACCOUNT',
  RoleClientManagedAccount = 'ROLE_CLIENT_MANAGED_ACCOUNT',
  RoleClientManagedAdminAccount = 'ROLE_CLIENT_MANAGED_ADMIN_ACCOUNT',
  RoleClientMemberAccount = 'ROLE_CLIENT_MEMBER_ACCOUNT',
  RoleClientStrategy = 'ROLE_CLIENT_STRATEGY',
  RoleConcierge = 'ROLE_CONCIERGE',
  RoleConciergeOperations = 'ROLE_CONCIERGE_OPERATIONS',
  RoleCsTam = 'ROLE_CS_TAM',
  RoleE2ETestUser = 'ROLE_E2E_TEST_USER',
  RoleEngineering = 'ROLE_ENGINEERING',
  RoleEngineeringIdentity = 'ROLE_ENGINEERING_IDENTITY',
  RoleEngineeringSales = 'ROLE_ENGINEERING_SALES',
  RoleEngineeringSolutions = 'ROLE_ENGINEERING_SOLUTIONS',
  RoleItSecurity = 'ROLE_IT_SECURITY',
  RoleMessagingOps = 'ROLE_MESSAGING_OPS',
  RoleProductManagement = 'ROLE_PRODUCT_MANAGEMENT',
  RoleReadonlyConcierge = 'ROLE_READONLY_CONCIERGE',
  RoleSubscriberAuditor = 'ROLE_SUBSCRIBER_AUDITOR',
  RoleSuperUser = 'ROLE_SUPER_USER',
  RoleUnknown = 'ROLE_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/generativeaiservice/template/GenerativeAITemplateContract.proto */
export type RoleBasedTemplateContent = {
  __typename?: 'RoleBasedTemplateContent';
  content: Scalars['String']['output'];
  role: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/featureflag/service/Contract.proto */
export type Rollout = {
  __typename?: 'Rollout';
  percent: Scalars['Float']['output'];
  variation: Scalars['String']['output'];
};

export type RolloutInput = {
  percent: Scalars['Float']['input'];
  variation: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/gateway/service/RateLimiting.proto */
export type RouteRateLimitsPayload = {
  __typename?: 'RouteRateLimitsPayload';
  routeRateLimitResponses: Array<UpdateRouteRateLimitSuccess>;
};

/** Generated from com/attentivemobile/contracts/keywords/KeywordRuleService.proto */
export type RulesLegendEntry = {
  __typename?: 'RulesLegendEntry';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type RunBackfillInput = {
  backfillType: BackfillType;
  companyId: Scalars['ID']['input'];
  endDate: Scalars['String']['input'];
  etlId: Scalars['Long']['input'];
  isRollUp: Scalars['Boolean']['input'];
  isSingleCompanyBackfill: InputMaybe<Scalars['Boolean']['input']>;
  runBy: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/solutions/sftp/Sftp.proto */
export type RunBackfillPayload = {
  __typename?: 'RunBackfillPayload';
  company: Maybe<Company>;
};

/** Generated from com/attentivemobile/contracts/eventdestination/telemetry/service/Contract.proto */
export type RunReport = {
  __typename?: 'RunReport';
  end: Scalars['String']['output'];
  runUuid: Scalars['String']['output'];
  start: Scalars['String']['output'];
  statusCodeCounts: Array<StatusCodeCountsEntry>;
  taskId: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type RunScheduledReportBackfillInput = {
  companyId: Scalars['ID']['input'];
  endDate: Scalars['String']['input'];
  etlId: Scalars['Long']['input'];
  startDate: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/solutions/sftp/Sftp.proto */
export type RunScheduledReportBackfillPayload = {
  __typename?: 'RunScheduledReportBackfillPayload';
  company: Maybe<Company>;
};

export type RunSftpReportBackfillInput = {
  companyId: Scalars['ID']['input'];
  endDate: Scalars['String']['input'];
  etlId: Scalars['Long']['input'];
  isRollUp: Scalars['Boolean']['input'];
  isSingleCompanyBackfill: InputMaybe<Scalars['Boolean']['input']>;
  startDate: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/solutions/sftp/Sftp.proto */
export type RunSftpReportBackfillPayload = {
  __typename?: 'RunSftpReportBackfillPayload';
  company: Maybe<Company>;
};

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type RunsEntry = {
  __typename?: 'RunsEntry';
  key: Scalars['String']['output'];
  value: JobErrorReport;
};

/** Generated from com/attentivemobile/contracts/event/platform/registration/service/Datastream.proto */
export type S3EventSink = {
  __typename?: 'S3EventSink';
  s3Location: S3Location;
};

/** Generated from com/attentivemobile/contracts/event/platform/registration/service/Datastream.proto */
export type S3GenericSink = {
  __typename?: 'S3GenericSink';
  s3Location: S3Location;
};

/** Generated from com/attentivemobile/contracts/event/platform/registration/service/DatastreamModel.proto */
export type S3Location = {
  __typename?: 'S3Location';
  uri: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/protobuf/common/campaigns/Campaign.proto */
export type SampleAudienceFilter = {
  __typename?: 'SampleAudienceFilter';
  samplePercentage: Scalars['Float']['output'];
};

export type SaveAITemplateInput = {
  templateInputs: InputMaybe<Array<TemplateInput>>;
};

/** Generated from com/attentivemobile/contracts/generativeaiservice/template/GenerativeAITemplateContract.proto */
export type SaveAITemplatePayload = {
  __typename?: 'SaveAITemplatePayload';
  isCreated: Scalars['Boolean']['output'];
};

export type SaveAutoJourneySettingsInput = {
  autoJourneyTriggerType: AutoJourneyTriggerType;
  companyId: Scalars['ID']['input'];
  discountAmount: InputMaybe<Scalars['String']['input']>;
  enableGoogleLinkTracking: InputMaybe<Scalars['Boolean']['input']>;
  promoCode: InputMaybe<Scalars['String']['input']>;
  utmCampaignName: InputMaybe<Scalars['String']['input']>;
};

/** Generated from com/attentivemobile/contracts/messaging/automation/journeys/Contract.proto */
export type SaveAutoJourneySettingsPayload = {
  __typename?: 'SaveAutoJourneySettingsPayload';
  isAutoJourneysSettingsSaved: Scalars['Boolean']['output'];
};

export type SaveAvailableBrandFontsInput = {
  companyId: Scalars['ID']['input'];
  fonts: InputMaybe<Array<AvailableBrandFontsInput>>;
};

/** Generated from com/attentivemobile/contracts/brand-kit/BrandFont.proto */
export type SaveAvailableBrandFontsPayload = {
  __typename?: 'SaveAvailableBrandFontsPayload';
  availableBrandFonts: Array<AvailableBrandFont>;
};

export type SaveConciergeCartInput = {
  coupon: InputMaybe<ConciergeCartCouponInput>;
  /** @deprecated deprecated */
  couponCode: InputMaybe<Scalars['String']['input']>;
  items: InputMaybe<Array<ConciergeCartItemInput>>;
  sessionId: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/concierge/service/ShoppingCart.proto */
export type SaveConciergeCartPayload = {
  __typename?: 'SaveConciergeCartPayload';
  cart: SavedConciergeCart;
};

export type SaveConciergeSpecializationInput = {
  agentIds: InputMaybe<Array<Scalars['ID']['input']>>;
  companyIds: InputMaybe<Array<Scalars['ID']['input']>>;
  description: InputMaybe<Scalars['String']['input']>;
  id: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  strictRouting: InputMaybe<Scalars['Boolean']['input']>;
};

/** Generated from com/attentivemobile/contracts/concierge/service/Specialization.proto */
export type SaveConciergeSpecializationPayload = {
  __typename?: 'SaveConciergeSpecializationPayload';
  conciergeSpecialization: Maybe<ConciergeSpecialization>;
};

/** Generated from com/attentivemobile/contracts/company-service/onboarding/Company.proto */
export type SaveContactCardFailure = StandardError & {
  __typename?: 'SaveContactCardFailure';
  companyId: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  status: StandardErrorStatus;
  title: Scalars['String']['output'];
  traceId: Scalars['String']['output'];
};

export type SaveContactCardInput = {
  contactCard: CompanyContactCardInput;
};

export type SaveContactCardPayload = SaveContactCardFailure | SaveContactCardSuccess;

/** Generated from com/attentivemobile/contracts/company-service/onboarding/Company.proto */
export type SaveContactCardSuccess = {
  __typename?: 'SaveContactCardSuccess';
  company: Maybe<Company>;
};

export type SaveGeneratedTextAndFeedbackInput = {
  companyId: Scalars['ID']['input'];
  feedbackCategory: InputMaybe<GenerativeAITextFeedbackCategory>;
  feedbackType: GenerativeAITextFeedbackType;
  generatedText: Scalars['String']['input'];
  generatedTextInputMetadata: GeneratedTextInputMetadata;
  groupId: Scalars['String']['input'];
  messageId: InputMaybe<Scalars['ID']['input']>;
  source: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/generativeaiservice/feedback/SaveGeneratedTextAndFeedbackContract.proto */
export type SaveGeneratedTextAndFeedbackPayload = {
  __typename?: 'SaveGeneratedTextAndFeedbackPayload';
  statusText: Scalars['String']['output'];
};

export type SaveMappingAndEnqueueBatchJobInput = {
  fileId: Scalars['ID']['input'];
  jobParameters: SubscriberUploadJobParametersInput;
  mappings: InputMaybe<Array<SubscriberUploadFileMappingInput>>;
  subscriberUploadJobType: InputMaybe<SubscriberUploadJobType>;
};

/** Generated from com/attentivemobile/contracts/subscriptions/batch/Contract.proto */
export type SaveMappingAndEnqueueBatchJobPayload = {
  __typename?: 'SaveMappingAndEnqueueBatchJobPayload';
  fileId: Scalars['String']['output'];
};

export type SaveOnboardingSurveySelectionsInput = {
  companyId: Scalars['ID']['input'];
  selections: InputMaybe<Array<OnboardingSurveySelectionInput>>;
};

export type SaveSelectedBrandFontsInput = {
  companyId: Scalars['ID']['input'];
  fonts: InputMaybe<Array<SelectedBrandFontsInput>>;
};

/** Generated from com/attentivemobile/contracts/brand-kit/BrandFont.proto */
export type SaveSelectedBrandFontsPayload = {
  __typename?: 'SaveSelectedBrandFontsPayload';
  company: Maybe<Company>;
  selectedBrandFonts: Array<SelectedBrandFont>;
};

/** Generated from com/attentivemobile/contracts/journeys/api/RevisionStep.proto */
export type SaveUserAttributeStep = {
  __typename?: 'SaveUserAttributeStep';
  childrenSteps: Array<AdderStep>;
  stepParameters: SaveUserAttributeStepParameters;
};

/** Generated from com/attentivemobile/contracts/journeys/api/RevisionStepParameters.proto */
export type SaveUserAttributeStepParameters = {
  __typename?: 'SaveUserAttributeStepParameters';
  userAttributeValueId: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/concierge/service/ShoppingCart.proto */
export type SavedConciergeCart = {
  __typename?: 'SavedConciergeCart';
  coupon: Maybe<ConciergeCartCoupon>;
  /** @deprecated deprecated */
  couponCode: Maybe<Scalars['String']['output']>;
  items: Array<SavedConciergeCartItem>;
  sessionId: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/concierge/service/ShoppingCart.proto */
export type SavedConciergeCartItem = {
  __typename?: 'SavedConciergeCartItem';
  lastUpdated: Scalars['DateTime']['output'];
  productVariant: Maybe<ProductVariant>;
  quantity: Scalars['Int']['output'];
};

export type SceneConstructionInput = {
  imageScale: InputMaybe<Scalars['Float']['input']>;
  inputImageUrls: InputMaybe<Array<UrlInput>>;
  modifiers: InputMaybe<Array<SceneConstructionModifierInput>>;
  scene: Scalars['String']['input'];
  scenePreposition: Scalars['String']['input'];
};

export type SceneConstructionModifierInput = {
  selectedValues: InputMaybe<Array<Scalars['String']['input']>>;
  title: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/generativeaiservice/image/ImageGenerationContract.proto */
export type SceneConstructionOption = {
  __typename?: 'SceneConstructionOption';
  options: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type: SceneConstructionOptionType;
};

export enum SceneConstructionOptionType {
  SceneConstructionOptionTypeMultiSelect = 'SCENE_CONSTRUCTION_OPTION_TYPE_MULTI_SELECT',
  SceneConstructionOptionTypeSingleSelect = 'SCENE_CONSTRUCTION_OPTION_TYPE_SINGLE_SELECT',
  SceneConstructionOptionTypeUnknown = 'SCENE_CONSTRUCTION_OPTION_TYPE_UNKNOWN'
}

export type SceneCreationInput = {
  imageScale: InputMaybe<Scalars['Float']['input']>;
  inputImageUrls: InputMaybe<Array<UrlInput>>;
  modifiers: InputMaybe<Array<SceneCreationModifierInput>>;
  scene: Scalars['String']['input'];
  scenePreposition: Scalars['String']['input'];
};

export type SceneCreationModifierInput = {
  selectedValues: InputMaybe<Array<Scalars['String']['input']>>;
  title: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/composition/generation/Contract.proto */
export type SceneCreationOption = {
  __typename?: 'SceneCreationOption';
  options: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type: SceneCreationOptionType;
};

export enum SceneCreationOptionType {
  SceneCreationOptionTypeMultiSelect = 'SCENE_CREATION_OPTION_TYPE_MULTI_SELECT',
  SceneCreationOptionTypeSingleSelect = 'SCENE_CREATION_OPTION_TYPE_SINGLE_SELECT',
  SceneCreationOptionTypeUnknown = 'SCENE_CREATION_OPTION_TYPE_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/journeys/api/JourneySchedule.proto */
export type ScheduleAssignedJourney = {
  __typename?: 'ScheduleAssignedJourney';
  evictPreviousRevisionSubscribersActivateStart: Maybe<Scalars['Boolean']['output']>;
  journey: Maybe<Journey>;
  journeyRevisionActivateStart: Maybe<JourneyRevision>;
};

export type ScheduleComposeCampaignInput = {
  companyId: Scalars['ID']['input'];
  composeCampaignId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type ScheduleEdsCronJobInput = {
  cron: Scalars['String']['input'];
  description: InputMaybe<Scalars['String']['input']>;
  taskId: Scalars['String']['input'];
  zoneId: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/eventdestination/cron/service/CronScheduler.proto */
export type ScheduleEdsCronJobPayload = {
  __typename?: 'ScheduleEdsCronJobPayload';
  msg: Scalars['String']['output'];
};

export type SchedulePageInspectionInput = {
  companyId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/tagbuildservice/taghealth/Contract.proto */
export type SchedulePageInspectionPayload = {
  __typename?: 'SchedulePageInspectionPayload';
  jobId: Scalars['String']['output'];
};

export enum ScheduleType {
  ScheduleTypeFixedDateList = 'SCHEDULE_TYPE_FIXED_DATE_LIST',
  ScheduleTypeRecurring = 'SCHEDULE_TYPE_RECURRING',
  ScheduleTypeUnknown = 'SCHEDULE_TYPE_UNKNOWN'
}

export type ScheduleWarmupCampaignInput = {
  campaignName: Scalars['String']['input'];
  companyId: Scalars['ID']['input'];
  messageId: InputMaybe<Scalars['ID']['input']>;
  restMessageId: Scalars['Long']['input'];
  startTime: Scalars['DateTime']['input'];
  timezoneRelative: Scalars['Boolean']['input'];
};

/** Generated from com/attentivemobile/contracts/emailwarmups/Contract.proto */
export type ScheduleWarmupCampaignPayload = {
  __typename?: 'ScheduleWarmupCampaignPayload';
  status: WarmupCampaignStatus;
};

export enum ScheduleWarnings {
  ScheduleWarningUnset = 'SCHEDULE_WARNING_UNSET',
  ScheduleWarningWarmupNotFinished = 'SCHEDULE_WARNING_WARMUP_NOT_FINISHED',
  ScheduleWarningWarmupNotScheduled = 'SCHEDULE_WARNING_WARMUP_NOT_SCHEDULED',
  ScheduleWarningWarmupPartiallyCancelled = 'SCHEDULE_WARNING_WARMUP_PARTIALLY_CANCELLED'
}

export type ScheduleWorkloadInput = {
  cronSchedule: InputMaybe<Scalars['String']['input']>;
  workloadName: Scalars['String']['input'];
};

export type ScheduleWorkloadPayload = DefaultErrorFailure | ScheduleWorkloadSuccess;

/** Generated from com/attentivemobile/contracts/workload/management/service/runner/Contract.proto */
export type ScheduleWorkloadSuccess = {
  __typename?: 'ScheduleWorkloadSuccess';
  status: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/protobuf/common/campaigns/Campaign.proto */
export type SchedulingMetadata = {
  __typename?: 'SchedulingMetadata';
  clientSelectedSendTime: Maybe<SchedulingMetadataClientSelectedSendTime>;
  passedTimezonesStrategy: PassedTimezonesStrategy;
  type: CampaignScheduleType;
};

export type SchedulingMetadataClientSelectedSendTime = Date | TimestampNoTz;

export type SchedulingMetadataInput = {
  clientSelectedSendTime: InputMaybe<SchedulingMetadataInputClientSelectedSendTime>;
  passedTimezonesStrategy: InputMaybe<PassedTimezonesStrategy>;
  scheduleType: CampaignScheduleType;
};

export type SchedulingMetadataInputClientSelectedSendTime =
  { date: DateInput; timestampNoTz?: never; }
  |  { date?: never; timestampNoTz: TimestampNoTzInput; };

export enum Scope {
  ScopeAllAccounts = 'SCOPE_ALL_ACCOUNTS',
  ScopeAllConciergeSettings = 'SCOPE_ALL_CONCIERGE_SETTINGS',
  ScopeAllCoupons = 'SCOPE_ALL_COUPONS',
  ScopeAllCustomAttributes = 'SCOPE_ALL_CUSTOM_ATTRIBUTES',
  ScopeAllCustomEvents = 'SCOPE_ALL_CUSTOM_EVENTS',
  ScopeAllEcommerceEvents = 'SCOPE_ALL_ECOMMERCE_EVENTS',
  ScopeAllIdentity = 'SCOPE_ALL_IDENTITY',
  ScopeAllMessages = 'SCOPE_ALL_MESSAGES',
  ScopeAllOffers = 'SCOPE_ALL_OFFERS',
  ScopeAllPolls = 'SCOPE_ALL_POLLS',
  ScopeAllPrivacyRequests = 'SCOPE_ALL_PRIVACY_REQUESTS',
  ScopeAllProductCatalogs = 'SCOPE_ALL_PRODUCT_CATALOGS',
  ScopeAllProvision = 'SCOPE_ALL_PROVISION',
  ScopeAllScim = 'SCOPE_ALL_SCIM',
  ScopeAllSubscriberEvents = 'SCOPE_ALL_SUBSCRIBER_EVENTS',
  ScopeAllWebhooks = 'SCOPE_ALL_WEBHOOKS',
  ScopeAuditSubscriptions = 'SCOPE_AUDIT_SUBSCRIPTIONS',
  ScopeUnknown = 'SCOPE_UNKNOWN',
  ScopeViewPolls = 'SCOPE_VIEW_POLLS',
  ScopeViewPrivacyRequests = 'SCOPE_VIEW_PRIVACY_REQUESTS',
  ScopeViewProductCatalogs = 'SCOPE_VIEW_PRODUCT_CATALOGS',
  ScopeViewProvision = 'SCOPE_VIEW_PROVISION',
  ScopeViewScim = 'SCOPE_VIEW_SCIM',
  ScopeViewSubscriberEvents = 'SCOPE_VIEW_SUBSCRIBER_EVENTS'
}

/** Generated from com/attentivemobile/contracts/segmentation/data/service/Contract.proto */
export type SearchAttribute = Node & {
  __typename?: 'SearchAttribute';
  company: Maybe<Company>;
  dataType: AttributeDataType;
  id: Scalars['ID']['output'];
  isArray: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  searchAttributeFields: Maybe<SearchAttributeFieldConnection>;
  source: AttributeSource;
  status: AttributeStatus;
  type: AttributeType;
};


/** Generated from com/attentivemobile/contracts/segmentation/data/service/Contract.proto */
export type SearchAttributesearchAttributeFieldsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<ListSearchAttributeFieldsFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** Generated from com/attentivemobile/contracts/segmentation/data/service/Contract.proto */
export type SearchAttributeConnection = {
  __typename?: 'SearchAttributeConnection';
  edges: Array<SearchAttributeEdge>;
  pageInfo: PageInfo;
};

export type SearchAttributeDefinitionInput = {
  alias: Scalars['String']['input'];
  scope: AttributeScope;
  vendor: ProfileVendor;
};

/** Generated from com/attentivemobile/contracts/segmentation/data/service/Contract.proto */
export type SearchAttributeEdge = {
  __typename?: 'SearchAttributeEdge';
  cursor: Scalars['String']['output'];
  node: SearchAttribute;
};

/** Generated from com/attentivemobile/contracts/segmentation/data/service/Contract.proto */
export type SearchAttributeField = Node & {
  __typename?: 'SearchAttributeField';
  alias: Scalars['String']['output'];
  company: Maybe<Company>;
  dataType: AttributeDataType;
  dynamicOptions: Maybe<DynamicOptionConnection>;
  id: Scalars['ID']['output'];
  jsonPath: Scalars['String']['output'];
  searchAttributeId: Scalars['ID']['output'];
};


/** Generated from com/attentivemobile/contracts/segmentation/data/service/Contract.proto */
export type SearchAttributeFielddynamicOptionsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** Generated from com/attentivemobile/contracts/segmentation/data/service/Contract.proto */
export type SearchAttributeFieldConnection = {
  __typename?: 'SearchAttributeFieldConnection';
  edges: Array<SearchAttributeFieldEdge>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/segmentation/data/service/Contract.proto */
export type SearchAttributeFieldEdge = {
  __typename?: 'SearchAttributeFieldEdge';
  cursor: Scalars['String']['output'];
  node: SearchAttributeField;
};

export type SearchAttributeFilter = {
  wellKnownPropertyFilter: InputMaybe<WellKnownPropertyFilter>;
};

export type SearchAttributeIdentifier = SearchAttributeMetadataIdentifier;

export type SearchAttributeInput =
  { metadataIdentifier: SearchAttributeDefinitionInput; };

/** Generated from com/attentivemobile/protobuf/common/targeting/segmentation/output/SearchDefinitionOutput.proto */
export type SearchAttributeMetadataIdentifier = {
  __typename?: 'SearchAttributeMetadataIdentifier';
  alias: Scalars['String']['output'];
  scope: AttributeScope;
  vendor: ProfileVendor;
};

export enum SearchBooleanComparator {
  SearchBooleanComparatorEquals = 'SEARCH_BOOLEAN_COMPARATOR_EQUALS',
  SearchBooleanComparatorUnknown = 'SEARCH_BOOLEAN_COMPARATOR_UNKNOWN'
}

export enum SearchDateComponentComparator {
  SearchDateComponentComparatorMonthOf = 'SEARCH_DATE_COMPONENT_COMPARATOR_MONTH_OF',
  SearchDateComponentComparatorUnknown = 'SEARCH_DATE_COMPONENT_COMPARATOR_UNKNOWN'
}

export enum SearchDistanceComparator {
  SearchDistanceComparatorUnknown = 'SEARCH_DISTANCE_COMPARATOR_UNKNOWN',
  SearchDistanceComparatorWithin = 'SEARCH_DISTANCE_COMPARATOR_WITHIN'
}

export enum SearchDistanceUnit {
  SearchDistanceUnitKilometers = 'SEARCH_DISTANCE_UNIT_KILOMETERS',
  SearchDistanceUnitMiles = 'SEARCH_DISTANCE_UNIT_MILES',
  SearchDistanceUnitUnknown = 'SEARCH_DISTANCE_UNIT_UNKNOWN'
}

export type SearchEventFilter = {
  cadenceCondition: InputMaybe<CadenceCondition>;
  event: UserSearchEvent;
  eventPropertiesFilter: InputMaybe<Array<EventPropertiesFilter>>;
  isNot: Scalars['Boolean']['input'];
  timeCondition: InputMaybe<TimeCondition>;
};

export type SearchFieldIdentifier = Alias;

export type SearchFieldInput =
  { alias: Scalars['String']['input']; };

export enum SearchFrequencyComparator {
  SearchFrequencyComparatorBetween = 'SEARCH_FREQUENCY_COMPARATOR_BETWEEN',
  SearchFrequencyComparatorEquals = 'SEARCH_FREQUENCY_COMPARATOR_EQUALS',
  SearchFrequencyComparatorLessThan = 'SEARCH_FREQUENCY_COMPARATOR_LESS_THAN',
  SearchFrequencyComparatorLessThanEquals = 'SEARCH_FREQUENCY_COMPARATOR_LESS_THAN_EQUALS',
  SearchFrequencyComparatorMoreThan = 'SEARCH_FREQUENCY_COMPARATOR_MORE_THAN',
  SearchFrequencyComparatorMoreThanEquals = 'SEARCH_FREQUENCY_COMPARATOR_MORE_THAN_EQUALS',
  SearchFrequencyComparatorUnknown = 'SEARCH_FREQUENCY_COMPARATOR_UNKNOWN'
}

export enum SearchNumericComparator {
  SearchNumericComparatorBetween = 'SEARCH_NUMERIC_COMPARATOR_BETWEEN',
  SearchNumericComparatorEquals = 'SEARCH_NUMERIC_COMPARATOR_EQUALS',
  SearchNumericComparatorIn = 'SEARCH_NUMERIC_COMPARATOR_IN',
  SearchNumericComparatorLessThan = 'SEARCH_NUMERIC_COMPARATOR_LESS_THAN',
  SearchNumericComparatorLessThanEquals = 'SEARCH_NUMERIC_COMPARATOR_LESS_THAN_EQUALS',
  SearchNumericComparatorMoreThan = 'SEARCH_NUMERIC_COMPARATOR_MORE_THAN',
  SearchNumericComparatorMoreThanEquals = 'SEARCH_NUMERIC_COMPARATOR_MORE_THAN_EQUALS',
  SearchNumericComparatorUnknown = 'SEARCH_NUMERIC_COMPARATOR_UNKNOWN'
}

export enum SearchOperator {
  SearchOperatorAll = 'SEARCH_OPERATOR_ALL',
  SearchOperatorAny = 'SEARCH_OPERATOR_ANY',
  SearchOperatorUnknown = 'SEARCH_OPERATOR_UNKNOWN'
}

export type SearchPropertyFilter = {
  attributeFilter: InputMaybe<SearchAttributeFilter>;
  eventFilter: InputMaybe<SearchEventFilter>;
};

export enum SearchRelativeTimeComparator {
  SearchRelativeTimeComparatorBetweenTheLast = 'SEARCH_RELATIVE_TIME_COMPARATOR_BETWEEN_THE_LAST',
  SearchRelativeTimeComparatorBetweenTheLastIgnoreYear = 'SEARCH_RELATIVE_TIME_COMPARATOR_BETWEEN_THE_LAST_IGNORE_YEAR',
  SearchRelativeTimeComparatorBetweenTheNext = 'SEARCH_RELATIVE_TIME_COMPARATOR_BETWEEN_THE_NEXT',
  SearchRelativeTimeComparatorBetweenTheNextIgnoreYear = 'SEARCH_RELATIVE_TIME_COMPARATOR_BETWEEN_THE_NEXT_IGNORE_YEAR',
  SearchRelativeTimeComparatorDaysAgo = 'SEARCH_RELATIVE_TIME_COMPARATOR_DAYS_AGO',
  SearchRelativeTimeComparatorDaysAgoIgnoreYear = 'SEARCH_RELATIVE_TIME_COMPARATOR_DAYS_AGO_IGNORE_YEAR',
  SearchRelativeTimeComparatorDaysUntil = 'SEARCH_RELATIVE_TIME_COMPARATOR_DAYS_UNTIL',
  SearchRelativeTimeComparatorDaysUntilIgnoreYear = 'SEARCH_RELATIVE_TIME_COMPARATOR_DAYS_UNTIL_IGNORE_YEAR',
  SearchRelativeTimeComparatorToday = 'SEARCH_RELATIVE_TIME_COMPARATOR_TODAY',
  SearchRelativeTimeComparatorTodayIgnoreYear = 'SEARCH_RELATIVE_TIME_COMPARATOR_TODAY_IGNORE_YEAR',
  SearchRelativeTimeComparatorUnknown = 'SEARCH_RELATIVE_TIME_COMPARATOR_UNKNOWN'
}

export enum SearchStringComparator {
  SearchStringComparatorEquals = 'SEARCH_STRING_COMPARATOR_EQUALS',
  SearchStringComparatorIn = 'SEARCH_STRING_COMPARATOR_IN',
  SearchStringComparatorLike = 'SEARCH_STRING_COMPARATOR_LIKE',
  SearchStringComparatorUnknown = 'SEARCH_STRING_COMPARATOR_UNKNOWN'
}

/** Generated from com/attentivemobile/protobuf/common/targeting/segmentation/output/SearchDefinitionOutput.proto */
export type SearchSubCondition = {
  __typename?: 'SearchSubCondition';
  operation: Maybe<SearchSubConditionOperation>;
};

export type SearchSubConditionInput = {
  operation: InputMaybe<SearchSubConditionInputOperation>;
};

export type SearchSubConditionInputOperation =
  { comparison: ComparisonInput; groupedComparisons?: never; }
  |  { comparison?: never; groupedComparisons: GroupedComparisonInput; };

export type SearchSubConditionOperation = Comparison | GroupedComparison;

export enum SearchTimeComparator {
  SearchTimeComparatorAfter = 'SEARCH_TIME_COMPARATOR_AFTER',
  SearchTimeComparatorBefore = 'SEARCH_TIME_COMPARATOR_BEFORE',
  SearchTimeComparatorBetween = 'SEARCH_TIME_COMPARATOR_BETWEEN',
  SearchTimeComparatorBetweenIgnoreYear = 'SEARCH_TIME_COMPARATOR_BETWEEN_IGNORE_YEAR',
  SearchTimeComparatorOn = 'SEARCH_TIME_COMPARATOR_ON',
  SearchTimeComparatorOnIgnoreYear = 'SEARCH_TIME_COMPARATOR_ON_IGNORE_YEAR',
  SearchTimeComparatorOverAllTime = 'SEARCH_TIME_COMPARATOR_OVER_ALL_TIME',
  SearchTimeComparatorUnknown = 'SEARCH_TIME_COMPARATOR_UNKNOWN'
}

export enum SearchTimeUnit {
  SearchTimeUnitDay = 'SEARCH_TIME_UNIT_DAY',
  SearchTimeUnitHours = 'SEARCH_TIME_UNIT_HOURS',
  SearchTimeUnitMonth = 'SEARCH_TIME_UNIT_MONTH',
  SearchTimeUnitUnknown = 'SEARCH_TIME_UNIT_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/user/profile/api/Graph.proto */
export type Segment = Node & {
  __typename?: 'Segment';
  /** The associated company for this segment */
  company: Maybe<Company>;
  companyNode: Maybe<Company>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  internalId: Scalars['Long']['output'];
  metadata: Array<SegmentMetadata>;
  name: Scalars['String']['output'];
  /** the size of the segment. note: may be slow to load! */
  segmentCount: Maybe<SegmentCountPayload>;
  /** The associated segment enhancement for this segment */
  segmentEnhancement: Maybe<SegmentEnhancement>;
  source: SegmentSource;
  status: SegmentStatus;
};

/** Generated from com/attentivemobile/protobuf/common/campaigns/Campaign.proto */
export type SegmentAudienceFilter = {
  __typename?: 'SegmentAudienceFilter';
  isExcludingSubscribers: Scalars['Boolean']['output'];
  segment: Maybe<Segment>;
  segmentId: Scalars['Int']['output'];
  segmentType: Maybe<SegmentType>;
};

export enum SegmentAutomationType {
  SegmentAutomationTypeBlended = 'SEGMENT_AUTOMATION_TYPE_BLENDED',
  SegmentAutomationTypeExclusion = 'SEGMENT_AUTOMATION_TYPE_EXCLUSION',
  SegmentAutomationTypeRevenueBoosting = 'SEGMENT_AUTOMATION_TYPE_REVENUE_BOOSTING',
  SegmentAutomationTypeRevenueMaximizing = 'SEGMENT_AUTOMATION_TYPE_REVENUE_MAXIMIZING',
  SegmentAutomationTypeRoiBoosting = 'SEGMENT_AUTOMATION_TYPE_ROI_BOOSTING',
  SegmentAutomationTypeRoiMaximizing = 'SEGMENT_AUTOMATION_TYPE_ROI_MAXIMIZING',
  SegmentAutomationTypeUnknown = 'SEGMENT_AUTOMATION_TYPE_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type SegmentConnection = {
  __typename?: 'SegmentConnection';
  edges: Array<SegmentEdge>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type SegmentCountItem = {
  __typename?: 'SegmentCountItem';
  activeSubscribers: Scalars['Long']['output'];
  activeSubscribersNotFatigued: Scalars['Long']['output'];
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type SegmentCountPayload = {
  __typename?: 'SegmentCountPayload';
  email: Maybe<SegmentCountItem>;
  sms: SegmentCountItem;
  smsAndEmail: Maybe<SegmentCountItem>;
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type SegmentEdge = {
  __typename?: 'SegmentEdge';
  cursor: Scalars['String']['output'];
  node: Segment;
};

/** Generated from com/attentivemobile/protobuf/common/SegmentEnhancement.proto */
export type SegmentEnhancement = Node & {
  __typename?: 'SegmentEnhancement';
  audienceSize: Maybe<Scalars['Long']['output']>;
  /** @deprecated deprecated */
  audienceTheme: Scalars['String']['output'];
  baseAudienceId: Maybe<Scalars['String']['output']>;
  chunk: Array<SegmentEnhancementChunk>;
  company: Maybe<Company>;
  /** @deprecated deprecated */
  cutoffPercentileType: Scalars['String']['output'];
  effects: Array<EnhancementEffect>;
  id: Scalars['ID']['output'];
  isRecommended: Scalars['Boolean']['output'];
  /** @deprecated deprecated */
  mlCutoffPercentile: Scalars['String']['output'];
  productFilter: AudienceAiProductFilter;
  /** @deprecated deprecated */
  subscribersAdded: Scalars['Long']['output'];
  /** @deprecated deprecated */
  subscribersRemoved: Scalars['Long']['output'];
  type: EnhancementType;
  uuid: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/protobuf/common/Chunk.proto */
export type SegmentEnhancementChunk = {
  __typename?: 'SegmentEnhancementChunk';
  chunkMetadataOneOf: Maybe<SegmentEnhancementChunkChunkMetadataOneOf>;
  modelVersion: Maybe<Scalars['String']['output']>;
  shouldExclude: Scalars['Boolean']['output'];
};

export type SegmentEnhancementChunkChunkMetadataOneOf = ExclusionHeuristicChunk | ExclusionInvalidSubscribersChunk | ExclusionProductAffinityAlphaChunk | InclusionProductAffinityAlphaChunk | InclusionUserPurchasePropensityChunk;

export type SegmentEnhancementsFilter = {
  excludeFatigued: InputMaybe<Scalars['Boolean']['input']>;
  identifyAndKeep: InputMaybe<Scalars['Boolean']['input']>;
};

export type SegmentEnhancementsWorkflowResultInputCallbackId =
  { campaignMessageGroupId: Scalars['ID']['input']; };

/** Generated from com/attentivemobile/contracts/segment/recommendation/service/Contract.proto */
export type SegmentEnhancementsWorkflowResultPayload = {
  __typename?: 'SegmentEnhancementsWorkflowResultPayload';
  company: Maybe<Company>;
  segmentEnhancementWorkflowStatus: Maybe<SegmentEnhancementsWorkflowResultPayloadSegmentEnhancementWorkflowStatus>;
};

export type SegmentEnhancementsWorkflowResultPayloadSegmentEnhancementWorkflowStatus = FailureSegmentEnhancementWorkflowStatus | InProgressSegmentEnhancementWorkflowStatus | NotFoundSegmentEnhancementWorkflowStatus | SuccessSegmentEnhancementWorkflowStatus;

export type SegmentFilterInput = {
  isExclusion: InputMaybe<Scalars['Boolean']['input']>;
  segmentId: InputMaybe<Scalars['ID']['input']>;
  segmentType: InputMaybe<SegmentType>;
};

export type SegmentFiltersInput = {
  segment: InputMaybe<Array<SegmentFilterInput>>;
};

export type SegmentId =
  { legacySegmentId: Scalars['ID']['input']; segmentId?: never; }
  |  { legacySegmentId?: never; segmentId: Scalars['ID']['input']; };

/** Generated from com/attentivemobile/contracts/user/profile/api/Graph.proto */
export type SegmentMetadata = {
  __typename?: 'SegmentMetadata';
  tag: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type SegmentMetadataInput = {
  tag: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export enum SegmentSource {
  SegmentSourceDynamic = 'SEGMENT_SOURCE_DYNAMIC',
  SegmentSourceInternalApi = 'SEGMENT_SOURCE_INTERNAL_API',
  SegmentSourceManualUpload = 'SEGMENT_SOURCE_MANUAL_UPLOAD',
  SegmentSourceThirdParty = 'SEGMENT_SOURCE_THIRD_PARTY',
  SegmentSourceUnknown = 'SEGMENT_SOURCE_UNKNOWN'
}

export enum SegmentStatus {
  SegmentStatusActive = 'SEGMENT_STATUS_ACTIVE',
  SegmentStatusDeleted = 'SEGMENT_STATUS_DELETED',
  SegmentStatusInactive = 'SEGMENT_STATUS_INACTIVE',
  SegmentStatusUnknown = 'SEGMENT_STATUS_UNKNOWN'
}

export type SegmentStatusFilter = {
  includeStatuses: InputMaybe<Array<SegmentStatus>>;
};

export enum SegmentTag {
  TagAllSubscribers = 'TAG_ALL_SUBSCRIBERS',
  TagBlended = 'TAG_BLENDED',
  TagConciergeFollowup = 'TAG_CONCIERGE_FOLLOWUP',
  TagExclusion = 'TAG_EXCLUSION',
  TagExitRule = 'TAG_EXIT_RULE',
  TagJourneyTemplate = 'TAG_JOURNEY_TEMPLATE',
  TagManualSegmentCreatedInSs = 'TAG_MANUAL_SEGMENT_CREATED_IN_SS',
  TagMlAttribute = 'TAG_ML_ATTRIBUTE',
  TagRetargeting = 'TAG_RETARGETING',
  TagRevenueBoosting = 'TAG_REVENUE_BOOSTING',
  TagRevenueMaximizing = 'TAG_REVENUE_MAXIMIZING',
  TagRoiBoosting = 'TAG_ROI_BOOSTING',
  TagRoiMaximizing = 'TAG_ROI_MAXIMIZING',
  TagUnknown = 'TAG_UNKNOWN',
  TagWarmups = 'TAG_WARMUPS'
}

export type SegmentTagFilter = {
  excludeTags: InputMaybe<Array<SegmentTag>>;
  includeTags: InputMaybe<Array<SegmentTag>>;
};

export type SegmentTemplateIdentifierInput =
  { name: Scalars['String']['input']; };

export type SegmentTemplateInput = {
  identifier: SegmentTemplateIdentifierInput;
  values: InputMaybe<Array<SegmentTemplateValueInput>>;
};

export type SegmentTemplateValueInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
  values: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum SegmentType {
  AudiencesAiExclusion = 'AUDIENCES_AI_EXCLUSION',
  AudiencesAiInclusion = 'AUDIENCES_AI_INCLUSION',
  AudiencesAiUnknown = 'AUDIENCES_AI_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/segmentation/service/segment/contract.proto */
export type SegmentV2 = Node & {
  __typename?: 'SegmentV2';
  automationType: Maybe<SegmentAutomationType>;
  company: Maybe<Company>;
  id: Scalars['ID']['output'];
  internalId: Scalars['String']['output'];
  internalLegacyId: Scalars['Long']['output'];
  name: Scalars['String']['output'];
  searchDefinition: AudienceSearchDefinition;
  /** The associated segment enhancement for this segment */
  segmentEnhancement: Maybe<SegmentEnhancement>;
};

/** Generated from com/attentivemobile/contracts/brand-kit/BrandFont.proto */
export type SelectedBrandFont = {
  __typename?: 'SelectedBrandFont';
  fallbackFont: Scalars['String']['output'];
  fontFamilyName: Scalars['String']['output'];
  fontType: FontType;
  placement: Placement;
  properties: Array<BrandFontProperties>;
  selectedBrandFontId: Scalars['Long']['output'];
  sources: Array<AvailableBrandFontSource>;
};

/** Generated from com/attentivemobile/contracts/brand-kit/BrandFont.proto */
export type SelectedBrandFontFamily = {
  __typename?: 'SelectedBrandFontFamily';
  cssUrl: Maybe<Url>;
  fallbackFont: Scalars['String']['output'];
  fontFamilyName: Scalars['String']['output'];
  fontType: FontType;
  sources: Array<AvailableBrandFontSource>;
  variants: Array<BrandFontVariant>;
};

export type SelectedBrandFontsInput = {
  fallbackFont: Scalars['String']['input'];
  fontFamilyName: Scalars['String']['input'];
  fontType: FontType;
  placement: Placement;
  properties: InputMaybe<Array<BrandFontPropertiesInput>>;
  selectedBrandFontId: InputMaybe<Scalars['Long']['input']>;
};

/** Add product to cart event input. */
export type SendAddProductToCartEventInput = {
  companyId: InputMaybe<Scalars['ID']['input']>;
  /** Timestamp of when the action occurred in ISO 8601 format. If the timestamp is older than 12 hours, it will not trigger any relevant journeys. If no timestamp is provided, it is assumed the event occurred when the endpoint is called. */
  occurredAt: InputMaybe<Scalars['DateTime']['input']>;
  /** Detailed information about the products in this request. */
  products: InputMaybe<Array<EcommerceProductInput>>;
  /** User associated with the action. Note that this is a visitor to the site and does not need to be actively subscribed to Attentive. */
  visitor: EventVisitorInput;
};

/**
 * Generated from com/attentivemobile/contracts/analytics/event/handler/Contract.proto
 * Response to the AddToCartEvent mutation.
 */
export type SendAddProductToCartEventPayload = {
  __typename?: 'SendAddProductToCartEventPayload';
  /** Timestamp of when the action occurred in ISO 8601 format. If the timestamp is older than 12 hours, it will not trigger any relevant journeys. If no timestamp is provided, it is assumed the event occurred when the endpoint is called. */
  occurredAt: Maybe<Scalars['DateTime']['output']>;
  /** Detailed information about the products in this request. */
  products: Array<EcommerceProduct>;
  /** User associated with the action. Note that this is a visitor to the site and does not need to be actively subscribed to Attentive. */
  visitor: EventVisitor;
};

/** Generated from com/attentivemobile/contracts/concierge/service/Contract.proto */
export type SendAgentHeartbeatPayload = {
  __typename?: 'SendAgentHeartbeatPayload';
  conciergeAgent: Maybe<ConciergeAgent>;
};

export type SendAgentMessageInput = {
  content: Scalars['String']['input'];
  lastInboundMessageBody: InputMaybe<Scalars['String']['input']>;
  lastMessageId: InputMaybe<Scalars['String']['input']>;
  linkTypesUsed: InputMaybe<Array<ConciergeLinkType>>;
  mediaUrl: InputMaybe<Scalars['String']['input']>;
  recommendedTemplateRequestId: InputMaybe<Scalars['String']['input']>;
  recommendedTemplates: InputMaybe<RecommendedTemplateListInput>;
  templatesUsed: InputMaybe<TemplateReferenceListInput>;
  userId: Scalars['ID']['input'];
};

export type SendApplicationWebhookTestEventInput = {
  eventType: ApplicationWebhookEventTypeInput;
  signingKey: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/externaltokens/Contract.proto */
export type SendApplicationWebhookTestEventPayload = {
  __typename?: 'SendApplicationWebhookTestEventPayload';
  responseStatus: Scalars['JSON']['output'];
};

export type SendClientMessageInput = {
  body: Scalars['String']['input'];
  mediaUrl: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/conversations/v1/Conversation.proto */
export type SendClientMessagePayload = {
  __typename?: 'SendClientMessagePayload';
  enqueued: Scalars['Boolean']['output'];
};

export type SendEmailMessagePreviewRequestInput = {
  companyId: Scalars['ID']['input'];
  emailPreviewProperties: EmailPreviewPropertiesInput;
  emailPreviewRecipients: EmailPreviewRecipientsInput;
  fullCompanySend: Scalars['Boolean']['input'];
  messageId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/composition/Contract.proto */
export type SendEmailMessagePreviewResponsePayload = {
  __typename?: 'SendEmailMessagePreviewResponsePayload';
  failedSendDestinations: Array<Scalars['String']['output']>;
  s3Url: Scalars['String']['output'];
  successfulSendDestinations: Array<Scalars['String']['output']>;
};

/** Generated from com/attentivemobile/contracts/concierge/service/Contract.proto */
export type SendMessagePayload = {
  __typename?: 'SendMessagePayload';
  sendId: Scalars['String']['output'];
  sentContent: Scalars['String']['output'];
};

export type SendMessageToAudienceInput = {
  messageText: Scalars['String']['input'];
  pollId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/survey/poll/Contract.proto */
export type SendMessageToAudiencePayload = {
  __typename?: 'SendMessageToAudiencePayload';
  success: Scalars['Boolean']['output'];
};

export type SendMfaOneTimePasscodeInput = {
  deviceIdentifier: InputMaybe<Scalars['String']['input']>;
  mfaToken: Scalars['String']['input'];
  preferredMfaMethod: InputMaybe<PreferredMfaMethod>;
};

/** Generated from com/attentivemobile/contracts/account-service/MFAContract.proto */
export type SendMfaOneTimePasscodePayload = {
  __typename?: 'SendMfaOneTimePasscodePayload';
  error: Maybe<Scalars['String']['output']>;
  successful: Scalars['Boolean']['output'];
};

export type SendPasswordResetEmailInput = {
  email: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/account-service/AuthenticationContract.proto */
export type SendPasswordResetEmailPayload = {
  __typename?: 'SendPasswordResetEmailPayload';
  successful: Scalars['Boolean']['output'];
};

export type SendPhonyInboundMessageInput = {
  body: Scalars['String']['input'];
  fromPhone: Scalars['String']['input'];
  provider: Provider;
  toPhone: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/conversations/v1/Conversation.proto */
export type SendPhonyInboundMessagePayload = {
  __typename?: 'SendPhonyInboundMessagePayload';
  sendId: Scalars['String']['output'];
};

/** Product purchase event input. */
export type SendProductPurchaseEventInput = {
  companyId: InputMaybe<Scalars['String']['input']>;
  /** Timestamp of when the action occurred in ISO 8601 format. If the timestamp is older than 12 hours, it will not trigger any relevant journeys. If no timestamp is provided, it is assumed the event occurred when the endpoint is called. */
  occurredAt: InputMaybe<Scalars['DateTime']['input']>;
  /** Detailed information about the products in this request. */
  products: InputMaybe<Array<EcommerceProductInput>>;
  /** User associated with the action. Note that this is a visitor to the site and does not need to be actively subscribed to Attentive. */
  visitor: EventVisitorInput;
};

/**
 * Generated from com/attentivemobile/contracts/analytics/event/handler/Contract.proto
 * Response to productPurchaseEvent mutation.
 */
export type SendProductPurchaseEventPayload = {
  __typename?: 'SendProductPurchaseEventPayload';
  /** Timestamp of when the action occurred in ISO 8601 format. If the timestamp is older than 12 hours, it will not trigger any relevant journeys. If no timestamp is provided, it is assumed the event occurred when the endpoint is called. */
  occurredAt: Maybe<Scalars['DateTime']['output']>;
  /** Detailed information about the products in this request. */
  products: Array<EcommerceProduct>;
  /** User associated with the action. Note that this is a visitor to the site and does not need to be actively subscribed to Attentive. */
  visitor: EventVisitor;
};

/** Product view event input. */
export type SendProductViewEventInput = {
  companyId: InputMaybe<Scalars['String']['input']>;
  /** Timestamp of when the action occurred in ISO 8601 format. If the timestamp is older than 12 hours, it will not trigger any relevant journeys. If no timestamp is provided, it is assumed the event occurred when the endpoint is called. */
  occurredAt: InputMaybe<Scalars['DateTime']['input']>;
  /** Detailed information about the products in this request. */
  products: InputMaybe<Array<EcommerceProductInput>>;
  /** User associated with the action. Note that this is a visitor to the site and does not need to be actively subscribed to Attentive. */
  visitor: EventVisitorInput;
};

/**
 * Generated from com/attentivemobile/contracts/analytics/event/handler/Contract.proto
 * Response to the ProductViewEvent mutation.
 */
export type SendProductViewEventPayload = {
  __typename?: 'SendProductViewEventPayload';
  /** Timestamp of when the action occurred in ISO 8601 format. If the timestamp is older than 12 hours, it will not trigger any relevant journeys. If no timestamp is provided, it is assumed the event occurred when the endpoint is called. */
  occurredAt: Maybe<Scalars['DateTime']['output']>;
  /** Detailed information about the products in this request. */
  products: Array<EcommerceProduct>;
  /** User associated with the action. Note that this is a visitor to the site and does not need to be actively subscribed to Attentive. */
  visitor: EventVisitor;
};

export type SendTextMessagePreviewRequestInput = {
  companyId: Scalars['ID']['input'];
  fullCompanySend: Scalars['Boolean']['input'];
  messageId: Scalars['ID']['input'];
  textPreviewRecipients: TextPreviewRecipientsInput;
  textPreviewRequest: TextPreviewInput;
};

/** Generated from com/attentivemobile/contracts/composition/Contract.proto */
export type SendTextMessagePreviewResponsePayload = {
  __typename?: 'SendTextMessagePreviewResponsePayload';
  body: Scalars['String']['output'];
  failedSendDestinations: Array<Scalars['String']['output']>;
  mediaUrl: Maybe<Scalars['String']['output']>;
  successfulSendDestinations: Array<Scalars['String']['output']>;
};

/** Generated from com/attentivemobile/protobuf/common/campaigns/Campaign.proto */
export type SendTime = {
  __typename?: 'SendTime';
  filters: Array<CampaignAudienceFilter>;
  messageMembers: Array<CampaignMessageMember>;
  startTime: Scalars['DateTime']['output'];
  status: CampaignStatus;
};

/**
 * Generated from com/attentivemobile/protobuf/common/campaigns/Campaign.proto
 * This metadata is used for passing along to the copyMessage mutation to sync content for send time experiments.
 */
export type SendTimeExperimentMessageMetadata = {
  __typename?: 'SendTimeExperimentMessageMetadata';
  composeMessage: Maybe<ComposeMessage>;
  sendTime: Scalars['DateTime']['output'];
};

/** Generated from com/attentivemobile/protobuf/common/campaigns/Campaign.proto */
export type SendTimeExperimentMetadata = {
  __typename?: 'SendTimeExperimentMetadata';
  mainCampaignMessageMember: Maybe<CampaignMessageMember>;
  sendTimeExperimentMessageMetadata: Array<SendTimeExperimentMessageMetadata>;
  sendTimes: Array<Scalars['DateTime']['output']>;
};

export type SendTimeExperimentMetadataAttachmentInput = {
  experimentSendTimes: InputMaybe<SendTimeExperimentSendTimeInput>;
};

export type SendTimeExperimentSendTimeInput = {
  experimentSendTime: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

/** Generated from com/attentivemobile/contracts/concierge/service/Message.proto */
export type SendingAgent = {
  __typename?: 'SendingAgent';
  account: Maybe<Account>;
};

/** Generated from com/attentivemobile/contracts/emails/management/Contract.proto */
export type SendingDomain = {
  __typename?: 'SendingDomain';
  default: Maybe<Scalars['Boolean']['output']>;
  dnsRecords: DnsRecords;
  domain: Scalars['String']['output'];
  domainId: Scalars['Long']['output'];
  lastValidationAttempt: Maybe<Scalars['DateTime']['output']>;
  shared: Scalars['Boolean']['output'];
  subdomain: Scalars['String']['output'];
  valid: Scalars['Boolean']['output'];
};

/** Generated from com/attentivemobile/contracts/integrations/serversidetag/Contract.proto */
export type ServerSideTagDnsRecord = {
  __typename?: 'ServerSideTagDnsRecord';
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type SetAIBrandVoiceSettingsInput = {
  aiBrandVoiceSettings: AIBrandVoiceSettingsInput;
  companyId: Scalars['ID']['input'];
};

export type SetAIBrandVoiceSettingsPayload = DefaultErrorFailure | SetAIBrandVoiceSettingsSuccess;

/** Generated from com/attentivemobile/contracts/messaging/automation/brandvoice/AIBrandVoice.proto */
export type SetAIBrandVoiceSettingsSuccess = {
  __typename?: 'SetAIBrandVoiceSettingsSuccess';
  aiBrandVoiceSettings: AIBrandVoiceSettings;
  statusText: Scalars['String']['output'];
};

export type SetAIBrandVoiceSettingsV2Input = {
  aiBrandVoiceSettings: AIBrandVoiceSettingsV2Input;
  companyId: Scalars['ID']['input'];
};

export type SetAIBrandVoiceSettingsV2Payload = DefaultErrorFailure | SetAIBrandVoiceSettingsV2Success;

/** Generated from com/attentivemobile/contracts/generativeaiservice/brandvoice/AIBrandVoice.proto */
export type SetAIBrandVoiceSettingsV2Success = {
  __typename?: 'SetAIBrandVoiceSettingsV2Success';
  aiBrandVoiceSettings: AIBrandVoiceSettingsV2;
  statusText: Scalars['String']['output'];
};

export type SetAIJourneyLinkConfigurationInput = {
  companyId: Scalars['ID']['input'];
  linkConfigurationInput: AIJourneyLinkConfigurationInput;
};

export type SetAIJourneyLinkConfigurationPayload = DefaultErrorFailure | SetAIJourneyLinkConfigurationSuccess;

/** Generated from com/attentivemobile/contracts/messaging/automation/aijourneys/onboarding/AIJourneyLinkConfigurationServiceContract.proto */
export type SetAIJourneyLinkConfigurationSuccess = {
  __typename?: 'SetAIJourneyLinkConfigurationSuccess';
  linkConfigurationInput: AIJourneyLinkConfiguration;
};

export type SetAIJourneyLinkTrackingParamsInput = {
  aiJourneyTrackingParamsInput: InputMaybe<Array<AIJourneyTrackingParamsInput>>;
  companyId: Scalars['ID']['input'];
};

export type SetAIJourneyLinkTrackingParamsPayload = DefaultErrorFailure | SetAIJourneyLinkTrackingParamsSuccess;

/** Generated from com/attentivemobile/contracts/messaging/automation/aijourneys/onboarding/AIJourneyLinkConfigServiceContract.proto */
export type SetAIJourneyLinkTrackingParamsSuccess = {
  __typename?: 'SetAIJourneyLinkTrackingParamsSuccess';
  aiJourneyTrackingParams: Array<AIJourneyTrackingParams>;
};

export type SetAccountMfaPreferencesInput = {
  mfaPhoneNumber: InputMaybe<Scalars['String']['input']>;
  mfaToken: Scalars['String']['input'];
  preferredOtpDeliveryMethod: InputMaybe<MfaOneTimePasscodeDeliveryMethod>;
};

export type SetBrandColorSchemeInput = {
  colors: InputMaybe<Array<BrandColorInput>>;
  companyId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/brand-kit/BrandColorSchemeService.proto */
export type SetBrandColorSchemePayload = {
  __typename?: 'SetBrandColorSchemePayload';
  colors: Array<BrandColor>;
  company: Maybe<Company>;
};

export type SetConciergeAgentAvailabilityInput = {
  accountId: Scalars['ID']['input'];
  isAvailable: Scalars['Boolean']['input'];
};

/** Generated from com/attentivemobile/contracts/concierge/service/Contract.proto */
export type SetConciergeAgentAvailabilityPayload = {
  __typename?: 'SetConciergeAgentAvailabilityPayload';
  account: Maybe<Account>;
};

/** Request message for setting custom attributes. */
export type SetCustomAttributesInput = {
  companyId: InputMaybe<Scalars['ID']['input']>;
  /** Any metadata associated with the attribute. Object keys and values are expected to be strings. Both object keys and object values are case sensitive. "Favorite color" and "Favorite Color" would be considered different custom attributes. */
  properties: Scalars['JSON']['input'];
  /** User associated with the action. Note that this is a visitor to the site and does not need to be actively subscribed to Attentive. */
  user: EventUserInput;
};

/**
 * Generated from com/attentivemobile/contracts/user/profile/Contract.proto
 * Response message to a request for setting custom attributes.
 */
export type SetCustomAttributesPayload = {
  __typename?: 'SetCustomAttributesPayload';
  /** Any metadata associated with the attribute. */
  properties: Scalars['JSON']['output'];
  /** User associated with the action. Note that this is a visitor to the site and does not need to be actively subscribed to Attentive. */
  user: EventUser;
};

export type SetDefaultDomainInput = {
  companyId: Scalars['ID']['input'];
  domainId: Scalars['Long']['input'];
};

/** Generated from com/attentivemobile/contracts/emails/management/Contract.proto */
export type SetDefaultDomainPayload = {
  __typename?: 'SetDefaultDomainPayload';
  sendingDomain: SendingDomain;
};

export type SetEmailIPAddressWarmupDateInput = {
  disableWarmup: InputMaybe<Scalars['Boolean']['input']>;
  ipAddress: Scalars['String']['input'];
  warmupDate: InputMaybe<Scalars['DateTime']['input']>;
};

export type SetEmailIPAddressWarmupDatePayload = DefaultErrorFailure | SetEmailIPAddressWarmupDateSuccess;

/** Generated from com/attentivemobile/contracts/emails/management/Contract.proto */
export type SetEmailIPAddressWarmupDateSuccess = {
  __typename?: 'SetEmailIPAddressWarmupDateSuccess';
  disableWarmup: Maybe<Scalars['Boolean']['output']>;
  ipAddress: Scalars['String']['output'];
  warmupDate: Maybe<Scalars['DateTime']['output']>;
};

export type SetSubscriberBlocklistStatusByAgentInput = {
  shouldBlocklist: Scalars['Boolean']['input'];
  userId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/concierge/service/Contract.proto */
export type SetSubscriberBlocklistStatusByAgentPayload = {
  __typename?: 'SetSubscriberBlocklistStatusByAgentPayload';
  isBlocklisted: Scalars['Boolean']['output'];
  user: Maybe<User>;
};

export type SetSubscriberOptInStatusByAgentInput = {
  desiredOptInStatus: Scalars['Boolean']['input'];
  userId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/concierge/service/Contract.proto */
export type SetSubscriberOptInStatusByAgentPayload = {
  __typename?: 'SetSubscriberOptInStatusByAgentPayload';
  isOptedIn: Scalars['Boolean']['output'];
  user: Maybe<User>;
};

export type SetSweepstakesFilterInput = {
  numToSelect: Scalars['Int']['input'];
  pollId: Scalars['ID']['input'];
  pollKeywordIds: InputMaybe<Array<Scalars['ID']['input']>>;
  selectorType: SweepstakesSelectorType;
  subscriberType: SweepstakesSubscriberType;
};

export type SetSweepstakesFilterPayload = DefaultErrorFailure | SetSweepstakesFilterSuccess;

/** Generated from com/attentivemobile/contracts/survey/poll/Contract.proto */
export type SetSweepstakesFilterSuccess = {
  __typename?: 'SetSweepstakesFilterSuccess';
  poll: Poll;
};

export type SetTargetingDataInput = {
  featureFlagId: Scalars['ID']['input'];
  inputs: InputMaybe<Array<TargetingData>>;
};

/** Generated from com/attentivemobile/contracts/featureflag/service/Contract.proto */
export type SetTargetingDataPayload = {
  __typename?: 'SetTargetingDataPayload';
  values: Array<TargetingDatum>;
};

export type SetupTemplatizedPostbackInput = {
  body: JsonStringInput;
  companyId: Scalars['ID']['input'];
  companyPostbackTemplateId: Scalars['String']['input'];
  conditionalMacros: InputMaybe<Array<JsonStringInput>>;
  endpoint: Scalars['String']['input'];
  httpRequestType: HttpRequestType;
  integrationEvent: InputMaybe<Array<IntegrationEventTypeInput>>;
  integrationType: IntegrationType;
  postbackHeaders: InputMaybe<Array<TemplatizedPostbackHeaderSettingsInput>>;
  templateName: Scalars['String']['input'];
  templatizedAuthSettingsInput: TemplatizedAuthSettingInput;
  vendor: Vendor;
};

/** Generated from com/attentivemobile/contracts/integrations/templatizedpostbacks/Contract.proto */
export type SetupTemplatizedPostbackPayload = {
  __typename?: 'SetupTemplatizedPostbackPayload';
  responseBody: Scalars['String']['output'];
  responseCode: Scalars['Long']['output'];
};

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type SftpAuthKey = {
  __typename?: 'SftpAuthKey';
  bucketName: Scalars['String']['output'];
  keyName: Scalars['String']['output'];
};

export type SftpAuthKeyInput = {
  bucketName: Scalars['String']['input'];
  keyName: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/solutions/sftp/SftpUser.proto */
export type SftpCognitoUserInternalStatus = {
  __typename?: 'SftpCognitoUserInternalStatus';
  email: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/solutions/sftp/SftpUser.proto */
export type SftpCognitoUserStatusResponse = {
  __typename?: 'SftpCognitoUserStatusResponse';
  forceChangePassword: Scalars['Boolean']['output'];
  hasEmail: Scalars['Boolean']['output'];
  internalStatus: SftpCognitoUserInternalStatus;
};

export enum SftpConnectionStatus {
  SftpConnectionStatusConnectionError = 'SFTP_CONNECTION_STATUS_CONNECTION_ERROR',
  SftpConnectionStatusMissingFile = 'SFTP_CONNECTION_STATUS_MISSING_FILE',
  SftpConnectionStatusMissingPath = 'SFTP_CONNECTION_STATUS_MISSING_PATH',
  SftpConnectionStatusSuccess = 'SFTP_CONNECTION_STATUS_SUCCESS'
}

export enum SftpReportFeature {
  SftpReportFeatureConversions = 'SFTP_REPORT_FEATURE_CONVERSIONS',
  SftpReportFeatureGeneralDatafeed = 'SFTP_REPORT_FEATURE_GENERAL_DATAFEED',
  SftpReportFeatureOptOut = 'SFTP_REPORT_FEATURE_OPT_OUT'
}

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type SftpSettings = {
  __typename?: 'SftpSettings';
  authKey: Maybe<SftpAuthKey>;
  filename: Scalars['String']['output'];
  host: Scalars['String']['output'];
  password: Scalars['String']['output'];
  path: Scalars['String']['output'];
  port: Scalars['Int']['output'];
  timezone: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type SftpSettingsInput = {
  authKey: InputMaybe<SftpAuthKeyInput>;
  filename: Scalars['String']['input'];
  host: Scalars['String']['input'];
  password: Scalars['String']['input'];
  path: Scalars['String']['input'];
  port: Scalars['Int']['input'];
  timezone: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export enum ShippingProvider {
  ShippingProviderDhl = 'SHIPPING_PROVIDER_DHL',
  ShippingProviderFedex = 'SHIPPING_PROVIDER_FEDEX',
  ShippingProviderUnknown = 'SHIPPING_PROVIDER_UNKNOWN',
  ShippingProviderUps = 'SHIPPING_PROVIDER_UPS',
  ShippingProviderUsps = 'SHIPPING_PROVIDER_USPS'
}

export enum ShortCodeLeaseOwner {
  ShortCodeLeaseOwnerAggregator = 'SHORT_CODE_LEASE_OWNER_AGGREGATOR',
  ShortCodeLeaseOwnerAttentive = 'SHORT_CODE_LEASE_OWNER_ATTENTIVE',
  ShortCodeLeaseOwnerClient = 'SHORT_CODE_LEASE_OWNER_CLIENT',
  ShortCodeLeaseOwnerUnknown = 'SHORT_CODE_LEASE_OWNER_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto */
export type ShortLink = {
  __typename?: 'ShortLink';
  displayUrl: Scalars['String']['output'];
  isOpenInBrowser: Maybe<Scalars['Boolean']['output']>;
  messageLinkId: Scalars['String']['output'];
  messageToken: Maybe<Scalars['String']['output']>;
  redirectTargetUrl: Scalars['String']['output'];
  shouldDisableHttpsPrefix: Maybe<Scalars['Boolean']['output']>;
  videoLinkPreviewProperties: Maybe<VideoPreviewLinkProperties>;
};

export type ShortLinkProperties = {
  displayUrl: InputMaybe<Scalars['String']['input']>;
  isOpenInBrowser: InputMaybe<Scalars['Boolean']['input']>;
  messageLinkId: InputMaybe<Scalars['String']['input']>;
  messageToken: InputMaybe<Scalars['String']['input']>;
  redirectTargetUrl: Scalars['String']['input'];
  videoLinkProperties: InputMaybe<VideoLinkPropertiesInput>;
};

export type SingleSearch = {
  searchDefinition: UserSearchDefinition;
};

export type Site = {
  __typename?: 'Site';
  appStatus: AppStatus;
  appTeardownTransaction: AppTeardownTransaction;
  authStatus: AuthStatus;
  companyTransition: CompanyTransition;
  degradationWarningMessage: Maybe<Scalars['String']['output']>;
  developerBanner: DeveloperBanner;
  toasts: Array<Toast>;
};

/** Generated from com/attentivemobile/protobuf/common/campaigns/Campaign.proto */
export type SliceAudienceFilter = {
  __typename?: 'SliceAudienceFilter';
  count: Scalars['Int']['output'];
  index: Scalars['Int']['output'];
};

export type SnowflakeSecureDataSharingInput = {
  bucket: InputMaybe<Scalars['String']['input']>;
  datasetOrderingKey: InputMaybe<Scalars['String']['input']>;
  datasetSql: Scalars['String']['input'];
  keyName: InputMaybe<Scalars['String']['input']>;
};

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type SnowflakeSecureDataSharingSettings = {
  __typename?: 'SnowflakeSecureDataSharingSettings';
  bucket: Maybe<Scalars['String']['output']>;
  datasetOrderingKey: Maybe<Scalars['String']['output']>;
  datasetSql: Scalars['String']['output'];
  keyName: Maybe<Scalars['String']['output']>;
};

export type SnowflakeSettingsConfirmationResult = SnowflakeSettingsDataSample | SnowflakeSettingsDisplayableError;

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type SnowflakeSettingsDataSample = {
  __typename?: 'SnowflakeSettingsDataSample';
  columnNames: Array<Scalars['String']['output']>;
  dataSampleRows: Array<SnowflakeSettingsDataSampleRow>;
  dataTypes: Array<Scalars['String']['output']>;
};

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type SnowflakeSettingsDataSampleRow = {
  __typename?: 'SnowflakeSettingsDataSampleRow';
  values: Array<Scalars['String']['output']>;
};

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type SnowflakeSettingsDisplayableError = {
  __typename?: 'SnowflakeSettingsDisplayableError';
  errorMessage: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/solutions/bulkoptout/SolutionsOptout.proto */
export type SolutionsDateRange = {
  __typename?: 'SolutionsDateRange';
  endTime: Maybe<Scalars['DateTime']['output']>;
  startTime: Maybe<Scalars['DateTime']['output']>;
};

export type SolutionsDateRangeInput = {
  endTime: InputMaybe<Scalars['DateTime']['input']>;
  startTime: InputMaybe<Scalars['DateTime']['input']>;
};

export type SolutionsEmailOptOutInput = {
  companyId: Scalars['ID']['input'];
  email: InputMaybe<Array<Scalars['String']['input']>>;
  filename: InputMaybe<Scalars['String']['input']>;
  totalRows: InputMaybe<Scalars['Long']['input']>;
};

/** Generated from com/attentivemobile/contracts/solutions/bulkoptout/SolutionsOptout.proto */
export type SolutionsEmailOptOutPayload = {
  __typename?: 'SolutionsEmailOptOutPayload';
  jobId: Scalars['String']['output'];
  /** @deprecated deprecated */
  successfulOptouts: Array<SuccessfulOptoutsEntry>;
};

export type SolutionsPhoneOptOutInput = {
  companyId: Scalars['ID']['input'];
  countryCode: InputMaybe<Scalars['String']['input']>;
  filename: InputMaybe<Scalars['String']['input']>;
  optOutSource: InputMaybe<OptOutSource>;
  phones: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Generated from com/attentivemobile/contracts/solutions/bulkoptout/SolutionsOptout.proto */
export type SolutionsPhoneOptOutPayload = {
  __typename?: 'SolutionsPhoneOptOutPayload';
  wasSuccessful: Scalars['Boolean']['output'];
};

export type SolutionsUpdateMessageLinkTargetInput = {
  companyId: Scalars['ID']['input'];
  messageLinkId: Scalars['Long']['input'];
  newUrl: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/solutions/MessageProperties.proto */
export type SolutionsUpdateMessageLinkTargetPayload = {
  __typename?: 'SolutionsUpdateMessageLinkTargetPayload';
  newUrl: Scalars['String']['output'];
};

export enum SortDirection {
  SortDirectionAsc = 'SORT_DIRECTION_ASC',
  SortDirectionDesc = 'SORT_DIRECTION_DESC',
  SortDirectionUnknown = 'SORT_DIRECTION_UNKNOWN'
}

export enum SourceType {
  SourceTypeCompanySettings = 'SOURCE_TYPE_COMPANY_SETTINGS',
  SourceTypeConversation = 'SOURCE_TYPE_CONVERSATION',
  SourceTypeFeedback = 'SOURCE_TYPE_FEEDBACK',
  SourceTypeUnknown = 'SOURCE_TYPE_UNKNOWN'
}

export type StandardError = {
  message: Scalars['String']['output'];
  status: StandardErrorStatus;
  title: Scalars['String']['output'];
  traceId: Scalars['String']['output'];
};

/** Standard error status. */
export enum StandardErrorStatus {
  StandardErrorStatusAborted = 'STANDARD_ERROR_STATUS_ABORTED',
  StandardErrorStatusAlreadyExists = 'STANDARD_ERROR_STATUS_ALREADY_EXISTS',
  StandardErrorStatusCancelled = 'STANDARD_ERROR_STATUS_CANCELLED',
  StandardErrorStatusDataLoss = 'STANDARD_ERROR_STATUS_DATA_LOSS',
  StandardErrorStatusDeadlineExceeded = 'STANDARD_ERROR_STATUS_DEADLINE_EXCEEDED',
  StandardErrorStatusFailedPrecondition = 'STANDARD_ERROR_STATUS_FAILED_PRECONDITION',
  StandardErrorStatusInternal = 'STANDARD_ERROR_STATUS_INTERNAL',
  StandardErrorStatusInvalidArgument = 'STANDARD_ERROR_STATUS_INVALID_ARGUMENT',
  StandardErrorStatusNotFound = 'STANDARD_ERROR_STATUS_NOT_FOUND',
  StandardErrorStatusNotSet = 'STANDARD_ERROR_STATUS_NOT_SET',
  StandardErrorStatusOutOfRange = 'STANDARD_ERROR_STATUS_OUT_OF_RANGE',
  StandardErrorStatusPermissionDenied = 'STANDARD_ERROR_STATUS_PERMISSION_DENIED',
  StandardErrorStatusResourceExhausted = 'STANDARD_ERROR_STATUS_RESOURCE_EXHAUSTED',
  StandardErrorStatusUnauthenticated = 'STANDARD_ERROR_STATUS_UNAUTHENTICATED',
  StandardErrorStatusUnavailable = 'STANDARD_ERROR_STATUS_UNAVAILABLE',
  StandardErrorStatusUnimplemented = 'STANDARD_ERROR_STATUS_UNIMPLEMENTED',
  StandardErrorStatusUnknown = 'STANDARD_ERROR_STATUS_UNKNOWN'
}

export type StartAiProTrialSubscriptionInput = {
  companyId: Scalars['ID']['input'];
  formSubmittedDate: DateInput;
  startDate: DateInput;
};

export type StartAiProTrialSubscriptionPayload = AiProTrialActionFailure | StartAiProTrialSubscriptionSuccess;

/** Generated from com/attentivemobile/contracts/segment/recommendation/service/aipro/Contract.proto */
export type StartAiProTrialSubscriptionSuccess = {
  __typename?: 'StartAiProTrialSubscriptionSuccess';
  success: Scalars['Boolean']['output'];
};

export type StartDatastreamInput = {
  datastreamName: Scalars['String']['input'];
  entryClass: Scalars['String']['input'];
  entryClassArgs: InputMaybe<Array<EntryClassArgsEntry>>;
  jarName: Scalars['String']['input'];
  mode: OperationMode;
  restorePath: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/event/platform/registration/service/GraphContract.proto */
export type StartDatastreamPayload = {
  __typename?: 'StartDatastreamPayload';
  datastreamName: Scalars['String']['output'];
  flinkJobId: Scalars['String']['output'];
  result: OperationResult;
  startPath: Scalars['String']['output'];
  state: DatastreamStatus;
};

export type StateRecoveryPolicy = {
  checkpointMaxSecondsBehind: Scalars['Int']['input'];
  failoverStartWithoutState: Scalars['Boolean']['input'];
};

/** Generated from com/attentivemobile/contracts/eventdestination/telemetry/service/Contract.proto */
export type StatusCodeCountsEntry = {
  __typename?: 'StatusCodeCountsEntry';
  key: Scalars['String']['output'];
  value: Scalars['Long']['output'];
};

export type StopDatastreamInput = {
  async: Scalars['Boolean']['input'];
  datastreamName: Scalars['String']['input'];
  mode: OperationMode;
};

/** Generated from com/attentivemobile/contracts/event/platform/registration/service/Contract.proto */
export type StopDatastreamPayload = {
  __typename?: 'StopDatastreamPayload';
  datastreamName: Scalars['String']['output'];
  result: OperationResult;
  state: DatastreamStatus;
  stopPath: Scalars['String']['output'];
};

export type StreamingReplayInput = {
  datadogEnv: Scalars['String']['input'];
  endTimeUtc: Scalars['DateTime']['input'];
  serviceName: Scalars['String']['input'];
  startTimeUtc: Scalars['DateTime']['input'];
  useConcurrentReplay: InputMaybe<Scalars['Boolean']['input']>;
};

export type StreamingReplayPayload = DefaultErrorFailure | StreamingReplaySuccess;

/** Generated from com/attentivemobile/contracts/streaming/replay/service/replay/Contract.proto */
export type StreamingReplaySuccess = {
  __typename?: 'StreamingReplaySuccess';
  status: Scalars['String']['output'];
};

export enum StringComparator {
  StringComparatorEquals = 'STRING_COMPARATOR_EQUALS',
  StringComparatorIn = 'STRING_COMPARATOR_IN',
  StringComparatorLike = 'STRING_COMPARATOR_LIKE',
  StringComparatorUnknown = 'STRING_COMPARATOR_UNKNOWN'
}

/** Generated from com/attentivemobile/protobuf/common/targeting/segmentation/output/ComparisonTypes.proto */
export type StringComparison = {
  __typename?: 'StringComparison';
  comparator: SearchStringComparator;
  isNot: Maybe<Scalars['Boolean']['output']>;
  values: Array<Scalars['String']['output']>;
};

export type StringComparisonInput = {
  comparator: SearchStringComparator;
  isNot: InputMaybe<Scalars['Boolean']['input']>;
  values: InputMaybe<Array<Scalars['String']['input']>>;
};

export type StringCondition = {
  comparator: StringComparator;
  isNot: InputMaybe<Scalars['Boolean']['input']>;
  values: InputMaybe<Array<Scalars['String']['input']>>;
};

export type StringDatasetFilter = {
  operator: StringFilterOperator;
  values: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Generated from com/attentivemobile/contracts/metricsapi/DatasetsContract.proto */
export type StringDatasetValue = {
  __typename?: 'StringDatasetValue';
  value: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/metricsapi/Contract.proto */
export type StringDimension = {
  __typename?: 'StringDimension';
  description: Maybe<Scalars['String']['output']>;
  dimensionId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /**
   * A new dimensionValues type on Company will replace this field
   * @deprecated deprecated
   */
  possibleValues: Array<Scalars['String']['output']>;
};

export enum StringFilterOperator {
  StringFilterOperatorIn = 'STRING_FILTER_OPERATOR_IN',
  StringFilterOperatorNotIn = 'STRING_FILTER_OPERATOR_NOT_IN',
  StringFilterOperatorUnknown = 'STRING_FILTER_OPERATOR_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type StringPropertyValue = {
  __typename?: 'StringPropertyValue';
  value: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/user/profile/property/UserPropertyV2.proto */
export type StringPropertyValueV2 = {
  __typename?: 'StringPropertyValueV2';
  stringValue: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/brand-kit/BrandElementStyles.proto */
export type StylableElement = {
  __typename?: 'StylableElement';
  name: Scalars['String']['output'];
};

export type StylableElementInput = {
  name: Scalars['String']['input'];
};

export type SubmitGeneratedTextFeedbackInput = {
  companyId: Scalars['ID']['input'];
  generatedTextFeedback: InputMaybe<Array<GenerativeAITextFeedbackInput>>;
};

/** Generated from com/attentivemobile/contracts/generativeaiservice/feedback/GenerativeAIFeedbackContract.proto */
export type SubmitGeneratedTextFeedbackPayload = {
  __typename?: 'SubmitGeneratedTextFeedbackPayload';
  statusText: Scalars['String']['output'];
};

export type SubmitGenerativeAIOptInInput = {
  companyId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/generativeaiservice/legal/GenerativeAILegalContract.proto */
export type SubmitGenerativeAIOptInPayload = {
  __typename?: 'SubmitGenerativeAIOptInPayload';
  company: Maybe<Company>;
};

/** Subscribe mutation input. */
export type SubscribeInput = {
  companyId: InputMaybe<Scalars['ID']['input']>;
  /** External Identifiers for a user. */
  externalIdentifiers: InputMaybe<ExternalIdentifiersForSubscribeInput>;
  /** The locale of the user. This field is required if sign source id is omitted. */
  locale: InputMaybe<LocaleForSubscribeInput>;
  /** The unique identifier of the sign-up source. This can be found in the Sign-up Units tab of the Attentive platform in the ID column for any API sign-up method. Or, you can contact our White Glove team (whiteglove@attentivemobile.com) or your Client Strategy Manager at Attentive to request a sign-up source for either marketing or transactional opt-ins. Our team will review API opt-in units with [compliance in mind](https://docs.attentivemobile.com/pages/legal-docs/legal-disclosure-language/). This field is not required if both locale and subscription type are provided. */
  signupSourceId: InputMaybe<Scalars['String']['input']>;
  /** Opt in subscriber silently (do not send a Reply Y to subscribe text). NOTE: This property is disabled by default. We strongly recommend maintaining the double opt-in flow. The double opt in serves a couple of important purposes from a legal/compliance perspective. Contact your dedicated Client Strategy Manager (CSM) or our whiteglove@attentivemobile.com team to enable it. If wanting to skip legal/reply y language, set this to be true. We will not send reply y message or legal message to subscribers added with this flag set to true. */
  singleOptIn: InputMaybe<Scalars['Boolean']['input']>;
  /** Type of subscription. This field is required if sign source id is omitted. */
  type: InputMaybe<SubscriptionType>;
  /** User associated with the action. Note that this is a visitor to the site and does not need to be actively subscribed to Attentive. */
  user: UserInput;
};

/**
 * Generated from com/attentivemobile/contracts/subscriptions/api/v2/Contract.proto
 * Response to subscribe mutation.
 */
export type SubscribePayload = {
  __typename?: 'SubscribePayload';
  /** External Identifiers for a user. */
  externalIdentifiers: Maybe<ExternalIdentifiersForSubscribe>;
  /** Subscribe results. */
  subscribeResults: Array<Subscription>;
};

/** Generated from com/attentivemobile/protobuf/common/Identifiers.proto */
export type SubscriberID = {
  __typename?: 'SubscriberID';
  id: Scalars['Long']['output'];
};

export enum SubscriberIdentifier {
  Id = 'ID',
  Phone = 'PHONE'
}

/** Generated from com/attentivemobile/contracts/keywords/KeywordService.proto */
export type SubscriberInfo = {
  __typename?: 'SubscriberInfo';
  subscriptionType: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/concierge/service/Message.proto */
export type SubscriberInitiated = {
  __typename?: 'SubscriberInitiated';
  subscriberInitiated: Scalars['Boolean']['output'];
};

/** Generated from com/attentivemobile/protobuf/common/campaigns/Campaign.proto */
export type SubscriberOptimizedSendTimeAudienceFilter = {
  __typename?: 'SubscriberOptimizedSendTimeAudienceFilter';
  includeSubscribersWithoutOptimalSendTime: Scalars['Boolean']['output'];
  optimizedWindowSizeInMins: Scalars['Int']['output'];
  windowStartTimeInUtc: Scalars['DateTime']['output'];
};

/** Generated from com/attentivemobile/contracts/subscriptions/batch/Model.proto */
export type SubscriberTag = {
  __typename?: 'SubscriberTag';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type SubscriberTagInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/subscriptions/batch/Model.proto */
export type SubscriberUploadClientId = {
  __typename?: 'SubscriberUploadClientId';
  customIdNamespace: Maybe<Scalars['String']['output']>;
  type: SubscriberUploadClientIdType;
  /** @deprecated deprecated */
  value: Scalars['String']['output'];
};

export type SubscriberUploadClientIdInput = {
  customIdNamespace: InputMaybe<Scalars['String']['input']>;
  type: SubscriberUploadClientIdType;
  value: Scalars['String']['input'];
};

export enum SubscriberUploadClientIdType {
  SubscriberUploadClientIdTypeClientUserId = 'SUBSCRIBER_UPLOAD_CLIENT_ID_TYPE_CLIENT_USER_ID',
  SubscriberUploadClientIdTypeCordialCustomerId = 'SUBSCRIBER_UPLOAD_CLIENT_ID_TYPE_CORDIAL_CUSTOMER_ID',
  SubscriberUploadClientIdTypeCustomId = 'SUBSCRIBER_UPLOAD_CLIENT_ID_TYPE_CUSTOM_ID',
  SubscriberUploadClientIdTypeKlaviyoId = 'SUBSCRIBER_UPLOAD_CLIENT_ID_TYPE_KLAVIYO_ID',
  SubscriberUploadClientIdTypeSalesforceMarketingCloudSubscriberId = 'SUBSCRIBER_UPLOAD_CLIENT_ID_TYPE_SALESFORCE_MARKETING_CLOUD_SUBSCRIBER_ID',
  SubscriberUploadClientIdTypeShopifyId = 'SUBSCRIBER_UPLOAD_CLIENT_ID_TYPE_SHOPIFY_ID',
  SubscriberUploadClientIdTypeUnknown = 'SUBSCRIBER_UPLOAD_CLIENT_ID_TYPE_UNKNOWN'
}

export enum SubscriberUploadDestinationType {
  DestinationTypeEmail = 'DESTINATION_TYPE_EMAIL',
  DestinationTypePhone = 'DESTINATION_TYPE_PHONE',
  DestinationTypeUnknown = 'DESTINATION_TYPE_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/subscriptions/batch/Model.proto */
export type SubscriberUploadFile = Node & {
  __typename?: 'SubscriberUploadFile';
  company: Maybe<Company>;
  id: Scalars['ID']['output'];
  lastUpdated: Scalars['DateTime']['output'];
  mapping: SubscriberUploadFileMappingTemplate;
  name: Scalars['String']['output'];
  sampleData: Maybe<Array<SubscriberUploadFileColumn>>;
  timeUploaded: Scalars['DateTime']['output'];
  uploadedBy: Scalars['String']['output'];
};


/** Generated from com/attentivemobile/contracts/subscriptions/batch/Model.proto */
export type SubscriberUploadFilesampleDataArgs = {
  numberOfLines: InputMaybe<Scalars['Long']['input']>;
};

/** Generated from com/attentivemobile/contracts/subscriptions/batch/Model.proto */
export type SubscriberUploadFileColumn = {
  __typename?: 'SubscriberUploadFileColumn';
  header: Scalars['String']['output'];
  values: Array<Scalars['String']['output']>;
};

export type SubscriberUploadFileField = CustomPropertyDefinition | DestinationEmail | DestinationPhone | SubscriberUploadClientId | WellKnownProperty;

/** Generated from com/attentivemobile/contracts/subscriptions/batch/Model.proto */
export type SubscriberUploadFileFieldMapping = {
  __typename?: 'SubscriberUploadFileFieldMapping';
  customPropertyDefinition: Maybe<CustomPropertyDefinition>;
  destinationType: Maybe<SubscriberUploadDestinationType>;
  /** @deprecated deprecated */
  field: SubscriberUploadFileField;
  header: Scalars['String']['output'];
  subscriberUploadClientId: Maybe<SubscriberUploadClientId>;
  wellKnownProperty: Maybe<WellKnownProperty>;
};

export type SubscriberUploadFileMappingInput = {
  clientId: InputMaybe<SubscriberUploadClientIdInput>;
  customPropertyId: InputMaybe<Scalars['Long']['input']>;
  destinationType: InputMaybe<SubscriberUploadDestinationType>;
  /** @deprecated deprecated */
  email: InputMaybe<Scalars['String']['input']>;
  header: Scalars['String']['input'];
  newCustomProperty: InputMaybe<CustomPropertyDefinitionInput>;
  /** @deprecated deprecated */
  phone: InputMaybe<Scalars['String']['input']>;
  wellKnownProperty: InputMaybe<Scalars['String']['input']>;
  /** @deprecated deprecated */
  wellKnownPropertyId: InputMaybe<Scalars['Long']['input']>;
};

/** Generated from com/attentivemobile/contracts/subscriptions/batch/Model.proto */
export type SubscriberUploadFileMappingTemplate = {
  __typename?: 'SubscriberUploadFileMappingTemplate';
  emailMapped: Scalars['Boolean']['output'];
  mappings: Array<SubscriberUploadFileFieldMapping>;
  phoneMapped: Scalars['Boolean']['output'];
  unusedClientIds: Array<SubscriberUploadClientIdType>;
  unusedCustomProperties: Array<CustomPropertyDefinition>;
  unusedHeaders: Array<Scalars['String']['output']>;
  unusedWellKnownProperties: Array<WellKnownProperty>;
};

/** Generated from com/attentivemobile/contracts/subscriptions/batch/Model.proto */
export type SubscriberUploadJob = Node & {
  __typename?: 'SubscriberUploadJob';
  batchId: Scalars['Long']['output'];
  company: Maybe<Company>;
  id: Scalars['ID']['output'];
  jobType: SubscriberUploadJobType;
  jobUploadResults: SubscriberUploadJobResults;
  parameters: SubscriberUploadJobParameters;
  processingTime: Duration;
  status: SubscriberUploadJobStatus;
  subscriberUploadFile: SubscriberUploadFile;
  subscriptionFlow: SubscriberUploadSubscriptionFlow;
  validationResult: SubscriberUploadValidationResult;
};

/** Generated from com/attentivemobile/contracts/subscriptions/batch/Contract.proto */
export type SubscriberUploadJobConnection = {
  __typename?: 'SubscriberUploadJobConnection';
  edges: Array<SubscriberUploadJobEdge>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/subscriptions/batch/Contract.proto */
export type SubscriberUploadJobEdge = {
  __typename?: 'SubscriberUploadJobEdge';
  cursor: Scalars['String']['output'];
  node: SubscriberUploadJob;
};

/** Generated from com/attentivemobile/contracts/subscriptions/batch/Model.proto */
export type SubscriberUploadJobParameters = {
  __typename?: 'SubscriberUploadJobParameters';
  /** @deprecated deprecated */
  assignCoupons: Scalars['Boolean']['output'];
  journey: Maybe<Journey>;
  offer: Maybe<Scalars['String']['output']>;
  /** @deprecated deprecated */
  sendWelcome: Scalars['Boolean']['output'];
  strategy: SubscriberUploadUpdateStrategy;
  subscriberTag: SubscriberTag;
  subscriptionTypes: SubscriberUploadSubscriptionType;
};

export type SubscriberUploadJobParametersInput = {
  assignCoupons: Scalars['Boolean']['input'];
  companyId: Scalars['ID']['input'];
  journeyId: InputMaybe<Scalars['ID']['input']>;
  locale: InputMaybe<LocaleInput>;
  offer: InputMaybe<Scalars['String']['input']>;
  sendWelcome: Scalars['Boolean']['input'];
  strategy: SubscriberUploadUpdateStrategy;
  subscriberTag: SubscriberTagInput;
  subscriptionFlow: SubscriberUploadSubscriptionFlow;
  subscriptionTypes: InputMaybe<Array<SubscriberUploadSubscriptionType>>;
};

/** Generated from com/attentivemobile/contracts/subscriptions/batch/Model.proto */
export type SubscriberUploadJobResults = {
  __typename?: 'SubscriberUploadJobResults';
  existingActiveOptOutSubscribersCount: Scalars['Long']['output'];
  existingActiveSubscribersCount: Scalars['Long']['output'];
  existingJoinedSubscribersCount: Scalars['Long']['output'];
  invalidSubscribersCount: Scalars['Long']['output'];
  validSubscribersCount: Scalars['Long']['output'];
};

export enum SubscriberUploadJobStatus {
  StatusAbandoned = 'STATUS_ABANDONED',
  StatusComplete = 'STATUS_COMPLETE',
  StatusInvalid = 'STATUS_INVALID',
  StatusInProgress = 'STATUS_IN_PROGRESS',
  StatusProcess = 'STATUS_PROCESS',
  StatusUnknown = 'STATUS_UNKNOWN',
  StatusUploaded = 'STATUS_UPLOADED'
}

export enum SubscriberUploadJobType {
  JobTypeAddSubscribers = 'JOB_TYPE_ADD_SUBSCRIBERS',
  JobTypeOptOutEmails = 'JOB_TYPE_OPT_OUT_EMAILS',
  JobTypeOptOutPhones = 'JOB_TYPE_OPT_OUT_PHONES',
  JobTypeSubscriberTagOnly = 'JOB_TYPE_SUBSCRIBER_TAG_ONLY',
  JobTypeSuppressSubscriptions = 'JOB_TYPE_SUPPRESS_SUBSCRIPTIONS',
  JobTypeUnknown = 'JOB_TYPE_UNKNOWN',
  JobTypeUnsuppressSubscriptions = 'JOB_TYPE_UNSUPPRESS_SUBSCRIPTIONS',
  JobTypeUpdateExisting = 'JOB_TYPE_UPDATE_EXISTING'
}

export enum SubscriberUploadSubscriptionFlow {
  SubscriptionFlowDoubleOptIn = 'SUBSCRIPTION_FLOW_DOUBLE_OPT_IN',
  SubscriptionFlowSilentOptIn = 'SUBSCRIPTION_FLOW_SILENT_OPT_IN',
  SubscriptionFlowSingleOptIn = 'SUBSCRIPTION_FLOW_SINGLE_OPT_IN',
  SubscriptionFlowUnknown = 'SUBSCRIPTION_FLOW_UNKNOWN'
}

export enum SubscriberUploadSubscriptionType {
  SubscriptionTypeEmailCheckoutAbandoned = 'SUBSCRIPTION_TYPE_EMAIL_CHECKOUT_ABANDONED',
  SubscriptionTypeEmailMarketing = 'SUBSCRIPTION_TYPE_EMAIL_MARKETING',
  SubscriptionTypeEmailTransactional = 'SUBSCRIPTION_TYPE_EMAIL_TRANSACTIONAL',
  SubscriptionTypeTextCheckoutAbandoned = 'SUBSCRIPTION_TYPE_TEXT_CHECKOUT_ABANDONED',
  SubscriptionTypeTextMarketing = 'SUBSCRIPTION_TYPE_TEXT_MARKETING',
  SubscriptionTypeTextTransactional = 'SUBSCRIPTION_TYPE_TEXT_TRANSACTIONAL',
  SubscriptionTypeUnknown = 'SUBSCRIPTION_TYPE_UNKNOWN'
}

export enum SubscriberUploadUpdateStrategy {
  SubscriberUpdateStrategyOverwrite = 'SUBSCRIBER_UPDATE_STRATEGY_OVERWRITE',
  SubscriberUpdateStrategyRemoveDuplicates = 'SUBSCRIBER_UPDATE_STRATEGY_REMOVE_DUPLICATES',
  SubscriberUpdateStrategyUnknown = 'SUBSCRIBER_UPDATE_STRATEGY_UNKNOWN',
  SubscriberUpdateStrategyUpdateExisting = 'SUBSCRIBER_UPDATE_STRATEGY_UPDATE_EXISTING'
}

/** Generated from com/attentivemobile/contracts/subscriptions/batch/Model.proto */
export type SubscriberUploadValidationResult = {
  __typename?: 'SubscriberUploadValidationResult';
  duplicates: Scalars['Long']['output'];
  invalidEnumValues: Array<InvalidEnumValuesEntry>;
  invalidRows: Scalars['Long']['output'];
  invalidRowsPreCheckReportUrl: Scalars['String']['output'];
  totalRows: Scalars['Long']['output'];
};

/**
 * Generated from com/attentivemobile/contracts/subscriptions/api/v2/Model.proto
 * Subscription details.
 */
export type Subscription = {
  __typename?: 'Subscription';
  /** Channel for subscription. */
  channel: SubscriptionChannel;
  /** Destination address for subscription. */
  destinationAddress: Maybe<DestinationAddress>;
  /** Type of subscription. */
  type: SubscriptionType;
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type SubscriptionAttribution = {
  __typename?: 'SubscriptionAttribution';
  name: Scalars['String']['output'];
};

/** Channel of subscription. */
export enum SubscriptionChannel {
  ChannelEmail = 'CHANNEL_EMAIL',
  ChannelText = 'CHANNEL_TEXT',
  ChannelUnknown = 'CHANNEL_UNKNOWN'
}

/**
 * Generated from com/attentivemobile/contracts/user/profile/Contract.proto
 * Subscription connection details.
 */
export type SubscriptionConnection = {
  __typename?: 'SubscriptionConnection';
  /** Sign up date timestamp. */
  signupDate: Scalars['DateTime']['output'];
  /** Subscription eligibility details. */
  subscriptionEligibilities: Array<SubscriptionEligibility>;
};

/**
 * Generated from com/attentivemobile/contracts/user/profile/Contract.proto
 * Subscription eligibility information.
 */
export type SubscriptionEligibility = {
  __typename?: 'SubscriptionEligibility';
  attribution: Maybe<SubscriptionAttribution>;
  /** Eligibility information. */
  eligibility: Eligibility;
  /** Subscription details. */
  subscription: Subscription;
};

/** Type and channel of a subscription. */
export type SubscriptionInput = {
  /** Channel of subscription. */
  channel: SubscriptionChannel;
  /** Type of subscription. */
  type: SubscriptionType;
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type SubscriptionOptInCompletedEvent = {
  __typename?: 'SubscriptionOptInCompletedEvent';
  /** @deprecated deprecated */
  created: Scalars['DateTime']['output'];
  /** @deprecated deprecated */
  id: Scalars['String']['output'];
  subscription: Subscription;
};

export enum SubscriptionOptOutSource {
  OptOutSourceAttentiveSupportEmail = 'OPT_OUT_SOURCE_ATTENTIVE_SUPPORT_EMAIL',
  OptOutSourceCcpa = 'OPT_OUT_SOURCE_CCPA',
  OptOutSourceClient = 'OPT_OUT_SOURCE_CLIENT',
  OptOutSourceCrmUiUnsubscribeButton = 'OPT_OUT_SOURCE_CRM_UI_UNSUBSCRIBE_BUTTON',
  OptOutSourceEmail = 'OPT_OUT_SOURCE_EMAIL',
  OptOutSourceKeyword = 'OPT_OUT_SOURCE_KEYWORD',
  OptOutSourceLitigator = 'OPT_OUT_SOURCE_LITIGATOR',
  OptOutSourceScript = 'OPT_OUT_SOURCE_SCRIPT',
  OptOutSourceUnknown = 'OPT_OUT_SOURCE_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type SubscriptionOptedOutEvent = {
  __typename?: 'SubscriptionOptedOutEvent';
  /** @deprecated deprecated */
  created: Scalars['DateTime']['output'];
  /** @deprecated deprecated */
  id: Scalars['String']['output'];
  subscription: Subscription;
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type SubscriptionSuppressedEvent = {
  __typename?: 'SubscriptionSuppressedEvent';
  subscription: Subscription;
  suppressionReason: SuppressionReason;
};

/** Type of subscription. */
export enum SubscriptionType {
  TypeCheckoutAbandoned = 'TYPE_CHECKOUT_ABANDONED',
  TypeMarketing = 'TYPE_MARKETING',
  TypeTransactional = 'TYPE_TRANSACTIONAL',
  TypeUnknown = 'TYPE_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type SubscriptionUnsuppressedEvent = {
  __typename?: 'SubscriptionUnsuppressedEvent';
  subscription: Subscription;
};

/** Generated from com/attentivemobile/contracts/segment/recommendation/service/Contract.proto */
export type SuccessSegmentEnhancementWorkflowStatus = {
  __typename?: 'SuccessSegmentEnhancementWorkflowStatus';
  enhancements: Array<SegmentEnhancement>;
};

/** Generated from com/attentivemobile/contracts/solutions/bulkoptout/SolutionsOptout.proto */
export type SuccessfulOptoutsEntry = {
  __typename?: 'SuccessfulOptoutsEntry';
  key: Scalars['String']['output'];
  value: Scalars['Boolean']['output'];
};

/** Generated from com/attentivemobile/contracts/reports/api/Contract.proto */
export type SummaryMetrics = {
  __typename?: 'SummaryMetrics';
  summaryMetricIds: Array<Scalars['String']['output']>;
};

export type SummaryMetricsInput = {
  summaryMetricIds: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum SuppressionReason {
  SuppressionReasonHighBlockRate = 'SUPPRESSION_REASON_HIGH_BLOCK_RATE',
  SuppressionReasonHighBounceRate = 'SUPPRESSION_REASON_HIGH_BOUNCE_RATE',
  SuppressionReasonInvalidAddress = 'SUPPRESSION_REASON_INVALID_ADDRESS',
  SuppressionReasonManual = 'SUPPRESSION_REASON_MANUAL',
  SuppressionReasonRoleAccounts = 'SUPPRESSION_REASON_ROLE_ACCOUNTS',
  SuppressionReasonSpamReporter = 'SUPPRESSION_REASON_SPAM_REPORTER',
  SuppressionReasonTemporaryAddress = 'SUPPRESSION_REASON_TEMPORARY_ADDRESS',
  SuppressionReasonUnknown = 'SUPPRESSION_REASON_UNKNOWN'
}

export type SwapCreativeExternalNameInput = {
  creativeIdOne: Scalars['String']['input'];
  creativeIdTwo: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/solutions/companyconfiguration/Configuration.proto */
export type SwapCreativeExternalNamePayload = {
  __typename?: 'SwapCreativeExternalNamePayload';
  success: Maybe<Scalars['Boolean']['output']>;
};

export enum SweepstakesSelectorType {
  SweepstakesSelectorTypeFirst = 'SWEEPSTAKES_SELECTOR_TYPE_FIRST',
  SweepstakesSelectorTypeLast = 'SWEEPSTAKES_SELECTOR_TYPE_LAST',
  SweepstakesSelectorTypeMostVotes = 'SWEEPSTAKES_SELECTOR_TYPE_MOST_VOTES',
  SweepstakesSelectorTypeRandom = 'SWEEPSTAKES_SELECTOR_TYPE_RANDOM'
}

export enum SweepstakesSubscriberType {
  SweepstakesSubscriberTypeAny = 'SWEEPSTAKES_SUBSCRIBER_TYPE_ANY',
  SweepstakesSubscriberTypeMarketing = 'SWEEPSTAKES_SUBSCRIBER_TYPE_MARKETING'
}

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type Sync = Node & {
  __typename?: 'Sync';
  connectionSettings: Maybe<SyncConnectionSettings>;
  description: Scalars['String']['output'];
  fetchOptions: FetchOptions;
  fileSettings: FileSettings;
  fileType: EdsJobIngestionFormat;
  id: Scalars['ID']['output'];
  latestSync: Maybe<Scalars['DateTime']['output']>;
  latestSyncRunStatus: Maybe<SyncRunStatus>;
  name: Scalars['String']['output'];
  schedule: SyncSchedule;
  status: SyncStatus;
  syncId: Scalars['String']['output'];
  syncMethod: EdsJobSyncMethod;
  /** Retrieve all runs of this sync. */
  syncRuns: Maybe<SyncRuns>;
  syncType: EdsJobSync;
  targetConfig: Scalars['JSON']['output'];
};


/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type SyncsyncRunsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type SyncConnectionSettings = AttentiveS3Settings | SftpSettings | SnowflakeSecureDataSharingSettings;

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type SyncEdge = {
  __typename?: 'SyncEdge';
  cursor: Scalars['String']['output'];
  node: Sync;
};

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type SyncError = {
  __typename?: 'SyncError';
  message: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type SyncRun = Node & {
  __typename?: 'SyncRun';
  failed: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  ingested: Scalars['Int']['output'];
  status: SyncRunStatus;
  /** Retrieve the details of this specific run. */
  syncRunDetails: Maybe<SyncRunDetails>;
  trigger: SyncTrigger;
  updated: Scalars['DateTime']['output'];
};

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type SyncRunDetails = {
  __typename?: 'SyncRunDetails';
  links: SyncRunLinks;
  stepTroubleshootingDetails: Array<SyncRunStepTroubleshootingDetails>;
};

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type SyncRunEdge = {
  __typename?: 'SyncRunEdge';
  cursor: Scalars['String']['output'];
  node: SyncRun;
};

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type SyncRunLinks = {
  __typename?: 'SyncRunLinks';
  datadog: Scalars['String']['output'];
  druid: Scalars['String']['output'];
  temporal: Scalars['String']['output'];
};

export enum SyncRunStatus {
  SyncRunStatusCompletedMixedSuccess = 'SYNC_RUN_STATUS_COMPLETED_MIXED_SUCCESS',
  SyncRunStatusCompletedSuccessfully = 'SYNC_RUN_STATUS_COMPLETED_SUCCESSFULLY',
  SyncRunStatusFailed = 'SYNC_RUN_STATUS_FAILED',
  SyncRunStatusRunning = 'SYNC_RUN_STATUS_RUNNING',
  SyncRunStatusScheduled = 'SYNC_RUN_STATUS_SCHEDULED',
  SyncRunStatusUnknown = 'SYNC_RUN_STATUS_UNKNOWN'
}

export enum SyncRunStep {
  SyncRunStepChunkFile = 'SYNC_RUN_STEP_CHUNK_FILE',
  SyncRunStepFileDownload = 'SYNC_RUN_STEP_FILE_DOWNLOAD',
  SyncRunStepFileSurvey = 'SYNC_RUN_STEP_FILE_SURVEY',
  SyncRunStepIngestData = 'SYNC_RUN_STEP_INGEST_DATA',
  SyncRunStepPreprocessor = 'SYNC_RUN_STEP_PREPROCESSOR',
  SyncRunStepProductCatalogUpload = 'SYNC_RUN_STEP_PRODUCT_CATALOG_UPLOAD',
  SyncRunStepSetRatelimit = 'SYNC_RUN_STEP_SET_RATELIMIT',
  SyncRunStepSnowflakeContentDownload = 'SYNC_RUN_STEP_SNOWFLAKE_CONTENT_DOWNLOAD',
  SyncRunStepSyncStart = 'SYNC_RUN_STEP_SYNC_START',
  SyncRunStepTransformData = 'SYNC_RUN_STEP_TRANSFORM_DATA',
  SyncRunStepUnknown = 'SYNC_RUN_STEP_UNKNOWN',
  SyncRunStepWaitingForExternalSystemResults = 'SYNC_RUN_STEP_WAITING_FOR_EXTERNAL_SYSTEM_RESULTS'
}

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type SyncRunStepTroubleshootingDetails = {
  __typename?: 'SyncRunStepTroubleshootingDetails';
  details: Scalars['JSON']['output'];
  status: SyncRunStatus;
  step: SyncRunStep;
  timestamp: Scalars['DateTime']['output'];
};

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type SyncRuns = {
  __typename?: 'SyncRuns';
  edges: Array<SyncRunEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type SyncSchedule = {
  __typename?: 'SyncSchedule';
  schedule: Maybe<SyncScheduleSchedule>;
};

export type SyncScheduleInput = {
  schedule: InputMaybe<SyncScheduleInputSchedule>;
};

export type SyncScheduleInputSchedule =
  { cronSchedule: CronInput; oneTimeSchedule?: never; }
  |  { cronSchedule?: never; oneTimeSchedule: OneTimeInput; };

export type SyncScheduleSchedule = Cron | OneTime;

export enum SyncStatus {
  SyncStatusActive = 'SYNC_STATUS_ACTIVE',
  SyncStatusInactive = 'SYNC_STATUS_INACTIVE',
  SyncStatusUnknown = 'SYNC_STATUS_UNKNOWN'
}

export enum SyncTrigger {
  SyncTriggerManual = 'SYNC_TRIGGER_MANUAL',
  SyncTriggerScheduled = 'SYNC_TRIGGER_SCHEDULED',
  SyncTriggerUnknown = 'SYNC_TRIGGER_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type Syncs = {
  __typename?: 'Syncs';
  edges: Array<SyncEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Generated from com/attentivemobile/protobuf/common/tags/Tag.proto */
export type Tag = Node & {
  __typename?: 'Tag';
  company: Maybe<Company>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /** @deprecated deprecated */
  uuid: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/tagbuildservice/Contract.proto */
export type TagConfig = {
  __typename?: 'TagConfig';
  company: Maybe<Company>;
  description: Maybe<Scalars['String']['output']>;
  dtagConfig: JsonString;
  enrichers: Array<Scalars['String']['output']>;
  preset: Scalars['String']['output'];
  tagVersion: Scalars['String']['output'];
  transformers: Array<Scalars['String']['output']>;
  triggers: Array<Scalars['String']['output']>;
};

/** Generated from com/attentivemobile/contracts/tagbuildservice/Contract.proto */
export type TagConfigRelease = {
  __typename?: 'TagConfigRelease';
  created: Scalars['String']['output'];
  id: Scalars['String']['output'];
  tagConfig: TagConfig;
  userEmail: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/tag/service/Contract.proto */
export type TagEdge = {
  __typename?: 'TagEdge';
  cursor: Scalars['String']['output'];
  node: Tag;
};

/** Generated from com/attentivemobile/contracts/tagbuildservice/taghealth/Contract.proto */
export type TagEventStats = {
  __typename?: 'TagEventStats';
  addToCartStats: Maybe<EventStats>;
  productViewStats: Maybe<EventStats>;
  purchaseStats: Maybe<EventStats>;
};

export type TagFilter = {
  name: InputMaybe<Scalars['String']['input']>;
};

/** Generated from com/attentivemobile/contracts/tag/service/Contract.proto */
export type TagPayload = {
  __typename?: 'TagPayload';
  tag: Tag;
};

/** Generated from com/attentivemobile/protobuf/common/tags/Tag.proto */
export type TaggableEntity = {
  __typename?: 'TaggableEntity';
  campaignMessageGroup: Maybe<CampaignMessageGroup>;
  taggableEntityType: TaggableEntityType;
};

export enum TaggableEntityType {
  TaggableEntityTypeCampaignMessageGroup = 'TAGGABLE_ENTITY_TYPE_CAMPAIGN_MESSAGE_GROUP',
  TaggableEntityTypeUnknown = 'TAGGABLE_ENTITY_TYPE_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/tag/service/Contract.proto */
export type TagsConnection = {
  __typename?: 'TagsConnection';
  edges: Array<TagEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Long']['output'];
};

export type TakeActionOnFeedbackInput = {
  messageFeedbackId: Scalars['ID']['input'];
  outcome: MessageFeedbackOutcome;
};

/** Generated from com/attentivemobile/contracts/concierge/service/Feedback.proto */
export type TakeActionOnFeedbackPayload = {
  __typename?: 'TakeActionOnFeedbackPayload';
  messageFeedback: MessageFeedback;
};

export enum TargetCreativeStatus {
  TargetCreativeStatusActive = 'TARGET_CREATIVE_STATUS_ACTIVE',
  TargetCreativeStatusInactive = 'TARGET_CREATIVE_STATUS_INACTIVE',
  TargetCreativeStatusUnknown = 'TARGET_CREATIVE_STATUS_UNKNOWN'
}

/** If the message is already created, you can sync the content by passing in the target_message_id. A new message will be created if target_message_id is blank. */
export type TargetMessageInput = {
  name: Scalars['String']['input'];
  targetMessageId: InputMaybe<Scalars['ID']['input']>;
  utmParameters: InputMaybe<UtmParametersInput>;
};

/** Generated from com/attentivemobile/contracts/featureflag/service/Contract.proto */
export type TargetingConnection = {
  __typename?: 'TargetingConnection';
  edges: Array<TargetingDatumEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Long']['output'];
};

export type TargetingData = {
  contextKey: Scalars['String']['input'];
  contextValue: Scalars['String']['input'];
  description: Scalars['String']['input'];
  rolloutVariants: InputMaybe<Array<RolloutInput>>;
};

/** Generated from com/attentivemobile/contracts/featureflag/service/Contract.proto */
export type TargetingDatum = {
  __typename?: 'TargetingDatum';
  contextKey: Scalars['String']['output'];
  contextValue: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  featureFlag: Maybe<FeatureFlag>;
  featureFlagId: Scalars['String']['output'];
  rolloutVariants: Array<Rollout>;
  updated: Scalars['DateTime']['output'];
};

/** Generated from com/attentivemobile/contracts/featureflag/service/Contract.proto */
export type TargetingDatumEdge = {
  __typename?: 'TargetingDatumEdge';
  cursor: Scalars['String']['output'];
  node: TargetingDatum;
};

export type TargetingRemove = {
  contextKey: Scalars['String']['input'];
  contextValue: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/concierge/service/Template.proto */
export type TemplateEdge = {
  __typename?: 'TemplateEdge';
  cursor: Scalars['String']['output'];
  node: AgentTemplate;
};

export enum TemplateFieldValidationType {
  TemplateFieldsValidationTypeShortcut = 'TEMPLATE_FIELDS_VALIDATION_TYPE_SHORTCUT',
  TemplateFieldsValidationTypeUnknown = 'TEMPLATE_FIELDS_VALIDATION_TYPE_UNKNOWN'
}

export enum TemplateFilter {
  TemplateFilterCompany = 'TEMPLATE_FILTER_COMPANY',
  TemplateFilterGlobal = 'TEMPLATE_FILTER_GLOBAL',
  TemplateFilterUnknown = 'TEMPLATE_FILTER_UNKNOWN'
}

export type TemplateInput = {
  content: Scalars['String']['input'];
  role: Scalars['String']['input'];
  templateName: Scalars['String']['input'];
};

export type TemplateReferenceInput = {
  templateId: Scalars['String']['input'];
  templateText: Scalars['String']['input'];
};

export type TemplateReferenceListInput = {
  templates: InputMaybe<Array<TemplateReferenceInput>>;
};

/** Generated from com/attentivemobile/contracts/concierge/service/TemplateRequest.proto */
export type TemplateRequestConnection = {
  __typename?: 'TemplateRequestConnection';
  edges: Array<TemplateRequestEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Long']['output'];
};

/** Generated from com/attentivemobile/contracts/concierge/service/TemplateRequest.proto */
export type TemplateRequestEdge = {
  __typename?: 'TemplateRequestEdge';
  cursor: Scalars['String']['output'];
  node: AgentTemplateRequest;
};

export enum TemplateRequestFilter {
  TemplateRequestFilterRequested = 'TEMPLATE_REQUEST_FILTER_REQUESTED',
  TemplateRequestFilterReviewed = 'TEMPLATE_REQUEST_FILTER_REVIEWED',
  TemplateRequestFilterUnknown = 'TEMPLATE_REQUEST_FILTER_UNKNOWN'
}

export enum TemplateRequestStatus {
  TemplateRequestStatusApproved = 'TEMPLATE_REQUEST_STATUS_APPROVED',
  TemplateRequestStatusDenied = 'TEMPLATE_REQUEST_STATUS_DENIED',
  TemplateRequestStatusRead = 'TEMPLATE_REQUEST_STATUS_READ',
  TemplateRequestStatusUnknown = 'TEMPLATE_REQUEST_STATUS_UNKNOWN',
  TemplateRequestStatusUnread = 'TEMPLATE_REQUEST_STATUS_UNREAD'
}

export enum TemplateRole {
  TemplateRoleSystem = 'TEMPLATE_ROLE_SYSTEM',
  TemplateRoleUnknown = 'TEMPLATE_ROLE_UNKNOWN',
  TemplateRoleUser = 'TEMPLATE_ROLE_USER'
}

/** Generated from com/attentivemobile/contracts/concierge/service/Template.proto */
export type TemplateSource = {
  __typename?: 'TemplateSource';
  source: Maybe<TemplateSourceSource>;
};

export type TemplateSourceSource = CompanySettingsSource | ConversationSource | FeedbackSource;

export enum TemplatingSyntaxType {
  TemplatingSyntaxTypeLiquid = 'TEMPLATING_SYNTAX_TYPE_LIQUID',
  TemplatingSyntaxTypeNonLiquid = 'TEMPLATING_SYNTAX_TYPE_NON_LIQUID',
  TemplatingSyntaxTypeUnknown = 'TEMPLATING_SYNTAX_TYPE_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/integrations/templatizedpostbacks/Contract.proto */
export type TemplatizedAuthSetting = {
  __typename?: 'TemplatizedAuthSetting';
  templatizedAuthSettings: Array<TemplatizedAuthSettingsEntry>;
};

export type TemplatizedAuthSettingInput = {
  templatizedAuthSettingsInput: InputMaybe<Array<TemplatizedAuthSettingsInputEntry>>;
};

/** Generated from com/attentivemobile/contracts/integrations/templatizedpostbacks/Contract.proto */
export type TemplatizedAuthSettingsEntry = {
  __typename?: 'TemplatizedAuthSettingsEntry';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type TemplatizedAuthSettingsInputEntry = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/integrations/templatizedpostbacks/Contract.proto */
export type TemplatizedHeaderSettingEntry = {
  __typename?: 'TemplatizedHeaderSettingEntry';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type TemplatizedHeaderSettingInputEntry = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/integrations/templatizedpostbacks/Contract.proto */
export type TemplatizedPostback = {
  __typename?: 'TemplatizedPostback';
  body: JsonString;
  companyPostbackTemplateId: Scalars['String']['output'];
  conditionalMacros: Array<JsonString>;
  endpoint: Scalars['String']['output'];
  headerSettings: Array<TemplatizedPostbackHeaderSettings>;
  httpRequestType: HttpRequestType;
  responseBody: Scalars['String']['output'];
  responseCode: Scalars['Long']['output'];
  templateName: Scalars['String']['output'];
  templatizedAuthSettings: TemplatizedAuthSetting;
};

/** Generated from com/attentivemobile/contracts/integrations/templatizedpostbacks/Contract.proto */
export type TemplatizedPostbackHeaderSettings = {
  __typename?: 'TemplatizedPostbackHeaderSettings';
  templatizedHeaderSetting: Array<TemplatizedHeaderSettingEntry>;
};

export type TemplatizedPostbackHeaderSettingsInput = {
  templatizedHeaderSettingInput: InputMaybe<Array<TemplatizedHeaderSettingInputEntry>>;
};

export type TemplatizedPostbackSettingsInput = {
  companyId: Scalars['ID']['input'];
  companyPostbackTemplateId: Scalars['String']['input'];
  enabled: Scalars['Boolean']['input'];
  integrationEvent: InputMaybe<Array<IntegrationEventTypeInput>>;
  templateName: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/integrations/templatizedpostbacks/Contract.proto */
export type TemplatizedPostbackSettingsPayload = {
  __typename?: 'TemplatizedPostbackSettingsPayload';
  responseBody: Scalars['String']['output'];
  responseCode: Scalars['Long']['output'];
};

export enum TextChannel {
  TextChannelMms = 'TEXT_CHANNEL_MMS',
  TextChannelSms = 'TEXT_CHANNEL_SMS',
  TextChannelUnknown = 'TEXT_CHANNEL_UNKNOWN'
}

export type TextContentInput = {
  body: Scalars['String']['input'];
};

export type TextGenVendorOptions = {
  vendorOptions: InputMaybe<TextGenVendorOptionsVendorOptions>;
};

export type TextGenVendorOptionsVendorOptions =
  { openaiOptions: OpenAIOptions; };

/** Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto */
export type TextMessageProperties = {
  __typename?: 'TextMessageProperties';
  body: Scalars['String']['output'];
  estimatedNumberOfMessageParts: Scalars['Int']['output'];
  personalizedLinkId: Maybe<Scalars['Long']['output']>;
  shortLinks: Array<ShortLink>;
};

export type TextMessagePropertiesRequest = {
  body: Scalars['String']['input'];
  shortLinkProperties: InputMaybe<Array<ShortLinkProperties>>;
};

export type TextMessagingService = {
  messagingServiceProvider: InputMaybe<MessagingServiceProvider>;
  sourcePhone: InputMaybe<Scalars['String']['input']>;
};

export type TextPreviewInput = {
  body: Scalars['String']['input'];
  mediaUrl: InputMaybe<Scalars['String']['input']>;
  personalizedLinkId: InputMaybe<Scalars['String']['input']>;
  shortLinkProperties: InputMaybe<Array<ShortLinkProperties>>;
};

export type TextPreviewRecipientsInput = {
  phoneNumbers: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Generated from com/attentivemobile/protobuf/common/compose/Message.proto */
export type TextTemplate = {
  __typename?: 'TextTemplate';
  body: Scalars['String']['output'];
};

export type TextTemplateInput = {
  body: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/payments/v2/Contract.proto */
export type TextToBuyConfig = {
  __typename?: 'TextToBuyConfig';
  company: Maybe<Company>;
  disableOrderConfirmMessages: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  paymentCompanyStatus: PaymentCompanyStatus;
};

export enum TextToBuyMessageType {
  TextToBuyMessageTypeCampaign = 'TEXT_TO_BUY_MESSAGE_TYPE_CAMPAIGN',
  TextToBuyMessageTypeJourney = 'TEXT_TO_BUY_MESSAGE_TYPE_JOURNEY',
  TextToBuyMessageTypeUnknown = 'TEXT_TO_BUY_MESSAGE_TYPE_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type ThirdPartyMessageId = {
  __typename?: 'ThirdPartyMessageId';
  id: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type ThirdPartyPropertyDefinition = Node & {
  __typename?: 'ThirdPartyPropertyDefinition';
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  vendor: ProfileVendor;
};

export type ThirdPartySegmentMetadata = {
  source: Scalars['String']['input'];
  thirdPartyId: Scalars['String']['input'];
};

export enum ThreadRunStatus {
  ThreadRunStatusCancelled = 'THREAD_RUN_STATUS_CANCELLED',
  ThreadRunStatusCancelling = 'THREAD_RUN_STATUS_CANCELLING',
  ThreadRunStatusCompleted = 'THREAD_RUN_STATUS_COMPLETED',
  ThreadRunStatusExpired = 'THREAD_RUN_STATUS_EXPIRED',
  ThreadRunStatusFailed = 'THREAD_RUN_STATUS_FAILED',
  ThreadRunStatusInProgress = 'THREAD_RUN_STATUS_IN_PROGRESS',
  ThreadRunStatusQueued = 'THREAD_RUN_STATUS_QUEUED',
  ThreadRunStatusRequiresAction = 'THREAD_RUN_STATUS_REQUIRES_ACTION',
  ThreadRunStatusUnknown = 'THREAD_RUN_STATUS_UNKNOWN'
}

export enum TimeComparator {
  TimeComparatorAfter = 'TIME_COMPARATOR_AFTER',
  TimeComparatorBefore = 'TIME_COMPARATOR_BEFORE',
  TimeComparatorBetween = 'TIME_COMPARATOR_BETWEEN',
  TimeComparatorDayOf = 'TIME_COMPARATOR_DAY_OF',
  TimeComparatorMonthOf = 'TIME_COMPARATOR_MONTH_OF',
  TimeComparatorOverAllTime = 'TIME_COMPARATOR_OVER_ALL_TIME',
  TimeComparatorUnknown = 'TIME_COMPARATOR_UNKNOWN',
  TimeComparatorWithin = 'TIME_COMPARATOR_WITHIN'
}

/** Generated from com/attentivemobile/protobuf/common/targeting/segmentation/output/ComparisonTypes.proto */
export type TimeComparison = {
  __typename?: 'TimeComparison';
  comparator: SearchTimeComparator;
  endTime: Maybe<Scalars['DateTime']['output']>;
  startTime: Maybe<Scalars['DateTime']['output']>;
};

export type TimeComparisonInput = {
  comparator: SearchTimeComparator;
  endTime: InputMaybe<Scalars['DateTime']['input']>;
  startTime: InputMaybe<Scalars['DateTime']['input']>;
};

export type TimeCondition = {
  comparator: TimeComparator;
  endTime: InputMaybe<Scalars['DateTime']['input']>;
  time: InputMaybe<Scalars['DateTime']['input']>;
};

/** Generated from com/attentivemobile/protobuf/common/conditions/Time.proto */
export type TimeConditionOutput = {
  __typename?: 'TimeConditionOutput';
  comparator: TimeComparator;
  endTime: Scalars['DateTime']['output'];
  time: Scalars['DateTime']['output'];
};

export type TimeDatasetFilter = {
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
};

/** Generated from com/attentivemobile/contracts/metricsapi/DatasetsContract.proto */
export type TimeDatasetValue = {
  __typename?: 'TimeDatasetValue';
  value: Scalars['DateTime']['output'];
};

/** Generated from com/attentivemobile/contracts/metricsapi/Contract.proto */
export type TimeDimension = {
  __typename?: 'TimeDimension';
  description: Maybe<Scalars['String']['output']>;
  dimensionId: Scalars['String']['output'];
  /** Only populated when the TimeDimension is returned as a MetricDefinition subfield. Denotes the earliest date the metric has data when queried by this time dimension */
  earliestAvailableDate: Maybe<Scalars['DateTime']['output']>;
  /** Only populated when the TimeDimension is returned as a MetricDefinition subfield. Denotes the granularities the metric can be grouped by with the dimension */
  granularities: Array<TimeDimensionGranularity>;
  name: Scalars['String']['output'];
};

export enum TimeDimensionGranularity {
  TimeDimensionGranularity_15Min = 'TIME_DIMENSION_GRANULARITY_15MIN',
  TimeDimensionGranularityDaily = 'TIME_DIMENSION_GRANULARITY_DAILY',
  TimeDimensionGranularityHourly = 'TIME_DIMENSION_GRANULARITY_HOURLY',
  TimeDimensionGranularityMonthly = 'TIME_DIMENSION_GRANULARITY_MONTHLY',
  TimeDimensionGranularityUnknown = 'TIME_DIMENSION_GRANULARITY_UNKNOWN',
  TimeDimensionGranularityWeekly = 'TIME_DIMENSION_GRANULARITY_WEEKLY',
  TimeDimensionGranularityYearly = 'TIME_DIMENSION_GRANULARITY_YEARLY'
}

/** Generated from com/attentivemobile/contracts/common/TimeOfDay.proto */
export type TimeOfDay = {
  __typename?: 'TimeOfDay';
  hours: Scalars['Int']['output'];
  minutes: Scalars['Int']['output'];
  nanos: Scalars['Int']['output'];
  seconds: Scalars['Int']['output'];
};

export type TimeOfDayInput = {
  hours: Scalars['Int']['input'];
  minutes: Scalars['Int']['input'];
  nanos: Scalars['Int']['input'];
  seconds: Scalars['Int']['input'];
};

/** Generated from com/attentivemobile/contracts/keywords/KeywordRuleService.proto */
export type TimeWindowRule = {
  __typename?: 'TimeWindowRule';
  created: Scalars['DateTime']['output'];
  endTime: LocalTimeMessage;
  id: Scalars['Int']['output'];
  startTime: LocalTimeMessage;
  timeZoneId: Scalars['String']['output'];
  updated: Scalars['DateTime']['output'];
};

/** Generated from com/attentivemobile/contracts/survey/poll/Contract.proto */
export type TimelineEntry = {
  __typename?: 'TimelineEntry';
  keyword: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
};

/** Generated from com/attentivemobile/protobuf/common/campaigns/Campaign.proto */
export type TimestampNoTz = {
  __typename?: 'TimestampNoTz';
  day: Scalars['Int']['output'];
  hour: Scalars['Int']['output'];
  minute: Scalars['Int']['output'];
  month: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
};

export type TimestampNoTzInput = {
  day: Scalars['Int']['input'];
  hour: Scalars['Int']['input'];
  minute: Scalars['Int']['input'];
  month: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

/** Generated from com/attentivemobile/protobuf/common/campaigns/Campaign.proto */
export type TimezoneAudienceFilter = {
  __typename?: 'TimezoneAudienceFilter';
  includeSubscribersWithoutTimezone: Scalars['Boolean']['output'];
  zoneOffset: Scalars['String']['output'];
};

export type Toast = {
  __typename?: 'Toast';
  text: Scalars['String']['output'];
  title: Maybe<Scalars['String']['output']>;
  type: Maybe<ToastType>;
};

export enum ToastType {
  Error = 'error',
  Success = 'success'
}

export type ToggleArchivePollInput = {
  companyId: Scalars['ID']['input'];
  pollId: Scalars['ID']['input'];
  shouldArchive: Scalars['Boolean']['input'];
};

/** Generated from com/attentivemobile/contracts/survey/poll/Contract.proto */
export type ToggleArchivePollPayload = {
  __typename?: 'ToggleArchivePollPayload';
  success: Scalars['Boolean']['output'];
};

/** A Solutions-owned wrapper around Creatives' EnableCreativeTemplate/DisableCreativeTemplate rpc which is not on the graph */
export type ToggleCreativeTemplateInput = {
  companyId: Scalars['ID']['input'];
  enabled: Scalars['Boolean']['input'];
  templateId: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/solutions/companyconfiguration/Configuration.proto */
export type ToggleCreativeTemplatePayload = {
  __typename?: 'ToggleCreativeTemplatePayload';
  success: Scalars['Boolean']['output'];
};

export type ToggleLegacyReportInput = {
  companyId: Scalars['ID']['input'];
  reportId: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/reports/api/Contract.proto */
export type ToggleLegacyReportPayload = {
  __typename?: 'ToggleLegacyReportPayload';
  message: Scalars['String']['output'];
};

export type ToggleLegacyScheduledReportInput = {
  scheduledReportId: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/reports/api/Contract.proto */
export type ToggleLegacyScheduledReportPayload = {
  __typename?: 'ToggleLegacyScheduledReportPayload';
  message: Scalars['String']['output'];
};

export type ToggleMfaForCompanyInput = {
  companyId: Scalars['ID']['input'];
  enabled: Scalars['Boolean']['input'];
};

/** Generated from com/attentivemobile/contracts/company-service/Contract.proto */
export type ToggleMfaForCompanyPayload = {
  __typename?: 'ToggleMfaForCompanyPayload';
  successful: Scalars['Boolean']['output'];
};

/** Generated from com/attentivemobile/contracts/event/platform/registration/service/Datastream.proto */
export type TopicToMessageOffsetsEntry = {
  __typename?: 'TopicToMessageOffsetsEntry';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/event/ecommerce/Fulfillment.proto */
export type Tracking = {
  __typename?: 'Tracking';
  trackingLink: Url;
  trackingNumber: Scalars['String']['output'];
  /** The company tracking the shipment of items in this fulfillment. */
  trackingProvider: Scalars['String']['output'];
};

export enum TrafficSplitType {
  TrafficSplitTypeCustom = 'TRAFFIC_SPLIT_TYPE_CUSTOM',
  TrafficSplitTypeEvenly = 'TRAFFIC_SPLIT_TYPE_EVENLY',
  TrafficSplitTypeUnknown = 'TRAFFIC_SPLIT_TYPE_UNKNOWN'
}

export type TriggerEmailIPAddressDomainVerificationInput = {
  domain: Scalars['String']['input'];
};

export type TriggerEmailIPAddressDomainVerificationPayload = DefaultErrorFailure | TriggerEmailIPAddressDomainVerificationSuccess;

/** Generated from com/attentivemobile/contracts/emails/management/Contract.proto */
export type TriggerEmailIPAddressDomainVerificationSuccess = {
  __typename?: 'TriggerEmailIPAddressDomainVerificationSuccess';
  domain: Scalars['String']['output'];
};

export type TriggerJourneyPreviewInput = {
  companyId: Scalars['ID']['input'];
  journeyId: Scalars['ID']['input'];
  recipient: InputMaybe<RecipientInput>;
};

/** Generated from com/attentivemobile/contracts/journeys/api/Contract.proto */
export type TriggerJourneyPreviewPayload = {
  __typename?: 'TriggerJourneyPreviewPayload';
  eventType: EventType;
};

/** Generated from com/attentivemobile/contracts/journeys/api/JourneyTrigger.proto */
export type TriggerSource = {
  __typename?: 'TriggerSource';
  oneofSource: Maybe<TriggerSourceOneofSource>;
};

export type TriggerSourceInput = {
  applicationTriggerSourceRequest: InputMaybe<ApplicationTriggerSourceInput>;
  customTriggerSourceRequest: InputMaybe<CustomTriggerSourceInput>;
};

export type TriggerSourceOneofSource = ApplicationTriggerSource | CustomTriggerSource | GlobalTriggerSource | VendorTriggerSource;

export type TriggerWorkloadInput = {
  workloadName: Scalars['String']['input'];
};

export type TriggerWorkloadPayload = DefaultErrorFailure | TriggerWorkloadSuccess;

/** Generated from com/attentivemobile/contracts/workload/management/service/runner/Contract.proto */
export type TriggerWorkloadSuccess = {
  __typename?: 'TriggerWorkloadSuccess';
  jobState: WorkloadJobState;
  workloadName: Scalars['String']['output'];
};

export type TurnOffTagAiCollectionAndReleaseTagConfigInput = {
  companyId: Scalars['ID']['input'];
};

export type TurnOffTagAiCollectionAndReleaseTagConfigPayload = DefaultErrorFailure | TurnOffTagAiCollectionAndReleaseTagConfigSuccess;

/** Generated from com/attentivemobile/contracts/tagbuildservice/Contract.proto */
export type TurnOffTagAiCollectionAndReleaseTagConfigSuccess = {
  __typename?: 'TurnOffTagAiCollectionAndReleaseTagConfigSuccess';
  releaseId: Scalars['Long']['output'];
};

export type TurnOnTagAiCollectionAndReleaseTagConfigInput = {
  companies: InputMaybe<Array<Scalars['ID']['input']>>;
  tagAiConfig: Scalars['String']['input'];
};

export type TurnOnTagAiCollectionAndReleaseTagConfigPayload = DefaultErrorFailure | TurnOnTagAiCollectionAndReleaseTagConfigSuccess;

/** Generated from com/attentivemobile/contracts/tagbuildservice/Contract.proto */
export type TurnOnTagAiCollectionAndReleaseTagConfigSuccess = {
  __typename?: 'TurnOnTagAiCollectionAndReleaseTagConfigSuccess';
  companies: Array<TurnOnTagAiCollectionCompanyResponse>;
};

/** Generated from com/attentivemobile/contracts/tagbuildservice/Contract.proto */
export type TurnOnTagAiCollectionCompanyResponse = {
  __typename?: 'TurnOnTagAiCollectionCompanyResponse';
  company: Maybe<Company>;
  error: Maybe<Scalars['String']['output']>;
  releaseId: Scalars['Long']['output'];
};

/** Generated from com/attentivemobile/protobuf/common/targeting/segmentation/output/SearchDefinitionOutput.proto */
export type TypeComparison = {
  __typename?: 'TypeComparison';
  values: Maybe<TypeComparisonValues>;
};

export type TypeComparisonInput = {
  values: InputMaybe<TypeComparisonInputValues>;
};

export type TypeComparisonInputValues =
  { booleanComparison: BooleanComparisonInput; dateComponentComparison?: never; distanceComparison?: never; existenceComparison?: never; frequencyComparison?: never; numericComparison?: never; relativeTimeComparison?: never; stringComparison?: never; timeComparison?: never; }
  |  { booleanComparison?: never; dateComponentComparison: DateComponentComparisonInput; distanceComparison?: never; existenceComparison?: never; frequencyComparison?: never; numericComparison?: never; relativeTimeComparison?: never; stringComparison?: never; timeComparison?: never; }
  |  { booleanComparison?: never; dateComponentComparison?: never; distanceComparison: DistanceComparisonInput; existenceComparison?: never; frequencyComparison?: never; numericComparison?: never; relativeTimeComparison?: never; stringComparison?: never; timeComparison?: never; }
  |  { booleanComparison?: never; dateComponentComparison?: never; distanceComparison?: never; existenceComparison: ExistenceComparisonInput; frequencyComparison?: never; numericComparison?: never; relativeTimeComparison?: never; stringComparison?: never; timeComparison?: never; }
  |  { booleanComparison?: never; dateComponentComparison?: never; distanceComparison?: never; existenceComparison?: never; frequencyComparison: FrequencyComparisonInput; numericComparison?: never; relativeTimeComparison?: never; stringComparison?: never; timeComparison?: never; }
  |  { booleanComparison?: never; dateComponentComparison?: never; distanceComparison?: never; existenceComparison?: never; frequencyComparison?: never; numericComparison: NumericComparisonInput; relativeTimeComparison?: never; stringComparison?: never; timeComparison?: never; }
  |  { booleanComparison?: never; dateComponentComparison?: never; distanceComparison?: never; existenceComparison?: never; frequencyComparison?: never; numericComparison?: never; relativeTimeComparison: RelativeTimeComparisonInput; stringComparison?: never; timeComparison?: never; }
  |  { booleanComparison?: never; dateComponentComparison?: never; distanceComparison?: never; existenceComparison?: never; frequencyComparison?: never; numericComparison?: never; relativeTimeComparison?: never; stringComparison: StringComparisonInput; timeComparison?: never; }
  |  { booleanComparison?: never; dateComponentComparison?: never; distanceComparison?: never; existenceComparison?: never; frequencyComparison?: never; numericComparison?: never; relativeTimeComparison?: never; stringComparison?: never; timeComparison: TimeComparisonInput; };

export type TypeComparisonValues = BooleanComparison | DateComponentComparison | DistanceComparison | ExistenceComparison | FrequencyComparison | NumericComparison | RelativeTimeComparison | StringComparison | TimeComparison;

/** Generated from com/attentivemobile/contracts/creative-service/creative/CreativeGraphService.proto */
export type UnarchiveCreativeFailure = {
  __typename?: 'UnarchiveCreativeFailure';
  error: Scalars['String']['output'];
};

export type UnarchiveCreativeInput = {
  companyId: Scalars['ID']['input'];
  creativeId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/creative-service/creative/CreativeGraphService.proto */
export type UnarchiveCreativePayload = {
  __typename?: 'UnarchiveCreativePayload';
  /** @deprecated deprecated */
  creative: Creative;
  response: Maybe<UnarchiveCreativePayloadResponse>;
};

export type UnarchiveCreativePayloadResponse = UnarchiveCreativeFailure | UnarchiveCreativeSuccess;

/** Generated from com/attentivemobile/contracts/creative-service/creative/CreativeGraphService.proto */
export type UnarchiveCreativeSuccess = {
  __typename?: 'UnarchiveCreativeSuccess';
  creative: Creative;
};

export type UnassignCreativesFromJourneyInput = {
  companyId: Scalars['ID']['input'];
  creativeId: InputMaybe<Array<Scalars['ID']['input']>>;
  journeyId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/journeys/api/Contract.proto */
export type UnassignCreativesFromJourneyPayload = {
  __typename?: 'UnassignCreativesFromJourneyPayload';
  journey: Journey;
};

/** Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto */
export type UndefinedMacro = {
  __typename?: 'UndefinedMacro';
  macro: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/concierge/service/Contract.proto */
export type UnhandledUsersConnection = {
  __typename?: 'UnhandledUsersConnection';
  edges: Array<UnhandledUsersEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Long']['output'];
};

/** Generated from com/attentivemobile/contracts/concierge/service/Contract.proto */
export type UnhandledUsersEdge = {
  __typename?: 'UnhandledUsersEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<User>;
};

export type UninstallTagInput = {
  companyId: Scalars['ID']['input'];
  tagUrl: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/conversations/v1/Conversation.proto */
export type UnreadCountResponse = {
  __typename?: 'UnreadCountResponse';
  clientConversationUnreadCount: Scalars['Long']['output'];
};

export type UnscheduleEdsCronJobInput = {
  taskId: Scalars['String']['input'];
  triggerType: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/eventdestination/cron/service/CronScheduler.proto */
export type UnscheduleEdsCronJobPayload = {
  __typename?: 'UnscheduleEdsCronJobPayload';
  msg: Scalars['String']['output'];
};

/** Unsubscribe mutation input. */
export type UnsubscribeInput = {
  companyId: InputMaybe<Scalars['ID']['input']>;
  /** Optional notification properties to override. */
  notification: InputMaybe<UnsubscribeNotificationInput>;
  optOutSource: InputMaybe<SubscriptionOptOutSource>;
  /** Subscriptions. */
  subscriptions: InputMaybe<Array<SubscriptionInput>>;
  /** User associated with the action. Note that this is a visitor to the site and does not need to be actively subscribed to Attentive. */
  user: UserInput;
};

/** Unsubscribe notification details. */
export type UnsubscribeNotificationInput = {
  /** Whether or not notification is disabled. */
  disabled: InputMaybe<Scalars['Boolean']['input']>;
  /** Standard language tags as defined by the ISO. Currently only supports en-US and fr-CA. Case sensitive, must match exactly. */
  language: InputMaybe<Scalars['String']['input']>;
};

/**
 * Generated from com/attentivemobile/contracts/subscriptions/api/v2/Contract.proto
 * Response for unsubscribe mutation.
 */
export type UnsubscribePayload = {
  __typename?: 'UnsubscribePayload';
  /** Unsubscribe subscription results. */
  unsubscribeResults: Array<Subscription>;
};

/** Generated from com/attentivemobile/contracts/concierge/service/Template.proto */
export type UnsupportedMacros = {
  __typename?: 'UnsupportedMacros';
  macros: Array<UserConversationMacro>;
};

export type UpdateAIBrandVoiceSettingsInput = {
  aiBrandVoiceSettings: AIBrandVoiceSettingsUpdateInput;
  companyId: Scalars['ID']['input'];
};

export type UpdateAIBrandVoiceSettingsPayload = DefaultErrorFailure | UpdateAIBrandVoiceSettingsSuccess;

/** Generated from com/attentivemobile/contracts/messaging/automation/brandvoice/AIBrandVoice.proto */
export type UpdateAIBrandVoiceSettingsSuccess = {
  __typename?: 'UpdateAIBrandVoiceSettingsSuccess';
  aiBrandVoiceSettings: AIBrandVoiceSettings;
  statusText: Scalars['String']['output'];
};

export type UpdateAIBrandVoiceSettingsV2Input = {
  aiBrandVoiceSettings: AIBrandVoiceSettingsUpdateV2Input;
  companyId: Scalars['ID']['input'];
};

export type UpdateAIBrandVoiceSettingsV2Payload = DefaultErrorFailure | UpdateAIBrandVoiceSettingsV2Success;

/** Generated from com/attentivemobile/contracts/generativeaiservice/brandvoice/AIBrandVoice.proto */
export type UpdateAIBrandVoiceSettingsV2Success = {
  __typename?: 'UpdateAIBrandVoiceSettingsV2Success';
  aiBrandVoiceSettings: AIBrandVoiceSettingsV2;
  statusText: Scalars['String']['output'];
};

export type UpdateAdminNotificationConfigurationInput = {
  adminNotificationConfiguration: AdminNotificationConfigurationInput;
  adminNotificationConfigurationId: Scalars['ID']['input'];
  deleted: Scalars['Boolean']['input'];
  notificationTemplateId: Scalars['Long']['input'];
};

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type UpdateAdminNotificationConfigurationPayload = {
  __typename?: 'UpdateAdminNotificationConfigurationPayload';
  adminNotificationConfiguration: AdminNotificationConfiguration;
};

export type UpdateAgentTemplateRequestInput = {
  id: Scalars['String']['input'];
  shortcut: InputMaybe<Scalars['String']['input']>;
  status: TemplateRequestStatus;
  text: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type UpdateApplicationWebhookInput = {
  applicationId: Scalars['ID']['input'];
  applicationWebhookId: Scalars['ID']['input'];
  clientSecret: Scalars['String']['input'];
  destination: Scalars['String']['input'];
  eventTypeIds: InputMaybe<Array<Scalars['Long']['input']>>;
  webhookStatus: WebhookStatus;
  webhookType: ApplicationWebhookType;
};

/** Generated from com/attentivemobile/contracts/externaltokens/Contract.proto */
export type UpdateApplicationWebhookPayload = {
  __typename?: 'UpdateApplicationWebhookPayload';
  applicationWebhook: ApplicationWebhook;
};

export type UpdateAttachmentRequest = {
  attachmentContextRequest: AttachmentBodyProperties;
  attachmentId: InputMaybe<Scalars['String']['input']>;
  attachmentType: AttachmentType;
};

export type UpdateAttributionSettingsInput = {
  companyId: Scalars['ID']['input'];
  emailClickWindow: InputMaybe<AttributionWindowInput>;
  emailViewWindow: InputMaybe<AttributionWindowInput>;
  filterMachineOpened: InputMaybe<Scalars['Boolean']['input']>;
  textClickWindow: InputMaybe<AttributionWindowInput>;
  textViewWindow: InputMaybe<AttributionWindowInput>;
};

/** Generated from com/attentivemobile/contracts/lift/service/Contract.proto */
export type UpdateAttributionSettingsPayload = {
  __typename?: 'UpdateAttributionSettingsPayload';
  company: Maybe<Company>;
};

export type UpdateAutoJourneyInput = {
  clientSettings: InputMaybe<AutoJourneyClientSettingsInput>;
  config: InputMaybe<AutoJourneyConfigInput>;
  id: Scalars['ID']['input'];
  status: InputMaybe<AutoJourneyStatus>;
};

/** Generated from com/attentivemobile/contracts/messaging/automation/autojourneys/AutoJourneysContract.proto */
export type UpdateAutoJourneysPayload = {
  __typename?: 'UpdateAutoJourneysPayload';
  journey: AutoJourney;
};

export type UpdateAutomationProgramInput = {
  companyId: Scalars['ID']['input'];
  couponPoolId: InputMaybe<Scalars['Long']['input']>;
  id: Scalars['ID']['input'];
  messageLinkTargetUrl: InputMaybe<Scalars['String']['input']>;
  updateEmailNotificationSettingsRequest: InputMaybe<UpdateEmailNotificationSettingsInput>;
  updateProgramCadenceRequest: InputMaybe<UpdateProgramCadenceInput>;
};

/** Generated from com/attentivemobile/contracts/messaging/automation/Contract.proto */
export type UpdateAutomationProgramPayload = {
  __typename?: 'UpdateAutomationProgramPayload';
  automationProgram: AutomationProgram;
};

export type UpdateBlockCompanyInfoInput = {
  blockCompanyInfo: InputMaybe<Array<BlockCompanyInfoInput>>;
  companyId: Scalars['ID']['input'];
};

export type UpdateBlockCompanyInfoPayload = DefaultErrorFailure | UpdateBlockCompanyInfoSuccess;

/** Generated from com/attentivemobile/contracts/personalization/service/aijourneys/onboarding/companyinfo/BlockCompanyInfo.proto */
export type UpdateBlockCompanyInfoSuccess = {
  __typename?: 'UpdateBlockCompanyInfoSuccess';
  blockCompanyInfo: Array<BlockCompanyInfo>;
  success: Scalars['Boolean']['output'];
};

export type UpdateCampaignMessageGroupInput = {
  campaignMessageGroupId: Scalars['ID']['input'];
  companyId: Scalars['ID']['input'];
  downgradePreapproved: InputMaybe<Scalars['Boolean']['input']>;
  experimentMetadata: InputMaybe<CampaignExperimentMetadataAttachmentInput>;
  messageMembers: InputMaybe<Array<CampaignMessageGroupMessageMemberInput>>;
  name: InputMaybe<Scalars['String']['input']>;
  passedTimezonesStrategy: InputMaybe<PassedTimezonesStrategy>;
  scheduleType: InputMaybe<CampaignScheduleType>;
  segments: InputMaybe<SegmentFiltersInput>;
  sendTimeExperimentMetadata: InputMaybe<SendTimeExperimentMetadataAttachmentInput>;
  smartSendingEnabled: InputMaybe<Scalars['Boolean']['input']>;
  startTime: InputMaybe<Scalars['DateTime']['input']>;
  status: InputMaybe<CampaignMessageGroupStatus>;
  tags: InputMaybe<CampaignTagsInput>;
  type: InputMaybe<CampaignMessageGroupType>;
  userId: Scalars['ID']['input'];
};

export type UpdateCircuitBreakerRateLimitInput = {
  endpoint: Scalars['String']['input'];
  isActive: Scalars['Boolean']['input'];
  rateLimit: Scalars['Long']['input'];
};

export type UpdateCircuitBreakerRateLimitPayload = DefaultErrorFailure | UpdateCircuitBreakerRateLimitSuccess;

/** Generated from com/attentivemobile/contracts/gateway/service/RateLimiting.proto */
export type UpdateCircuitBreakerRateLimitSuccess = {
  __typename?: 'UpdateCircuitBreakerRateLimitSuccess';
  endpoint: Scalars['String']['output'];
  id: Scalars['Long']['output'];
  isActive: Scalars['Boolean']['output'];
  rateLimit: Scalars['Long']['output'];
};

export type UpdateCompanyApplicationRouteRateLimitInput = {
  applicationId: Scalars['String']['input'];
  companyId: Scalars['ID']['input'];
  endpoint: Scalars['String']['input'];
  isActive: Scalars['Boolean']['input'];
  rateLimit: Scalars['Long']['input'];
};

export type UpdateCompanyApplicationRouteRateLimitPayload = DefaultErrorFailure | UpdateCompanyApplicationRouteRateLimitSuccess;

/** Generated from com/attentivemobile/contracts/gateway/service/RateLimiting.proto */
export type UpdateCompanyApplicationRouteRateLimitSuccess = {
  __typename?: 'UpdateCompanyApplicationRouteRateLimitSuccess';
  applicationId: Scalars['String']['output'];
  company: Maybe<Company>;
  endpoint: Scalars['String']['output'];
  id: Scalars['Long']['output'];
  isActive: Scalars['Boolean']['output'];
  rateLimit: Scalars['Long']['output'];
};

export type UpdateCompanyBrandVoiceInput = {
  brandVoice: CompanyBrandVoiceInput;
  companyId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/concierge/service/ConversationManager.proto */
export type UpdateCompanyBrandVoicePayload = {
  __typename?: 'UpdateCompanyBrandVoicePayload';
  company: Maybe<Company>;
};

export type UpdateCompanyEmailIntegrationInput = {
  companyId: Scalars['ID']['input'];
  integrationLevel: EmailIntegrationLevel;
};

/** Generated from com/attentivemobile/contracts/emails/management/Contract.proto */
export type UpdateCompanyEmailIntegrationPayload = {
  __typename?: 'UpdateCompanyEmailIntegrationPayload';
  emailAccount: EmailAccount;
};

export type UpdateCompanyGlobalRateLimitInput = {
  companyId: Scalars['ID']['input'];
  isActive: Scalars['Boolean']['input'];
  rateLimit: Scalars['Long']['input'];
};

export type UpdateCompanyGlobalRateLimitPayload = DefaultErrorFailure | UpdateCompanyGlobalRateLimitSuccess;

/** Generated from com/attentivemobile/contracts/gateway/service/RateLimiting.proto */
export type UpdateCompanyGlobalRateLimitSuccess = {
  __typename?: 'UpdateCompanyGlobalRateLimitSuccess';
  company: Maybe<Company>;
  id: Scalars['Long']['output'];
  isActive: Scalars['Boolean']['output'];
  rateLimit: Scalars['Long']['output'];
};

export type UpdateCompanyLinkParametersInput = {
  companyId: Scalars['ID']['input'];
  companyLinkParameterInput: InputMaybe<Array<CompanyLinkParameterInput>>;
};

export type UpdateCompanyLinkParametersPayload = DefaultErrorFailure | UpdateCompanyLinkParametersSuccess;

/** Generated from com/attentivemobile/contracts/composition/Contract.proto */
export type UpdateCompanyLinkParametersSuccess = {
  __typename?: 'UpdateCompanyLinkParametersSuccess';
  company: Maybe<Company>;
  companyLinkParameter: Array<CompanyLinkParameter>;
};

export type UpdateCompanyRegionLocalizedSettingsInput = {
  localizedSettings: InputMaybe<Array<LocalizedSettingsInput>>;
  regionId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/company-service/region/Region.proto */
export type UpdateCompanyRegionLocalizedSettingsPayload = {
  __typename?: 'UpdateCompanyRegionLocalizedSettingsPayload';
  region: Region;
};

export type UpdateCompanySettingsInput = {
  companyId: Scalars['ID']['input'];
  companySettings: CompanySettingsInput;
};

/** Generated from com/attentivemobile/contracts/company-service/companysetting/CompanySetting.proto */
export type UpdateCompanySettingsPayload = {
  __typename?: 'UpdateCompanySettingsPayload';
  company: Maybe<Company>;
};

export type UpdateCompanyV2Input = {
  /** the address of the company */
  address: InputMaybe<AddressInput>;
  /** the company's unique attentive domain e.g. siren.attentive.com */
  attentiveDomain: InputMaybe<Scalars['String']['input']>;
  /** the automated response to subscriber messages without keywords */
  autoResponse: InputMaybe<Scalars['String']['input']>;
  /** whether to send automatic auto responses for this company */
  autoResponseActive: InputMaybe<Scalars['Boolean']['input']>;
  /** a url pointing to the shopping cart on the company's website */
  cartLink: InputMaybe<Scalars['String']['input']>;
  /** the click through attribution window in days for this company */
  clickThroughAttributionWindow: InputMaybe<Scalars['Long']['input']>;
  companyId: Scalars['ID']['input'];
  /** the type of the company */
  companyType: InputMaybe<CompanyType>;
  /** the message subscribers will get to confirm opt in */
  confirmOptIn: InputMaybe<Scalars['String']['input']>;
  /** the main country of traffic (as opposed to the address country) */
  country: InputMaybe<Scalars['String']['input']>;
  /** the creative holdout rate of the company */
  creativeHoldoutRate: InputMaybe<Scalars['Float']['input']>;
  /** the desktop URL of the company */
  desktopUrl: InputMaybe<Scalars['String']['input']>;
  /** the display name of the company */
  displayName: InputMaybe<Scalars['String']['input']>;
  /** the company's domain */
  domain: InputMaybe<Scalars['String']['input']>;
  /** the contact email for this company */
  email: InputMaybe<Scalars['String']['input']>;
  /** the external id of the company */
  externalId: InputMaybe<Scalars['Long']['input']>;
  /** the response subscribers will get if they text HELP */
  helpResponse: InputMaybe<Scalars['String']['input']>;
  /** the message holdout rate of the company */
  messageHoldoutRate: InputMaybe<Scalars['Float']['input']>;
  /** the SID of the messaging service of this company */
  messagingServiceSid: InputMaybe<Scalars['String']['input']>;
  /** is MFA enabled for this company */
  mfaEnabled: InputMaybe<Scalars['Boolean']['input']>;
  /** the name of the company */
  name: InputMaybe<Scalars['String']['input']>;
  /** whether non-Attentive links are allowed in messages */
  nonAttentiveMessageLinksAllowed: InputMaybe<Scalars['Boolean']['input']>;
  /** the contact phone of the company */
  phone: InputMaybe<Scalars['String']['input']>;
  /** a url pointing to the company's privacy policy */
  privacyLink: InputMaybe<Scalars['String']['input']>;
  /** the end time of quiet hours for this company */
  quietHoursEnd: InputMaybe<Scalars['String']['input']>;
  /** the start time of quiet hours for this company */
  quietHoursStart: InputMaybe<Scalars['String']['input']>;
  /** whether we reply to messages if the subscriber is already opted out */
  replyWhenOptedOut: InputMaybe<Scalars['Boolean']['input']>;
  /** the id of the corresponding Salesforce account */
  salesforceAccountId: InputMaybe<Scalars['String']['input']>;
  /** the id of the corresponding Salesforce opportunity */
  salesforceOpportunityId: InputMaybe<Scalars['String']['input']>;
  /** the service begin message for this company */
  serviceBeginMessage: InputMaybe<Scalars['String']['input']>;
  /** the service end message for this company */
  serviceEndMessage: InputMaybe<Scalars['String']['input']>;
  /** the service verification keyword for this company */
  serviceVerificationKeyword: InputMaybe<Scalars['String']['input']>;
  /** the settings url of the company */
  settingsUrl: InputMaybe<Scalars['String']['input']>;
  /** the response subscribers will get if they text START */
  startResponse: InputMaybe<Scalars['String']['input']>;
  /** the status of the company */
  status: InputMaybe<Scalars['String']['input']>;
  /** the response subscribers will get if they text STOP */
  stopResponse: InputMaybe<Scalars['String']['input']>;
  /** a phrase such as 'stop 2 stop' to append at the end of messages for compliance */
  stopToStopPhrase: InputMaybe<Scalars['String']['input']>;
  /** the message subscribers will get when they first subscribe */
  subscribedResponse: InputMaybe<Scalars['String']['input']>;
  /** a url pointing to the company's terms and conditions */
  termsLink: InputMaybe<Scalars['String']['input']>;
  /** the timezone of the company */
  timezone: InputMaybe<Scalars['String']['input']>;
  /** whether TTJ double opt in is on for this company */
  ttjDoubleOptIn: InputMaybe<Scalars['Boolean']['input']>;
  /** the view through attribution window in days for this company */
  viewThroughAttributionWindow: InputMaybe<Scalars['Long']['input']>;
};

export type UpdateCompanyV2Payload = DefaultErrorFailure | UpdateCompanyV2Success;

/** Generated from com/attentivemobile/contracts/company-service/Contract.proto */
export type UpdateCompanyV2Success = {
  __typename?: 'UpdateCompanyV2Success';
  company: Maybe<Company>;
};

export type UpdateCompanyVendorIntegrationStatusInput = {
  companyId: Scalars['ID']['input'];
  status: CompanyVendorIntegrationStatus;
  vendor: Vendor;
};

export type UpdateCompanyVendorIntegrationStatusPayload = DefaultErrorFailure | UpdateCompanyVendorIntegrationStatusSuccess;

/** Generated from com/attentivemobile/contracts/integrations/v2/Contract.proto */
export type UpdateCompanyVendorIntegrationStatusSuccess = {
  __typename?: 'UpdateCompanyVendorIntegrationStatusSuccess';
  companyVendorIntegration: CompanyVendorIntegration;
};

export type UpdateComposeCampaignInput = {
  campaignId: Scalars['ID']['input'];
  campaignMessageGroupIdOrder: InputMaybe<Array<Scalars['ID']['input']>>;
  campaignStatus: InputMaybe<ComposeCampaignStatus>;
  companyId: Scalars['ID']['input'];
  name: InputMaybe<Scalars['String']['input']>;
  tags: InputMaybe<CampaignTagsInput>;
  type: InputMaybe<ComposeCampaignType>;
  userId: Scalars['ID']['input'];
};

export type UpdateCompositionMessageInput = {
  attachments: InputMaybe<Array<UpdateAttachmentRequest>>;
  companyId: Scalars['ID']['input'];
  emailProperties: InputMaybe<EmailPropertiesRequest>;
  ignoreFatigue: InputMaybe<Scalars['Boolean']['input']>;
  mediaUrls: InputMaybe<Array<UrlInput>>;
  messageId: Scalars['ID']['input'];
  messageTags: InputMaybe<Array<ComposeMessageTagInput>>;
  name: Scalars['String']['input'];
  rbmProperties: InputMaybe<RbmPropertiesInput>;
  status: ComposeMessageStatus;
  textMessageProperties: InputMaybe<TextMessagePropertiesRequest>;
  updatedUtmParameters: InputMaybe<UtmParametersInput>;
  validationRulesToSkip: InputMaybe<Array<ValidationRuleToSkipInput>>;
};

/** Generated from com/attentivemobile/contracts/composition/Contract.proto */
export type UpdateCompositionMessagePayload = {
  __typename?: 'UpdateCompositionMessagePayload';
  updateMessageResult: UpdateMessageResult;
};

export type UpdateConciergeCompanyNotesInput = {
  companyId: Scalars['ID']['input'];
  notes: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/concierge/service/Notes.proto */
export type UpdateConciergeCompanyNotesPayload = {
  __typename?: 'UpdateConciergeCompanyNotesPayload';
  company: Maybe<Company>;
};

export type UpdateConciergeCompanyPromotionInput = {
  end: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['ID']['input'];
  limitedTime: InputMaybe<Scalars['Boolean']['input']>;
  note: Scalars['String']['input'];
  start: InputMaybe<Scalars['DateTime']['input']>;
  startPromoting: InputMaybe<Scalars['DateTime']['input']>;
  title: Scalars['String']['input'];
  type: ConciergeCompanyPromotionType;
};

/** Generated from com/attentivemobile/contracts/concierge/service/CompanyPromotions.proto */
export type UpdateConciergeCompanyPromotionPayload = {
  __typename?: 'UpdateConciergeCompanyPromotionPayload';
  company: Maybe<Company>;
};

export type UpdateConciergeJourneyControlsInput = {
  companyId: Scalars['ID']['input'];
  conciergeIncentiveConfigurationInput: ConciergeIncentiveConfigurationInput;
  isConciergeEnabled: Scalars['Boolean']['input'];
  journeyId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/concierge/service/ConversationManager.proto */
export type UpdateConciergeJourneyControlsPayload = {
  __typename?: 'UpdateConciergeJourneyControlsPayload';
  journey: Maybe<Journey>;
};

export type UpdateConciergeSelfServeSettingsAgentPreferencesInput = {
  agentName: Scalars['String']['input'];
  companyId: Scalars['ID']['input'];
  couponSetId: InputMaybe<Scalars['Long']['input']>;
  customUrlParametersInput: InputMaybe<Array<CustomUrlParametersInputEntry>>;
  isUtmTrackingEnabled: Scalars['Boolean']['input'];
  messageTone: MessageTone;
  utmCampaign: InputMaybe<Scalars['String']['input']>;
};

export type UpdateConciergeSelfServeSettingsCustomerSupportInput = {
  additionalUrls: InputMaybe<Array<Scalars['String']['input']>>;
  companyId: Scalars['ID']['input'];
  customerServiceEmail: Scalars['String']['input'];
  escalationEmails: InputMaybe<Array<Scalars['String']['input']>>;
  faqUrl: InputMaybe<Scalars['String']['input']>;
  returnPolicyUrl: Scalars['String']['input'];
};

export type UpdateConciergeSelfServeSettingsJourneysInput = {
  companyId: Scalars['ID']['input'];
  siteVisitorJourneyId: Scalars['Long']['input'];
};

/** Generated from com/attentivemobile/contracts/concierge/service/SelfServe.proto */
export type UpdateConciergeSelfServeSettingsJourneysPayload = {
  __typename?: 'UpdateConciergeSelfServeSettingsJourneysPayload';
  company: Maybe<Company>;
};

/** Generated from com/attentivemobile/contracts/concierge/service/SelfServe.proto */
export type UpdateConciergeSelfServeSettingsPayload = {
  __typename?: 'UpdateConciergeSelfServeSettingsPayload';
  company: Maybe<Company>;
};

export type UpdateConciergeSelfServeSettingsShippingInput = {
  companyId: Scalars['ID']['input'];
  domesticShippingTimeframe: Scalars['String']['input'];
  freeShippingPricePoint: InputMaybe<Scalars['String']['input']>;
  internationalShippingTimeframe: InputMaybe<Scalars['String']['input']>;
  processingTimeBeforeShipping: Scalars['String']['input'];
  shippingProviderOther: InputMaybe<Scalars['String']['input']>;
  shippingProviders: InputMaybe<Array<ShippingProvider>>;
};

export type UpdateConciergeSelfServeSettingsSignUpInput = {
  companyId: Scalars['ID']['input'];
  conciergeRegistrationName: Scalars['String']['input'];
};

export type UpdateConciergeSelfServeSettingsSubmissionInput = {
  companyId: Scalars['ID']['input'];
};

export type UpdateConciergeSettingsInput = {
  companyId: Scalars['ID']['input'];
  isConciergeEnabled: Scalars['Boolean']['input'];
  isRouteAllInboundsEnabled: InputMaybe<Scalars['Boolean']['input']>;
};

/** Generated from com/attentivemobile/contracts/concierge/service/ConversationManager.proto */
export type UpdateConciergeSettingsPayload = {
  __typename?: 'UpdateConciergeSettingsPayload';
  company: Maybe<Company>;
};

export type UpdateConversationsSettingsInput = {
  companyId: Scalars['ID']['input'];
  escalationEmails: InputMaybe<Array<Scalars['String']['input']>>;
  incentiveConfiguration: ConciergeIncentiveConfigurationInput;
  isConciergeForCampaignsEnabled: Scalars['Boolean']['input'];
  isRouteAllInboundsEnabled: Scalars['Boolean']['input'];
  utmConfiguration: UtmConfigurationInput;
};

/** Generated from com/attentivemobile/contracts/concierge/service/ConversationManager.proto */
export type UpdateConversationsSettingsPayload = {
  __typename?: 'UpdateConversationsSettingsPayload';
  company: Maybe<Company>;
};

export type UpdateCouponSetInput = {
  autoRefresh: InputMaybe<Scalars['Boolean']['input']>;
  codeTemplate: InputMaybe<Scalars['String']['input']>;
  couponUrlTemplate: InputMaybe<Scalars['String']['input']>;
  couponValueInput: InputMaybe<CouponValueInput>;
  description: InputMaybe<Scalars['String']['input']>;
  distributionTtlInSeconds: InputMaybe<Scalars['Long']['input']>;
  distributionTtlUnit: InputMaybe<DistributionUnit>;
  externalId: InputMaybe<Scalars['Long']['input']>;
  id: Scalars['ID']['input'];
  name: InputMaybe<Scalars['String']['input']>;
  staticCode: InputMaybe<Scalars['String']['input']>;
  status: InputMaybe<CouponSetStatus>;
};

/** Generated from com/attentivemobile/contracts/coupon/CouponContract.proto */
export type UpdateCouponSetPayload = {
  __typename?: 'UpdateCouponSetPayload';
  updated: CouponSet;
};

export type UpdateCouponUploadInput = {
  couponUploadId: Scalars['ID']['input'];
  distributionStart: InputMaybe<Scalars['DateTime']['input']>;
  distributionStop: InputMaybe<Scalars['DateTime']['input']>;
};

/** Generated from com/attentivemobile/contracts/coupon/Contract.proto */
export type UpdateCouponUploadPayload = {
  __typename?: 'UpdateCouponUploadPayload';
  couponUpload: Maybe<CouponUpload>;
};

export type UpdateCreateSupportTicketStepInput = {
  customerServiceGroupId: Scalars['String']['input'];
  vendorId: Scalars['String']['input'];
  vendorName: Scalars['String']['input'];
};

export type UpdateCreativeContentInput = {
  companyId: Scalars['ID']['input'];
  content: JsonStringInput;
  creativeContentId: Scalars['ID']['input'];
};

export type UpdateCreativeContentPayload = DefaultErrorFailure | UpdateCreativeContentSuccess;

/** Generated from com/attentivemobile/contracts/creative-service/creative/CreativeContentService.proto */
export type UpdateCreativeContentSuccess = {
  __typename?: 'UpdateCreativeContentSuccess';
  creativeContent: CreativeContent;
};

export type UpdateCreativeExperimentGroupTrafficSplitRequest = {
  experimentGroupId: Scalars['String']['input'];
  percent: Scalars['String']['input'];
};

export type UpdateCreativeExperimentTrafficSplitInput = {
  companyId: Scalars['ID']['input'];
  experimentGroups: InputMaybe<Array<UpdateCreativeExperimentGroupTrafficSplitRequest>>;
  experimentId: Scalars['String']['input'];
};

export type UpdateCreativeExperimentTrafficSplitPayload = DefaultErrorFailure | UpdateCreativeExperimentTrafficSplitSuccess;

/** Generated from com/attentivemobile/contracts/creative-service/experiment/CreativeExperimentService.proto */
export type UpdateCreativeExperimentTrafficSplitSuccess = {
  __typename?: 'UpdateCreativeExperimentTrafficSplitSuccess';
  experimentId: Scalars['String']['output'];
};

export type UpdateCreativeInput = {
  companyId: Scalars['ID']['input'];
  creativeId: Scalars['ID']['input'];
  delete: InputMaybe<DeleteCreativePropertiesInput>;
  update: InputMaybe<UpdateCreativePropertiesInput>;
};

/** Generated from com/attentivemobile/contracts/creative-service/creative/CreativeGraphService.proto */
export type UpdateCreativePayload = {
  __typename?: 'UpdateCreativePayload';
  creativeOrError: Maybe<UpdateCreativePayloadCreativeOrError>;
};

export type UpdateCreativePayloadCreativeOrError = Creative | CreativeMutationError;

/** Optionally set certain values on a Creative */
export type UpdateCreativePropertiesInput = {
  alreadySubscribedMessageId: InputMaybe<Scalars['ID']['input']>;
  config: InputMaybe<JsonStringInput>;
  couponSetId: InputMaybe<Scalars['ID']['input']>;
  customProperties: InputMaybe<Array<CreativeCustomPropertyInput>>;
  externalName: InputMaybe<Scalars['String']['input']>;
  language: InputMaybe<Scalars['String']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  rules: InputMaybe<JsonStringInput>;
  smsBody: InputMaybe<Scalars['String']['input']>;
  src: InputMaybe<Scalars['String']['input']>;
  status: InputMaybe<CreativeStatus>;
};

export type UpdateCreativeScheduleAndMembersInput = {
  members: InputMaybe<Array<CreateOrUpdateCreativeScheduleMemberInput>>;
  schedule: UpdateCreativeScheduleInput;
  scheduleId: Scalars['ID']['input'];
};

export type UpdateCreativeScheduleInput = {
  companyId: Scalars['ID']['input'];
  completed: InputMaybe<Scalars['DateTime']['input']>;
  description: InputMaybe<Scalars['String']['input']>;
  endTime: InputMaybe<Scalars['DateTime']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  scheduledEvent: Scalars['Boolean']['input'];
  startTime: InputMaybe<Scalars['DateTime']['input']>;
  timezone: InputMaybe<Scalars['String']['input']>;
  userId: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCreativeScheduleWorkflowInput = {
  applyStatusPermanently: Scalars['Boolean']['input'];
  companyId: Scalars['ID']['input'];
  endTime: InputMaybe<Scalars['DateTime']['input']>;
  name: Scalars['String']['input'];
  /** @deprecated deprecated */
  scheduleId: InputMaybe<Scalars['ID']['input']>;
  scheduleMembers: InputMaybe<Array<CreativeScheduleMemberDetails>>;
  startTime: Scalars['DateTime']['input'];
  timezone: InputMaybe<Scalars['String']['input']>;
  workflowId: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateCreativeScheduleWorkflowPayload = DefaultErrorFailure | UpdateCreativeScheduleWorkflowSuccess;

/** Generated from com/attentivemobile/contracts/creative/workflow/service/workflows/CreativeSchedulesWorkflowService.proto */
export type UpdateCreativeScheduleWorkflowSuccess = {
  __typename?: 'UpdateCreativeScheduleWorkflowSuccess';
  schedule: CreativeWorkflowSchedule;
};

export type UpdateCustomCompanyKeywordInput = {
  attributeName: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['ID']['input'];
  keyword: InputMaybe<Scalars['String']['input']>;
  keywordId: Scalars['ID']['input'];
  keywordOrdinal: InputMaybe<Scalars['Int']['input']>;
  mediaUrl: InputMaybe<Scalars['String']['input']>;
  messageText: InputMaybe<Scalars['String']['input']>;
  shouldForceSend: InputMaybe<Scalars['Boolean']['input']>;
  shouldShortenLink: InputMaybe<Scalars['Boolean']['input']>;
  subscriptionType: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCustomCompanyKeywordPayload = DefaultErrorFailure | UpdateCustomCompanyKeywordSuccess;

/** Generated from com/attentivemobile/contracts/keywords/KeywordService.proto */
export type UpdateCustomCompanyKeywordSuccess = {
  __typename?: 'UpdateCustomCompanyKeywordSuccess';
  keyword: Maybe<CustomCompanyKeyword>;
};

export type UpdateCustomEventKeyInput = {
  companyId: Scalars['ID']['input'];
  customEventKeyId: Scalars['ID']['input'];
  updatedFields: PostCustomEventKeyInput;
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type UpdateCustomEventKeyPayload = {
  __typename?: 'UpdateCustomEventKeyPayload';
  customEventKey: CustomEventKey;
};

export type UpdateCustomKeywordInput = {
  companyId: Scalars['ID']['input'];
  isActive: InputMaybe<Scalars['Boolean']['input']>;
  isTransactional: InputMaybe<Scalars['Boolean']['input']>;
  keyword: Scalars['String']['input'];
  keywordId: Scalars['ID']['input'];
  response: Scalars['String']['input'];
  subscriberTag: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/tactical/companycustomkeywords/CompanyCustomKeywordService.proto */
export type UpdateCustomKeywordPayload = {
  __typename?: 'UpdateCustomKeywordPayload';
  customKeyword: CustomKeyword;
};

export type UpdateCustomPropertyDefinitionInput = {
  companyId: Scalars['ID']['input'];
  customPropertyDefinitionId: Scalars['ID']['input'];
  updatedFields: PostCustomPropertyDefinitionInput;
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type UpdateCustomPropertyDefinitionPayload = {
  __typename?: 'UpdateCustomPropertyDefinitionPayload';
  customPropertyDefinition: CustomPropertyDefinition;
};

export type UpdateCustomReportInput = {
  companyId: Scalars['ID']['input'];
  deliveryFrequencies: InputMaybe<ReportDeliveryFrequenciesUpdateInput>;
  filters: InputMaybe<ReportFiltersUpdateInput>;
  groupings: InputMaybe<ReportGroupingsUpdateInput>;
  metrics: InputMaybe<ReportMetricsUpdateInput>;
  name: InputMaybe<Scalars['String']['input']>;
  recipients: InputMaybe<Array<Scalars['ID']['input']>>;
  reportId: Scalars['String']['input'];
  selectedChart: InputMaybe<ReportChart>;
  selectedMetricId: InputMaybe<Scalars['String']['input']>;
  sftpEnabled: InputMaybe<Scalars['Boolean']['input']>;
  /** @deprecated deprecated */
  summaryMetricIds: InputMaybe<Array<Scalars['String']['input']>>;
  summaryMetrics: InputMaybe<SummaryMetricsInput>;
  tableSortOrders: InputMaybe<Array<ReportMetricsOrderingInput>>;
};

/** Generated from com/attentivemobile/contracts/reports/api/Contract.proto */
export type UpdateCustomReportPayload = {
  __typename?: 'UpdateCustomReportPayload';
  report: Report;
};

export type UpdateDatastreamParallelismInput = {
  datastreamName: Scalars['String']['input'];
  filterParallelism: Scalars['Int']['input'];
  kinesisSinkMaxBufferTimeMillis: Scalars['Int']['input'];
  kinesisSinkParallelism: Scalars['Int']['input'];
  kinesisSinkRateLimitPercentage: Scalars['Int']['input'];
  kinesisSourceParallelism: Scalars['Int']['input'];
  pulsarSinkParallelism: Scalars['Int']['input'];
  pulsarSourceParallelism: Scalars['Int']['input'];
  s3SinkParallelism: Scalars['Int']['input'];
};

/** Generated from com/attentivemobile/contracts/event/platform/registration/service/Contract.proto */
export type UpdateDatastreamParallelismPayload = {
  __typename?: 'UpdateDatastreamParallelismPayload';
  datastreamName: Scalars['String']['output'];
  result: OperationResult;
};

export type UpdateDatastreamsParallelismInput = {
  datastreamName: InputMaybe<Array<Scalars['String']['input']>>;
  filterParallelism: Scalars['Int']['input'];
  kinesisSinkMaxBufferTimeMillis: Scalars['Int']['input'];
  kinesisSinkParallelism: Scalars['Int']['input'];
  kinesisSinkRateLimitPercentage: Scalars['Int']['input'];
  kinesisSourceParallelism: Scalars['Int']['input'];
  pulsarSinkParallelism: Scalars['Int']['input'];
  pulsarSourceParallelism: Scalars['Int']['input'];
  s3SinkParallelism: Scalars['Int']['input'];
};

export type UpdateDatastreamsParallelismPayload = DefaultErrorFailure | UpdateDatastreamsParallelismSuccess;

/** Generated from com/attentivemobile/contracts/event/platform/registration/service/Contract.proto */
export type UpdateDatastreamsParallelismSuccess = {
  __typename?: 'UpdateDatastreamsParallelismSuccess';
  result: Scalars['String']['output'];
};

export type UpdateDelayStepInput = {
  durationSeconds: Scalars['Long']['input'];
  durationUnit: JourneyDurationUnit;
};

export type UpdateDismissibleModalInput = {
  description: InputMaybe<Scalars['String']['input']>;
  endTime: Scalars['DateTime']['input'];
  featureFlags: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['ID']['input'];
  imageUrl: InputMaybe<Scalars['String']['input']>;
  modalId: Scalars['String']['input'];
  primaryButtonAction: ButtonAction;
  primaryButtonText: Scalars['String']['input'];
  primaryButtonUrl: InputMaybe<Scalars['String']['input']>;
  secondaryButtonAction: InputMaybe<ButtonAction>;
  secondaryButtonText: InputMaybe<Scalars['String']['input']>;
  secondaryButtonUrl: InputMaybe<Scalars['String']['input']>;
  startTime: Scalars['DateTime']['input'];
  title: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type UpdateDismissibleModalPayload = {
  __typename?: 'UpdateDismissibleModalPayload';
  modal: DismissibleModal;
};

/** Generated from com/attentivemobile/contracts/emails/management/Contract.proto */
export type UpdateEmailAccountPayload = {
  __typename?: 'UpdateEmailAccountPayload';
  emailAccount: EmailAccount;
};

export type UpdateEmailAccountPoolsInput = {
  campaignsPoolId: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  transactionalPoolId: InputMaybe<Scalars['ID']['input']>;
  triggeredPoolId: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateEmailIPAddressInput = {
  domain: InputMaybe<Scalars['String']['input']>;
  ipAddress: Scalars['String']['input'];
  ipPoolIds: InputMaybe<Array<Scalars['Long']['input']>>;
};

export type UpdateEmailIPAddressPayload = DefaultErrorFailure | UpdateEmailIPAddressSuccess;

/** Generated from com/attentivemobile/contracts/emails/management/Contract.proto */
export type UpdateEmailIPAddressSuccess = {
  __typename?: 'UpdateEmailIPAddressSuccess';
  ipAddress: EmailIPAddress;
};

export type UpdateEmailIPPoolInput = {
  assignedCompanies: InputMaybe<EmailPoolAssignedCompanies>;
  assignedIpAddresses: InputMaybe<EmailPoolAssignedIPAddresses>;
  companyId: InputMaybe<Scalars['Long']['input']>;
  id: Scalars['ID']['input'];
  name: InputMaybe<Scalars['String']['input']>;
  shared: InputMaybe<Scalars['Boolean']['input']>;
  type: InputMaybe<IPPoolType>;
};

/** Generated from com/attentivemobile/contracts/emails/management/Contract.proto */
export type UpdateEmailIPPoolPayload = {
  __typename?: 'UpdateEmailIPPoolPayload';
  ipPool: EmailIPPool;
};

export type UpdateEmailNotificationSettingsInput = {
  updateEmailRecipientRequests: InputMaybe<Array<UpdateEmailRecipientInput>>;
};

export type UpdateEmailRecipientInput = {
  email: Scalars['String']['input'];
};

export type UpdateEmailSettingsInput = {
  bccEmails: InputMaybe<Array<Scalars['String']['input']>>;
  ccEmails: InputMaybe<Array<Scalars['String']['input']>>;
  companyId: Scalars['ID']['input'];
  enableCopyRecipientsForCampaigns: InputMaybe<Scalars['Boolean']['input']>;
  enableCopyRecipientsForJourneys: InputMaybe<Scalars['Boolean']['input']>;
  linkSubdomain: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/emails/management/Contract.proto */
export type UpdateEmailSettingsPayload = {
  __typename?: 'UpdateEmailSettingsPayload';
  company: Maybe<Company>;
  emailSettings: EmailSettings;
};

export type UpdateEncryptionInput = {
  etlConfigId: Scalars['Long']['input'];
  isEncrypted: InputMaybe<Scalars['Boolean']['input']>;
  pgpKeyString: InputMaybe<Scalars['String']['input']>;
};

/** Generated from com/attentivemobile/contracts/solutions/sftp/EtlConfig.proto */
export type UpdateEncryptionPayload = {
  __typename?: 'UpdateEncryptionPayload';
  message: Scalars['String']['output'];
};

export type UpdateEscalationEmailsInput = {
  companyId: Scalars['ID']['input'];
  emails: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Generated from com/attentivemobile/contracts/concierge/service/Escalation.proto */
export type UpdateEscalationEmailsPayload = {
  __typename?: 'UpdateEscalationEmailsPayload';
  company: Maybe<Company>;
};

export type UpdateEtlConfigCompaniesData = {
  companyId: Scalars['ID']['input'];
  isEnabled: Scalars['Boolean']['input'];
};

export type UpdateEtlConfigCompaniesInput = {
  etlConfigId: Scalars['Long']['input'];
  updateEtlConfigCompaniesData: InputMaybe<Array<UpdateEtlConfigCompaniesData>>;
};

export type UpdateEtlConfigCompaniesPayload = DefaultErrorFailure | UpdateEtlConfigCompaniesSuccess;

/** Generated from com/attentivemobile/contracts/solutions/sftp/EtlConfig.proto */
export type UpdateEtlConfigCompaniesSuccess = {
  __typename?: 'UpdateEtlConfigCompaniesSuccess';
  message: Scalars['String']['output'];
};

export type UpdateExperimentStepInput = {
  childrenSteps: InputMaybe<Array<UpdateVariantStepInput>>;
  trafficSplitType: TrafficSplitType;
};

export type UpdateFilterInput = {
  filter: NotificationFilterInput;
};

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type UpdateFilterPayload = {
  __typename?: 'UpdateFilterPayload';
  filter: NotificationFilter;
};

export type UpdateImpactMetadataInput = {
  datastreamName: Scalars['String']['input'];
  impactMetadata: DatastreamImpactMetadataInput;
};

/** Generated from com/attentivemobile/contracts/event/platform/registration/service/Contract.proto */
export type UpdateImpactMetadataPayload = {
  __typename?: 'UpdateImpactMetadataPayload';
  datastreamName: Scalars['String']['output'];
};

export type UpdateIntegrationVendorSettingsInput = {
  companyVendorIntegrationId: Scalars['ID']['input'];
  features: FeatureMapInput;
  fields: InputMaybe<Array<FieldsEntry>>;
};

export type UpdateJourneyInput = {
  companyId: Scalars['ID']['input'];
  description: InputMaybe<Scalars['String']['input']>;
  journeyId: Scalars['ID']['input'];
  name: InputMaybe<Scalars['String']['input']>;
};

/** Generated from com/attentivemobile/contracts/journeys/api/Contract.proto */
export type UpdateJourneyPayload = {
  __typename?: 'UpdateJourneyPayload';
  journey: Journey;
};

export type UpdateJourneyRevisionApprovalStatusInput = {
  approvalStatus: ApprovalStatus;
  companyId: Scalars['ID']['input'];
  journeyId: Scalars['ID']['input'];
  revisionApprovalHash: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/journeys/api/Contract.proto */
export type UpdateJourneyRevisionApprovalStatusPayload = {
  __typename?: 'UpdateJourneyRevisionApprovalStatusPayload';
  journey: Journey;
};

export type UpdateJourneyRevisionInput = {
  companyId: Scalars['ID']['input'];
  eligibilityDurationSeconds: Scalars['Long']['input'];
  eligibilityDurationUnit: JourneyDurationUnit;
  revisionId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/journeys/api/JourneyRevision.proto */
export type UpdateJourneyRevisionPayload = {
  __typename?: 'UpdateJourneyRevisionPayload';
  journeyRevision: JourneyRevision;
};

export type UpdateJourneyScheduleInput = {
  companyId: Scalars['ID']['input'];
  journeyScheduleId: Scalars['ID']['input'];
  name: InputMaybe<Scalars['String']['input']>;
  startTime: InputMaybe<Scalars['DateTime']['input']>;
};

/** Generated from com/attentivemobile/contracts/journeys/api/JourneySchedule.proto */
export type UpdateJourneySchedulePayload = {
  __typename?: 'UpdateJourneySchedulePayload';
  updatedJourneySchedule: JourneySchedule;
};

export type UpdateJourneyStatusInput = {
  companyId: Scalars['ID']['input'];
  doEvictSubscribers: Scalars['Boolean']['input'];
  isActive: Scalars['Boolean']['input'];
  journeyId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/journeys/api/Contract.proto */
export type UpdateJourneyStatusPayload = {
  __typename?: 'UpdateJourneyStatusPayload';
  journey: Journey;
};

export type UpdateJourneyTriggerInput = {
  description: Scalars['String']['input'];
  journeyTriggerId: Scalars['ID']['input'];
  maxJourneyLimit: InputMaybe<Scalars['Long']['input']>;
  name: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/journeys/api/JourneyTrigger.proto */
export type UpdateJourneyTriggerPayload = {
  __typename?: 'UpdateJourneyTriggerPayload';
  journeyTrigger: JourneyTrigger;
};

export type UpdateJourneyTriggerStatusInput = {
  companyId: Scalars['ID']['input'];
  enabled: Scalars['Boolean']['input'];
  journeyTriggerId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/journeys/api/JourneyTrigger.proto */
export type UpdateJourneyTriggerStatusPayload = {
  __typename?: 'UpdateJourneyTriggerStatusPayload';
  company: Maybe<Company>;
};

export type UpdateLegacyScheduledReportRecipientInput = {
  companyId: Scalars['ID']['input'];
  newUserId: Scalars['String']['input'];
  scheduledReportId: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/reports/api/Contract.proto */
export type UpdateLegacyScheduledReportRecipientPayload = {
  __typename?: 'UpdateLegacyScheduledReportRecipientPayload';
  message: Scalars['String']['output'];
};

export type UpdateLegacyTagConfigInput = {
  companyId: Scalars['ID']['input'];
  newConfig: InputMaybe<Array<LegacyTagConfigUpdateInput>>;
  updateTagVersion: InputMaybe<Scalars['Boolean']['input']>;
};

/** Generated from com/attentivemobile/contracts/tagbuildservice/Contract.proto */
export type UpdateLegacyTagConfigPayload = {
  __typename?: 'UpdateLegacyTagConfigPayload';
  rowsUpdated: Scalars['Long']['output'];
  updatedConfig: Array<LegacyTagConfig>;
};

/** Generated from com/attentivemobile/contracts/composition/Contract.proto */
export type UpdateMessageFailure = {
  __typename?: 'UpdateMessageFailure';
  errors: Array<UpdateMessageValidationError>;
};

export type UpdateMessageInput = {
  attachments: InputMaybe<Array<UpdateAttachmentRequest>>;
  companyId: Scalars['ID']['input'];
  emailProperties: InputMaybe<EmailPropertiesRequest>;
  ignoreFatigue: InputMaybe<Scalars['Boolean']['input']>;
  mediaUrls: InputMaybe<Array<UrlInput>>;
  messageId: Scalars['ID']['input'];
  messageTags: InputMaybe<Array<ComposeMessageTagInput>>;
  name: Scalars['String']['input'];
  rbmProperties: InputMaybe<RbmPropertiesInput>;
  status: ComposeMessageStatus;
  textMessageProperties: InputMaybe<TextMessagePropertiesRequest>;
  updatedUtmParameters: InputMaybe<UtmParametersInput>;
  validationRulesToSkip: InputMaybe<Array<ValidationRuleToSkipInput>>;
};

export type UpdateMessageResult = UpdateMessageFailure | UpdateMessageSuccess;

/** Generated from com/attentivemobile/contracts/composition/Contract.proto */
export type UpdateMessageSuccess = {
  __typename?: 'UpdateMessageSuccess';
  composeMessage: ComposeMessage;
};

export type UpdateMessageTemplateInput = {
  channel: Channel;
  companyId: Scalars['Long']['input'];
  id: Scalars['ID']['input'];
  templateMetadata: ComposeMessageTemplateMetaDataInput;
  templateName: Scalars['String']['input'];
  templateType: MessagingTemplateType;
};

/** Generated from com/attentivemobile/contracts/compose/api/Contract.proto */
export type UpdateMessageTemplatePayload = {
  __typename?: 'UpdateMessageTemplatePayload';
  messageTemplate: ComposeMessageTemplate;
};

export type UpdateMessageValidationError = ConflictingAttachments | InvalidCouponCodeUsage | InvalidLiquidSyntax | InvalidMacroUsages | UndefinedMacro;

export type UpdateMetadataEntry = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type UpdateMobileWalletCardInput = {
  companyId: Scalars['ID']['input'];
  updateMobileWalletCardItems: InputMaybe<Array<UpdateMobileWalletCardItemInput>>;
};

export type UpdateMobileWalletCardItemInput = {
  cardText: Scalars['String']['input'];
  couponId: Scalars['String']['input'];
  expirationDate: InputMaybe<Scalars['DateTime']['input']>;
  externalCampaignId: Scalars['String']['input'];
  externalOfferId: Scalars['String']['input'];
  mediaUrl: InputMaybe<Scalars['String']['input']>;
  messageText: InputMaybe<Scalars['String']['input']>;
  phone: InputMaybe<Scalars['String']['input']>;
  updateMetadata: InputMaybe<Array<UpdateMetadataEntry>>;
  uuid: InputMaybe<Scalars['String']['input']>;
};

export type UpdateNBAConfigInput = {
  nextBestActionConfiguration: NextBestActionConfigurationInput;
  nextBestActionConfigurationId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type UpdateNBAConfigPayload = {
  __typename?: 'UpdateNBAConfigPayload';
  nextBestActionConfiguration: NextBestActionConfiguration;
};

export type UpdateNotificationBannerInput = {
  callToAction: Scalars['String']['input'];
  description: Scalars['String']['input'];
  eventDate: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  startTime: Scalars['DateTime']['input'];
  stopTime: Scalars['DateTime']['input'];
  title: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type UpdateNotificationBannerPayload = {
  __typename?: 'UpdateNotificationBannerPayload';
  banner: NotificationBanner;
};

export type UpdateNotificationEmailTemplateInput = {
  emailTemplate: NotificationEmailTemplateInput;
  notificationEmailTemplateId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type UpdateNotificationEmailTemplatePayload = {
  __typename?: 'UpdateNotificationEmailTemplatePayload';
  emailTemplate: NotificationEmailTemplate;
};

export type UpdatePollStatusInput = {
  pollId: Scalars['ID']['input'];
  status: PollStatus;
};

/** Generated from com/attentivemobile/contracts/survey/poll/Contract.proto */
export type UpdatePollStatusPayload = {
  __typename?: 'UpdatePollStatusPayload';
  error: Maybe<Scalars['String']['output']>;
  poll: Maybe<Poll>;
  status: PollStatus;
};

export type UpdatePollV2Input = {
  allowAltSignupUnit: InputMaybe<Scalars['Boolean']['input']>;
  allowBreakTies: InputMaybe<Scalars['Boolean']['input']>;
  allowMarketingSubmitMessage: InputMaybe<Scalars['Boolean']['input']>;
  allowMultiVote: InputMaybe<Scalars['Boolean']['input']>;
  altSignupUnitId: InputMaybe<Scalars['Int']['input']>;
  applicationId: InputMaybe<Scalars['String']['input']>;
  closedMessage: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['ID']['input'];
  decimalPrecision: InputMaybe<Scalars['Int']['input']>;
  description: InputMaybe<Scalars['String']['input']>;
  duplicateVoteMessage: InputMaybe<Scalars['String']['input']>;
  enableParticipantAttributeName: InputMaybe<Scalars['Boolean']['input']>;
  marketingSubmitMessage: InputMaybe<Scalars['String']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  onClosedMessage: InputMaybe<Scalars['String']['input']>;
  participantAttributeName: InputMaybe<Scalars['String']['input']>;
  pollId: Scalars['ID']['input'];
  state: InputMaybe<PollState>;
  submitMessage: InputMaybe<Scalars['String']['input']>;
  subscribedEventType: InputMaybe<Scalars['String']['input']>;
};

/** Generated from com/attentivemobile/contracts/survey/poll/Contract.proto */
export type UpdatePollV2Payload = {
  __typename?: 'UpdatePollV2Payload';
  error: Maybe<Scalars['String']['output']>;
  poll: Maybe<Poll>;
};

export type UpdatePrebuiltContentInput = {
  id: Scalars['ID']['input'];
  name: InputMaybe<Scalars['String']['input']>;
  orchestrationContent: InputMaybe<Array<OrchestrationContentInput>>;
  orchestrationType: InputMaybe<OrchestrationType>;
};

/** Generated from com/attentivemobile/contracts/messaging/automation/prebuilt/Contract.proto */
export type UpdatePrebuiltContentPayload = {
  __typename?: 'UpdatePrebuiltContentPayload';
  prebuiltContent: PrebuiltContent;
};

export type UpdatePrebuiltProgramInput = {
  description: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  programStatus: InputMaybe<ProgramStatus>;
  scheduleType: InputMaybe<ScheduleType>;
  thumbnailUrl: InputMaybe<Scalars['String']['input']>;
  title: InputMaybe<Scalars['String']['input']>;
};

/** Generated from com/attentivemobile/contracts/messaging/automation/prebuilt/Contract.proto */
export type UpdatePrebuiltProgramPayload = {
  __typename?: 'UpdatePrebuiltProgramPayload';
  prebuiltProgram: PrebuiltProgram;
};

export type UpdateProductSegmentInput = {
  companyId: Scalars['ID']['input'];
  filters: InputMaybe<Array<ProductSegmentFilterInput>>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto */
export type UpdateProductSegmentPayload = {
  __typename?: 'UpdateProductSegmentPayload';
  productSegment: Maybe<ProductSegment>;
};

export type UpdateProgramCadenceInput = {
  programCadenceType: InputMaybe<ProgramCadenceType>;
  updateProgramFrequencyRequest: InputMaybe<UpdateProgramFrequencyInput>;
};

export type UpdateProgramFrequencyInput = {
  period: InputMaybe<Scalars['Int']['input']>;
  periodUnit: InputMaybe<ProgramCalendarPeriod>;
};

export type UpdateProviderMigrationInput = {
  carrier: InputMaybe<Carrier>;
  endDate: InputMaybe<Scalars['DateTime']['input']>;
  newProvider: InputMaybe<Provider>;
  oldProvider: InputMaybe<Provider>;
  phoneNumber: Scalars['String']['input'];
  startDate: InputMaybe<Scalars['DateTime']['input']>;
  status: InputMaybe<ProviderMigrationStatus>;
};

/** Generated from com/attentivemobile/contracts/providermigration/Contract.proto */
export type UpdateProviderMigrationPayload = {
  __typename?: 'UpdateProviderMigrationPayload';
  migrations: Array<ProviderMigration>;
};

export type UpdateProviderMigrationsPayload = DefaultErrorFailure | UpdateProviderMigrationsSuccess;

/** Generated from com/attentivemobile/contracts/providermigration/Contract.proto */
export type UpdateProviderMigrationsSuccess = {
  __typename?: 'UpdateProviderMigrationsSuccess';
  migrations: Array<ProviderMigration>;
};

export type UpdateRateLimitInput = {
  aggregator: InputMaybe<Aggregator>;
  country: InputMaybe<RateLimitCountry>;
  phoneNumberType: InputMaybe<PhoneNumberType>;
  provider: Provider;
  rateLimit: Scalars['Long']['input'];
  textChannel: TextChannel;
};

/** Generated from com/attentivemobile/contracts/ratelimiting/Contract.proto */
export type UpdateRateLimitPayload = {
  __typename?: 'UpdateRateLimitPayload';
  rateLimit: RateLimit;
};

export type UpdateRegionInput = {
  region: RegionInput;
};

/** Generated from com/attentivemobile/contracts/company-service/region/Region.proto */
export type UpdateRegionPayload = {
  __typename?: 'UpdateRegionPayload';
  region: Region;
};

export type UpdateRequest = {
  id: Scalars['String']['input'];
  isVisible: Scalars['Boolean']['input'];
  shortcut: InputMaybe<Scalars['String']['input']>;
  text: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type UpdateRevisionStepInput = {
  companyId: Scalars['ID']['input'];
  revisionId: Scalars['String']['input'];
  revisionStepId: Scalars['String']['input'];
  updateCreateSupportTicketStepRequest: InputMaybe<UpdateCreateSupportTicketStepInput>;
  updateDelayStepRequest: InputMaybe<UpdateDelayStepInput>;
  updateExperimentStepRequest: InputMaybe<UpdateExperimentStepInput>;
  updateVariantStepRequest: InputMaybe<UpdateVariantStepInput>;
};

/** Generated from com/attentivemobile/contracts/journeys/api/RevisionStep.proto */
export type UpdateRevisionStepPayload = {
  __typename?: 'UpdateRevisionStepPayload';
  journeyRevision: JourneyRevision;
};

export type UpdateRouteRateLimitInput = {
  endpoint: Scalars['String']['input'];
  isActive: Scalars['Boolean']['input'];
  rateLimit: Scalars['Long']['input'];
};

export type UpdateRouteRateLimitPayload = DefaultErrorFailure | UpdateRouteRateLimitSuccess;

/** Generated from com/attentivemobile/contracts/gateway/service/RateLimiting.proto */
export type UpdateRouteRateLimitSuccess = {
  __typename?: 'UpdateRouteRateLimitSuccess';
  endpoint: Scalars['String']['output'];
  id: Scalars['Long']['output'];
  isActive: Scalars['Boolean']['output'];
  rateLimit: Scalars['Long']['output'];
};

export type UpdateSegmentFromSearchDefinitionInput = {
  companyId: Scalars['ID']['input'];
  description: InputMaybe<Scalars['String']['input']>;
  metadata: InputMaybe<Array<CreateSegmentMetadataInput>>;
  name: Scalars['String']['input'];
  searchDefinition: AudienceSearchDefinitionInput;
  segmentId: SegmentId;
};

export type UpdateSegmentFromSearchDefinitionPayload = DefaultErrorFailure | UpdateSegmentFromSearchDefinitionSuccess;

/** Generated from com/attentivemobile/contracts/segmentation/service/segment/contract.proto */
export type UpdateSegmentFromSearchDefinitionSuccess = {
  __typename?: 'UpdateSegmentFromSearchDefinitionSuccess';
  legacy: Maybe<Segment>;
  segment: Maybe<SegmentV2>;
};

export type UpdateSendScheduledReportOverSftpInput = {
  active: InputMaybe<Scalars['Boolean']['input']>;
  companyId: Scalars['ID']['input'];
  reportSystem: InputMaybe<Scalars['String']['input']>;
  scheduledReportIds: InputMaybe<Array<Scalars['Long']['input']>>;
};

export type UpdateSendScheduledReportOverSftpPayload = DefaultErrorFailure | UpdateSendScheduledReportOverSftpSuccess;

/** Generated from com/attentivemobile/contracts/solutions/sftp/Sftp.proto */
export type UpdateSendScheduledReportOverSftpSuccess = {
  __typename?: 'UpdateSendScheduledReportOverSftpSuccess';
  unscheduledReportIds: Array<Scalars['Long']['output']>;
};

export type UpdateSftpUserEmailInput = {
  companyId: Scalars['ID']['input'];
  email: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/solutions/sftp/SftpUser.proto */
export type UpdateSftpUserEmailPayload = {
  __typename?: 'UpdateSftpUserEmailPayload';
  email: Scalars['String']['output'];
};

export type UpdateShopifyAutoApplyFormatsInput = {
  companyId: Scalars['ID']['input'];
  discountLinkFormat: DiscountLinkFormat;
};

export type UpdateShopifyAutoApplyFormatsPayload = DefaultErrorFailure | UpdateShopifyAutoApplyFormatsSuccess;

/** Generated from com/attentivemobile/contracts/personalization/service/aijourneys/onboarding/autoapply/ShopifyAutoApplyFormatsContract.proto */
export type UpdateShopifyAutoApplyFormatsSuccess = {
  __typename?: 'UpdateShopifyAutoApplyFormatsSuccess';
  discountLinkFormat: DiscountLinkFormat;
  success: Scalars['Boolean']['output'];
};

export type UpdateSyncInput = {
  connectionSettings: InputMaybe<UpdateSyncInputConnectionSettings>;
  description: InputMaybe<Scalars['String']['input']>;
  fetchOptions: FetchOptionsInput;
  fileSettings: FileSettingsInput;
  fileType: EdsJobIngestionFormat;
  name: InputMaybe<Scalars['String']['input']>;
  schedule: InputMaybe<SyncScheduleInput>;
  syncId: Scalars['String']['input'];
  syncMethod: InputMaybe<EdsJobSyncMethod>;
  targetConfig: Scalars['JSON']['input'];
};

export type UpdateSyncInputConnectionSettings =
  { attentiveS3Settings: AttentiveS3SettingsInput; sftpSettings?: never; snowflakeSecureDataShareSettings?: never; }
  |  { attentiveS3Settings?: never; sftpSettings: SftpSettingsInput; snowflakeSecureDataShareSettings?: never; }
  |  { attentiveS3Settings?: never; sftpSettings?: never; snowflakeSecureDataShareSettings: SnowflakeSecureDataSharingInput; };

export type UpdateSyncPayload = DefaultErrorFailure | UpdateSyncSuccess;

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type UpdateSyncSuccess = {
  __typename?: 'UpdateSyncSuccess';
  error: Maybe<SyncError>;
  success: Maybe<Scalars['Boolean']['output']>;
  sync: Sync;
};

export type UpdateTagInput = {
  companyId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  tagId: Scalars['ID']['input'];
};

export type UpdateVariantStepInput = {
  id: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  percentage: Scalars['String']['input'];
  sortOrder: Scalars['String']['input'];
};

export type UpdateWebTemplateInput = {
  webTemplate: WebTemplateInput;
  webTemplateId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type UpdateWebTemplatePayload = {
  __typename?: 'UpdateWebTemplatePayload';
  webTemplate: WebTemplate;
};

export type UpdateWebhookInput = {
  companyId: InputMaybe<Scalars['String']['input']>;
  /** (optional) Disable the webhook endpoint if set to true. */
  disabled: Scalars['Boolean']['input'];
  /** The list of events this webhook is subscribed to. */
  events: InputMaybe<Array<Scalars['String']['input']>>;
  /** The id of the webhook to update. */
  id: Scalars['ID']['input'];
  /** The destination url of the webhook. */
  url: Scalars['String']['input'];
};

/**
 * Generated from com/attentivemobile/contracts/externaltokens/Webhooks.proto
 * Response for updateWebhook mutation.
 */
export type UpdateWebhookPayload = {
  __typename?: 'UpdateWebhookPayload';
  /** Webhook details. */
  webhook: Webhook;
};

export type UpdateWorkloadConfigInput = {
  newWorkloadConfig: WorkloadConfigInput;
};

export type UpdateWorkloadConfigPayload = DefaultErrorFailure | UpdateWorkloadConfigSuccess;

/** Generated from com/attentivemobile/contracts/workload/management/service/configuration/Contract.proto */
export type UpdateWorkloadConfigSuccess = {
  __typename?: 'UpdateWorkloadConfigSuccess';
  newWorkloadConfig: WorkloadConfig;
  oldWorkloadConfig: WorkloadConfig;
};

export type UploadManualSegmentInput = {
  companyId: Scalars['ID']['input'];
  externalId: Scalars['String']['input'];
  isMultiFileUpload: InputMaybe<Scalars['Boolean']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  operation: UploadManualSegmentOperation;
  s3FileOrDirectoryPath: Scalars['String']['input'];
  source: ManualSegmentSource;
  thirdPartySegmentUploadMetadata: InputMaybe<ThirdPartySegmentMetadata>;
};

export enum UploadManualSegmentOperation {
  UploadManualSegmentOperationAppendToExisting = 'UPLOAD_MANUAL_SEGMENT_OPERATION_APPEND_TO_EXISTING',
  UploadManualSegmentOperationCreateOrReplace = 'UPLOAD_MANUAL_SEGMENT_OPERATION_CREATE_OR_REPLACE',
  UploadManualSegmentOperationUnknown = 'UPLOAD_MANUAL_SEGMENT_OPERATION_UNKNOWN'
}

export type UploadManualSegmentPayload = DefaultErrorFailure | UploadManualSegmentSuccess;

/** Generated from com/attentivemobile/contracts/segmentation/service/ManualSegmentUpload.proto */
export type UploadManualSegmentSuccess = {
  __typename?: 'UploadManualSegmentSuccess';
  segment: Maybe<Segment>;
};

export type UpsertAIJourneyDefaultMessageInput = {
  companyId: Scalars['ID']['input'];
  messageContent: Scalars['String']['input'];
  messageType: MessageType;
};

export type UpsertAIJourneyDefaultMessagePayload = DefaultErrorFailure | UpsertAIJourneyDefaultMessageSuccess;

/** Generated from com/attentivemobile/contracts/personalization/service/aijourneys/defaultmessage/AIJourneyDefaultMessageServiceContract.proto */
export type UpsertAIJourneyDefaultMessageSuccess = {
  __typename?: 'UpsertAIJourneyDefaultMessageSuccess';
  defaultMessage: AIJourneyDefaultMessage;
};

export type UpsertAccountInput = {
  accountId: InputMaybe<Scalars['ID']['input']>;
  authRoles: InputMaybe<Array<Role>>;
  companyId: Scalars['ID']['input'];
  email: InputMaybe<Scalars['String']['input']>;
  firstName: InputMaybe<Scalars['String']['input']>;
  lastName: InputMaybe<Scalars['String']['input']>;
  phone: InputMaybe<Scalars['String']['input']>;
};

/** Generated from com/attentivemobile/contracts/account-service/Contract.proto */
export type UpsertAccountPayload = {
  __typename?: 'UpsertAccountPayload';
  account: Account;
  created: Scalars['Boolean']['output'];
};

export type UpsertAffiliateUserInput = {
  companyId: Scalars['ID']['input'];
  email: Scalars['String']['input'];
  password: InputMaybe<Scalars['String']['input']>;
};

/** Generated from com/attentivemobile/contracts/externaltokens/AffiliateUsers.proto */
export type UpsertAffiliateUserPayload = {
  __typename?: 'UpsertAffiliateUserPayload';
  affiliateUserId: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

export type UpsertAreaCodeRuleRequest = {
  areaCodes: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpsertClientSegmentImportInput = {
  clientRowInput: ClientSegmentImportsRowInput;
  companyId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/solutions/clientsegmentimports/ClientSegmentImports.proto */
export type UpsertClientSegmentImportPayload = {
  __typename?: 'UpsertClientSegmentImportPayload';
  clientSegmentImportsRow: ClientSegmentImportsRow;
};

export type UpsertCustomAttributeRuleRequest = {
  expression: Scalars['String']['input'];
};

export type UpsertEdsTaskProcessingConfigInput = {
  endpoint: Scalars['String']['input'];
  id: InputMaybe<Scalars['String']['input']>;
  limitPerSec: Scalars['Int']['input'];
  retryTimes: Scalars['Int']['input'];
  taskId: InputMaybe<Scalars['String']['input']>;
};

export type UpsertEdsTaskProcessingConfigPayload = DefaultErrorFailure | UpsertEdsTaskProcessingConfigSuccess;

/** Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto */
export type UpsertEdsTaskProcessingConfigSuccess = {
  __typename?: 'UpsertEdsTaskProcessingConfigSuccess';
  config: EdsTaskProcessingConfig;
};

export type UpsertKeywordRulePayload = DefaultErrorFailure | UpsertKeywordRuleSuccess;

export type UpsertKeywordRuleRequestInput = {
  areaCodeRule: InputMaybe<UpsertAreaCodeRuleRequest>;
  companyId: Scalars['ID']['input'];
  customAttributeRule: InputMaybe<UpsertCustomAttributeRuleRequest>;
  id: InputMaybe<Scalars['Int']['input']>;
  keywordId: Scalars['ID']['input'];
  marketingSubscriberRule: InputMaybe<Scalars['Boolean']['input']>;
  ruleExpression: InputMaybe<Scalars['String']['input']>;
  timeWindowRule: InputMaybe<UpsertTimeWindowRuleRequest>;
};

/** Generated from com/attentivemobile/contracts/keywords/KeywordRuleService.proto */
export type UpsertKeywordRuleSuccess = {
  __typename?: 'UpsertKeywordRuleSuccess';
  keywordRule: KeywordRule;
};

export type UpsertLocalTimeMessage = {
  hour: Scalars['Int']['input'];
  minute: Scalars['Int']['input'];
  second: Scalars['Int']['input'];
};

export type UpsertPollKeywordInput = {
  companyId: Scalars['ID']['input'];
  pollId: Scalars['ID']['input'];
  pollKeywords: InputMaybe<Array<PollKeywordInput>>;
};

/** Generated from com/attentivemobile/contracts/survey/poll/PollKeywordService.proto */
export type UpsertPollKeywordPayload = {
  __typename?: 'UpsertPollKeywordPayload';
  pollKeywords: Array<PollKeyword>;
  success: Scalars['Boolean']['output'];
};

export type UpsertTimeWindowRuleRequest = {
  endTime: UpsertLocalTimeMessage;
  startTime: UpsertLocalTimeMessage;
  timeZoneId: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/protobuf/common/Url.proto */
export type Url = {
  __typename?: 'Url';
  value: Scalars['String']['output'];
};

export type UrlInput = {
  value: Scalars['String']['input'];
};

/**
 * Generated from com/attentivemobile/contracts/user/profile/Contract.proto
 * User details.
 */
export type User = Node & {
  __typename?: 'User';
  activeDismissibleModal: Maybe<DismissibleModal>;
  agentTemplates: Maybe<ListAgentTemplatesResponse>;
  blocklistStatus: Maybe<UserBlocklistStatus>;
  /** City for this user. */
  city: Scalars['String']['output'];
  /** The associated company for this user */
  company: Maybe<Company>;
  conciergeConversationDetails: Maybe<UserConversationDetails>;
  contentSettings: Maybe<FindUserContentSettingsConnection>;
  conversationEscalation: Maybe<UserConversationEscalation>;
  /** Country for this user. */
  country: Scalars['String']['output'];
  coupons: Maybe<UserCouponConnection>;
  /** Email for this user. */
  email: Scalars['String']['output'];
  events: Maybe<GetUserEventsResponse>;
  /** First name for this user. */
  firstName: Scalars['String']['output'];
  hasConciergeConversion: Maybe<Scalars['Boolean']['output']>;
  /** ID for this user. */
  id: Scalars['ID']['output'];
  /** Last name for this user. */
  lastName: Scalars['String']['output'];
  latestConciergeSession: Maybe<ConciergeSession>;
  messages: Maybe<MessageConnection>;
  optInStatus: Maybe<UserOptInStatus>;
  personalizedProductRecommendations: Maybe<ProductRecommendationsRedactedResponse>;
  recommendedAgentTemplates: Maybe<ListAgentTemplatesResponse>;
  /** State for this user. */
  state: Scalars['String']['output'];
  /** Subscribed phone number for this user. */
  subscribedPhone: Scalars['String']['output'];
  /** @deprecated deprecated */
  subscriberId: Scalars['Long']['output'];
  /** SubscriptionConnections associated with this user. */
  subscriptions: Maybe<SubscriptionConnection>;
  /** Timezone for this user. */
  timezone: Scalars['String']['output'];
  userConversationLock: Maybe<UserConversationLock>;
  userConversationMacros: Maybe<ListUserConversationMacrosResponse>;
  userProperties: Maybe<ListUserPropertiesResponse>;
  userPropertiesV2: Maybe<UserPropertyConnection>;
  /** Zip code for this user. */
  zipcode: Scalars['String']['output'];
};


/**
 * Generated from com/attentivemobile/contracts/user/profile/Contract.proto
 * User details.
 */
export type UseractiveDismissibleModalArgs = {
  companyId: InputMaybe<Scalars['ID']['input']>;
  url: Scalars['String']['input'];
};


/**
 * Generated from com/attentivemobile/contracts/user/profile/Contract.proto
 * User details.
 */
export type UsercontentSettingsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  companyId: InputMaybe<Scalars['ID']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


/**
 * Generated from com/attentivemobile/contracts/user/profile/Contract.proto
 * User details.
 */
export type UsercouponsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
};


/**
 * Generated from com/attentivemobile/contracts/user/profile/Contract.proto
 * User details.
 */
export type UsereventsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  includeNonSubscriberData: InputMaybe<Scalars['Boolean']['input']>;
  timeCondition: InputMaybe<TimeCondition>;
  typeFilter: InputMaybe<Array<EventType>>;
};


/**
 * Generated from com/attentivemobile/contracts/user/profile/Contract.proto
 * User details.
 */
export type UsermessagesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  dateRange: InputMaybe<DateRange>;
  first: InputMaybe<Scalars['Int']['input']>;
  onlyDelivered: InputMaybe<Scalars['Boolean']['input']>;
  slidingWindowDays: InputMaybe<Scalars['Int']['input']>;
};


/**
 * Generated from com/attentivemobile/contracts/user/profile/Contract.proto
 * User details.
 */
export type UseruserPropertiesV2Args = {
  after: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<UserPropertiesV2Filter>;
  first: InputMaybe<Scalars['Int']['input']>;
};

export enum UserAuthStatus {
  Authenticated = 'authenticated',
  Unauthenticated = 'unauthenticated',
  Unknown = 'unknown'
}

/** Generated from com/attentivemobile/contracts/concierge/service/Contract.proto */
export type UserBlocklistStatus = {
  __typename?: 'UserBlocklistStatus';
  isBlocklisted: Scalars['Boolean']['output'];
};

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type UserContentSetting = Node & {
  __typename?: 'UserContentSetting';
  companyId: Maybe<Scalars['ID']['output']>;
  deleted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  objectClass: ContentClass;
  objectId: Scalars['String']['output'];
  preferenceType: PreferenceType;
  preferenceValue: Scalars['String']['output'];
  userId: Maybe<Scalars['ID']['output']>;
};

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type UserContentSettingsEdge = {
  __typename?: 'UserContentSettingsEdge';
  cursor: Scalars['String']['output'];
  node: UserContentSetting;
};

export type UserConversationDeescalationInput = {
  userId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/concierge/service/Escalation.proto */
export type UserConversationDeescalationPayload = {
  __typename?: 'UserConversationDeescalationPayload';
  user: Maybe<User>;
};

/** Generated from com/attentivemobile/contracts/concierge/service/UserParameters.proto */
export type UserConversationDetails = {
  __typename?: 'UserConversationDetails';
  couponPool: Maybe<CouponSet>;
};

/** Generated from com/attentivemobile/contracts/concierge/service/Escalation.proto */
export type UserConversationEscalation = {
  __typename?: 'UserConversationEscalation';
  escalatedBy: Maybe<Account>;
  lastNudged: Maybe<Scalars['DateTime']['output']>;
  note: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
  user: Maybe<User>;
};

export type UserConversationEscalationInput = {
  note: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/concierge/service/Escalation.proto */
export type UserConversationEscalationPayload = {
  __typename?: 'UserConversationEscalationPayload';
  user: Maybe<User>;
};

/** Generated from com/attentivemobile/contracts/concierge/service/Lock.proto */
export type UserConversationLock = {
  __typename?: 'UserConversationLock';
  lockExpiration: Scalars['DateTime']['output'];
  lockedBy: Maybe<Account>;
};

export type UserConversationLockInput = {
  userId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/concierge/service/Lock.proto */
export type UserConversationLockPayload = {
  __typename?: 'UserConversationLockPayload';
  user: Maybe<User>;
};

/** Generated from com/attentivemobile/contracts/concierge/service/Macro.proto */
export type UserConversationMacro = {
  __typename?: 'UserConversationMacro';
  category: MacroCategory;
  token: Scalars['String']['output'];
  type: MacroType;
};

export type UserConversationNudgeInput = {
  userId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/concierge/service/Escalation.proto */
export type UserConversationNudgePayload = {
  __typename?: 'UserConversationNudgePayload';
  user: Maybe<User>;
};

export type UserConversationUnlockInput = {
  userId: Scalars['ID']['input'];
};

/** Generated from com/attentivemobile/contracts/concierge/service/Lock.proto */
export type UserConversationUnlockPayload = {
  __typename?: 'UserConversationUnlockPayload';
  user: Maybe<User>;
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type UserCoupon = {
  __typename?: 'UserCoupon';
  couponCode: Scalars['String']['output'];
  couponSet: Maybe<CouponSet>;
  couponUrl: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type UserCouponConnection = {
  __typename?: 'UserCouponConnection';
  edges: Array<UserCouponEdge>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type UserCouponEdge = {
  __typename?: 'UserCouponEdge';
  cursor: Scalars['String']['output'];
  node: UserCoupon;
};

export enum UserDownloadField {
  UserDownloadFieldEmail = 'USER_DOWNLOAD_FIELD_EMAIL',
  UserDownloadFieldUnknown = 'USER_DOWNLOAD_FIELD_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type UserEdge = {
  __typename?: 'UserEdge';
  cursor: Scalars['String']['output'];
  node: User;
  /** @deprecated deprecated */
  user: User;
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type UserEvent = {
  __typename?: 'UserEvent';
  created: Scalars['DateTime']['output'];
  event: Maybe<UserEventEvent>;
  id: Scalars['String']['output'];
  user: Maybe<User>;
};

export type UserEventEvent = AddToCartEvent | CartUpdatedEvent | CheckoutAbandonedEvent | CheckoutStartedEvent | CheckoutUpdatedEvent | CustomEvent | EmailOpened | EmailSent | FulfillmentAvailableForPickupEvent | FulfillmentCancelledEvent | FulfillmentCreatedEvent | FulfillmentDeliveredEvent | FulfillmentDeliveryAttemptFailureEvent | FulfillmentDeliveryExceptionEvent | FulfillmentInTransitEvent | FulfillmentOutForDeliveryEvent | FulfillmentPendingEvent | JoinEvent | MessageLinkClick | OptOutEvent | OrderCancelledEvent | OrderConfirmedEvent | ProductViewEvent | PurchaseEvent | RefundFailedEvent | RefundSucceededEvent | SubscriptionOptInCompletedEvent | SubscriptionOptedOutEvent | SubscriptionSuppressedEvent | SubscriptionUnsuppressedEvent;

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type UserEventsEdge = {
  __typename?: 'UserEventsEdge';
  cursor: Scalars['String']['output'];
  node: UserEvent;
};

/** Generated from com/attentivemobile/contracts/user/profile/download/Model.proto */
export type UserFileDownload = Node & {
  __typename?: 'UserFileDownload';
  company: Maybe<Company>;
  completedAt: Maybe<Scalars['DateTime']['output']>;
  downloadType: UserFileDownloadType;
  downloadUrl: Maybe<PresignedUrl>;
  failureCount: Maybe<Scalars['Long']['output']>;
  id: Scalars['ID']['output'];
  listSize: Maybe<Scalars['Long']['output']>;
  metadata: Scalars['String']['output'];
  startedAt: Maybe<Scalars['DateTime']['output']>;
  status: UserFileDownloadStatus;
  submittedAt: Scalars['DateTime']['output'];
  userFields: Array<UserFileDownloadField>;
};

export enum UserFileDownloadField {
  UserFileDownloadFieldEmail = 'USER_FILE_DOWNLOAD_FIELD_EMAIL',
  UserFileDownloadFieldPhoneNumber = 'USER_FILE_DOWNLOAD_FIELD_PHONE_NUMBER',
  UserFileDownloadFieldUnknown = 'USER_FILE_DOWNLOAD_FIELD_UNKNOWN'
}

export type UserFileDownloadFilterInput =
  { segmentId: Scalars['Long']['input']; status?: never; }
  |  { segmentId?: never; status: UserStatus; };

export enum UserFileDownloadStatus {
  UserFileDownloadStatusCancelled = 'USER_FILE_DOWNLOAD_STATUS_CANCELLED',
  UserFileDownloadStatusCompleted = 'USER_FILE_DOWNLOAD_STATUS_COMPLETED',
  UserFileDownloadStatusProcessing = 'USER_FILE_DOWNLOAD_STATUS_PROCESSING',
  UserFileDownloadStatusSubmitted = 'USER_FILE_DOWNLOAD_STATUS_SUBMITTED',
  UserFileDownloadStatusUnknown = 'USER_FILE_DOWNLOAD_STATUS_UNKNOWN'
}

export enum UserFileDownloadType {
  UserFileDownloadTypeAutoSuppressed = 'USER_FILE_DOWNLOAD_TYPE_AUTO_SUPPRESSED',
  UserFileDownloadTypeSegment = 'USER_FILE_DOWNLOAD_TYPE_SEGMENT',
  UserFileDownloadTypeSuppressed = 'USER_FILE_DOWNLOAD_TYPE_SUPPRESSED',
  UserFileDownloadTypeUnknown = 'USER_FILE_DOWNLOAD_TYPE_UNKNOWN',
  UserFileDownloadTypeUserSuppressed = 'USER_FILE_DOWNLOAD_TYPE_USER_SUPPRESSED'
}

/** User associated with the action. Note that this is a visitor to the site and does not need to be actively subscribed to Attentive. */
export type UserInput = {
  /** A user's email we use to fetch subscription eligibility. Example: email=test@gmail.com */
  email: InputMaybe<Scalars['String']['input']>;
  /** A user's phone number we use to fetch subscription eligibility. Example: phone=+13115552368 */
  phone: InputMaybe<Scalars['String']['input']>;
};

/** Generated from com/attentivemobile/contracts/concierge/service/Contract.proto */
export type UserOptInStatus = {
  __typename?: 'UserOptInStatus';
  isOptedIn: Scalars['Boolean']['output'];
};

export type UserPropertiesV2Filter =
  { propertyNameFilter: Array<PropertyNameInput>; };

/** Generated from com/attentivemobile/contracts/composition/MessageAttachment.proto */
export type UserPropertyAttachment = {
  __typename?: 'UserPropertyAttachment';
  entityToken: Scalars['String']['output'];
};

export type UserPropertyAttachmentProperties = {
  entityToken: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/user/profile/property/UserPropertyV2.proto */
export type UserPropertyConnection = {
  __typename?: 'UserPropertyConnection';
  edges: Array<UserPropertyEdge>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type UserPropertyDefinition = {
  __typename?: 'UserPropertyDefinition';
  type: Maybe<UserPropertyDefinitionType>;
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type UserPropertyDefinitionConnection = {
  __typename?: 'UserPropertyDefinitionConnection';
  edges: Array<UserPropertyDefinitionEdge>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type UserPropertyDefinitionConnectionV2 = {
  __typename?: 'UserPropertyDefinitionConnectionV2';
  edges: Array<UserPropertyDefinitionEdgeV2>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type UserPropertyDefinitionEdge = {
  __typename?: 'UserPropertyDefinitionEdge';
  cursor: Scalars['String']['output'];
  node: UserPropertyDefinition;
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type UserPropertyDefinitionEdgeV2 = {
  __typename?: 'UserPropertyDefinitionEdgeV2';
  cursor: Scalars['String']['output'];
  node: UserPropertyDefinitionV2;
};

export type UserPropertyDefinitionType = CustomPropertyDefinition | WellKnownPropertyDefinition;

export type UserPropertyDefinitionV2 = CustomPropertyDefinition | WellKnownPropertyDefinition;

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type UserPropertyDefinitionValue = {
  __typename?: 'UserPropertyDefinitionValue';
  created: Scalars['DateTime']['output'];
  metadata: Maybe<PropertyValueMetadata>;
  property: Maybe<UserPropertyDefinitionValueProperty>;
  source: Scalars['String']['output'];
  /** The installed application that sent this value */
  sourceInstalledApplication: Maybe<InstalledApplication>;
  value: UserPropertyValue;
};

export type UserPropertyDefinitionValueProperty = CustomPropertyDefinition | ThirdPartyPropertyDefinition | WellKnownProperty;

/** Generated from com/attentivemobile/contracts/user/profile/property/UserPropertyV2.proto */
export type UserPropertyEdge = {
  __typename?: 'UserPropertyEdge';
  cursor: Scalars['String']['output'];
  userProperty: UserPropertyV2;
};

export enum UserPropertySource {
  UserPropertySourceAny = 'USER_PROPERTY_SOURCE_ANY',
  UserPropertySourceCustom = 'USER_PROPERTY_SOURCE_CUSTOM',
  UserPropertySourceWellKnown = 'USER_PROPERTY_SOURCE_WELL_KNOWN'
}

/** Generated from com/attentivemobile/contracts/user/profile/property/UserPropertyV2.proto */
export type UserPropertyV2 = {
  __typename?: 'UserPropertyV2';
  originatingEntity: OriginatingEntity;
  propertyId: PropertyDefinitionId;
  timestamp: Scalars['DateTime']['output'];
  user: Maybe<User>;
  userPropertyValue: UserPropertyValueV2;
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type UserPropertyValue = {
  __typename?: 'UserPropertyValue';
  customPropertyValue: Maybe<UserPropertyValueCustomPropertyValue>;
};

export type UserPropertyValueCustomPropertyValue = BoolPropertyValue | ConversationalState | DatePropertyValue | EnumeratedValue | FloatPropertyValue | ProductRecommendationsResponse | StringPropertyValue;

export type UserPropertyValueV2 = BooleanPropertyValueV2 | CollectionPropertyValueV2 | DateTimeValueV2 | DateValueV2 | DoubleValueV2 | EnumeratedValueV2 | JsonPropertyValueV2 | LongValueV2 | StringPropertyValueV2;

export type UserSearchCountsRequest = {
  baseAudienceDefinition: InputMaybe<BaseAudienceDefinition>;
  requestId: Scalars['String']['input'];
  searchFormat: InputMaybe<UserSearchCountsRequestSearchFormat>;
};

export type UserSearchCountsRequestSearchFormat =
  { fullListSearch: FullListSearch; includeExcludeSearch?: never; singleSearch?: never; }
  |  { fullListSearch?: never; includeExcludeSearch: IncludeExcludeSearch; singleSearch?: never; }
  |  { fullListSearch?: never; includeExcludeSearch?: never; singleSearch: SingleSearch; };

/** Generated from com/attentivemobile/contracts/segmentation/service/search/contract.proto */
export type UserSearchCountsResponse = {
  __typename?: 'UserSearchCountsResponse';
  counts: Scalars['Long']['output'];
  requestId: Scalars['String']['output'];
};

export type UserSearchDefinition =
  { segmentId: Scalars['ID']['input']; templateDefinition?: never; }
  |  { segmentId?: never; templateDefinition: SegmentTemplateInput; };

export enum UserSearchEvent {
  UserSearchEventAddToCart = 'USER_SEARCH_EVENT_ADD_TO_CART',
  UserSearchEventCartUpdated = 'USER_SEARCH_EVENT_CART_UPDATED',
  UserSearchEventCheckoutAbandoned = 'USER_SEARCH_EVENT_CHECKOUT_ABANDONED',
  UserSearchEventCheckoutStarted = 'USER_SEARCH_EVENT_CHECKOUT_STARTED',
  UserSearchEventCheckoutUpdated = 'USER_SEARCH_EVENT_CHECKOUT_UPDATED',
  UserSearchEventCustomEvent = 'USER_SEARCH_EVENT_CUSTOM_EVENT',
  UserSearchEventFulfillmentAvailableForPickup = 'USER_SEARCH_EVENT_FULFILLMENT_AVAILABLE_FOR_PICKUP',
  UserSearchEventFulfillmentCancelled = 'USER_SEARCH_EVENT_FULFILLMENT_CANCELLED',
  UserSearchEventFulfillmentCreated = 'USER_SEARCH_EVENT_FULFILLMENT_CREATED',
  UserSearchEventFulfillmentDelivered = 'USER_SEARCH_EVENT_FULFILLMENT_DELIVERED',
  UserSearchEventFulfillmentDeliveryAttemptFailure = 'USER_SEARCH_EVENT_FULFILLMENT_DELIVERY_ATTEMPT_FAILURE',
  UserSearchEventFulfillmentDeliveryException = 'USER_SEARCH_EVENT_FULFILLMENT_DELIVERY_EXCEPTION',
  UserSearchEventFulfillmentInTransit = 'USER_SEARCH_EVENT_FULFILLMENT_IN_TRANSIT',
  UserSearchEventFulfillmentOutForDelivery = 'USER_SEARCH_EVENT_FULFILLMENT_OUT_FOR_DELIVERY',
  UserSearchEventFulfillmentPending = 'USER_SEARCH_EVENT_FULFILLMENT_PENDING',
  UserSearchEventOrderCancelled = 'USER_SEARCH_EVENT_ORDER_CANCELLED',
  UserSearchEventOrderConfirmed = 'USER_SEARCH_EVENT_ORDER_CONFIRMED',
  UserSearchEventOrderSubscriptionChargeFailed = 'USER_SEARCH_EVENT_ORDER_SUBSCRIPTION_CHARGE_FAILED',
  UserSearchEventOrderSubscriptionChargeScheduled = 'USER_SEARCH_EVENT_ORDER_SUBSCRIPTION_CHARGE_SCHEDULED',
  UserSearchEventOrderSubscriptionCreated = 'USER_SEARCH_EVENT_ORDER_SUBSCRIPTION_CREATED',
  UserSearchEventOrderSubscriptionSkipped = 'USER_SEARCH_EVENT_ORDER_SUBSCRIPTION_SKIPPED',
  UserSearchEventPageView = 'USER_SEARCH_EVENT_PAGE_VIEW',
  UserSearchEventProductView = 'USER_SEARCH_EVENT_PRODUCT_VIEW',
  UserSearchEventPurchase = 'USER_SEARCH_EVENT_PURCHASE',
  UserSearchEventRefundFailed = 'USER_SEARCH_EVENT_REFUND_FAILED',
  UserSearchEventRefundSucceeded = 'USER_SEARCH_EVENT_REFUND_SUCCEEDED',
  UserSearchEventSubscriptionSuppressed = 'USER_SEARCH_EVENT_SUBSCRIPTION_SUPPRESSED'
}

export type UserSearchFilter = {
  operation: FilterOperation;
  propertyFilters: InputMaybe<Array<SearchPropertyFilter>>;
};

export enum UserStatus {
  Any = 'ANY',
  AutoSuppressed = 'AUTO_SUPPRESSED',
  Eligible = 'ELIGIBLE',
  Ineligible = 'INELIGIBLE',
  NotYetJoined = 'NOT_YET_JOINED',
  OptedOut = 'OPTED_OUT',
  Suppressed = 'SUPPRESSED',
  Unknown = 'UNKNOWN',
  UserSuppressed = 'USER_SUPPRESSED'
}

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type UsersConnection = {
  __typename?: 'UsersConnection';
  edges: Array<UserEdge>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/concierge/service/ConversationManager.proto */
export type UtmConfiguration = {
  __typename?: 'UtmConfiguration';
  customUrlParams: Array<CustomUrlParamsEntry>;
  isUtmTrackingEnabled: Scalars['Boolean']['output'];
  utmCampaign: Maybe<Scalars['String']['output']>;
};

export type UtmConfigurationInput = {
  customUrlParamsInput: InputMaybe<Array<CustomUrlParamsInputEntry>>;
  isUtmTrackingEnabled: Scalars['Boolean']['input'];
  utmCampaign: InputMaybe<Scalars['String']['input']>;
};

/** Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto */
export type UtmParametersEntry = {
  __typename?: 'UtmParametersEntry';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type UtmParametersInput = {
  utmParametersInput: InputMaybe<Array<UtmParametersInputEntry>>;
};

export type UtmParametersInputEntry = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/messaging/automation/autojourneys/AutoJourneysModel.proto */
export type UtmSettings = {
  __typename?: 'UtmSettings';
  customParams: Array<CustomParamsEntry>;
  isUtmTrackingEnabled: Scalars['Boolean']['output'];
  utmCampaign: Maybe<Scalars['String']['output']>;
};

export type UtmSettingsInput = {
  customUrlInputParams: InputMaybe<Array<CustomUrlInputParamsEntry>>;
  isUtmTrackingEnabled: Scalars['Boolean']['input'];
  utmCampaign: InputMaybe<Scalars['String']['input']>;
};

export type ValidateMappingForFileInput = {
  companyId: Scalars['ID']['input'];
  fileId: Scalars['ID']['input'];
  locale: InputMaybe<LocaleInput>;
  mappings: InputMaybe<Array<SubscriberUploadFileMappingInput>>;
  subscriberUploadJobType: InputMaybe<SubscriberUploadJobType>;
  uploadSubscriptionType: SubscriberUploadSubscriptionType;
};

/** Generated from com/attentivemobile/contracts/subscriptions/batch/Contract.proto */
export type ValidateMappingForFilePayload = {
  __typename?: 'ValidateMappingForFilePayload';
  validationResult: SubscriberUploadValidationResult;
};

/** Generated from com/attentivemobile/contracts/geography/Location.proto */
export type ValidatePostalCodesResponse = {
  __typename?: 'ValidatePostalCodesResponse';
  invalidPostalCodes: Array<InvalidPostalCode>;
  postalCodes: Array<PostalCode>;
};

/** Generated from com/attentivemobile/contracts/concierge/service/Template.proto */
export type ValidateTemplateFieldResponse = {
  __typename?: 'ValidateTemplateFieldResponse';
  valid: Scalars['Boolean']['output'];
  validationType: TemplateFieldValidationType;
};

/** Generated from com/attentivemobile/contracts/event/platform/batchjobs/service/Contract.proto */
export type ValidatedMigratedJob = {
  __typename?: 'ValidatedMigratedJob';
  datastreamName: Scalars['String']['output'];
  jobUrl: Scalars['String']['output'];
  newJobId: Scalars['String']['output'];
  restoredPath: Scalars['String']['output'];
  stoppedPath: Scalars['String']['output'];
  successfulCheckpoints: Array<Checkpoint>;
};

export type ValidationErrorInformation = ConflictingAttachments | EmptyEmailSubject | EmptyMessageBody | InvalidCouponCodeUsage | InvalidLink | InvalidLiquidSyntax | InvalidMacroUsages | MissingEmailMetadata | UndefinedMacro;

export enum ValidationRuleName {
  ValidationRuleNameUnknown = 'VALIDATION_RULE_NAME_UNKNOWN',
  ValidationRuleUnsubscribeLink = 'VALIDATION_RULE_UNSUBSCRIBE_LINK'
}

export type ValidationRuleToSkipInput = {
  validationRuleName: ValidationRuleName;
};

/** Generated from com/attentivemobile/contracts/journeys/api/RevisionStep.proto */
export type VariantStep = {
  __typename?: 'VariantStep';
  childrenSteps: Array<AdderStep>;
  stepParameters: VariantStepParameters;
};

/** Generated from com/attentivemobile/contracts/journeys/api/RevisionStepParameters.proto */
export type VariantStepParameters = {
  __typename?: 'VariantStepParameters';
  name: Scalars['String']['output'];
  percentage: Scalars['Float']['output'];
  sortOrder: Scalars['Int']['output'];
};

export enum Vendor {
  Aftership = 'AFTERSHIP',
  Bluecore = 'BLUECORE',
  BlueshiftEvents = 'BLUESHIFT_EVENTS',
  Braze = 'BRAZE',
  Bronto = 'BRONTO',
  Cheetahmail = 'CHEETAHMAIL',
  CheetahmailSetuser = 'CHEETAHMAIL_SETUSER',
  Conversio = 'CONVERSIO',
  Cordial = 'CORDIAL',
  CrowdTwist = 'CROWD_TWIST',
  Custom = 'CUSTOM',
  Drip = 'DRIP',
  Emarsys = 'EMARSYS',
  Freshdesk = 'FRESHDESK',
  Gorgias = 'GORGIAS',
  Hubspot = 'HUBSPOT',
  IbmWatson = 'IBM_WATSON',
  Iterable = 'ITERABLE',
  Justuno = 'JUSTUNO',
  Klaviyo = 'KLAVIYO',
  KlaviyoSubscribe = 'KLAVIYO_SUBSCRIBE',
  Kustomer = 'KUSTOMER',
  Listrak = 'LISTRAK',
  Mailchimp = 'MAILCHIMP',
  Maropost = 'MAROPOST',
  MauricesResponsys = 'MAURICES_RESPONSYS',
  Mparticle = 'MPARTICLE',
  OmahaSteaks = 'OMAHA_STEAKS',
  Omnisend = 'OMNISEND',
  Rainbowshops = 'RAINBOWSHOPS',
  Reebok = 'REEBOK',
  Rejoiner = 'REJOINER',
  Responsys = 'RESPONSYS',
  RetentionScience = 'RETENTION_SCIENCE',
  Revolve = 'REVOLVE',
  Sailthru = 'SAILTHRU',
  Salesforce = 'SALESFORCE',
  SalesforceDataExtension = 'SALESFORCE_DATA_EXTENSION',
  SalesforceJourney = 'SALESFORCE_JOURNEY',
  SegmentAnalytics = 'SEGMENT_ANALYTICS',
  SegmentAnalyticsPgBrands = 'SEGMENT_ANALYTICS_PG_BRANDS',
  Shopify = 'SHOPIFY',
  Silverpop = 'SILVERPOP',
  Simon = 'SIMON',
  Sprouts = 'SPROUTS',
  Swell = 'SWELL',
  VendorActivecampaign = 'VENDOR_ACTIVECAMPAIGN',
  VendorAdobe = 'VENDOR_ADOBE',
  VendorAdobeExperiencePlatform = 'VENDOR_ADOBE_EXPERIENCE_PLATFORM',
  VendorAirship = 'VENDOR_AIRSHIP',
  VendorAttentiveGoogleProductCatalogue = 'VENDOR_ATTENTIVE_GOOGLE_PRODUCT_CATALOGUE',
  VendorAweber = 'VENDOR_AWEBER',
  VendorBazaarvoice = 'VENDOR_BAZAARVOICE',
  VendorBigcommerce = 'VENDOR_BIGCOMMERCE',
  VendorBloomreach = 'VENDOR_BLOOMREACH',
  VendorBlotoutWhitelabel = 'VENDOR_BLOTOUT_WHITELABEL',
  VendorBrierly = 'VENDOR_BRIERLY',
  VendorBubblepayShopify = 'VENDOR_BUBBLEPAY_SHOPIFY',
  VendorCampaignMonitor = 'VENDOR_CAMPAIGN_MONITOR',
  VendorChatter = 'VENDOR_CHATTER',
  VendorConstantContact = 'VENDOR_CONSTANT_CONTACT',
  VendorConvertkit = 'VENDOR_CONVERTKIT',
  VendorDotdigital = 'VENDOR_DOTDIGITAL',
  VendorEloqua = 'VENDOR_ELOQUA',
  VendorEmailOptOut = 'VENDOR_EMAIL_OPT_OUT',
  VendorEpsilon = 'VENDOR_EPSILON',
  VendorEpsilonLoyalty = 'VENDOR_EPSILON_LOYALTY',
  VendorExponea = 'VENDOR_EXPONEA',
  VendorFacebookAds = 'VENDOR_FACEBOOK_ADS',
  VendorFivetran = 'VENDOR_FIVETRAN',
  VendorGladly = 'VENDOR_GLADLY',
  VendorGoogleAds = 'VENDOR_GOOGLE_ADS',
  VendorGoogleContentApi = 'VENDOR_GOOGLE_CONTENT_API',
  VendorGoogleTagManager = 'VENDOR_GOOGLE_TAG_MANAGER',
  VendorHelpscout = 'VENDOR_HELPSCOUT',
  VendorIntercom = 'VENDOR_INTERCOM',
  VendorLiveperson = 'VENDOR_LIVEPERSON',
  VendorLoadTest = 'VENDOR_LOAD_TEST',
  VendorLoyaltylion = 'VENDOR_LOYALTYLION',
  VendorMagento = 'VENDOR_MAGENTO',
  VendorMailtrain = 'VENDOR_MAILTRAIN',
  VendorMindbody = 'VENDOR_MINDBODY',
  VendorNarvar = 'VENDOR_NARVAR',
  VendorOlo = 'VENDOR_OLO',
  VendorOmetria = 'VENDOR_OMETRIA',
  VendorOptimizely = 'VENDOR_OPTIMIZELY',
  VendorOptimove = 'VENDOR_OPTIMOVE',
  VendorPasskit = 'VENDOR_PASSKIT',
  VendorPaytronix = 'VENDOR_PAYTRONIX',
  VendorPostPilot = 'VENDOR_POST_PILOT',
  VendorPunchh = 'VENDOR_PUNCHH',
  VendorReamaze = 'VENDOR_REAMAZE',
  VendorRecharge = 'VENDOR_RECHARGE',
  VendorRemarkety = 'VENDOR_REMARKETY',
  VendorRichpanel = 'VENDOR_RICHPANEL',
  VendorSalesforceServiceCloud = 'VENDOR_SALESFORCE_SERVICE_CLOUD',
  VendorSatisfilabs = 'VENDOR_SATISFILABS',
  VendorSegment = 'VENDOR_SEGMENT',
  VendorSendgrid = 'VENDOR_SENDGRID',
  VendorSendinblue = 'VENDOR_SENDINBLUE',
  VendorSendlane = 'VENDOR_SENDLANE',
  VendorSkechers = 'VENDOR_SKECHERS',
  VendorSmileIo = 'VENDOR_SMILE_IO',
  VendorSnowflakeDataShare = 'VENDOR_SNOWFLAKE_DATA_SHARE',
  VendorStamped = 'VENDOR_STAMPED',
  VendorTally = 'VENDOR_TALLY',
  VendorTalonOne = 'VENDOR_TALON_ONE',
  VendorTravelclick = 'VENDOR_TRAVELCLICK',
  VendorTreasuredata = 'VENDOR_TREASUREDATA',
  VendorWoocommerce = 'VENDOR_WOOCOMMERCE',
  VendorWorldMarket = 'VENDOR_WORLD_MARKET',
  VendorYesmail = 'VENDOR_YESMAIL',
  VendorYotpo = 'VENDOR_YOTPO',
  VendorZetaGlobal = 'VENDOR_ZETA_GLOBAL',
  VendorZoho = 'VENDOR_ZOHO',
  Whatcounts = 'WHATCOUNTS',
  Zaius = 'ZAIUS',
  Zendesk = 'ZENDESK'
}

/** Generated from com/attentivemobile/contracts/mobilewalletservice/CouponsService.proto */
export type VendorCompanyCard = Node & {
  __typename?: 'VendorCompanyCard';
  couponDisplayRegex: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  failRedirectUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  requireExpiryOnIssue: Scalars['Boolean']['output'];
  updated: Scalars['DateTime']['output'];
  uuid: Scalars['String']['output'];
  vendorCampaignId: Scalars['String']['output'];
  vendorOfferId: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/mobilewalletservice/Contract.proto */
export type VendorCompanyCardConnection = {
  __typename?: 'VendorCompanyCardConnection';
  edges: Array<VendorCompanyCardEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Generated from com/attentivemobile/contracts/mobilewalletservice/Contract.proto */
export type VendorCompanyCardEdge = {
  __typename?: 'VendorCompanyCardEdge';
  cursor: Scalars['String']['output'];
  node: VendorCompanyCard;
};

/** Generated from com/attentivemobile/protobuf/common/EventOrigin.proto */
export type VendorOrigin = {
  __typename?: 'VendorOrigin';
  vendor: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/journeys/api/JourneyTrigger.proto */
export type VendorTriggerSource = {
  __typename?: 'VendorTriggerSource';
  eventType: EventType;
  vendor: Vendor;
};

/** Generated from com/attentivemobile/contracts/messaging/automation/admin/Contract.proto */
export type VerifyAIJourneyMessageSentPayload = {
  __typename?: 'VerifyAIJourneyMessageSentPayload';
  company: Maybe<Company>;
  result: Scalars['Boolean']['output'];
};

/** Generated from com/attentivemobile/contracts/messaging/automation/admin/Contract.proto */
export type VerifyAIJourneyWelcomeSentPayload = {
  __typename?: 'VerifyAIJourneyWelcomeSentPayload';
  company: Maybe<Company>;
  eventCount: Scalars['Int']['output'];
};

export type VerifyChallengeForAdminAccessInput = {
  companyId: Scalars['ID']['input'];
  nonce: Scalars['String']['input'];
};

export type VerifyChallengeForAdminAccessPayload = DefaultErrorFailure | VerifyChallengeForAdminAccessSuccess;

/**
 * Generated from com/attentivemobile/contracts/account-service/AuthenticatorContract.proto
 * If success is true, the user has passed authorization and we return back a token with new JWT
 */
export type VerifyChallengeForAdminAccessSuccess = {
  __typename?: 'VerifyChallengeForAdminAccessSuccess';
  company: Maybe<Company>;
  success: Scalars['Boolean']['output'];
  token: Scalars['String']['output'];
};

export type VerifyChallengeInput = {
  companyId: Scalars['ID']['input'];
  nonce: Scalars['String']['input'];
};

export type VerifyChallengePayload = DefaultErrorFailure | VerifyChallengeSuccess;

/**
 * Generated from com/attentivemobile/contracts/account-service/AuthenticatorContract.proto
 * If success is true, the user has passed authorization and we return back a token
 */
export type VerifyChallengeSuccess = {
  __typename?: 'VerifyChallengeSuccess';
  company: Maybe<Company>;
  success: Scalars['Boolean']['output'];
  token: Scalars['String']['output'];
};

export type VerifyMfaOneTimePasscodeInput = {
  mfaToken: Scalars['String']['input'];
  oneTimePasscode: Scalars['String']['input'];
  rememberDevice: InputMaybe<Scalars['Boolean']['input']>;
};

/** Generated from com/attentivemobile/contracts/account-service/MFAContract.proto */
export type VerifyMfaOneTimePasscodePayload = {
  __typename?: 'VerifyMfaOneTimePasscodePayload';
  deviceId: Maybe<Scalars['String']['output']>;
  error: Maybe<Scalars['String']['output']>;
  jwt: Maybe<Scalars['String']['output']>;
};

export type VerifySendingDomainInput = {
  companyId: Scalars['ID']['input'];
  domainId: Scalars['Long']['input'];
};

/** Generated from com/attentivemobile/contracts/emails/management/Contract.proto */
export type VerifySendingDomainPayload = {
  __typename?: 'VerifySendingDomainPayload';
  domainValidation: DomainValidationResult;
};

export type VerifyServerSideTagDnsRecordsInput = {
  companyId: Scalars['ID']['input'];
};

export type VerifyServerSideTagDnsRecordsPayload = DefaultErrorFailure | VerifyServerSideTagDnsRecordsSuccess;

/** Generated from com/attentivemobile/contracts/integrations/serversidetag/Contract.proto */
export type VerifyServerSideTagDnsRecordsSuccess = {
  __typename?: 'VerifyServerSideTagDnsRecordsSuccess';
  dnsRecords: Array<ServerSideTagDnsRecord>;
  valid: Scalars['Boolean']['output'];
};

export type VerifySubdomainInput = {
  companyId: Scalars['ID']['input'];
  domainId: Scalars['Long']['input'];
};

/** Generated from com/attentivemobile/contracts/emails/management/Contract.proto */
export type VerifySubdomainPayload = {
  __typename?: 'VerifySubdomainPayload';
  domainValidation: DomainValidationResult;
};

export type VideoLinkPropertiesInput = {
  imagePreviewUrl: UrlInput;
  title: Scalars['String']['input'];
  videoPreviewUrl: UrlInput;
};

/** Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto */
export type VideoPreviewLinkProperties = {
  __typename?: 'VideoPreviewLinkProperties';
  imagePreviewUrl: Scalars['String']['output'];
  title: Scalars['String']['output'];
  videoPreviewUrl: Scalars['String']['output'];
};

/**
 * Generated from com/attentivemobile/contracts/account-service/Contract.proto
 * Viewer details
 */
export type Viewer = {
  __typename?: 'Viewer';
  account: Maybe<Account>;
  beeFreeAuthToken: Maybe<BeeFreeAuthToken>;
  /** Installed application details. */
  installedApplication: Maybe<InstalledApplication>;
  roles: Maybe<GetRolesResponse>;
  scopes: Maybe<Array<Scope>>;
};


/**
 * Generated from com/attentivemobile/contracts/account-service/Contract.proto
 * Viewer details
 */
export type ViewerrolesArgs = {
  companyId: Scalars['ID']['input'];
};


/**
 * Generated from com/attentivemobile/contracts/account-service/Contract.proto
 * Viewer details
 */
export type ViewerscopesArgs = {
  companyId: Scalars['ID']['input'];
};

export enum VisibilityType {
  All = 'ALL',
  None = 'NONE',
  Superuser = 'SUPERUSER',
  VisibilityTypeUnknown = 'VISIBILITY_TYPE_UNKNOWN'
}

export enum WarmupCampaignStatus {
  WarmupCampaignStatusScheduled = 'WARMUP_CAMPAIGN_STATUS_SCHEDULED',
  WarmupCampaignStatusUnset = 'WARMUP_CAMPAIGN_STATUS_UNSET'
}

/** Generated from com/attentivemobile/contracts/emailwarmups/Contract.proto */
export type WarmupSeriesSizeAndDuration = {
  __typename?: 'WarmupSeriesSizeAndDuration';
  emailSubscriberListSize: Scalars['Long']['output'];
  warmupDays: Scalars['Long']['output'];
  warningMessage: WarmupWarnings;
};

export enum WarmupWarnings {
  WarmupWarningLow_90DaysEngagement = 'WARMUP_WARNING_LOW_90_DAYS_ENGAGEMENT',
  WarmupWarningLow_365DaysEngagement = 'WARMUP_WARNING_LOW_365_DAYS_ENGAGEMENT',
  WarmupWarningUnset = 'WARMUP_WARNING_UNSET'
}

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type WebTemplate = Node & {
  __typename?: 'WebTemplate';
  callToAction: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  subtitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type WebTemplateInput = {
  callToAction: Scalars['String']['input'];
  description: Scalars['String']['input'];
  subtitle: Scalars['String']['input'];
  title: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

/**
 * Generated from com/attentivemobile/contracts/externaltokens/Webhooks.proto
 * Webhook details.
 */
export type Webhook = Node & {
  __typename?: 'Webhook';
  /** Timestamp of when the webhook was disabled. */
  disabledAt: Maybe<Scalars['DateTime']['output']>;
  /** The list of events this webhook is subscribed to. */
  events: Array<Scalars['String']['output']>;
  /** Id of the webhook. */
  id: Scalars['ID']['output'];
  /** The destination url of the webhook. */
  url: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/externaltokens/Contract.proto */
export type WebhookEventConnection = {
  __typename?: 'WebhookEventConnection';
  edges: Array<WebhookEventEdge>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/externaltokens/Contract.proto */
export type WebhookEventEdge = {
  __typename?: 'WebhookEventEdge';
  cursor: Scalars['String']['output'];
  node: WebhookEventType;
};

/** Generated from com/attentivemobile/contracts/externaltokens/Contract.proto */
export type WebhookEventType = {
  __typename?: 'WebhookEventType';
  displayName: Scalars['String']['output'];
  internalId: Scalars['Long']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export enum WebhookStatus {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED',
  Inactive = 'INACTIVE',
  WebhookStatusUnknown = 'WEBHOOK_STATUS_UNKNOWN'
}

/** Generated from com/attentivemobile/contracts/messaging/automation/aijourneys/onboarding/AIJourneyLinkConfiguration.proto */
export type WelcomeLinkConfiguration = {
  __typename?: 'WelcomeLinkConfiguration';
  linkConfig: Maybe<WelcomeLinkConfigurationLinkConfig>;
};

export type WelcomeLinkConfigurationInput = {
  linkConfig: InputMaybe<WelcomeLinkConfigurationInputLinkConfig>;
};

export type WelcomeLinkConfigurationInputLinkConfig =
  { couponDisplayLinkInput: CouponDisplayScreenConfigInput; customLinkInput?: never; offerLinkInput?: never; }
  |  { couponDisplayLinkInput?: never; customLinkInput: CustomLinkConfigInput; offerLinkInput?: never; }
  |  { couponDisplayLinkInput?: never; customLinkInput?: never; offerLinkInput: OfferLinkConfigInput; };

export type WelcomeLinkConfigurationLinkConfig = CouponDisplayScreenConfig | CustomLinkConfig | OfferLinkConfig;

/** Generated from com/attentivemobile/protobuf/common/targeting/UserProperty.proto */
export type WellKnownProperty = {
  __typename?: 'WellKnownProperty';
  type: WellKnownPropertyType;
};

/** Generated from com/attentivemobile/contracts/user/profile/Contract.proto */
export type WellKnownPropertyDefinition = Node & {
  __typename?: 'WellKnownPropertyDefinition';
  id: Scalars['ID']['output'];
  metadata: WellKnownPropertyMetadata;
  type: WellKnownPropertyType;
};

export type WellKnownPropertyFilter = {
  stringCondition: InputMaybe<StringCondition>;
  type: InputMaybe<WellKnownPropertyType>;
};

/** Generated from com/attentivemobile/protobuf/common/targeting/UserProperty.proto */
export type WellKnownPropertyMetadata = {
  __typename?: 'WellKnownPropertyMetadata';
  dataType: CustomPropertyType;
  displayName: Scalars['String']['output'];
  internal: Scalars['Boolean']['output'];
};

export enum WellKnownPropertyType {
  WellKnownPropertyAreaCode = 'WELL_KNOWN_PROPERTY_AREA_CODE',
  WellKnownPropertyCity = 'WELL_KNOWN_PROPERTY_CITY',
  WellKnownPropertyConversationalState = 'WELL_KNOWN_PROPERTY_CONVERSATIONAL_STATE',
  WellKnownPropertyCountry = 'WELL_KNOWN_PROPERTY_COUNTRY',
  WellKnownPropertyFirstName = 'WELL_KNOWN_PROPERTY_FIRST_NAME',
  WellKnownPropertyFullName = 'WELL_KNOWN_PROPERTY_FULL_NAME',
  WellKnownPropertyIsLitigious = 'WELL_KNOWN_PROPERTY_IS_LITIGIOUS',
  WellKnownPropertyLanguage = 'WELL_KNOWN_PROPERTY_LANGUAGE',
  WellKnownPropertyLastName = 'WELL_KNOWN_PROPERTY_LAST_NAME',
  WellKnownPropertyLocale = 'WELL_KNOWN_PROPERTY_LOCALE',
  WellKnownPropertyMailExchange = 'WELL_KNOWN_PROPERTY_MAIL_EXCHANGE',
  WellKnownPropertyRegion = 'WELL_KNOWN_PROPERTY_REGION',
  WellKnownPropertyTimezone = 'WELL_KNOWN_PROPERTY_TIMEZONE',
  WellKnownPropertyTypesBestsellerRecommendation = 'WELL_KNOWN_PROPERTY_TYPES_BESTSELLER_RECOMMENDATION',
  WellKnownPropertyTypesCategoryBestsellerLastPurchased = 'WELL_KNOWN_PROPERTY_TYPES_CATEGORY_BESTSELLER_LAST_PURCHASED',
  WellKnownPropertyTypesEstimatedLifetimeValue = 'WELL_KNOWN_PROPERTY_TYPES_ESTIMATED_LIFETIME_VALUE',
  WellKnownPropertyTypesIsPartOfAiJourney = 'WELL_KNOWN_PROPERTY_TYPES_IS_PART_OF_AI_JOURNEY',
  WellKnownPropertyTypesKlaviyoLastEmailOpen = 'WELL_KNOWN_PROPERTY_TYPES_KLAVIYO_LAST_EMAIL_OPEN',
  WellKnownPropertyTypesMailchimpMemberRating = 'WELL_KNOWN_PROPERTY_TYPES_MAILCHIMP_MEMBER_RATING',
  WellKnownPropertyTypesNotifyWhenBackInStock = 'WELL_KNOWN_PROPERTY_TYPES_NOTIFY_WHEN_BACK_IN_STOCK',
  WellKnownPropertyTypesSalesforceServiceCloudContactId = 'WELL_KNOWN_PROPERTY_TYPES_SALESFORCE_SERVICE_CLOUD_CONTACT_ID',
  WellKnownPropertyTypesSmsChurnProbability = 'WELL_KNOWN_PROPERTY_TYPES_SMS_CHURN_PROBABILITY',
  WellKnownPropertyTypesUnknown = 'WELL_KNOWN_PROPERTY_TYPES_UNKNOWN',
  WellKnownPropertyZip = 'WELL_KNOWN_PROPERTY_ZIP'
}

/** Generated from com/attentivemobile/contracts/workload/management/service/configuration/Contract.proto */
export type WorkloadConfig = {
  __typename?: 'WorkloadConfig';
  cronSchedule: Scalars['String']['output'];
  entryClass: Scalars['String']['output'];
  jar: Scalars['String']['output'];
  jobStates: Array<WorkloadJobState>;
  payloadArgs: Scalars['JSON']['output'];
  payloadSparkConfigs: Scalars['JSON']['output'];
  status: Scalars['String']['output'];
  tags: Array<Scalars['String']['output']>;
  teamName: Scalars['String']['output'];
  workloadName: Scalars['String']['output'];
};

export type WorkloadConfigInput = {
  cronSchedule: InputMaybe<Scalars['String']['input']>;
  entryClass: Scalars['String']['input'];
  inputArgs: InputMaybe<Scalars['JSON']['input']>;
  inputSparkConfigs: InputMaybe<Scalars['JSON']['input']>;
  jar: Scalars['String']['input'];
  tags: InputMaybe<Array<Scalars['String']['input']>>;
  teamName: Scalars['String']['input'];
  workloadName: Scalars['String']['input'];
};

/** Generated from com/attentivemobile/contracts/workload/management/service/runner/Contract.proto */
export type WorkloadJobState = {
  __typename?: 'WorkloadJobState';
  applicationId: Scalars['String']['output'];
  runId: Scalars['String']['output'];
  state: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/workload/management/service/runner/Contract.proto */
export type WorkloadRun = {
  __typename?: 'WorkloadRun';
  appId: Scalars['String']['output'];
  appTags: Array<Scalars['String']['output']>;
  duration: Duration;
  endTimeUtc: Scalars['DateTime']['output'];
  finalStatus: Scalars['String']['output'];
  runName: Scalars['String']['output'];
  startTimeUtc: Scalars['DateTime']['output'];
  state: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type ZendeskHelpCenterArticle = Node & {
  __typename?: 'ZendeskHelpCenterArticle';
  id: Scalars['ID']['output'];
  snippet: Scalars['String']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type ZendeskHelpCenterArticleConnection = {
  __typename?: 'ZendeskHelpCenterArticleConnection';
  edges: Array<ZendeskHelpCenterArticleEdge>;
  pageInfo: PageInfo;
};

/** Generated from com/attentivemobile/contracts/notification/service/Contract.proto */
export type ZendeskHelpCenterArticleEdge = {
  __typename?: 'ZendeskHelpCenterArticleEdge';
  cursor: Scalars['String']['output'];
  node: ZendeskHelpCenterArticle;
};

export enum ZendeskJwtType {
  ZendeskJwtTypeMessaging = 'ZENDESK_JWT_TYPE_MESSAGING',
  ZendeskJwtTypeSso = 'ZENDESK_JWT_TYPE_SSO',
  ZendeskJwtTypeUnknown = 'ZENDESK_JWT_TYPE_UNKNOWN'
}
