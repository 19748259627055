import React from 'react';
import { Navigate, Route } from 'react-router-dom';

import { CompanyFeatureFlagNames, RoutePermissions } from '@attentive/acore-utils';
import { SegmentSubscribersListEntryPoint } from '@attentive/crm/entrypoints';
import { RoutesFn } from '@attentive/data-router';

import { SegmentDetailsPageEntrypoint } from './pages/SegmentDetails/SegmentDetailsPage.entrypoint';

const routes: RoutesFn<CompanyFeatureFlagNames> = ({ getCompanyFeatureFlag }) => {
  const enableProductCatalog = getCompanyFeatureFlag('ENABLE_PRODUCT_CATALOG_UI');
  const enableViewSubscribers = getCompanyFeatureFlag('ENABLE_SEGMENTATION_SUBSCRIBERS_IN_SEGMENT');

  return (
    <Route Component={React.lazy(() => import('./SegmentationMfe'))}>
      <Route path="all" Component={React.lazy(() => import('./pages/SegmentsList/SegmentsList'))} />
      <Route
        path="attentive"
        Component={React.lazy(() => import('./pages/SegmentsList/SegmentsList'))}
      />
      <Route
        path="manual"
        Component={React.lazy(() => import('./pages/SegmentsList/SegmentsList'))}
      />
      <Route
        path="third-party"
        Component={React.lazy(() => import('./pages/SegmentsList/SegmentsList'))}
      />
      <Route
        path="create/:templateId"
        Component={React.lazy(() => import('./pages/CreateSegment/CreateSegment'))}
      />
      <Route element={<RoutePermissions permission={() => enableProductCatalog} />}>
        <Route path="products" Component={React.lazy(() => import('./pages/ProductCatalog'))} />
        <Route path="products/:id" Component={React.lazy(() => import('./pages/ProductDetail'))} />
      </Route>

      <Route path=":id" {...SegmentDetailsPageEntrypoint} />
      <Route path=":id/performance" {...SegmentDetailsPageEntrypoint} />
      <Route path=":id/edit" {...SegmentDetailsPageEntrypoint} />
      <Route element={<RoutePermissions permission={() => enableViewSubscribers} />}>
        <Route
          path=":id/subscribers"
          Component={React.lazy(() => import('./pages/SegmentSubscribers/SegmentSubscribers'))}
        >
          <Route index {...SegmentSubscribersListEntryPoint} />
        </Route>
      </Route>
      <Route path="*" element={<Navigate to="all" />} />
    </Route>
  );
};

export { routes };
