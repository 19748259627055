import { useState } from 'react';

import { API } from '@attentive/acore-utils';

export type SegmentDownloadSubscriberType = 'SMS' | 'EMAIL' | 'ALL';

interface QueueReportMetadata {
  recipients: string[]; // Changed from string to string[]
  reportId: number;
  startTime?: number;
  endTime?: number;
  scheduledTime?: number;
  segmentId?: number; // Specific to segment csv download
  subscriberType?: SegmentDownloadSubscriberType; // Specific to segment csv download
}

interface QueueReportInput {
  metadata: QueueReportMetadata;
  onComplete?: (result: { scheduledRecipients: string[]; failedRecipients: string[] }) => void;
}

const batchSize = 10;
const createBatches = <T>(items: T[]): T[][] => {
  return Array.from({ length: Math.ceil(items.length / batchSize) }, (_, index) =>
    items.slice(index * batchSize, index * batchSize + batchSize)
  );
};

export const useQueueScheduledReport = (internalCompanyId: number) => {
  const [isLoading, setIsLoading] = useState(false);
  const unixNow = Math.floor(Date.now() / 1000);

  const queueScheduledReport = async ({ metadata, onComplete }: QueueReportInput) => {
    setIsLoading(true);

    const {
      startTime = unixNow,
      endTime = unixNow,
      scheduledTime = unixNow,
      recipients,
      reportId,
      segmentId,
      subscriberType,
    } = metadata;

    const scheduledRecipients: string[] = [];
    const failedRecipients: string[] = [];

    const recipientBatches = createBatches(recipients);

    try {
      for (const batch of recipientBatches) {
        const batchPromises = batch.map(async (recipient) => {
          try {
            const response = await API.put(
              `/report-platform/rp/reports/enqueue/${internalCompanyId}`,
              {
                start: startTime,
                end: endTime,
                scheduledTime,
                recipients: recipient,
                reportDto: { id: reportId },
                ...(segmentId && { segmentId }),
                ...(subscriberType && { subscriberType }),
              }
            );
            if (response.status !== 200) {
              failedRecipients.push(recipient);
            } else {
              scheduledRecipients.push(recipient);
            }
          } catch {
            failedRecipients.push(recipient);
          }
        });

        await Promise.all(batchPromises);
      }

      onComplete?.({
        scheduledRecipients,
        failedRecipients,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    queueScheduledReport,
    isLoading,
  };
};
