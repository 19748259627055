import { useMemo } from 'react';

import {
  KnownKlaviyoProfileAttr,
  ShopifyFilter,
  SupportedVendorAttributeDataType,
  Vendor,
  VendorAttribute,
  VendorAttributeDataType,
  supportedKlaviyoAttributeDataTypes,
} from '../../../../constants';
import { isKlaviyoEmailAttr } from '../../utils/stringUtils';

interface SeparateOptionsArgs {
  enableNestedListTypes?: boolean;
  options: VendorAttribute[];
  knownOptions?: string[];
  vendor: Vendor;
  unsupportedOptions?: string[];
}

function separateOptions({
  enableNestedListTypes,
  options,
  knownOptions,
  vendor,
  unsupportedOptions,
}: SeparateOptionsArgs) {
  const profileOptions: VendorAttribute[] = [];

  options.forEach((property) => {
    if (unsupportedOptions && unsupportedOptions.includes(property.attributeName)) {
      return;
    }

    if (
      enableNestedListTypes &&
      property.segmentDataType === VendorAttributeDataType.LIST &&
      property.nestedOptions
    ) {
      const nestedProfileOptions = separateOptions({
        enableNestedListTypes,
        options: property.nestedOptions,
        vendor: `${vendor}:${property.attributeName}` as Vendor,
        unsupportedOptions,
        knownOptions,
      });

      profileOptions.push(
        ...nestedProfileOptions.map((item) => ({
          ...item,
          attributeName: `${property.attributeName}:${item.attributeName}`,
        }))
      );
    } else if (
      knownOptions?.includes(`${vendor}:${property.attributeName}`) ||
      SupportedVendorAttributeDataType.includes(property.segmentDataType as VendorAttributeDataType)
    ) {
      profileOptions.push(property);
    }
  });

  return profileOptions;
}

export const useMemoizedSeparateOptions = ({
  enableNestedListTypes,
  options,
  knownOptions,
  vendor,
  unsupportedOptions,
}: SeparateOptionsArgs) => {
  return useMemo(
    () =>
      separateOptions({
        enableNestedListTypes,
        options,
        knownOptions,
        vendor,
        unsupportedOptions,
      }),
    [enableNestedListTypes, options, knownOptions, vendor, unsupportedOptions]
  );
};

export function separateShopifyOptions(options: VendorAttribute[] = []) {
  const EmailAttributes = [
    ShopifyFilter.ACCEPTS_MARKETING,
    ShopifyFilter.MARKETING_OPT_IN_LEVEL,
    ShopifyFilter.ACCEPTS_MARKETING_UPDATED_TIME,
  ];

  const shopifyEmailOptions: VendorAttribute[] = [];
  const shopifyShoppingOptions: VendorAttribute[] = [];

  options.forEach((property) => {
    if (EmailAttributes.includes(`${Vendor.SHOPIFY}:${property.attributeName}` as ShopifyFilter)) {
      shopifyEmailOptions.push(property);
    } else {
      shopifyShoppingOptions.push(property);
    }
  });

  return { shopifyEmailOptions, shopifyShoppingOptions };
}

export function separateKlaviyoOptions(options: VendorAttribute[] = []) {
  const KlaviyoLocationAttributes = [
    KnownKlaviyoProfileAttr.CITY,
    KnownKlaviyoProfileAttr.REGION,
    KnownKlaviyoProfileAttr.ZIP,
    KnownKlaviyoProfileAttr.TIMEZONE,
    KnownKlaviyoProfileAttr.COUNTRY,
  ];

  const klaviyoLocationOptions: VendorAttribute[] = [];
  const klaviyoProfileOptions: VendorAttribute[] = [];
  const klaviyoEmailOptions: VendorAttribute[] = [];

  options.forEach((property) => {
    if (
      KlaviyoLocationAttributes.includes(
        `${Vendor.PROFILE_VENDOR_KLAVIYO}:${property.attributeName}` as KnownKlaviyoProfileAttr
      )
    ) {
      klaviyoLocationOptions.push(property);
    } else if (isKlaviyoEmailAttr(property)) {
      klaviyoEmailOptions.push(property);
    } else if (
      !supportedKlaviyoAttributeDataTypes.includes(
        property.segmentDataType as VendorAttributeDataType
      )
    ) {
      // Location and email attributes are known attributes so we don't need to check their types
      // However, for profile options, we want to be sure to remove any data types that are not currently supported
      return;
    } else {
      klaviyoProfileOptions.push(property);
    }
  });

  return {
    klaviyoLocationOptions,
    klaviyoEmailOptions,
    klaviyoProfileOptions,
  };
}
